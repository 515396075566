import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";
import { PinInput } from "react-input-pin-code";
import { useMediaQuery } from "react-responsive";
import Barcode from "react-barcode";

export default function GenerarBarCode(props) {
  let navigate = useNavigate();

  const [value, setValue] = useState(null);
  const toast = useRef(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const { statusAcceso, obtenerAcceso } = configuracionContext;

  const exitClick = (e) => {
    navigate("/");
  };

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar
  };

  //console.log(statusAcceso);
  //console.log(state);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      <Grid
        container
        xs={12}
        md={12}
        style={{
          padding: "2vw",
        }}
      >
        <Fragment>
          <Grid item xs={12} md={4}>
            <Barcode value="0200642300000002" />,
          </Grid>
        </Fragment>
      </Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

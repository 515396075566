import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";
import { InputMask } from "primereact/inputmask";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { InputNumber } from "primereact/inputnumber";

export default function Empresas(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  let navigate = useNavigate();

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Nombre", dataKey: "nombre" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [empresa, guardarEmpresa] = useState(null);
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    empresas,
    tiposContribuyentes,
    ivas,
    cuentas,
    cobradores,
    verificacionAfip,
    obtenerEmpresas,
    obtenerAcceso,
    agregarEmpresa,
    actualizarEmpresa,
    obtenerTiposContribuyentes,
    obtenerIvas,
    obtenerUltimoNumeroAfip,
    obtenerUltimoNumeroSistema,
    verificarComprobantesAfip,
    obtenerCuentas,
    obtenerCobradores,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Empresas" });
    }
  }, [state]);

  useEffect(() => {
    obtenerEmpresas();
    obtenerTiposContribuyentes();
    obtenerIvas();
    obtenerCuentas();
    obtenerCobradores();
  }, []);

  const editClick = (e) => {
    if (selectedRow) {
      guardarEmpresa(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un empresa.",
        life: 3000,
      });
    }
  };

  const addClick = (e) => {
    //props.history.push(`/empresa/0`);
    setVisible(true);
    setSelectedRow(null);
    guardarEmpresa({
      habilitado: true,
    });
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  function ValidateCUITCUIL(cuit) {
    if (cuit.length != 13) return 0;

    var rv = false;
    var resultado = 0;
    var cuit_nro = cuit ? cuit.replace("-", "") : null;
    var codes = "6789456789";
    var cuit_long = parseInt(cuit_nro);
    var verificador = parseInt(cuit_nro[cuit_nro.length - 1]);
    var x = 0;

    while (x < 10) {
      var digitoValidador = parseInt(codes.substring(x, x + 1));
      if (isNaN(digitoValidador)) digitoValidador = 0;
      var digito = parseInt(cuit_nro.substring(x, x + 1));
      if (isNaN(digito)) digito = 0;
      var digitoValidacion = digitoValidador * digito;
      resultado += digitoValidacion;
      x++;
    }
    resultado = resultado % 11;
    rv = resultado == verificador;
    return rv;
  }

  const aceptarClick = (e) => {
    e.preventDefault();

    //Validar
    if (
      !empresa ||
      empresa.nombre === undefined ||
      empresa.nombre === null ||
      empresa.nombre.trim() === ""
    ) {
      mensajeAlerta("Empresas", "Nombre es necesario !.", "error");
      return;
    }

    if (
      !empresa ||
      empresa.domicilio === undefined ||
      empresa.domicilio === null ||
      empresa.domicilio.trim() === ""
    ) {
      mensajeAlerta("Empresas", "Domicilio completo es necesario !.", "error");
      return;
    }

    if (empresa && empresa.cuit !== null && empresa.cuit.trim() !== "" && empresa.facturaElectronica) {
      if (!ValidateCUITCUIL(empresa.cuit)) {
        mensajeAlerta("Empresas", "El Nº de C.U.I.T es incorrecto !.", "error");
        return;
      }
    }

    if (
      empresa.facturaElectronica ||
      empresa.percepcionArba ||
      empresa.percepcionAgip ||
      empresa.retenciones
    ) {
      if (
        !empresa ||
        empresa.cuit === undefined ||
        empresa.cuit === null ||
        empresa.cuit.trim() === ""
      ) {
        mensajeAlerta(
          "Empresas",
          "Para factura electrónica, percepciones o retenciones debe cargar C.U.I.T. !.",
          "error"
        );
        return;
      }
    }

    if (
      !empresa ||
      empresa.id_tipocontribuyente === undefined ||
      empresa.id_tipocontribuyente === null ||
      empresa.id_tipocontribuyente === 0 ||
      empresa.id_tipocontribuyente === ""
    ) {
      mensajeAlerta(
        "Empresas",
        "Tipo de contribuyente es necesario !.",
        "error"
      );
      return;
    }

    if (
      !empresa ||
      empresa.iva === undefined ||
      empresa.iva === null ||
      empresa.iva === 0 ||
      empresa.iva === ""
    ) {
      mensajeAlerta("Empresas", "Iva es necesario !.", "error");
      return;
    }

    if (
      empresa &&
      (empresa.id_tipocontribuyente === 2 ||
        empresa.id_tipocontribuyente === 3) /*EXENTO/MONOT*/ &&
      Number(empresa.iva != 0)
    ) {
      mensajeAlerta(
        "Empresas",
        "Exento ó Monotributista debe tener Iva 0 !.",
        "error"
      );
      return;
    }

    if (
      empresa &&
      (empresa.id_tipocontribuyente === 2 ||
        empresa.id_tipocontribuyente === 3) /*EXENTO/MONOT*/ &&
      (empresa.percepcionArba || empresa.percepcionAgip)
    ) {
      mensajeAlerta(
        "Empresas",
        "Empresa Exento ó Monotributista no tiene percepciones !.",
        "error"
      );
      return;
    }

    if (
      empresa &&
      empresa.facturaElectronica !== undefined &&
      empresa.facturaElectronica !== null &&
      empresa.facturaElectronica
    ) {
      if (
        !empresa ||
        empresa.ingresosBrutos === undefined ||
        empresa.ingresosBrutos === null ||
        empresa.ingresosBrutos.trim() === ""
      ) {
        mensajeAlerta("Empresas", "Ingresos Brutos es necesario !.", "error");
        return;
      }

      if (
        !empresa ||
        empresa.inicioActividades === undefined ||
        empresa.inicioActividades === null ||
        empresa.inicioActividades === 0
      ) {
        mensajeAlerta(
          "Empresas",
          "La fecha de inicio de actividades es necesaria !.",
          "error"
        );
        return;
      }
    }

    if (
      empresa &&
      empresa.mp !== undefined &&
      empresa.mp !== null &&
      empresa.mp
    ) {
      if (
        !empresa ||
        empresa.cuentaIdMp === undefined ||
        empresa.cuentaIdMp === null ||
        empresa.cuentaIdMp === "" ||
        empresa.cuentaIdMp === 0
      ) {
        mensajeAlerta("Empresas", "Cuenta MP es necesaria !.", "error");
        return;
      }

      if (
        !empresa ||
        empresa.cobradorIdMp === undefined ||
        empresa.cobradorIdMp === null ||
        empresa.cobradorIdMp === "" ||
        empresa.cobradorIdMp === 0
      ) {
        mensajeAlerta("Empresas", "Cobrador MP es necesaria !.", "error");
        return;
      }
    }

    // Llamar al context
    if (selectedRow) {
      actualizarEmpresa({ empresa });
    } else {
      agregarEmpresa({ empresa });
    }
    onHide();
    setTimeout(() => {
      setSelectedRow(null);
      obtenerEmpresas();
    }, 2000);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Empresas",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, empresas);
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(empresas);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "empresas");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Empresas"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Empresa"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const handleClickOpenAfip = (cuit, puntoVenta, tipo) => {

    if (!puntoVenta) {
      puntoVenta = 1
    }

    if (
      !cuit ||
      cuit === undefined ||
      cuit === null ||
      cuit === 0 ||
      cuit.trim() === ""
    ) {
      mensajeAlerta("Empresas", "El C.U.I.T. es necesario !.", "error");
      return;
    }

    if (
      !puntoVenta ||
      puntoVenta === undefined ||
      puntoVenta === null ||
      puntoVenta === 0 ||
      puntoVenta === ""
    ) {
      mensajeAlerta("Empresas", "El Punto de Venta es necesario !.", "error");
      return;
    }

    if (
      !tipo ||
      tipo === undefined ||
      tipo === null ||
      tipo === 0 ||
      tipo === ""
    ) {
      mensajeAlerta("Empresas", "El Tipo es necesario !.", "error");
      return;
    }

    if (empresa.facturaElectronica) {
      obtenerUltimoNumeroAfip({ cuit, puntoVenta, tipo, empresa: selectedRow });
    } else {
      obtenerUltimoNumeroSistema({ cuit, puntoVenta, tipo, empresa: selectedRow });
    }

  };

  //console.log(selectedRow);
  //console.log(cuentas);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {empresas && tiposContribuyentes ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ align: "center", margin: "1em" }}
              >
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-pencil"
                  tooltip="Editar"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={
                        empresas &&
                        empresas.filter(
                          (item) => item.control && item.habilitado
                        ).length > 0
                          ? empresas.filter(
                              (item) => item.control && item.habilitado
                            )
                          : empresas &&
                            empresas.filter((item) => item.habilitado)
                          ? empresas.filter((item) => item.habilitado)
                          : null
                      }
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={50}
                      rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        //body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="nombre"
                        header="Nombre"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="cuit"
                        header="Cuit"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="tiposcontribuyentes.nombre"
                        header="Tipos de Contribuyentes"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="habilitado"
                        header="Habilitado"
                        body={habilitadoTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "95vw" }}
                style={{ width: "95vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                  {selectedRow ? (
                    <Grid item xs={12} md={2}>
                      <TextField
                        value={empresa ? empresa.id : null}
                        required
                        variant="standard"
                        id="id"
                        label="ID"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={10}>
                    <TextField
                      value={empresa ? empresa.nombre : null}
                      required
                      variant="standard"
                      id="nombre"
                      label="Nombre"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        guardarEmpresa({
                          ...empresa,
                          nombre: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      value={empresa ? empresa.domicilio : null}
                      required
                      variant="standard"
                      id="domicilio"
                      label="Domicilio Completo"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        guardarEmpresa({
                          ...empresa,
                          domicilio: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <label htmlFor="id">Nº C.U.I.T *</label>
                    <InputMask
                      id="cuit"
                      mask="99-99999999-9"
                      value={empresa ? empresa.cuit : null}
                      placeholder="99-99999999-9"
                      size={20}
                      onChange={(e) =>
                        guardarEmpresa({
                          ...empresa,
                          cuit: e.value,
                        })
                      }
                      style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                    ></InputMask>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="tipoContribuyente"
                      //disabled
                      fullWidth
                      options={
                        tiposContribuyentes
                          ? tiposContribuyentes.filter(
                              (item) => item.id === 2 || item.id === 6
                            )
                          : null
                      }
                      disablePortal
                      defaultValue={
                        tiposContribuyentes && selectedRow
                          ? tiposContribuyentes[
                              tiposContribuyentes.findIndex(
                                (item) =>
                                  item.id === selectedRow.id_tipocontribuyente
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Tipo Contribuyente *"
                          size="small"
                        />
                      )}
                      onChange={(event, value) => {
                        if (value) {
                          guardarEmpresa({
                            ...empresa,
                            id_tipocontribuyente: value.id,
                          });
                        } else {
                          guardarEmpresa({
                            ...empresa,
                            id_tipocontribuyente: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Autocomplete
                      id="iva"
                      //disabled
                      fullWidth
                      options={ivas ? ivas : null}
                      disablePortal
                      defaultValue={
                        ivas && selectedRow
                          ? ivas[
                              ivas.findIndex(
                                (item) => item.tasa === selectedRow.iva
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Iva Defecto *"
                          size="small"
                        />
                      )}
                      onChange={(event, value) => {
                        if (value) {
                          guardarEmpresa({
                            ...empresa,
                            iva: value.tasa,
                          });
                        } else {
                          guardarEmpresa({
                            ...empresa,
                            iva: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      value={empresa ? empresa.ingresosBrutos : null}
                      required
                      variant="standard"
                      id="ingresosBrutos"
                      label="Ingresos Brutos"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        guardarEmpresa({
                          ...empresa,
                          ingresosBrutos: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <TextField
                      value={empresas ? empresas.inicioActividades : null}
                      id="inicioActividades"
                      label="Inicio de Actividades"
                      type="date"
                      fullWidth
                      variant="standard"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      //onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarEmpresa({
                          ...empresa,
                          inicioActividades: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <label htmlFor="cbu">C.B.U.</label>
                    <InputMask
                      id="cbu"
                      mask="9999999999999999999999"
                      value={empresas ? empresas.cbu : null}
                      placeholder="9999999999999999999999"
                      //onBlur={handleEnter}
                      size={30}
                      onChange={(e) =>
                        guardarEmpresa({
                          ...empresas,
                          cbu: e.value,
                        })
                      }
                      style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                    ></InputMask>
                  </Grid>

                  <Grid item xs={12} md={3} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={empresa ? empresa.facturaElectronica : null}
                          style={{ marginLeft: "1vw" }}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              facturaElectronica: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Factura Electrónica"
                      labelPlacement="start"
                      style={{ paddingLeft: ".7vw" }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={empresa ? empresa.percepcionArba : null}
                          style={{ marginLeft: "1vw" }}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              percepcionArba: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Percepciones ARBA"
                      labelPlacement="start"
                      style={{ paddingLeft: ".7vw" }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={empresa ? empresa.percepcionAgip : null}
                          style={{ marginLeft: "1vw" }}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              percepcionAgip: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Percepciones AGIP"
                      labelPlacement="start"
                      style={{ paddingLeft: ".7vw" }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={empresa ? empresa.retenciones : null}
                          style={{ marginLeft: "1vw" }}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              retenciones: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Retenciones"
                      labelPlacement="start"
                      style={{ paddingLeft: ".7vw" }}
                    />
                  </Grid>

                  {/*   
                  <Grid item xs={12} md={4} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={empresa ? empresa.linkMP : null}
                          style={{ marginLeft: "1vw" }}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              linkMP: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Genera Links de Mercado Pago"
                      labelPlacement="start"
                      style={{ paddingLeft: ".7vw" }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={empresa ? empresa.pdf : null}
                          style={{ marginLeft: "1vw" }}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              pdf: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Genera comprobantes en PDF"
                      labelPlacement="start"
                      style={{ paddingLeft: ".7vw" }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={empresa ? empresa.enviarEmail : null}
                          style={{ marginLeft: "1vw" }}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              enviarEmail: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Enviar por Email"
                      labelPlacement="start"
                      style={{ paddingLeft: ".7vw" }}
                    />
                  </Grid>
                  */}

                  <Grid item xs={12} md={3} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={empresa ? empresa.pdf : null}
                          style={{ marginLeft: "1vw" }}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              pdf: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Genera Pdf"
                      labelPlacement="start"
                      style={{ paddingLeft: ".7vw" }}
                    />
                  </Grid>

                  <Grid item xs={12} md={9} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={empresa ? empresa.habilitado : null}
                          style={{ marginLeft: "1vw" }}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              habilitado: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Habilitado"
                      labelPlacement="start"
                      style={{ paddingLeft: ".7vw" }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h6"
                      style={{ textDecoration: "underline", color: "coral" }}
                    >
                      Mercado Pago
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={3} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={empresa ? empresa.mp : null}
                          style={{ marginLeft: "1vw" }}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              mp: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Utiliza Mercado Pago"
                      labelPlacement="start"
                      style={{ paddingLeft: ".7vw" }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4.5} style={{ marginTop: "-1.5vw" }}>
                    <Autocomplete
                      id="cuentaMp"
                      disabled={empresa && !empresa.mp}
                      fullWidth
                      options={
                        cuentas && selectedRow
                          ? cuentas.filter(
                              (item) =>
                                item.id_empresa === selectedRow.id &&
                                selectedRow.habilitado
                            )
                          : null
                      }
                      disablePortal
                      defaultValue={
                        cuentas && selectedRow
                          ? cuentas[
                              cuentas.findIndex(
                                (item) => item.id === selectedRow.cuentaIdMp
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Cuenta MP *"
                          size="small"
                        />
                      )}
                      onChange={(event, value) => {
                        if (value) {
                          guardarEmpresa({
                            ...empresa,
                            cuentaIdMp: value.id,
                          });
                        } else {
                          guardarEmpresa({
                            ...empresa,
                            cuentaIdMp: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4.5} style={{ marginTop: "-1.5vw" }}>
                    <Autocomplete
                      id="cobradorMp"
                      disabled={empresa && !empresa.mp}
                      fullWidth
                      options={
                        cobradores
                          ? cobradores.filter((item) => item.habilitado)
                          : null
                      }
                      disablePortal
                      defaultValue={
                        cobradores && selectedRow
                          ? cobradores[
                              cobradores.findIndex(
                                (item) => item.id === selectedRow.cobradorIdMp
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Cobrador MP *"
                          size="small"
                        />
                      )}
                      onChange={(event, value) => {
                        if (value) {
                          guardarEmpresa({
                            ...empresa,
                            cobradorIdMp: value.id,
                          });
                        } else {
                          guardarEmpresa({
                            ...empresa,
                            cobradorIdMp: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h6"
                      style={{ textDecoration: "underline", color: "coral" }}
                    >
                      Próximos Números de Comprobantes
                    </Typography>
                  </Grid>

                  {empresa &&
                  empresa.id_tipocontribuyente !== undefined &&
                  empresa.id_tipocontribuyente !== null &&
                  empresa.id_tipocontribuyente === 6 ? ( // RESP. INSC.
                    <Fragment>
                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">P. Venta Factura A</label>
                        <InputNumber
                          id="faPv"
                          value={
                            empresa &&
                            empresa.faPv !== null &&
                            empresa.faPv !== 0
                              ? empresa.faPv
                              : 1
                          }
                          size={2}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              faPv: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">Número Factura A</label>
                        <InputNumber
                          id="faNu"
                          value={
                            empresa &&
                            empresa.faNu !== null &&
                            empresa.faNu !== 0
                              ? empresa.faNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              faNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={(e) =>
                            handleClickOpenAfip(empresa.cuit, empresa.faPv, 1)
                          }
                          endIcon={<SearchIcon />}
                        >
                          {empresa.facturaElectronica ? "Afip" : "Sistema"}
                        </Button>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">P. Venta Notas de Débito A</label>
                        <InputNumber
                          id="ndaPv"
                          value={
                            empresa &&
                            empresa.ndaPv !== null &&
                            empresa.ndaPv !== 0
                              ? empresa.ndaPv
                              : 1
                          }
                          size={2}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ndaPv: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">Número Notas de Débito A</label>
                        <InputNumber
                          id="ndaNu"
                          value={
                            empresa &&
                            empresa.ndaNu !== null &&
                            empresa.ndaNu !== 0
                              ? empresa.ndaNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ndaNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={(e) =>
                            handleClickOpenAfip(empresa.cuit, empresa.ndaPv, 2)
                          }
                          endIcon={<SearchIcon />}
                        >
                                                    {empresa.facturaElectronica ? "Afip" : "Sistema"}

                        </Button>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">P. Venta Notas de Crédito A</label>
                        <InputNumber
                          id="ncaPv"
                          value={
                            empresa &&
                            empresa.ncaPv !== null &&
                            empresa.ncaPv !== 0
                              ? empresa.ncaPv
                              : 1
                          }
                          size={2}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ncaPv: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">Número Notas de Crédito A</label>
                        <InputNumber
                          id="ncaNu"
                          value={
                            empresa &&
                            empresa.ncaNu !== null &&
                            empresa.ncaNu !== 0
                              ? empresa.ncaNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ncaNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={(e) =>
                            handleClickOpenAfip(empresa.cuit, empresa.ncaPv, 3)
                          }
                          endIcon={<SearchIcon />}
                        >
                                                    {empresa.facturaElectronica ? "Afip" : "Sistema"}

                        </Button>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">P. Venta Facturas B</label>
                        <InputNumber
                          id="fbPv"
                          value={
                            empresa &&
                            empresa.fbPv !== null &&
                            empresa.fbPv !== 0
                              ? empresa.fbPv
                              : 1
                          }
                          size={2}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              fbPv: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">Número Factura B</label>
                        <InputNumber
                          id="fbNu"
                          value={
                            empresa &&
                            empresa.fbNu !== null &&
                            empresa.fbNu !== 0
                              ? empresa.fbNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              fbNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={(e) =>
                            handleClickOpenAfip(empresa.cuit, empresa.fbPv, 6)
                          }
                          endIcon={<SearchIcon />}
                        >
                                                    {empresa.facturaElectronica ? "Afip" : "Sistema"}

                        </Button>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">P. Venta Notas de Débito B</label>
                        <InputNumber
                          id="ndbPv"
                          value={
                            empresa &&
                            empresa.ndbPv !== null &&
                            empresa.ndbPv !== 0
                              ? empresa.ndbPv
                              : 1
                          }
                          size={2}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ndbPv: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">Número Notas de Débito B</label>
                        <InputNumber
                          id="ndbNu"
                          value={
                            empresa &&
                            empresa.ndbNu !== null &&
                            empresa.ndbNu !== 0
                              ? empresa.ndbNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ndbNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={(e) =>
                            handleClickOpenAfip(empresa.cuit, empresa.ndbPv, 7)
                          }
                          endIcon={<SearchIcon />}
                        >
                                                    {empresa.facturaElectronica ? "Afip" : "Sistema"}

                        </Button>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">P. Venta Notas de Crédito B</label>
                        <InputNumber
                          id="ncbPv"
                          value={
                            empresa &&
                            empresa.ncbPv !== null &&
                            empresa.ncbPv !== 0
                              ? empresa.ncbPv
                              : 1
                          }
                          size={2}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ncbPv: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">Número Notas de Crédito B</label>
                        <InputNumber
                          id="ncbNu"
                          value={
                            empresa &&
                            empresa.ncbNu !== null &&
                            empresa.ncbNu !== 0
                              ? empresa.ncbNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ncbNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={(e) =>
                            handleClickOpenAfip(empresa.cuit, empresa.ndbPv, 8)
                          }
                          endIcon={<SearchIcon />}
                        >
                                                    {empresa.facturaElectronica ? "Afip" : "Sistema"}

                        </Button>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>
                    </Fragment>
                  ) : null}

                  {empresa &&
                  empresa.id_tipocontribuyente !== undefined &&
                  empresa.id_tipocontribuyente !== null &&
                  empresa.id_tipocontribuyente === 2 ? ( // EXENTO
                    <Fragment>
                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">P. Venta Facturas C</label>
                        <InputNumber
                          id="fcPv"
                          value={
                            empresa &&
                            empresa.fcPv !== null &&
                            empresa.fcPv !== 0
                              ? empresa.fcPv
                              : 1
                          }
                          size={2}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              fcPv: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">Número Factura C</label>
                        <InputNumber
                          id="fcNu"
                          value={
                            empresa &&
                            empresa.fcNu !== null &&
                            empresa.fcNu !== 0
                              ? empresa.fcNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              fcNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={(e) =>
                            handleClickOpenAfip(empresa.cuit, empresa.fcPv, 11)
                          }
                          endIcon={<SearchIcon />}
                        >
                                                    {empresa.facturaElectronica ? "Afip" : "Sistema"}

                        </Button>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">P. Venta Notas de Débito C</label>
                        <InputNumber
                          id="ndcPv"
                          value={
                            empresa &&
                            empresa.ndcPv !== null &&
                            empresa.ndcPv !== 0
                              ? empresa.ndcPv
                              : 1
                          }
                          size={2}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ndcPv: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">Número Notas de Débito C</label>
                        <InputNumber
                          id="ndcNu"
                          value={
                            empresa &&
                            empresa.ndcNu !== null &&
                            empresa.ndcNu !== 0
                              ? empresa.ndcNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ndcNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={(e) =>
                            handleClickOpenAfip(empresa.cuit, empresa.ndcPv, 12)
                          }
                          endIcon={<SearchIcon />}
                        >
                                                    {empresa.facturaElectronica ? "Afip" : "Sistema"}

                        </Button>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">P. Venta Notas de Crédito C</label>
                        <InputNumber
                          id="nccPv"
                          value={
                            empresa &&
                            empresa.nccPv !== null &&
                            empresa.nccPv !== 0
                              ? empresa.nccPv
                              : 1
                          }
                          size={2}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              nccPv: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">Número Notas de Crédito C</label>
                        <InputNumber
                          id="nccNu"
                          value={
                            empresa &&
                            empresa.nccNu !== null &&
                            empresa.nccNu !== 0
                              ? empresa.nccNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              nccNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={(e) =>
                            handleClickOpenAfip(empresa.cuit, empresa.nccPv, 13)
                          }
                          endIcon={<SearchIcon />}
                        >
                                                    {empresa.facturaElectronica ? "Afip" : "Sistema"}

                        </Button>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>
                    </Fragment>
                  ) : null}

                  {empresa &&
                  empresa.id_tipocontribuyente !== undefined &&
                  empresa.id_tipocontribuyente !== null &&
                  empresa.id_tipocontribuyente === 6 ? ( // RESP. INSC.
                    <Fragment>
                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">P. Venta Facturas M</label>
                        <InputNumber
                          id="fmPv"
                          value={
                            empresa &&
                            empresa.fmPv !== null &&
                            empresa.fmPv !== 0
                              ? empresa.fmPv
                              : 1
                          }
                          size={2}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              fmPv: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">Número Factura M</label>
                        <InputNumber
                          id="fmNu"
                          value={
                            empresa &&
                            empresa.fmNu !== null &&
                            empresa.fmNu !== 0
                              ? empresa.fmNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              fmNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={(e) =>
                            handleClickOpenAfip(empresa.cuit, empresa.fmPv, 51)
                          }
                          endIcon={<SearchIcon />}
                        >
                                                    {empresa.facturaElectronica ? "Afip" : "Sistema"}

                        </Button>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">P. Venta Notas de Débito M</label>
                        <InputNumber
                          id="ndmPv"
                          value={
                            empresa &&
                            empresa.ndmPv !== null &&
                            empresa.ndmPv !== 0
                              ? empresa.ndmPv
                              : 1
                          }
                          size={2}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ndmPv: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">Número Notas de Débito M</label>
                        <InputNumber
                          id="ndmNu"
                          value={
                            empresa &&
                            empresa.ndmNu !== null &&
                            empresa.ndmNu !== 0
                              ? empresa.ndmNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ndmNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={(e) =>
                            handleClickOpenAfip(empresa.cuit, empresa.ndmPv, 52)
                          }
                          endIcon={<SearchIcon />}
                        >
                                                    {empresa.facturaElectronica ? "Afip" : "Sistema"}

                        </Button>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">P. Venta Notas de Crédito M</label>
                        <InputNumber
                          id="ncmPv"
                          value={
                            empresa &&
                            empresa.ncmPv !== null &&
                            empresa.ncmPv !== 0
                              ? empresa.ncmPv
                              : 1
                          }
                          size={2}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ncmPv: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">Número Notas de Crédito M</label>
                        <InputNumber
                          id="ncmNu"
                          value={
                            empresa &&
                            empresa.ncmNu !== null &&
                            empresa.ncmNu !== 0
                              ? empresa.ncmNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ncmNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={(e) =>
                            handleClickOpenAfip(empresa.cuit, empresa.ncmPv, 53)
                          }
                          endIcon={<SearchIcon />}
                        >
                                                    {empresa.facturaElectronica ? "Afip" : "Sistema"}

                        </Button>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">
                          P. Venta Fact. de Crédito Electrónica A
                        </label>
                        <InputNumber
                          id="fceaPv"
                          value={
                            empresa &&
                            empresa.fceaPv !== null &&
                            empresa.fceaPv !== 0
                              ? empresa.fceaPv
                              : 1
                          }
                          size={2}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              fceaPv: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">
                          Número Fact. de Crédito Electrónica A
                        </label>
                        <InputNumber
                          id="fceaNu"
                          value={
                            empresa &&
                            empresa.fceaNu !== null &&
                            empresa.fceaNu !== 0
                              ? empresa.fceaNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              fceaNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={(e) =>
                            handleClickOpenAfip(
                              empresa.cuit,
                              empresa.fceaPv,
                              201
                            )
                          }
                          endIcon={<SearchIcon />}
                        >
                                                    {empresa.facturaElectronica ? "Afip" : "Sistema"}

                        </Button>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">
                          P. Venta Notas de Débito Electrónica A
                        </label>
                        <InputNumber
                          id="ndeaPv"
                          value={
                            empresa &&
                            empresa.ndeaPv !== null &&
                            empresa.ndeaPv !== 0
                              ? empresa.ndeaPv
                              : 1
                          }
                          size={2}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ndeaPv: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">
                          Número Notas de Débito Electrónica A
                        </label>
                        <InputNumber
                          id="ndeaNu"
                          value={
                            empresa &&
                            empresa.ndeaNu !== null &&
                            empresa.ndeaNu !== 0
                              ? empresa.ndeaNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ndeaNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={(e) =>
                            handleClickOpenAfip(
                              empresa.cuit,
                              empresa.ndeaPv,
                              202
                            )
                          }
                          endIcon={<SearchIcon />}
                        >
                                                    {empresa.facturaElectronica ? "Afip" : "Sistema"}

                        </Button>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">
                          P. Venta Notas de Crédito Electrónica A
                        </label>
                        <InputNumber
                          id="nceaPv"
                          value={
                            empresa &&
                            empresa.nceaPv !== null &&
                            empresa.nceaPv !== 0
                              ? empresa.nceaPv
                              : 1
                          }
                          size={2}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              nceaPv: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">
                          Número Notas de Crédito Electrónica A
                        </label>
                        <InputNumber
                          id="nceaNu"
                          value={
                            empresa &&
                            empresa.nceaNu !== null &&
                            empresa.nceaNu !== 0
                              ? empresa.nceaNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              nceaNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={(e) =>
                            handleClickOpenAfip(
                              empresa.cuit,
                              empresa.nceaPv,
                              203
                            )
                          }
                          endIcon={<SearchIcon />}
                        >
                                                    {empresa.facturaElectronica ? "Afip" : "Sistema"}

                        </Button>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">
                          P. Venta Fact. de Crédito Electrónica B
                        </label>
                        <InputNumber
                          id="fcebPv"
                          value={
                            empresa &&
                            empresa.fcebPv !== null &&
                            empresa.fcebPv !== 0
                              ? empresa.fcebPv
                              : 1
                          }
                          size={2}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              fcebPv: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">
                          Número Fact. de Crédito Electrónica B
                        </label>
                        <InputNumber
                          id="fcebNu"
                          value={
                            empresa &&
                            empresa.fcebNu !== null &&
                            empresa.fcebNu !== 0
                              ? empresa.fcebNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              fcebNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={(e) =>
                            handleClickOpenAfip(
                              empresa.cuit,
                              empresa.fcebPv,
                              206
                            )
                          }
                          endIcon={<SearchIcon />}
                        >
                                                    {empresa.facturaElectronica ? "Afip" : "Sistema"}

                        </Button>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">
                          P. Venta Notas de Débito Electrónica B
                        </label>
                        <InputNumber
                          id="ndebPv"
                          value={
                            empresa &&
                            empresa.ndebPv !== null &&
                            empresa.ndebPv !== 0
                              ? empresa.ndebPv
                              : 1
                          }
                          size={2}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ndebPv: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">
                          Número Notas de Débito Electrónica B
                        </label>
                        <InputNumber
                          id="ndebNu"
                          value={
                            empresa &&
                            empresa.ndebNu !== null &&
                            empresa.ndebNu !== 0
                              ? empresa.ndebNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ndebNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={(e) =>
                            handleClickOpenAfip(
                              empresa.cuit,
                              empresa.ndebPv,
                              207
                            )
                          }
                          endIcon={<SearchIcon />}
                        >
                                                    {empresa.facturaElectronica ? "Afip" : "Sistema"}

                        </Button>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">
                          P. Venta Notas de Crédito Electrónica B
                        </label>
                        <InputNumber
                          id="ncebPv"
                          value={
                            empresa &&
                            empresa.ncebPv !== null &&
                            empresa.ncebPv !== 0
                              ? empresa.ncebPv
                              : 1
                          }
                          size={2}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ncebPv: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">
                          Número Notas de Crédito Electrónica B
                        </label>
                        <InputNumber
                          id="ncebNu"
                          value={
                            empresa &&
                            empresa.ncebNu !== null &&
                            empresa.ncebNu !== 0
                              ? empresa.ncebNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ncebNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={(e) =>
                            handleClickOpenAfip(
                              empresa.cuit,
                              empresa.ncebPv,
                              208
                            )
                          }
                          endIcon={<SearchIcon />}
                        >
                                                    {empresa.facturaElectronica ? "Afip" : "Sistema"}

                        </Button>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">Número Ajustes Débito</label>
                        <InputNumber
                          id="ajdxNu"
                          value={
                            empresa &&
                            empresa.ajdxNu !== null &&
                            empresa.ajdxNu !== 0
                              ? empresa.ajdxNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ajdxNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">Número Ajustes Crédito</label>
                        <InputNumber
                          id="ajcxNu"
                          value={
                            empresa &&
                            empresa.ajcxNu !== null &&
                            empresa.ajcxNu !== 0
                              ? empresa.ajcxNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ajcxNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">A Cuenta</label>
                        <InputNumber
                          id="acxNu"
                          value={
                            empresa &&
                            empresa.acxNu !== null &&
                            empresa.acxNu !== 0
                              ? empresa.acxNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              acxNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>
                    </Fragment>
                  ) : null}

                  {empresa &&
                  empresa.id_tipocontribuyente !== undefined &&
                  empresa.id_tipocontribuyente !== null &&
                  empresa.id_tipocontribuyente === 2 ? ( // EXENTO
                    <Fragment>
                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">
                          P. Venta Fact. de Crédito Electrónica C
                        </label>
                        <InputNumber
                          id="fcecPv"
                          value={
                            empresa &&
                            empresa.fcecPv !== null &&
                            empresa.fcecPv !== 0
                              ? empresa.fcecPv
                              : 1
                          }
                          size={2}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              fcecPv: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">
                          Número Fact. de Crédito Electrónica C
                        </label>
                        <InputNumber
                          id="fcecNu"
                          value={
                            empresa &&
                            empresa.fcecNu !== null &&
                            empresa.fcecNu !== 0
                              ? empresa.fcecNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              fcecNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={(e) =>
                            handleClickOpenAfip(
                              empresa.cuit,
                              empresa.fcecPv,
                              211
                            )
                          }
                          endIcon={<SearchIcon />}
                        >
                                                    {empresa.facturaElectronica ? "Afip" : "Sistema"}

                        </Button>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">
                          P. Venta Notas de Débito Electrónica C
                        </label>
                        <InputNumber
                          id="ndecPv"
                          value={
                            empresa &&
                            empresa.ndecPv !== null &&
                            empresa.ndecPv !== 0
                              ? empresa.ndecPv
                              : 1
                          }
                          size={2}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ndecPv: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">
                          Número Notas de Débito Electrónica C
                        </label>
                        <InputNumber
                          id="ndecNu"
                          value={
                            empresa &&
                            empresa.ndecNu !== null &&
                            empresa.ndecNu !== 0
                              ? empresa.ndecNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ndecNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={(e) =>
                            handleClickOpenAfip(
                              empresa.cuit,
                              empresa.ndecPv,
                              212
                            )
                          }
                          endIcon={<SearchIcon />}
                        >
                                                    {empresa.facturaElectronica ? "Afip" : "Sistema"}

                        </Button>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">
                          P. Venta Notas de Crédito Electrónica C
                        </label>
                        <InputNumber
                          id="ncecPv"
                          value={
                            empresa &&
                            empresa.ncecPv !== null &&
                            empresa.ncecPv !== 0
                              ? empresa.ncecPv
                              : 1
                          }
                          size={2}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ncecPv: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">
                          Número Notas de Crédito Electrónica C
                        </label>
                        <InputNumber
                          id="ncecNu"
                          value={
                            empresa &&
                            empresa.ncecNu !== null &&
                            empresa.ncecNu !== 0
                              ? empresa.ncecNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ncecNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={(e) =>
                            handleClickOpenAfip(
                              empresa.cuit,
                              empresa.ncecPv,
                              213
                            )
                          }
                          endIcon={<SearchIcon />}
                        >
                                                    {empresa.facturaElectronica ? "Afip" : "Sistema"}

                        </Button>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">Número Ajustes Débito</label>
                        <InputNumber
                          id="ajdxNu"
                          value={
                            empresa &&
                            empresa.ajdxNu !== null &&
                            empresa.ajdxNu !== 0
                              ? empresa.ajdxNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ajdxNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">Número Ajustes Crédito</label>
                        <InputNumber
                          id="ajcxNu"
                          value={
                            empresa &&
                            empresa.ajcxNu !== null &&
                            empresa.ajcxNu !== 0
                              ? empresa.ajcxNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              ajcxNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <label htmlFor="id">A Cuenta</label>
                        <InputNumber
                          id="acxNu"
                          value={
                            empresa &&
                            empresa.acxNu !== null &&
                            empresa.acxNu !== 0
                              ? empresa.acxNu
                              : 1
                          }
                          size={8}
                          onChange={(e) =>
                            guardarEmpresa({
                              ...empresa,
                              acxNu: e.value,
                            })
                          }
                          inputStyle={{ textAlign: "right" }}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <hr />
                      </Grid>
                    </Fragment>
                  ) : null}
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

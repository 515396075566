import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "../Menu";
import { InputNumber } from "primereact/inputnumber";
import { jsPDF } from "jspdf";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";

export default function ProcesosDebitosAutomaticos(props) {
  const exportColumns = [
    { title: "Socio Nº", dataKey: "id_titular" },
    { title: "Nombre", dataKey: "nombre_razonsocial" },
    { title: "Tarjeta", dataKey: "numerotarjeta" },
    { title: "Importe", dataKey: "cuota" },
  ];

  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [visible, setVisible] = useState(false);
  const [visibleProceso, setVisibleProceso] = useState(true);
  const [visibleTarjetas, setVisibleTarjetas] = useState(false);
  const [todosLosCobradores, setTodosLosCobradores] = useState(false);
  const [repetirPeriodo, setRepetirPeriodo] = useState(false);
  const [proceso, guardarProceso] = useState(null);
  const [importeTarjeta, guardarImporteTarjeta] = useState(null);
  const [bandPrevio, setBandPrevio] = useState(false);
  const [valor, guardarValor] = useState("cuota");
  const [fijo, setFijo] = useState(null);
  const toast = useRef(null);
  const acCobrador = useRef(null);

  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    cobradores,
    statusAcceso,
    empresaElegida,
    listadoExcel,
    totalTarjetas,
    empresas,
    formasPagos,
    configuracion,
    obtenerAcceso,
    tiposComprobantes,
    procesos,
    obtenerModulos,
    obtenerCobradores,
    obtenerEmpresas,
    procesoDebitosAutomaticos,
    procesoDebitosAutomaticosPrevios,
    obtenerFormasPagos,
    guardarTotalTarjetas,
    guardarProcesos,
    generarPrevio,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "ProcesosDebitosAutomaticos",
      });
    }
  }, [state.id]);

  useEffect(() => {
    obtenerEmpresas();
  }, [empresaElegida]);

  useEffect(() => {
    obtenerFormasPagos();
  }, []);

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setSelectedRow(null);
    setVisible(false);
  };

  const onHideProceso = (e) => {
    setVisibleProceso(false);
  };

  const onHideTarjetas = (e) => {
    setVisibleTarjetas(false);
    guardarProcesos([]);
  };

  const aceptarProceso = async (e) => {
    e.preventDefault();
    //Validar

    if (
      !proceso ||
      proceso.periodo === undefined ||
      proceso.periodo === null ||
      proceso.periodo === 0 ||
      proceso.periodo === ""
    ) {
      mensajeAlerta("Procesos", "El período es necesario !.", "error");
      return;
    }

    if (
      !proceso ||
      proceso.id_formaPago === undefined ||
      proceso.id_formaPago === null ||
      proceso.id_formaPago === 0 ||
      proceso.id_formaPago === ""
    ) {
      mensajeAlerta("Procesos", "La forma de pago es necesaria !.", "error");
      return;
    }

    await procesoDebitosAutomaticosPrevios({
      proceso,
      empresaElegida,
      usuario: state.usuario.usuario,
    }).then((data) => {
      let elem = document.getElementById("confirmar");
      elem.click();
    });
  };

  const confirmar = () => {
    if (procesos && procesos.length === 0) {
      confirm1();
    } else {
      confirm2();
    }
  };

  const confirm1 = () => {
    confirmDialog({
      message: "Esta seguro de iniciar nuevo proceso",
      header: "Confirmación",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-success",
      closable: false,
      acceptLabel: "Si",
      rejectLabel: "No",
      accept: accept1,
    });
  };

  const accept1 = async () => {
    await procesoDebitosAutomaticos({
      proceso,
      empresaElegida,
      usuario: state.usuario.usuario,
    }).then((data) => {
      setVisibleTarjetas(true);
      setBandPrevio(false);
    });
  };

  const confirm2 = () => {
    confirmDialog({
      message: "Ya existe un archivo previo generado.",
      header: "Confirmación",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-success",
      closable: false,
      acceptLabel: "Abrir archivo generado",
      rejectLabel: "Generar archivo nuevo",
      accept: accept2,
      reject: accept1,
    });
  };

  const accept2 = async () => {
    setVisibleTarjetas(true);
    setBandPrevio(true);
  };

  const editarItems = (rowData) => {
    let index = procesos.findIndex(
      (item) => item.id_titular === rowData.id_titular
    );

    setSelectedRow(procesos[index]);
    guardarImporteTarjeta(procesos[index].cuota);
    setVisible(true);
  };

  const aceptarClick = () => {
    let index = procesos.findIndex(
      (item) => item.id_titular === selectedRow.id_titular
    );

    procesos[index].cuota = parseFloat(importeTarjeta).toFixed(2);

    let total = 0;

    for (var i = 0; i < procesos.length; i++) {
      total = total + Number(procesos[i].cuota);
    }

    guardarTotalTarjetas(total);

    setVisible(false);
    setSelectedRow(null);
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Proceso de Impresión",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const renderHeaderProceso = () => {
    return (
      <div>
        <Tag
          value={
            empresas && empresas.length > 0
              ? "Débitos Automáticos " + empresaElegida.nombre
              : null
          }
        ></Tag>
      </div>
    );
  };

  const renderFooterProceso = () => {
    return (
      <div>
        <ButtonMui
          onClick={(e) => aceptarProceso(e)}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
          autoFocus
        >
          Aceptar
        </ButtonMui>

        <ButtonMui
          onClick={onHideProceso}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderFooterTarjetas = () => {
    return (
      <div>
        <ButtonMui
          onClick={generarPrevioClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
          autoFocus
        >
          Generar Previo
        </ButtonMui>

        <ButtonMui
          onClick={onHideTarjetas}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const fechaTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fecha)}</Fragment>;
  };

  const anuladoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.anulado}></Checkbox>
      </Fragment>
    );
  };

  const opcionesTemplate = (rowData) => {
    return (
      <Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          text
          severity="success"
          onClick={(e) => editarItems(rowData)}
          aria-label="Bookmark"
          tooltip="Editar"
          tooltipOptions={{ position: "bottom" }}
        />
      </Fragment>
    );
  };

  function formatDate2(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  function formatDateReves(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[2],
      month = datePart[1],
      day = datePart[0];
    return year + month + day;
  }

  function formatDateIso(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[0], thisDate[1], thisDate[2]].join("");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate;
  }

  const exportTxt = () => {
    let hoyFecha = new Date().toLocaleDateString();
    let hoyHora = new Date().toLocaleTimeString();

    hoyHora = hoyHora.substring(0, 2) + hoyHora.substring(3, 5);

    if (proceso && proceso.id_formaPago === 3) {
      // VISA

      let xEstable = null;

      if (empresaElegida.id === 4) {
        xEstable = "0019508134";
      } else {
        xEstable = "0043363373";
      }

      let blobVisa = new Blob([], { type: "text/plain;charset=utf-8" });

      blobVisa = new Blob(
        [
          blobVisa,
          "0" +
            "DEBLIQC " +
            xEstable +
            "900000    " +
            formatDateReves(hoyFecha) +
            hoyHora +
            "0" +
            " ".repeat(2) +
            " ".repeat(55) +
            "*" +
            "\n",
        ],
        { type: "text/plain;charset=utf-8" }
      );

      let xtotal = 0;
      let xParteEntera = null;
      let xParteDecimal = null;

      var result = [];
      procesos.reduce(function (res, value) {
        if (!res[value.numerotarjeta]) {
          res[value.numerotarjeta] = {
            numerotarjeta: value.numerotarjeta,
            id_titular: value.id_titular,
            nombre_razonsocial: value.nombre_razonsocial,
            cbu: value.cbu,
            id: value.id,
            cuota: 0,
          };
          result.push(res[value.numerotarjeta]);
        }
        res[value.numerotarjeta].cuota += Number(value.cuota);
        return res;
      }, {});

      for (var i = 0; i < result.length; i++) {
        xtotal = xtotal + result[i].cuota;

        let numTarjeta = result[i].numerotarjeta.replace(/[-]/g, "");

        xParteEntera = String(
          Math.trunc(Math.abs(Number(result[i].cuota)))
        ).padStart(13, "0");
        xParteDecimal = String(
          parseFloat(Number(result[i].cuota)).toFixed(2)
        ).slice(-2);

        blobVisa = new Blob(
          [
            blobVisa,
            "1" +
              numTarjeta +
              " ".repeat(3) +
              String(result[i].id).padStart(8, "0") +
              formatDateReves(hoyFecha) +
              "0005" +
              xParteEntera +
              xParteDecimal +
              "0".repeat(7) +
              result[i].id_titular +
              " " +
              "  " +
              " ".repeat(26) +
              "*" +
              "\n",
          ],
          { type: "text/plain;charset=utf-8" }
        );
      }
      xParteEntera = String(Math.trunc(Math.abs(Number(xtotal)))).padStart(
        13,
        "0"
      );
      xParteDecimal = String(parseFloat(Number(xtotal)).toFixed(2)).slice(-2);

      blobVisa = new Blob(
        [
          blobVisa,
          "9" +
            "DEBLIQC " +
            xEstable +
            "900000    " +
            formatDateReves(hoyFecha) +
            hoyHora +
            String(result.length).padStart(7, "0") +
            xParteEntera +
            xParteDecimal +
            " ".repeat(36) +
            "*" +
            "\n",
        ],
        { type: "text/plain;charset=utf-8" }
      );

      saveAs(blobVisa, "DEBLIQC.txt");
    }

    if (proceso && proceso.id_formaPago === 4) {
      // MASTER


      let xtotal = 0;
      let xParteEntera = null;
      let xParteDecimal = null;

      var result = [];
      procesos.reduce(function (res, value) {
        if (!res[value.numerotarjeta]) {
          res[value.numerotarjeta] = {
            numerotarjeta: value.numerotarjeta,
            id_titular: value.id_titular,
            nombre_razonsocial: value.nombre_razonsocial,
            cbu: value.cbu,
            id: value.id,
            cuota: 0,
          };
          result.push(res[value.numerotarjeta]);
        }
        res[value.numerotarjeta].cuota += Number(value.cuota);
        return res;
      }, {});

      for (var i = 0; i < result.length; i++) {
        xtotal = xtotal + result[i].cuota;
      }

      xParteEntera = String(Math.trunc(Math.abs(Number(xtotal)))).padStart(
        13,
        "0"
      );
      xParteDecimal = String(parseFloat(Number(xtotal)).toFixed(2)).slice(-2);

      let blobMaster = new Blob([], { type: "text/plain;charset=utf-8" });

      blobMaster = new Blob(
        [
          blobMaster,
          "13465631120" +
            proceso.periodo.substring(0, 2) +
            proceso.periodo.substring(3, 5) +
            String(result.length).padStart(7, "0") +
            xParteEntera +
            xParteDecimal +
            " ".repeat(92) +
            "\n",
        ],
        { type: "text/plain;charset=utf-8" }
      );

      for (var i = 0; i < result.length; i++) {
        xtotal = xtotal + result[i].cuota;

        let numTarjeta = result[i].numerotarjeta.replace(/[-]/g, "");

        xParteEntera = String(
          Math.trunc(Math.abs(Number(result[i].cuota)))
        ).padStart(9, "0");
        xParteDecimal = String(
          parseFloat(Number(result[i].cuota)).toFixed(2)
        ).slice(-2);

        blobMaster = new Blob(
          [
            blobMaster,
            "134656312" +
              numTarjeta +
              "0".repeat(4) +
              result[i].id_titular +
              "00199901" +
              xParteEntera +
              xParteDecimal +
              proceso.periodo +
              " ".repeat(68) +
              "\n",
          ],
          { type: "text/plain;charset=utf-8" }
        );
      }

      saveAs(blobMaster, "DA168D.TXT");
    }

    /*
    if (proceso && proceso.id_formaPago === 4) {
      // MASTER

      let blobMaster = new Blob([], { type: "text/plain;charset=utf-8" });

      blobMaster = new Blob(
        [
          blobMaster,
          "1" +
            "DEBLIMC " +
            "0043363381" +
            "900000    " +
            formatDateReves(hoyFecha) +
            hoyHora +
            "0" +
            " ".repeat(2) +
            " ".repeat(55) +
            "*" +
            "\n",
        ],
        { type: "text/plain;charset=utf-8" }
      );

      let xtotal = 0;
      let xParteEntera = null;
      let xParteDecimal = null;

      var result = [];
      procesos.reduce(function (res, value) {
        if (!res[value.numerotarjeta]) {
          res[value.numerotarjeta] = {
            numerotarjeta: value.numerotarjeta,
            id_titular: value.id_titular,
            nombre_razonsocial: value.nombre_razonsocial,
            cbu: value.cbu,
            id: value.id,
            cuota: 0,
          };
          result.push(res[value.numerotarjeta]);
        }
        res[value.numerotarjeta].cuota += Number(value.cuota);
        return res;
      }, {});

      for (var i = 0; i < result.length; i++) {
        xtotal = xtotal + result[i].cuota;

        let numTarjeta = result[i].numerotarjeta.replace(/[-]/g, "");

        xParteEntera = String(
          Math.trunc(Math.abs(Number(result[i].cuota)))
        ).padStart(13, "0");
        xParteDecimal = String(
          parseFloat(Number(result[i].cuota)).toFixed(2)
        ).slice(-2);

        blobMaster = new Blob(
          [
            blobMaster,
            "1" +
              numTarjeta +
              " ".repeat(3) +
              String(result[i].id).padStart(8, "0") +
              formatDateReves(hoyFecha) +
              "0005" +
              xParteEntera +
              xParteDecimal +
              "0".repeat(7) +
              result[i].id_titular +
              " " +
              "  " +
              " ".repeat(26) +
              "*" +
              "\n",
          ],
          { type: "text/plain;charset=utf-8" }
        );
      }
      xParteEntera = String(Math.trunc(Math.abs(Number(xtotal)))).padStart(
        13,
        "0"
      );
      xParteDecimal = String(parseFloat(Number(xtotal)).toFixed(2)).slice(-2);

      blobMaster = new Blob(
        [
          blobMaster,
          "9" +
            "DEBLIMC " +
            "0043363381" +
            "900000    " +
            formatDateReves(hoyFecha) +
            hoyHora +
            String(result.length).padStart(7, "0") +
            xParteEntera +
            xParteDecimal +
            " ".repeat(36) +
            "*" +
            "\n",
        ],
        { type: "text/plain;charset=utf-8" }
      );

      saveAs(blobMaster, "DEBLIMC.txt");
    }
    */
  };

  const generarPrevioClick = async () => {
    generarPrevio({
      proceso,
      procesos,
      empresaElegida,
      usuario: state.usuario.usuario,
    });
    //setVisibleTarjetas(false);
    setBandPrevio(true);
    aceptarTarjetas();
  };

  const aceptarTarjetas = async () => {
    if (!procesos || procesos.length === 0) {
      return;
    }

    const doc = new jsPDF();

    let hoy = new Date().toLocaleDateString();

    let empresa = empresas.find((item) => item.id === empresaElegida.id);

    let row = 10;
    doc.setFontSize(11);
    doc.text("LISTADO PREVIO DE TARJETAS ", 70, row);
    doc.setFontSize(10);
    row = 20;
    doc.text("Empresa: " + empresa.nombre, 10, row);
    doc.text("Tarjeta: " + proceso.formaPago, 80, row);
    doc.text("Fecha: " + hoy, 150, row);
    doc.setFontSize(9);
    row = 30;

    doc.autoTable(exportColumns, procesos, {
      margin: { top: 30, left: 10 },
      styles: {
        fontSize: 9,
      },
      columnStyles: {
        3: {
          halign: "right",
        },
      },
      didParseCell: (data) => {
        if (data.cell && data.cell.section === "head") {
          switch (data.cell.raw) {
            case "Importe":
              data.cell.styles.halign = "right";
              break;
            default:
              data.cell.styles.halign = "left";
              break;
          }
        }
      },
    });

    let finalY = doc.previousAutoTable.finalY;

    row = finalY + 10;

    doc.text("Total: " + parseFloat(totalTarjetas).toFixed(2), 174, row);

    window.open(doc.output("bloburl"));
  };

  //console.log(procesos);
  //console.log(serviciosFinalizadosExcel);

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file"
          onClick={(e) => exportTxt()}
          disabled={!bandPrevio}
          className="p-button-success p-mr-2"
          data-pr-tooltip="TXT"
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag
          value={
            proceso && proceso.formaPago
              ? "Débitos Automáticos " + proceso.formaPago
              : "Débitos Automáticos"
          }
        ></Tag>

        <Tag
          value={
            totalTarjetas ? "Total " + parseFloat(totalTarjetas).toFixed(2) : ""
          }
          severity={"danger"}
        ></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag
          value={
            proceso && proceso.formaPago
              ? "Débitos Automáticos " + proceso.formaPago
              : "Débitos Automáticos"
          }
        ></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      <ConfirmDialog />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <button
            id="confirmar"
            type="button"
            onClick={(e) => {
              confirmar();
            }}
            style={{ display: "none" }}
          >
            Confirmar
          </button>

          <Dialog
            header={renderHeaderProceso()}
            visible={visibleProceso}
            closable={false}
            footer={renderFooterProceso()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "70vw", paddingBottom: "0" }}
            position="top"
          >
            <Grid container spacing={3} style={{ marginTop: "1vw" }}>
              <Grid item xs={12} md={3}>
                <label htmlFor="periodo">Período</label>
                <InputMask
                  id="periodo"
                  mask="99/99"
                  value={proceso ? proceso.periodo : null}
                  placeholder="99/99"
                  size={4}
                  onChange={(e) =>
                    guardarProceso({
                      ...proceso,
                      periodo: e.value,
                    })
                  }
                  style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                ></InputMask>
              </Grid>

              <Grid item xs={12} md={5}>
                <Autocomplete
                  id="formasPagos"
                  //disabled
                  fullWidth
                  options={
                    formasPagos
                      ? formasPagos.filter((item) => item.debitoAutomatico)
                      : null
                  }
                  disablePortal
                  //defaultValue={colores[idColorDefault]}

                  getOptionLabel={(option) => option.nombre}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Forma de Pago *"
                      size="small"
                    />
                  )}
                  //onKeyDown={handleEnter}
                  onChange={(event, value) => {
                    if (value) {
                      guardarProceso({
                        ...proceso,
                        id_formaPago: value.id,
                        formaPago: value.nombre,
                      });
                    } else {
                      guardarProceso({
                        ...proceso,
                        id_formaPago: null,
                        formaPago: null,
                      });
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Dialog>

          <Dialog
            //header={renderHeaderProceso()}
            visible={visibleTarjetas}
            closable={false}
            footer={renderFooterTarjetas()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "70vw", paddingBottom: "0" }}
            position="top"
          >
            <Grid item spacing={3} style={{ marginTop: "1vw" }}>
              <div className="datatable-responsive-demo">
                <div className="card">
                  <Tooltip target=".export-buttons>button" position="bottom" />
                  <DataTable
                    value={procesos}
                    selection={selectedRow}
                    onSelectionChange={(e) => setSelectedRow(e.value)}
                    selectionMode="single"
                    dataKey="id"
                    header={header}
                    className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                    style={{ fontSize: "small", marginTop: "1vw" }}
                    globalFilter={globalFilter}
                    emptyMessage="No hay datos."
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    //paginatorLeft={paginatorLeft}
                    //paginatorRight={paginatorRight}
                  >
                    <Column
                      field="id_titular"
                      header="Socio"
                      //body={idTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="nombre_razonsocial"
                      header="Nombre"
                      //body={descripcionTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="numerotarjeta"
                      header="Tarjeta"
                      //body={descripcionTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="cuota"
                      header="Importe"
                      //body={descripcionTemplate}
                      sortable
                    ></Column>
                    <Column
                      header="Editar"
                      body={opcionesTemplate}
                      //body={descripcionTemplate}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </Grid>
          </Dialog>

          <Dialog
            header={renderHeader()}
            visible={visible}
            closable={false}
            footer={renderFooter()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw", paddingBottom: "0" }}
            position="center"
          >
            <Grid container spacing={3} style={{ marginTop: "1vw" }}>
              <Grid item xs={12} md={8}>
                <label htmlFor="importe">Importe *</label>
                <InputNumber
                  id="importe"
                  inputId="importe"
                  value={importeTarjeta ? importeTarjeta : null}
                  //onKeyDown={handleEnter}
                  onChange={(e) => guardarImporteTarjeta(e.value)}
                  mode="decimal"
                  locale="en-US"
                  minFractionDigits={2}
                  style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                />
              </Grid>
            </Grid>
          </Dialog>
        </Fragment>
      ) : null}

      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

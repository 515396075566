import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import Checkbox from "@mui/material/Checkbox";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tooltip } from "primereact/tooltip";

export default function ClientesIntegrantes(props) {
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const { direcciones, obtenerDirecciones, guardarDirecciones } =
    serviciosContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    integrantes,
    titular,
    proximoIntegrante,
    integrantesHabilitados,
    integrantesInhabilitados,
    integrantesTotales,
    setBandFirst,
    guardarCliente,
    setActiveStep,
    setSelectedRowClientes,
  } = configuracionContext;

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);

  const toast = useRef(null);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const addClick = (e) => {
    guardarCliente({
      id:
        titular.id_titular +
        String(proximoIntegrante).padStart(6, 0) +
        "000000",
      habilitado: true,
      titular: false,
    });
    setSelectedRowClientes(null);
    //buscarTitular({ titularId: selectedRow.id_titular });
    //buscarIntegrantes({ titularId: selectedRow.id_titular });
    //setVisible(true);
    setBandFirst(true);
    handleBack();
  };

  const editClick = (e) => {
    if (selectedRow) {
      guardarCliente(selectedRow);
      setSelectedRowClientes(selectedRow);
      //buscarTitular({ titularId: selectedRow.id_titular });
      //buscarIntegrantes({ titularId: selectedRow.id_titular });
      //setVisible(true);
      setBandFirst(true);
      handleBack();
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un afiliado.",
        life: 3000,
      });
    }
  };


  const exitClick = (e) => {
    //setActiveStep(0)
    navigate("/clientes");
    window.location.reload(false);
  };


  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  function formatDate(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[0], thisDate[1], thisDate[2]].join("-");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate;
  }

  const titularTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.titular}></Checkbox>
      </Fragment>
    );
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const suspendidoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.suspendido}></Checkbox>
      </Fragment>
    );
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          //onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          //onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        
        <Tag 
          value={
            "Integrantes Total: " +
            Number(integrantesTotales)  + 
            " Habilitados: " +
            Number(integrantesHabilitados) +
            " Inhabilitados: " +
            Number(integrantesInhabilitados)
          }
        ></Tag>
      </div>
    </Fragment>
  );

 //console.log(integrantesInhabilitados);
  //console.log(bandInicio)
  //console.log(afiliado ? formatDate(afiliado.ingreso) : null);

  return (
    <Fragment>
      <Toast ref={toast} />
      {integrantes ? (
        <Fragment>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ align: "center", margin: "1em" }}
          >
            <Button
              icon="pi pi-plus"
              tooltip="Agregar"
              className="p-button-rounded p-button-help"
              style={{ marginLeft: "0em" }}
              onClick={addClick}
            />
            <Button
              icon="pi pi-pencil"
              tooltip="Editar"
              className="p-button-rounded p-button-danger"
              style={{ marginLeft: "1em" }}
              onClick={editClick}
            />
            <Button
              icon="pi pi-times"
              tooltip="Cerrar"
              className="p-button-rounded p-button-primary"
              style={{ marginLeft: "1em" }}
              onClick={exitClick}
            />
            <div className="datatable-responsive-demo">
              <div className="card">
                <Tooltip target=".export-buttons>button" position="bottom" />
                <DataTable
                  value={integrantes}
                  selection={selectedRow}
                  onSelectionChange={(e) => setSelectedRow(e.value)}
                  selectionMode="single"
                  dataKey="id"
                  header={header}
                  className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                  style={{ fontSize: "small", marginTop: "1vw" }}
                  globalFilter={globalFilter}
                  emptyMessage="No hay datos."
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                  rows={10}
                  rowsPerPageOptions={[10, 20, 50, 100, 500, 1000]}
                  //paginatorLeft={paginatorLeft}
                  //paginatorRight={paginatorRight}
                >
                  <Column
                    field="id"
                    header="Nº Afiliado"
                    //body={idTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="nombre_razonsocial"
                    header="Apellido y Nombre"
                    //body={descripcionTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="domicilio"
                    header="Domicilio"
                    //body={stockTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="numerodocumento"
                    header="Nº Documento"
                    //body={unidadTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="telefono1"
                    header="Teléfono"
                    //body={unidadTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="celular"
                    header="Celular"
                    //body={unidadTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="titular"
                    header="Titular"
                    body={titularTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="habilitado"
                    header="Habilitado"
                    body={habilitadoTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="suspendido"
                    header="Suspendido"
                    body={suspendidoTemplate}
                    sortable
                  ></Column>
                </DataTable>
              </div>
            </div>
          </Grid>
        </Fragment>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import Checkbox from "@mui/material/Checkbox";
import { OrderList } from "primereact/orderlist";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import CloseIcon from "@mui/icons-material/Close";

export default function CierresPeriodos(props) {
  let navigate = useNavigate();
  const toast = useRef(null);
  const { origen } = useParams();

  const [periodoConfirmado, setPeriodoConfirmado] = useState(false);

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const { statusAcceso, cierre, obtenerAcceso, cerrarPeriodo, obtenerCierre, guardarCierre } = configuracionContext;

  //const classes = useStyles({color: afiliado ? afiliado.motivoConsultaColor : null });

  useEffect(() => {
    if (state.usuario) {
      if (origen === "compras") {
        obtenerAcceso({
          usuarioId: state.usuario.id,
          layout: "CierreCompras",
        });
      }
      if (origen === "ventas") {
        obtenerAcceso({
          usuarioId: state.usuario.id,
          layout: "CierreVentas",
        });
      }
      if (origen === "cobranzas") {
        obtenerAcceso({
          usuarioId: state.usuario.id,
          layout: "CierreCobranzas",
        });
      }
    }
  }, [state]);

  const exitClick = (e) => {
    //navigate("/");
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Cierre Período",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const salir = (e) => {
    navigate("/");
  };

  const periodoClick = (e) => {
    e.preventDefault();
    //Validar

    if (
      !cierre ||
      cierre.periodo === undefined ||
      cierre.periodo === null ||
      cierre.periodo === ""
    ) {
      mensajeAlerta("Cierre Período", "El período es necesario !.", "error");
      return;
    }

    obtenerCierre({cierre, origen})

    setPeriodoConfirmado(true)

  }

  const confirmar = (e) => {
    e.preventDefault();
    //Validar

    if (!periodoConfirmado) {
      mensajeAlerta("Cierre Período", "Debe confirmar el período !.", "error");
      return;
    }


    // Llamar al context
    cerrarPeriodo({ cierre, origen });

    setTimeout(() => {
      guardarCierre({
        periodo: null,
        cerrado: null
      })
      setPeriodoConfirmado(false)
    }, 1000);
  };

  function capitalizarPrimeraLetra(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  //console.log(cierre);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      <ConfirmDialog />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <CloseIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {"Cierre de Período " + capitalizarPrimeraLetra(origen)}
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <label htmlFor="id" style={{ color: "red" }}>
                Período
              </label>
              <InputMask
                id="periodo"
                mask="99/99"
                value={cierre ? cierre.periodo : null}
                placeholder="99/99"
                //onBlur={handleEnter}
                size={3}
                onChange={(e) =>
                  guardarCierre({
                    ...cierre,
                    periodo: e.value,
                  })
                }
                style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
              ></InputMask>
              <Button
                icon="pi pi-check"
                tooltip="Confirmar Período"
                className="p-button-rounded p-button-success"
                style={{ marginLeft: "1em" }}
                onClick={periodoClick}
              />

              <Grid item xs={12} md={12}>
                <FormControlLabel
                  value="yes"
                  control={
                    <Checkbox
                      color="primary"
                      checked={cierre.cerrado}
                      onChange={(e) => {
                        guardarCierre({
                          ...cierre,
                          cerrado: e.target.checked,
                        });
                      }}
                    />
                  }
                  disabled={!periodoConfirmado}
                  label="Cerrado"
                  labelPlacement="start"
                  style={{ paddingTop: "1vw" }}
                />
              </Grid>
              <ButtonMui
                type="button"
                variant="contained"
                color="primary"
                onClick={confirmar}
                style={{ marginTop: "1vw" }}
              >
                Confirmar
              </ButtonMui>
              <ButtonMui
                type="button"
                variant="contained"
                color="secondary"
                onClick={salir}
                style={{ marginLeft: "1vw", marginTop: "1vw" }}
              >
                Salir
              </ButtonMui>
            </Box>
          </Box>
        </Container>
      ) : null}
    </Fragment>
  );
}

import clienteAxios from './axios';
import clienteAxiosAdmin  from './axiosAdmin';
const tokenAuth = token => {

    if(token) {
        clienteAxios.defaults.headers.common['x-auth-token'] = token;
        clienteAxiosAdmin.defaults.headers.common['x-auth-token'] = token;
    } else {
        delete clienteAxios.defaults.headers.common['x-auth-token'];
        delete clienteAxiosAdmin.defaults.headers.common['x-auth-token'];
    }
}

export default tokenAuth;
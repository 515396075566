import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";
import { InputNumber } from "primereact/inputnumber";
import { jsPDF } from "jspdf";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";

export default function RendicionesMercadoPago(props) {
  const exportColumns = [
    { title: "Socio Nº", dataKey: "id_titular" },
    { title: "Nombre", dataKey: "nombre_razonsocial" },
    { title: "Tarjeta", dataKey: "numerotarjeta" },
    { title: "Importe", dataKey: "cuota" },
  ];

  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [visibleProceso, setVisibleProceso] = useState(true);
  const [proceso, guardarProceso] = useState(null);
  const [empresa, guardarEmpresa] = useState(null);
  const [cuenta, guardarCuenta] = useState(null);
  const [cobrador, guardarCobrador] = useState(null);
  const [bandEmpresa, setBandEmpresa] = useState(false);
  const [bandCuenta, setBandCuenta] = useState(false);
  const [bandCobrador, setBandCobrador] = useState(false);
  const [bandProceso, setBandProceso] = useState(false);

  const toast = useRef(null);
  const acCobrador = useRef(null);

  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    cobradores,
    cuentas,
    mercadoPago,
    aceptados,
    rechazados,
    statusAcceso,
    empresaElegida,
    empresas,
    formasPagos,
    obtenerAcceso,
    obtenerEmpresas,
    obtenerMercadoPago,
    obtenerFormasPagos,
    obtenerCuentas,
    obtenerCobradores,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "RendicionesMercadoPago",
      });
    }
  }, [state.id]);

  useEffect(() => {
    obtenerEmpresas();
  }, []);

  useEffect(() => {
    obtenerFormasPagos();
    obtenerCuentas();
    obtenerCobradores({ todos: false });
  }, []);

  const exitClick = (e) => {
    navigate("/");
  };

  const onHideProceso = (e) => {
    setVisibleProceso(false);
  };

  const aceptarProceso = async (e) => {
    e.preventDefault();
    //Validar

    if (
      !proceso ||
      proceso.id_cuenta === undefined ||
      proceso.id_cuenta === null ||
      proceso.id_cuenta === 0 ||
      proceso.id_cuenta === ""
    ) {
      mensajeAlerta("Procesos", "La cuenta es necesaria !.", "error");
      return;
    }

    if (
      !proceso ||
      proceso.id_cobrador === undefined ||
      proceso.id_cobrador === null ||
      proceso.id_cobrador === 0 ||
      proceso.id_cobrador === ""
    ) {
      mensajeAlerta("Procesos", "El cobrador es necesario !.", "error");
      return;
    }

    confirmDialog({
      message: "Esta seguro de iniciar la rendición de Mercado Pago",
      header: "Confirmación",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-success",
      closable: false,
      acceptLabel: "Si",
      rejectLabel: "No",
      accept: accept1,
    });
  };

  const accept1 = async () => {
    await obtenerMercadoPago({
      proceso,
      empresaElegida,
      usuario: state.usuario,
    }).then((data) => {
      let elem = document.getElementById("mercadoPago");
      elem.click();
    });
  };

  const exportExcelAceptados = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(aceptados);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(
        excelBuffer,
        "Aceptados-MercadoPago-" + proceso.cuenta + "-" + proceso.id_cobrador
      );
    });
  };

  const exportExcelRechazados = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(rechazados);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(
        excelBuffer,
        "Rechazados-MercadoPago-" + proceso.cuenta + "-" + proceso.id_cobrador
      );
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "-" + new Date().toLocaleDateString() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Proceso de Impresión",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const renderHeaderProceso = () => {
    return (
      <div>
        <Tag
          value={
            empresas && empresas.length > 0
              ? "Mercado Pago " + empresaElegida.nombre
              : null
          }
        ></Tag>
      </div>
    );
  };

  const renderFooterProceso = () => {
    return (
      <div>
        <ButtonMui
          onClick={(e) => aceptarProceso(e)}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
          autoFocus
        >
          Aceptar
        </ButtonMui>

        <ButtonMui
          onClick={onHideProceso}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  //INICIO

  if (
    empresas &&
    empresas.length > 0 &&
    empresaElegida &&
    !empresa &&
    !bandEmpresa
  ) {
    const index = empresas.findIndex((item) => item.id === empresaElegida.id);

    guardarEmpresa(empresas[index]);
    setBandEmpresa(true);
  }

  if (cuentas && cuentas.length > 0 && empresa && !cuenta && !bandCuenta) {
    const index = cuentas.findIndex((item) => item.id === empresa.cuentaIdMp);

    if (index !== -1) {
      guardarCuenta(cuentas[index]);
    }
    setBandCuenta(true);
  }

  if (
    cobradores &&
    cobradores.length > 0 &&
    empresa &&
    !cobrador &&
    !bandCobrador
  ) {
    const index = cobradores.findIndex(
      (item) => item.id === empresa.cobradorIdMp
    );

    if (index !== -1) {
      guardarCobrador(cobradores[index]);
    }
    setBandCobrador(true);
  }
  if (bandCuenta && bandEmpresa && bandCobrador && !bandProceso) {
    setBandProceso(true);
    guardarProceso({
      ...proceso,
      id_cuenta: cuenta ? cuenta.id : null,
      cuenta: cuenta ? cuenta.nombre : null,
      id_cobrador: cobrador ? cobrador.id : null,
      cobrador: cobrador ? cobrador.nombre : null,
    });
  }

  //FIN

  //console.log(rechazados);
  //console.log(cuenta);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      <ConfirmDialog />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Dialog
            header={renderHeaderProceso()}
            visible={
              visibleProceso && bandCobrador && bandCuenta && bandEmpresa
            }
            closable={false}
            footer={renderFooterProceso()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "70vw", paddingBottom: "0" }}
            position="top"
          >
            <Grid container spacing={3} style={{ marginTop: "1vw" }}>
              <Grid item xs={12} md={5}>
                <Autocomplete
                  id="cuentas"
                  //disabled
                  fullWidth
                  options={
                    cuentas && empresaElegida
                      ? cuentas.filter(
                          (item) => item.id_empresa === empresaElegida.id
                        )
                      : null
                  }
                  disablePortal
                  defaultValue={
                    cuentas &&
                    cuenta &&
                    bandCuenta &&
                    bandEmpresa &&
                    bandCobrador
                      ? cuenta
                      : null
                  }
                  getOptionLabel={(option) => option.nombre}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Cuenta *"
                      size="small"
                    />
                  )}
                  //onKeyDown={handleEnter}
                  disabled
                  onChange={(event, value) => {
                    if (value) {
                      guardarProceso({
                        ...proceso,
                        id_cuenta: value.id,
                        cuenta: value.nombre,
                      });
                    } else {
                      guardarProceso({
                        ...proceso,
                        id_cuenta: null,
                        cuenta: null,
                      });
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} md={5}>
                <Autocomplete
                  id="cobradores"
                  //disabled
                  fullWidth
                  options={cobradores ? cobradores : null}
                  disablePortal
                  defaultValue={
                    cobradores &&
                    cobrador &&
                    bandCuenta &&
                    bandEmpresa &&
                    bandCobrador
                      ? cobrador
                      : null
                  }
                  getOptionLabel={(option) => option.nombre}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Cobrador *"
                      size="small"
                    />
                  )}
                  //onKeyDown={handleEnter}
                  disabled
                  onChange={(event, value) => {
                    if (value) {
                      guardarProceso({
                        ...proceso,
                        id_cobrador: value.id,
                        cobrador: value.nombre,
                      });
                    } else {
                      guardarProceso({
                        ...proceso,
                        id_cobrador: null,
                        cobrador: null,
                      });
                    }
                  }}
                />
              </Grid>
              <button
                id="mercadoPago"
                type="button"
                onClick={(e) => {
                  exportExcelAceptados();
                  exportExcelRechazados();
                }}
                style={{ display: "none" }}
              >
                Export Excel
              </button>
            </Grid>
          </Dialog>
        </Fragment>
      ) : null}

      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

import React, { Fragment, useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { AlertProvider } from "./context/AlertContext";
import { ServiciosProvider } from "./context/ServiciosContext";
import { ConfiguracionProvider } from "./context/ConfiguracionContext";
import RutaPrivada from "./components/rutas/RutaPrivada";
import '@fortawesome/fontawesome-free/css/all.min.css';
//import Account from "./components/auth/Account";
import Login from "./components/auth/Login";
//import Account from "./components/auth/Account";
import Reset from "./components/auth/Reset";
import Change from "./components/auth/Change";
//
import Menu from "./components/layout/Menu";
import Configuraciones from "./components/layout/Configuraciones";
import Accesos from "./components/layout/Accesos";
import Clientes from "./components/layout/Clientes";
import Rubros from "./components/layout/Rubros";
import Localidades from "./components/layout/Localidades";
import Planes from "./components/layout/Planes";
import Asesores from "./components/layout/Asesores";
import Cobradores from "./components/layout/Cobradores";
import Adicionales from "./components/layout/Adicionales";
import Empresas from "./components/layout/Empresas";
import MotivosBajas from "./components/layout/MotivosBajas";
import MotivosSuspendidos from "./components/layout/MotivosSuspendidos";
import Facturacion from "./components/layout/Facturacion";
import Comprobantes from "./components/layout/Comprobantes";
//import Ajustes from "./components/layout/Ajustes";
import Importaciones from "./components/layout/Importaciones";
import TiposRetenciones from "./components/layout/TiposRetenciones";
//import Rendiciones from "./components/layout/Rendiciones";
import RendicionesPendientes from "./components/layout/RendicionesPendientes";
import RendicionesPendientesItems from "./components/layout/RendicionesPendientesItems";
import RendicionesAplicadasEliminadas from "./components/layout/RendicionesAplicadasEliminadas";
import Bancos from "./components/layout/Bancos";
import Monedas from "./components/layout/Monedas";
import Conceptos from "./components/layout/Conceptos";
import Cuentas from "./components/layout/Cuentas";
//import RendicionesDiarias from "./components/layout/RendicionesDiarias";
import GenerarBarCode from "./components/layout/GenerarBarCode";
import MovimientosCajas from "./components/layout/MovimientosCajas";
import MovimientosCheques from "./components/layout/MovimientosCheques";
import MovimientosCompensaciones from "./components/layout/MovimientosCompensaciones";
import Proveedores from "./components/layout/Proveedores";
import FacturacionCompras from "./components/layout/FacturacionCompras";
import ComprobantesCompras from "./components/layout/ComprobantesCompras";
import OrdenPago from "./components/layout/OrdenPago";
import OrdenesPagos from "./components/layout/OrdenesPagos";
import MovimientosBancos from "./components/layout/MovimientosBancos";
import CierresPeriodos from "./components/layout/CierresPeriodos";
import ListadosCartera from "./components/layout/listados/ListadosCartera";
import ListadosAltas from "./components/layout/listados/ListadosAltas";
import ListadosBajas from "./components/layout/listados/ListadosBajas";
import ListadosIvaVentas from "./components/layout/listados/ListadosIvaVentas";
import ListadosIvaCompras from "./components/layout/listados/ListadosIvaCompras";
import ListadosCobranzasPendientes from "./components/layout/listados/ListadosCobranzasPendientes";
import ListadosCobranzasRealizadas from "./components/layout/listados/ListadosCobranzasRealizadas";
import ListadosSuspendidos from "./components/layout/listados/ListadosSuspendidos";
import ListadosComprobantesVentas from "./components/layout/listados/ListadosComprobantesVentas";
import ListadosRetenciones from "./components/layout/listados/ListadosRetenciones";
import ListadosComprobantesCompras from "./components/layout/listados/ListadosComprobantesCompras";
import ListadosOrdenesPagos from "./components/layout/listados/ListadosOrdenesPagos";
import ListadosModulos from "./components/layout/listados/ListadosModulos";
import ProcesosFacturacion from "./components/layout/procesos/ProcesosFacturacion"
import ProcesosImpresion from "./components/layout/procesos/ProcesosImpresion";
import ProcesosComprasYVentas from "./components/layout/procesos/ProcesosComprasYVentas";
import InformesProcesosFacturacion from "./components/layout/InformesProcesosFacturacion";
import ProcesosPercepciones from "./components/layout/procesos/ProcesosPercepciones";
import ProcesosDebitosAutomaticos from "./components/layout/procesos/ProcesosDebitosAutomaticos";
import RendicionesDebitosAutomaticos from "./components/layout/RendicionesDebitosAutomaticos";
import RendicionesMercadoPago from "./components/layout/RendicionesMercadoPago";
import ListadosMensajes from "./components/layout/listados/ListadosMensajes";
import ListadosCobranzasPlanilla from "./components/layout/listados/ListadosCobranzasPlanilla";
import ListadoMovimientos from "./components/layout/listados/ListadoMovimientos";

//import Pacientes from "./components/layout/Pacientes";
/*
import Pedidos from "./components/layout/Pedidos";
import Comisiones from "./components/layout/Comisiones";
import ProductosEnvasados from "./components/layout/ProductosEnvasados";
import ProductosNaturales from "./components/layout/ProductosNaturales";
import ProductosElaborados from "./components/layout/ProductosElaborados";
import Movimientos from "./components/layout/Movimientos";
import ListadoSaldos from "./components/layout/listados/ListadoSaldos";
import ListadoStock from "./components/layout/listados/ListadoStock";
import ListadoCajas from "./components/layout/listados/ListadoCajas";
import ListadoComisiones from "./components/layout/listados/ListadoComisiones";
*/
function App() {
  return (
    <AlertProvider>
      <AuthProvider>
        <ServiciosProvider>
          <ConfiguracionProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/change/:token" element={<Change />} />
                <Route path="/reset" element={<Reset />} />
                <Route path="/" element={<Menu />} />
                <Route
                  path="/rendicionespendientesitems/:id"
                  element={
                    <RutaPrivada>
                      <RendicionesPendientesItems />
                    </RutaPrivada>
                  }
                />
                {/*
                <Route
                  path="/rendicionesdiarias"
                  element={<RendicionesDiarias />}
                />
                */}
                <Route
                  path="/movimientoscajas"
                  element={
                    <RutaPrivada>
                      <MovimientosCajas />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/movimientoscheques"
                  element={
                    <RutaPrivada>
                      <MovimientosCheques />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/movimientosbancos"
                  element={
                    <RutaPrivada>
                      <MovimientosBancos />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/movimientoscompensaciones"
                  element={
                    <RutaPrivada>
                      <MovimientosCompensaciones />
                    </RutaPrivada>
                  }
                />
                {/*<Route path="/rendiciones" element={<Rendiciones />} />*/}
                <Route path="/generarbarcode" element={<GenerarBarCode />} />

                <Route
                  path="/accesos"
                  element={
                    <RutaPrivada>
                      <Accesos />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/configuraciones"
                  element={
                    <RutaPrivada>
                      <Configuraciones />
                    </RutaPrivada>
                  }
                />

                <Route
                  path="/clientes"
                  element={
                    <RutaPrivada>
                      <Clientes />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/rubros"
                  element={
                    <RutaPrivada>
                      <Rubros />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/localidades"
                  element={
                    <RutaPrivada>
                      <Localidades />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/planes"
                  element={
                    <RutaPrivada>
                      <Planes />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/asesores"
                  element={
                    <RutaPrivada>
                      <Asesores />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/cobradores"
                  element={
                    <RutaPrivada>
                      <Cobradores />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/bancos"
                  element={
                    <RutaPrivada>
                      <Bancos />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/monedas"
                  element={
                    <RutaPrivada>
                      <Monedas />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/cuentas"
                  element={
                    <RutaPrivada>
                      <Cuentas />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/conceptos"
                  element={
                    <RutaPrivada>
                      <Conceptos />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/adicionales"
                  element={
                    <RutaPrivada>
                      <Adicionales />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/empresas"
                  element={
                    <RutaPrivada>
                      <Empresas />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/motivosbajas"
                  element={
                    <RutaPrivada>
                      <MotivosBajas />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/motivossuspendidos"
                  element={
                    <RutaPrivada>
                      <MotivosSuspendidos />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/facturacion/:id/:titularId"
                  element={
                    <RutaPrivada>
                      <Facturacion />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/comprobantes"
                  element={
                    <RutaPrivada>
                      <Comprobantes />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/uploadpercepciones"
                  element={
                    <RutaPrivada>
                      <Importaciones data={1} />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/importarpercepciones"
                  element={
                    <RutaPrivada>
                      <Importaciones data={2} />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/tiposretenciones"
                  element={
                    <RutaPrivada>
                      <TiposRetenciones />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/rendicionespendientes"
                  element={
                    <RutaPrivada>
                      <RendicionesPendientes />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/rendicionesaplicadaseliminadas/:tipo"
                  element={
                    <RutaPrivada>
                      <RendicionesAplicadasEliminadas />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/proveedores"
                  element={
                    <RutaPrivada>
                      <Proveedores />
                    </RutaPrivada>
                  }
                />

                <Route
                  path="/facturacioncompras/:id/:proveedorId"
                  element={
                    <RutaPrivada>
                      <FacturacionCompras />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/comprobantescompras"
                  element={
                    <RutaPrivada>
                      <ComprobantesCompras />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/ordenpago/:id/:proveedorId/:origen"
                  element={
                    <RutaPrivada>
                      <OrdenPago />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/ordenespagos/:origen"
                  element={
                    <RutaPrivada>
                      <OrdenesPagos />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/cierresperiodos/:origen"
                  element={
                    <RutaPrivada>
                      <CierresPeriodos />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/listadoscartera"
                  element={
                    <RutaPrivada>
                      <ListadosCartera />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/listadosaltas"
                  element={
                    <RutaPrivada>
                      <ListadosAltas />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/listadosbajas"
                  element={
                    <RutaPrivada>
                      <ListadosBajas />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/listadossuspendidos"
                  element={
                    <RutaPrivada>
                      <ListadosSuspendidos />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/listadosmodulos"
                  element={
                    <RutaPrivada>
                      <ListadosModulos />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/listadosivaventas/:tipo"
                  element={
                    <RutaPrivada>
                      <ListadosIvaVentas />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/listadosivacompras/:tipo"
                  element={
                    <RutaPrivada>
                      <ListadosIvaCompras />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/listadoscobranzaspendientes"
                  element={
                    <RutaPrivada>
                      <ListadosCobranzasPendientes />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/listadoscobranzasrealizadas"
                  element={
                    <RutaPrivada>
                      <ListadosCobranzasRealizadas />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/listadoscobranzasplanilla"
                  element={
                    <RutaPrivada>
                      <ListadosCobranzasPlanilla />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/listadomovimientos"
                  element={
                    <RutaPrivada>
                      <ListadoMovimientos />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/listadoscomprobantesventas"
                  element={
                    <RutaPrivada>
                      <ListadosComprobantesVentas />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/listadoscomprobantescompras"
                  element={
                    <RutaPrivada>
                      <ListadosComprobantesCompras />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/listadosordenespagos"
                  element={
                    <RutaPrivada>
                      <ListadosOrdenesPagos />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/listadosretenciones"
                  element={
                    <RutaPrivada>
                      <ListadosRetenciones />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/procesosfacturacion"
                  element={
                    <RutaPrivada>
                      <ProcesosFacturacion />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/procesosimpresion"
                  element={
                    <RutaPrivada>
                      <ProcesosImpresion />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/procesoscomprasyventas"
                  element={
                    <RutaPrivada>
                      <ProcesosComprasYVentas />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/procesospercepciones"
                  element={
                    <RutaPrivada>
                      <ProcesosPercepciones />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/procesosdebitosautomaticos"
                  element={
                    <RutaPrivada>
                      <ProcesosDebitosAutomaticos />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/rendicionesdebitosautomaticos"
                  element={
                    <RutaPrivada>
                      <RendicionesDebitosAutomaticos />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/rendicionesmercadopago"
                  element={
                    <RutaPrivada>
                      <RendicionesMercadoPago />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/listadosmensajes"
                  element={
                    <RutaPrivada>
                      <ListadosMensajes />
                    </RutaPrivada>
                  }
                />
                <Route
                  path="/informesprocesosfacturacion"
                  element={
                    <RutaPrivada>
                      <InformesProcesosFacturacion />
                    </RutaPrivada>
                  }
                />
              </Routes>
            </BrowserRouter>
          </ConfiguracionProvider>
        </ServiciosProvider>
      </AuthProvider>
    </AlertProvider>
  );
}

export default App;

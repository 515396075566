import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";
import * as EmailValidator from "email-validator";
import { InputMask } from "primereact/inputmask";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { InputNumber } from "primereact/inputnumber";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Cobradores(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowComision, setSelectedRowComision] = useState(null);

  let navigate = useNavigate();

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Nombre", dataKey: "nombre" },
    { title: "Domicilio", dataKey: "domicilio" },
    { title: "Teléfono", dataKey: "telefono" },
    { title: "Email", dataKey: "email" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [cobrador, guardarCobrador] = useState(null);
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  const [value, setValue] = useState(0);
  const [empresa, guardarEmpresa] = useState(null);
  const [copiaEmpresas, setCopiaEmpresas] = useState(false);
  const [copiaCobradores, setCopiaCobradores] = useState(false);
  const [eliminarEmpresa, setEliminarEmpresa] = useState(false);
  const [eliminarCobrador, setEliminarCobrador] = useState(false);
  const [bandInicio, setBandInicio] = useState(false);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    cobradores,
    empresas,
    empresaElegida,
    empresaComision,
    cobradorComision,
    comisionCobradorEmpresa,
    obtenerCobradores,
    obtenerAcceso,
    agregarCobrador,
    actualizarCobrador,
    obtenerEmpresas,
    eliminarComisionCobrador,
    obtenerComisionCobradorEmpresa,
    guardarComisionCobradorEmpresa,
    guardarEmpresaComision,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Cobradores" });
    }
  }, [state]);

  useEffect(() => {
    obtenerCobradores();
    obtenerEmpresas();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const editClick = (e) => {
    if (selectedRow) {
      guardarCobrador(selectedRow);
      guardarComisionCobradorEmpresa(null);
      guardarEmpresa(null);
      guardarEmpresaComision(null);
      setBandInicio(false);
      obtenerComisionCobradorEmpresa({
        empresaId: null,
        cobradorId: selectedRow.id,
      });
      setCopiaCobradores(false);
      setCopiaEmpresas(false);
      setEliminarCobrador(false);
      setEliminarEmpresa(false);
      setValue(0);
      setSelectedRowComision(null);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un cobrador.",
        life: 3000,
      });
    }
  };

  const addClick = (e) => {
    //props.history.push(`/cobrador/0`);
    setVisible(true);
    setSelectedRow(null);
    guardarCobrador({
      habilitado: true,
    });
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !cobrador ||
      cobrador.nombre === undefined ||
      cobrador.nombre === null ||
      cobrador.nombre.trim() === ""
    ) {
      mensajeAlerta("Cobradores", "Nombre es necesario !.", "error");
      return;
    }

    if (
      cobrador &&
      cobrador.email !== undefined &&
      cobrador.email !== null &&
      cobrador.email.trim() !== ""
    ) {
      if (!EmailValidator.validate(cobrador.email)) {
        mensajeAlerta("Cobradores", "El email es incorrecto !.", "error");
        return;
      }
    }

    //Comisiones

    if (comisionCobradorEmpresa && comisionCobradorEmpresa.habilitarComision) {
      if (
        !empresa ||
        empresa.id === undefined ||
        empresa.id === null ||
        empresa.id === 0
      ) {
        mensajeAlerta(
          "Comisiones Cobradores",
          "Debe ingresar una empresa !.",
          "error"
        );
        return;
      }

      if (
        !comisionCobradorEmpresa ||
        comisionCobradorEmpresa.habilitar1 === undefined ||
        comisionCobradorEmpresa.habilitar1 === null ||
        !comisionCobradorEmpresa.habilitar1
      ) {
        mensajeAlerta(
          "Comisiones Cobradores",
          "Debe habilitar una comisión !.",
          "error"
        );
        return;
      }

      if (
        comisionCobradorEmpresa &&
        comisionCobradorEmpresa.habilitar1 !== undefined &&
        comisionCobradorEmpresa.habilitar1
      ) {
        if (Number(comisionCobradorEmpresa.entre1 <= 0)) {
          mensajeAlerta(
            "Comisiones Cobradores",
            "Debe ingresar un valor mayor que cero entre1 !.",
            "error"
          );
          return;
        }

        if (Number(comisionCobradorEmpresa.entre1 <= 0)) {
          mensajeAlerta(
            "Comisiones Cobradores",
            "Debe ingresar un valor mayor que cero en entre1 !.",
            "error"
          );
          return;
        }

        if (
          Number(comisionCobradorEmpresa.entre2) <=
          Number(comisionCobradorEmpresa.entre1)
        ) {
          mensajeAlerta(
            "Comisiones Cobradores",
            "En entre2 debe ingresar un valor mayor que entre1 !.",
            "error"
          );
          return;
        }

        if (
          Number(comisionCobradorEmpresa.importe1) === 0 &&
          Number(comisionCobradorEmpresa.porcentaje1) === 0
        ) {
          mensajeAlerta(
            "Comisiones Cobradores",
            "Debe ingresar un importe fijo o porcentaje de comisión !.",
            "error"
          );
          return;
        }
      }

      if (
        comisionCobradorEmpresa &&
        comisionCobradorEmpresa.habilitar2 !== undefined &&
        comisionCobradorEmpresa.habilitar2
      ) {
        if (Number(comisionCobradorEmpresa.entre3) <= 0) {
          mensajeAlerta(
            "Comisiones Cobradores",
            "Debe ingresar un valor mayor que cero entre3 !.",
            "error"
          );
          return;
        }

        if (
          Number(comisionCobradorEmpresa.entre4) <=
          Number(comisionCobradorEmpresa.entre3)
        ) {
          mensajeAlerta(
            "Comisiones Cobradores",
            "En entre4 debe ingresar un valor mayor que entre3 !.",
            "error"
          );
          return;
        }
        if (
          Number(comisionCobradorEmpresa.importe2) === 0 &&
          Number(comisionCobradorEmpresa.porcentaje2) === 0
        ) {
          mensajeAlerta(
            "Comisiones Cobradores",
            "Debe ingresar un importe fijo o porcentaje de comisión !.",
            "error"
          );
          return;
        }
      }
    }

    // Llamar al context
    if (selectedRow) {
      actualizarCobrador({
        cobrador,
        comisionCobradorEmpresa,
        empresa,
        copiaCobradores,
        copiaEmpresas,
        eliminarCobrador,
        eliminarEmpresa,
      });
      setTimeout(() => {
        obtenerComisionCobradorEmpresa({
          empresaId: null,
          cobradorId: cobrador.id,
        });
      }, 500);
    } else {
      agregarCobrador({
        cobrador,
      });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerCobradores();
    }, 2000);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Cobradores",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const ivaTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.descontarIVA}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, cobradores);
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(cobradores);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "cobradores");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Cobradores"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag value={cobrador ? "Cobrador " + cobrador.id + " - " + cobrador.nombre : "Cobrador"}></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  /* INICIO */

  if (!bandInicio && empresaComision) {
    let index = empresas.findIndex((item) => item.id === empresaComision.id);

    setBandInicio(true);
    guardarEmpresa(empresas[index]);
    setCopiaCobradores(false);
    setCopiaEmpresas(false);
    setEliminarCobrador(false);
    setEliminarEmpresa(false);
  }

  /* FIN */

  //console.log(cobradorComision);
  //console.log(empresa);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {cobradores ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ align: "center", margin: "1em" }}
              >
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-pencil"
                  tooltip="Editar"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={cobradores}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        //body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="nombre"
                        header="Nombre"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="domicilio"
                        header="Domicilio"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="telefono"
                        header="Teléfono"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="email"
                        header="Email"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="habilitado"
                        header="Habilitado"
                        body={habilitadoTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "90vw", paddingBottom: "0" }}
                position="center"
              >
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab label="Datos de Cobradores" {...a11yProps(0)} />
                      <Tab label="Comisiones" {...a11yProps(1)} />
                      <Tab label="Grilla Comisiones" {...a11yProps(2)} />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                      {selectedRow ? (
                        <Grid item xs={12} md={2}>
                          <TextField
                            value={cobrador ? cobrador.id : null}
                            required
                            variant="standard"
                            id="id"
                            label="ID"
                            fullWidth
                            disabled
                          />
                        </Grid>
                      ) : null}
                      <Grid item xs={12} md={10}>
                        <TextField
                          value={cobrador ? cobrador.nombre : null}
                          required
                          variant="standard"
                          id="nombre"
                          label="Nombre"
                          fullWidth
                          onChange={(e) =>
                            guardarCobrador({
                              ...cobrador,
                              nombre: e.target.value,
                            })
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <TextField
                          value={cobrador ? cobrador.domicilio : null}
                          variant="standard"
                          id="domicilio"
                          label="Domicilio"
                          fullWidth
                          onChange={(e) =>
                            guardarCobrador({
                              ...cobrador,
                              domicilio: e.target.value,
                            })
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <TextField
                          value={cobrador ? cobrador.telefono : null}
                          variant="standard"
                          id="telefono"
                          label="Teléfono"
                          fullWidth
                          onChange={(e) =>
                            guardarCobrador({
                              ...cobrador,
                              telefono: e.target.value,
                            })
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <TextField
                          value={cobrador ? cobrador.email : null}
                          variant="standard"
                          id="email"
                          label="Email"
                          fullWidth
                          onChange={(e) =>
                            guardarCobrador({
                              ...cobrador,
                              email: e.target.value,
                            })
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={4} style={{ paddingLeft: "0vw" }}>
                        <FormControlLabel
                          value="yes"
                          control={
                            <Checkbox
                              color="primary"
                              checked={cobrador ? cobrador.habilitado : null}
                              style={{ marginLeft: "1vw" }}
                              onChange={(e) =>
                                guardarCobrador({
                                  ...cobrador,
                                  habilitado: e.target.checked,
                                })
                              }
                            />
                          }
                          label="Habilitado"
                          labelPlacement="start"
                          style={{ paddingLeft: "0.0vw" }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4} style={{ paddingLeft: "0vw" }}>
                        <label htmlFor="id">Pin: </label>
                        <InputMask
                          id="pin"
                          mask="9999"
                          value={cobrador ? cobrador.pin : null}
                          placeholder="9999"
                          size={10}
                          onChange={(e) =>
                            guardarCobrador({
                              ...cobrador,
                              pin: e.value,
                            })
                          }
                          style={{ marginLeft: "0.7vw", marginTop: "-0.4vw" }}
                        ></InputMask>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  {selectedRow ? (
                    <Fragment>
                      <TabPanel value={value} index={1}>
                        <Grid
                          container
                          spacing={3}
                          style={{ marginTop: "1vw" }}
                        >
                          {selectedRow ? (
                            <Grid item xs={12} md={2}>
                              <TextField
                                value={cobrador ? cobrador.id : null}
                                required
                                variant="standard"
                                id="id"
                                label="ID"
                                fullWidth
                                disabled
                              />
                            </Grid>
                          ) : null}
                          <Grid item xs={12} md={10}>
                            <TextField
                              value={cobrador ? cobrador.nombre : null}
                              required
                              variant="standard"
                              id="nombre"
                              label="Nombre"
                              fullWidth
                              disabled
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={2}
                            style={{ marginTop: "1vw" }}
                          >
                            <FormControlLabel
                              value="yes"
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={
                                    comisionCobradorEmpresa
                                      ? comisionCobradorEmpresa.habilitarComision
                                      : false
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      guardarComisionCobradorEmpresa({
                                        habilitarComision: e.target.checked,
                                      });
                                    } else {
                                      guardarComisionCobradorEmpresa(null);
                                    }
                                    setCopiaEmpresas(false);
                                    setCopiaCobradores(false);
                                    guardarEmpresa(null);
                                  }}
                                  style={{ marginLeft: "0.5vw" }}
                                />
                              }
                              label="Habilitar comisión"
                              labelPlacement="start"
                            />
                          </Grid>
                          <Grid item xs={12} md={10} />

                          <Grid item xs={12} md={4}>
                            <Autocomplete
                              id="empresa"
                              fullWidth
                              noOptionsText={"No hay opciones"}
                              options={
                                empresas
                                  ? empresas.filter((item) => item.habilitado)
                                  : null
                              }
                              value={empresa}
                              getOptionLabel={(option) => option.nombre}
                              openOnFocus={true}
                              disablePortal
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Empresa *"
                                  variant="standard"
                                />
                              )}
                              onChange={(event, value) => {
                                if (value) {
                                  guardarEmpresa(value);
                                  obtenerComisionCobradorEmpresa({
                                    empresaId: value.id,
                                    cobradorId: cobrador.id,
                                  });
                                } else {
                                  guardarEmpresa(null);
                                  guardarComisionCobradorEmpresa({
                                    ...comisionCobradorEmpresa,
                                    habilitar1: false,
                                    habilitar2: false,
                                    entre1: 0,
                                    entre2: 0,
                                    importe1: 0,
                                    porcentaje1: 0,
                                    entre3: 0,
                                    entre4: 0,
                                    importe2: 0,
                                    porcentaje2: 0,
                                    descontarIVA: false,
                                  });
                                }
                              }}
                              disabled={
                                comisionCobradorEmpresa &&
                                comisionCobradorEmpresa.habilitarComision
                                  ? false
                                  : true
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={8} />

                          <Grid
                            item
                            xs={12}
                            md={2}
                            style={{ marginTop: "1vw" }}
                          >
                            <FormControlLabel
                              value="yes"
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={
                                    comisionCobradorEmpresa
                                      ? comisionCobradorEmpresa.habilitar1
                                      : false
                                  }
                                  onChange={(e) => {
                                    guardarComisionCobradorEmpresa({
                                      ...comisionCobradorEmpresa,
                                      habilitar1: e.target.checked,
                                      habilitar2: false,
                                      entre1: 0,
                                      entre2: 0,
                                      importe1: 0,
                                      porcentaje1: 0,
                                      entre3: 0,
                                      entre4: 0,
                                      importe2: 0,
                                      porcentaje2: 0,
                                      descontarIVA: false,
                                    });
                                    if (!e.target.checked) {
                                      guardarEmpresa(null);
                                    }
                                  }}
                                  style={{ marginLeft: "0.5vw" }}
                                />
                              }
                              label="Habilitar"
                              labelPlacement="start"
                              disabled={
                                comisionCobradorEmpresa &&
                                comisionCobradorEmpresa.habilitarComision &&
                                empresa
                                  ? false
                                  : true
                              }
                            />
                          </Grid>

                          <Grid item xs={12} md={2.5}>
                            <label htmlFor="entre1">Entre *</label>
                            <InputNumber
                              id="entre1"
                              inputId="entre1"
                              size={10}
                              value={
                                comisionCobradorEmpresa
                                  ? comisionCobradorEmpresa.entre1
                                  : 0
                              }
                              onChange={(e) =>
                                guardarComisionCobradorEmpresa({
                                  ...comisionCobradorEmpresa,
                                  entre1: e.value,
                                })
                              }
                              mode="decimal"
                              locale="en-US"
                              minFractionDigits={2}
                              style={{
                                marginLeft: "0.7vw",
                                marginTop: "0.4vw",
                              }}
                              disabled={
                                comisionCobradorEmpresa
                                  ? !comisionCobradorEmpresa.habilitar1
                                  : true
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={2.5}>
                            <label htmlFor="entre2">y Entre *</label>
                            <InputNumber
                              id="entre2"
                              inputId="entre2"
                              size={10}
                              value={
                                comisionCobradorEmpresa
                                  ? comisionCobradorEmpresa.entre2
                                  : 0
                              }
                              onChange={(e) =>
                                guardarComisionCobradorEmpresa({
                                  ...comisionCobradorEmpresa,
                                  entre2: e.value,
                                  entre3: e.value,
                                })
                              }
                              mode="decimal"
                              locale="en-US"
                              minFractionDigits={2}
                              style={{
                                marginLeft: "0.7vw",
                                marginTop: "0.4vw",
                              }}
                              disabled={
                                comisionCobradorEmpresa
                                  ? !comisionCobradorEmpresa.habilitar1
                                  : true
                              }
                            />
                          </Grid>

                          <Grid item xs={12} md={2.5}>
                            <label htmlFor="importe1">Importe</label>
                            <InputNumber
                              id="importe1"
                              inputId="importe1"
                              size={10}
                              value={
                                comisionCobradorEmpresa
                                  ? comisionCobradorEmpresa.importe1
                                  : 0
                              }
                              onChange={(e) =>
                                guardarComisionCobradorEmpresa({
                                  ...comisionCobradorEmpresa,
                                  importe1: e.value,
                                })
                              }
                              mode="decimal"
                              locale="en-US"
                              disabled={
                                comisionCobradorEmpresa &&
                                comisionCobradorEmpresa.habilitar1 &&
                                Number(comisionCobradorEmpresa.entre1) > 0 &&
                                Number(comisionCobradorEmpresa.entre2) > 0 &&
                                Number(comisionCobradorEmpresa.porcentaje1) ===
                                  0
                                  ? false
                                  : true
                              }
                              minFractionDigits={2}
                              style={{
                                marginLeft: "0.7vw",
                                marginTop: "0.4vw",
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} md={2.5}>
                            <label htmlFor="porcentaje1">Porcentaje</label>
                            <InputNumber
                              id="porcentaje1"
                              inputId="porcentaje1"
                              size={10}
                              value={
                                comisionCobradorEmpresa
                                  ? comisionCobradorEmpresa.porcentaje1
                                  : 0
                              }
                              onChange={(e) =>
                                guardarComisionCobradorEmpresa({
                                  ...comisionCobradorEmpresa,
                                  porcentaje1: e.value,
                                })
                              }
                              mode="decimal"
                              locale="en-US"
                              disabled={
                                comisionCobradorEmpresa &&
                                comisionCobradorEmpresa.habilitar1 &&
                                Number(comisionCobradorEmpresa.entre1) > 0 &&
                                Number(comisionCobradorEmpresa.entre2) > 0 &&
                                Number(comisionCobradorEmpresa.importe1) === 0
                                  ? false
                                  : true
                              }
                              minFractionDigits={2}
                              style={{
                                marginLeft: "0.7vw",
                                marginTop: "0.4vw",
                              }}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            md={2}
                            style={{ marginTop: "1vw" }}
                          >
                            <FormControlLabel
                              value="yes"
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={
                                    comisionCobradorEmpresa
                                      ? comisionCobradorEmpresa.habilitar2
                                      : false
                                  }
                                  onChange={(e) => {
                                    guardarComisionCobradorEmpresa({
                                      ...comisionCobradorEmpresa,
                                      habilitar2: e.target.checked,
                                      entre3: 0,
                                      entre4: 0,
                                      importe2: 0,
                                      porcentaje2: 0,
                                    });
                                  }}
                                  style={{ marginLeft: "0.5vw" }}
                                />
                              }
                              label="Habilitar"
                              labelPlacement="start"
                              disabled={
                                comisionCobradorEmpresa &&
                                comisionCobradorEmpresa.habilitar1 &&
                                (Number(comisionCobradorEmpresa.importe1) !==
                                  0 ||
                                  Number(
                                    comisionCobradorEmpresa.porcentaje1
                                  ) !== 0)
                                  ? false
                                  : true
                              }
                            />
                          </Grid>

                          <Grid item xs={12} md={2.5}>
                            <label htmlFor="entre3">Entre *</label>
                            <InputNumber
                              id="entre3"
                              inputId="entre3"
                              size={10}
                              value={
                                comisionCobradorEmpresa
                                  ? comisionCobradorEmpresa.entre3
                                  : 0
                              }
                              //onChange={(e) => guardarEntre3(e.value)}
                              mode="decimal"
                              locale="en-US"
                              minFractionDigits={2.5}
                              style={{
                                marginLeft: "0.7vw",
                                marginTop: "0.4vw",
                              }}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12} md={2.5}>
                            <label htmlFor="entre4">y Entre *</label>
                            <InputNumber
                              id="entre4"
                              inputId="entre4"
                              size={10}
                              value={
                                comisionCobradorEmpresa
                                  ? comisionCobradorEmpresa.entre4
                                  : 0
                              }
                              onChange={(e) =>
                                guardarComisionCobradorEmpresa({
                                  ...comisionCobradorEmpresa,
                                  entre4: e.value,
                                })
                              }
                              mode="decimal"
                              locale="en-US"
                              minFractionDigits={2}
                              style={{
                                marginLeft: "0.7vw",
                                marginTop: "0.4vw",
                              }}
                              disabled={
                                comisionCobradorEmpresa
                                  ? !comisionCobradorEmpresa.habilitar2
                                  : true
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={2.5}>
                            <label htmlFor="importe2">Importe</label>
                            <InputNumber
                              id="importe2"
                              inputId="importe2"
                              size={10}
                              value={
                                comisionCobradorEmpresa
                                  ? comisionCobradorEmpresa.importe2
                                  : 0
                              }
                              onChange={(e) =>
                                guardarComisionCobradorEmpresa({
                                  ...comisionCobradorEmpresa,
                                  importe2: e.value,
                                })
                              }
                              mode="decimal"
                              locale="en-US"
                              disabled={
                                comisionCobradorEmpresa &&
                                comisionCobradorEmpresa.habilitar2 &&
                                Number(comisionCobradorEmpresa.entre3) > 0 &&
                                Number(comisionCobradorEmpresa.entre4) > 0 &&
                                Number(comisionCobradorEmpresa.porcentaje2) ===
                                  0
                                  ? false
                                  : true
                              }
                              minFractionDigits={2}
                              style={{
                                marginLeft: "0.7vw",
                                marginTop: "0.4vw",
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2.5}>
                            <label htmlFor="porcentaje2">Porcentaje</label>
                            <InputNumber
                              id="porcentaje2"
                              inputId="porcentaje2"
                              size={10}
                              value={
                                comisionCobradorEmpresa
                                  ? comisionCobradorEmpresa.porcentaje2
                                  : 0
                              }
                              onChange={(e) =>
                                guardarComisionCobradorEmpresa({
                                  ...comisionCobradorEmpresa,
                                  porcentaje2: e.value,
                                })
                              }
                              mode="decimal"
                              locale="en-US"
                              disabled={
                                comisionCobradorEmpresa &&
                                comisionCobradorEmpresa.habilitar2 &&
                                Number(comisionCobradorEmpresa.entre3) > 0 &&
                                Number(comisionCobradorEmpresa.entre4) > 0 &&
                                Number(comisionCobradorEmpresa.importe2) === 0
                                  ? false
                                  : true
                              }
                              minFractionDigits={2}
                              style={{
                                marginLeft: "0.7vw",
                                marginTop: "0.4vw",
                              }}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            md={4}
                            style={{ marginTop: "1vw" }}
                          >
                            <FormControlLabel
                              value="yes"
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={
                                    comisionCobradorEmpresa
                                      ? comisionCobradorEmpresa.descontarIVA
                                      : false
                                  }
                                  onChange={(e) => {
                                    guardarComisionCobradorEmpresa({
                                      ...comisionCobradorEmpresa,
                                      descontarIVA: e.target.checked,
                                    });
                                  }}
                                  style={{ marginLeft: "0.5vw" }}
                                />
                              }
                              label="Descontar IVA"
                              labelPlacement="start"
                              disabled={
                                comisionCobradorEmpresa &&
                                comisionCobradorEmpresa.habilitarComision &&
                                comisionCobradorEmpresa.habilitar1 &&
                                !eliminarEmpresa &&
                                !eliminarCobrador
                                  ? false
                                  : true
                              }
                            />
                          </Grid>

                          <Grid item xs={12} md={8} />

                          <Grid
                            item
                            xs={12}
                            md={4}
                            style={{ marginTop: "1vw" }}
                          >
                            <FormControlLabel
                              value="yes"
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={copiaEmpresas}
                                  onChange={(e) => {
                                    setCopiaEmpresas(e.target.checked);
                                  }}
                                  style={{ marginLeft: "0.5vw" }}
                                />
                              }
                              label="Copia a todas las empresas"
                              labelPlacement="start"
                              disabled={
                                comisionCobradorEmpresa &&
                                comisionCobradorEmpresa.habilitarComision &&
                                comisionCobradorEmpresa.habilitar1 &&
                                !eliminarEmpresa &&
                                !eliminarCobrador
                                  ? false
                                  : true
                              }
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            md={4}
                            style={{ marginTop: "1vw" }}
                          >
                            <FormControlLabel
                              value="yes"
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={copiaCobradores}
                                  onChange={(e) => {
                                    setCopiaCobradores(e.target.checked);
                                  }}
                                  style={{ marginLeft: "0.5vw" }}
                                />
                              }
                              label="Copia a todos los cobradores"
                              labelPlacement="start"
                              disabled={
                                comisionCobradorEmpresa &&
                                comisionCobradorEmpresa.habilitarComision &&
                                comisionCobradorEmpresa.habilitar1 &&
                                !eliminarEmpresa &&
                                !eliminarCobrador
                                  ? false
                                  : true
                              }
                            />
                          </Grid>

                          <Grid item xs={12} md={4} />

                          <Grid
                            item
                            xs={12}
                            md={4}
                            style={{ marginTop: "1vw" }}
                          >
                            <FormControlLabel
                              value="yes"
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={eliminarEmpresa}
                                  onChange={(e) => {
                                    setEliminarEmpresa(e.target.checked);
                                  }}
                                  style={{ marginLeft: "0.5vw" }}
                                />
                              }
                              label="Eliminar comisión de la empresa"
                              labelPlacement="start"
                              disabled={
                                comisionCobradorEmpresa &&
                                comisionCobradorEmpresa.habilitarComision &&
                                empresa &&
                                comisionCobradorEmpresa.habilitar1 &&
                                !eliminarCobrador
                                  ? false
                                  : true
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            style={{ marginTop: "1vw" }}
                          >
                            <FormControlLabel
                              value="yes"
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={eliminarCobrador}
                                  onChange={(e) => {
                                    setEliminarCobrador(e.target.checked);
                                  }}
                                  style={{ marginLeft: "0.5vw" }}
                                />
                              }
                              label="Eliminar todas las comisiones del cobrador"
                              labelPlacement="start"
                              disabled={
                                comisionCobradorEmpresa &&
                                comisionCobradorEmpresa.habilitarComision &&
                                empresa &&
                                comisionCobradorEmpresa.habilitar1 &&
                                !eliminarEmpresa
                                  ? false
                                  : true
                              }
                            />
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        {comisionCobradorEmpresa ? (
                          <div className="datatable-responsive-demo">
                            <div className="card">
                              <Tooltip
                                target=".export-buttons>button"
                                position="bottom"
                              />
                              <DataTable
                                value={cobradorComision}
                                selection={selectedRowComision}
                                onSelectionChange={(e) => {
                                  setSelectedRowComision(e.value);
                                  obtenerComisionCobradorEmpresa({
                                    empresaId: e.value.empresaId,
                                    cobradorId: e.value.cobradorId,
                                  });
                                  setBandInicio(false);
                                  guardarEmpresaComision(null);
                                }}
                                selectionMode="single"
                                dataKey="id"
                                //header={header}
                                className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                                style={{ fontSize: "small", marginTop: "1vw" }}
                                globalFilter={globalFilter}
                                emptyMessage="No hay datos."
                                paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                //paginatorLeft={paginatorLeft}
                                //paginatorRight={paginatorRight}
                              >
                                <Column
                                  field="id"
                                  header="ID"
                                  //body={idTemplate}
                                  sortable
                                ></Column>
                                <Column
                                  field="empresas.nombre"
                                  header="Empresa"
                                  //body={idTemplate}
                                  sortable
                                ></Column>
                                <Column
                                  field="entre1"
                                  header="Entre"
                                  //body={descripcionTemplate}
                                  //sortable
                                ></Column>
                                <Column
                                  field="entre2"
                                  header="y Entre"
                                  //body={descripcionTemplate}
                                  //sortable
                                ></Column>
                                <Column
                                  field="importe1"
                                  header="Importe"
                                  //body={descripcionTemplate}
                                  //sortable
                                ></Column>
                                <Column
                                  field="porcentaje1"
                                  header="Porcentaje"
                                  //body={descripcionTemplate}
                                  //sortable
                                ></Column>
                                <Column
                                  field="entre3"
                                  header="Entre"
                                  //body={descripcionTemplate}
                                  //sortable
                                ></Column>
                                <Column
                                  field="entre4"
                                  header="y Entre"
                                  //body={descripcionTemplate}
                                  //sortable
                                ></Column>
                                <Column
                                  field="importe2"
                                  header="Importe"
                                  //body={descripcionTemplate}
                                  //sortable
                                ></Column>
                                <Column
                                  field="porcentaje2"
                                  header="Porcentaje"
                                  //body={descripcionTemplate}
                                  //sortable
                                ></Column>
                                <Column
                                  field="descontarIVA"
                                  header="Descuenta I.V.A."
                                  body={ivaTemplate}

                                  //sortable
                                ></Column>
                              </DataTable>
                            </div>
                          </div>
                        ) : null}
                      </TabPanel>
                    </Fragment>
                  ) : null}
                </Box>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

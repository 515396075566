import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";
import { InputNumber } from "primereact/inputnumber";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { InputMask } from "primereact/inputmask";
import IconButton from "@mui/material/IconButton";
import * as EmailValidator from "email-validator";
import { Dropdown } from "primereact/dropdown";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

export default function FacturacionCompras(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRowProveedores, setSelectedRowProveedores] = useState(null);
  const [selectedRowComprobantes, setSelectedRowComprobantes] = useState(null);
  const [selectedField, setSelectedField] = useState("Número de proveedor");
  const [comprobante, guardarComprobante] = useState(null);
  const [visible, setVisible] = useState(false);
  const [total, setTotal] = useState(0);
  const [bandInicio1, setBandInicio1] = useState(false);
  const [bandInicio2, setBandInicio2] = useState(false);
  const [bandInicio3, setBandInicio3] = useState(false);
  const [bandInicio4, setBandInicio4] = useState(false);
  const [bandInicio5, setBandInicio5] = useState(false);
  const [bandInicio6, setBandInicio6] = useState(false);
  const [indexTipoComprobante, setIndexTipoComprobante] = useState(null);
  const [indexEmpresa, setIndexEmpresa] = useState(null);
  const [indexMoneda, setIndexMoneda] = useState(null);
  const [indexCondicionesPagos, setIndexCondicionesPagos] = useState(null);
  const [indexConceptos, setIndexConceptos] = useState(null);
  const [alerta, setAlerta] = useState(false);
  const [cotizaciones, guardarCotizaciones] = useState([]);

  let navigate = useNavigate();

  let { id, proveedorId } = useParams();

  const idProps = props.idProps;
  const proveedorIdProps = props.proveedorIdProps;

  if (id === undefined && idProps !== undefined) {
    id = idProps;
    proveedorId = proveedorIdProps;
  }

  const opciones = [
    { label: "Número de proveedor", value: "id" },
    { label: "Apellido y nombre", value: "nombre" },
    { label: "Domicilio", value: "domicilio" },
    { label: "Teléfono", value: "telefono" },
    { label: "Documento", value: "dni" },
  ];
  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Descripción", dataKey: "descripcion" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const toast = useRef(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    comprobantes,
    proveedores,
    proveedor,
    empresa,
    empresas,
    ivas,
    cierre,
    monedas,
    monedaPredeterminada,
    empresaElegida,
    conceptos,
    condicionesPagos,
    numeroComprobante,
    tiposComprobantes,
    comprobanteAsociado,
    idComprobante,
    factura,
    items,
    configuracionesEmpresas,
    configuracionesCobradores,
    configuracionesFormasPagos,
    obtenerProveedoresComprobantes,
    obtenerAcceso,
    agregarComprobanteCompras,
    actualizarComprobante,
    obtenerProveedores,
    buscarProveedor,
    guardarProveedor,
    guardarComprobantes,
    guardarTitular,
    obtenerTiposComprobantesProveedores,
    obtenerNumeroComprobante,
    guardarNumeroComprobante,
    obtenerIvas,
    guardarProveedores,
    obtenerComprobanteAsociado,
    guardarComprobanteAsociado,
    guardarIdComprobante,
    obtenerConfiguracionesFacturacionCompras,
    crearComprobantePdf,
    urlPdf,
    enviarEmail,
    enviarWhatsApp,
    obtenerFacturaCompras,
    obtenerEmpresas,
    obtenerCondicionesPagos,
    obtenerConceptos,
    setVerComprobante,
    obtenerCierre,
    obtenerMonedas,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      if (id === "0") {
        obtenerAcceso({
          usuarioId: state.usuario.id,
          layout: "GenerarComprobantesCompras",
        });
      }
    }
  }, [state]);

  useEffect(() => {
    obtenerIvas();
    obtenerEmpresas();
    obtenerTiposComprobantesProveedores();
    obtenerCondicionesPagos();
    obtenerConceptos();
    obtenerMonedas();
    //obtenerConfiguracionesFacturacionCompras();
  }, []);

  const returnClick = (e) => {
    setSelectedRowProveedores(null);
    guardarProveedor(null);
    guardarComprobantes([]);
    guardarNumeroComprobante(null);
    guardarProveedores([]);
    guardarIdComprobante(null);
  };

  const findClick = (e) => {
    if (selectedField === null) {
      toast.current.show({
        severity: "warn",
        summary: "Información",
        detail: "Debe escribir algúna opción de busqueda.",
        life: 3000,
      });
      return;
    }

    if (globalFilter.trim().length === 0) {
      toast.current.show({
        severity: "warn",
        summary: "Información",
        detail: "Debe escribir algún texto a buscar.",
        life: 3000,
      });
      return;
    }

    obtenerProveedoresComprobantes({
      opcion: selectedField,
      texto: globalFilter,
    });
  };

  const handleBlurFechaContabilizacion = (e) => {
    let fecha = e.target.value;
    let periodo = fecha.substring(5, 7) + "/" + fecha.substring(2, 4);
    const cierre = {
      periodo: periodo,
    };
    const origen = "compras";
    obtenerCierre({ cierre, origen });
    setAlerta(true);
  };

  const addClick = (e) => {
    //props.history.push(`/comprobante/0`);

    if (
      !empresaElegida ||
      empresaElegida.id === 0 ||
      empresaElegida.nombre === null
    ) {
      toast.current.show({
        severity: "warn",
        summary: "Información",
        detail: "Debe seleccionar una empresa.",
        life: 3000,
      });
      return;
    }

    setVisible(true);
    //setSelectedRow(null);
    guardarComprobante({
      proveedorId: selectedRowProveedores.id,
      nombre: selectedRowProveedores.nombre_razonsocial,
      tipoContribuyenteId: selectedRowProveedores.tiposcontribuyentes.id,
      tipoContribuyente: selectedRowProveedores.tiposcontribuyentes.nombre,
      numeroDocumento: selectedRowProveedores.numerodocumento,
      domicilio: selectedRowProveedores.domicilio,
      email: selectedRowProveedores.email,
      celular: selectedRowProveedores.celular,
      fechaComprobante: new Date().toISOString().slice(0, 10),
      fechaContabilizacion: new Date().toISOString().slice(0, 10),
      periodo:
        new Date().toISOString().slice(0, 10).substring(5, 7) +
        "/" +
        new Date().toISOString().slice(0, 10).substring(2, 4),
      pdf: false,
      dolares: false,
      comprobanteId: null,
      comprobante: null,
      comprobanteAbreviatura: null,
      tipo: null,
      puntoVenta: null,
      numero: null,
      id_empresa: empresaElegida.id,
      id_condicionpago: null,
      condicionpago: null,
      id_concepto: null,
      concepto: null,
      detalle: null,
      neto21: null,
      neto105: null,
      neto27: null,
      iva21: null,
      iva105: null,
      iva27: null,
      exento: null,
      periva: null,
      periibbbsas: null,
      periibbcaba: null,
      pergan: null,
      retiva: null,
      retgan: null,
      retiibb: null,
      otrostributos: null,
      descuento: null,
      id_moneda: monedaPredeterminada,
    });

    guardarNumeroComprobante(null);
    guardarIdComprobante(null);

    setTimeout(() => {
      let campo = document.getElementById("empresa");
      campo.focus();
    }, 1000);
  };

  const exitClick = (e) => {
    setSelectedRowProveedores(null);
    guardarProveedor(null);
    guardarComprobantes([]);
    guardarNumeroComprobante(null);
    guardarProveedores([]);
    guardarIdComprobante(null);
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
    setVerComprobante(false);
    if (id !== "0") {
      navigate("/comprobantescompras");
    }
  };

  function ValidateCUITCUIL(cuit) {
    if (cuit.length != 13) return 0;

    var rv = false;
    var resultado = 0;
    var cuit_nro = cuit.replace("-", "");
    var codes = "6789456789";
    var cuit_long = parseInt(cuit_nro);
    var verificador = parseInt(cuit_nro[cuit_nro.length - 1]);
    var x = 0;

    while (x < 10) {
      var digitoValidador = parseInt(codes.substring(x, x + 1));
      if (isNaN(digitoValidador)) digitoValidador = 0;
      var digito = parseInt(cuit_nro.substring(x, x + 1));
      if (isNaN(digito)) digito = 0;
      var digitoValidacion = digitoValidador * digito;
      resultado += digitoValidacion;
      x++;
    }
    resultado = resultado % 11;
    rv = resultado == verificador;
    return rv;
  }

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar

    if (
      proveedor &&
      proveedor.id_tipodocumento === 3 && // C.U.I.T
      !ValidateCUITCUIL(proveedor.numerodocumento)
    ) {
      mensajeAlerta(
        "Comprobantes",
        "El Nº de C.U.I.T del proveedor es incorrecto !.",
        "error"
      );
      return;
    }

    if (
      !comprobante ||
      comprobante.fechaComprobante === undefined ||
      comprobante.fechaComprobante === null ||
      comprobante.fechaComprobante === 0 ||
      comprobante.fechaComprobante === ""
    ) {
      mensajeAlerta(
        "Comprobantes",
        "Fecha de comprobante es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !comprobante ||
      comprobante.fechaContabilizacion === undefined ||
      comprobante.fechaContabilizacion === null ||
      comprobante.fechaContabilizacion === 0 ||
      comprobante.fechaContabilizacion === ""
    ) {
      mensajeAlerta(
        "Comprobantes",
        "Fecha de contabilización es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !comprobante ||
      comprobante.periodo === undefined ||
      comprobante.periodo === null ||
      comprobante.periodo === 0 ||
      comprobante.periodo === ""
    ) {
      mensajeAlerta("Comprobantes", "Período es necesario !.", "error");
      return;
    }

    if (
      !comprobante ||
      comprobante.id_moneda === undefined ||
      comprobante.id_moneda === null ||
      comprobante.id_moneda === 0 ||
      comprobante.id_moneda === ""
    ) {
      mensajeAlerta("Comprobantes", "Moneda es necesario !.", "error");
      return;
    }

    if (comprobante && comprobante.id_moneda !== monedaPredeterminada) {
      if (
        !comprobante ||
        comprobante.cotizacion === undefined ||
        comprobante.cotizacion === null ||
        comprobante.cotizacion === 0 ||
        comprobante.cotizacion === ""
      ) {
        mensajeAlerta(
          "Comprobantes",
          "Debe ingresar una cotización !.",
          "error"
        );
        return;
      }
    }

    if (
      !comprobante ||
      comprobante.comprobanteId === undefined ||
      comprobante.comprobanteId === null ||
      comprobante.comprobanteId === 0 ||
      comprobante.comprobanteId === ""
    ) {
      mensajeAlerta("Comprobantes", "Comprobante es necesario !.", "error");
      return;
    }

    if (
      !comprobante ||
      comprobante.puntoVenta === undefined ||
      comprobante.puntoVenta === null ||
      comprobante.puntoVenta === 0 ||
      comprobante.puntoVenta === ""
    ) {
      mensajeAlerta("Comprobantes", "Punto de venta es necesario !.", "error");
      return;
    }

    if (
      !comprobante ||
      comprobante.numero === undefined ||
      comprobante.numero === null ||
      comprobante.numero === 0 ||
      comprobante.numero === ""
    ) {
      mensajeAlerta("Comprobantes", "Número es necesario !.", "error");
      return;
    }

    if (
      !comprobante ||
      comprobante.id_empresa === undefined ||
      comprobante.id_empresa === null ||
      comprobante.id_empresa === 0 ||
      comprobante.id_empresa === ""
    ) {
      mensajeAlerta("Comprobantes", "Empresa es necesaria !.", "error");
      return;
    }

    if (
      !comprobante ||
      comprobante.id_condicionpago === undefined ||
      comprobante.id_condicionpago === null ||
      comprobante.id_condicionpago === 0 ||
      comprobante.id_condicionpago === ""
    ) {
      mensajeAlerta(
        "Comprobantes",
        "Condición de pago es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !comprobante ||
      comprobante.id_concepto === undefined ||
      comprobante.id_concepto === null ||
      comprobante.id_concepto === 0 ||
      comprobante.id_concepto === ""
    ) {
      mensajeAlerta("Comprobantes", "Concepto es necesario !.", "error");
      return;
    }

    let total = 0;

    total =
      Number(comprobante.neto21) +
      Number(comprobante.neto105) +
      Number(comprobante.neto27) +
      Number(comprobante.iva21) +
      Number(comprobante.iva105) +
      Number(comprobante.iva27) +
      Number(comprobante.exento) +
      Number(comprobante.periva) +
      Number(comprobante.pergan) +
      Number(comprobante.exento) +
      Number(comprobante.periibbbsas) +
      Number(comprobante.periibbcaba) +
      Number(comprobante.retiva) +
      Number(comprobante.retgan) +
      Number(comprobante.retiibb) +
      Number(comprobante.otrostributos) -
      Number(comprobante.descuento);

    if (total <= 0) {
      mensajeAlerta("Comprobantes", "Debe ingresar algún valor !.", "error");
      return;
    }

    let moneda = monedas.findIndex((item) => item.id === comprobante.id_moneda);

    if (
      monedaPredeterminada &&
      monedaPredeterminada !== comprobante.id_moneda
    ) {
      confirmDialog({
        message: `Confirma la cotización de ${monedas[moneda].nombre} (${monedas[moneda].simbolo}) a $ ${comprobante.cotizacion} ?.`,
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        rejectLabel: "No",
        acceptLabel: "Si",
        accept: () => {
          setTimeout(() => {
            confirmarClick();
          }, 500);
        },
        reject: () => {
          return;
        },
      });
    } else {
      confirmarClick();
    }
  };

  const confirmarClick = () => {
    // Llamar al context

    if (
      id === "0" &&
      comprobante.comprobanteId !== 3 &&
      comprobante.comprobanteId !== 8 &&
      comprobante.comprobanteId !== 13 &&
      comprobante.comprobanteId !== 53 &&
      comprobante.comprobanteId !== 203 &&
      comprobante.comprobanteId !== 208 &&
      comprobante.comprobanteId !== 213 &&
      comprobante.comprobanteId !== 1001
    ) {
      confirmDialog({
        message: "Desea generar una órden de pago ?.",
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        rejectLabel: "No",
        acceptLabel: "Si",
        accept: () => acceptOrden(),
        reject: () => rejectOrden(),
      });
    } else {
      if (id === "0") {
        rejectOrden();
      } else {
        guardarIdComprobante(id);
      }
    }

    onHide();
    //exitClick();
  };

  const acceptOrden = () => {
    agregarComprobanteCompras({
      comprobante,
      proveedor,
      usuario: state.usuario.usuario,
      generaOrden: true,
    });
  };

  const rejectOrden = () => {
    agregarComprobanteCompras({
      comprobante,
      proveedor,
      usuario: state.usuario.id,
      generaOrden: false,
    });
  };

  const calcularTotal = () => {
    return (
      Number(comprobante.neto21) +
      Number(comprobante.neto105) +
      Number(comprobante.neto27) +
      Number(comprobante.iva21) +
      Number(comprobante.iva105) +
      Number(comprobante.iva27) +
      Number(comprobante.exento) +
      Number(comprobante.periva) +
      Number(comprobante.pergan) +
      Number(comprobante.periibbbsas) +
      Number(comprobante.periibbcaba) +
      Number(comprobante.retiva) +
      Number(comprobante.retgan) +
      Number(comprobante.retiibb) +
      Number(comprobante.otrostributos) -
      Number(comprobante.descuento)
    );
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Comprobantes",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    //exitClick();
    return;
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const titularTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.titular}></Checkbox>
      </Fragment>
    );
  };

  const suspendidoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.suspendido}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, comprobantes);
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(comprobantes);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "comprobantes");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const headerProveedores = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          defaultValue={opciones[0]}
          options={opciones}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Opciones de Búsqueda" />
          )}
          onChange={(event, value) => {
            if (value) {
              setSelectedField(value.label);
            } else {
              setSelectedField(null);
            }
          }}
        />

        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
            onKeyDown={handleEnterGlobal}
          />
        </span>
        <Button
          icon="pi pi-search"
          tooltip="Buscar"
          className="p-button-rounded p-button-help"
          style={{ marginLeft: "0em" }}
          onClick={findClick}
        />
        <Tag
          value="Proveedores - Facturación"
          style={{ marginLeft: "1em" }}
        ></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return comprobante && comprobante.anulado ? (
      <div>
        <Tag
          value={"Comprobante de Compras" + " ANULADO"}
          severity="danger"
        ></Tag>
      </div>
    ) : (
      <div>
        <Tag value="Comprobante de Compras"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        {id === "0" ? (
          <Fragment>
            <ButtonMui
              onClick={aceptarClick}
              color="primary"
              type="button"
              fullWidth
              variant="contained"
              startIcon={<CheckIcon />}
            >
              Aceptar
            </ButtonMui>
            <ButtonMui
              onClick={onHide}
              color="secondary"
              type="button"
              fullWidth
              variant="contained"
              startIcon={<CloseIcon />}
              autoFocus
            >
              Cancelar
            </ButtonMui>
          </Fragment>
        ) : (
          <ButtonMui
            onClick={onHide}
            color="secondary"
            type="button"
            fullWidth
            variant="contained"
            startIcon={<CloseIcon />}
            autoFocus
          >
            Salir
          </ButtonMui>
        )}
      </div>
    );
  };

  function handleEnterGlobal(e) {
    if (e.keyCode === 13) {
      findClick();
    }
  }

  function handleEnter(event) {
    if (event.keyCode === 13) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "tipoComprobante":
          campo = document.getElementById("cantidad");
          campo.focus();
          break;
        case "fecha":
          campo = document.getElementById("cantidad");
          campo.focus();
          break;
        case "precioUnitario":
          campo = document.getElementById("iva");
          campo.focus();
          break;
        case "iva":
          campo = document.getElementById("precioTotal");
          campo.focus();
          break;
        case "precioTotal":
          campo = document.getElementById("agregarArticulo");
          campo.focus();
          break;
      }
    }

    if (event.keyCode === 27) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "fecha":
          campo = document.getElementById("tipoComprobante");
          campo.focus();
          break;
        case "cantidad":
          campo = document.getElementById("fecha");
          campo.focus();
          break;
        case "descripcion":
          campo = document.getElementById("cantidad");
          campo.focus();
          break;
        case "precioUnitario":
          campo = document.getElementById("descripcion");
          campo.focus();
          break;
        case "iva":
          campo = document.getElementById("precioUnitario");
          campo.focus();
          break;
        case "precioTotal":
          campo = document.getElementById("iva");
          campo.focus();
          break;
      }
    }
  }

  // INICIO de IF

  if (id !== undefined && id !== "0") {
    if (!bandInicio1) {
      obtenerFacturaCompras({ id });
      buscarProveedor({ proveedorId });
      setIndexTipoComprobante(null);
      setIndexEmpresa(null);
      setIndexMoneda(null);
      setIndexCondicionesPagos(null);
      setIndexConceptos(null);
      setBandInicio1(true);
    }

    if (
      bandInicio1 &&
      !bandInicio2 &&
      factura &&
      proveedor &&
      tiposComprobantes &&
      tiposComprobantes.length > 0 &&
      empresas &&
      empresas.length > 0 &&
      condicionesPagos &&
      condicionesPagos.length > 0 &&
      conceptos &&
      conceptos.length > 0
    ) {
      let index1 = tiposComprobantes.findIndex(
        (item) => item.abreviatura === factura.comprobante
      );
      setIndexTipoComprobante(index1);

      let index2 = empresas.findIndex((item) => item.id === factura.id_empresa);
      setIndexEmpresa(index2);

      let index3 = condicionesPagos.findIndex(
        (item) => item.id === factura.id_condicionpago
      );
      setIndexCondicionesPagos(index3);

      let index4 = conceptos.findIndex(
        (item) => item.id === factura.id_concepto
      );
      setIndexConceptos(index4);

      let index5 = monedas.findIndex((item) => item.id === factura.id_moneda);
      setIndexMoneda(index5);

      setBandInicio2(true);
    }

    if (
      bandInicio1 &&
      bandInicio2 &&
      !bandInicio3 &&
      indexTipoComprobante !== null &&
      indexEmpresa !== null &&
      indexMoneda !== null &&
      indexCondicionesPagos !== null &&
      indexConceptos !== null
    ) {
      guardarComprobante({
        proveedorId: factura.id_proveedor,
        nombre: factura.nombre_proveedor,
        domicilio: proveedor.domicilio,
        tipoContribuyenteId: proveedor.tiposcontribuyentes.id,
        tipoContribuyente: proveedor.tiposcontribuyentes.nombre,
        numeroDocumento: proveedor.numerodocumento,
        fechaComprobante: factura.fechacomprobante,
        fechaContabilizacion: factura.fechacontabilizacion,
        periodo: factura.periodo,
        dolares: factura.dolares,
        id_moneda: factura.id_moneda,
        moneda: monedas[indexMoneda].nombre,
        simbolo: monedas[indexMoneda].simbolo,
        cotizacion: factura.cotizacion,
        comprobanteId: tiposComprobantes[indexTipoComprobante].id,
        comprobante: tiposComprobantes[indexTipoComprobante].nombre,
        comprobanteAbreviatura:
          tiposComprobantes[indexTipoComprobante].abreviatura,
        puntoVenta: factura.puntoventa,
        numero: factura.numero,
        id_empresa: factura.id_empresa,
        empresa: empresas[indexEmpresa].nombre,
        id_condicionpago: factura.id_condicionpago,
        condicionpago: condicionesPagos[indexCondicionesPagos].nombre,
        id_concepto: factura.id_concepto,
        concepto: conceptos[indexConceptos].nombre,
        detalle: factura.detalle,
        neto21: factura.neto21,
        neto105: factura.neto105,
        neto27: factura.neto27,
        exento: factura.exento,
        iva105: factura.iva1,
        iva21: factura.iva2,
        iva27: factura.iva3,
        periva: factura.periva,
        pergan: factura.perganancias,
        periibbbsas: factura.periibbbsas,
        periibbcaba: factura.periibbcaba,
        retiva: factura.retiva,
        retgan: factura.retganancias,
        retiibb: factura.retiibb,
        otrostributos: factura.otrostributos,
        descuento: factura.descuento,
        anulado: factura.anulado,
      });
      setBandInicio3(true);
      setVisible(true);
    }
  }

  if (alerta && cierre && cierre.cerrado) {
    setAlerta(false);

    let periodo =
      comprobante.fechaContabilizacion.substring(5, 7) +
      "/" +
      comprobante.fechaContabilizacion.substring(2, 4);

    if (periodo === cierre.periodo) {
      toast.current.show({
        severity: "error",
        summary: "Período Cerrado",
        detail:
          "El período " + cierre.periodo + " para compras esta cerrado. !! ",
        life: 3000,
      });

      let elem = document.getElementById("fechaContabilizacion");
      if (elem) {
        elem.focus();
      }
    }
  }

  // Fin de IF

  //console.log(proveedores);
  //console.log(statusAcceso);

  return (
    <Fragment>
      {id === "0" ? <Menu /> : null}
      <Toast ref={toast} />
      <ConfirmDialog />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {proveedores ? (
            <Fragment>
              {id === "0" ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ align: "center", margin: "1em" }}
                >
                  <Fragment>
                    <Button
                      icon="pi pi-plus"
                      tooltip="Agregar Factura"
                      className="p-button-rounded p-button-warning"
                      disabled={!selectedRowProveedores}
                      style={{ marginLeft: "1em" }}
                      onClick={addClick}
                    />
                    <Button
                      icon="pi pi-times"
                      tooltip="Cerrar"
                      className="p-button-rounded p-button-primary"
                      style={{ marginLeft: "1em" }}
                      onClick={exitClick}
                    />
                    <Button
                      icon="pi pi-arrow-left"
                      tooltip="Volver"
                      className="p-button-rounded p-button-success"
                      style={{ marginLeft: "1em" }}
                      onClick={returnClick}
                    />
                    <button
                      id="verComprobante"
                      type="button"
                      onClick={(e) => {
                        setVisible(true);
                      }}
                      style={{ visibility: "hidden" }}
                    >
                      Ver
                    </button>
                  </Fragment>
                  <div className="datatable-responsive-demo">
                    <div className="card">
                      <Tooltip
                        target=".export-buttons>button"
                        position="bottom"
                      />

                      <Fragment>
                        <DataTable
                          value={proveedores}
                          selection={selectedRowProveedores}
                          onSelectionChange={(e) => {
                            setSelectedRowProveedores(e.value);
                            //buscarTitular({ titularId: e.value.id_titular });
                          }}
                          selectionMode="single"
                          dataKey="id"
                          header={headerProveedores}
                          className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                          style={{ fontSize: "small", marginTop: "1vw" }}
                          //globalFilter={globalFilter}
                          emptyMessage="No hay datos."
                          paginator
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                          rows={100}
                          rowsPerPageOptions={[10, 20, 50]}
                          //paginatorLeft={paginatorLeft}
                          //paginatorRight={paginatorRight}
                        >
                          <Column
                            field="id"
                            header="Nº Proveedor"
                            //body={idTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="nombre_razonsocial"
                            header="Razón Social"
                            //body={descripcionTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="domicilio"
                            header="Domicilio"
                            //body={stockTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="numerodocumento"
                            header="D.N.I./C.U.I.T."
                            //body={unidadTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="telefono1"
                            header="Teléfono"
                            //body={unidadTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="celular"
                            header="Celular"
                            //body={unidadTemplate}
                            sortable
                          ></Column>
                        </DataTable>
                      </Fragment>
                    </div>
                  </div>
                </Grid>
              ) : null}

              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                maximized={true}
                maximizable={true}
                onHide={onHide}
                footer={renderFooter()}
                breakpoints={{ "960px": "95vw", "641px": "100vw" }}
                style={{ width: "95", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={1} style={{ marginTop: "1vw" }}>
                  <Grid
                    container
                    xs={12}
                    md={5}
                    style={{ borderStyle: "solid", borderWidth: "1px" }}
                  >
                    <Grid item xs={12} md={2.5} style={{ padding: "0.2vw" }}>
                      <TextField
                        value={comprobante ? comprobante.proveedorId : null}
                        required
                        variant="standard"
                        id="proveedorId"
                        label="Nº de Proveedor"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={9} style={{ padding: "0.2vw" }}>
                      <TextField
                        value={comprobante ? comprobante.nombre : null}
                        required
                        variant="standard"
                        id="nombre"
                        label="Nombre/Razón Social"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={11} style={{ padding: "0.2vw" }}>
                      <TextField
                        value={comprobante ? comprobante.domicilio : null}
                        required
                        variant="standard"
                        id="domicilio"
                        label="Domicilio"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} style={{ padding: "0.2vw" }}>
                      <TextField
                        value={
                          comprobante ? comprobante.tipoContribuyente : null
                        }
                        required
                        variant="standard"
                        id="tipoContribuyente"
                        label="Tipo de Contribuyente"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={5} style={{ padding: "0.2vw" }}>
                      <TextField
                        value={comprobante ? comprobante.numeroDocumento : null}
                        required
                        variant="standard"
                        id="numeroDocumento"
                        label="C.U.I.T. / D.N.I."
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    xs={12}
                    md={7}
                    style={{ borderStyle: "solid", borderWidth: "1px" }}
                  >
                    <Grid item xs={12} md={2} style={{ padding: "0.2vw" }}>
                      <TextField
                        id="fechaComprobante"
                        value={
                          comprobante && comprobante.fechaComprobante
                            ? comprobante.fechaComprobante
                            : null
                        }
                        label="Fecha Comprobante"
                        type="date"
                        fullWidth
                        variant="standard"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "red" },
                        }}
                        disabled={id !== "0"}
                        onChange={(e) =>
                          guardarComprobante({
                            ...comprobante,
                            fechaComprobante: e.target.value,
                          })
                        }
                        //onKeyDown={handleEnter}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={2}
                      style={{ padding: "0.2vw", marginLeft: "1vw" }}
                    >
                      <TextField
                        id="fechaContabilizacion"
                        value={
                          comprobante && comprobante.fechaContabilizacion
                            ? comprobante.fechaContabilizacion
                            : null
                        }
                        label="Fecha Contabilización"
                        type="date"
                        fullWidth
                        variant="standard"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "red" },
                        }}
                        disabled={id !== "0"}
                        onChange={(e) =>
                          guardarComprobante({
                            ...comprobante,
                            fechaContabilizacion: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlurFechaContabilizacion(e)}
                        //onKeyDown={handleEnter}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={2}
                      style={{ padding: "0.2vw", marginLeft: "0.5vw" }}
                    >
                      <label
                        htmlFor="periodo"
                        style={{ color: "red", fontSize: "small" }}
                      >
                        Período
                      </label>
                      <InputMask
                        id="periodo"
                        mask="99/99"
                        value={comprobante ? comprobante.periodo : null}
                        placeholder="99/99"
                        //onBlur={handleEnter}
                        size={3}
                        disabled={id !== "0"}
                        onChange={(e) =>
                          guardarComprobante({
                            ...comprobante,
                            periodo: e.value,
                          })
                        }
                        style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                      ></InputMask>
                    </Grid>

                    {id === "0" ? (
                      <Grid item xs={12} md={3} style={{ padding: "0.2vw" }}>
                        <Autocomplete
                          id="moneda"
                          size={"small"}
                          fullWidth
                          noOptionsText={"No hay opciones"}
                          options={monedas ? monedas : null}
                          defaultValue={
                            comprobante && comprobante.id_moneda !== null
                              ? monedas[
                                  monedas.findIndex(
                                    (item) => item.id === comprobante.id_moneda
                                  )
                                ]
                              : monedas[
                                  monedas.findIndex(
                                    (item) => item.id === monedaPredeterminada
                                  )
                                ]
                          }
                          getOptionLabel={(option) =>
                            option.nombre + " (" + option.simbolo + ")"
                          }
                          openOnFocus={true}
                          disablePortal
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Moneda *"
                              variant="standard"
                              InputLabelProps={{
                                style: { color: "red" },
                              }}
                            />
                          )}
                          //onKeyDown={handleEnter}
                          onChange={(event, value) => {
                            if (value) {
                              guardarComprobante({
                                ...comprobante,
                                id_moneda: value.id,
                                moneda: value.nombre,
                              });
                              let moneda = monedas.findIndex(
                                (item) => item.id === value.id
                              );

                              let cotiza = [];
                              cotiza.push({
                                id: "1",
                                nombre: "Compra",
                                cotizacion: monedas[moneda].compra,
                              });
                              cotiza.push({
                                id: "2",
                                nombre: "Venta",
                                cotizacion: monedas[moneda].venta,
                              });
                              cotiza.push({
                                id: "3",
                                nombre: "Tarjeta",
                                cotizacion: monedas[moneda].tarjeta,
                              });
                              guardarCotizaciones(cotiza);
                            } else {
                              guardarComprobante({
                                ...comprobante,
                                id_moneda: null,
                                moneda: null,
                              });
                              guardarCotizaciones([]);
                            }
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={3} style={{ padding: "0.2vw" }}>
                        <TextField
                          value={
                            comprobante && comprobante.moneda
                              ? comprobante.moneda +
                                " (" +
                                comprobante.simbolo +
                                ")"
                              : ""
                          }
                          variant="standard"
                          id="moneda"
                          label="Moneda *"
                          fullWidth
                          disabled
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "red" },
                          }}
                        />
                      </Grid>
                    )}

                    {comprobante &&
                    monedaPredeterminada &&
                    comprobante.id_moneda !== monedaPredeterminada ? (
                      <Fragment>
                        {id === "0" ? (
                          <Grid
                            item
                            xs={12}
                            md={2.5}
                            style={{ padding: "0.2vw" }}
                          >
                            <Autocomplete
                              id="cotizacion"
                              size={"small"}
                              fullWidth
                              noOptionsText={"No hay opciones"}
                              options={cotizaciones ? cotizaciones : null}
                              getOptionLabel={(option) =>
                                "$ " +
                                option.cotizacion +
                                " (" +
                                option.nombre +
                                ")"
                              }
                              openOnFocus={true}
                              disablePortal
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Cotización *"
                                  variant="standard"
                                  InputLabelProps={{
                                    style: { color: "red" },
                                  }}
                                />
                              )}
                              //onKeyDown={handleEnter}
                              onChange={(event, value) => {
                                if (value) {
                                  guardarComprobante({
                                    ...comprobante,
                                    cotizacion: value.cotizacion,
                                    cotizacionTipo: value.nombre,
                                  });
                                } else {
                                  guardarComprobante({
                                    ...comprobante,
                                    cotizacion: null,
                                    cotizacionTipo: null,
                                  });
                                }
                              }}
                            />
                          </Grid>
                        ) : (
                          <Grid
                            item
                            xs={12}
                            md={2.5}
                            style={{ padding: "0.2vw" }}
                          >
                            <TextField
                              value={
                                comprobante && comprobante.moneda
                                  ? comprobante.moneda +
                                    " (" +
                                    comprobante.simbolo +
                                    ")"
                                  : ""
                              }
                              variant="standard"
                              id="cotizacion"
                              label="Cotización *"
                              fullWidth
                              disabled
                              InputLabelProps={{
                                shrink: true,
                                style: { color: "red" },
                              }}
                            />
                          </Grid>
                        )}
                      </Fragment>
                    ) : null}

                    {/*      

                      {comprobante &&
                      monedaPredeterminada &&
                      comprobante.id_moneda !== monedaPredeterminada ? (
                        <Grid
                          item
                          xs={12}
                          md={2.5}
                          style={{ padding: "0.2vw", marginLeft: "0.5vw" }}
                        >
                          <label
                            htmlFor="cotizacion"
                            style={{ color: "red", fontSize: "small" }}
                          >
                            Cotización
                          </label>
                          <InputNumber
                            inputId="cotizacion"
                            value={comprobante ? comprobante.cotizacion : null}
                            //onKeyDown={handleEnter}
                            onChange={(e) => {
                              guardarComprobante({
                                ...comprobante,
                                cotizacion: e.value,
                              });
                            }}
                            disabled={
                              (comprobante &&
                                comprobante.id_moneda ===
                                  monedas.find((item) => item.predeterminado)
                                    .id) ||
                              id !== "0"
                            }
                            size={5}
                            mode="decimal"
                            locale="en-US"
                            minFractionDigits={2}
                            style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                          />
                        </Grid>
                      ) : null}

                          */}

                    {id === "0" ? (
                      <Grid item xs={12} md={12} style={{ padding: "0.2vw" }}>
                        <Autocomplete
                          id="tipoComprobante"
                          fullWidth
                          noOptionsText={"No hay opciones"}
                          options={tiposComprobantes ? tiposComprobantes : null}
                          //defaultValue={tiposComprobantes[0]}
                          getOptionLabel={(option) => option.nombre}
                          openOnFocus={true}
                          disablePortal
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Comprobante *"
                              variant="standard"
                              InputLabelProps={{
                                style: { color: "red" },
                              }}
                            />
                          )}
                          //onKeyDown={handleEnter}
                          onChange={(event, value) => {
                            if (value) {
                              guardarComprobante({
                                ...comprobante,
                                comprobanteId: value.id,
                                comprobante: value.nombre,
                                comprobanteAbreviatura: value.abreviatura,
                                tipo: value.abreviatura.substring(
                                  value.abreviatura.length - 1
                                ),
                                puntoVenta: null,
                                numero: null,
                              });
                              guardarNumeroComprobante(null);
                            } else {
                              guardarComprobante({
                                ...comprobante,
                                comprobanteId: null,
                                comprobante: null,
                                comprobanteAbreviatura: null,
                                tipo: null,
                                puntoVenta: null,
                                numero: null,
                              });
                              guardarNumeroComprobante(null);
                            }
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={12} style={{ padding: "0.2vw" }}>
                        <TextField
                          value={
                            comprobante && comprobante.comprobante
                              ? comprobante.comprobante
                              : ""
                          }
                          variant="standard"
                          id="tipoComprobante"
                          label="Comprobante *"
                          fullWidth
                          disabled
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "red" },
                          }}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} md={3} style={{ padding: "0.2vw" }}>
                      <label htmlFor="puntoVenta" style={{ color: "red" }}>
                        P. Venta
                      </label>
                      <InputNumber
                        inputId="puntoVenta"
                        value={comprobante ? comprobante.puntoVenta : null}
                        //onKeyDown={handleEnter}
                        onChange={(e) => {
                          guardarComprobante({
                            ...comprobante,
                            puntoVenta: e.value,
                          });
                        }}
                        disabled={id !== "0"}
                        size={4}
                        //mode="decimal"
                        //locale="en-US"
                        //minFractionDigits={0}
                        useGrouping={false}
                        style={{ marginLeft: "0.5vw", marginTop: "0.8vw" }}
                      />
                    </Grid>

                    <Grid item xs={12} md={3} style={{ padding: "0.2vw" }}>
                      <label htmlFor="numero" style={{ color: "red" }}>
                        Número
                      </label>
                      <InputNumber
                        inputId="numero"
                        value={comprobante ? comprobante.numero : null}
                        //onKeyDown={handleEnter}
                        onChange={(e) => {
                          guardarComprobante({
                            ...comprobante,
                            numero: e.value,
                          });
                        }}
                        disabled={id !== "0"}
                        size={6}
                        //mode="decimal"
                        //locale="en-US"
                        //minFractionDigits={0}
                        useGrouping={false}
                        style={{ marginLeft: "0.5vw", marginTop: "0.8vw" }}
                      />
                    </Grid>

                    {id === "0" ? (
                      <Grid item xs={12} md={6} style={{ padding: "0.2vw" }}>
                        <Autocomplete
                          id="empresa"
                          fullWidth
                          noOptionsText={"No hay opciones"}
                          options={
                            empresas
                              ? empresas.filter((item) => item.habilitado)
                              : null
                          }
                          defaultValue={
                            comprobante && comprobante.id_empresa !== null
                              ? empresas[
                                  empresas.findIndex(
                                    (item) => item.id === comprobante.id_empresa
                                  )
                                ]
                              : empresas[
                                  empresas.findIndex(
                                    (item) => item.id === empresaElegida.id
                                  )
                                ]
                          }
                          getOptionLabel={(option) => option.nombre}
                          openOnFocus={true}
                          disablePortal
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Empresa *"
                              variant="standard"
                              InputLabelProps={{
                                style: { color: "red" },
                              }}
                            />
                          )}
                          disabled
                          //onKeyDown={handleEnter}
                          /*
                            onChange={(event, value) => {
                              if (value) {
                                guardarComprobante({
                                  ...comprobante,
                                  id_empresa: value.id,
                                  empresa: value.nombre,
                                });
                              } else {
                                guardarComprobante({
                                  ...comprobante,
                                  id_empresa: null,
                                  empresa: null,
                                });
                              }
                            }}
                            */
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={6} style={{ padding: "0.2vw" }}>
                        <TextField
                          value={
                            comprobante && comprobante.empresa
                              ? comprobante.empresa
                              : ""
                          }
                          variant="standard"
                          id="tipoComprobante"
                          label="Empresa *"
                          fullWidth
                          disabled
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "red" },
                          }}
                        />
                      </Grid>
                    )}

                    {id === "0" ? (
                      <Grid item xs={12} md={5} style={{ padding: "0.2vw" }}>
                        <Autocomplete
                          id="condicionPago"
                          fullWidth
                          noOptionsText={"No hay opciones"}
                          options={condicionesPagos ? condicionesPagos : null}
                          getOptionLabel={(option) => option.nombre}
                          openOnFocus={true}
                          disablePortal
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Condición de Pago *"
                              variant="standard"
                              InputLabelProps={{
                                style: { color: "red" },
                              }}
                            />
                          )}
                          //onKeyDown={handleEnter}
                          onChange={(event, value) => {
                            if (value) {
                              guardarComprobante({
                                ...comprobante,
                                id_condicionpago: value.id,
                                condicionpago: value.nombre,
                              });
                            } else {
                              guardarComprobante({
                                ...comprobante,
                                id_condicionpago: null,
                                condicionpago: null,
                              });
                            }
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={6} style={{ padding: "0.2vw" }}>
                        <TextField
                          value={
                            comprobante && comprobante.condicionpago
                              ? comprobante.condicionpago
                              : ""
                          }
                          variant="standard"
                          id="condicionPago"
                          label="Condición Pago *"
                          fullWidth
                          disabled
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "red" },
                          }}
                        />
                      </Grid>
                    )}

                    {id === "0" ? (
                      <Grid item xs={12} md={7} style={{ padding: "0.2vw" }}>
                        <Autocomplete
                          id="concepto"
                          fullWidth
                          noOptionsText={"No hay opciones"}
                          options={
                            conceptos
                              ? conceptos.filter((item) => item.habilitado)
                              : null
                          }
                          getOptionLabel={(option) => option.nombre}
                          openOnFocus={true}
                          disablePortal
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Concepto *"
                              variant="standard"
                              InputLabelProps={{
                                style: { color: "red" },
                              }}
                            />
                          )}
                          //onKeyDown={handleEnter}
                          onChange={(event, value) => {
                            if (value) {
                              guardarComprobante({
                                ...comprobante,
                                id_concepto: value.id,
                                concepto: value.nombre,
                              });
                            } else {
                              guardarComprobante({
                                ...comprobante,
                                id_concepto: null,
                                concepto: null,
                              });
                            }
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={6} style={{ padding: "0.2vw" }}>
                        <TextField
                          value={
                            comprobante && comprobante.concepto
                              ? comprobante.concepto
                              : ""
                          }
                          variant="standard"
                          id="concepto"
                          label="Concepto *"
                          fullWidth
                          disabled
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "red" },
                          }}
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{ marginBottom: "0.5vw" }}
                    >
                      <TextField
                        value={comprobante ? comprobante.detalle : null}
                        id="detalle"
                        label="Detalle"
                        fullWidth
                        variant="standard"
                        size="small"
                        disabled={id !== "0"}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        //onKeyDown={handleEnter}
                        onChange={(e) =>
                          guardarComprobante({
                            ...comprobante,
                            detalle: e.target.value,
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} style={{ marginTop: "1vw" }}>
                    <Grid
                      container
                      xs={12}
                      md={9}
                      style={{ borderStyle: "solid", borderWidth: "1px" }}
                    >
                      <Grid item xs={12} md={2.5}>
                        <label htmlFor="neto21">Neto 21%</label>
                        <InputNumber
                          inputId="neto21"
                          value={comprobante ? comprobante.neto21 : null}
                          //onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarComprobante({
                              ...comprobante,
                              neto21: e.value,
                              iva21: (Number(e.value) * 21) / 100,
                            })
                          }
                          size={10}
                          disabled={id !== "0"}
                          mode="decimal"
                          locale="en-US"
                          minFractionDigits={2}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <label htmlFor="neto105">Neto 10.5%</label>
                        <InputNumber
                          inputId="neto105"
                          value={comprobante ? comprobante.neto105 : null}
                          //onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarComprobante({
                              ...comprobante,
                              neto105: e.value,
                              iva105: (Number(e.value) * 10.5) / 100,
                            })
                          }
                          size={10}
                          disabled={id !== "0"}
                          mode="decimal"
                          locale="en-US"
                          minFractionDigits={2}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2.5}>
                        <label htmlFor="neto27">Neto 27%</label>
                        <InputNumber
                          inputId="neto27"
                          value={comprobante ? comprobante.neto27 : null}
                          //onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarComprobante({
                              ...comprobante,
                              neto27: e.value,
                              iva27: (Number(e.value) * 27) / 100,
                            })
                          }
                          size={10}
                          disabled={id !== "0"}
                          mode="decimal"
                          locale="en-US"
                          minFractionDigits={2}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <label htmlFor="exento">Monot./No Grav./Ex. </label>
                        <InputNumber
                          inputId="exento"
                          value={comprobante ? comprobante.exento : null}
                          //onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarComprobante({
                              ...comprobante,
                              exento: e.value,
                            })
                          }
                          size={10}
                          disabled={id !== "0"}
                          mode="decimal"
                          locale="en-US"
                          minFractionDigits={2}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2.5}>
                        <label htmlFor="iva21">I.V.A. 21%</label>
                        <InputNumber
                          inputId="iva21"
                          value={comprobante ? comprobante.iva21 : null}
                          //onKeyDown={handleEnter}
                          disabled
                          size={10}
                          mode="decimal"
                          locale="en-US"
                          minFractionDigits={2}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <label htmlFor="iva105">I.V.A. 10.5%</label>
                        <InputNumber
                          inputId="iva105"
                          value={comprobante ? comprobante.iva105 : null}
                          //onKeyDown={handleEnter}
                          disabled
                          size={10}
                          mode="decimal"
                          locale="en-US"
                          minFractionDigits={2}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2.5}>
                        <label htmlFor="iva27">I.V.A. 27%</label>
                        <InputNumber
                          inputId="iva27"
                          value={comprobante ? comprobante.iva27 : null}
                          //onKeyDown={handleEnter}
                          disabled
                          size={10}
                          mode="decimal"
                          locale="en-US"
                          minFractionDigits={2}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={3} />

                      <Grid item xs={12} md={2.5}>
                        <label htmlFor="periva">Perc. I.V.A.</label>
                        <InputNumber
                          inputId="periva"
                          value={comprobante ? comprobante.periva : null}
                          //onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarComprobante({
                              ...comprobante,
                              periva: e.value,
                            })
                          }
                          disabled={id !== "0"}
                          size={10}
                          mode="decimal"
                          locale="en-US"
                          minFractionDigits={2}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2.5}>
                        <label htmlFor="pergan">Perc. Gan.</label>
                        <InputNumber
                          inputId="pergan"
                          value={comprobante ? comprobante.pergan : null}
                          //onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarComprobante({
                              ...comprobante,
                              pergan: e.value,
                            })
                          }
                          disabled={id !== "0"}
                          size={10}
                          mode="decimal"
                          locale="en-US"
                          minFractionDigits={2}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={3.5}>
                        <label htmlFor="periibbbsas">
                          Perc. II.BB. BS. As.
                        </label>
                        <InputNumber
                          inputId="periibbbsas"
                          value={comprobante ? comprobante.periibbbsas : null}
                          //onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarComprobante({
                              ...comprobante,
                              periibbbsas: e.value,
                            })
                          }
                          disabled={id !== "0"}
                          size={10}
                          mode="decimal"
                          locale="en-US"
                          minFractionDigits={2}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={3.5}>
                        <label htmlFor="periibbcaba">
                          Perc. II.BB. C.A.B.A.
                        </label>
                        <InputNumber
                          inputId="periibbcaba"
                          value={comprobante ? comprobante.periibbcaba : null}
                          //onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarComprobante({
                              ...comprobante,
                              periibbcaba: e.value,
                            })
                          }
                          disabled={id !== "0"}
                          size={10}
                          mode="decimal"
                          locale="en-US"
                          minFractionDigits={2}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2.5}>
                        <label htmlFor="retiva">Ret. I.V.A.</label>
                        <InputNumber
                          inputId="retiva"
                          value={comprobante ? comprobante.retiva : null}
                          //onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarComprobante({
                              ...comprobante,
                              retiva: e.value,
                            })
                          }
                          disabled={id !== "0"}
                          size={10}
                          mode="decimal"
                          locale="en-US"
                          minFractionDigits={2}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2.5}>
                        <label htmlFor="retgan">Ret. Gan.</label>
                        <InputNumber
                          inputId="retgan"
                          value={comprobante ? comprobante.retgan : null}
                          //onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarComprobante({
                              ...comprobante,
                              retgan: e.value,
                            })
                          }
                          disabled={id !== "0"}
                          size={10}
                          mode="decimal"
                          locale="en-US"
                          minFractionDigits={2}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <label htmlFor="retiibb">Ret. II.BB.</label>
                        <InputNumber
                          inputId="retiibb"
                          value={comprobante ? comprobante.retiibb : null}
                          //onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarComprobante({
                              ...comprobante,
                              retiibb: e.value,
                            })
                          }
                          disabled={id !== "0"}
                          size={10}
                          mode="decimal"
                          locale="en-US"
                          minFractionDigits={2}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={4} />

                      <Grid
                        item
                        xs={12}
                        md={3}
                        style={{ marginBottom: "0.5vw" }}
                      >
                        <label htmlFor="otrostributos">Otros tributos</label>
                        <InputNumber
                          inputId="otrostributos"
                          value={comprobante ? comprobante.otrostributos : null}
                          //onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarComprobante({
                              ...comprobante,
                              otrostributos: e.value,
                            })
                          }
                          disabled={id !== "0"}
                          size={10}
                          mode="decimal"
                          locale="en-US"
                          minFractionDigits={2}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={3}
                        style={{ marginBottom: "0.5vw" }}
                      >
                        <label htmlFor="descuento">Descuento</label>
                        <InputNumber
                          inputId="descuento"
                          value={comprobante ? comprobante.descuento : null}
                          //onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarComprobante({
                              ...comprobante,
                              descuento: e.value,
                            })
                          }
                          disabled={id !== "0"}
                          size={10}
                          mode="decimal"
                          locale="en-US"
                          minFractionDigits={2}
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      xs={12}
                      md={3}
                      style={{ borderStyle: "solid", borderWidth: "1px" }}
                    >
                      {comprobante ? (
                        <Grid item xs={12} md={12}>
                          <Tag
                            value={
                              "TOTAL: " + parseFloat(calcularTotal()).toFixed(2)
                            }
                            style={{ marginLeft: "1em", marginTop: "1em" }}
                          ></Tag>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import * as EmailValidator from "email-validator";
import FacturacionCompras from "./FacturacionCompras";
import OrdenPago from "./OrdenPago";

export default function Proveedores(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [globalFilterCtaCte, setGlobalFilterCtaCte] = useState(null);
  const [globalFilterSaldos, setGlobalFilterSaldos] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  let navigate = useNavigate();

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Nombre", dataKey: "nombre_razonsocial" },
    { title: "Domicilio", dataKey: "domicilio" },
    { title: "Teléfono", dataKey: "telefono1" },
    { title: "D.N.I./C.U.I.T.", dataKey: "numerodocumento" },
  ];

  const exportColumnsCtaCte = [
    { title: "ID", dataKey: "id" },
    { title: "Fecha", dataKey: "fecha" },
    { title: "Comprobante", dataKey: "comprobante" },
    { title: "Debe", dataKey: "debe" },
    { title: "Haber", dataKey: "haber" },
    { title: "Saldo", dataKey: "saldo" },
  ];

  const exportColumnsSaldos = [
    { title: "ID", dataKey: "id" },
    { title: "Fecha", dataKey: "fecha" },
    { title: "Comprobante", dataKey: "comprobante" },
    { title: "Total", dataKey: "total" },
    { title: "Saldo", dataKey: "saldo" },
  ];

  const [proveedor, guardarProveedor] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleCtaCte, setVisibleCtaCte] = useState(false);
  const [visibleSaldos, setVisibleSaldos] = useState(false);
  const [selectedRowCtaCte, setSelectedRowCtaCte] = useState(null);
  const [selectedRowSaldos, setSelectedRowSaldos] = useState(null);
  const [visibleComprobante, setVisibleComprobante] = useState(false);
  const [comprobanteIdVisualizar, guardarComprobanteIdVisualizar] =
  useState(null);
  const [
    comprobanteTitularIdVisualizar,
    guardarComprobanteTitularIdVisualizar,
  ] = useState(null);
  const [comprobanteTipoVisualizar, guardarComprobanteTipoVisualizar] =
    useState(null);

  const [detalle, guardarDetalle] = useState(null);
  const [fecha, guardarFecha] = useState(null);
  const toast = useRef(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    proveedores,
    localidades,
    condicionesPagos,
    tiposDocumentos,
    tiposContribuyentes,
    ctasCtes,
    saldos,
    verComprobante,
    obtenerProveedores,
    obtenerAcceso,
    agregarProveedor,
    actualizarProveedor,
    obtenerLocalidades,
    obtenerTiposDocumentos,
    obtenerTiposContribuyentes,
    obtenerCondicionesPagos,
    obtenerCtaCteProveedor,
    obtenerSaldosProveedor,
    guardarCtasCtes,
    setVerComprobante,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Proveedores" });
    }
  }, [state]);

  useEffect(() => {
    obtenerProveedores();
    obtenerLocalidades();
    obtenerTiposDocumentos();
    obtenerTiposContribuyentes();
    obtenerCondicionesPagos();
  }, []);

  const editClick = (e) => {
    if (selectedRow) {
      guardarProveedor(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un proveedor.",
        life: 3000,
      });
    }
  };

  const ctaCteClick = (e) => {
    if (selectedRow) {
      guardarProveedor(selectedRow);
      obtenerCtaCteProveedor({ id: selectedRow.id });
      setTimeout(() => {
        setVisibleCtaCte(true);
      }, 1000);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Cuenta Corriente.",
        detail: "Debe seleccionar un proveedor.",
        life: 3000,
      });
    }
  };

  const saldosClick = (e) => {
    if (selectedRow) {
      guardarProveedor(selectedRow);
      obtenerSaldosProveedor({ id: selectedRow.id });
      setTimeout(() => {
        setVisibleSaldos(true);
      }, 1000);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Saldos Pendientes.",
        detail: "Debe seleccionar un proveedor.",
        life: 3000,
      });
    }
  };

  const addClick = (e) => {
    //props.history.push(`/proveedor/0`);
    setVisible(true);
    setSelectedRow(null);
    guardarProveedor({
      habilitado: true,
    });
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHideCtaCte = (e) => {
    setVisibleCtaCte(false);
  };

  const onHideSaldos = (e) => {
    setVisibleSaldos(false);
  };

  const onHide = (e) => {
    setVisible(false);
  };

  function is_numeric(str) {
    return /^\d+$/.test(str);
  }

  function ValidateCUITCUIL(cuit) {
    if (cuit.length != 13) return 0;

    var rv = false;
    var resultado = 0;
    var cuit_nro = cuit.replace("-", "");
    var codes = "6789456789";
    var cuit_long = parseInt(cuit_nro);
    var verificador = parseInt(cuit_nro[cuit_nro.length - 1]);
    var x = 0;

    while (x < 10) {
      var digitoValidador = parseInt(codes.substring(x, x + 1));
      if (isNaN(digitoValidador)) digitoValidador = 0;
      var digito = parseInt(cuit_nro.substring(x, x + 1));
      if (isNaN(digito)) digito = 0;
      var digitoValidacion = digitoValidador * digito;
      resultado += digitoValidacion;
      x++;
    }
    resultado = resultado % 11;
    rv = resultado == verificador;
    return rv;
  }

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !proveedor ||
      proveedor.nombre_razonsocial === undefined ||
      proveedor.nombre_razonsocial === null ||
      proveedor.nombre_razonsocial.trim() === ""
    ) {
      mensajeAlerta("Proveedores", "Nombre es necesario !.", "error");
      return;
    }

    if (
      !proveedor ||
      proveedor.domicilio === undefined ||
      proveedor.domicilio === null ||
      proveedor.domicilio.trim() === ""
    ) {
      mensajeAlerta("Proveedores", "El domicilio es necesario !.", "error");
      return;
    }

    if (
      !proveedor ||
      proveedor.id_localidad === undefined ||
      proveedor.id_localidad === null ||
      proveedor.id_localidad === 0
    ) {
      mensajeAlerta("Proveedores", "La localidad es necesaria !.", "error");
      return;
    }

    if (
      proveedor &&
      proveedor.email !== undefined &&
      proveedor.email !== null &&
      proveedor.email.trim() !== ""
    ) {
      if (!EmailValidator.validate(proveedor.email)) {
        mensajeAlerta("Proveedores", "El email es incorrecto !.", "error");
        return;
      }
    }

    if (
      !proveedor ||
      proveedor.id_tipodocumento === undefined ||
      proveedor.id_tipodocumento === null ||
      proveedor.id_tipodocumento === 0
    ) {
      mensajeAlerta(
        "Proveedores",
        "El tipo de documento es necesario !.",
        "error"
      );
      return;
    }

    if (
      !proveedor ||
      proveedor.numerodocumento === undefined ||
      proveedor.numerodocumento === null ||
      proveedor.numerodocumento === 0 ||
      proveedor.numerodocumento.trim() === ""
    ) {
      mensajeAlerta(
        "Proveedores",
        "El Nº de documento o C.U.I.T. es necesario !.",
        "error"
      );
      return;
    }

    if (
      proveedor &&
      proveedor.id_tipodocumento === 4 && // D.N.I
      !is_numeric(proveedor.numerodocumento)
    ) {
      mensajeAlerta(
        "Proveedores",
        "El Nº de documento es incorrecto !.",
        "error"
      );
      return;
    }

    if (
      proveedor &&
      proveedor.id_tipodocumento === 3 && // C.U.I.T
      !ValidateCUITCUIL(proveedor.numerodocumento)
    ) {
      mensajeAlerta(
        "Proveedores",
        "El Nº de C.U.I.T es incorrecto !.",
        "error"
      );
      return;
    }

    if (
      !proveedor ||
      proveedor.id_tipocontribuyente === undefined ||
      proveedor.id_tipocontribuyente === null ||
      proveedor.id_tipocontribuyente === 0 ||
      proveedor.id_tipocontribuyente === ""
    ) {
      mensajeAlerta(
        "Proveedores",
        "El tipo de contribuyente es necesario !.",
        "error"
      );
      return;
    }

    if (
      proveedor &&
      (proveedor.id_tipocontribuyente === 2 ||
        proveedor.id_tipocontribuyente === 3 ||
        proveedor.id_tipocontribuyente === 6) &&
      proveedor.id_tipodocumento !== 3
    ) {
      mensajeAlerta(
        "Proveedores",
        "Al tipo de contribuyente le corresponde C.U.I.T. !.",
        "error"
      );
      return;
    }

    if (
      proveedor &&
      (proveedor.id_tipocontribuyente === 1 ||
        proveedor.id_tipocontribuyente === 4 ||
        proveedor.id_tipocontribuyente === 5) &&
      proveedor.id_tipodocumento !== 4
    ) {
      mensajeAlerta(
        "Proveedores",
        "Al tipo de contribuyente le corresponde D.N.I. !.",
        "error"
      );
      return;
    }

    if (
      !proveedor ||
      proveedor.id_condicionpago === undefined ||
      proveedor.id_condicionpago === null ||
      proveedor.id_condicionpago === 0 ||
      proveedor.id_condicionpago === ""
    ) {
      mensajeAlerta(
        "Proveedores",
        "La condicion de pago es necesaria !.",
        "error"
      );
      return;
    }

    // Llamar al context
    if (selectedRow) {
      actualizarProveedor({ proveedor });
    } else {
      agregarProveedor({ proveedor });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerProveedores();
    }, 2000);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Proveedores",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, proveedores, {
          styles: {
            fontSize: 8,
          },
        });
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(proveedores);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "proveedores");
    });
  };

  const exportPdfCtaCte = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumnsCtaCte, ctasCtes);
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcelCtaCte = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(ctasCtes);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "CtasCtes");
    });
  };

  const exportPdfSaldos = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumnsSaldos, saldos);
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcelSaldos = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(saldos);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Saldos");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Proveedores"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Proveedor"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const idTemplate = (rowData) => {
    if (Number(rowData.saldoComprobante) === 0) {
      return (
        <Fragment>
          <div className="verde">{rowData.id}</div>
        </Fragment>
      );
    } else {
      return <Fragment>{rowData.id}</Fragment>;
    }
  };

  const fechaTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fecha)}</Fragment>;
  };

  const opcionesTemplate = (rowData) => {
    return (
      <Fragment>
        <Button
          icon="pi pi-search"
          rounded
          text
          severity="success"
          onClick={(e) => visualizar(rowData)}
          aria-label="Bookmark"
          tooltip="Visualizar"
          tooltipOptions={{ position: "bottom" }}
        />
      </Fragment>
    );
  };

  function formatDate(dateString) {
    var allDate = dateString.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  const visualizar = (rowData) => {
    guardarComprobanteIdVisualizar(rowData.id.slice(1));
    guardarComprobanteTitularIdVisualizar(rowData.id_proveedor);
    guardarComprobanteTipoVisualizar(rowData.id.slice(0,1));
    setVisibleComprobante(true);
  };

  const onHideComprobante = (e) => {
    setVisibleComprobante(false);
  };

  const headerCtaCte = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcelCtaCte}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdfCtaCte}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilterCtaCte(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag
          value={
            selectedRow && ctasCtes && ctasCtes.length > 0
              ? "Cuenta Corriente de " +
                selectedRow.id +
                " - " +
                selectedRow.nombre_razonsocial +
                " - Saldo: " +
                parseFloat(ctasCtes[ctasCtes.length - 1].saldo).toFixed(2)
              : selectedRow && ctasCtes && ctasCtes.length === 0
              ? "Cuenta Corriente de " +
                selectedRow.id +
                " - " +
                selectedRow.nombre_razonsocial +
                " - Saldo: " +
                parseFloat(0).toFixed(2)
              : "Cuenta Corriente"
          }
        ></Tag>
      </div>
    </Fragment>
  );

  const headerSaldos = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcelSaldos}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdfSaldos}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilterSaldos(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag
          value={
            selectedRow && saldos && saldos.length > 0
              ? "Saldos Pendientes de " +
                selectedRow.id +
                " - " +
                selectedRow.nombre_razonsocial +
                " - Saldo: " +
                parseFloat(saldos[saldos.length - 1].saldoFinal).toFixed(2)
              : selectedRow && saldos && saldos.length === 0
              ? "Saldos Pendientes de " +
                selectedRow.id +
                " - " +
                selectedRow.nombre_razonsocial +
                " - Saldo: " +
                parseFloat(0).toFixed(2)
              : "Saldos Pendientes"
          }
        ></Tag>
      </div>
    </Fragment>
  );


    // INICIO

    if (!verComprobante && visibleComprobante) {
      guardarComprobanteIdVisualizar(null);
      guardarComprobanteTitularIdVisualizar(null);
      setVerComprobante(true);
      onHideComprobante();
    }
  
    // FIN
  


  //console.log(ctasCtes);
  //console.log(state);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {proveedores ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ align: "center", margin: "1em" }}
              >
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-pencil"
                  tooltip="Editar"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                <Button
                  icon="pi pi-align-justify"
                  tooltip="Cta. Cte."
                  className="p-button-rounded p-button-success"
                  style={{ marginLeft: "1em" }}
                  onClick={ctaCteClick}
                />
                <Button
                  icon="pi pi-clock"
                  tooltip="Saldos Pendientes"
                  className="p-button-rounded p-button-warning"
                  style={{ marginLeft: "1em" }}
                  onClick={saldosClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={proveedores}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        //body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="nombre_razonsocial"
                        header="Nombre/Razón Social"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="domicilio"
                        header="Domicilio"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="telefono1"
                        header="Teléfono"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="numerodocumento"
                        header="D.N.I./C.U.I.T."
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>

              <Dialog
                //header={renderHeaderCtaCte()}
                visible={visibleCtaCte}
                closable={true}
                maximizable={true}
                //maximized={true}
                onHide={() => onHideCtaCte()}
                //footer={renderFooter()}
                breakpoints={{ "960px": "95vw" }}
                style={{ width: "95vw", paddingBottom: "0" }}
                position="center"
              >
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={ctasCtes}
                      selection={selectedRowCtaCte}
                      onSelectionChange={(e) => setSelectedRowCtaCte(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={headerCtaCte}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilterCtaCte}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={20}
                      rowsPerPageOptions={[10, 20, 50, 100, 500]}
                    >
                      <Column
                        field="id"
                        header="ID"
                        body={idTemplate}
                        //sortable
                      ></Column>
                      <Column
                        field="fecha"
                        header="Fecha"
                        body={fechaTemplate}
                        //sortable
                      ></Column>
                      <Column
                        field="comprobante"
                        header="Comprobante"
                        //body={habilitadoTemplate}
                        //sortable
                      ></Column>
                      <Column
                        field="ordenId"
                        header="Orden"
                        //body={habilitadoTemplate}
                        //sortable
                      ></Column>
                      <Column
                        field="haber"
                        header="Haber"
                        //body={propioTemplate}
                        bodyStyle={{ textAlign: "right" }}
                        //sortable
                      ></Column>
                      <Column
                        field="moneda"
                        header="Moneda"
                        //body={propioTemplate}
                        bodyStyle={{ textAlign: "right" }}
                        //sortable
                      ></Column>
                      <Column
                        field="cotizacion"
                        header="Cotización"
                        //body={propioTemplate}
                        bodyStyle={{ textAlign: "right" }}
                        //sortable
                      ></Column>
                      <Column
                        field="haber"
                        header="Haber"
                        //body={propioTemplate}
                        bodyStyle={{ textAlign: "right" }}
                        //sortable
                      ></Column>
                      <Column
                        field="debe"
                        header="Debe"
                        //body={propioTemplate}
                        bodyStyle={{ textAlign: "right" }}
                        //sortable
                      ></Column>
                      <Column
                        field="saldo"
                        header="Saldo"
                        bodyStyle={{ textAlign: "right" }}
                        //body={propioTemplate}
                        //sortable
                      ></Column>
                      <Column
                        header="Opciones"
                        body={opcionesTemplate}
                        //body={descripcionTemplate}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Dialog>

              <Dialog
                //header={renderHeaderCtaCte()}
                visible={visibleSaldos}
                closable={true}
                maximizable={true}
                //maximized={true}
                onHide={() => onHideSaldos()}
                //footer={renderFooter()}
                breakpoints={{ "960px": "95vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="center"
              >
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={saldos}
                      selection={selectedRowSaldos}
                      onSelectionChange={(e) => setSelectedRowSaldos(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={headerSaldos}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilterSaldos}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={20}
                      rowsPerPageOptions={[10, 20, 50, 100, 500]}
                    >
                      <Column
                        field="id"
                        header="ID"
                        //body={idTemplate}
                        //sortable
                      ></Column>
                      <Column
                        field="fecha"
                        header="Fecha"
                        body={fechaTemplate}
                        //sortable
                      ></Column>
                      <Column
                        field="comprobante"
                        header="Comprobante"
                        //body={habilitadoTemplate}
                        //sortable
                      ></Column>
                      <Column
                        field="total"
                        header="Total"
                        //body={propioTemplate}
                        bodyStyle={{ textAlign: "right" }}
                        //sortable
                      ></Column>
                      <Column
                        field="saldo"
                        header="Saldo"
                        bodyStyle={{ textAlign: "right" }}
                        //body={propioTemplate}
                        //sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Dialog>

              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "95vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h6"
                      style={{ textDecoration: "underline", color: "coral" }}
                    >
                      Datos Personales
                    </Typography>
                  </Grid>

                  {selectedRow ? (
                    <Grid item xs={12} md={2}>
                      <TextField
                        value={proveedor ? proveedor.id : null}
                        required
                        variant="standard"
                        id="id"
                        label="ID"
                        fullWidth
                        disabled
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={10}>
                    <TextField
                      value={proveedor ? proveedor.nombre_razonsocial : null}
                      required
                      variant="standard"
                      id="nombre"
                      label="Nombre/Razón Social"
                      fullWidth
                      onChange={(e) =>
                        guardarProveedor({
                          ...proveedor,
                          nombre_razonsocial: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      value={proveedor ? proveedor.domicilio : null}
                      id="domicilio"
                      label="Domicilio"
                      fullWidth
                      required
                      variant="standard"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      //onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarProveedor({
                          ...proveedor,
                          domicilio: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      value={proveedor ? proveedor.entre1 : null}
                      id="entre1"
                      label="Entre"
                      fullWidth
                      variant="standard"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      //onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarProveedor({
                          ...proveedor,
                          entre1: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      value={proveedor ? proveedor.entre2 : null}
                      id="entre2"
                      label="Entre"
                      fullWidth
                      variant="standard"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      //onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarProveedor({
                          ...proveedor,
                          entre2: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="localidad"
                      //disabled
                      fullWidth
                      options={localidades ? localidades : null}
                      disablePortal
                      required
                      //defaultValue={colores[idColorDefault]}

                      value={
                        localidades &&
                        proveedor &&
                        proveedor.id_localidad !== undefined &&
                        proveedor.id_localidad !== null
                          ? localidades[
                              localidades.findIndex(
                                (item) => item.id === proveedor.id_localidad
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Localidad *"
                          size="small"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarProveedor({
                            ...proveedor,
                            id_localidad: value.id,
                            localidad: value.nombre,
                          });
                        } else {
                          guardarProveedor({
                            ...proveedor,
                            id_localidad: null,
                            localidad: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={1}>
                    <TextField
                      value={proveedor ? proveedor.cp : null}
                      id="cp"
                      label="Cod. Postal"
                      fullWidth
                      variant="standard"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      //onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarProveedor({
                          ...proveedor,
                          cp: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <TextField
                      value={proveedor ? proveedor.telefono1 : null}
                      id="telefono1"
                      label="Teléfono"
                      fullWidth
                      variant="standard"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      //onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarProveedor({
                          ...proveedor,
                          telefono1: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <TextField
                      value={proveedor ? proveedor.celular : null}
                      id="celular"
                      label="Celular"
                      fullWidth
                      variant="standard"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      //onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarProveedor({
                          ...proveedor,
                          celular: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      value={proveedor ? proveedor.email : null}
                      id="email"
                      label="Email"
                      fullWidth
                      variant="standard"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      //onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarProveedor({
                          ...proveedor,
                          email: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Autocomplete
                      id="tipoDocumento"
                      //disabled
                      fullWidth
                      options={tiposDocumentos ? tiposDocumentos : null}
                      disablePortal
                      value={
                        tiposDocumentos &&
                        proveedor &&
                        proveedor.id_tipodocumento !== undefined &&
                        proveedor.id_tipodocumento !== null
                          ? tiposDocumentos[
                              tiposDocumentos.findIndex(
                                (item) => item.id === proveedor.id_tipodocumento
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Tipo Documento *"
                          size="small"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarProveedor({
                            ...proveedor,
                            id_tipodocumento: value.id,
                            tipodocumento: value.nombre,
                          });
                        } else {
                          guardarProveedor({
                            ...proveedor,
                            id_tipodocumento: null,
                            tipodocumento: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    {proveedor && proveedor.id_tipodocumento === 4 /*DNI*/ ? (
                      <TextField
                        value={proveedor ? proveedor.numerodocumento : null}
                        id="numeroDocumento"
                        label="Nº D.N.I."
                        fullWidth
                        required
                        variant="standard"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        //onKeyDown={handleEnter}
                        onChange={(e) =>
                          guardarProveedor({
                            ...proveedor,
                            numerodocumento: e.target.value,
                          })
                        }
                      />
                    ) : (
                      <Fragment>
                        <label htmlFor="id">Nº C.U.I.T *</label>
                        <InputMask
                          id="numeroDocumento"
                          mask="99-99999999-9"
                          value={proveedor ? proveedor.numerodocumento : null}
                          placeholder="99-99999999-9"
                          //onBlur={handleEnter}
                          size={15}
                          onChange={(e) =>
                            guardarProveedor({
                              ...proveedor,
                              numerodocumento: e.value,
                            })
                          }
                          style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        ></InputMask>
                        {/*
                                <IconButton
                type="submit"
                aria-label="search"
                onClick={(e) => handleClickOpenCuit()}
              >
                <SearchIcon />
              </IconButton>
                  */}
                      </Fragment>
                    )}
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      id="tipoContribuyente"
                      //disabled
                      fullWidth
                      options={tiposContribuyentes ? tiposContribuyentes : null}
                      disablePortal
                      value={
                        tiposContribuyentes &&
                        proveedor &&
                        proveedor.id_tipocontribuyente !== undefined &&
                        proveedor.id_tipocontribuyente !== null
                          ? tiposContribuyentes[
                              tiposContribuyentes.findIndex(
                                (item) =>
                                  item.id === proveedor.id_tipocontribuyente
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Tipo Contribuyente *"
                          size="small"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarProveedor({
                            ...proveedor,
                            id_tipocontribuyente: value.id,
                            tipocontribuyente: value.nombre,
                          });
                        } else {
                          guardarProveedor({
                            ...proveedor,
                            id_tipocontribuyente: null,
                            tipocontribuyente: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      id="tipoContribuyente"
                      //disabled
                      fullWidth
                      options={condicionesPagos ? condicionesPagos : null}
                      disablePortal
                      value={
                        condicionesPagos &&
                        proveedor &&
                        proveedor.id_condicionpago !== undefined &&
                        proveedor.id_condicionpago !== null
                          ? condicionesPagos[
                              condicionesPagos.findIndex(
                                (item) => item.id === proveedor.id_condicionpago
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Condicion de Pago *"
                          size="small"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarProveedor({
                            ...proveedor,
                            id_condicionpago: value.id,
                            condicionpago: value.nombre,
                          });
                        } else {
                          guardarProveedor({
                            ...proveedor,
                            id_condicionpago: null,
                            condicionpago: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} style={{ marginTop: "1vw" }}>
                    <Typography
                      variant="h6"
                      style={{ textDecoration: "underline", color: "coral" }}
                    >
                      Impuestos
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={proveedor ? proveedor.marcaarba : null}
                          //onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarProveedor({
                              ...proveedor,
                              marcaarba: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Tributa Ret. A.R.B.A."
                      labelPlacement="start"
                      style={{ marginTop: "0.5vw" }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <label htmlFor="alicuotaArba">Alícuota A.R.B.A.</label>
                    <InputNumber
                      inputId="alicuotaArba"
                      value={proveedor ? proveedor.alicuotaarba : null}
                      disabled={proveedor && proveedor.marcaarba ? false : true}
                      //onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarProveedor({
                          ...proveedor,
                          alicuotaarba: e.value,
                        })
                      }
                      size={8}
                      mode="decimal"
                      locale="en-US"
                      minFractionDigits={2}
                      style={{ marginLeft: "0.7vw", marginTop: "0vw" }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={proveedor ? proveedor.marcaagip : null}
                          //onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarProveedor({
                              ...proveedor,
                              marcaagip: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Tributa Ret. A.G.I.P."
                      labelPlacement="start"
                      style={{ marginTop: "0.5vw" }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <label htmlFor="alicuotaAgip">Alícuota A.G.I.P.</label>
                    <InputNumber
                      inputId="alicuotaAgip"
                      value={proveedor ? proveedor.alicuotaagip : null}
                      //onKeyDown={handleEnter}
                      disabled={proveedor && proveedor.marcaagip ? false : true}
                      onChange={(e) =>
                        guardarProveedor({
                          ...proveedor,
                          alicuotaagip: e.value,
                        })
                      }
                      size={8}
                      mode="decimal"
                      locale="en-US"
                      minFractionDigits={2}
                      style={{ marginLeft: "0.7vw", marginTop: "0vw" }}
                    />
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                //header={renderHeader()}
                visible={visibleComprobante}
                closable={true}
                maximizable={true}
                maximized={true}
                onHide={() => onHideComprobante()}
                //footer={renderFooter()}
                breakpoints={{ "960px": "95vw" }}
                style={{ width: "95vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  {comprobanteIdVisualizar && comprobanteTitularIdVisualizar && comprobanteTipoVisualizar === "c" ? (
                    <FacturacionCompras
                      idProps={comprobanteIdVisualizar}
                      proveedorIdProps={comprobanteTitularIdVisualizar}
                    />
                  ) : null}
                  {comprobanteIdVisualizar && comprobanteTitularIdVisualizar && comprobanteTipoVisualizar === "o" ? (
                    <OrdenPago
                      idProps={comprobanteIdVisualizar}
                      proveedorIdProps={comprobanteTitularIdVisualizar}
                    />
                  ) : null}
                </Grid>
              </Dialog>

            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";
import { InputNumber } from "primereact/inputnumber";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { InputMask } from "primereact/inputmask";
import IconButton from "@mui/material/IconButton";
import * as EmailValidator from "email-validator";
import { Dropdown } from "primereact/dropdown";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputSwitch } from "primereact/inputswitch";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

export default function OrdenPago(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRowProveedores, setSelectedRowProveedores] = useState(null);
  const [selectedRowOrdenes, setSelectedRowOrdenes] = useState(null);
  const [selectedRowComprobantes, setSelectedRowComprobantes] = useState(null);
  const [selectedField, setSelectedField] = useState("Número de proveedor");
  const [orden, guardarOrden] = useState(null);
  const [visible, setVisible] = useState(false);
  const [total, setTotal] = useState(0);
  const [bandInicio1, setBandInicio1] = useState(false);
  const [bandInicio2, setBandInicio2] = useState(false);
  const [bandInicio3, setBandInicio3] = useState(false);
  const [bandInicio4, setBandInicio4] = useState(false);
  const [bandInicio5, setBandInicio5] = useState(false);
  const [bandInicio6, setBandInicio6] = useState(false);
  const [indexEmpresa, setIndexEmpresa] = useState(null);
  const [indexMoneda, setIndexMoneda] = useState(null);
  const [indexConceptos, setIndexConceptos] = useState(null);
  const [rowClick, setRowClick] = useState(true);
  const [bandChange, setBandChange] = useState(false);

  let navigate = useNavigate();

  let { id, proveedorId, origen } = useParams();

  const idProps = props.idProps;
  const proveedorIdProps = props.proveedorIdProps;

  if (id === undefined && idProps !== undefined) {
    id = idProps;
    proveedorId = proveedorIdProps;
    origen = "compras";
  }

  const opciones = [
    { label: "Número de proveedor", value: "id" },
    { label: "Apellido y nombre", value: "nombre" },
    { label: "Domicilio", value: "domicilio" },
    { label: "Teléfono", value: "telefono" },
    { label: "Documento", value: "dni" },
  ];
  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Descripción", dataKey: "descripcion" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const toast = useRef(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    ordenes,
    proveedores,
    proveedor,
    empresa,
    empresas,
    monedas,
    monedaPredeterminada,
    ivas,
    empresaElegida,
    conceptos,
    condicionesPagos,
    numeroOrden,
    tiposOrdenes,
    ordenAsociado,
    idOrden,
    ordenPago,
    factura,
    comprobantes,
    items,
    configuracionesEmpresas,
    configuracionesCobradores,
    configuracionesFormasPagos,
    obtenerProveedoresComprobantes,
    obtenerAcceso,
    agregarOrdenPago,
    actualizarOrden,
    obtenerProveedores,
    buscarProveedor,
    guardarProveedor,
    guardarOrdenes,
    guardarTitular,
    obtenerTiposOrdenesProveedores,
    obtenerNumeroOrden,
    obtenerIvas,
    guardarProveedores,
    obtenerOrdenAsociado,
    guardarOrdenAsociado,
    guardarIdOrden,
    obtenerConfiguracionesOrdenesPagos,
    crearOrdenPdf,
    urlPdf,
    enviarEmail,
    enviarWhatsApp,
    obtenerOrdenPago,
    obtenerEmpresas,
    obtenerCondicionesPagos,
    obtenerConceptos,
    obtenerComprobantesAdeudadosProveedor,
    obtenerComprobantesOrden,
    actualizarOrdenPago,
    setVerComprobante,
    obtenerMonedas,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      if (id === "0") {
        obtenerAcceso({
          usuarioId: state.usuario.id,
          layout: "GenerarOrdenesPagos",
        });
      }
    }
  }, [state]);

  useEffect(() => {
    obtenerEmpresas();
    obtenerConceptos();
    obtenerMonedas();
  }, []);

  const returnClick = (e) => {
    setSelectedRowProveedores(null);
    guardarProveedor(null);
    guardarOrdenes([]);
    guardarProveedores([]);
    guardarIdOrden(null);
    navigate("/ordenespagos/" + origen);
  };

  const findClick = (e) => {
    if (selectedField === null) {
      toast.current.show({
        severity: "warn",
        summary: "Información",
        detail: "Debe escribir algúna opción de busqueda.",
        life: 3000,
      });
      return;
    }

    if (globalFilter.trim().length === 0) {
      toast.current.show({
        severity: "warn",
        summary: "Información",
        detail: "Debe escribir algún texto a buscar.",
        life: 3000,
      });
      return;
    }

    obtenerProveedoresComprobantes({
      opcion: selectedField,
      texto: globalFilter,
    });
  };

  const addClick = (e) => {
    //props.history.push(`/orden/0`);

    if (
      !empresaElegida ||
      empresaElegida.id === 0 ||
      empresaElegida.nombre === null
    ) {
      toast.current.show({
        severity: "warn",
        summary: "Información",
        detail: "Debe seleccionar una empresa.",
        life: 3000,
      });
      return;
    }


    obtenerComprobantesAdeudadosProveedor({
      proveedorId: selectedRowProveedores.id,
      empresaId: empresaElegida.id,
    });


    setVisible(true);
    //setSelectedRow(null);
    guardarOrden({
      ordenId: null,
      id_orden: null,
      proveedorId: selectedRowProveedores.id,
      nombre: selectedRowProveedores.nombre_razonsocial,
      tipoContribuyenteId: selectedRowProveedores.tiposcontribuyentes.id,
      tipoContribuyente: selectedRowProveedores.tiposcontribuyentes.nombre,
      numeroDocumento: selectedRowProveedores.numerodocumento,
      domicilio: selectedRowProveedores.domicilio,
      email: selectedRowProveedores.email,
      fecha: new Date().toISOString().slice(0, 10),
      vencimiento: null,
      id_empresa: empresaElegida.id,
      id_concepto: null,
      concepto: null,
      id_moneda: selectedRowProveedores.id_moneda,
    });

    setSelectedRowProveedores(null);
    guardarIdOrden(null);
    setTimeout(() => {
      let campo = document.getElementById("fecha");
      if (campo) {
        campo.focus();
      }
    }, 1000);
  };

  const exitClick = (e) => {
    setSelectedRowProveedores(null);
    guardarProveedor(null);
    guardarOrdenes([]);
    guardarProveedores([]);
    guardarIdOrden(null);
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
    if (id === "0") {
      navigate("/ordenpago/0/0/" + origen);
    } else {
      if (idProps === undefined) {
        navigate("/ordenespagos/" + origen);
      } else {
        setVerComprobante(false);
      }
    }
  };

  function ValidateCUITCUIL(cuit) {
    if (cuit.length != 13) return 0;

    var rv = false;
    var resultado = 0;
    var cuit_nro = cuit.replace("-", "");
    var codes = "6789456789";
    var cuit_long = parseInt(cuit_nro);
    var verificador = parseInt(cuit_nro[cuit_nro.length - 1]);
    var x = 0;

    while (x < 10) {
      var digitoValidador = parseInt(codes.substring(x, x + 1));
      if (isNaN(digitoValidador)) digitoValidador = 0;
      var digito = parseInt(cuit_nro.substring(x, x + 1));
      if (isNaN(digito)) digito = 0;
      var digitoValidacion = digitoValidador * digito;
      resultado += digitoValidacion;
      x++;
    }
    resultado = resultado % 11;
    rv = resultado == verificador;
    return rv;
  }

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar

    if (
      proveedor &&
      proveedor.id_tipodocumento === 3 && // C.U.I.T
      !ValidateCUITCUIL(proveedor.numerodocumento)
    ) {
      mensajeAlerta(
        "Ordenes de Pago",
        "El Nº de C.U.I.T del proveedor es incorrecto !.",
        "error"
      );
      return;
    }

    if (
      !orden ||
      orden.fecha === undefined ||
      orden.fecha === null ||
      orden.fecha === 0 ||
      orden.fecha === ""
    ) {
      mensajeAlerta(
        "Ordenes de Pago",
        "Fecha de orden es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !orden ||
      orden.id_empresa === undefined ||
      orden.id_empresa === null ||
      orden.id_empresa === 0 ||
      orden.id_empresa === ""
    ) {
      mensajeAlerta("Ordenes de Pago", "Empresa es necesaria !.", "error");
      return;
    }

    if (
      !orden ||
      orden.id_concepto === undefined ||
      orden.id_concepto === null ||
      orden.id_concepto === 0 ||
      orden.id_concepto === ""
    ) {
      mensajeAlerta("Ordenes de Pago", "Concepto es necesario !.", "error");
      return;
    }

    if (!selectedRowComprobantes || selectedRowComprobantes.length === 0) {
      mensajeAlerta(
        "Ordenes de Pago",
        "Debe seleccionar algún comprobante !.",
        "error"
      );
      return;
    }

    let total = 0;
    let bandConcepto = false;
    let bandMoneda = false;
    let bandCotizacion = false;

    if (
      selectedRowComprobantes &&
      selectedRowComprobantes !== null &&
      selectedRowComprobantes.length > 0
    ) {
      let conc = orden.id_concepto;
      let moneda = selectedRowComprobantes[0].id_moneda;
      let cotizacion = selectedRowComprobantes[0].cotizacion;
      for (var i = 0; i < selectedRowComprobantes.length; i++) {
        if (
          selectedRowComprobantes[i].id_comprobanteafip === 3 ||
          selectedRowComprobantes[i].id_comprobanteafip === 8 ||
          selectedRowComprobantes[i].id_comprobanteafip === 13 ||
          selectedRowComprobantes[i].id_comprobanteafip === 53 ||
          selectedRowComprobantes[i].id_comprobanteafip === 203 ||
          selectedRowComprobantes[i].id_comprobanteafip === 208 ||
          selectedRowComprobantes[i].id_comprobanteafip === 213 ||
          selectedRowComprobantes[i].id_comprobanteafip === 1001
        ) {
          total = Number(total) - Number(selectedRowComprobantes[i].total);
        } else {
          total = Number(total) + Number(selectedRowComprobantes[i].total);
        }
        if (conc !== selectedRowComprobantes[i].id_concepto) {
          bandConcepto = true;
        }
        if (moneda !== selectedRowComprobantes[i].id_moneda) {
          bandMoneda = true;
        }
        if (cotizacion !== selectedRowComprobantes[i].cotizacion) {
          bandCotizacion = true;
        }
      }
    }

    if (total <= 0) {
      mensajeAlerta(
        "Ordenes de Pago",
        "Total no puede ser negativo o Cero !.",
        "error"
      );
      return;
    }

    if (bandConcepto) {
      mensajeAlerta(
        "Ordenes de Pago",
        "No puede seleccionar comprobantes con conceptos diferentes en la misma órden de pago !.",
        "error"
      );
      return;
    }

    if (bandMoneda) {
      mensajeAlerta(
        "Ordenes de Pago",
        "No puede seleccionar comprobantes con monedas diferentes en la misma órden de pago !.",
        "error"
      );
      return;
    }

    /*
    if (bandCotizacion) {
      mensajeAlerta(
        "Ordenes de Pago",
        "No puede seleccionar comprobantes con cotizaciones diferentes en la misma órden de pago !.",
        "error"
      );
      return;
    }
    */

    // Llamar al context

    if (id === "0") {
      agregarOrdenPago({
        orden,
        selectedRowComprobantes,
        proveedor,
        usuario: state.usuario.usuario,
      });
    } else {
      if (bandChange) {
        actualizarOrdenPago({
          id,
          orden,
        });
      }
    }
    navigate("/ordenespagos/" + origen);
    //onHide();
    //exitClick();
  };

  const calcularTotal = () => {
    let total = 0;
    if (
      selectedRowComprobantes &&
      selectedRowComprobantes !== null &&
      selectedRowComprobantes.length > 0
    ) {
      for (var i = 0; i < selectedRowComprobantes.length; i++) {
        if (
          selectedRowComprobantes[i].id_comprobanteafip === 3 ||
          selectedRowComprobantes[i].id_comprobanteafip === 8 ||
          selectedRowComprobantes[i].id_comprobanteafip === 13 ||
          selectedRowComprobantes[i].id_comprobanteafip === 53 ||
          selectedRowComprobantes[i].id_comprobanteafip === 203 ||
          selectedRowComprobantes[i].id_comprobanteafip === 208 ||
          selectedRowComprobantes[i].id_comprobanteafip === 213 ||
          selectedRowComprobantes[i].id_comprobanteafip === 1001
        ) {
          total = Number(total) - Number(selectedRowComprobantes[i].total);
        } else {
          total = Number(total) + Number(selectedRowComprobantes[i].total);
        }
      }
    }
    return total;
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Ordenes",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    //exitClick();
    return;
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const titularTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.titular}></Checkbox>
      </Fragment>
    );
  };

  const suspendidoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.suspendido}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, ordenes);
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(ordenes);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "ordenesPagos");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const headerProveedores = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          defaultValue={opciones[0]}
          options={opciones}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Opciones de Búsqueda" />
          )}
          onChange={(event, value) => {
            if (value) {
              setSelectedField(value.label);
            } else {
              setSelectedField(null);
            }
          }}
        />

        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
            onKeyDown={handleEnterGlobal}
          />
        </span>
        <Button
          icon="pi pi-search"
          tooltip="Buscar"
          className="p-button-rounded p-button-help"
          style={{ marginLeft: "0em" }}
          onClick={findClick}
        />
        <Tag
          value="Proveedores - Ordenes de Pago"
          style={{ marginLeft: "1em" }}
        ></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag
          value={
            id === "0"
              ? "Orden de Pago"
              : ordenPago && ordenPago.id_orden
              ? "Orden de Pago " + ordenPago.id_orden
              : "Orden de Pago"
          }
        ></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <Fragment>
          <ButtonMui
            onClick={aceptarClick}
            color="primary"
            type="button"
            fullWidth
            variant="contained"
            startIcon={<CheckIcon />}
          >
            Aceptar
          </ButtonMui>
          <ButtonMui
            onClick={onHide}
            color="secondary"
            type="button"
            fullWidth
            variant="contained"
            startIcon={<CloseIcon />}
            autoFocus
          >
            Cancelar
          </ButtonMui>
        </Fragment>
      </div>
    );
  };

  function handleEnterGlobal(e) {
    if (e.keyCode === 13) {
      findClick();
    }
  }

  function handleEnter(event) {
    if (event.keyCode === 13) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "tipoOrden":
          campo = document.getElementById("cantidad");
          campo.focus();
          break;
        case "fecha":
          campo = document.getElementById("cantidad");
          campo.focus();
          break;
        case "precioUnitario":
          campo = document.getElementById("iva");
          campo.focus();
          break;
        case "iva":
          campo = document.getElementById("precioTotal");
          campo.focus();
          break;
        case "precioTotal":
          campo = document.getElementById("agregarArticulo");
          campo.focus();
          break;
      }
    }

    if (event.keyCode === 27) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "fecha":
          campo = document.getElementById("tipoOrden");
          campo.focus();
          break;
        case "cantidad":
          campo = document.getElementById("fecha");
          campo.focus();
          break;
        case "descripcion":
          campo = document.getElementById("cantidad");
          campo.focus();
          break;
        case "precioUnitario":
          campo = document.getElementById("descripcion");
          campo.focus();
          break;
        case "iva":
          campo = document.getElementById("precioUnitario");
          campo.focus();
          break;
        case "precioTotal":
          campo = document.getElementById("iva");
          campo.focus();
          break;
      }
    }
  }

  // INICIO de IF

  if (id !== "0") {
    if (!bandInicio1) {
      obtenerOrdenPago({ id });
      obtenerComprobantesOrden({ id });
      buscarProveedor({ proveedorId });
      setIndexEmpresa(null);
      setIndexMoneda(null);
      setIndexConceptos(null);
      setBandInicio1(true);
    }

    if (
      bandInicio1 &&
      !bandInicio2 &&
      ordenPago &&
      proveedor &&
      comprobantes &&
      empresas &&
      empresas.length > 0 &&
      conceptos &&
      monedas &&
      monedas.length > 0 &&
      conceptos.length > 0 &&
      Number(ordenPago.id) === Number(id) && 
      Number(ordenPago.id_proveedor) === Number(proveedor.id)
    ) {

      setSelectedRowComprobantes(comprobantes);

      let index1 = empresas.findIndex(
        (item) => item.id === ordenPago.id_empresa
      );
      setIndexEmpresa(index1);

      let index2 = conceptos.findIndex(
        (item) => item.id === ordenPago.id_concepto
      );
      setIndexConceptos(index2);

      let index3 = monedas.findIndex((item) => item.id === ordenPago.id_moneda);
      setIndexMoneda(index3);

      setBandInicio2(true);
    }

    if (
      bandInicio1 &&
      bandInicio2 &&
      !bandInicio3 &&
      selectedRowComprobantes &&
      indexEmpresa !== null &&
      indexMoneda !== null &&
      indexConceptos !== null
    ) {

      guardarOrden({
        ordenId: ordenPago.id,
        id_orden: ordenPago.id_orden,
        proveedorId: ordenPago.id_proveedor,
        nombre: proveedor.nombre_razonsocial,
        tipoContribuyenteId: proveedor.id_tipocontribuyente,
        tipoContribuyente: proveedor.tiposcontribuyentes.nombre,
        numeroDocumento: proveedor.numerodocumento,
        domicilio: proveedor.domicilio,
        email: proveedor.email,
        fecha: ordenPago.fecha,
        vencimiento: ordenPago.vencimiento,
        id_empresa: ordenPago.id_empresa,
        empresa: empresas[indexEmpresa].nombre,
        id_concepto: ordenPago.id_concepto,
        concepto: conceptos[indexConceptos].nombre,
        id_moneda: ordenPago.id_moneda,
        moneda: monedas[indexMoneda].nombre,
        simbolo: monedas[indexMoneda].simbolo,
      });

      setBandInicio3(true);
      setVisible(true);
    }
  } else {
    if (indexMoneda === -1) {
      let index3 = monedas.findIndex(
        (item) => item.id === monedaPredeterminada
      );
      setIndexMoneda(index3);
    }
  }

  // Fin de IF

  function formatDate(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  const fechaComprobanteTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fechacomprobante)}</Fragment>;
  };

  const fechaContabilizacionTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fechacontabilizacion)}</Fragment>;
  };

  const monedaTemplate = (rowData) => {
    return (
      <Fragment>
        {rowData.monedas.nombre + " (" + rowData.monedas.simbolo + ")"}
      </Fragment>
    );
  };

  console.log(comprobantes);
  console.log(id);
  //console.log(factura);

  return (
    <Fragment>
      {id === "0" ? <Menu /> : null}
      <Toast ref={toast} />
      <ConfirmDialog />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {proveedores ? (
            <Fragment>
              {id === "0" ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ align: "center", margin: "1em" }}
                >
                  <Fragment>
                    <Button
                      icon="pi pi-plus"
                      tooltip="Agregar Orden de Pago"
                      className="p-button-rounded p-button-warning"
                      disabled={!selectedRowProveedores}
                      style={{ marginLeft: "1em" }}
                      onClick={addClick}
                    />
                    <Button
                      icon="pi pi-times"
                      tooltip="Cerrar"
                      className="p-button-rounded p-button-primary"
                      style={{ marginLeft: "1em" }}
                      onClick={exitClick}
                    />
                    <Button
                      icon="pi pi-arrow-left"
                      tooltip="Volver"
                      className="p-button-rounded p-button-success"
                      style={{ marginLeft: "1em" }}
                      onClick={returnClick}
                    />
                    <button
                      id="verOrden"
                      type="button"
                      onClick={(e) => {
                        setVisible(true);
                      }}
                      style={{ visibility: "hidden" }}
                    >
                      Ver
                    </button>
                  </Fragment>
                  <div className="datatable-responsive-demo">
                    <div className="card">
                      <Tooltip
                        target=".export-buttons>button"
                        position="bottom"
                      />

                      <Fragment>
                        <DataTable
                          value={proveedores}
                          selection={selectedRowProveedores}
                          onSelectionChange={(e) => {
                            setSelectedRowProveedores(e.value);
                            setSelectedRowComprobantes(null);
                            //buscarTitular({ titularId: e.value.id_titular });
                          }}
                          selectionMode="single"
                          dataKey="id"
                          header={headerProveedores}
                          className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                          style={{ fontSize: "small", marginTop: "1vw" }}
                          //globalFilter={globalFilter}
                          emptyMessage="No hay datos."
                          paginator
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                          rows={100}
                          rowsPerPageOptions={[10, 20, 50]}
                          //paginatorLeft={paginatorLeft}
                          //paginatorRight={paginatorRight}
                        >
                          <Column
                            field="id"
                            header="Nº Proveedor"
                            //body={idTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="nombre_razonsocial"
                            header="Razón Social"
                            //body={descripcionTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="domicilio"
                            header="Domicilio"
                            //body={stockTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="numerodocumento"
                            header="D.N.I./C.U.I.T."
                            //body={unidadTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="telefono1"
                            header="Teléfono"
                            //body={unidadTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="celular"
                            header="Celular"
                            //body={unidadTemplate}
                            sortable
                          ></Column>
                        </DataTable>
                      </Fragment>
                    </div>
                  </div>
                </Grid>
              ) : null}

              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                maximized={true}
                maximizable={true}
                onHide={onHide}
                footer={renderFooter()}
                breakpoints={{ "960px": "95vw" }}
                style={{ width: "95vw", paddingBottom: "0" }}
                position="center"
              >
                {(Number(id) !== 0 && orden && Number(id) === Number(orden.ordenId) || (Number(id) === 0 && orden)) ? (
                  <Grid container spacing={1} style={{ marginTop: "1vw" }}>
                    <Grid
                      container
                      xs={12}
                      md={12}
                      style={{ borderStyle: "solid", borderWidth: "1px" }}
                    >
                      <Grid item xs={12} md={2.5} style={{ padding: "0.2vw" }}>
                        <TextField
                          value={orden ? orden.proveedorId : null}
                          required
                          variant="standard"
                          id="proveedorId"
                          label="Nº de Proveedor"
                          fullWidth
                          disabled
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#636363",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={9} style={{ padding: "0.2vw" }}>
                        <TextField
                          value={orden ? orden.nombre : null}
                          required
                          variant="standard"
                          id="nombre"
                          label="Nombre/Razón Social"
                          fullWidth
                          disabled
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#636363",
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} style={{ padding: "0.2vw" }}>
                        <TextField
                          value={orden ? orden.domicilio : null}
                          required
                          variant="standard"
                          id="domicilio"
                          label="Domicilio"
                          fullWidth
                          disabled
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#636363",
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={3} style={{ padding: "0.2vw" }}>
                        <TextField
                          value={orden ? orden.tipoContribuyente : null}
                          required
                          variant="standard"
                          id="tipoContribuyente"
                          label="Tipo de Contribuyente"
                          fullWidth
                          disabled
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#636363",
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={3} style={{ padding: "0.2vw" }}>
                        <TextField
                          value={orden ? orden.numeroDocumento : null}
                          required
                          variant="standard"
                          id="numeroDocumento"
                          label="C.U.I.T. / D.N.I."
                          fullWidth
                          disabled
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#636363",
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={1.5} style={{ padding: "0.2vw" }}>
                        <TextField
                          id="fecha"
                          value={orden && orden.fecha ? orden.fecha : null}
                          label="Fecha"
                          type="date"
                          fullWidth
                          variant="standard"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "red" },
                          }}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#636363",
                            },
                          }}
                          disabled={id !== "0"}
                          onChange={(e) =>
                            guardarOrden({
                              ...orden,
                              fecha: e.target.value,
                            })
                          }
                          //onKeyDown={handleEnter}
                        />
                      </Grid>

                      <Grid item xs={12} md={1.5} style={{ padding: "0.2vw" }}>
                        <TextField
                          id="vencimiento"
                          value={
                            orden && orden.vencimiento
                              ? orden.vencimiento
                              : null
                          }
                          label="Vencimiento"
                          type="date"
                          fullWidth
                          variant="standard"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "red" },
                          }}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#636363",
                            },
                          }}
                          onChange={(e) => {
                            guardarOrden({
                              ...orden,
                              vencimiento: e.target.value,
                            });
                            setBandChange(true);
                          }}
                          //onKeyDown={handleEnter}
                        />
                      </Grid>

                      {id === "0" ? (
                        <Grid
                          item
                          xs={12}
                          md={2.5}
                          style={{ padding: "0.2vw" }}
                        >
                          <Autocomplete
                            id="empresa"
                            fullWidth
                            noOptionsText={"No hay opciones"}
                            options={
                              empresas
                                ? empresas.filter((item) => item.habilitado)
                                : null
                            }
                            defaultValue={
                              orden && orden.id_empresa !== null
                                ? empresas[
                                    empresas.findIndex(
                                      (item) => item.id === orden.id_empresa
                                    )
                                  ]
                                : empresas[
                                    empresas.findIndex(
                                      (item) => item.id === empresaElegida.id
                                    )
                                  ]
                            }
                            getOptionLabel={(option) => option.nombre}
                            openOnFocus={true}
                            disablePortal
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Empresa *"
                                variant="standard"
                                InputLabelProps={{
                                  style: { color: "red" },
                                }}
                              />
                            )}
                            disabled
                            //onKeyDown={handleEnter}
                            /*
                          onChange={(event, value) => {
                            if (value) {
                              guardarOrden({
                                ...orden,
                                id_empresa: value.id,
                                empresa: value.nombre,
                              });
                            } else {
                              guardarOrden({
                                ...orden,
                                id_empresa: null,
                                empresa: null,
                              });
                            }
                          }}
                          */
                          />
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={12}
                          md={2.5}
                          style={{ padding: "0.2vw" }}
                        >
                          <TextField
                            value={orden && orden.empresa ? orden.empresa : ""}
                            variant="standard"
                            id="empresa"
                            label="Empresa *"
                            fullWidth
                            disabled
                            InputLabelProps={{
                              shrink: true,
                              style: { color: "red" },
                            }}
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#636363",
                              },
                            }}
                          />
                        </Grid>
                      )}

                      {id === "0" ? (
                        <Grid item xs={12} md={4} style={{ padding: "0.2vw" }}>
                          <Autocomplete
                            id="concepto"
                            fullWidth
                            noOptionsText={"No hay opciones"}
                            options={
                              conceptos
                                ? conceptos.filter((item) => item.habilitado)
                                : null
                            }
                            getOptionLabel={(option) => option.nombre}
                            openOnFocus={true}
                            disablePortal
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Concepto *"
                                variant="standard"
                                InputLabelProps={{
                                  style: { color: "red" },
                                }}
                              />
                            )}
                            //onKeyDown={handleEnter}
                            onChange={(event, value) => {
                              if (value) {
                                guardarOrden({
                                  ...orden,
                                  id_concepto: value.id,
                                  concepto: value.nombre,
                                });
                              } else {
                                guardarOrden({
                                  ...orden,
                                  id_concepto: null,
                                  concepto: null,
                                });
                              }
                            }}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12} md={4} style={{ padding: "0.2vw" }}>
                          <TextField
                            value={
                              orden && orden.concepto ? orden.concepto : ""
                            }
                            variant="standard"
                            id="concepto"
                            label="Concepto *"
                            fullWidth
                            disabled
                            InputLabelProps={{
                              shrink: true,
                              style: { color: "red" },
                            }}
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#636363",
                              },
                            }}
                          />
                        </Grid>
                      )}

                      <Grid item xs={12} md={2.5} style={{ padding: "0.2vw" }}>
                        <TextField
                          id="observaciones"
                          value={
                            orden && orden.observaciones
                              ? orden.observaciones
                              : null
                          }
                          label="Observaciones"
                          fullWidth
                          variant="standard"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#636363",
                            },
                          }}
                          disabled={id !== "0"}
                          onChange={(e) => {
                            guardarOrden({
                              ...orden,
                              observaciones: e.target.value,
                            });
                          }}
                          //onKeyDown={handleEnter}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      xs={12}
                      md={12}
                      style={{ borderStyle: "solid", borderWidth: "1px" }}
                    >
                      <div
                        className={`datatable-responsive-demo ${
                          id !== "0" ? "p-disabled" : ""
                        }`}
                      >
                        <div className="card">
                          <Tooltip
                            target=".export-buttons>button"
                            position="bottom"
                          />

                          <Fragment>
                            <DataTable
                              value={comprobantes}
                              selection={selectedRowComprobantes}
                              onSelectionChange={(e) => {
                                setSelectedRowComprobantes(e.value);
                                //buscarTitular({ titularId: e.value.id_titular });
                              }}
                              selectionMode={rowClick ? null : "checkbox"}
                              dataKey="id"
                              //header={headerProveedores}
                              className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                              style={{ fontSize: "small", marginTop: "1vw" }}
                              //globalFilter={globalFilter}
                              emptyMessage="No hay datos."
                              paginator
                              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                              currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                              rows={100}
                              rowsPerPageOptions={[10, 20, 50, 100]}
                              //paginatorLeft={paginatorLeft}
                              //paginatorRight={paginatorRight}
                            >
                              <Column
                                selectionMode="multiple"
                                headerStyle={{ width: "3rem" }}
                              ></Column>
                              <Column
                                field="id"
                                header="ID"
                                style={{ display: "none" }}
                                //body={idTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="fechacomprobante"
                                header="Fecha Comprob."
                                body={fechaComprobanteTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="fechacontabilizacion"
                                header="Fecha Contab."
                                body={fechaContabilizacionTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="comprobante"
                                header="Comprobante"
                                //body={descripcionTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="tipo"
                                header="Tipo"
                                //body={descripcionTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="puntoventa"
                                header="P. Venta"
                                //body={descripcionTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="numero"
                                header="Número"
                                //body={descripcionTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="total"
                                header="Importe"
                                //body={descripcionTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="conceptos.nombre"
                                header="Concepto"
                                //body={descripcionTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="moneda"
                                header="Moneda"
                                body={monedaTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="cotizacion"
                                header="Cotización"
                                //body={estadoTemplate}
                                sortable
                              ></Column>
                            </DataTable>
                          </Fragment>
                        </div>
                      </div>
                    </Grid>

                    <Grid
                      container
                      xs={12}
                      md={12}
                      style={{ borderStyle: "solid", borderWidth: "1px" }}
                    >
                      {orden && monedas && indexMoneda ? (
                        <Grid item xs={12} md={12}>
                          <Tag
                            value={
                              "TOTAL: " +
                              monedas[indexMoneda].simbolo +
                              " " +
                              parseFloat(calcularTotal()).toFixed(2)
                            }
                            style={{ marginLeft: "1em", marginTop: "1em" }}
                          ></Tag>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                ) : null}
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

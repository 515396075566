import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { MultiSelect } from "primereact/multiselect";

export default function RendicionesPendientes(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  let navigate = useNavigate();

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Descripción", dataKey: "descripcion" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [imprimirRendiciones, setImprimirRendiciones] = useState("1");
  const [rendicion, guardarRendicion] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleImprimir, setVisibleImprimir] = useState(false);
  const [visibleFecha, setVisibleFecha] = useState(false);
  const [rendicionImprimir, guardarRendicionImprimir] = useState(null);
  const [selectedRendiciones, setSelectedRendiciones] = useState([]);
  const [fechaNueva, guardarFechaNueva] = useState(null);

  const toast = useRef(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    statusAcceso2,
    statusAcceso3,
    statusAcceso4,
    rendiciones,
    monedas,
    rendicionesMultiselect,
    obtenerAcceso,
    obtenerAcceso2,
    obtenerAcceso3,
    obtenerAcceso4,
    itemsRendicion,
    obtenerRendicionesPendientes,
    agregarRendicionPendiente,
    actualizarRendicionPendiente,
    obtenerItemsRendicionPendiente,
    obtenerItemsRendicion,
    setRendicionNumero,
    eliminarRendicionPendiente,
    confirmarRendicionPendiente,
    aplicarRendicionConfirmada,
    obtenerItemsRendiciones,
    actualizarFechaRendicionPendiente,
    obtenerMonedas,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "RendicionesPendientes",
      });
      obtenerAcceso2({
        usuarioId: state.usuario.id,
        layout: "AplicarRendiciones",
      });
      obtenerAcceso3({
        usuarioId: state.usuario.id,
        layout: "EliminarRendicionesConfirmadas",
      });
      obtenerAcceso4({
        usuarioId: state.usuario.id,
        layout: "CambiarFechasRendiciones",
      });
    }
  }, [state]);

  useEffect(() => {
    obtenerRendicionesPendientes();
    obtenerMonedas();
  }, []);

  const addClick = (e) => {
    //props.history.push(`/rendicion/0`);
    window.localStorage.removeItem("RENDICION_DIARIA");
    window.localStorage.removeItem("CHEQUES_COMPARTIDOS");
    setRendicionNumero(null);
    navigate("/rendicionespendientesitems/0");
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const onHideImprimir = (e) => {
    guardarRendicionImprimir(null);
    setVisibleImprimir(false);
  };

  const onHideFecha = (e) => {
    setVisibleFecha(false);
  };

  const eliminar = (rowData) => {
    const id = rowData.id;
    const rendicionId = rowData.id_rendicion;
    const empresaId = rowData.id_empresa;

    confirmDialog({
      message: `Esta seguro de eliminar la rendición pendiente ${rendicionId}.`,
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      rejectLabel: "No",
      acceptLabel: "Si",
      accept: () => {
        eliminarRendicionPendiente({
          id,
          rendicionId,
          empresaId,
          usuario: state.usuario.id,
        }).then((data) => {
          obtenerRendicionesPendientes();
        });
      },
      //reject: () => reject(),
    });
  };

  const confirmarRendicion = (id) => {
    confirmDialog({
      message: "Esta seguro de confirmar la rendición pendiente",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      rejectLabel: "No",
      acceptLabel: "Si",
      accept: () => {
        confirmarRendicionPendiente({ id });
        setTimeout(() => {
          obtenerRendicionesPendientes();
        }, 1000);
      },
      //reject: () => reject(),
    });
  };

  const aplicarRendicion = (rowData) => {
    confirmDialog({
      message: "Esta seguro de aplicar la rendición confirmada",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      rejectLabel: "No",
      acceptLabel: "Si",
      accept: () => {
        aplicarRendicionConfirmada({
          data: rowData,
          usuario: state.usuario.usuario,
        });
        setTimeout(() => {
          obtenerRendicionesPendientes();
        }, 1000);
      },
      //reject: () => reject(),
    });
  };

  const cambiarFecha = (rowData) => {
    guardarRendicion(rowData);
    setVisibleFecha(true);
  };

  const editarItems = (rowData) => {
    setRendicionNumero({
      rendicionId: Number(rowData.id_rendicion),
      empresaId: Number(rowData.id_empresa),
    });
    const rendicionId = rowData.id_rendicion;
    const empresaId = rowData.id_empresa;
    obtenerItemsRendicionPendiente({ rendicionId, empresaId });
    toast.current.show({
      severity: "info",
      summary: "Rendiciones",
      detail: "Obteniendo información... !",
      life: 3000,
    });

    let elem = document.getElementById("navigateRendiciones");
    if (elem) {
      elem.click();
    }
   

    //window.location.reload();
  };

  const pdfRendiciones = async (e) => {
    if (imprimirRendiciones === "2" && selectedRendiciones.length === 0) {
      mensajeAlerta(
        "Rendiciones",
        "Debe seleccionar al menos una rendición !.",
        "error"
      );
      return;
    }

    if (imprimirRendiciones === "1") {
      const rendicionId = rendicionImprimir.rendicionId;
      const empresaId = rendicionImprimir.empresaId;

      toast.current.show({
        severity: "info",
        summary: "Rendiciones",
        detail: "Obteniendo información... !",
        life: 3000,
      });
      var elem;
      elem = document.getElementById("imprimirClick1");
      await obtenerItemsRendicion({ rendicionId, empresaId }).then((data) => {
        if (elem) {
          elem.click();
        }
      });
    }

    if (imprimirRendiciones === "2") {
      const rendiciones = selectedRendiciones;

      toast.current.show({
        severity: "info",
        summary: "Rendiciones",
        detail: "Obteniendo información... !",
        life: 3000,
      });
      var elem;
      elem = document.getElementById("imprimirClick2");
      await obtenerItemsRendiciones({ rendiciones }).then((data) => {
        if (elem) {
          elem.click();
          //console.log(itemsRendicion)
        }
      });
    }
  };

  /*
  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !rendicion ||
      rendicion.nombre === undefined ||
      rendicion.nombre === null ||
      rendicion.nombre.trim() === ""
    ) {
      mensajeAlerta("Rendiciones", "Nombre es necesario !.", "error");
      return;
    }

    // Llamar al context
    if (selectedRow) {
      actualizarRendicionPendiente({ rendicion });
    } else {
      agregarRendicionPendiente({ rendicion });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerRendicionesPendientes();
    }, 2000);
    //exitClick();
  };
  */

  const aceptarFechaClick = (e) => {
    e.preventDefault();
    //Validar

    if (fechaNueva === null) {
      mensajeAlerta("Rendiciones", "Fecha nueva es necesaria !.", "error");
      return;
    }

    actualizarFechaRendicionPendiente({
      rendicionId: rendicion.id_rendicion,
      empresaId: rendicion.id_empresa,
      fechaNueva,
    });
    onHideFecha();
    setTimeout(() => {
      obtenerRendicionesPendientes();
    }, 2000);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Rendiciones",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, rendiciones);
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(rendiciones);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "rendiciones");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Rendiciones Pendientes"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Rendición"></Tag>
      </div>
    );
  };

  /*
  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };
  */

  const cobradorTemplate = (rowData) => {
    return <span>{rowData.id_cobrador + "-" + rowData.cobradores.nombre}</span>;
  };

  const empresaTemplate = (rowData) => {
    return <span>{rowData.id_empresa + "-" + rowData.empresas.nombre}</span>;
  };

  const opcionesTemplate = (rowData) => {
    return (
      <Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          text
          severity="success"
          onClick={(e) => editarItems(rowData)}
          aria-label="Bookmark"
          disabled={rowData.estado.toUpperCase() !== "PENDIENTE"}
          tooltip="Editar"
          tooltipOptions={{ position: "bottom" }}
        />
        <Button
          icon="pi pi-calendar"
          rounded
          text
          severity="primary"
          onClick={(e) => cambiarFecha(rowData)}
          aria-label="Bookmark"
          disabled={statusAcceso4 !== 200}
          tooltip="Fecha"
          tooltipOptions={{ position: "bottom" }}
        />
        <Button
          style={{ marginLeft: "0.2vw" }}
          icon="pi pi-check"
          rounded
          text
          severity="success"
          onClick={(e) => confirmarRendicion(rowData.id)}
          aria-label="Bookmark"
          disabled={rowData.estado.toUpperCase() !== "PENDIENTE"}
          tooltip="Confirmar"
          tooltipOptions={{ position: "bottom" }}
        />
        <Button
          style={{ marginLeft: "0.2vw" }}
          icon="pi pi-trash"
          rounded
          text
          severity="danger"
          onClick={(e) => eliminar(rowData)}
          aria-label="Bookmark"
          disabled={
            rowData.estado.toUpperCase() !== "PENDIENTE" &&
            statusAcceso3 !== 200
          }
          tooltip="Eliminar"
          tooltipOptions={{ position: "bottom" }}
        />
        <Button
          style={{ marginLeft: "0.2vw" }}
          icon="pi pi-file-pdf"
          rounded
          text
          severity="danger"
          onClick={(e) => {
            setVisibleImprimir(true);
            guardarRendicionImprimir({
              id: rowData.id,
              rendicionId: rowData.id_rendicion,
              empresaId: rowData.id_empresa,
              empresaNombre: rowData.empresas.nombre,
              cobradorId: rowData.id_cobrador,
              //moneda: rowData.monedas.nombre
            });
          }}
          aria-label="Bookmark"
          disabled={rowData.estado.toUpperCase() === "PENDIENTE"}
          tooltip="Pdf"
          tooltipOptions={{ position: "bottom" }}
        />
        <Button
          style={{ marginLeft: "0.2vw" }}
          icon="pi pi-arrow-circle-down"
          rounded
          text
          severity="secondary"
          disabled={
            rowData.estado.toUpperCase() === "PENDIENTE" ||
            (rowData.estado.toUpperCase() !== "PENDIENTE" &&
              statusAcceso2 !== 200 &&
              (Number(rowData.efectivo) > 0 ||
                Number(rowData.cheques) > 0 ||
                Number(rowData.bancos) > 0 ||
                Number(rowData.compensaciones) > 0))
          }
          onClick={(e) => aplicarRendicion(rowData)}
          aria-label="Bookmark"
          tooltip="Aplicar"
          tooltipOptions={{ position: "bottom" }}
        />
        <Button
          id="imprimirClick1"
          icon="pi pi-print"
          tooltip="Imprimir"
          className="p-button-rounded p-button-help"
          style={{ marginLeft: "1em", display: "none" }}
          onClick={(e) => acceptImprimir1(rowData)}
        />
        <Button
          id="navigateRendiciones"
          icon="pi pi-print"
          className="p-button-rounded p-button-help"
          style={{ marginLeft: "1em", display: "none" }}
          onClick={(e) =>  navigate("/rendicionespendientesitems/0")
          }
        />
        <Button
          id="imprimirClick2"
          icon="pi pi-print"
          tooltip="Imprimir"
          className="p-button-rounded p-button-help"
          style={{ marginLeft: "1em", display: "none" }}
          onClick={(e) => acceptImprimir2()}
        />
      </Fragment>
    );
  };

  const fechaTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fecha)}</Fragment>;
  };

  const estadoTemplate = (rowData) => {
    return (
      <Fragment>
        {rowData.estado === "pendiente" ? (
          <Button label={rowData.estado.toUpperCase()} severity="warning" />
        ) : null}
        {rowData.estado === "confirmada" ? (
          <Button label={rowData.estado.toUpperCase()} severity="success" />
        ) : null}
      </Fragment>
    );
  };

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  function formatDate2(dateString) {
    var allDate = dateString.toISOString().replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate;
  }

  function formatDateTime(dateString) {
    var allDate = dateString.toISOString().replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  const groupBy = (prop) => (data) => {
    return data.reduce((dict, item) => {
      const { [prop]: _, ...rest } = item;
      dict[item[prop]] = [...(dict[item[prop]] || []), rest];
      return dict;
    }, {});
  };

  const acceptImprimir1 = (rowData) => {
    const doc = new jsPDF();
    let date = new Date();
    let newDate = new Date(date);
    newDate.setHours(date.getHours() - 3);

    let row = 10;
    doc.setFontSize(10);
    doc.text("Rendición Nº: " + rowData.id_rendicion, 10, row);
    doc.text("Fecha: " + formatDateTime(newDate), 70, row);
    doc.text("Estado: " + rowData.estado.toUpperCase(), 130, row);
    row = 15;
    doc.text(
      "Cobrador: " +
        rowData.cobradores.nombre +
        " (" +
        rowData.cobradores.id +
        ")",
      10,
      row
    );
    row = 25;

    var columns = [
      "Nº. Afiliado",
      "Razón Social",
      "Comprobante",
      "Valor",
      "Moneda",
      "Importe",
      "Descuento",
      "Recibo",
      "Empresa",
    ];

    let data = itemsRendicion.map((item) => [
      item.titularId,
      item.nombreTitular,
      item.comprobante + "-" + item.numero,
      item.valor.toUpperCase(),
      item.monedas.nombre,
      parseFloat(item.importeTotal).toFixed(2),
      parseFloat(item.comision).toFixed(2),
      item.recibo !== null && item.recibo !== 0 ? item.recibo : "",
      item.empresas.nombre,
    ]);

    let total = 0;
    let comision = 0;
    for (var i = 0; i < itemsRendicion.length; i++) {
      //total = Number(total) + Number(itemsRendicion[i].importeTotal);
      comision = Number(comision) + Number(itemsRendicion[i].comision);
    }

    let arrayTotal = [
      "TOTAL",
      "",
      "",
      "",
      "",
      //parseFloat(total).toFixed(2),
      "",
      parseFloat(comision).toFixed(2),
      "",
      "",
    ];

    data.push(arrayTotal);

    doc.autoTable(columns, data, {
      margin: { top: 25, left: 10 },
      styles: {
        fontSize: 8,
      },
      columnStyles: {
        0: {
          halign: "left",
        },
        1: {
          halign: "left",
        },
        2: {
          halign: "left",
        },
        3: {
          halign: "left",
        },
        4: {
          halign: "left",
        },
        5: {
          halign: "right",
        },
        6: {
          halign: "right",
        },
        7: {
          halign: "left",
        },
        8: {
          halign: "left",
        },
      },
      didParseCell: (data) => {
        if (data.cell && data.cell.section === "head") {
          switch (data.cell.raw) {
            case "Importe":
            case "Descuento":
              data.cell.styles.halign = "right";
              break;
            default:
              data.cell.styles.halign = "left";
              break;
          }
        }
      },
    });

    let finalY = doc.previousAutoTable.finalY;

    row = finalY + 10;

    if (row >= 250) {
      doc.addPage();
    }

    const result = Object.entries(groupBy("valor")(itemsRendicion)).map(
      ([key, value]) => ({ valor: key, datos: value })
    );

    let array = [];

    // efectivo

    let efectivoTotal = new Array(monedas.length);
    efectivoTotal.fill(0);

    let efectivoComision = new Array(monedas.length);
    efectivoComision.fill(0);

    for (var i = 0; i < itemsRendicion.length; i++) {
      if (itemsRendicion[i].valor === "efectivo") {
        let moneda = monedas.findIndex(
          (item) => item.id === itemsRendicion[i].id_moneda
        );
        efectivoTotal[moneda] =
          Number(efectivoTotal[moneda]) +
          Number(itemsRendicion[i].importeTotal);
        efectivoComision[moneda] =
          Number(efectivoComision[moneda]) + Number(itemsRendicion[i].comision);
      }
    }

    for (var i = 0; i < efectivoTotal.length; i++) {
      if (efectivoTotal[i] > 0) {
        array.push({
          valor: "Total Efectivo",
          importe: parseFloat(Number(efectivoTotal[i]).toFixed(2)),
          detalle: monedas[i].nombre,
          //empresa: "",
          comision: parseFloat(Number(efectivoComision[i]).toFixed(2)),
        });
      }
    }

    // cheques

    for (var i = 0; i < itemsRendicion.length; i++) {
      if (
        itemsRendicion[i].valor === "cheques" &&
        itemsRendicion[i].chequeIndex === 0
      ) {
        let moneda = monedas.findIndex(
          (item) => item.id === itemsRendicion[i].id_moneda
        );
        array.push({
          valor: "Cheques",
          importe: parseFloat(
            Number(itemsRendicion[i].importeTotal).toFixed(2)
          ),
          detalle:
            formatDate(itemsRendicion[i].chequeEmision) +
            "-" +
            formatDate(itemsRendicion[i].chequeDeposito) +
            "-" +
            itemsRendicion[i].chequeBanco +
            " - (" +
            monedas[moneda].nombre +
            ")",
          //empresa: "",
          comision: parseFloat(Number(itemsRendicion[i].comision).toFixed(2)),
        });
      }
    }

    let result2 = [];

    for (var i = 0; i < result.length; i++) {
      if (result[i].valor === "bancos") {
        result2 = Object.entries(groupBy("bancoCuentaId")(result[i].datos)).map(
          ([key, value]) => ({ bancoCuentaId: key, datos: value })
        );
      }
    }

    for (var i = 0; i < result2.length; i++) {
      let bancosTotal = 0;
      let bancosComision = 0;
      let detalle = "";
      let empresa = "";
      let monedaId = result2[i].datos[0].id_moneda;
      let moneda = monedas.findIndex((item) => item.id === monedaId);

      for (var j = 0; j < result2[i].datos.length; j++) {
        if (result2[i].datos[0].id_moneda !== monedaId) {
          detalle = "MONEDAS DIFERENTES EN LA MISMA CUENTA !!!!";
        } else {
          detalle =
            result2[i].datos[j].bancoCuenta +
            " - (" +
            monedas[moneda].nombre +
            ")";
        }
        bancosTotal =
          Number(bancosTotal) + Number(result2[i].datos[j].importeTotal);
        bancosComision =
          Number(bancosComision) + Number(result2[i].datos[j].comision);
        //empresa = result2[i].datos[j].empresas.nombre;
      }
      array.push({
        valor: "Bancos",
        importe: parseFloat(Number(bancosTotal).toFixed(2)),
        detalle,
        //empresa,
        comision: parseFloat(Number(bancosComision).toFixed(2)),
      });
    }

    doc.setFontSize(9);

    doc.text("VALORES A RENDIR: ", 10, row);

    row = row + 5;

    if (row >= 250) {
      doc.addPage();
    }

    var columns = ["Valor", "Detalle", "Importe", "Descuento"];

    data = array.map((item) => [
      item.valor,
      item.detalle,
      parseFloat(item.importe).toFixed(2),
      parseFloat(item.comision).toFixed(2),
    ]);


    doc.autoTable(columns, data, {
      margin: { left: 10 },
      styles: {
        fontSize: 8,
      },
      startY: row,
      columnStyles: {
        0: {
          halign: "left",
        },
        1: {
          halign: "left",
        },
        2: {
          halign: "right",
        },
        3: {
          halign: "right",
        },
      },
      didParseCell: (data) => {
        if (data.cell && data.cell.section === "head") {
          switch (data.cell.raw) {
            case "Valor":
            case "Detalle":
              data.cell.styles.halign = "left";
              break;
            default:
              data.cell.styles.halign = "right";
              break;
          }
        }
      },
    });

    finalY = doc.previousAutoTable.finalY;

    if (row >= 200) {
      doc.addPage();
    }

    row = finalY + 2;

    let monedasEfectivo = new Array(monedas.length);
    monedasEfectivo.fill(0);

    let monedasCheques = new Array(monedas.length);
    monedasCheques.fill(0);

    let monedasBancos = new Array(monedas.length);
    monedasBancos.fill(0);

    for (var i = 0; i < itemsRendicion.length; i++) {
      if (itemsRendicion[i].valor === "efectivo") {
        let moneda = monedas.findIndex(
          (item) => item.id === itemsRendicion[i].id_moneda
        );
        monedasEfectivo[moneda] =
          Number(monedasEfectivo[moneda]) +
          Number(itemsRendicion[i].importeTotal);
      }

      if (itemsRendicion[i].valor === "cheques" && itemsRendicion[i].chequeIndex === 0) {
        let moneda = monedas.findIndex(
          (item) => item.id === itemsRendicion[i].id_moneda
        );
        monedasCheques[moneda] =
          Number(monedasCheques[moneda]) +
          Number(itemsRendicion[i].importeTotal);
      }

      if (itemsRendicion[i].valor === "bancos") {
        let moneda = monedas.findIndex(
          (item) => item.id === itemsRendicion[i].id_moneda
        );
        monedasBancos[moneda] =
          Number(monedasBancos[moneda]) +
          Number(itemsRendicion[i].importeTotal);
      }
    }

    var columns = ["Moneda", "Efectivo", "Cheques", "Bancos" ];

    array = []

    for (var i = 0; i < monedas.length; i++) {
      array.push({
        moneda: monedas[i].nombre,
        efectivo: parseFloat(Number(monedasEfectivo[i])).toFixed(2),
        cheques: parseFloat(Number(monedasCheques[i])).toFixed(2),
        bancos: parseFloat(Number(monedasBancos[i])).toFixed(2),
      })
    }


    data = []

    data = array.map((item) => [
      item.moneda,
      item.efectivo,
      item.cheques,
      item.bancos,
    ]);

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      styles: {
        fontSize: 8,
      },
      startY: row,
      columnStyles: {
        0: {
          halign: "left",
        },
        1: {
          halign: "right",
        },
        2: {
          halign: "right",
        },
        3: {
          halign: "right",
        },
      },
      didParseCell: (data) => {
        if (data.cell && data.cell.section === "head") {
          switch (data.cell.raw) {
            case "Moneda":
              data.cell.styles.halign = "left";
              break;
            default:
              data.cell.styles.halign = "right";
              break;
          }
        }
      },
    });

    window.open(doc.output("bloburl"));
  };

  const acceptImprimir2 = () => {
    const doc = new jsPDF();
    let date = new Date();
    let newDate = new Date(date);
    newDate.setHours(date.getHours() - 3);

    let row = 10;
    let array = [];
    let finalY = 0;

    for (var j = 0; j < selectedRendiciones.length; j++) {
      doc.setFontSize(10);
      doc.text("Rendición Nº: " + selectedRendiciones[j].id_rendicion, 10, row);
      doc.text("Fecha: " + formatDateTime(newDate), 70, row);
      doc.text(
        "Estado: " + selectedRendiciones[j].estado.toUpperCase(),
        130,
        row
      );
      row = row + 5;

      doc.text(
        "Cobrador: " +
          selectedRendiciones[j].cobrador +
          " (" +
          selectedRendiciones[j].id_cobrador +
          ")",
        10,
        row
      );
      row = row + 5;

      var columns = [
        "Nº. Afiliado",
        "Razón Social",
        "Comprobante",
        "Valor",
        "Moneda",
        "Importe",
        "Descuento",
        "Recibo",
        "Empresa",
      ];

      const filterItems = itemsRendicion.filter(
        (item) =>
          item.rendicionId === selectedRendiciones[j].id_rendicion &&
          item.empresaId === selectedRendiciones[j].id_empresa
      );

      let data = filterItems.map((item) => [
        item.titularId,
        item.nombreTitular,
        item.comprobante + "-" + item.numero,
        item.valor.toUpperCase(),
        item.monedas.nombre,
        parseFloat(item.importeTotal).toFixed(2),
        parseFloat(item.comision).toFixed(2),
        item.recibo !== null && item.recibo !== 0 ? item.recibo : "",
        item.empresas.nombre,
      ]);

      let total = 0;
      let comision = 0;
      for (var i = 0; i < filterItems.length; i++) {
        //total = Number(total) + Number(filterItems[i].importeTotal);
        comision = Number(comision) + Number(filterItems[i].comision);
      }

      let arrayTotal = [
        "TOTAL",
        "",
        "",
        "",
        "",
        //parseFloat(total).toFixed(2),
        "",
        parseFloat(comision).toFixed(2),
        "",
        "",
      ];

      data.push(arrayTotal);

      //console.log(filterData)

      doc.autoTable(columns, data, {
        startY: row,
        margin: { top: 0, left: 10 },
        styles: {
          fontSize: 8,
        },
        columnStyles: {
          0: {
            halign: "left",
          },
          1: {
            halign: "left",
          },
          2: {
            halign: "left",
          },
          3: {
            halign: "left",
          },
          4: {
            halign: "left",
          },
          5: {
            halign: "right",
          },
          6: {
            halign: "right",
          },
          7: {
            halign: "left",
          },
          8: {
            halign: "left",
          },
        },
        didParseCell: (data) => {
          if (data.cell && data.cell.section === "head") {
            switch (data.cell.raw) {
              case "Importe":
              case "Descuento":
                data.cell.styles.halign = "right";
                break;
              default:
                data.cell.styles.halign = "left";
                break;
            }
          }
        },
      });

      let finalY = doc.previousAutoTable.finalY;

      row = finalY + 10;

      if (row >= 250) {
        doc.addPage();
      }
    }

    const result = Object.entries(groupBy("valor")(itemsRendicion)).map(
      ([key, value]) => ({ valor: key, datos: value })
    );

    // efectivo
    let efectivoTotal = new Array(monedas.length);
    efectivoTotal.fill(0);

    let efectivoComision = new Array(monedas.length);
    efectivoComision.fill(0);

    for (var i = 0; i < itemsRendicion.length; i++) {
      if (itemsRendicion[i].valor === "efectivo") {
        let moneda = monedas.findIndex(
          (item) => item.id === itemsRendicion[i].id_moneda
        );
        efectivoTotal[moneda] =
          Number(efectivoTotal[moneda]) +
          Number(itemsRendicion[i].importeTotal);
        efectivoComision[moneda] =
          Number(efectivoComision[moneda]) + Number(itemsRendicion[i].comision);
      }
    }

    for (var i = 0; i < efectivoTotal.length; i++) {
      if (efectivoTotal[i] > 0) {
        array.push({
          valor: "Total Efectivo",
          importe: parseFloat(Number(efectivoTotal[i]).toFixed(2)),
          detalle: monedas[i].nombre,
          //empresa: "",
          comision: parseFloat(Number(efectivoComision[i]).toFixed(2)),
        });
      }
    }

    for (var i = 0; i < itemsRendicion.length; i++) {
      if (
        itemsRendicion[i].valor === "cheques" &&
        itemsRendicion[i].chequeIndex === 0
      ) {
        let moneda = monedas.findIndex(
          (item) => item.id === itemsRendicion[i].id_moneda
        );
        array.push({
          valor: "Cheques",
          importe: parseFloat(
            Number(itemsRendicion[i].importeTotal).toFixed(2)
          ),
          detalle:
            formatDate(itemsRendicion[i].chequeEmision) +
            "-" +
            formatDate(itemsRendicion[i].chequeDeposito) +
            "-" +
            itemsRendicion[i].chequeBanco +
            " - (" +
            monedas[moneda].nombre +
            ")",
          //empresa: "",
          comision: parseFloat(Number(itemsRendicion[i].comision).toFixed(2)),
        });
      }
    }

    let result2 = [];

    for (var i = 0; i < result.length; i++) {
      if (result[i].valor === "bancos") {
        result2 = Object.entries(groupBy("bancoCuentaId")(result[i].datos)).map(
          ([key, value]) => ({ bancoCuentaId: key, datos: value })
        );
      }
    }

    for (var i = 0; i < result2.length; i++) {
      let bancosTotal = 0;
      let bancosComision = 0;
      let detalle = "";
      let empresa = "";
      let monedaId = result2[i].datos[0].id_moneda;
      let moneda = monedas.findIndex((item) => item.id === monedaId);

      for (var j = 0; j < result2[i].datos.length; j++) {
        if (result2[i].datos[0].id_moneda !== monedaId) {
          detalle = "MONEDAS DIFERENTES EN LA MISMA CUENTA !!!!";
        } else {
          detalle =
            result2[i].datos[j].bancoCuenta +
            " - (" +
            monedas[moneda].nombre +
            ")";
        }
        bancosTotal =
          Number(bancosTotal) + Number(result2[i].datos[j].importeTotal);
        bancosComision =
          Number(bancosComision) + Number(result2[i].datos[j].comision);
        //empresa = result2[i].datos[j].empresas.nombre;
      }
      array.push({
        valor: "Bancos",
        importe: parseFloat(Number(bancosTotal).toFixed(2)),
        detalle,
        //empresa,
        comision: parseFloat(Number(bancosComision).toFixed(2)),
      });
    }

    doc.setFontSize(9);

    doc.text("VALORES A RENDIR: ", 10, row);

    row = row + 5;

    if (row >= 250) {
      doc.addPage();
    }

    var columns = ["Valor", "Detalle", "Importe", "Descuento"];

    let data = array.map((item) => [
      item.valor,
      item.detalle,
      parseFloat(item.importe).toFixed(2),
      //item.empresa,
      parseFloat(item.comision).toFixed(2),
    ]);

    doc.autoTable(columns, data, {
      startY: row,
      margin: { left: 10 },
      styles: {
        fontSize: 8,
      },
      columnStyles: {
        0: {
          halign: "left",
        },
        1: {
          halign: "left",
        },
        2: {
          halign: "right",
        },
        3: {
          halign: "right",
        },
      },
      didParseCell: (data) => {
        if (data.cell && data.cell.section === "head") {
          switch (data.cell.raw) {
            case "Valor":
            case "Detalle":
              data.cell.styles.halign = "left";
              break;
            default:
              data.cell.styles.halign = "right";
              break;
          }
        }
      },
    });

    finalY = doc.previousAutoTable.finalY;

    if (row >= 250) {
      doc.addPage();
    }



    row = finalY + 2;

    let monedasEfectivo = new Array(monedas.length);
    monedasEfectivo.fill(0);

    let monedasCheques = new Array(monedas.length);
    monedasCheques.fill(0);

    let monedasBancos = new Array(monedas.length);
    monedasBancos.fill(0);

    for (var i = 0; i < itemsRendicion.length; i++) {
      if (itemsRendicion[i].valor === "efectivo") {
        let moneda = monedas.findIndex(
          (item) => item.id === itemsRendicion[i].id_moneda
        );
        monedasEfectivo[moneda] =
          Number(monedasEfectivo[moneda]) +
          Number(itemsRendicion[i].importeTotal);
      }

      if (itemsRendicion[i].valor === "cheques" && itemsRendicion[i].chequeIndex === 0) {
        let moneda = monedas.findIndex(
          (item) => item.id === itemsRendicion[i].id_moneda
        );
        monedasCheques[moneda] =
          Number(monedasCheques[moneda]) +
          Number(itemsRendicion[i].importeTotal);
      }

      if (itemsRendicion[i].valor === "bancos") {
        let moneda = monedas.findIndex(
          (item) => item.id === itemsRendicion[i].id_moneda
        );
        monedasBancos[moneda] =
          Number(monedasBancos[moneda]) +
          Number(itemsRendicion[i].importeTotal);
      }
    }

    var columns = ["Moneda", "Efectivo", "Cheques", "Bancos" ];

    array = []

    for (var i = 0; i < monedas.length; i++) {
      array.push({
        moneda: monedas[i].nombre,
        efectivo: parseFloat(Number(monedasEfectivo[i])).toFixed(2),
        cheques: parseFloat(Number(monedasCheques[i])).toFixed(2),
        bancos: parseFloat(Number(monedasBancos[i])).toFixed(2),
      })
    }


    data = []

    data = array.map((item) => [
      item.moneda,
      item.efectivo,
      item.cheques,
      item.bancos,
    ]);

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      styles: {
        fontSize: 8,
      },
      startY: row,
      columnStyles: {
        0: {
          halign: "left",
        },
        1: {
          halign: "right",
        },
        2: {
          halign: "right",
        },
        3: {
          halign: "right",
        },
      },
      didParseCell: (data) => {
        if (data.cell && data.cell.section === "head") {
          switch (data.cell.raw) {
            case "Moneda":
              data.cell.styles.halign = "left";
              break;
            default:
              data.cell.styles.halign = "right";
              break;
          }
        }
      },
    });







    window.open(doc.output("bloburl"));
  };

  const renderHeaderImprimir = () => {
    return (
      <div>
        <Tag value="Rendiciones"></Tag>
      </div>
    );
  };

  const renderFooterImprimir = () => {
    return (
      <div>
        <Button
          icon="pi pi-file-pdf"
          onClick={(e) => {
            pdfRendiciones();
            //aceptarClick("pdf");
          }}
          className="p-button-raised p-button-warning"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
          label="PDF"
        />
        {/*
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        */}
        <ButtonMui
          onClick={onHideImprimir}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderHeaderFecha = () => {
    return (
      <div>
        <Tag value="Cambio de Fecha"></Tag>
      </div>
    );
  };

  const renderFooterFecha = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarFechaClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideFecha}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  //console.log(rendiciones);
  //console.log(state);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      <ConfirmDialog />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {rendiciones ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ align: "center", margin: "1em" }}
              >
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={rendiciones}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      size="small"
                      className="p-datatable-sm p-datatable-responsive-demo pdatatable"
                      //style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={100}
                      rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        //className="colRendicionesPendientes"
                        //body={idTemplate}
                        style={{ display: "none" }}
                        sortable
                      ></Column>
                      <Column
                        field="id_rendicion"
                        header="Número"
                        className="colRendicionesPendientes"
                        //body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="fecha"
                        header="Fecha"
                        className="colRendicionesPendientes"
                        body={fechaTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="total"
                        header="Total"
                        className="colRendicionesPendientes"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="autoliquidacion"
                        header="Autoliquidación"
                        className="colRendicionesPendientes"
                        //body={descripcionTemplate}
                        sortable
                        //style={{ fontSize: "10px" }}
                      ></Column>
                      <Column
                        field=""
                        header="Cobrador"
                        className="colRendicionesPendientes"
                        body={cobradorTemplate}
                        sortable
                      ></Column>
                      <Column
                        field=""
                        header="Empresa"
                        className="colRendicionesPendientes"
                        body={empresaTemplate}
                        sortable
                      ></Column>
                      {/*
                      <Column
                        field="empresas.nombre"
                        header="Empresa"
                        className="colRendicionesPendientes"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      */}
                      <Column
                        header="Opciones"
                        className="colRendicionesPendientes"
                        style={{ fontSize: "10px" }}
                        body={opcionesTemplate}
                        //body={descripcionTemplate}
                      ></Column>
                      <Column
                        header="Estado"
                        className="colRendicionesPendientes"
                        body={estadoTemplate}
                        style={{ fontSize: "10px" }}
                        //body={descripcionTemplate}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
              {rendicionImprimir ? (
                <Fragment>
                  <Dialog
                    header={renderHeaderImprimir()}
                    visible={visibleImprimir}
                    closable={false}
                    footer={renderFooterImprimir()}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "75vw", paddingBottom: "0" }}
                    position="center"
                  >
                    <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                      <Grid item xs={12} md={3}>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="imprimirRendiciones"
                            value={
                              imprimirRendiciones ? imprimirRendiciones : null
                            }
                            onChange={(e) => {
                              if (e.target.value === "1") {
                                setSelectedRendiciones([]);
                              }
                              setImprimirRendiciones(e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label={
                                rendicionImprimir
                                  ? "Rendición: " +
                                    rendicionImprimir.rendicionId +
                                    "-" +
                                    rendicionImprimir.empresaNombre
                                  : null
                              }
                            />
                            <FormControlLabel
                              value="2"
                              control={<Radio />}
                              label={
                                rendicionImprimir
                                  ? "Cobrador: " + rendicionImprimir.cobradorId
                                  : null
                              }
                            />
                          </RadioGroup>
                        </FormControl>

                        <Grid item xs={12} md={12}>
                          <MultiSelect
                            id="rendicionesCobrador"
                            value={selectedRendiciones}
                            onChange={(e) => setSelectedRendiciones(e.value)}
                            options={
                              rendicionesMultiselect
                                ? rendicionesMultiselect.filter(
                                    (item) =>
                                      item.id_cobrador ===
                                        rendicionImprimir.cobradorId &&
                                      item.estado === "confirmada"
                                  )
                                : null
                            }
                            optionLabel="dataMultiselect"
                            placeholder="Seleccione las rendiciones"
                            maxSelectedLabels={20}
                            display="chip"
                            disabled={imprimirRendiciones !== "2"}
                            style={{ marginLeft: "1vw" }}
                            //className="w-full md:w-40rem"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Dialog>
                </Fragment>
              ) : null}
            </Fragment>
          ) : (
            <Spinner />
          )}

          <Dialog
            header={renderHeaderFecha()}
            visible={visibleFecha}
            closable={false}
            footer={renderFooterFecha()}
            breakpoints={{ "960px": "55vw" }}
            style={{ width: "55vw", paddingBottom: "0" }}
            position="center"
          >
            <Grid container spacing={3} style={{ marginTop: "0vw" }}>
              <Grid item xs={12} md={3}>
                <TextField
                  id="fechaActual"
                  label="Fecha Actual"
                  type="date"
                  variant="standard"
                  fullWidth
                  disabled
                  value={rendicion && rendicion.fecha ? rendicion.fecha : null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  id="fechaNueva"
                  label="Fecha Nueva"
                  type="date"
                  variant="standard"
                  fullWidth
                  value={fechaNueva ? fechaNueva : null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    guardarFechaNueva(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Dialog>

          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

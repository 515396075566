import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";
import { InputNumber } from "primereact/inputnumber";
import { jsPDF } from "jspdf";

export default function MovimientosCompensaciones(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  let navigate = useNavigate();

  const estadosCompensaciones = [
    { id: 1, nombre: "PENDIENTE" },
    { id: 2, nombre: "APLICADA" },
  ];

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Descripción", dataKey: "descripcion" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [movimientoCompensacion, guardarMovimientoCompensacion] =
    useState(null);
  const [visible, setVisible] = useState(false);
  const [detalle, guardarDetalle] = useState(null);
  const [fechaDesde, guardarFechaDesde] = useState(null);
  const [fechaHasta, guardarFechaHasta] = useState(null);
  const [estadoCompensacion, guardarEstadoCompensacion] = useState("PENDIENTE");
  const [empresaTodas, setEmpresaTodas] = useState(true);
  const [tipo, setTipo] = useState(null);
  const [cambio, setCambio] = useState(false);
  const toast = useRef(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    movimientosCompensaciones,
    empresaElegida,
    compensacionesEgresos,
    compensacionesIngresos,
    compensacionesSaldoAnterior,
    compensacionesSaldoActual,
    empresas,
    conceptos,
    obtenerMovimientosCompensaciones,
    obtenerAcceso,
    agregarMovimientoCompensacion,
    actualizarMovimientoCompensacion,
    obtenerEmpresas,
    obtenerConceptos,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "MovimientosCompensaciones",
      });
    }
  }, [state]);

  useEffect(() => {
    let date = new Date();
    let day = `${date.getDate()}`.padStart(2, "0");
    let month = `${date.getMonth() + 1}`.padStart(2, "0");
    let year = date.getFullYear();
    let fecha = `${year}-${month}-${day}`;
    guardarFechaDesde(fecha);
    guardarFechaHasta(fecha);
    obtenerMovimientosCompensaciones({
      empresaTodas,
      empresaId: null,
      estado: estadoCompensacion
    });
    obtenerEmpresas();
    obtenerConceptos();
  }, []);

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !movimientoCompensacion ||
      movimientoCompensacion.fecha === undefined ||
      movimientoCompensacion.fecha === null ||
      movimientoCompensacion.fecha === ""
    ) {
      mensajeAlerta(
        "Movimientos Compensaciones",
        "La fecha es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !movimientoCompensacion ||
      movimientoCompensacion.id_empresa === undefined ||
      movimientoCompensacion.id_empresa === null ||
      movimientoCompensacion.id_empresa === ""
    ) {
      mensajeAlerta(
        "Movimientos Compensaciones",
        "La empresa es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !movimientoCompensacion ||
      movimientoCompensacion.id_concepto === undefined ||
      movimientoCompensacion.id_concepto === null ||
      movimientoCompensacion.id_concepto === ""
    ) {
      mensajeAlerta(
        "Movimientos Compensaciones",
        "El concepto es necesario !.",
        "error"
      );
      return;
    }

    if (
      !movimientoCompensacion ||
      movimientoCompensacion.importe === undefined ||
      movimientoCompensacion.importe === null ||
      movimientoCompensacion.importe === ""
    ) {
      mensajeAlerta(
        "Movimientos Compensaciones",
        "El importe es necesario !.",
        "error"
      );
      return;
    }

    if (Number(movimientoCompensacion.importe) <= 0) {
      mensajeAlerta(
        "Movimientos Compensaciones",
        "El importe debe ser positivo !.",
        "error"
      );
      return;
    }

    // Llamar al context
    if (selectedRow) {
      //actualizarMovimientoCompensacion({ movimientoCompensacion });
    } else {
      agregarMovimientoCompensacion({ movimientoCompensacion });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerMovimientosCompensaciones({
        empresaTodas,
        empresaId: empresaElegida.id,
        estado: estadoCompensacion,
      });
    }, 2000);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Movimientos Compensaciones",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  const fechaTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fecha)}</Fragment>;
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, movimientosCompensaciones);
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(movimientosCompensaciones);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "movimientosCompensaciones");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Movimientos Compensaciones"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag
          value={
            tipo === "i"
              ? "Movimiento de Compensacion - INGRESOS"
              : tipo === "e"
              ? "Movimiento de Compensacion - EGRESOS"
              : null
          }
        ></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  function handleEnter(event) {
    if (event.keyCode === 13) {
      let element = event.target.id;
      let campo = null;
      switch (element) {
        case "fechaDesde":
          campo = document.getElementById("fechaHasta");
          campo.focus();
          break;
        case "fecha":
          campo = document.getElementById("empresaMovimiento");
          campo.focus();
          break;
        case "empresaMovimiento":
          campo = document.getElementById("concepto");
          campo.focus();
          break;
        case "concepto":
          campo = document.getElementById("detalle");
          campo.focus();
          break;
        case "detalle":
          campo = document.getElementById("importe");
          campo.focus();
          break;
      }
    }
  }

  console.log(movimientosCompensaciones);
  //console.log(state);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {movimientosCompensaciones ? (
            <Fragment>
              <Grid
                container
                spacing={3}
                style={{ marginTop: "1vw", marginLeft: "1vw" }}
              >
                  <Button
                    icon="pi pi-times"
                    tooltip="Cerrar"
                    className="p-button-rounded p-button-primary"
                    style={{ marginLeft: "1em" }}
                    onClick={exitClick}
                  />
                  {/*
                  <TextField
                    value={fechaDesde ? fechaDesde : null}
                    id="fechaDesde"
                    label="Fecha Desde"
                    type="date"
                    variant="standard"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ marginLeft: "1em" }}
                    onChange={(e) => {
                      guardarFechaDesde(e.target.value);
                      setCambio(true);
                    }}
                    onKeyDown={handleEnter}
                  />
                  <TextField
                    value={fechaHasta ? fechaHasta : null}
                    id="fechaHasta"
                    label="Fecha Hasta"
                    type="date"
                    variant="standard"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ marginLeft: "1em" }}
                    //onKeyDown={handleEnter}
                    onChange={(e) => {
                      setCambio(true);
                      guardarFechaHasta(e.target.value);
                    }}
                  />
                  */}    
                  <Autocomplete
                    id="estado"
                    required
                    noOptionsText={"No hay opciones"}
                    options={estadosCompensaciones}
                    defaultValue={estadosCompensaciones[0]}
                    getOptionLabel={(option) => option.nombre}
                    sx={{ width: 250, marginLeft: "3vw" }}
                    openOnFocus={true}
                    disablePortal
                    //disabled={afiliado.titular ? false : true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Estado"
                        variant="standard"
                      />
                    )}
                    //onKeyDown={handleEnter}
                    onChange={(event, value) => {
                      if (value) {
                        guardarEstadoCompensacion(value.nombre);
                        obtenerMovimientosCompensaciones({
                          empresaTodas,
                          empresaId: empresaElegida.id,
                          estado: value.nombre,
                        });
                      } else {
                        guardarEstadoCompensacion(null);
                      }
                    }}
                  />

                  <FormControlLabel
                    value="yes"
                    control={
                      <Checkbox
                        color="primary"
                        checked={empresaTodas}
                        style={{ marginLeft: "1vw" }}
                        onChange={(e) => {
                          //setCambio(true);
                          setEmpresaTodas(e.target.checked);
                          obtenerMovimientosCompensaciones({
                            empresaTodas,
                            empresaId: empresaElegida.id,
                            estado: estadoCompensacion,
                          });
    
                        }}
                      />
                    }
                    label="Todas las empresas"
                    labelPlacement="start"
                    style={{ paddingLeft: "1vw", paddingTop: "1vw" }}
                  />

                  <Button
                    icon="pi pi-check"
                    tooltip="Confirmar Fechas"
                    className={
                      cambio
                        ? "p-button-rounded p-button-danger"
                        : "p-button-rounded p-button-success"
                    }
                    style={{ marginLeft: "3em", display: "none" }}
                    onClick={(e) => {
                      setCambio(false);
                      obtenerMovimientosCompensaciones({
                        empresaTodas,
                        empresaId: empresaElegida.id,
                        estado: estadoCompensacion,
                      });
                    }}
                  />
                  <Grid container xs={12} md={12}>
                    <Grid item xs={12} md={12}>
                      <div className="datatable-responsive-demo">
                        <div className="card">
                          <Tooltip
                            target=".export-buttons>button"
                            position="bottom"
                          />
                          <DataTable
                            value={movimientosCompensaciones}
                            selection={selectedRow}
                            onSelectionChange={(e) => setSelectedRow(e.value)}
                            selectionMode="single"
                            dataKey="id"
                            size="small"
                            header={header}
                            className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                            style={{ fontSize: "small", marginTop: "1vw" }}
                            globalFilter={globalFilter}
                            emptyMessage="No hay datos."
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                            rows={100}
                            rowsPerPageOptions={[10, 20, 50, 100, 500]}
                            //paginatorLeft={paginatorLeft}
                            //paginatorRight={paginatorRight}
                          >
                            <Column
                              field="id"
                              header="ID"
                              //body={idTemplate}
                              style={{ fontSize: "xx-small" }}
                              sortable
                            ></Column>
                            <Column
                              field="fecha"
                              header="Fecha"
                              body={fechaTemplate}
                              style={{ fontSize: "xx-small" }}
                              sortable
                            ></Column>
                            <Column
                              field="afiliados.nombre_razonsocial"
                              header="Afiliado"
                              //body={habilitadoTemplate}
                              style={{ fontSize: "xx-small" }}
                              sortable
                            ></Column>
                            <Column
                              field="afiliados.numerodocumento"
                              header="C.U.I.T/D.N.I."
                              //body={habilitadoTemplate}
                              style={{ fontSize: "xx-small" }}
                              sortable
                            ></Column>
                            <Column
                              field="conceptos.nombre"
                              header="Concepto"
                              //body={habilitadoTemplate}
                              style={{ fontSize: "xx-small" }}
                              sortable
                            ></Column>
                            <Column
                              field="detalle"
                              header="Detalle"
                              //body={habilitadoTemplate}
                              style={{ fontSize: "xx-small" }}
                              sortable
                            ></Column>
                            <Column
                              field="empresas.nombre"
                              header="Empresa"
                              //body={habilitadoTemplate}
                              style={{ fontSize: "xx-small" }}
                              sortable
                            ></Column>
                            <Column
                              field="importe"
                              header="Importe"
                              //body={habilitadoTemplate}
                              style={{ fontSize: "xx-small" }}
                              sortable
                            ></Column>
                          </DataTable>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
            </Fragment>
          ) : (
            <Spinner />
          )}

          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "../Menu";
import { InputNumber } from "primereact/inputnumber";
import { InputMask } from "primereact/inputmask";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ProgressBar } from "primereact/progressbar";

const exportColumnsListado = [
  { title: "Nº Afiliado.", dataKey: "id_titular" },
  { title: "Nombre/Razón Social", dataKey: "nombre_razonsocial" },
  { title: "Domicilio", dataKey: "domicilio" },
  { title: "Localidad", dataKey: "localidad" },
  { title: "Grupo", dataKey: "grupo" },
  { title: "Plan", dataKey: "plan" },
  { title: "Empresa", dataKey: "empresa" },
  { title: "Vigencia", dataKey: "vigencia" },
  { title: "Cuota", dataKey: "cuota" },
  { title: "Módulo", dataKey: "modulo" },
  { title: "Valor", dataKey: "valor" },
];

export default function ProcesosFacturacion(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [visibleProceso, setVisibleProceso] = useState(true);
  const [todosLosCobradores, setTodosLosCobradores] = useState(false);
  const [repetirPeriodo, setRepetirPeriodo] = useState(false);
  const [proceso, guardarProceso] = useState(null);
  const [valor, guardarValor] = useState("cuota");
  const [fijo, setFijo] = useState(null);
  const toast = useRef(null);
  const acCobrador = useRef(null);
  const [visibleProgressBar, setVisibleProgressBar] = useState(false);

  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    cobradores,
    statusAcceso,
    empresaElegida,
    listadoExcel,
    empresas,
    resultProgressBar,
    obtenerAcceso,
    obtenerModulos,
    obtenerCobradores,
    obtenerEmpresas,
    procesoFacturacion,
    progressBar,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "ProcesosFacturacion",
      });
    }
  }, [state.id]);

  useEffect(() => {
    obtenerCobradores({ todos: false });
    obtenerEmpresas();
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      progressBar();
    }, 3000);
    return () => clearTimeout(interval);
  }, []);

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  const exitClick = (e) => {
    navigate("/");
  };

  const onHideProceso = (e) => {
    setVisibleProceso(false);
  };

  const aceptarProceso = async (e) => {
    e.preventDefault();
    //Validar

    if (
      !proceso ||
      proceso.fecha === undefined ||
      proceso.fecha === null ||
      proceso.fecha === 0 ||
      proceso.fecha.trim() === ""
    ) {
      mensajeAlerta(
        "Procesos",
        "La fecha de facturación es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !proceso ||
      proceso.vigencia === undefined ||
      proceso.vigencia === null ||
      proceso.vigencia.trim() === ""
    ) {
      mensajeAlerta(
        "Procesos",
        "La vigencia de facturación es necesaria !.",
        "error"
      );
      return;
    }

    if (!todosLosCobradores) {
      if (
        !proceso ||
        proceso.id_cobrador === undefined ||
        proceso.id_cobrador === null ||
        proceso.id_cobrador === 0 ||
        proceso.id_cobrador === ""
      ) {
        mensajeAlerta("Procesos", "El cobrador es necesario !.", "error");
        return;
      }
    }

    if (
      !proceso ||
      proceso.descripcion === undefined ||
      proceso.descripcion === null ||
      proceso.descripcion.trim() === ""
    ) {
      mensajeAlerta("Procesos", "Descripción es necesario !.", "error");
      return;
    }

    if (valor === "fijo") {
      if (!fijo || Number(fijo) <= 0 || fijo === "") {
        mensajeAlerta("Procesos", "Importe Fijo es necesario !.", "error");
        return;
      }
    }

    if (
      empresas &&
      empresas.length > 0 &&
      !empresas.find((item) => item.id === empresaElegida.id).iva
    ) {
      mensajeAlerta(
        "Procesos",
        "La empresa no tiene cargado el iva !.",
        "error"
      );
      return;
    }

    confirm1();
  };

  const confirm1 = () => {
    let cobrador = null
    if (todosLosCobradores) {
      cobrador = "Todos"
    } else {
      cobrador = proceso.cobrador
    }
    confirmDialog({
      message:
        "Esta seguro de iniciar el proceso de facturación. " +
        "Empresa " + empresaElegida.nombre + ". " +
        "Cobrador " + cobrador + ". " +
        "Este proceso no se puede interrumpir.",
      header: "Confirmación",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-success",
      closable: false,
      acceptLabel: "Si",
      accept: accept1,
    });
  };

  const accept1 = () => {
    procesoFacturacion({
      proceso,
      todosLosCobradores,
      valor,
      fijo,
      empresaElegida,
      repetirPeriodo,
      usuario: state.usuario.usuario,
    });
    setVisibleProgressBar(true);
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Proceso de Facturación",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const renderHeaderProceso = () => {
    return (
      <div>
        <Tag
          value={
            empresas && empresas.length > 0
              ? empresaElegida ? "Proceso de Facturación de " + empresaElegida.nombre : "Proceso de Facturación de " 
              : null
          }
        ></Tag>

        <Tag
          style={{ marginLeft: "1vw" }}
          value={
            empresas &&
            empresas.length > 0 &&
            empresas.find((item) => item.id === empresaElegida.id)
              .facturaElectronica
              ? "Factura Electrónica"
              : null
          }
          severity={"danger"}
        ></Tag>

        <Tag
          style={{ marginLeft: "1vw" }}
          value={
            empresas &&
            empresas.length > 0 &&
            empresas.find((item) => item.id === empresaElegida.id).iva
              ? "Iva %: " +
                empresas.find((item) => item.id === empresaElegida.id).iva
              : null
          }
          severity={"success"}
        ></Tag>
      </div>
    );
  };

  const renderFooterProceso = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarProceso}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
          autoFocus
        >
          Aceptar
        </ButtonMui>

        <ButtonMui
          onClick={onHideProceso}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const fechaTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fecha)}</Fragment>;
  };

  const anuladoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.anulado}></Checkbox>
      </Fragment>
    );
  };

  const exportExcel = () => {
    for (let i = 0; i < listadoExcel.length; i++) {
      listadoExcel[i].cuota = parseFloat(listadoExcel[i].cuota, 10);
      listadoExcel[i].valor = parseFloat(listadoExcel[i].valor, 10);
    }

    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(listadoExcel);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "modulosAfiliados");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default("l");
        doc.setFontSize(10);
        doc.text(
          `Listado de afiliados con Módulos - empresa: ${
            todosLosCobradores ? "TODAS" : empresaElegida.nombre
          } `,
          60,
          8
        );

        doc.autoTable(exportColumnsListado, listadoExcel, {
          styles: {
            fontSize: 6,
          },
          columnStyles: {
            8: {
              halign: "right",
            },
            10: {
              halign: "right",
            },
          },
          didParseCell: (data) => {
            if (data.cell && data.cell.section === "head") {
              switch (data.cell.raw) {
                case "Cuota":
                case "Valor":
                  data.cell.styles.halign = "right";
                  break;
                default:
                  data.cell.styles.halign = "left";
                  break;
              }
            }
          },
        });
        //doc.save("modulosAfiliados.pdf");
        window.open(doc.output("bloburl"));
      });
    });
  };

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  console.log(resultProgressBar);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      <ConfirmDialog />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Dialog
            header={renderHeaderProceso()}
            visible={visibleProceso}
            closable={false}
            footer={renderFooterProceso()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "70vw", paddingBottom: "0" }}
            position="top"
          >
            <Grid container spacing={3} style={{ marginTop: "1vw" }}>
              <Grid item xs={12} md={3}>
                <TextField
                  value={proceso ? proceso.fecha : null}
                  id="fecha"
                  label="Fecha de Facturación"
                  type="date"
                  required
                  fullWidth
                  variant="standard"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  //onKeyDown={handleEnter}
                  onChange={(e) =>
                    guardarProceso({
                      ...proceso,
                      fecha: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <label htmlFor="id">Vigencia de Facturación *</label>
                <InputMask
                  id="vigencia"
                  mask="99/99"
                  value={proceso ? proceso.vigencia : null}
                  placeholder="99/99"
                  //onBlur={handleEnter}
                  size={4}
                  onChange={(e) =>
                    guardarProceso({
                      ...proceso,
                      vigencia: e.value,
                    })
                  }
                  style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                ></InputMask>
              </Grid>

              <Grid
                item
                xs={6}
                md={4}
                style={{ paddingLeft: "1vw", marginTop: "1vw" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ marginLeft: "1vw", marginRight: "1vw" }}
                      inputId="repetirPeriodo"
                      onChange={(e) => {
                        setRepetirPeriodo(e.target.checked);
                      }}
                      checked={repetirPeriodo ? repetirPeriodo : false}
                    />
                  }
                  label="Repetir Período"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="cobrador"
                  ref={acCobrador}
                  fullWidth
                  noOptionsText={"No hay opciones"}
                  options={
                    cobradores
                      ? cobradores.filter((item) => item.habilitado)
                      : null
                  }
                  getOptionLabel={(option) => option.nombre}
                  openOnFocus={true}
                  disablePortal
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cobrador *"
                      variant="standard"
                    />
                  )}
                  disabled={todosLosCobradores}
                  //onKeyDown={handleEnter}
                  onChange={(event, value) => {
                    if (value) {
                      guardarProceso({
                        ...proceso,
                        id_cobrador: value.id,
                        cobrador: value.nombre,
                      });
                    } else {
                      guardarProceso({
                        ...proceso,
                        id_cobrador: null,
                        cobrador: null,
                      });
                    }
                  }}
                />
              </Grid>

              <Grid
                item
                xs={6}
                md={3}
                style={{ paddingLeft: "1vw", marginTop: "1vw" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ marginLeft: "1vw", marginRight: "1vw" }}
                      inputId="todosLosCobradores"
                      onChange={(e) => {
                        if (proceso && proceso.id_cobrador) {
                          acCobrador.current
                            .getElementsByClassName(
                              "MuiAutocomplete-clearIndicator"
                            )[0]
                            .click();
                        }
                        setTodosLosCobradores(e.target.checked);
                      }}
                      checked={todosLosCobradores ? todosLosCobradores : false}
                    />
                  }
                  label="Todos Las Cobradores"
                />
              </Grid>

              <Grid item xs={12} md={10}>
                <TextField
                  value={proceso ? proceso.descripcion : null}
                  id="descripcion"
                  label="Descripción"
                  fullWidth
                  variant="standard"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  //onKeyDown={handleEnter}
                  onChange={(e) =>
                    guardarProceso({
                      ...proceso,
                      descripcion: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="valor"
                    value={valor ? valor : null}
                    onChange={(e) => {
                      guardarValor(e.target.value);
                      if (e.target.value === "cuota") {
                        setFijo(null);
                      }
                    }}
                  >
                    <FormControlLabel
                      value="cuota"
                      control={<Radio />}
                      label="Cuota"
                    />
                    <FormControlLabel
                      value="fijo"
                      control={<Radio />}
                      label="Importe fijo"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <label htmlFor="cuota">Importe Fijo *</label>
                <InputNumber
                  id="importeFijo"
                  inputId="importeFijo"
                  value={fijo ? fijo : null}
                  //onKeyDown={handleEnter}
                  onChange={(e) => setFijo(e.value)}
                  disabled={valor && valor === "cuota" ? true : false}
                  mode="decimal"
                  locale="en-US"
                  minFractionDigits={2}
                  style={{ marginLeft: "0.7vw", marginTop: "0vw" }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                {resultProgressBar &&
                resultProgressBar.progressFacturacion &&
                visibleProgressBar ? (
                  <ProgressBar
                    value={resultProgressBar.progressFacturacion}
                  ></ProgressBar>
                ) : null}
              </Grid>
            </Grid>
          </Dialog>
        </Fragment>
      ) : null}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";

export default function Importaciones(props) {
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState(null);

  let navigate = useNavigate();

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Nombre", dataKey: "nombre" },
    { title: "Domicilio", dataKey: "domicilio" },
    { title: "Teléfono", dataKey: "telefono" },
    { title: "Email", dataKey: "email" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const toast = useRef(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    percepciones,
    statusAcceso,
    uploadFiles,
    obtenerAcceso,
    obtenerArchivosPercepciones,
    actualizarPercepcionAgip,
    actualizarPercepcionArba,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Importaciones" });
    }
  }, [state]);

  const importacionId = props.data;

  useEffect(() => {
    if (importacionId === 2) {
      obtenerArchivosPercepciones();
    }
  }, []);

  const accesoDenegado = () => {
    mensajeAlerta(
      "Comprobantes",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onChange = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  const uploadFile = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("Id", importacionId);
    uploadFiles(formData);
  };

  const importarPercepcionesAgip = (e) => {
    actualizarPercepcionAgip({file: e})
  };

  const importarPercepcionesArba = (e) => {
    actualizarPercepcionArba({file: e})
  };


  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {importacionId === 1 ? (
            <Fragment>
              <Grid item xs={12} md={12} style={{ margin: "1vw" }}>
                <Typography
                  variant="h6"
                  style={{ textDecoration: "underline", color: "coral" }}
                >
                  Subir Padrones Percepciones A.R.B.A. y A.G.I.P.
                </Typography>
              </Grid>

              <Grid item xs={12} md={12} style={{ margin: "1vw" }}>
                <div className="file-select" id="src-file1">
                  <input
                    type="file"
                    name="src-file1"
                    aria-label="Archivo"
                    accept=".txt"
                    onChange={onChange}
                  />
                </div>

                <div style={{ marginLeft: "1vw", display: "inline-block" }}>
                  <label htmlFor="src-file1">{filename}</label>
                </div>
              </Grid>

              <Grid item xs={12} md={12} style={{ margin: "1vw" }}>
                <Button
                  type="button"
                  icon="pi pi-upload"
                  onClick={uploadFile}
                  className="p-button-warning p-mr-2"
                  style={{ marginRight: "1em" }}
                  label="Subir Padrón"
                />
              </Grid>
            </Fragment>
          ) : null}
          {importacionId === 2 ? (
            <Fragment>
              <Grid
                container
                xs={12}
                md={12}
                style={{ borderStyle: "solid", borderWidth: "1px" }}
              >
                  <Grid item xs={12} md={12} style={{ margin: "1vw" }}>
                    <Typography
                      variant="h6"
                      style={{ textDecoration: "underline", color: "coral" }}
                    >
                      Importar Padrones Percepciones A.R.B.A.
                    </Typography>
                  </Grid>
                  {percepciones.map((element) => (
                    <Fragment>
                      {element.substring(0, 1) === "P" ? (
                        <Grid item xs={12} md={4} style={{ margin: "1vw" }}>
                          <Button
                            type="button"
                            icon="pi pi-download"
                            onClick={(e) => importarPercepcionesArba(element)}
                            className="p-button-warning p-mr-2"
                            style={{ marginRight: "1em" }}
                            label={"Importar Padrón " + element}
                          />
                        </Grid>
                      ) : null}
                    </Fragment>
                  ))}
              </Grid>

              <Grid
                container
                xs={12}
                md={12}
                style={{ borderStyle: "solid", borderWidth: "1px" }}
              >
                  <Grid item xs={12} md={12} style={{ margin: "1vw" }}>
                    <Typography
                      variant="h6"
                      style={{ textDecoration: "underline", color: "coral" }}
                    >
                      Importar Padrones Percepciones A.G.I.P.
                    </Typography>
                  </Grid>
                  {percepciones.map((element) => (
                    <Fragment>
                      {element.substring(0, 1) === "A" ? (
                        <Grid item xs={12} md={4} style={{ margin: "1vw" }}>
                          <Button
                            type="button"
                            icon="pi pi-download"
                            onClick={(e) => importarPercepcionesAgip(element)}
                            className="p-button-warning p-mr-2"
                            style={{ marginRight: "1em" }}
                            label={"Importar Padrón " + element}
                          />
                        </Grid>
                      ) : null}
                    </Fragment>
                  ))}
              </Grid>
            </Fragment>
          ) : null}
        </Fragment>
      ) : null}
    </Fragment>
  );
}

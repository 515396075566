import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../context/AlertContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from ".././Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from ".././Menu";
import { MultiSelect } from "primereact/multiselect";
import { InputMask } from "primereact/inputmask";


const exportColumnsListado = [
  { title: "ID", dataKey: "id" },
  { title: "Teléfono", dataKey: "telefono" },
  { title: "Status", dataKey: "status" },
  { title: "Enviado", dataKey: "enviado" },
  { title: "Mensaje", dataKey: "mensaje" },
];

export default function ListadosMensajes(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [visibleFechas, setVisibleFechas] = useState(true);
  const [grupo, setGrupo] = useState(null);
  const [incluyeIntegrantes, setIncluyeIntegrantes] = useState(false);
  const [todasLasEmpresas, setTodasLasEmpresas] = useState(false);
  const [fechaDesde, guardarFechaDesde] = useState(null);
  const [fechaHasta, guardarFechaHasta] = useState(null);
  const [selectedCities, setSelectedCities] = useState([]);
  const [telefono, guardarTelefono] = useState(null);

  const toast = useRef(null);

  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    empresaElegida,
    empresas,
    listadoExcel,
    obtenerAcceso,
    obtenerBajas,
    obtenerEmpresas,
    obtenerMensajesWhatsApp,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "Mensajes",
      });
    }
  }, [state.id]);

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  const startClick = (e) => {
    setTimeout(() => {
      navigate(`/listadosbajas`);
      setVisibleFechas(true);
    }, 2000);
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHideFechas = (e) => {
    setVisibleFechas(false);
  };

  const aceptarFechas = async (exportType) => {
    //e.preventDefault();
    //Validar

    if (fechaDesde === null) {
      mensajeAlerta(
        "Mensajes de WhatsApp",
        "Debe indicar fecha desde !.",
        "error"
      );
      return;
    }

    if (fechaHasta === null) {
      mensajeAlerta(
        "Mensajes de WhatsApp",
        "Debe indicar fecha hasta !.",
        "error"
      );
      return;
    }

    if (fechaDesde > fechaHasta) {
      mensajeAlerta(
        "Mensajes de WhatsApp",
        "Fecha desde no puede ser mayor que fecha hasta !.",
        "error"
      );
      return;
    }

    /*
    if (selectedCities && selectedCities.length === 0) {
      mensajeAlerta(
        "Mensajes de WhatsApp",
        "Debe indicar al menos una empresa !.",
        "error"
      );
      return;
    }
    */
    var elem;

    let fdesde = fechaDesde + "T00:00:00";
    let fhasta = fechaHasta + "T23:59:59";

    toast.current.show({
      severity: "info",
      summary: "Mensajes",
      detail: "Obteniendo información... !",
      life: 3000,
    });

    await obtenerMensajesWhatsApp({
      desde: fdesde,
      hasta: fhasta,
      selectedCities,
      telefono,
      exportType,
    }).then((data) => {
      if (exportType === "excel") {
        elem = document.getElementById("expButtonExcel");
        elem.click();
      }
      if (exportType === "pdf") {
        elem = document.getElementById("expButtonPdf");
        elem.click();
      }
    });
    /*
    onHideFechas();
    setGrupo(null);
    guardarVigencia({
      desde: null,
      hasta: null,
    });
    setIncluyeIntegrantes(false);
    setTodasLasEmpresas(false);
    */
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Mensajes de WhatsApp",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const renderHeaderFechas = () => {
    return (
      <div>
        <Tag value="Mensajes de WhatsApp"></Tag>
      </div>
    );
  };

  const renderFooterFechas = () => {
    return (
      <div>
        <button
          id="expButtonExcel"
          type="button"
          onClick={(e) => {
            exportExcel();
          }}
          style={{ visibility: "hidden" }}
        >
          Export Excel
        </button>
        <button
          id="expButtonPdf"
          type="button"
          onClick={(e) => {
            exportPdf();
          }}
          style={{ visibility: "hidden" }}
        >
          Export Pdf
        </button>

        <Button
          icon="pi pi-file-excel"
          onClick={(e) => {
            aceptarFechas("excel");
          }}
          className="p-button-raised p-button-success"
          data-pr-tooltip="XLS"
          label="Excel"
        />
        <Button
          icon="pi pi-file-pdf"
          onClick={(e) => {
            aceptarFechas("pdf");
            //exportPdf();
          }}
          className="p-button-raised p-button-warning"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
          label="PDF"
        />
        <ButtonMui
          onClick={onHideFechas}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const fechaTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fecha)}</Fragment>;
  };

  const anuladoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.anulado}></Checkbox>
      </Fragment>
    );
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(listadoExcel);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "mensajesWhatsApp");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };


  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
          const doc = new jsPDF.default("l");
          doc.setFontSize(10);
          doc.text(
            `Listado de mensajes de WhatsApp `,
            60,
            8
          );

          doc.autoTable(exportColumnsListado, listadoExcel, {
            styles: {
              fontSize: 6,
            },
            });
          //doc.save("modulosAfiliados.pdf");
          window.open(doc.output("bloburl"));
      });
    });
  };




  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  //console.log(empresas.filter((item) => item.control).length);
  //console.log(listadoExcel);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Dialog
            header={renderHeaderFechas()}
            visible={visibleFechas}
            closable={false}
            footer={renderFooterFechas()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "75vw", paddingBottom: "0" }}
            position="top"
          >
            <Grid container spacing={3} style={{ marginTop: "1vw" }}>
              <Grid item xs={12} md={3}>
                <TextField
                  id="fechaDesde"
                  label="Fecha Desde"
                  type="date"
                  variant="standard"
                  fullWidth
                  value={fechaDesde ? fechaDesde : null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    guardarFechaDesde(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  id="fechaHasta"
                  label="Fecha Hasta"
                  type="date"
                  variant="standard"
                  fullWidth
                  value={fechaHasta ? fechaHasta : null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    guardarFechaHasta(e.target.value);
                  }}
                />
              </Grid>


              <Grid item xs={12} md={4}>
              <label htmlFor="celular">Celular</label>
              <InputMask
                id="celular"
                mask="9999999999"
                value={telefono ? telefono : null}
                placeholder="9999999999"
                size={15}
                onChange={(e) =>
                  guardarTelefono(e.value)
                }
                style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
              ></InputMask>
            </Grid>

              {/*   
              <Grid
                item
                xs={12}
                md={12}
                style={{ paddingLeft: "1vw", marginTop: "1vw" }}
              >
                <label htmlFor="id">Empresas</label>

                <MultiSelect
                  value={selectedCities}
                  onChange={(e) => setSelectedCities(e.value)}
                  options={
                    empresas &&
                    empresas.filter((item) => item.control && item.habilitado)
                      .length > 0
                      ? empresas.filter(
                          (item) => item.control && item.habilitado
                        )
                      : empresas && empresas.filter((item) => item.habilitado)
                      ? empresas.filter((item) => item.habilitado)
                      : null
                  }
                  optionLabel="nombre"
                  placeholder="Seleccione la/s empresa/s"
                  maxSelectedLabels={5}
                  display="chip"
                  style={{ marginLeft: "1vw" }}
                  //className="w-full md:w-40rem"
                />
              </Grid>
              */}
            </Grid>
          </Dialog>
        </Fragment>
      ) : null}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

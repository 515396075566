import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";
import { InputNumber } from "primereact/inputnumber";
import { jsPDF } from "jspdf";

export default function MovimientosBancos(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  let navigate = useNavigate();

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Descripción", dataKey: "descripcion" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [movimientoBanco, guardarMovimientoBanco] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleTransferencias, setVisibleTransferencias] = useState(false);
  const [detalle, guardarDetalle] = useState(null);
  const [fechaDesde, guardarFechaDesde] = useState(null);
  const [fechaHasta, guardarFechaHasta] = useState(null);
  const [empresaTodas, setEmpresaTodas] = useState(false);
  const [tipo, setTipo] = useState(null);
  const [cambio, setCambio] = useState(false);
  const toast = useRef(null);
  const cuentaBanco = useRef();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    movimientosBancos,
    empresaElegida,
    bancosEgresos,
    bancosIngresos,
    bancosSaldoAnterior,
    bancosSaldoActual,
    empresas,
    cuentas,
    conceptos,
    obtenerMovimientosBancos,
    obtenerAcceso,
    agregarMovimientoBanco,
    actualizarMovimientoBanco,
    obtenerEmpresas,
    obtenerCuentas,
    obtenerConceptos,
    agregarTransferencia,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "MovimientosBancos",
      });
    }
  }, [state]);

  useEffect(() => {
    let date = new Date();
    let day = `${date.getDate()}`.padStart(2, "0");
    let month = `${date.getMonth() + 1}`.padStart(2, "0");
    let year = date.getFullYear();
    let fecha = `${year}-${month}-${day}`;
    guardarFechaDesde(fecha);
    guardarFechaHasta(fecha);
    /*
    obtenerMovimientosBancos({
      fechaDesde: fecha,
      fechaHasta: fecha,
      empresaTodas,
      empresaId: null,
    });
    */
    obtenerEmpresas();
    obtenerCuentas();
    obtenerConceptos();
  }, []);

  const imprimirClick = () => {
    if (!selectedRow) {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un movimiento de banco.",
        life: 3000,
      });
    }

    const doc = new jsPDF();
    let hoy = new Date();

    let fechaDia = hoy.getDate();
    let fechaMes = hoy.getMonth() + 1;
    let fechaAnio = hoy.getFullYear();
    let nombreMes = "";

    if (fechaMes === 1) {
      nombreMes = "Enero";
    }
    if (fechaMes === 2) {
      nombreMes = "Febrero";
    }
    if (fechaMes === 3) {
      nombreMes = "Marzo";
    }
    if (fechaMes === 4) {
      nombreMes = "Abril";
    }
    if (fechaMes === 5) {
      nombreMes = "Mayo";
    }
    if (fechaMes === 6) {
      nombreMes = "Junio";
    }
    if (fechaMes === 7) {
      nombreMes = "Julio";
    }
    if (fechaMes === 8) {
      nombreMes = "Agosto";
    }
    if (fechaMes === 9) {
      nombreMes = "Septiembre";
    }
    if (fechaMes === 10) {
      nombreMes = "Octubre";
    }
    if (fechaMes === 11) {
      nombreMes = "Noviembre";
    }
    if (fechaMes === 12) {
      nombreMes = "Diciembre";
    }

    let row = 10;
    doc.setFontSize(10);
    doc.text(fechaDia + " de " + nombreMes + " de " + fechaAnio, 160, row);
    row = 20;
    doc.setFontSize(11);
    let titulo = "COMPROBANTE DE MOVIMIENTO DE BANCO";
    doc.text(titulo, 65, row);
    let textWidth = doc.getTextWidth(titulo);
    doc.line(65, row + 2, 65 + textWidth + 1, row + 2);
    doc.setFontSize(10);
    row = 30;

    doc.text(`ID: ${selectedRow.id}`, 30, row, "left", "middle");

    doc.text(`EMPRESA: ${selectedRow.empresa}`, 120, row, "left", "middle");

    row = row + 10;

    doc.text(
      `Fecha del Movimiento: ${formatDate(selectedRow.fecha)}`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text(
      `Cuenta de Banco: ${
        selectedRow.cuenta !== null ? selectedRow.cuenta : ""
      }`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text(
      `Concepto del Movimiento: ${
        selectedRow.concepto !== null ? selectedRow.concepto : ""
      }`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text(
      `Detalle del Movimiento: ${
        selectedRow.detalle !== null ? selectedRow.detalle : ""
      }`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text(
      `Tipo de  Movimiento: ${
        selectedRow.tipo === "i"
          ? "INGRESO DE BANCO"
          : selectedRow.tipo === "e"
          ? "EGRESO DE BANCO"
          : ""
      }`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text(
      `Importe $: ${parseFloat(selectedRow.importe).toFixed(2)}`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 50;

    doc.text(`Recibí conforme`, 30, row, "left", "middle");

    doc.text(`Firma y sello de la caja`, 120, row, "left", "middle");

    row = row + 50;

    doc.text(`Firma y aclaración`, 30, row, "left", "middle");

    doc.text(`${selectedRow.usuario}`, 120, row, "left", "middle");

    window.open(doc.output("bloburl"));
    //setSelectedRow(null)
  };

  const addClick = (tipo) => {
    //props.history.push(`/movimientoBanco/0`);

    if (
      !movimientoBanco ||
      movimientoBanco.id_cuenta === undefined ||
      movimientoBanco.id_cuenta === null ||
      movimientoBanco.id_cuenta === ""
    ) {
      mensajeAlerta(
        "Movimientos Bancos",
        "Debe seleccionar una cuenta !.",
        "warn"
      );
      return;
    }

    if (cambio) {
      mensajeAlerta(
        "Movimientos Bancos",
        "Debe confirmar los datos desde el botón !.",
        "warn"
      );
      return;
    }

    setTipo(tipo);
    setVisible(true);
    setSelectedRow(null);
    let date = new Date();
    let day = `${date.getDate()}`.padStart(2, "0");
    let month = `${date.getMonth() + 1}`.padStart(2, "0");
    let year = date.getFullYear();
    let fecha = `${year}-${month}-${day}`;

    guardarMovimientoBanco({
      ...movimientoBanco,
      fecha,
      tipo,
      id_concepto: null,
      detalle: null,
      importe: null,
    });
  };

  const addTransClick = () => {
    //props.history.push(`/movimientoBanco/0`);

    if (
      !movimientoBanco ||
      movimientoBanco.id_cuenta === undefined ||
      movimientoBanco.id_cuenta === null ||
      movimientoBanco.id_cuenta === ""
    ) {
      mensajeAlerta(
        "Movimientos Bancos",
        "Debe seleccionar una cuenta !.",
        "warn"
      );
      return;
    }

    if (cambio) {
      mensajeAlerta(
        "Movimientos Bancos",
        "Debe confirmar los datos desde el botón !.",
        "warn"
      );
      return;
    }

    setVisibleTransferencias(true);
    setSelectedRow(null);
    let date = new Date();
    let day = `${date.getDate()}`.padStart(2, "0");
    let month = `${date.getMonth() + 1}`.padStart(2, "0");
    let year = date.getFullYear();
    let fecha = `${year}-${month}-${day}`;

    guardarMovimientoBanco({
      ...movimientoBanco,
      fecha,
      id_concepto: null,
      id_cuentadestino: null,
      detalle: null,
      importe: null,
    });
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const onHideTransferencias = (e) => {
    setVisibleTransferencias(false);
  };

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !movimientoBanco ||
      movimientoBanco.fecha === undefined ||
      movimientoBanco.fecha === null ||
      movimientoBanco.fecha === ""
    ) {
      mensajeAlerta("Movimientos Bancos", "La fecha es necesaria !.", "error");
      return;
    }

    if (
      !movimientoBanco ||
      movimientoBanco.id_cuenta === undefined ||
      movimientoBanco.id_cuenta === null ||
      movimientoBanco.id_cuenta === ""
    ) {
      mensajeAlerta("Movimientos Bancos", "La cuenta es necesaria !.", "error");
      return;
    }

    if (
      !movimientoBanco ||
      movimientoBanco.cuenta === undefined ||
      movimientoBanco.cuenta === null ||
      movimientoBanco.cuenta === ""
    ) {
      mensajeAlerta("Movimientos Bancos", "La cuenta es necesaria !.", "error");
      return;
    }

    if (
      !movimientoBanco ||
      movimientoBanco.id_concepto === undefined ||
      movimientoBanco.id_concepto === null ||
      movimientoBanco.id_concepto === ""
    ) {
      mensajeAlerta(
        "Movimientos Bancos",
        "El concepto es necesario !.",
        "error"
      );
      return;
    }

    if (
      !movimientoBanco ||
      movimientoBanco.importe === undefined ||
      movimientoBanco.importe === null ||
      movimientoBanco.importe === ""
    ) {
      mensajeAlerta(
        "Movimientos Bancos",
        "El importe es necesario !.",
        "error"
      );
      return;
    }

    if (Number(movimientoBanco.importe) <= 0) {
      mensajeAlerta(
        "Movimientos Bancos",
        "El importe debe ser positivo !.",
        "error"
      );
      return;
    }

    // Llamar al context
    if (selectedRow) {
      //actualizarMovimientoBanco({ movimientoBanco });
    } else {
      agregarMovimientoBanco({
        movimientoBanco,
        usuario: state.usuario.usuario,
      });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerMovimientosBancos({
        fechaDesde,
        fechaHasta,
        cuentaId: movimientoBanco.id_cuenta,
      });
    }, 2000);
    //exitClick();
  };

  const aceptarTransferenciasClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !movimientoBanco ||
      movimientoBanco.fecha === undefined ||
      movimientoBanco.fecha === null ||
      movimientoBanco.fecha === ""
    ) {
      mensajeAlerta(
        "Transferencias Cuentas Propias",
        "La fecha es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !movimientoBanco ||
      movimientoBanco.id_cuenta === undefined ||
      movimientoBanco.id_cuenta === null ||
      movimientoBanco.id_cuenta === ""
    ) {
      mensajeAlerta(
        "Transferencias Cuentas Propias",
        "La cuenta es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !movimientoBanco ||
      movimientoBanco.cuenta === undefined ||
      movimientoBanco.cuenta === null ||
      movimientoBanco.cuenta === ""
    ) {
      mensajeAlerta(
        "Transferencias Cuentas Propias",
        "La cuenta es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !movimientoBanco ||
      movimientoBanco.id_concepto === undefined ||
      movimientoBanco.id_concepto === null ||
      movimientoBanco.id_concepto === ""
    ) {
      mensajeAlerta(
        "Transferencias Cuentas Propias",
        "El concepto es necesario !.",
        "error"
      );
      return;
    }

    if (
      !movimientoBanco ||
      movimientoBanco.importe === undefined ||
      movimientoBanco.importe === null ||
      movimientoBanco.importe === ""
    ) {
      mensajeAlerta(
        "Transferencias Cuentas Propias",
        "El importe es necesario !.",
        "error"
      );
      return;
    }

    if (Number(movimientoBanco.importe) <= 0) {
      mensajeAlerta(
        "Transferencias Cuentas Propias",
        "El importe debe ser positivo !.",
        "error"
      );
      return;
    }

    if (
      !movimientoBanco ||
      movimientoBanco.id_cuentadestino === undefined ||
      movimientoBanco.id_cuentadestino === null ||
      movimientoBanco.id_cuentadestino === ""
    ) {
      mensajeAlerta(
        "Transferencias Cuentas Propias",
        "La cuenta destino es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !movimientoBanco ||
      movimientoBanco.cuentadestino === undefined ||
      movimientoBanco.cuentadestino === null ||
      movimientoBanco.cuentadestino === ""
    ) {
      mensajeAlerta(
        "Transferencias Cuentas Propias",
        "La cuenta destino es necesaria !.",
        "error"
      );
      return;
    }

    if (
      movimientoBanco &&
      movimientoBanco.id_cuentadestino === movimientoBanco.id_cuenta
    ) {
      mensajeAlerta(
        "Transferencias Cuentas Propias",
        "La cuenta destino debe ser diferente de la de origen !.",
        "error"
      );
      return;
    }

    // Llamar al context
    if (selectedRow) {
      //actualizarMovimientoBanco({ movimientoBanco });
    } else {
      agregarTransferencia({
        movimientoBanco,
        usuario: state.usuario.usuario,
      });
    }
    onHideTransferencias();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerMovimientosBancos({
        fechaDesde,
        fechaHasta,
        cuentaId: movimientoBanco.id_cuenta,
      });
    }, 2000);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Movimientos Bancos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  const fechaTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fecha)}</Fragment>;
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, movimientosBancos);
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(movimientosBancos);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "movimientosBancos");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Movimientos Bancos"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag
          value={
            tipo === "i"
              ? "Movimiento de Banco - INGRESOS"
              : tipo === "e"
              ? "Movimiento de Banco - EGRESOS"
              : null
          }
        ></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderHeaderTransferencias = () => {
    return (
      <div>
        <Tag value="Transferencias Cuentas Propias"></Tag>
      </div>
    );
  };

  const renderFooterTrasferencias = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarTransferenciasClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideTransferencias}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  function handleEnter(event) {
    if (event.keyCode === 13) {
      let element = event.target.id;
      let campo = null;
      switch (element) {
        case "fechaDesde":
          campo = document.getElementById("fechaHasta");
          campo.focus();
          break;
        case "fecha":
          campo = document.getElementById("empresaMovimiento");
          campo.focus();
          break;
        case "empresaMovimiento":
          campo = document.getElementById("concepto");
          campo.focus();
          break;
        case "concepto":
          campo = document.getElementById("detalle");
          campo.focus();
          break;
        case "detalle":
          campo = document.getElementById("importe");
          campo.focus();
          break;
      }
    }
  }

  //console.log(movimientoBanco);
  //console.log(state);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {movimientosBancos ? (
            <Fragment>
              <Grid
                container
                spacing={0}
                style={{ marginTop: "1vw", marginLeft: "1vw" }}
              >
                <Button
                  icon="pi pi-arrow-down"
                  tooltip="Agregar Ingresos"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={(e) => addClick("i")}
                />
                <Button
                  icon="pi pi-arrow-up"
                  tooltip="Agregar Egresos"
                  className="p-button-rounded p-button-secondary"
                  style={{ marginLeft: "1em" }}
                  onClick={(e) => addClick("e")}
                />
                <Button
                  icon="pi pi-sync"
                  tooltip="Transferencia Cuentas Propias"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={(e) => addTransClick()}
                />
                <Button
                  icon="pi pi-file-pdf"
                  tooltip="Imprimir"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={imprimirClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <TextField
                  value={fechaDesde ? fechaDesde : null}
                  id="fechaDesde"
                  label="Fecha Desde"
                  type="date"
                  variant="standard"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ marginLeft: "1em" }}
                  onChange={(e) => {
                    guardarFechaDesde(e.target.value);
                    setCambio(true);
                  }}
                  onKeyDown={handleEnter}
                />
                <TextField
                  value={fechaHasta ? fechaHasta : null}
                  id="fechaHasta"
                  label="Fecha Hasta"
                  type="date"
                  variant="standard"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ marginLeft: "1em" }}
                  //onKeyDown={handleEnter}
                  onChange={(e) => {
                    setCambio(true);
                    guardarFechaHasta(e.target.value);
                  }}
                />
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    id="cuenta"
                    ref={cuentaBanco}
                    fullWidth
                    required
                    noOptionsText={"No hay opciones"}
                    options={
                      cuentas && empresaTodas
                        ? cuentas.filter((item) => item.habilitado)
                        : cuentas && !empresaTodas
                        ? cuentas.filter(
                            (item) =>
                              item.habilitado &&
                              item.id_empresa === empresaElegida.id
                          )
                        : null
                    }
                    getOptionLabel={(option) => option.nombre}
                    openOnFocus={true}
                    disablePortal
                    style={{ marginTop: "-0.3vw", marginLeft: "2vw" }}
                    //disabled={afiliado.titular ? false : true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cuenta *"
                        variant="standard"
                      />
                    )}
                    onChange={(event, value) => {
                      if (value) {
                        guardarMovimientoBanco({
                          ...movimientoBanco,
                          id_cuenta: value.id,
                          cuenta: value.nombre,
                        });
                        setCambio(true);
                      } else {
                        guardarMovimientoBanco({
                          ...movimientoBanco,
                          id_cuenta: null,
                          cuenta: null,
                        });
                      }
                    }}
                  />
                </Grid>

                <FormControlLabel
                  value="yes"
                  control={
                    <Checkbox
                      color="primary"
                      checked={empresaTodas}
                      style={{ marginLeft: "1vw" }}
                      onChange={(e) => {
                        setCambio(true);
                        setEmpresaTodas(e.target.checked);
                        setTimeout(() => {
                          const ele =
                            cuentaBanco.current.getElementsByClassName(
                              "MuiAutocomplete-clearIndicator"
                            )[0];
                          if (ele) ele.click();
                        }, 500);
                      }}
                    />
                  }
                  label="Todas las empresas"
                  labelPlacement="start"
                  style={{ paddingLeft: "2vw", paddingTop: "1vw" }}
                />

                <Button
                  icon="pi pi-check"
                  tooltip="Confirmar Datos"
                  tooltipOptions={{ position: "bottom" }}
                  className={
                    cambio
                      ? "p-button-rounded p-button-danger"
                      : "p-button-rounded p-button-success"
                  }
                  style={{ marginLeft: "3em" }}
                  onClick={(e) => {
                    if (
                      !fechaDesde ||
                      fechaDesde === undefined ||
                      fechaDesde === null ||
                      fechaDesde === ""
                    ) {
                      toast.current.show({
                        severity: "info",
                        summary: "Movimientos Bancos",
                        detail: "Debe seleccionar fecha desde.",
                        life: 3000,
                      });
                      return;
                    }

                    if (
                      !fechaHasta ||
                      fechaHasta === undefined ||
                      fechaHasta === null ||
                      fechaHasta === ""
                    ) {
                      toast.current.show({
                        severity: "info",
                        summary: "Movimientos Bancos",
                        detail: "Debe seleccionar fecha hasta.",
                        life: 3000,
                      });
                      return;
                    }

                    if (
                      !movimientoBanco ||
                      movimientoBanco.id_cuenta === undefined ||
                      movimientoBanco.id_cuenta === null ||
                      movimientoBanco.id_cuenta === ""
                    ) {
                      toast.current.show({
                        severity: "info",
                        summary: "Movimientos Bancos",
                        detail: "Debe seleccionar una cuenta.",
                        life: 3000,
                      });
                      return;
                    }

                    setCambio(false);
                    obtenerMovimientosBancos({
                      fechaDesde,
                      fechaHasta,
                      cuentaId: movimientoBanco.id_cuenta,
                    });
                  }}
                />

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ align: "center", marginRight: "2em" }}
                >
                  <div className="datatable-responsive-demo">
                    <div className="card">
                      <Tooltip
                        target=".export-buttons>button"
                        position="bottom"
                      />
                      <DataTable
                        value={movimientosBancos}
                        selection={selectedRow}
                        onSelectionChange={(e) => setSelectedRow(e.value)}
                        selectionMode="single"
                        dataKey="id"
                        size="small"
                        header={header}
                        className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                        style={{ fontSize: "small", marginTop: "1vw" }}
                        globalFilter={globalFilter}
                        emptyMessage="No hay datos."
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                        rows={100}
                        rowsPerPageOptions={[10, 20, 50, 100, 500]}
                        //paginatorLeft={paginatorLeft}
                        //paginatorRight={paginatorRight}
                      >
                        <Column
                          field="id"
                          header="ID"
                          //body={idTemplate}
                          style={{ fontSize: "xx-small" }}
                          sortable
                        ></Column>
                        <Column
                          field="fecha"
                          header="Fecha"
                          body={fechaTemplate}
                          style={{ fontSize: "xx-small" }}
                          sortable
                        ></Column>
                        <Column
                          field="concepto"
                          header="Concepto"
                          //body={habilitadoTemplate}
                          style={{ fontSize: "xx-small" }}
                          sortable
                        ></Column>
                        <Column
                          field="detalle"
                          header="Detalle"
                          //body={habilitadoTemplate}
                          style={{ fontSize: "xx-small" }}
                          sortable
                        ></Column>
                        <Column
                          field="ingreso"
                          header="Ingreso"
                          //body={habilitadoTemplate}
                          style={{ fontSize: "xx-small" }}
                          sortable
                        ></Column>
                        <Column
                          field="egreso"
                          header="Egreso"
                          //body={habilitadoTemplate}
                          style={{ fontSize: "xx-small" }}
                          sortable
                        ></Column>
                      </DataTable>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{
                  align: "center",
                  marginLeft: "1vw",
                  marginTop: "5.5vw",
                }}
              >
                <Tag
                  value={
                    "Saldo Anterior: $ " +
                    parseFloat(bancosSaldoAnterior).toFixed(2)
                  }
                ></Tag>
                <Tag
                  value={"Ingresos: $ " + parseFloat(bancosIngresos).toFixed(2)}
                ></Tag>
                <Tag
                  value={"Egresos: $ " + parseFloat(bancosEgresos).toFixed(2)}
                ></Tag>
                <Tag
                  value={
                    "Saldo Actual: $ " +
                    parseFloat(bancosSaldoActual).toFixed(2)
                  }
                ></Tag>
              </Grid>
              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      value={movimientoBanco ? movimientoBanco.fecha : null}
                      id="fecha"
                      label="Fecha *"
                      type="date"
                      required
                      variant="standard"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ marginLeft: "1em" }}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarMovimientoBanco({
                          ...movimientoBanco,
                          fecha: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <TextField
                      value={movimientoBanco ? movimientoBanco.cuenta : null}
                      id="cuenta"
                      label="Cuenta"
                      fullWidth
                      required
                      variant="standard"
                      size="small"
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ marginLeft: "1em" }}
                      //onKeyDown={handleEnter}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      id="concepto"
                      fullWidth
                      required
                      noOptionsText={"No hay opciones"}
                      options={
                        conceptos
                          ? conceptos.filter((item) => item.habilitado)
                          : null
                      }
                      getOptionLabel={(option) =>
                        option.nombre + " - " + option.id
                      }
                      openOnFocus={true}
                      disablePortal
                      //disabled={afiliado.titular ? false : true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Concepto *"
                          variant="standard"
                        />
                      )}
                      onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarMovimientoBanco({
                            ...movimientoBanco,
                            id_concepto: value.id,
                            concepto: value.nombre,
                          });
                        } else {
                          guardarMovimientoBanco({
                            ...movimientoBanco,
                            id_concepto: null,
                            concepto: null,
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      value={movimientoBanco ? movimientoBanco.detalle : null}
                      id="detalle"
                      label="Detalle"
                      fullWidth
                      variant="standard"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarMovimientoBanco({
                          ...movimientoBanco,
                          detalle: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <label htmlFor="importe">Importe *</label>
                    <InputNumber
                      id="importe"
                      inputId="importe"
                      required
                      value={movimientoBanco ? movimientoBanco.importe : null}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarMovimientoBanco({
                          ...movimientoBanco,
                          importe: e.value,
                        })
                      }
                      mode="decimal"
                      locale="en-US"
                      minFractionDigits={2}
                      style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                    />
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeaderTransferencias()}
                visible={visibleTransferencias}
                closable={false}
                footer={renderFooterTrasferencias()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      value={movimientoBanco ? movimientoBanco.fecha : null}
                      id="fecha"
                      label="Fecha *"
                      type="date"
                      required
                      variant="standard"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ marginLeft: "1em" }}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarMovimientoBanco({
                          ...movimientoBanco,
                          fecha: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <TextField
                      value={movimientoBanco ? movimientoBanco.cuenta : null}
                      id="cuenta"
                      label="Cuenta Origen"
                      fullWidth
                      required
                      variant="standard"
                      size="small"
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ marginLeft: "1em" }}
                      //onKeyDown={handleEnter}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      id="concepto"
                      fullWidth
                      required
                      noOptionsText={"No hay opciones"}
                      options={
                        conceptos
                          ? conceptos.filter((item) => item.habilitado)
                          : null
                      }
                      getOptionLabel={(option) =>
                        option.nombre + " - " + option.id
                      }
                      openOnFocus={true}
                      disablePortal
                      //disabled={afiliado.titular ? false : true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Concepto *"
                          variant="standard"
                        />
                      )}
                      onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarMovimientoBanco({
                            ...movimientoBanco,
                            id_concepto: value.id,
                            concepto: value.nombre,
                          });
                        } else {
                          guardarMovimientoBanco({
                            ...movimientoBanco,
                            id_concepto: null,
                            concepto: null,
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      value={movimientoBanco ? movimientoBanco.detalle : null}
                      id="detalle"
                      label="Detalle"
                      fullWidth
                      variant="standard"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarMovimientoBanco({
                          ...movimientoBanco,
                          detalle: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <label htmlFor="importe">Importe *</label>
                    <InputNumber
                      id="importe"
                      inputId="importe"
                      required
                      value={movimientoBanco ? movimientoBanco.importe : null}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarMovimientoBanco({
                          ...movimientoBanco,
                          importe: e.value,
                        })
                      }
                      mode="decimal"
                      locale="en-US"
                      minFractionDigits={2}
                      style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      id="cuentaDestino"
                      fullWidth
                      required
                      noOptionsText={"No hay opciones"}
                      options={
                        cuentas && empresaTodas
                          ? cuentas.filter((item) => item.habilitado)
                          : cuentas && !empresaTodas
                          ? cuentas.filter(
                              (item) =>
                                item.habilitado &&
                                item.id_empresa === empresaElegida.id
                            )
                          : null
                      }
                      getOptionLabel={(option) => option.nombre}
                      openOnFocus={true}
                      disablePortal
                      style={{ marginTop: "-0vw", marginLeft: "0vw" }}
                      //disabled={afiliado.titular ? false : true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Cuenta Destino*"
                          variant="standard"
                        />
                      )}
                      onChange={(event, value) => {
                        if (value) {
                          guardarMovimientoBanco({
                            ...movimientoBanco,
                            id_cuentadestino: value.id,
                            cuentadestino: value.nombre,
                          });
                        } else {
                          guardarMovimientoBanco({
                            ...movimientoBanco,
                            id_cuentadestino: null,
                            cuentadestino: null,
                          });
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}

          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../context/AlertContext";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { saveAs } from "file-saver";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import Checkbox from "@mui/material/Checkbox";

import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Tooltip,
  useMap,
  latLngBound,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./Map.css";
import L from "leaflet";
import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { OrderList } from "primereact/orderlist";
import "../../assets/css/OrderListDemo.css";
import { InputTextarea } from "primereact/inputtextarea";
import MotivosBajas from "./MotivosBajas";

export default function ClientesDatosPersonales(props) {
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const { direcciones, obtenerDirecciones, guardarDirecciones } =
    serviciosContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso2,
    statusAcceso3,
    cliente,
    grupos,
    rubros,
    provincias,
    localidades,
    empresas,
    planes,
    cobradores,
    asesores,
    frecuencias,
    tiposContribuyentes,
    tiposDocumentos,
    formasPagos,
    afiliado,
    bandFirst,
    empresaElegida,
    grupo,
    rubro,
    provincia,
    provinciaFacturacion,
    empresa,
    localidad,
    localidadFacturacion,
    domicilioGeo,
    domicilioGeoFacturacion,
    tipoDocumento,
    plan,
    planExtraordinaria,
    asesor,
    cobrador,
    frecuencia,
    tipoContribuyente,
    formaPago,
    titular,
    historiales,
    adicionales,
    adicional,
    modulo,
    selectedRowClientes,
    motivosBajas,
    motivosSuspendidos,
    clienteRepetido,
    documentoRepetido,
    obtenerGrupos,
    obtenerRubros,
    obtenerProvincias,
    obtenerLocalidades,
    obtenerEmpresas,
    obtenerPlanes,
    guardarPlanes,
    obtenerCobradores,
    obtenerAsesores,
    obtenerFrecuencias,
    obtenerTiposDocumentos,
    obtenerTiposContribuyentes,
    obtenerFormasPagos,
    guardarAfiliado,
    guardarGrupo,
    guardarRubro,
    guardarProvincia,
    guardarProvinciaFacturacion,
    guardarEmpresa,
    guardarLocalidad,
    guardarLocalidadFacturacion,
    guardarDomicilioGeo,
    guardarDomicilioGeoFacturacion,
    guardarTipoDocumento,
    guardarPlan,
    guardarPlanExtraordinaria,
    guardarAsesor,
    guardarCobrador,
    guardarFrecuencia,
    guardarTipoContribuyente,
    guardarFormaPago,
    setBandFirst,
    guardarCliente,
    obtenerHistorial,
    agregarHistorial,
    obtenerMotivosBajas,
    obtenerMotivosSuspendidos,
    inhabilitarAfiliado,
    habilitarAfiliado,
    buscarTitular,
    setSelectedRowClientes,
    suspenderAfiliado,
    cancelarSuspenderAfiliado,
    obtenerAdicionales,
    agregarAdicional,
    actualizarAdicional,
    guardarAdicional,
    buscarIntegrantes,
    obtenerClienteId,
    setClienteRepetido,
    obtenerDocumentoCliente,
    setDocumentoRepetido,
    enviarWhatsAppAfiliado,
  } = configuracionContext;

  const [openDomicilio, setOpenDomicilio] = useState(false);
  const [openDomicilioFacturacion, setOpenDomicilioFacturacion] =
    useState(false);
  const [openCuit, setOpenCuit] = useState(false);
  const [historial, setHistorial] = useState(null);
  const [message, setMessage] = useState(null);
  const [historialVisible, setHistorialVisible] = useState(false);
  const [messageVisible, setMessageVisible] = useState(false);
  const [inhabilitarVisible, setInhabilitarVisible] = useState(false);
  const [valueDate, setValueDate] = useState(null);
  const [motivoBaja, guardarMotivoBaja] = useState(null);
  const [motivoSuspendido, guardarMotivoSuspendido] = useState(null);
  const [habilitarVisible, setHabilitarVisible] = useState(false);
  const [suspenderVisible, setSuspenderVisible] = useState(false);
  const [valueDateSuspenderDesde, setValueDateSuspenderDesde] = useState(null);
  const [valueDateSuspenderHasta, setValueDateSuspenderHasta] = useState(null);
  const [cancelarSuspenderVisible, setCancelarSuspenderVisible] =
    useState(false);

  const toast = useRef(null);
  const acPlanExtra = useRef(null);

  useEffect(() => {
    if (cliente && bandFirst) {
      guardarAfiliado(cliente);
      obtenerAdicionales();
      obtenerProvincias();
      obtenerLocalidades();
      obtenerGrupos();
      obtenerRubros();
      obtenerEmpresas();
      obtenerPlanes();
      obtenerCobradores({ todos: false });
      obtenerAsesores();
      obtenerFrecuencias();
      obtenerTiposDocumentos();
      obtenerTiposContribuyentes();
      obtenerFormasPagos();
      setBandFirst(true);
    }
  }, [cliente.id]);

  const handleInputBlurId = (e) => {
    let nuevoId = afiliado.id.replace(/_/g, "");
    nuevoId = nuevoId.padStart(8, 0);

    if (nuevoId === "99999999") {
      mensajeAlerta(
        "Afiliados",
        "Debe ingresar un Nº de afiliado válido !.",
        "error"
      );
      let campo = document.getElementById("id");
      if (campo) {
        campo.focus();
      }
      return;
    }

    guardarAfiliado({
      ...afiliado,
      id: nuevoId,
    });

    obtenerClienteId({ titularId: nuevoId });

    let campo = document.getElementById("nombre");
    if (campo) {
      campo.focus();
    }
  };

  const handleInputBlurNumeroDocumento = (e) => {
    let nuevoId = afiliado.id.replace(/_/g, "");
    nuevoId = nuevoId.padStart(8, 0);
    nuevoId = nuevoId.substring(0, 8);
    obtenerDocumentoCliente({
      titularId: nuevoId,
      documento: afiliado.numerodocumento,
    });
  };

  const handleInputBlurLocalidad = (e) => {
    if (
      !provincia ||
      provincia === undefined ||
      provincia === null ||
      e.target.value === undefined ||
      e.target.value === null ||
      e.target.value === ""
    ) {
      toast.current.show({
        severity: "warn",
        summary: "Mapa",
        detail:
          "Para ubicar la georreferencia debe indicar Domicilio, Localidad y Provincia.",
        life: 5000,
      });
      return;
    }

    obtenerDirecciones({
      domicilio:
        afiliado.domicilio.toLowerCase() + " " + e.target.value.toLowerCase(),
      provincia: provincia.nombre.toLowerCase(),
    });
  };

  const handleInputBlurLocalidadFacturacion = (e) => {
    if (
      !provinciaFacturacion ||
      provinciaFacturacion === undefined ||
      provinciaFacturacion === null ||
      e.target.value === undefined ||
      e.target.value === null ||
      e.target.value === ""
    ) {
      toast.current.show({
        severity: "warn",
        summary: "Mapa",
        detail:
          "Para ubicar la georreferencia debe indicar Domicilio, Localidad y Provincia.",
        life: 5000,
      });
      return;
    }

    obtenerDirecciones({
      domicilio:
        afiliado.domicilio_facturacion.toLowerCase() +
        " " +
        e.target.value.toLowerCase(),
      provincia: provinciaFacturacion.nombre.toLowerCase(),
    });
  };

  const handleClickOpenDomicilio = () => {
    setOpenDomicilio(true);
  };

  const handleClickOpenDomicilioFacturacion = () => {
    setOpenDomicilioFacturacion(true);
  };

  const handleClickOpenCuit = () => {
    setOpenCuit(true);
  };

  const handleCloseDomicilio = () => {
    setOpenDomicilio(false);
    setOpenDomicilioFacturacion(false);
  };

  const renderHeaderMapa = () => {
    return (
      <div>
        <Tag value="Ubicación en el Mapa"></Tag>
      </div>
    );
  };

  const renderFooterMapa = () => {
    return (
      <div>
        <Button
          label="Cerrar"
          icon="pi pi-times"
          onClick={() => handleCloseDomicilio()}
          className="p-button-text"
        />
      </div>
    );
  };

  function PopupExampleDomicilio() {
    if (
      !afiliado ||
      afiliado.lat === undefined ||
      afiliado.lat === null ||
      afiliado.lon === undefined ||
      afiliado.lon === null
    ) {
      return;
    }

    let position = [];
    position.push(afiliado.lat);
    position.push(afiliado.lon);

    const icon = L.icon({
      iconRetinaUrl: iconRetina,
      iconUrl: iconMarker,
      shadowUrl: iconShadow,
    });

    return (
      <MapContainer
        center={position}
        zoom={16}
        style={{ height: "60vh", width: "60vh" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position} icon={icon}></Marker>
      </MapContainer>
    );
  }

  function PopupExampleDomicilioFacturacion() {
    if (
      !afiliado ||
      afiliado.lat_facturacion === undefined ||
      afiliado.lat_facturacion === null ||
      afiliado.lon_facturacion === undefined ||
      afiliado.lon_facturacion === null
    ) {
      return;
    }

    let position = [];
    position.push(afiliado.lat_facturacion);
    position.push(afiliado.lon_facturacion);

    const icon = L.icon({
      iconRetinaUrl: iconRetina,
      iconUrl: iconMarker,
      shadowUrl: iconShadow,
    });

    return (
      <MapContainer
        center={position}
        zoom={16}
        style={{ height: "60vh", width: "60vh" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position} icon={icon}></Marker>
      </MapContainer>
    );
  }

  function handleEnter(event) {
    /*
    if (event.keyCode === 13) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "nombre":
          campo = document.getElementById("domicilio");
          campo.focus();
          break;
        case "domicilio":
          campo = document.getElementById("entre1");
          campo.focus();
          break;
        case "entre1":
          campo = document.getElementById("entre2");
          campo.focus();
          break;
        case "entre2":
          campo = document.getElementById("provincia");
          campo.focus();
          break;
        case "provincia":
          campo = document.getElementById("localidad");
          campo.focus();
          break;
        case "localidad":
          campo = document.getElementById("domicilioGeo");
          campo.focus();
          break;
        case "domicilioGeo":
          campo = document.getElementById("cp");
          campo.focus();
          break;
        case "cp":
          campo = document.getElementById("tipoDocumento");
          campo.focus();
          break;
        case "tipoDocumento":
          campo = document.getElementById("numeroDocumento");
          campo.focus();
          break;
        case "numeroDocumento":
          campo = document.getElementById("fechaNacimiento");
          campo.focus();
          break;
        case "fechaNacimiento":
          campo = document.getElementById("fechaIngreso");
          campo.focus();
          break;
        case "fechaIngreso":
          campo = document.getElementById("vigencia");
          campo.focus();
          break;
        case "vigencia":
          campo = document.getElementById("nombreFacturacion");
          campo.focus();
          break;
        case "nombreFacturacion":
          campo = document.getElementById("domicilioFacturacion");
          campo.focus();
          break;
        case "domicilioFacturacion":
          campo = document.getElementById("entre1Facturacion");
          campo.focus();
          break;
        case "entre1Facturacion":
          campo = document.getElementById("entre2Facturacion");
          campo.focus();
          break;
        case "entre2Facturacion":
          campo = document.getElementById("provinciaFacturacion");
          campo.focus();
          break;
        case "provinciaFacturacion":
          campo = document.getElementById("localidadFacturacion");
          campo.focus();
          break;
        case "localidadFacturacion":
          campo = document.getElementById("domicilioGeoFacturacion");
          campo.focus();
          break;
        case "domicilioGeoFacturacion":
          campo = document.getElementById("cpFacturacion");
          campo.focus();
          break;
        case "cpFacturacion":
          campo = document.getElementById("empresa");
          campo.focus();
          break;
        case "empresa":
          campo = document.getElementById("grupo");
          campo.focus();
          break;
        case "grupo":
          campo = document.getElementById("rubro");
          campo.focus();
          break;
        case "rubro":
          campo = document.getElementById("plan");
          campo.focus();
          break;
        case "plan":
          campo = document.getElementById("asesor");
          campo.focus();
          break;
        case "asesor":
          campo = document.getElementById("cobrador");
          campo.focus();
          break;
        case "cobrador":
          campo = document.getElementById("frecuencia");
          campo.focus();
          break;
        case "frecuencia":
          campo = document.getElementById("matricula");
          campo.focus();
          break;
        case "matricula":
          campo = document.getElementById("cuota");
          campo.focus();
          break;
        case "telefono1":
          campo = document.getElementById("celular");
          campo.focus();
          break;
        case "celular":
          campo = document.getElementById("email");
          campo.focus();
          break;
        case "email":
          campo = document.getElementById("tipoDocumento");
          campo.focus();
          break;
      }
    }

    if (event.keyCode === 27) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "domicilio":
          campo = document.getElementById("nombre");
          campo.focus();
          break;
        case "telefono":
          campo = document.getElementById("nombre");
          campo.focus();
          break;
        case "provincia":
          campo = document.getElementById("telefono");
          campo.focus();
          break;
        case "domicilio":
          campo = document.getElementById("provincia");
          campo.focus();
          break;
        case "domicilioGeo":
          campo = document.getElementById("domicilio");
          campo.focus();
          break;
        case "entre":
          campo = document.getElementById("domicilioGeo");
          campo.focus();
          break;
        case "localidad":
          campo = document.getElementById("entre");
          campo.focus();
          break;
        case "edad":
          campo = document.getElementById("localidad");
          campo.focus();
          break;
        case "sexo":
          campo = document.getElementById("edad");
          campo.focus();
          break;
        case "antecedente":
          campo = document.getElementById("sexo");
          campo.focus();
          break;
        case "tratamiento":
          campo = document.getElementById("antecedente");
          campo.focus();
          break;
        case "motivoConsulta":
          campo = document.getElementById("tratamiento");
          campo.focus();
          break;
        case "operadorEmisor":
          campo = document.getElementById("motivoConsulta");
          campo.focus();
          break;
        case "indicativoEmisor":
          campo = document.getElementById("operadorEmisor");
          campo.focus();
          break;
        case "obraSocial":
          campo = document.getElementById("indicativoEmisor");
          campo.focus();
          break;
        case "nroObraSocial":
          campo = document.getElementById("obraSocial");
          campo.focus();
          break;
        case "covid":
          campo = document.getElementById("coseguro");
          campo.focus();
          break;
        case "coseguro":
          campo = document.getElementById("nroObraSocial");
          campo.focus();
          break;
        case "fueraZona":
          campo = document.getElementById("covid");
          campo.focus();
          break;
        case "observaciones":
          campo = document.getElementById("fueraZona");
          campo.focus();
          break;
      }
    }
    */
  }

  // INICIO

  if (
    bandFirst &&
    titular &&
    provincias &&
    provincias.length > 0 &&
    localidades &&
    localidades.length > 0 &&
    grupos &&
    grupos.length > 0 &&
    rubros &&
    rubros.length > 0 &&
    empresas &&
    empresas.length > 0 &&
    asesores &&
    asesores.length > 0 &&
    cobradores &&
    cobradores.length > 0 &&
    tiposDocumentos &&
    tiposDocumentos.length > 0 &&
    frecuencias &&
    frecuencias.length > 0 &&
    tiposContribuyentes &&
    tiposContribuyentes.length > 0 &&
    formasPagos &&
    formasPagos.length > 0 &&
    adicionales &&
    afiliado
  ) {
    let provinciaId = provincias.findIndex(
      (item) => item.id === afiliado.id_provincia
    );
    if (provinciaId !== -1) {
      guardarProvincia(provincias[provinciaId]);
    } else {
      guardarProvincia(null);
    }

    if (
      afiliado.id_provincia_facturacion !== null &&
      afiliado.id_provincia_facturacion !== 0
    ) {
      let provinciaFacturacionId = provincias.findIndex(
        (item) => item.id === titular.id_provincia_facturacion
      );
      if (provinciaFacturacionId !== -1) {
        guardarProvinciaFacturacion(provincias[provinciaFacturacionId]);
      } else {
        guardarProvinciaFacturacion(null);
      }
    } else {
      let provinciaFacturacionId = provincias.findIndex(
        (item) => item.id === afiliado.id_provincia
      );
      if (provinciaFacturacionId !== -1) {
        guardarProvinciaFacturacion(provincias[provinciaFacturacionId]);
      } else {
        guardarProvinciaFacturacion(null);
      }
    }

    let localidadId = localidades.findIndex(
      (item) => item.id === afiliado.id_localidad
    );
    if (localidadId !== -1) {
      guardarLocalidad(localidades[localidadId]);
    } else {
      guardarLocalidad(null);
    }

    if (
      afiliado.id_localidad_facturacion !== null &&
      afiliado.id_localidad_facturacion !== 0
    ) {
      let localidadFacturacionId = localidades.findIndex(
        (item) => item.id === titular.id_localidad_facturacion
      );
      if (localidadFacturacionId !== -1) {
        guardarLocalidadFacturacion(localidades[localidadFacturacionId]);
      } else {
        guardarLocalidadFacturacion(null);
      }
    } else {
      let localidadFacturacionId = localidades.findIndex(
        (item) => item.id === afiliado.id_localidad
      );
      if (localidadFacturacionId !== -1) {
        guardarLocalidadFacturacion(localidades[localidadFacturacionId]);
      } else {
        guardarLocalidadFacturacion(null);
      }
    }

    let tipoDocumentoId = tiposDocumentos.findIndex(
      (item) => item.id === afiliado.id_tipodocumento
    );
    if (tipoDocumentoId !== -1) {
      guardarTipoDocumento(tiposDocumentos[tipoDocumentoId]);
    } else {
      guardarTipoDocumento(null);
    }

    if (titular.id !== "99999999000000000000") {
      let grupoId = grupos.findIndex((item) => item.id === titular.id_grupo);
      if (grupoId !== -1) {
        guardarGrupo(grupos[grupoId]);
      } else {
        guardarGrupo(null);
      }

      let planId = planes.findIndex(
        (item) => item.id_codigo === titular.id_plan_facturacion
      );
      if (planId !== -1) {
        guardarPlan(planes[planId]);
      } else {
        guardarPlan(null);
      }

      let planIdExtraordinaria = planes.findIndex(
        (item) => item.id_codigo === titular.id_plan_extra
      );
      if (planIdExtraordinaria !== -1) {
        guardarPlanExtraordinaria(planes[planIdExtraordinaria]);
      } else {
        guardarPlanExtraordinaria(null);
      }

      obtenerPlanes({ grupoId: titular.id_grupo });

      let rubroId = rubros.findIndex((item) => item.id === titular.id_rubro);
      if (rubroId !== -1) {
        guardarRubro(rubros[rubroId]);
      } else {
        guardarRubro(null);
      }

      let empresaId = empresas.findIndex(
        (item) => item.id === titular.id_empresa_facturacion
      );
      if (empresaId !== -1) {
        guardarEmpresa(empresas[empresaId]);
      } else {
        guardarEmpresa(null);
      }

      let asesorId = asesores.findIndex(
        (item) => item.id === titular.id_asesor_facturacion
      );
      if (asesorId !== -1) {
        guardarAsesor(asesores[asesorId]);
      } else {
        guardarAsesor(null);
      }

      let cobradorId = cobradores.findIndex(
        (item) => item.id === titular.id_cobrador1_facturacion
      );
      if (cobradorId !== -1) {
        guardarCobrador(cobradores[cobradorId]);
      } else {
        guardarCobrador(null);
      }

      let frecuenciaId = frecuencias.findIndex(
        (item) => item.id === titular.id_frecuencia
      );
      if (frecuenciaId !== -1) {
        guardarFrecuencia(frecuencias[frecuenciaId]);
      } else {
        guardarFrecuencia(null);
      }

      let tipoContribuyenteId = tiposContribuyentes.findIndex(
        (item) => item.id === titular.id_tipocontribuyente
      );
      if (tipoContribuyenteId !== -1) {
        guardarTipoContribuyente(tiposContribuyentes[tipoContribuyenteId]);
      } else {
        guardarTipoContribuyente(null);
      }

      let formaPagoId = formasPagos.findIndex(
        (item) => item.id === titular.id_formapago
      );

      if (formaPagoId !== -1) {
        guardarFormaPago(formasPagos[formaPagoId]);
      } else {
        guardarFormaPago(null);
      }
    } else {
      guardarGrupo(null);
      guardarPlan(null);
      guardarRubro(null);
      guardarEmpresa(null);
      guardarAsesor(null);
      guardarCobrador(null);
      guardarFrecuencia(null);
      guardarTipoContribuyente(null);
      guardarFormaPago(null);
    }
    //////

    if (
      afiliado.domicilioGeo !== undefined &&
      afiliado.domicilioGeo !== null &&
      afiliado.domicilioGeo !== ""
    ) {
      let local = [];
      local.push({
        id: 1,
        domicilioGeo: afiliado.domicilioGeo,
      });

      guardarDirecciones(local);
      guardarDomicilioGeo(local[0]);
    } else {
      guardarDirecciones([]);
      guardarDomicilioGeo(null);
    }

    if (
      afiliado.domicilioGeoFacturacion !== undefined &&
      afiliado.domicilioGeoFacturacion !== null &&
      afiliado.domicilioGeoFacturacion !== ""
    ) {
      let local = [];
      local.push({
        id: 1,
        domicilioGeoFacturacion: titular.domicilioGeoFacturacion,
      });

      guardarDirecciones(local);
      guardarDomicilioGeoFacturacion(local[0]);
    } else {
      guardarDirecciones([]);
      guardarDomicilioGeoFacturacion(null);
    }


    let localAdicional = []
    if (
      titular &&
      adicionales &&
      adicionales.length > 0 &&
      modulo &&
      modulo.length > 0
    ) {
      for (var i = 0; i < modulo.length; i++) {
        let index = adicionales.findIndex(
          (item) => item.id === modulo[i].id_adicional
        );
        if (index !== -1) {
          localAdicional.push({
            ...adicionales[index],
          });
        }
      }
      guardarAdicional(localAdicional);
    }


    if (afiliado.titular) {
      guardarAfiliado({
        ...afiliado,
        ingreso:
          afiliado.ingreso && afiliado.ingreso.length === 24
            ? formatDate(afiliado.ingreso)
            : afiliado.ingreso && afiliado.ingreso.length === 10
            ? afiliado.ingreso
            : null,
        nacimiento:
          afiliado.nacimiento && afiliado.nacimiento.length === 24
            ? formatDate(afiliado.nacimiento)
            : afiliado.nacimiento && afiliado.nacimiento.length === 10
            ? afiliado.nacimiento
            : null,
        nombre_razonsocial_facturacion:
          afiliado.nombre_razonsocial_facturacion &&
          afiliado.nombre_razonsocial_facturacion !== ""
            ? titular.nombre_razonsocial_facturacion
            : titular.nombre_razonsocial,
        domicilio_facturacion:
          afiliado.domicilio_facturacion !== null &&
          afiliado.domicilio_facturacion !== ""
            ? titular.domicilio_facturacion
            : titular.domicilio,
        id_localidad_facturacion:
          afiliado.id_localidad_facturacion &&
          afiliado.id_localidad_facturacion !== 0
            ? titular.id_localidad_facturacion
            : titular.id_localidad,
        id_provincia_facturacion:
          afiliado.id_provincia_facturacion &&
          afiliado.id_provincia_facturacion !== 0
            ? titular.id_provincia_facturacion
            : titular.id_provincia,
        cp_facturacion:
          afiliado.cp_facturacion && afiliado.cp_facturacion !== ""
            ? titular.id_cp_facturacion
            : titular.cp,
        id_titular: titular.id_titular,
        suspendido: false,
        fsuspendidodesde: null,
        fsuspendidohasta: null,
        id_motivosuspendido: null,
        habilitado: 1,
        fhabilitado: null,
        id_motivobaja: null,
        matricula: 0,
        adicional: localAdicional,
      });
    } else {
      guardarAfiliado({
        ...afiliado,
        ingreso:
          afiliado.ingreso && afiliado.ingreso.length === 24
            ? formatDate(afiliado.ingreso)
            : afiliado.ingreso && afiliado.ingreso.length === 10
            ? afiliado.ingreso
            : null,
        nacimiento:
          afiliado.nacimiento && afiliado.nacimiento.length === 24
            ? formatDate(afiliado.nacimiento)
            : afiliado.nacimiento && afiliado.nacimiento.length === 10
            ? afiliado.nacimiento
            : null,
        nombre_razonsocial_facturacion: titular.nombre_razonsocial_facturacion,
        domicilio_facturacion: titular.domicilio_facturacion,
        id_localidad_facturacion: titular.id_localidad_facturacion,
        id_provincia_facturacion: titular.id_provincia_facturacion,
        cp_facturacion: titular.id_cp_facturacion,
        id_tipocontribuyente: titular.id_tipocontribuyente,
        id_empresa_facturacion: titular.id_empresa_facturacion,
        id_asesor_facturacion: titular.id_asesor_facturacion,
        id_cobrador1_facturacion: titular.id_cobrador1_facturacion,
        id_plan_facturacion: titular.id_plan_facturacion,
        id_grupo: titular.id_grupo,
        id_rubro: titular.id_rubro,
        id_titular: titular.id_titular,
        cuota: titular.cuota,
        matricula: titular.matricula,
        facturacionmanual: titular.facturacionmanual,
        cuotaextra: titular.cuotaextra,
        cuotaextradesde: titular.cuotaextradesde,
        cuotaextrahasta: titular.cuotaextrahasta,
        numerotarjeta: titular.numerotarjeta,
        cbu: titular.cbu,
        suspendido: titular.suspendido,
        fsuspendidodesde: titular.fsuspendidodesde,
        fsuspendidohasta: titular.fsuspendidohasta,
        id_motivosuspendido: titular.id_motivosuspendido,
        habilitado:
          titular.habilitado === 0 ? titular.habilitado : afiliado.habilitado,
        fhabilitado: titular.fhabilitado ? titular.fhabilitado : null,
        id_motivobaja: titular.id_motivobaja ? titular.id_motivobaja : null,
        adicional: localAdicional,
      });
    }

    setBandFirst(false);
  }

  if (clienteRepetido) {
    mensajeAlerta(
      "Afiliado",
      "El Nº de afiliado " + afiliado.id + " ya existe !.",
      "error"
    );
    guardarAfiliado({
      ...afiliado,
      id: "99999999",
    });
    setClienteRepetido(false);

    let campo = document.getElementById("id");
    if (campo) {
      campo.focus();
    }
  }

  if (documentoRepetido) {
    mensajeAlerta(
      "Afiliado",
      "El Nº de D.N.I./C.U.I.T " + afiliado.numerodocumento + " ya existe !.",
      "warn"
    );
    setDocumentoRepetido(false);
  }

  // FIN INICIO

  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[0], thisDate[1], thisDate[2]].join("-");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  function formatDate(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[0], thisDate[1], thisDate[2]].join("-");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate;
  }

  const itemTemplate = (item) => {
    return (
      <div className="product-item">
        <div className="product-list-detail">
          <h5 className="p-mb-2 p-width">{item.descripcion}</h5>
          <h6 className="p-mb-2 p-width">
            {formatDateTime(item.fecha)}
            {"  "}
            {item.usuarios.usuario}
          </h6>
        </div>
      </div>
    );
  };

  const onHideMessage = (e) => {
    setMessageVisible(false);
  };

  const onHideHistorial = (e) => {
    setHistorialVisible(false);
  };

  const onHideCuit = (e) => {
    setOpenCuit(false);
  };

  const renderHeaderHistorial = () => {
    return (
      <div>
        <Tag value="Agregar nota al historial del afiliado"></Tag>
      </div>
    );
  };

  const renderFooterHistorial = () => {
    return (
      <div>
        <Button
          label="Confirmar"
          icon="pi pi-check"
          onClick={() => {
            agregarHistorial({
              historial,
              titularId: afiliado.id,
              usuario: state.usuario.id,
            });
            setHistorialVisible(false);
            setTimeout(() => {
              obtenerHistorial({ titularId: afiliado.id_titular });
            }, 2000);
          }}
          className="p-button-text"
          autoFocus
        />
        <Button
          label="Volver"
          icon="pi pi-times"
          onClick={() => onHideHistorial()}
          className="p-button-text"
        />
      </div>
    );
  };

  const renderHeaderMessage = () => {
    return (
      <div>
        <Tag value="Enviar WhatsApp"></Tag>
      </div>
    );
  };

  const renderFooterMessage = () => {
    return (
      <div>
        <Button
          label="Confirmar"
          icon="pi pi-check"
          onClick={() => {
            if (!message || message.length === 0) {
              mensajeAlerta("WhatsApp", "Debe ingresar un mensage !.", "error");
              return;
            }
            enviarWhatsAppAfiliado({
              message,
              celular: afiliado.celular,
              empresaId: empresaElegida.id,
            });
            setMessageVisible(false);
          }}
          className="p-button-text"
          autoFocus
        />
        <Button
          label="Volver"
          icon="pi pi-times"
          onClick={() => onHideMessage()}
          className="p-button-text"
        />
      </div>
    );
  };

  const renderHeaderInhabilitar = () => {
    return (
      <div>
        <Tag
          value={
            selectedRowClientes && selectedRowClientes.titular
              ? "Inhabilitar Grupo"
              : "Inhabilitar Adherente"
          }
        ></Tag>
      </div>
    );
  };

  const renderFooterInhabilitar = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarInhabilitarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideInhabilitar}
          color="error"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const aceptarInhabilitarClick = (e) => {
    e.preventDefault();
    //Validar

    if (!motivoBaja) {
      mensajeAlerta(
        "Inhabilitar",
        "El motivo de baja es necesario !.",
        "error"
      );
      return;
    }

    inhabilitarAfiliado({
      id: selectedRowClientes.id,
      titularId: selectedRowClientes.id_titular,
      motivoBaja,
      valueDate,
      usuario: state.usuario.id,
      titular: selectedRowClientes.titular,
    });

    setSelectedRowClientes({
      ...selectedRowClientes,
      habilitado: 0,
      id_motivobaja: motivoBaja.id,
      fhabilitado: valueDate,
      id_usuarioinhabilitado: state.usuario.id,
    });

    setTimeout(() => {
      buscarTitular({ titularId: selectedRowClientes.id_titular });
      buscarIntegrantes({ titularId: selectedRowClientes.id_titular });
      obtenerHistorial({ titularId: selectedRowClientes.id_titular });
    }, 1000);

    setInhabilitarVisible(false);
  };

  const onHideInhabilitar = (e) => {
    setInhabilitarVisible(false);
  };

  const renderHeaderHabilitar = () => {
    return (
      <div>
        <Tag
          value={
            selectedRowClientes && selectedRowClientes.titular
              ? "Habilitar Grupo"
              : "Habilitar Adherente"
          }
        ></Tag>
      </div>
    );
  };

  const renderFooterHabilitar = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarHabilitarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideHabilitar}
          color="error"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const addMessage = () => {
    if (!afiliado || !afiliado.celular || afiliado.celular.length !== 10) {
      mensajeAlerta("Afiliados", "El celular  es necesario !.", "error");
      return;
    }

    setMessageVisible(true);
  };

  const aceptarHabilitarClick = (e) => {
    e.preventDefault();
    //Validar

    habilitarAfiliado({
      id: selectedRowClientes.id,
      titularId: selectedRowClientes.id_titular,
      valueDate,
      usuario: state.usuario.id,
      titular: selectedRowClientes.titular,
    });
    setSelectedRowClientes({
      ...selectedRowClientes,
      habilitado: 1,
      id_motivobaja: null,
      id_usuarioinhabilitado: state.usuario.id,
    });
    setTimeout(() => {
      buscarTitular({ titularId: selectedRowClientes.id_titular });
      buscarIntegrantes({ titularId: selectedRowClientes.id_titular });
      obtenerHistorial({ titularId: selectedRowClientes.id_titular });
    }, 1000);

    setHabilitarVisible(false);
  };

  const onHideHabilitar = (e) => {
    setHabilitarVisible(false);
  };

  const renderHeaderSuspender = () => {
    return (
      <div>
        <Tag value={"Suspender Grupo"}></Tag>
      </div>
    );
  };

  const renderFooterSuspender = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarSuspenderClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideSuspender}
          color="error"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const aceptarSuspenderClick = (e) => {
    e.preventDefault();
    //Validar

    if (valueDateSuspenderDesde === null) {
      mensajeAlerta("Suspender", "Fecha Desde es necesaria !.", "error");
      return;
    }

    if (valueDateSuspenderHasta === null) {
      mensajeAlerta("Suspender", "Fecha Hasta es necesaria !.", "error");
      return;
    }

    if (valueDateSuspenderDesde > valueDateSuspenderHasta) {
      mensajeAlerta(
        "Suspender",
        "Fecha Desde no puede ser mayor que Fecha Hasta !.",
        "error"
      );
      return;
    }

    if (!motivoSuspendido) {
      mensajeAlerta(
        "Inhabilitar",
        "El motivo de suspensión es necesario !.",
        "error"
      );
      return;
    }

    suspenderAfiliado({
      id: selectedRowClientes.id,
      titularId: selectedRowClientes.id_titular,
      valueDateSuspenderDesde,
      valueDateSuspenderHasta,
      usuario: state.usuario.id,
      motivoSuspendido,
    });

    setSelectedRowClientes({
      ...selectedRowClientes,
      suspendido: 1,
      fsuspendidodesde: valueDateSuspenderDesde,
      fsuspendidohasta: valueDateSuspenderHasta,
      id_usuariosuspendido: state.usuario.id,
      id_motivosuspendido: motivoSuspendido.id,
    });
    setTimeout(() => {
      buscarTitular({ titularId: selectedRowClientes.id_titular });
      buscarIntegrantes({ titularId: selectedRowClientes.id_titular });
      obtenerHistorial({ titularId: selectedRowClientes.id_titular });
    }, 1000);

    setSuspenderVisible(false);
  };

  const onHideSuspender = (e) => {
    setSuspenderVisible(false);
  };

  const renderHeaderCancelarSuspender = () => {
    return (
      <div>
        <Tag value={"Cancelar Suspensión Grupo"}></Tag>
      </div>
    );
  };

  const renderFooterCancelarSuspender = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarCancelarSuspenderClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideCancelarSuspender}
          color="error"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const aceptarCancelarSuspenderClick = (e) => {
    e.preventDefault();
    //Validar

    cancelarSuspenderAfiliado({
      id: selectedRowClientes.id,
      titularId: selectedRowClientes.id_titular,
      usuario: state.usuario.id,
    });
    setSelectedRowClientes({
      ...selectedRowClientes,
      suspendido: 0,
      fsuspendidodesde: null,
      fsuspendidohasta: null,
      id_usuariosuspendido: state.usuario.id,
      id_motivosuspendido: null,
    });
    setTimeout(() => {
      buscarTitular({ titularId: selectedRowClientes.id_titular });
      buscarIntegrantes({ titularId: selectedRowClientes.id_titular });
      obtenerHistorial({ titularId: selectedRowClientes.id_titular });
    }, 1000);

    setCancelarSuspenderVisible(false);
  };

  const onHideCancelarSuspender = (e) => {
    setCancelarSuspenderVisible(false);
  };

  //console.log(afiliado);
  //console.log(adicionales)
  //console.log(afiliado ? formatDate(afiliado.ingreso) : null);

  return (
    <Fragment>
      <Toast ref={toast} />
      {!bandFirst && afiliado ? (
        <Fragment>
          <Grid
            container
            spacing={3}
            style={{ marginTop: "0vw", marginLeft: "0vw" }}
          >
            <Grid item xs={12} md={12}>
              <Typography
                variant="h6"
                style={{ textDecoration: "underline", color: "coral" }}
              >
                Datos Personales
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <label htmlFor="id">Nº de Socio</label>
              {titular.id !== "99999999000000000000" ? (
                <InputMask
                  id="id"
                  mask="99999999-999999-999999"
                  required
                  value={afiliado ? afiliado.id : null}
                  size={30}
                  placeholder="99999999-999999-999999"
                  style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                  disabled
                ></InputMask>
              ) : (
                <InputMask
                  id="id"
                  mask="99999999"
                  required
                  value={afiliado ? afiliado.id : null}
                  onBlur={(e) => handleInputBlurId(e)}
                  size={30}
                  //placeholder="99999999"
                  style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                  onChange={(e) => {
                    if (e.value) {
                      guardarAfiliado({
                        ...afiliado,
                        id: e.target.value,
                      });
                    }
                  }}
                ></InputMask>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                value={afiliado ? afiliado.nombre_razonsocial : null}
                id="nombre_razonsocial"
                label="Apellido y Nombre / Razón Social / Nombre Fantasía"
                fullWidth
                required
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    nombre_razonsocial: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                value={afiliado ? afiliado.domicilio : null}
                id="domicilio"
                label="Domicilio"
                fullWidth
                required
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    domicilio: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                value={afiliado ? afiliado.entre1 : null}
                id="entre1"
                label="Entre"
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    entre1: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                value={afiliado ? afiliado.entre2 : null}
                id="entre2"
                label="Entre"
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    entre2: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Autocomplete
                id="provincia"
                //disabled
                fullWidth
                options={provincias ? provincias : null}
                disablePortal
                //defaultValue={colores[idColorDefault]}

                value={provincia}
                getOptionLabel={(option) => option.nombre}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Provincia *"
                    size="small"
                  />
                )}
                onKeyDown={handleEnter}
                onChange={(event, value) => {
                  if (value) {
                    guardarProvincia(value);
                    guardarAfiliado({
                      ...afiliado,
                      id_provincia: value.id,
                      provincia: value.nombre,
                    });
                  } else {
                    guardarProvincia(null);
                    guardarAfiliado({
                      ...afiliado,
                      id_provincia: null,
                      provincia: null,
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Autocomplete
                id="localidad"
                //disabled
                fullWidth
                options={localidades ? localidades : null}
                disablePortal
                required
                //defaultValue={colores[idColorDefault]}

                value={localidad}
                getOptionLabel={(option) => option.nombre}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Localidad *"
                    size="small"
                  />
                )}
                onKeyDown={handleEnter}
                onBlur={(e) => handleInputBlurLocalidad(e)}
                onChange={(event, value) => {
                  if (value) {
                    guardarLocalidad(value);
                    guardarAfiliado({
                      ...afiliado,
                      id_localidad: value.id,
                      localidad: value.nombre,
                    });
                  } else {
                    guardarLocalidad(null);
                    guardarAfiliado({
                      ...afiliado,
                      id_localidad: null,
                      localidad: null,
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Autocomplete
                id="domicilioGeo"
                fullWidth
                noOptionsText={"No hay opciones"}
                value={domicilioGeo}
                options={direcciones}
                getOptionLabel={(option) => option.domicilioGeo}
                openOnFocus={true}
                disablePortal
                renderInput={(params) => (
                  <TextField {...params} label="Dirección Geolocalizada" />
                )}
                onKeyDown={handleEnter}
                onChange={(event, value) => {
                  if (value) {
                    guardarDomicilioGeo(value);
                    guardarAfiliado({
                      ...afiliado,
                      domicilioGeo: value.label,
                      lat: value.lat,
                      lon: value.lon,
                      cp: value.codigoPostal,
                    });
                  } else {
                    guardarDomicilioGeo(null);
                    guardarAfiliado({
                      ...afiliado,
                      domicilioGeo: null,
                      lat: null,
                      lon: null,
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={4} md={1}>
              <Typography variant="caption text">Mapa</Typography>

              <IconButton
                type="submit"
                aria-label="search"
                onClick={(e) => handleClickOpenDomicilio()}
              >
                <SearchIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12} md={1}>
              <TextField
                value={afiliado ? afiliado.cp : null}
                id="cp"
                label="Cod. Postal"
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    cp: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} md={1.5}>
              <Autocomplete
                id="tipoDocumento"
                //disabled
                fullWidth
                options={tiposDocumentos ? tiposDocumentos : null}
                disablePortal
                value={tipoDocumento}
                getOptionLabel={(option) => option.nombre}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Tipo Documento *"
                    size="small"
                  />
                )}
                onKeyDown={handleEnter}
                onChange={(event, value) => {
                  if (value) {
                    guardarTipoDocumento(value);
                    guardarAfiliado({
                      ...afiliado,
                      id_tipodocumento: value.id,
                      tipodocumento: value.nombre,
                    });
                  } else {
                    guardarTipoDocumento(null);
                    guardarAfiliado({
                      ...afiliado,
                      id_tipodocumento: null,
                      tipodocumento: null,
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} md={2.5}>
              {afiliado.id_tipodocumento === 4 /*DNI*/ ? (
                <TextField
                  value={afiliado ? afiliado.numerodocumento : null}
                  id="numeroDocumento"
                  label="Nº D.N.I."
                  fullWidth
                  required
                  variant="standard"
                  onBlur={(e) => handleInputBlurNumeroDocumento(e)}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onKeyDown={handleEnter}
                  onChange={(e) =>
                    guardarAfiliado({
                      ...afiliado,
                      numerodocumento: e.target.value,
                    })
                  }
                />
              ) : (
                <Fragment>
                  <label htmlFor="id">Nº C.U.I.T *</label>
                  <InputMask
                    id="numeroDocumento"
                    mask="99-99999999-9"
                    value={afiliado ? afiliado.numerodocumento : null}
                    placeholder="99-99999999-9"
                    onBlur={(e) => handleInputBlurNumeroDocumento(e)}
                    size={15}
                    onChange={(e) =>
                      guardarAfiliado({
                        ...afiliado,
                        numerodocumento: e.value,
                      })
                    }
                    style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                  ></InputMask>
                  {/*
                                <IconButton
                type="submit"
                aria-label="search"
                onClick={(e) => handleClickOpenCuit()}
              >
                <SearchIcon />
              </IconButton>
                  */}
                </Fragment>
              )}
            </Grid>

            <Grid item xs={12} md={1.5}>
              <TextField
                value={afiliado ? afiliado.nacimiento : null}
                id="fechaNacimiento"
                label="Fecha de Nacimiento"
                type="date"
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    nacimiento: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} md={1.5}>
              <TextField
                value={afiliado ? afiliado.ingreso : null}
                id="fechaIngreso"
                label="Fecha de Ingreso"
                type="date"
                required
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    ingreso: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <label htmlFor="id">Vigencia de Ingreso *</label>
              <InputMask
                id="vigencia"
                mask="99/99"
                value={afiliado ? afiliado.vigencia : null}
                placeholder="99/99"
                onBlur={handleEnter}
                size={4}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    vigencia: e.value,
                  })
                }
                style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
              ></InputMask>
            </Grid>

            <Grid item xs={12} md={2} style={{ marginTop: "1vw" }}>
              <Typography
                variant="h6"
                style={{ textDecoration: "underline", color: "coral" }}
              >
                Datos de Facturación
              </Typography>
            </Grid>

            <Grid item xs={12} md={10} style={{ marginTop: "1vw" }}>
              <Button
                icon="pi pi-plus-circle"
                className="p-button-warning p-button-rounded"
                onClick={() => {
                  guardarAfiliado({
                    ...afiliado,
                    nombre_razonsocial_facturacion: afiliado.nombre_razonsocial,
                    domicilio_facturacion: afiliado.domicilio,
                    entre1_facturacion: afiliado.entre1,
                    entre2_facturacion: afiliado.entre2,
                    id_localidad_facturacion: afiliado.id_localidad,
                    id_provincia_facturacion: afiliado.id_provincia,
                    cp_facturacion: afiliado.cp,
                  });
                  guardarLocalidadFacturacion(localidad);
                  guardarProvinciaFacturacion(provincia);
                }}
                tooltip="Copiar datos anteriores"
                disabled={!afiliado.titular || statusAcceso3 !== 200}
                tooltipOptions={{
                  position: "bottom",
                  mouseTrack: true,
                  mouseTrackTop: 15,
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                value={afiliado.nombre_razonsocial_facturacion}
                id="nombreFacturacion"
                label="Apellido y Nombre / Razón Social "
                fullWidth
                required
                disabled={afiliado.titular ? false : true}
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    nombre_razonsocial_facturacion: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                value={afiliado.domicilio_facturacion}
                id="domicilioFacturacion"
                label="Domicilio"
                fullWidth
                required
                disabled={afiliado.titular ? false : true}
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    domicilio_facturacion: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                value={afiliado.entre1_facturacion}
                id="entre1Facturacion"
                label="Entre"
                fullWidth
                disabled={afiliado.titular ? false : true}
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    entre1_facturacion: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                value={afiliado.entre2_facturacion}
                id="entre2Facturacion"
                label="Entre"
                fullWidth
                disabled={afiliado.titular ? false : true}
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    entre2_facturacion: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Autocomplete
                id="provinciaFacturacion"
                //disabled
                fullWidth
                options={provincias ? provincias : null}
                disablePortal
                disabled={afiliado.titular ? false : true}
                //defaultValue={colores[idColorDefault]}

                value={provinciaFacturacion}
                getOptionLabel={(option) => option.nombre}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Provincia *"
                    size="small"
                  />
                )}
                onKeyDown={handleEnter}
                onChange={(event, value) => {
                  if (value) {
                    guardarProvinciaFacturacion(value);
                    guardarAfiliado({
                      ...afiliado,
                      id_provincia_facturacion: value.id,
                      provincia_facturacion: value.nombre,
                    });
                  } else {
                    guardarProvinciaFacturacion(null);
                    guardarAfiliado({
                      ...afiliado,
                      id_provincia_facturacion: null,
                      provincia_facturacion: null,
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Autocomplete
                id="localidadFacturacion"
                //disabled
                fullWidth
                options={localidades ? localidades : null}
                disablePortal
                disabled={afiliado.titular ? false : true}
                required
                //defaultValue={colores[idColorDefault]}

                value={localidadFacturacion}
                getOptionLabel={(option) => option.nombre}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Localidad *"
                    size="small"
                  />
                )}
                onKeyDown={handleEnter}
                onBlur={(e) => handleInputBlurLocalidadFacturacion(e)}
                onChange={(event, value) => {
                  if (value) {
                    guardarLocalidadFacturacion(value);
                    guardarAfiliado({
                      ...afiliado,
                      id_localidad_facturacion: value.id,
                      localidad_facturacion: value.nombre,
                    });
                  } else {
                    guardarLocalidadFacturacion(null);
                    guardarAfiliado({
                      ...afiliado,
                      id_localidad_facturacion: null,
                      localidad_facturacion: null,
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Autocomplete
                id="domicilioGeoFacturacion"
                fullWidth
                noOptionsText={"No hay opciones"}
                value={domicilioGeoFacturacion}
                options={direcciones}
                getOptionLabel={(option) => option.domicilioGeo}
                openOnFocus={true}
                disablePortal
                disabled={afiliado.titular ? false : true}
                renderInput={(params) => (
                  <TextField {...params} label="Dirección Geolocalizada" />
                )}
                onKeyDown={handleEnter}
                onChange={(event, value) => {
                  if (value) {
                    guardarDomicilioGeoFacturacion(value);
                    guardarAfiliado({
                      ...afiliado,
                      domicilioGeo_facturacion: value.label,
                      lat_facturacion: value.lat,
                      lon_facturacion: value.lon,
                      cp_facturacion: value.codigoPostal,
                    });
                  } else {
                    guardarDomicilioGeoFacturacion(null);
                    guardarAfiliado({
                      ...afiliado,
                      domicilioGeo_facturacion: null,
                      lat_facturacion: null,
                      lon_facturacion: null,
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={4} md={1}>
              <Typography variant="caption text">Mapa</Typography>

              <IconButton
                type="submit"
                aria-label="search"
                onClick={(e) => handleClickOpenDomicilioFacturacion()}
              >
                <SearchIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12} md={1}>
              <TextField
                value={afiliado.cp_facturacion}
                id="cpFacturacion"
                label="Cod. Postal"
                fullWidth
                disabled={afiliado.titular ? false : true}
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    cp_facturacion: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                id="empresa"
                fullWidth
                noOptionsText={"No hay opciones"}
                options={
                  empresas ? empresas.filter((item) => item.habilitado) : null
                }
                defaultValue={empresa}
                getOptionLabel={(option) => option.nombre}
                openOnFocus={true}
                disablePortal
                disabled={afiliado.titular ? false : true}
                renderInput={(params) => (
                  <TextField {...params} label="Empresa *" variant="standard" />
                )}
                onKeyDown={handleEnter}
                onChange={(event, value) => {
                  if (value) {
                    guardarEmpresa(value);
                    guardarAfiliado({
                      ...afiliado,
                      id_empresa_facturacion: value.id,
                      empresa_facturacion: value.nombre,
                    });
                  } else {
                    guardarEmpresa(null);
                    guardarAfiliado({
                      ...afiliado,
                      id_empresa_facturacion: null,
                      empresa_facturacion: null,
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                id="grupo"
                fullWidth
                noOptionsText={"No hay opciones"}
                options={grupos ? grupos : null}
                value={grupo}
                getOptionLabel={(option) => option.nombre}
                openOnFocus={true}
                disablePortal
                disabled={afiliado.titular ? false : true}
                renderInput={(params) => (
                  <TextField {...params} label="Grupo *" variant="standard" />
                )}
                onKeyDown={handleEnter}
                onChange={(event, value) => {
                  if (value) {
                    guardarGrupo(value);
                    guardarAfiliado({
                      ...afiliado,
                      id_grupo: value.id,
                      grupo: value.nombre,
                    });
                    obtenerPlanes({ grupoId: value.id });
                    guardarPlan(null);
                  } else {
                    guardarGrupo(null);
                    guardarAfiliado({
                      ...afiliado,
                      id_grupo: null,
                      grupo: null,
                    });
                    guardarPlan(null);
                    guardarPlanes([]);
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                id="rubro"
                fullWidth
                noOptionsText={"No hay opciones"}
                defaultValue={rubro}
                options={
                  rubros ? rubros.filter((item) => item.habilitado) : null
                }
                getOptionLabel={(option) => option.nombre}
                openOnFocus={true}
                disablePortal
                disabled={afiliado.titular ? false : true}
                renderInput={(params) => (
                  <TextField {...params} label="Rubro *" variant="standard" />
                )}
                onKeyDown={handleEnter}
                onChange={(event, value) => {
                  if (value) {
                    guardarRubro(value);
                    guardarAfiliado({
                      ...afiliado,
                      id_rubro: value.id,
                      rubro: value.nombre,
                    });
                  } else {
                    guardarRubro(null);
                    guardarAfiliado({
                      ...afiliado,
                      id_rubro: null,
                      rubro: null,
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                id="plan"
                fullWidth
                noOptionsText={"No hay opciones"}
                defaultValue={plan}
                options={planes ? planes.filter((item) => item.activo) : null}
                getOptionLabel={(option) => option.nombre}
                openOnFocus={true}
                disablePortal
                disabled={afiliado.titular ? false : true}
                renderInput={(params) => (
                  <TextField {...params} label="Plan *" variant="standard" />
                )}
                onKeyDown={handleEnter}
                onChange={(event, value) => {
                  if (value) {
                    guardarPlan(value);
                    guardarAfiliado({
                      ...afiliado,
                      id_plan_facturacion: value.id_codigo,
                      plan_facturacion: value.nombre,
                    });
                  } else {
                    guardarPlan(null);
                    guardarAfiliado({
                      ...afiliado,
                      id_plan_facturacion: null,
                      plan_facturacion: null,
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                id="asesor"
                fullWidth
                noOptionsText={"No hay opciones"}
                defaultValue={asesor}
                options={
                  asesores ? asesores.filter((item) => item.habilitado) : null
                }
                getOptionLabel={(option) => option.nombre}
                openOnFocus={true}
                disablePortal
                disabled={afiliado.titular ? false : true}
                renderInput={(params) => (
                  <TextField {...params} label="Asesor *" variant="standard" />
                )}
                onKeyDown={handleEnter}
                onChange={(event, value) => {
                  if (value) {
                    guardarAsesor(value);
                    guardarAfiliado({
                      ...afiliado,
                      id_asesor_facturacion: value.id,
                      asesor_facturacion: value.nombre,
                    });
                  } else {
                    guardarAsesor(null);
                    guardarAfiliado({
                      ...afiliado,
                      id_asesor_facturacion: null,
                      asesor_facturacion: null,
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                id="cobrador"
                fullWidth
                noOptionsText={"No hay opciones"}
                defaultValue={cobrador}
                options={
                  cobradores
                    ? cobradores.filter((item) => item.habilitado)
                    : null
                }
                getOptionLabel={(option) => option.nombre}
                openOnFocus={true}
                disablePortal
                disabled={afiliado.titular ? false : true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cobrador *"
                    variant="standard"
                  />
                )}
                onKeyDown={handleEnter}
                onChange={(event, value) => {
                  if (value) {
                    guardarCobrador(value);
                    guardarAfiliado({
                      ...afiliado,
                      id_cobrador1_facturacion: value.id,
                      cobrador1_facturacion: value.nombre,
                    });
                  } else {
                    guardarCobrador(null);
                    guardarAfiliado({
                      ...afiliado,
                      id_cobrador1_facturacion: null,
                      cobrador1_facturacion: null,
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                id="tipoContribuyente"
                //disabled
                fullWidth
                options={tiposContribuyentes ? tiposContribuyentes : null}
                disablePortal
                value={tipoContribuyente}
                getOptionLabel={(option) => option.nombre}
                disabled={afiliado.titular ? false : true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Tipo Contribuyente *"
                    size="small"
                  />
                )}
                onKeyDown={handleEnter}
                onChange={(event, value) => {
                  if (value) {
                    guardarTipoContribuyente(value);
                    guardarAfiliado({
                      ...afiliado,
                      id_tipocontribuyente: value.id,
                      tipocontribuyente: value.nombre,
                    });
                  } else {
                    guardarTipoContribuyente(null);
                    guardarAfiliado({
                      ...afiliado,
                      id_tipocontribuyente: null,
                      tipocontribuyente: null,
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                id="frecuencia"
                //disabled
                fullWidth
                options={frecuencias ? frecuencias : null}
                disablePortal
                value={frecuencia}
                getOptionLabel={(option) => option.nombre}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Frecuencia *"
                    size="small"
                  />
                )}
                disabled={afiliado.titular ? false : true}
                onKeyDown={handleEnter}
                onChange={(event, value) => {
                  if (value) {
                    guardarFrecuencia(value);
                    guardarAfiliado({
                      ...afiliado,
                      id_frecuencia: value.id,
                      frecuencia: value.nombre,
                    });
                  } else {
                    guardarFrecuencia(null);
                    guardarAfiliado({
                      ...afiliado,
                      id_frecuencia: null,
                      frecuencia: null,
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                id="formaPago"
                //disabled
                fullWidth
                options={formasPagos ? formasPagos : null}
                disablePortal
                value={formaPago}
                getOptionLabel={(option) => option.nombre}
                disabled={afiliado.titular ? false : true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Forma de Pago *"
                    size="small"
                  />
                )}
                onKeyDown={handleEnter}
                onChange={(event, value) => {
                  if (value) {
                    guardarFormaPago(value);
                    guardarAfiliado({
                      ...afiliado,
                      id_formapago: value.id,
                      formapago: value.nombre,
                    });
                  } else {
                    guardarFormaPago(null);
                    guardarAfiliado({
                      ...afiliado,
                      id_formapago: null,
                      formapago: null,
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                value={afiliado ? afiliado.condicionpago : null}
                id="condicionPago"
                label="Condición de Pago"
                fullWidth
                variant="standard"
                size="small"
                disabled={afiliado.titular ? false : true}
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    condicionpago: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <label htmlFor="matricula">Valor Matrícula *</label>
              <InputNumber
                id="matricula"
                inputId="matricula"
                value={afiliado ? afiliado.matricula : null}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    matricula: e.value,
                  })
                }
                disabled={afiliado.titular ? false : true}
                mode="decimal"
                locale="en-US"
                minFractionDigits={2}
                style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <label htmlFor="cuota">Valor Cuota *</label>
              <InputNumber
                id="cuota"
                inputId="cuota"
                value={afiliado ? afiliado.cuota : null}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    cuota: e.value,
                  })
                }
                disabled={afiliado.titular ? false : true}
                mode="decimal"
                locale="en-US"
                minFractionDigits={2}
                style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControlLabel
                value="yes"
                control={
                  <Checkbox
                    color="primary"
                    checked={afiliado ? afiliado.facturacionmanual : null}
                    onKeyDown={handleEnter}
                    onChange={(e) =>
                      guardarAfiliado({
                        ...afiliado,
                        facturacionmanual: e.target.checked,
                      })
                    }
                  />
                }
                disabled={afiliado.titular ? false : true}
                label="Facturación Manual"
                labelPlacement="start"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <label htmlFor="cuotaextra">Valor Cuota Extraordinaria</label>
              <InputNumber
                inputId="cuotaextra"
                value={afiliado ? afiliado.cuotaextra : null}
                onKeyDown={handleEnter}
                size={8}
                onChange={(e) => {
                  if (Number(e.value) <= 0) {
                    acPlanExtra.current
                      .getElementsByClassName(
                        "MuiAutocomplete-clearIndicator"
                      )[0]
                      .click();
                    guardarAfiliado({
                      ...afiliado,
                      cuotaextra: e.value,
                      cuotaextradesde: null,
                      cuotaextrahasta: null,
                      id_plan_extra: null,
                      plan_extra: null,
                    });
                  } else {
                    guardarAfiliado({
                      ...afiliado,
                      cuotaextra: e.value,
                    });
                  }
                }}
                disabled={afiliado.titular ? false : true}
                mode="decimal"
                locale="en-US"
                minFractionDigits={2}
                style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <label htmlFor="cuotaExtraDesde">
                Vigencia Extraordinaria Desde
              </label>
              <InputMask
                id="cuotaExtraDesde"
                mask="99/99"
                value={afiliado ? afiliado.cuotaextradesde : null}
                placeholder="99/99"
                onBlur={handleEnter}
                size={4}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    cuotaextradesde: e.value,
                  })
                }
                disabled={
                  !afiliado.titular
                    ? true
                    : Number(afiliado.cuotaextra) <= 0
                    ? true
                    : false
                }
                style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
              ></InputMask>
            </Grid>

            <Grid item xs={12} md={4}>
              <label htmlFor="cuotaExtraHasta">
                Vigencia Extraordinaria Hasta
              </label>
              <InputMask
                id="cuotaExtraHasta"
                mask="99/99"
                value={afiliado ? afiliado.cuotaextrahasta : null}
                placeholder="99/99"
                onBlur={handleEnter}
                size={4}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    cuotaextrahasta: e.value,
                  })
                }
                disabled={
                  !afiliado.titular
                    ? true
                    : Number(afiliado.cuotaextra) <= 0
                    ? true
                    : false
                }
                style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
              ></InputMask>
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                id="planExtraordinaria"
                ref={acPlanExtra}
                fullWidth
                noOptionsText={"No hay opciones"}
                defaultValue={planExtraordinaria}
                options={planes ? planes.filter((item) => item.activo) : null}
                getOptionLabel={(option) => option.nombre}
                openOnFocus={true}
                disablePortal
                disabled={
                  !afiliado.titular
                    ? true
                    : Number(afiliado.cuotaextra) <= 0
                    ? true
                    : false
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Plan al finalizar vigencia extraordinaria"
                    variant="standard"
                  />
                )}
                onKeyDown={handleEnter}
                onChange={(event, value) => {
                  if (value) {
                    guardarPlanExtraordinaria(value);
                    guardarAfiliado({
                      ...afiliado,
                      id_plan_extra: value.id_codigo,
                      plan_extra: value.nombre,
                    });
                  } else {
                    guardarPlanExtraordinaria(null);
                    guardarAfiliado({
                      ...afiliado,
                      id_plan_extra: null,
                      plan_extra: null,
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <label htmlFor="numerotarjeta">Tarjeta de Crédito</label>
              <InputMask
                id="numerotarjeta"
                mask="9999-9999-9999-9999"
                value={afiliado ? afiliado.numerotarjeta : null}
                placeholder="9999-9999-9999-9999"
                onBlur={handleEnter}
                //size={2}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    numerotarjeta: e.value,
                  })
                }
                disabled={afiliado.titular ? false : true}
                style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
              ></InputMask>
            </Grid>

            <Grid item xs={12} md={4}>
              <label htmlFor="cbu">C.B.U.</label>
              <InputMask
                id="cbu"
                mask="9999999999999999999999"
                value={afiliado ? afiliado.cbu : null}
                placeholder="9999999999999999999999"
                onBlur={handleEnter}
                size={30}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    cbu: e.value,
                  })
                }
                disabled={afiliado.titular ? false : true}
                style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
              ></InputMask>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "1vw" }}>
              <Typography
                variant="h6"
                style={{ textDecoration: "underline", color: "coral" }}
              >
                Datos Personales Opcionales
              </Typography>
            </Grid>

            <Grid item xs={12} md={2}>
              <TextField
                value={afiliado ? afiliado.telefono1 : null}
                id="telefono1"
                label="Teléfono Particular"
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    telefono1: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <label htmlFor="celular">Celular WhatsApp</label>
              <InputMask
                id="celular"
                mask="9999999999"
                value={afiliado ? afiliado.celular : null}
                placeholder="9999999999"
                onBlur={handleEnter}
                size={15}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    celular: e.value,
                  })
                }
                disabled={afiliado.titular ? false : true}
                style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
              ></InputMask>
            </Grid>

            <Grid item xs={12} md={1}>
              <Button
                icon="pi pi-whatsapp"
                tooltip="Mensaje"
                className="p-button-rounded p-button-success"
                disabled={
                  !afiliado ||
                  !afiliado.celular ||
                  afiliado.celular.length !== 10 ||
                  statusAcceso3 !== 200
                }
                style={{ marginLeft: "0em" }}
                onClick={addMessage}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                value={afiliado ? afiliado.email : null}
                id="email"
                label="Email 1"
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    email: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                value={afiliado ? afiliado.email2 : null}
                id="email"
                label="Email 2"
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    email2: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                value={afiliado ? afiliado.email3 : null}
                id="email"
                label="Email 3"
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    email3: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                value={afiliado ? afiliado.email4 : null}
                id="email"
                label="Email 4"
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    email4: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                value={afiliado ? afiliado.email5 : null}
                id="email"
                label="Email 5"
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    email5: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "1vw" }}>
              <Typography
                variant="h6"
                style={{ textDecoration: "underline", color: "coral" }}
              >
                Observaciones Generales
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField
                id="observaciones"
                label="Observaciones"
                value={afiliado ? afiliado.observacionesgenerales : null}
                multiline
                fullWidth
                rows={4}
                disabled={afiliado.titular ? false : true}
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    observacionesgenerales: e.target.value,
                  })
                }
                //defaultValue="Observaciones"
              />
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "1vw" }}>
              <Typography
                variant="h6"
                style={{ textDecoration: "underline", color: "coral" }}
              >
                Servicios de Emergencias Médicas
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <FormControlLabel
                value="yes"
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      afiliado ? afiliado.bloquearServiciosEmergencias : null
                    }
                    onKeyDown={handleEnter}
                    disabled={statusAcceso2 !== 200 || !afiliado.titular}
                    onChange={(e) =>
                      guardarAfiliado({
                        ...afiliado,
                        bloquearServiciosEmergencias: e.target.checked,
                      })
                    }
                  />
                }
                label="Bloquear Servicios de Emergencias."
                labelPlacement="start"
              />
            </Grid>

            <Grid item xs={12} md={2} style={{ marginTop: "1vw" }}>
              <Typography
                variant="h6"
                style={{ textDecoration: "underline", color: "coral" }}
              >
                Historial
              </Typography>
            </Grid>
            <Grid item xs={12} md={2} style={{ marginTop: "1vw" }}>
              <Button
                icon="pi pi-plus-circle"
                className="p-button-success p-button-rounded"
                onClick={() => {
                  setHistorial(null);
                  setHistorialVisible(true);
                }}
                disabled={!afiliado.titular || statusAcceso3 !== 200}
                tooltip="Agregar Historial"
                tooltipOptions={{
                  position: "bottom",
                  mouseTrack: true,
                  mouseTrackTop: 15,
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <OrderList
                value={historiales}
                dragdrop
                listStyle={{ height: "auto" }}
                dataKey="id"
                itemTemplate={itemTemplate}
                //header={"Historials Id. " + servicio.id}
                style={{ marginRight: "0vw" }}
                //onChange={(e) => setProducts(e.value)}
              ></OrderList>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "1vw" }}>
              <Typography
                variant="h6"
                style={{ textDecoration: "underline", color: "coral" }}
              >
                Impuestos
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControlLabel
                value="yes"
                control={
                  <Checkbox
                    color="primary"
                    checked={afiliado ? afiliado.marcaarba : null}
                    onKeyDown={handleEnter}
                    disabled={!afiliado.titular}
                    onChange={(e) =>
                      guardarAfiliado({
                        ...afiliado,
                        marcaarba: e.target.checked,
                      })
                    }
                  />
                }
                label="Tributa Perc. A.R.B.A."
                labelPlacement="start"
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <label htmlFor="alicuotaArba">Alícuota A.R.B.A.</label>
              <InputNumber
                inputId="alicuotaArba"
                value={afiliado ? afiliado.alicuotaarba : null}
                disabled={
                  afiliado && afiliado.marcaarba && afiliado.titular
                    ? false
                    : true
                }
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    alicuotaarba: e.value,
                  })
                }
                size={8}
                mode="decimal"
                locale="en-US"
                minFractionDigits={2}
                style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControlLabel
                value="yes"
                control={
                  <Checkbox
                    color="primary"
                    checked={afiliado ? afiliado.marcaagip : null}
                    onKeyDown={handleEnter}
                    disabled={!afiliado.titular}
                    onChange={(e) =>
                      guardarAfiliado({
                        ...afiliado,
                        marcaagip: e.target.checked,
                      })
                    }
                  />
                }
                label="Tributa Perc. A.G.I.P."
                labelPlacement="start"
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <label htmlFor="alicuotaAgip">Alícuota A.G.I.P.</label>
              <InputNumber
                inputId="alicuotaAgip"
                value={afiliado ? afiliado.alicuotaagip : null}
                onKeyDown={handleEnter}
                disabled={
                  afiliado && afiliado.marcaagip && afiliado.titular
                    ? false
                    : true
                }
                onChange={(e) =>
                  guardarAfiliado({
                    ...afiliado,
                    alicuotaagip: e.value,
                  })
                }
                size={8}
                mode="decimal"
                locale="en-US"
                minFractionDigits={2}
                style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
              />
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "1vw" }}>
              <Typography
                variant="h6"
                style={{ textDecoration: "underline", color: "coral" }}
              >
                Módulos Adicionales
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Autocomplete
                id="adicionales"
                //disabled
                fullWidth
                options={
                  adicionales ? adicionales.filter((item) => item.activo) : null
                }
                disablePortal
                defaultValue={adicional}
                getOptionLabel={(option) => option.nombre}
                disabled={afiliado && afiliado.titular ? false : true}
                multiple
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Módulos Adicionales"
                    size="small"
                  />
                )}
                onKeyDown={handleEnter}
                onChange={(event, value) => {
                  if (value) {
                    guardarAdicional(value);
                    guardarAfiliado({
                      ...afiliado,
                      adicional: value,
                    });
                  } else {
                    guardarAdicional(null);
                    guardarAfiliado({
                      ...afiliado,
                      adicional: null,
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "1vw" }}>
              <Typography
                variant="h6"
                style={{ textDecoration: "underline", color: "coral" }}
              >
                Operaciones
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              {selectedRowClientes &&
              titular &&
              selectedRowClientes.titular &&
              titular.habilitado ? (
                <ButtonMui
                  onClick={(e) => {
                    let d = new Date();
                    d.setHours(d.getHours() - 3);
                    setValueDate(d.toISOString());
                    obtenerMotivosBajas();
                    guardarMotivoBaja(null);
                    setInhabilitarVisible(true);
                  }}
                  disabled={statusAcceso3 !== 200}
                  color="error"
                  type="button"
                  variant="contained"
                  startIcon={<CloseIcon />}
                  style={{ marginRight: "1em" }}
                >
                  INHABILITAR GRUPO
                </ButtonMui>
              ) : null}

              {selectedRowClientes &&
              titular &&
              selectedRowClientes.titular &&
              !titular.habilitado ? (
                <ButtonMui
                  onClick={(e) => {
                    let d = new Date();
                    d.setHours(d.getHours() - 3);
                    setValueDate(d.toISOString());
                    setHabilitarVisible(true);
                  }}
                  color="success"
                  type="button"
                  variant="contained"
                  startIcon={<CheckIcon />}
                  style={{ marginRight: "1em" }}
                >
                  HABILITAR GRUPO
                </ButtonMui>
              ) : null}

              {selectedRowClientes &&
              titular &&
              !selectedRowClientes.titular &&
              titular.habilitado &&
              selectedRowClientes.habilitado ? (
                <ButtonMui
                  onClick={(e) => {
                    let d = new Date();
                    d.setHours(d.getHours() - 3);
                    setValueDate(d.toISOString());
                    obtenerMotivosBajas();
                    guardarMotivoBaja(null);
                    setInhabilitarVisible(true);
                  }}
                  color="error"
                  type="button"
                  variant="contained"
                  startIcon={<CloseIcon />}
                  style={{ marginRight: "1em" }}
                >
                  INHABILITAR ADHERENTE
                </ButtonMui>
              ) : null}

              {selectedRowClientes &&
              titular &&
              !selectedRowClientes.titular &&
              titular.habilitado &&
              !selectedRowClientes.habilitado ? (
                <ButtonMui
                  onClick={(e) => {
                    let d = new Date();
                    d.setHours(d.getHours() - 3);
                    setValueDate(d.toISOString());
                    setHabilitarVisible(true);
                  }}
                  color="success"
                  type="button"
                  variant="contained"
                  startIcon={<CheckIcon />}
                  style={{ marginRight: "1em" }}
                >
                  HABILITAR ADHERENTE
                </ButtonMui>
              ) : null}

              {selectedRowClientes &&
              titular &&
              selectedRowClientes.titular &&
              titular.habilitado &&
              !titular.suspendido ? (
                <ButtonMui
                  onClick={(e) => {
                    setValueDateSuspenderDesde(null);
                    setValueDateSuspenderHasta(null);
                    obtenerMotivosSuspendidos();
                    guardarMotivoSuspendido(null);
                    setSuspenderVisible(true);
                  }}
                  disabled={statusAcceso3 !== 200}
                  color="warning"
                  type="button"
                  variant="contained"
                  startIcon={<CloseIcon />}
                  style={{ marginRight: "1em" }}
                >
                  SUSPENDER GRUPO
                </ButtonMui>
              ) : null}

              {selectedRowClientes &&
              titular &&
              selectedRowClientes.titular &&
              titular.habilitado &&
              titular.suspendido ? (
                <ButtonMui
                  onClick={(e) => {
                    setCancelarSuspenderVisible(true);
                  }}
                  color="success"
                  type="button"
                  variant="contained"
                  startIcon={<CheckIcon />}
                  style={{ marginRight: "1em" }}
                >
                  CANCELAR SUSPENCION GRUPO
                </ButtonMui>
              ) : null}
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
      <div className="dialog-demo">
        <Dialog
          header={renderHeaderMapa()}
          visible={openDomicilio}
          footer={renderFooterMapa()}
          closable={false}
        >
          {PopupExampleDomicilio()}
        </Dialog>

        <Dialog
          header={renderHeaderMapa()}
          visible={openDomicilioFacturacion}
          footer={renderFooterMapa()}
          closable={false}
        >
          {PopupExampleDomicilioFacturacion()}
        </Dialog>

        <Dialog
          //header={renderHeaderMapa()}
          visible={openCuit}
          //footer={renderFooterMapa()}
          closable={true}
          onHide={() => onHideCuit()}
        >
          {/*PopupExample()*/}
        </Dialog>

        <Dialog
          header={renderHeaderHistorial}
          visible={historialVisible}
          icon="pi pi-external-link"
          closable={false}
          onHide={() => onHideHistorial()}
          footer={renderFooterHistorial()}
          breakpoints={{ "960px": "75vw" }}
          style={{
            width: "40vw",
            paddingBottom: "0",
            overflowY: "visible",
          }}
          position="center"
        >
          <InputTextarea
            rows={5}
            cols={60}
            value={historial}
            onChange={(e) => setHistorial(e.target.value)}
          />{" "}
          <br />
        </Dialog>

        <Dialog
          header={renderHeaderInhabilitar()}
          visible={inhabilitarVisible}
          footer={renderFooterInhabilitar()}
          breakpoints={{ "960px": "70vw" }}
          style={{ width: "50vw", paddingBottom: "0" }}
          position="center"
          closable={false}
        >
          <Grid item xs={12} md={12}>
            <TextField
              id="fechaInhabilitar"
              label="Fecha"
              value={valueDate ? formatDateTime(valueDate) : null}
              type="datetime-local"
              fullWidth
              disabled
              variant="standard"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={handleEnter}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Autocomplete
              id="motivoBaja"
              //disabled
              //fullWidth
              options={
                motivosBajas
                  ? motivosBajas.filter((item) => item.habilitado)
                  : null
              }
              disablePortal
              defaultValue={motivoBaja}
              getOptionLabel={(option) => option.nombre}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Motivo de Baja *"
                  size="small"
                />
              )}
              onKeyDown={handleEnter}
              onChange={(event, value) => {
                if (value) {
                  guardarMotivoBaja(value);
                } else {
                  guardarMotivoBaja(null);
                }
              }}
            />
          </Grid>
        </Dialog>

        <Dialog
          header={renderHeaderHabilitar()}
          visible={habilitarVisible}
          footer={renderFooterHabilitar()}
          breakpoints={{ "960px": "70vw" }}
          style={{ width: "50vw", paddingBottom: "0" }}
          position="center"
          closable={false}
        >
          <Grid item xs={12} md={12}>
            <TextField
              id="fechaHabilitar"
              label="Fecha"
              value={valueDate ? formatDateTime(valueDate) : null}
              type="datetime-local"
              //fullWidth
              disabled
              variant="standard"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={handleEnter}
            />
          </Grid>
        </Dialog>

        <Dialog
          header={renderHeaderSuspender()}
          visible={suspenderVisible}
          footer={renderFooterSuspender()}
          breakpoints={{ "960px": "70vw" }}
          style={{ width: "40vw", paddingBottom: "0" }}
          position="center"
          closable={false}
        >
          <Grid item xs={12} md={12}>
            <TextField
              id="fechaSuspenderDesde"
              label="Fecha Desde"
              type="date"
              fullWidth
              variant="standard"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setValueDateSuspenderDesde(e.target.value);
              }}
              onKeyDown={handleEnter}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <TextField
              id="fechaSuspenderHasta"
              label="Fecha Hasta"
              //value={valueDateSuspenderDesde ? formatDate(valueDateSuspenderDesde) : null}
              type="date"
              fullWidth
              variant="standard"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setValueDateSuspenderHasta(e.target.value);
              }}
              onKeyDown={handleEnter}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Autocomplete
              id="motivoSuspendido"
              //disabled
              fullWidth
              options={
                motivosSuspendidos
                  ? motivosSuspendidos.filter((item) => item.habilitado)
                  : null
              }
              disablePortal
              defaultValue={motivoSuspendido}
              getOptionLabel={(option) => option.nombre}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Motivo de Suspensión *"
                  size="small"
                />
              )}
              onKeyDown={handleEnter}
              onChange={(event, value) => {
                if (value) {
                  guardarMotivoSuspendido(value);
                } else {
                  guardarMotivoSuspendido(null);
                }
              }}
            />
          </Grid>
        </Dialog>

        <Dialog
          header={renderHeaderCancelarSuspender()}
          visible={cancelarSuspenderVisible}
          footer={renderFooterCancelarSuspender()}
          breakpoints={{ "960px": "70vw" }}
          style={{ width: "30vw", paddingBottom: "0" }}
          position="center"
          closable={false}
        ></Dialog>

        <Dialog
          header={renderHeaderMessage}
          visible={messageVisible}
          icon="pi pi-external-link"
          closable={false}
          onHide={() => onHideMessage()}
          footer={renderFooterMessage()}
          breakpoints={{ "960px": "75vw" }}
          style={{
            width: "40vw",
            paddingBottom: "0",
            overflowY: "visible",
          }}
          position="center"
        >
          <InputTextarea
            rows={5}
            cols={60}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />{" "}
          <br />
        </Dialog>
      </div>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import ClientesDatosPersonales from "./ClientesDatosPersonales";
import ClientesIntegrantes from "./ClientesIntegrantes";
import Box from "@mui/material/Box";
import * as EmailValidator from "email-validator";
import Facturacion from "./Facturacion";
import RendicionesAplicadasEliminadas from "./RendicionesAplicadasEliminadas";

export default function Clientes(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [globalFilterCtaCte, setGlobalFilterCtaCte] = useState(null);
  const [globalFilterSaldos, setGlobalFilterSaldos] = useState(null);
  const [selectedField, setSelectedField] = useState("Número de socio");
  const [visible, setVisible] = useState(false);
  const [visibleCtaCte, setVisibleCtaCte] = useState(false);
  const [visibleSaldos, setVisibleSaldos] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowCtaCte, setSelectedRowCtaCte] = useState(null);
  const [selectedRowSaldos, setSelectedRowSaldos] = useState(null);
  const [visibleComprobante, setVisibleComprobante] = useState(false);
  const [comprobanteIdVisualizar, guardarComprobanteIdVisualizar] =
    useState(null);
    const [empresaVisualizar, guardarEmpresaVisualizar] =
    useState(null);
  const [
    comprobanteTitularIdVisualizar,
    guardarComprobanteTitularIdVisualizar,
  ] = useState(null);
  const [comprobanteTipoVisualizar, guardarComprobanteTipoVisualizar] =
    useState(null);
    const [rendicionIdVisualizar, guardarRendicionIdVisualizar] =
    useState(null);
  const [bandRendicion, setBandRendicion] = useState(false);

  let navigate = useNavigate();

  const steps = ["Información General", "Integrantes"];

  const opciones = [
    { label: "Número de socio", value: "id" },
    { label: "Apellido y nombre", value: "nombre" },
    { label: "Domicilio", value: "domicilio" },
    { label: "Teléfono", value: "telefono" },
    { label: "Documento", value: "dni" },
  ];

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Descripción", dataKey: "descripcion" },
    { title: "Stock", dataKey: "stock" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const exportColumnsCtaCte = [
    { title: "ID", dataKey: "id" },
    { title: "Fecha", dataKey: "fecha" },
    { title: "Comprobante", dataKey: "comprobante" },
    { title: "Debe", dataKey: "debe" },
    { title: "Haber", dataKey: "haber" },
    { title: "Saldo", dataKey: "saldo" },
    { title: "Empresa", dataKey: "empresa" },
  ];

  const exportColumnsSaldos = [
    { title: "ID", dataKey: "id" },
    { title: "Fecha", dataKey: "fecha" },
    { title: "Comprobante", dataKey: "comprobante" },
    { title: "Total", dataKey: "total" },
    { title: "Saldo", dataKey: "saldo" },
    { title: "Empresa", dataKey: "empresa" },
  ];

  const toast = useRef(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    statusAcceso2,
    statusAcceso3,
    clientes,
    afiliado,
    titular,
    activeStep,
    skipped,
    cliente,
    ctasCtes,
    saldos,
    empresaElegida,
    verComprobante,
    selectedRowClientes,
    obtenerClientes,
    obtenerAcceso,
    obtenerAcceso2,
    obtenerAcceso3,
    agregarCliente,
    guardarClientes,
    actualizarCliente,
    guardarAfiliado,
    setBandFirst,
    buscarTitular,
    buscarIntegrantes,
    guardarTitular,
    guardarIntegrantes,
    setActiveStep,
    setSkipped,
    guardarCliente,
    setSelectedRowClientes,
    obtenerHistorial,
    guardarHistoriales,
    obtenerCtaCte,
    guardarCtasCtes,
    setVerComprobante,
    obtenerSaldos,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Clientes" });
      obtenerAcceso2({
        usuarioId: state.usuario.id,
        layout: "BloquearServiciosEmergencias",
      });
      obtenerAcceso3({ usuarioId: state.usuario.id, layout: "ActualizarClientes" });
    }
  }, [state]);

  useEffect(() => {
    //obtenerClientes({ opcion: selectedField, texto: globalFilter })
  }, []);

  const editClick = (e) => {
    if (selectedRowClientes) {
      //console.log(selectedRowClientes);
      guardarCliente(selectedRowClientes);
      buscarTitular({
        titularId: selectedRowClientes.id_titular,
      });
      buscarIntegrantes({ titularId: selectedRowClientes.id_titular });
      obtenerHistorial({ titularId: selectedRowClientes.id_titular });
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un afiliado.",
        life: 3000,
      });
    }
  };

  const ctaCteClick = (e) => {
    if (selectedRowClientes) {
      //console.log(selectedRowClientes);
      //console.log(titular)
      guardarCliente(selectedRowClientes);
      buscarTitular({
        titularId: selectedRowClientes.id_titular,
      });
      obtenerCtaCte({
        titularId: selectedRowClientes.id_titular,
      });
      setTimeout(() => {
        setVisibleCtaCte(true);
      }, 1000);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Cuenta Corriente.",
        detail: "Debe seleccionar un afiliado.",
        life: 3000,
      });
    }
  };

  const saldosClick = (e) => {
    if (selectedRowClientes) {
      //console.log(selectedRowClientes);
      //console.log(titular)
      guardarCliente(selectedRowClientes);
      buscarTitular({
        titularId: selectedRowClientes.id_titular,
      });
      obtenerSaldos({
        titularId: selectedRowClientes.id_titular,
      });
      setTimeout(() => {
        setVisibleSaldos(true);
      }, 1000);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Saldos Pendientes.",
        detail: "Debe seleccionar un afiliado.",
        life: 3000,
      });
    }
  };

  const addClick = (e) => {
    //props.history.push(`/cliente/0`);
    guardarHistoriales([]);
    setSelectedRowClientes(null);
    guardarCliente({
      id: "99999999",
      habilitado: true,
      titular: true,
    });
    guardarTitular({ id: "99999999000000000000" });
    guardarIntegrantes([]);
    setActiveStep(0);
    setVisible(true);
  };

  const findClick = (e) => {
    if (selectedField === null) {
      toast.current.show({
        severity: "warn",
        summary: "Información",
        detail: "Debe escribir algúna opción de busqueda.",
        life: 3000,
      });
      return;
    }

    if (globalFilter.trim().length === 0) {
      toast.current.show({
        severity: "warn",
        summary: "Información",
        detail: "Debe escribir algún texto a buscar.",
        life: 3000,
      });
      return;
    }

    obtenerClientes({
      opcion: selectedField,
      texto: globalFilter,
    });
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHideCtaCte = (e) => {
    setVisibleCtaCte(false);
  };

  const onHideSaldos = (e) => {
    setVisibleSaldos(false);
  };

  const onHide = (e) => {
    guardarClientes([]);
    guardarAfiliado(null);
    setVisible(false);
    setSelectedRowClientes(null);
    //setBandFirst(true);
    guardarTitular(null);
    navigate("/clientes");
    window.location.reload(false);
  };

  function is_numeric(str) {
    return /^\d+$/.test(str);
  }

  function ValidateCUITCUIL(cuit) {
    if (cuit.length != 13) return 0;

    var rv = false;
    var resultado = 0;
    var cuit_nro = cuit.replace("-", "");
    var codes = "6789456789";
    var cuit_long = parseInt(cuit_nro);
    var verificador = parseInt(cuit_nro[cuit_nro.length - 1]);
    var x = 0;

    while (x < 10) {
      var digitoValidador = parseInt(codes.substring(x, x + 1));
      if (isNaN(digitoValidador)) digitoValidador = 0;
      var digito = parseInt(cuit_nro.substring(x, x + 1));
      if (isNaN(digito)) digito = 0;
      var digitoValidacion = digitoValidador * digito;
      resultado += digitoValidacion;
      x++;
    }
    resultado = resultado % 11;
    rv = resultado == verificador;
    return rv;
  }

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !afiliado ||
      afiliado.id === undefined ||
      afiliado.id === null ||
      afiliado.id === 0 ||
      afiliado.id.trim() === ""
    ) {
      mensajeAlerta("Afiliados", "El Nº de socio es necesario !.", "error");
      return;
    }

    if (afiliado && afiliado.id === "99999999000000000000") {
      mensajeAlerta("Afiliados", "El Nº de socio es incorrecto !.", "error");
      return;
    }

    if (
      !afiliado ||
      afiliado.nombre_razonsocial === undefined ||
      afiliado.nombre_razonsocial === null ||
      afiliado.nombre_razonsocial.trim() === ""
    ) {
      mensajeAlerta(
        "Afiliados",
        "El nombre o razón social es necesario !.",
        "error"
      );
      return;
    }

    if (
      !afiliado ||
      afiliado.domicilio === undefined ||
      afiliado.domicilio === null ||
      afiliado.domicilio.trim() === ""
    ) {
      mensajeAlerta("Afiliados", "El domicilio es necesario !.", "error");
      return;
    }

    if (
      !afiliado ||
      afiliado.id_provincia === undefined ||
      afiliado.id_provincia === null ||
      afiliado.id_provincia === 0
    ) {
      mensajeAlerta("Afiliados", "La provincia es necesaria !.", "error");
      return;
    }

    if (
      !afiliado ||
      afiliado.id_localidad === undefined ||
      afiliado.id_localidad === null ||
      afiliado.id_localidad === 0
    ) {
      mensajeAlerta("Afiliados", "La localidad es necesaria !.", "error");
      return;
    }

    if (
      !afiliado ||
      afiliado.id_tipodocumento === undefined ||
      afiliado.id_tipodocumento === null ||
      afiliado.id_tipodocumento === 0
    ) {
      mensajeAlerta(
        "Afiliados",
        "El tipo de documento es necesario !.",
        "error"
      );
      return;
    }

    if (
      !afiliado ||
      afiliado.numerodocumento === undefined ||
      afiliado.numerodocumento === null ||
      afiliado.numerodocumento === 0 ||
      afiliado.numerodocumento.trim() === ""
    ) {
      mensajeAlerta(
        "Afiliados",
        "El Nº de documento o C.U.I.T. es necesario !.",
        "error"
      );
      return;
    }

    if (
      afiliado &&
      afiliado.id_tipodocumento === 4 && // D.N.I
      !is_numeric(afiliado.numerodocumento)
    ) {
      mensajeAlerta(
        "Afiliados",
        "El Nº de documento es incorrecto !.",
        "error"
      );
      return;
    }

    if (
      afiliado &&
      afiliado.id_tipodocumento === 3 && // C.U.I.T
      !ValidateCUITCUIL(afiliado.numerodocumento)
    ) {
      mensajeAlerta("Afiliados", "El Nº de C.U.I.T es incorrecto !.", "error");
      return;
    }

    if (
      afiliado &&
      afiliado.id_tipodocumento === 4 && // D.N.I
      (afiliado.nacimiento === undefined ||
        afiliado.nacimiento === null ||
        afiliado.nacimiento === 0 ||
        afiliado.nacimiento.trim() === "")
    ) {
      mensajeAlerta(
        "Afiliados",
        "La fecha de nacimiento es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !afiliado ||
      afiliado.ingreso === undefined ||
      afiliado.ingreso === null ||
      afiliado.ingreso === 0
    ) {
      mensajeAlerta(
        "Afiliados",
        "La fecha de ingreso es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !afiliado ||
      afiliado.vigencia === undefined ||
      afiliado.vigencia === null ||
      afiliado.vigencia.trim() === ""
    ) {
      mensajeAlerta(
        "Afiliados",
        "La vigencia de ingreso es necesaria !.",
        "error"
      );
      return;
    }

    if (afiliado.titular) {
      if (
        !afiliado ||
        afiliado.nombre_razonsocial_facturacion === undefined ||
        afiliado.nombre_razonsocial_facturacion === null ||
        afiliado.nombre_razonsocial_facturacion.trim() === ""
      ) {
        mensajeAlerta(
          "Afiliados",
          "El nombre o razón social de facturación es necesario !.",
          "error"
        );
        return;
      }

      if (
        !afiliado ||
        afiliado.domicilio_facturacion === undefined ||
        afiliado.domicilio_facturacion === null ||
        afiliado.domicilio_facturacion.trim() === ""
      ) {
        mensajeAlerta(
          "Afiliados",
          "El domicilio de facturación es necesario !.",
          "error"
        );
        return;
      }

      if (
        !afiliado ||
        afiliado.id_provincia_facturacion === undefined ||
        afiliado.id_provincia_facturacion === null ||
        afiliado.id_provincia_facturacion === 0
      ) {
        mensajeAlerta(
          "Afiliados",
          "La provincia de facturacion es necesaria !.",
          "error"
        );
        return;
      }

      if (
        !afiliado ||
        afiliado.id_localidad_facturacion === undefined ||
        afiliado.id_localidad_facturacion === null ||
        afiliado.id_localidad_facturacion === 0
      ) {
        mensajeAlerta(
          "Afiliados",
          "La localidad de facturacion es necesaria !.",
          "error"
        );
        return;
      }

      if (
        !afiliado ||
        afiliado.id_tipocontribuyente === undefined ||
        afiliado.id_tipocontribuyente === null ||
        afiliado.id_tipocontribuyente === 0 ||
        afiliado.id_tipocontribuyente === ""
      ) {
        mensajeAlerta(
          "Proveedores",
          "El tipo de contribuyente es necesario !.",
          "error"
        );
        return;
      }

      if (
        !afiliado ||
        afiliado.id_empresa_facturacion === undefined ||
        afiliado.id_empresa_facturacion === null ||
        afiliado.id_empresa_facturacion === 0
      ) {
        mensajeAlerta("Afiliados", "La empresa es necesaria !.", "error");
        return;
      }

      if (
        !afiliado ||
        afiliado.id_grupo === undefined ||
        afiliado.id_grupo === null ||
        afiliado.id_grupo === 0
      ) {
        mensajeAlerta("Afiliados", "El grupo es necesario !.", "error");
        return;
      }

      if (
        !afiliado ||
        afiliado.id_rubro === undefined ||
        afiliado.id_rubro === null ||
        afiliado.id_rubro === 0
      ) {
        mensajeAlerta("Afiliados", "El rubro es necesario !.", "error");
        return;
      }

      if (
        !afiliado ||
        afiliado.id_plan_facturacion === undefined ||
        afiliado.id_plan_facturacion === null ||
        afiliado.id_plan_facturacion === 0
      ) {
        mensajeAlerta("Afiliados", "El plan es necesario !.", "error");
        return;
      }

      if (
        !afiliado ||
        afiliado.id_asesor_facturacion === undefined ||
        afiliado.id_asesor_facturacion === null ||
        afiliado.id_asesor_facturacion === 0
      ) {
        mensajeAlerta("Afiliados", "El asesor es necesario !.", "error");
        return;
      }

      if (
        !afiliado ||
        afiliado.id_cobrador1_facturacion === undefined ||
        afiliado.id_cobrador1_facturacion === null ||
        afiliado.id_cobrador1_facturacion === 0
      ) {
        mensajeAlerta("Afiliados", "El cobrador es necesario !.", "error");
        return;
      }

      if (
        !afiliado ||
        afiliado.id_tipocontribuyente === undefined ||
        afiliado.id_tipocontribuyente === null ||
        afiliado.id_tipocontribuyente === 0
      ) {
        mensajeAlerta(
          "Afiliados",
          "El tipo de contribuyente es necesario !.",
          "error"
        );
        return;
      }

      if (
        afiliado &&
        (afiliado.id_tipocontribuyente === 1 ||
          afiliado.id_tipocontribuyente === 4 ||
          afiliado.id_tipocontribuyente === 5) &&
        afiliado.id_tipodocumento === 3 /* C.U.I.T */
      ) {
        mensajeAlerta(
          "Afiliados",
          "El tipo de contribuyente debe tener D.N.I. !.",
          "error"
        );
        return;
      }

      if (
        afiliado &&
        (afiliado.id_tipocontribuyente === 2 ||
          afiliado.id_tipocontribuyente === 3 ||
          afiliado.id_tipocontribuyente === 6) &&
        afiliado.id_tipodocumento === 4 /* D.N.I. */
      ) {
        mensajeAlerta(
          "Afiliados",
          "El tipo de contribuyente debe tener C.U.I.T. !.",
          "error"
        );
        return;
      }

      if (
        !afiliado ||
        afiliado.id_frecuencia === undefined ||
        afiliado.id_frecuencia === null ||
        afiliado.id_frecuencia === 0
      ) {
        mensajeAlerta(
          "Afiliados",
          "La frecuencia de facturación es necesaria !.",
          "error"
        );
        return;
      }

      if (
        !afiliado ||
        afiliado.matricula === undefined ||
        afiliado.matricula === null
      ) {
        mensajeAlerta("Afiliados", "La matrícula es necesaria !.", "error");
        return;
      }

      if (
        !afiliado ||
        afiliado.cuota === undefined ||
        afiliado.cuota === null
      ) {
        mensajeAlerta("Afiliados", "La cuota es necesaria !.", "error");
        return;
      }

      if (
        !afiliado ||
        afiliado.id_formapago === undefined ||
        afiliado.id_formapago === null ||
        afiliado.id_formapago === 0
      ) {
        mensajeAlerta("Afiliados", "La forma de pago es necesaria !.", "error");
        return;
      }
    }

    if (afiliado && afiliado.cuotaextra > 0) {
      if (
        afiliado.cuotaextradesde === null ||
        afiliado.cuotaextradesde === ""
      ) {
        mensajeAlerta(
          "Afiliados",
          "Debe indicar vigencia extraordinaria desde !.",
          "error"
        );
        return;
      }

      if (
        afiliado.cuotaextrahasta === null ||
        afiliado.cuotaextrahasta === ""
      ) {
        mensajeAlerta(
          "Afiliados",
          "Debe indicar vigencia extraordinaria hasta !.",
          "error"
        );
        return;
      }

      let vig1 = null;
      let vig2 = null;
      if (
        afiliado.cuotaextradesde !== null &&
        afiliado.cuotaextrahasta !== null
      ) {
        vig1 = Number(
          afiliado.cuotaextradesde.substring(3, 5) +
            afiliado.cuotaextradesde.substring(0, 2)
        );
        vig2 = Number(
          afiliado.cuotaextrahasta.substring(3, 5) +
            afiliado.cuotaextrahasta.substring(0, 2)
        );
      }

      if (vig1 > vig2) {
        mensajeAlerta(
          "Afiliados",
          "Vigencia extraordinaria desde no puede ser mayor que vigencia extraordinaria hasta !.",
          "error"
        );
        return;
      }
    }

    if (
      afiliado &&
      afiliado.email !== undefined &&
      afiliado.email !== null &&
      afiliado.email.trim() !== ""
    ) {
      if (!EmailValidator.validate(afiliado.email)) {
        mensajeAlerta("Afiliados", "El email 1 es incorrecto !.", "error");
        return;
      }
    }

    if (
      afiliado &&
      afiliado.email2 !== undefined &&
      afiliado.email2 !== null &&
      afiliado.email2.trim() !== ""
    ) {
      if (!EmailValidator.validate(afiliado.email2)) {
        mensajeAlerta("Afiliados", "El email 2 es incorrecto !.", "error");
        return;
      }
    }

    if (
      afiliado &&
      afiliado.email3 !== undefined &&
      afiliado.email3 !== null &&
      afiliado.email3.trim() !== ""
    ) {
      if (!EmailValidator.validate(afiliado.email3)) {
        mensajeAlerta("Afiliados", "El email 3 es incorrecto !.", "error");
        return;
      }
    }

    if (
      afiliado &&
      afiliado.email4 !== undefined &&
      afiliado.email4 !== null &&
      afiliado.email4.trim() !== ""
    ) {
      if (!EmailValidator.validate(afiliado.email4)) {
        mensajeAlerta("Afiliados", "El email 4 es incorrecto !.", "error");
        return;
      }
    }

    if (
      afiliado &&
      afiliado.email5 !== undefined &&
      afiliado.email5 !== null &&
      afiliado.email5.trim() !== ""
    ) {
      if (!EmailValidator.validate(afiliado.email5)) {
        mensajeAlerta("Afiliados", "El email 5 es incorrecto !.", "error");
        return;
      }
    }

    // Llamar al context
    if (selectedRowClientes) {
      actualizarCliente({ afiliado });

      setTimeout(() => {
        buscarIntegrantes({ titularId: afiliado.id.substring(0, 8) });
      }, 1000);
    } else {
      agregarCliente({ afiliado });
      setTimeout(() => {
        if (afiliado.titular) {
          buscarIntegrantes({ titularId: afiliado.id });
          buscarTitular({
            titularId: afiliado.id,
          });
          window.location.reload(false);
        } else {
          buscarIntegrantes({ titularId: afiliado.id_titular });
          buscarTitular({
            titularId: afiliado.id_titular,
          });
        }
        //setActiveStep(1);
        //window.location.reload(false);
      }, 1000);
    }

    //onHide();
    //setSelectedRow(null);
    /*
    setTimeout(() => {
      obtenerClientes({ todos: true });
    }, 2000);
    */
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Clientes",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const titularTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.titular}></Checkbox>
      </Fragment>
    );
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const suspendidoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.suspendido}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, clientes);
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(clientes);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "clientes");
    });
  };

  const exportPdfCtaCte = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumnsCtaCte, ctasCtes);
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcelCtaCte = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(ctasCtes);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "CtasCtes");
    });
  };

  const exportPdfSaldos = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumnsSaldos, saldos);
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcelSaldos = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(saldos);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Saldos");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />

        <Autocomplete
          disablePortal
          id="combo-box-demo"
          defaultValue={opciones[0]}
          options={opciones}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Opciones de Búsqueda" />
          )}
          onChange={(event, value) => {
            if (value) {
              setSelectedField(value.label);
            } else {
              setSelectedField(null);
            }
          }}
        />

        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
            onKeyDown={handleEnter}
          />
        </span>
        <Button
          icon="pi pi-search"
          tooltip="Buscar"
          className="p-button-rounded p-button-help"
          style={{ marginLeft: "0em" }}
          onClick={findClick}
        />

        <Tag value="Afiliados" style={{ marginLeft: "1em" }}></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag
          value={
            selectedRowClientes && titular
              ? selectedRowClientes.titular
                ? "Afiliado: " +
                  selectedRowClientes.nombre_razonsocial +
                  " - TITULAR "
                : "Afiliado: " +
                  selectedRowClientes.nombre_razonsocial +
                  " - ADHERENTE - Socio Titular " +
                  titular.nombre_razonsocial
              : titular && titular.id !== "99999999000000000000"
              ? "Afiliado: NUEVO" +
                " - ADHERENTE - Socio Titular " +
                titular.nombre_razonsocial
              : titular && titular.id === "99999999000000000000"
              ? "Afiliado: NUEVO" + " - TITULAR"
              : null
          }
        ></Tag>

        {selectedRowClientes && titular ? (
          <Fragment>
            <Tag
              style={{ marginLeft: "1em" }}
              value={selectedRowClientes && titular ? "Estado: " : null}
            ></Tag>

            {selectedRowClientes.habilitado &&
            !selectedRowClientes.suspendido ? (
              <Tag value="ACTIVO" severity="success"></Tag>
            ) : null}

            {!selectedRowClientes.habilitado ? (
              <Tag value={"INHABILITADO"} severity="danger"></Tag>
            ) : null}

            {selectedRowClientes.habilitado &&
            selectedRowClientes.suspendido ? (
              <Tag value={"SUSPENDIDO"} severity="warning"></Tag>
            ) : null}
          </Fragment>
        ) : null}

        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
            style={{ marginLeft: "1vw" }}
          >
            Anterior
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            disabled={activeStep === steps.length - 1 || !selectedRowClientes}
            onClick={handleNext}
          >
            Siguiente
          </Button>
        </Box>
      </div>
    );
  };

  const renderFooter = () => {
    if (activeStep === 0) {
      return (
        <div>
          <ButtonMui
            onClick={aceptarClick}
            color="primary"
            type="button"
            fullWidth
            disabled={statusAcceso3 !== 200}
            variant="contained"
            startIcon={<CheckIcon />}
          >
            Aceptar
          </ButtonMui>
          <ButtonMui
            onClick={onHide}
            color="error"
            type="button"
            fullWidth
            variant="contained"
            startIcon={<CloseIcon />}
            autoFocus
          >
            Cancelar
          </ButtonMui>
        </div>
      );
    }
  };

  const headerCtaCte = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcelCtaCte}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdfCtaCte}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilterCtaCte(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag
          value={
            selectedRowClientes && titular && ctasCtes && ctasCtes.length > 0
              ? "Cuenta Corriente de " +
                titular.id_titular +
                " - " +
                titular.nombre_razonsocial +
                " - Saldo: " +
                parseFloat(ctasCtes[ctasCtes.length - 1].saldo).toFixed(2)
              : selectedRowClientes &&
                titular &&
                ctasCtes &&
                ctasCtes.length === 0
              ? "Cuenta Corriente de " +
                titular.id_titular +
                " - " +
                titular.nombre_razonsocial +
                " - Saldo: " +
                parseFloat(0).toFixed(2)
              : "Cuenta Corriente"
          }
        ></Tag>
      </div>
    </Fragment>
  );

  const headerSaldos = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcelSaldos}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdfSaldos}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilterSaldos(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag
          value={
            selectedRowClientes && titular && saldos && saldos.length > 0
              ? "Saldos Pendientes de " +
                titular.id_titular +
                " - " +
                titular.nombre_razonsocial +
                " - Saldo: " +
                parseFloat(saldos[saldos.length - 1].saldoFinal).toFixed(2)
              : selectedRowClientes && titular && saldos && saldos.length === 0
              ? "Saldos Pendientes de " +
                titular.id_titular +
                " - " +
                titular.nombre_razonsocial +
                " - Saldo: " +
                parseFloat(0).toFixed(2)
              : "Saldos Pendientes"
          }
        ></Tag>
      </div>
    </Fragment>
  );

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function handleEnter(e) {
    if (e.keyCode === 13) {
      findClick();
    }
  }

  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  function formatDate(dateString) {
    var allDate = dateString.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }



  const onHideComprobante = (e) => {
    guardarRendicionIdVisualizar(null)
    guardarComprobanteIdVisualizar(null);
    guardarEmpresaVisualizar(null)
    guardarComprobanteTitularIdVisualizar(null);
    guardarComprobanteTipoVisualizar(null);
    setVisibleComprobante(false);
    setSelectedRow(null)
  };

  const fechaTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fecha)}</Fragment>;
  };

  const opcionesTemplate = (rowData) => {
    return (
      <Fragment>
        <Button
          icon="pi pi-search"
          rounded
          text
          severity="success"
          onClick={(e) => {
            guardarComprobanteIdVisualizar(null);
            guardarRendicionIdVisualizar(null)
            guardarEmpresaVisualizar(null)
            guardarComprobanteTitularIdVisualizar(null);
            guardarComprobanteTipoVisualizar(null);
            setSelectedRow(rowData)  
          }
          }
          aria-label="Bookmark"
          tooltip="Visualizar"
          tooltipOptions={{ position: "bottom" }}
        />
      </Fragment>
    );
  };

  // INICIO

  if (!verComprobante && visibleComprobante) {
    guardarComprobanteIdVisualizar(null);
    guardarRendicionIdVisualizar(null)
    guardarEmpresaVisualizar(null)
    guardarComprobanteTitularIdVisualizar(null);
    setVerComprobante(true);
    onHideComprobante();
  }

  if (selectedRow) {

    console.log(selectedRow)


    setSelectedRow(null)
    guardarComprobanteIdVisualizar(selectedRow.id.slice(1));
    guardarRendicionIdVisualizar(null)
    guardarEmpresaVisualizar(selectedRow.empresaId)
    guardarComprobanteTitularIdVisualizar(selectedRow.id_titular);
    guardarComprobanteTipoVisualizar(selectedRow.id.slice(0, 1));
    if (selectedRow.id.slice(0, 1) === "c") {
      setVisibleComprobante(true);
    } else {
      setBandRendicion(true);
    }
  }




  // FIN

  //console.log(ctasCtes)
  //console.log(comprobanteIdVisualizar)

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {clientes ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ align: "center", margin: "1em" }}
              >
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-pencil"
                  tooltip="Editar"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                <Button
                  icon="pi pi-align-justify"
                  tooltip="Cta. Cte."
                  className="p-button-rounded p-button-success"
                  style={{ marginLeft: "1em" }}
                  onClick={ctaCteClick}
                />
                <Button
                  icon="pi pi-clock"
                  tooltip="Saldos Pendientes"
                  className="p-button-rounded p-button-warning"
                  style={{ marginLeft: "1em" }}
                  onClick={saldosClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />

                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={clientes}
                      selection={selectedRowClientes}
                      onSelectionChange={(e) => {
                        guardarCtasCtes([]);
                        setSelectedRowClientes(e.value);
                      }}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      //globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={20}
                      rowsPerPageOptions={[10, 20, 50, 100, 500, 1000]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="Nº Afiliado"
                        //body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="nombre_razonsocial"
                        header="Razón Social"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="domicilio"
                        header="Domicilio"
                        //body={stockTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="numerodocumento"
                        header="Nº Documento"
                        //body={unidadTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="telefono1"
                        header="Teléfono"
                        //body={unidadTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="celular"
                        header="Celular"
                        //body={unidadTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="titular"
                        header="Titular"
                        body={titularTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="habilitado"
                        header="Habilitado"
                        body={habilitadoTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="suspendido"
                        header="Suspendido"
                        body={suspendidoTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>

              <Dialog
                //header={renderHeaderCtaCte()}
                visible={visibleCtaCte}
                closable={true}
                maximizable={true}
                //maximized={true}
                onHide={() => onHideCtaCte()}
                //footer={renderFooter()}
                breakpoints={{ "960px": "95vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="center"
              >
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={ctasCtes}
                      selection={selectedRowCtaCte}
                      onSelectionChange={(e) => setSelectedRowCtaCte(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={headerCtaCte}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilterCtaCte}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={100}
                      rowsPerPageOptions={[10, 20, 50, 100, 500]}
                    >
                      <Column
                        field="id"
                        header="ID"
                        //body={idTemplate}
                        //sortable
                      ></Column>
                      <Column
                        field="fecha"
                        header="Fecha"
                        body={fechaTemplate}
                        //sortable
                      ></Column>
                      <Column
                        field="comprobante"
                        header="Comprobante"
                        //body={habilitadoTemplate}
                        //sortable
                      ></Column>
                      <Column
                        field="debe"
                        header="Debe"
                        //body={propioTemplate}
                        bodyStyle={{ textAlign: "right" }}
                        //sortable
                      ></Column>
                      <Column
                        field="haber"
                        header="Haber"
                        //body={propioTemplate}
                        bodyStyle={{ textAlign: "right" }}
                        //sortable
                      ></Column>
                      <Column
                        field="saldo"
                        header="Saldo"
                        bodyStyle={{ textAlign: "right" }}
                        //body={propioTemplate}
                        //sortable
                      ></Column>
                      <Column
                        field="empresa"
                        header="Empresa"
                        //bodyStyle={{ textAlign: "right" }}
                        //body={propioTemplate}
                        //sortable
                      ></Column>
                      <Column
                        field="aplicado"
                        header="Aplica"
                        //bodyStyle={{ textAlign: "right" }}
                        //body={propioTemplate}
                        //sortable
                      ></Column>
                      <Column
                        header="Opciones"
                        body={opcionesTemplate}
                        //body={descripcionTemplate}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Dialog>

              <Dialog
                //header={renderHeaderCtaCte()}
                visible={visibleSaldos}
                closable={true}
                maximizable={true}
                //maximized={true}
                onHide={() => onHideSaldos()}
                //footer={renderFooter()}
                breakpoints={{ "960px": "95vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="center"
              >
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={saldos}
                      selection={selectedRowSaldos}
                      onSelectionChange={(e) => setSelectedRowSaldos(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={headerSaldos}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilterSaldos}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={20}
                      rowsPerPageOptions={[10, 20, 50, 100, 500]}
                    >
                      <Column
                        field="id"
                        header="ID"
                        //body={idTemplate}
                        //sortable
                      ></Column>
                      <Column
                        field="fecha"
                        header="Fecha"
                        body={fechaTemplate}
                        //sortable
                      ></Column>
                      <Column
                        field="comprobante"
                        header="Comprobante"
                        //body={habilitadoTemplate}
                        //sortable
                      ></Column>
                      <Column
                        field="total"
                        header="Total"
                        //body={propioTemplate}
                        bodyStyle={{ textAlign: "right" }}
                        //sortable
                      ></Column>
                      <Column
                        field="saldo"
                        header="Saldo"
                        bodyStyle={{ textAlign: "right" }}
                        //body={propioTemplate}
                        //sortable
                      ></Column>
                      <Column
                        field="empresa"
                        header="Empresa"
                        //bodyStyle={{ textAlign: "right" }}
                        //body={propioTemplate}
                        //sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Dialog>

              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                maximizable={true}
                maximized={true}
                footer={renderFooter()}
                breakpoints={{ "960px": "95vw" }}
                style={{ width: "95vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={activeStep}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                    <Fragment>
                      {activeStep === 0 ? <ClientesDatosPersonales /> : null}
                      {activeStep === 1 ? <ClientesIntegrantes /> : null}
                    </Fragment>
                  </Box>
                </Grid>
              </Dialog>

              <Dialog
                //header={renderHeader()}
                visible={visibleComprobante}
                closable={true}
                maximizable={true}
                maximized={true}
                onHide={() => onHideComprobante()}
                //footer={renderFooter()}
                breakpoints={{ "960px": "95vw" }}
                style={{ width: "95vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "0vw" }}>
                  {comprobanteIdVisualizar &&
                  comprobanteTitularIdVisualizar &&
                  comprobanteTipoVisualizar === "c" ? (
                    <Facturacion
                      idProps={comprobanteIdVisualizar}
                      titularIdProps={comprobanteTitularIdVisualizar}
                    />
                  ) : null}
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}

      {comprobanteIdVisualizar &&
      comprobanteTitularIdVisualizar &&
      comprobanteTipoVisualizar === "r" &&
      bandRendicion ? (
        <Fragment>
          <RendicionesAplicadasEliminadas idProps={comprobanteIdVisualizar} empresaIdProps = {empresaVisualizar} />

          {setTimeout(() => {
            setBandRendicion(false);
          }, 500)}
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

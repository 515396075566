import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { AuthContext } from "../../context/AuthContext";
import { AlertContext } from "../../context/AlertContext";
import { Link, Redirect } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import Spinner from "./Spinner";
import { styled } from "@mui/material/styles";
import { Navigate, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { saveAs } from "file-saver";
import Menu from "./Menu";
import { Dialog } from "primereact/dialog";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { InputNumber } from "primereact/inputnumber";

export default function ConfiguracionesMP(props) {
  //const classes = useStyles();

  const dias = [
    { label: "1", id: 1 },
    { label: "2", id: 2 },
    { label: "3", id: 3 },
    { label: "4", id: 4 },
    { label: "5", id: 5 },
    { label: "6", id: 6 },
    { label: "7", id: 7 },
    { label: "8", id: 8 },
    { label: "9", id: 9 },
    { label: "10", id: 10 },
    { label: "11", id: 11 },
    { label: "12", id: 12 },
    { label: "13", id: 13 },
    { label: "14", id: 14 },
    { label: "15", id: 15 },
    { label: "16", id: 16 },
    { label: "17", id: 17 },
    { label: "18", id: 18 },
    { label: "19", id: 19 },
    { label: "20", id: 20 },
    { label: "21", id: 21 },
    { label: "22", id: 22 },
    { label: "23", id: 23 },
    { label: "24", id: 24 },
    { label: "25", id: 25 },
    { label: "26", id: 26 },
    { label: "27", id: 27 },
    { label: "28", id: 28 },
    { label: "29", id: 29 },
    { label: "30", id: 30 },
    { label: "31", id: 31 },
  ];
  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Fecha", dataKey: "fecha" },
    { title: "Valor", dataKey: "valor" },
  ];

  const [visibleMP, setVisibleMP] = useState(false);
  const [visibleCotizacion, setVisibleCotizacion] = useState(false);
  const [visibleCotizacionActual, setVisibleCotizacionActual] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [cotizacion, guardarCotizacion] = useState(null);

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    statusAcceso2,
    configuracion,
    cotizaciones,
    obtenerAcceso,
    obtenerAcceso2,
    diasEnvios,
    moverSabado,
    moverDomingo,
    actualizarConfiguracion,
    obtenerConfiguracion,
    guardarConfiguracion,
    obtenerDiasEnviosLinks,
    actualizarDiasEnviosLinks,
    guardarDiasEnvios,
    guardarMoverSabado,
    guardarMoverDomingo,
    obtenerCotizaciones,
  } = configuracionContext;

  const toast = useRef(null);
  let navigate = useNavigate();

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "Configuraciones",
      });
      obtenerAcceso2({
        usuarioId: state.usuario.id,
        layout: "LinksMP",
      });
    }
  }, [state.id]);

  useEffect(() => {
    //obtenerConfiguracion();
    obtenerDiasEnviosLinks();
  }, []);

  const aceptarClickMP = (e) => {
    e.preventDefault();

    //console.log(diaEnvio)

    if ((moverSabado || moverDomingo) && diasEnvios.length === 0) {
      mensajeAlerta(
        "Configuración",
        "Debe indicar por lo menos un día de envío !.",
        "error"
      );
      return;
    }

    actualizarDiasEnviosLinks({ diasEnvios, moverSabado, moverDomingo });
  };

  const clickMp = (e) => {
    setVisibleMP(true);
  };

  const onHideMP = (e) => {
    setVisibleMP(false);
  };


  const exitClick = (e) => {
    navigate(`/`);
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Configuración",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, cotizaciones);
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(cotizaciones);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "cotizaciones");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Cotizaciones de dolar"></Tag>
      </div>
    </Fragment>
  );

  const renderHeaderMP = () => {
    return (
      <div>
        <Tag value="Configuración de Links de Mercado Pago"></Tag>
      </div>
    );
  };

  const renderFooterMP = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClickMP}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideMP}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  //console.log(cotizacion);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      <Fragment>
        {statusAcceso === 0 ? <Spinner /> : null}
        {statusAcceso === 400 ? accesoDenegado() : null}
        {statusAcceso === 200 ? (
          <Fragment>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ align: "center", margin: "1em" }}
            >
              <Button
                icon="fa-regular fa-handshake"
                tooltip="Mercado Pago"
                className="p-button-rounded p-button-help"
                style={{ marginLeft: "0em" }}
                disabled={statusAcceso2 !== 200}
                onClick={clickMp}
              />
              <Button
                icon="pi pi-times"
                tooltip="Cerrar"
                className="p-button-rounded p-button-primary"
                style={{ marginLeft: "1em" }}
                onClick={exitClick}
              />
            </Grid>

            <Dialog
              header={renderHeaderMP()}
              visible={visibleMP}
              closable={false}
              footer={renderFooterMP()}
              breakpoints={{ "960px": "75vw" }}
              style={{ width: "70vw", paddingBottom: "0" }}
              position="top"
            >
              <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ paddingLeft: "1vw", marginTop: "1vw" }}
                >
                  <label htmlFor="id">Días de envío de links</label>

                  <MultiSelect
                    value={diasEnvios}
                    onChange={(e) => guardarDiasEnvios(e.value)}
                    options={dias ? dias : null}
                    optionLabel="label"
                    placeholder="Seleccione los días"
                    maxSelectedLabels={10}
                    display="chip"
                    style={{ marginLeft: "1vw" }}
                    //className="w-full md:w-40rem"
                  />
                </Grid>

                <Grid item xs={12} md={12}></Grid>
              </Grid>
            </Dialog>

          </Fragment>
        ) : null}
      </Fragment>
    </Fragment>
  );
}

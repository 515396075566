import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputNumber } from "primereact/inputnumber";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { NumeroLetras } from "./util/NumeroaLetras";

const estadosOrdenes = [
  { id: 1, nombre: "PENDIENTE" },
  { id: 2, nombre: "AUTORIZADA" },
  { id: 3, nombre: "ANULADA" },
  { id: 4, nombre: "FINALIZADA" },
];

export default function OrdenesPagos(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowCheques, setSelectedRowCheques] = useState(null);
  const [selectedRowCompensaciones, setSelectedRowCompensaciones] =
    useState(null);

  let navigate = useNavigate();

  const { origen } = useParams();

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Descripción", dataKey: "descripcion" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [orden, guardarOrden] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleCotizacion, setVisibleCotizacion] = useState(false);
  const [detalle, guardarDetalle] = useState(null);
  const [fecha, guardarFecha] = useState(null);
  const [empresaTodas, setEmpresaTodas] = useState(true);
  const [rowClick, setRowClick] = useState(true);
  const [seleccionar, setSeleccionar] = useState(false);
  const [botonesPagos, setBotonesPagos] = useState(false);
  const [totalAPagar, guardarTotalAPagar] = useState(null);
  const [totalOrden, guardarTotalOrden] = useState(null);
  const [visibleCaja, setVisibleCaja] = useState(false);
  const [valoresCajas, guardarValoresCajas] = useState(0);
  const [totalCajas, guardarTotalCajas] = useState(0);
  const [visibleCheques, setVisibleCheques] = useState(false);
  const [totalCheques, guardarTotalCheques] = useState(0);
  const [visibleCompensaciones, setVisibleCompensaciones] = useState(false);
  const [totalCompensaciones, guardarTotalCompensaciones] = useState(0);
  const [visibleBancos, setVisibleBancos] = useState(false);
  const [valoresBancos, guardarValoresBancos] = useState(0);
  const [totalBancos, guardarTotalBancos] = useState(0);
  const [cuenta, guardarCuenta] = useState(null);
  const [cuentaFinal, guardarCuentaFinal] = useState(null);
  const [visibleResumen, setVisibleResumen] = useState(false);
  const [visibleResumenPagos, setVisibleResumenPagos] = useState(false);
  const [dataResumen, guardarDataResumen] = useState([]);
  const [cotizacion, guardarCotizacion] = useState(null);
  const [cotizaciones, guardarCotizaciones] = useState([]);
  const [moneda, guardarMoneda] = useState(null);
  const [efectivoMonedas, guardarEfectivoMonedas] = useState([]);

  const toast = useRef(null);
  const toastL = useRef(null);

  //const isSelectable = (data) => data.proveedores.nombre_razonsocial === "EMME S.A.";
  //const isRowSelectable = (event) => (event.data ? isSelectable(event.data) : true);
  //const rowClassName = (data) => (isSelectable(data)  ? '' : 'p-disabled');
  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    ordenes,
    comprobantes,
    empresaElegida,
    monedaPredeterminada,
    monedas,
    movimientosCheques,
    movimientosCompensaciones,
    estadoOrden,
    cuentas,
    formaPagoProveedor,
    obtenerOrdenesPagos,
    obtenerAcceso,
    agregarOrden,
    actualizarOrdenPago,
    confirmarAutorizarOrden,
    confirmarAnularOrden,
    confirmarPendienteOrden,
    guardarEstadoOrden,
    obtenerMovimientosCheques,
    obtenerMovimientosCompensacionesProveedor,
    obtenerCuentas,
    pagarOrdenes,
    obtenerComprobantesOrden,
    obtenerMonedas,
    obtenerOrdenesPagosSeleccionadas,
    obtenerFormaPagoProveedor,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      if (origen === "compras") {
        obtenerAcceso({
          usuarioId: state.usuario.id,
          layout: "OrdenesPagosCompras",
        });
      }
      if (origen === "tesoreria") {
        obtenerAcceso({
          usuarioId: state.usuario.id,
          layout: "OrdenesPagosTesoreria",
        });
      }
    }
  }, [state]);

  useEffect(() => {
    if (origen === "compras") {
      obtenerOrdenesPagos({
        estado: estadoOrden,
        empresaId: null,
        empresaTodas,
      });
    } else {
      guardarEstadoOrden("AUTORIZADA");

      obtenerOrdenesPagos({
        estado: "AUTORIZADA",
        empresaId: null,
        empresaTodas,
      });
      obtenerCuentas();
      obtenerMonedas();
    }
  }, []);

  const editClick = (e) => {

    if (selectedRow) {

      //guardarComprobante(selectedRow);
      navigate(
        `/ordenpago/${selectedRow.id}/${selectedRow.id_proveedor}/${origen}`
      );

      //setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Visualizar",
        detail: "Debe seleccionar una órden de pago.",
        life: 3000,
      });
    }
  };

  const mensajeErrorProveedores = () => {
    toast.current.show({
      severity: "error",
      summary: "Ordenes de Pago",
      detail: "No se puede seleccionar proveedores diferentes.",
      life: 3000,
    });
  };

  const mensajeErrorEmpresas = () => {
    toast.current.show({
      severity: "error",
      summary: "Ordenes de Pago",
      detail: "No se puede seleccionar empresas diferentes.",
      life: 3000,
    });
  };

  const mensajeErrorMonedas = () => {
    toast.current.show({
      severity: "error",
      summary: "Ordenes de Pago",
      //detail: "No se puede seleccionar monedas diferentes.",
      detail: "Por el momento no se puede seleccionar monedas extranjeras.",
      life: 3000,
    });
  };

  const mensajeErrorCotizacion = () => {
    toast.current.show({
      severity: "error",
      summary: "Ordenes de Pago",
      detail: "Debe primero ingresar la cotización.",
      life: 3000,
    });
  };

  const addClick = (e) => {
    navigate("/ordenpago/0/0/" + origen);
  };

  const autorizarClick = () => {
    if (!selectedRow) {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar una órden de pago.",
        life: 3000,
      });
      return
    }
    confirmDialog({
      message:
        "Desea autorizar la órden de pago " +
        selectedRow.id_orden +
        " de " +
        selectedRow.monedas.simbolo +
        " " +
        selectedRow.total +
        " ?.",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      rejectLabel: "No",
      acceptLabel: "Si",
      accept: () => acceptAutorizar(),
      //reject: () => (),
    });
  };

  const acceptAutorizar = () => {
    confirmarAutorizarOrden({ ordenId: selectedRow.id });
    setTimeout(() => {
      obtenerOrdenesPagos({
        estado: estadoOrden,
        empresaId: null,
        empresaTodas,
      });
      setSelectedRow(null);
    }, 1000);
  };

  const pendienteClick = () => {
    if (!selectedRow) {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar una órden de pago.",
        life: 3000,
      });
    }
    confirmDialog({
      message:
        "Desea volver a pendiente la órden de pago " +
        selectedRow.id_orden +
        " de " +
        selectedRow.monedas.simbolo +
        " " +
        selectedRow.total +
        " ?.",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      rejectLabel: "No",
      acceptLabel: "Si",
      accept: () => acceptPendiente(),
      //reject: () => (),
    });
  };

  const acceptPendiente = () => {
    confirmarPendienteOrden({ ordenId: selectedRow.id });
    setTimeout(() => {
      obtenerOrdenesPagos({
        estado: estadoOrden,
        empresaId: empresaElegida.id,
        empresaTodas,
      });
      setSelectedRow(null);
    }, 1000);
  };

  const imprimirClick = () => {
    if (!selectedRow) {
      toast.current.show({
        severity: "info",
        summary: "Imprimir",
        detail: "Debe seleccionar una órden de pago.",
        life: 3000,
      });
    }
    obtenerComprobantesOrden({ id: selectedRow.id });
    let elem = document.getElementById("imprimirOrden");
    if (elem) {
      setTimeout(() => {
        elem.click();
      }, 2000);
    }

    //console.log(visibleCotizacion);
    //console.log(comprobantes);
    //console.log(selectedRow)
  };

  const anularClick = () => {
    if (!selectedRow) {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar una órden de pago.",
        life: 3000,
      });
    }
    confirmDialog({
      message:
        "Desea anular la orden de pago " +
        selectedRow.id_orden +
        " de " +
        selectedRow.monedas.simbolo +
        " " +
        selectedRow.total +
        " ?.",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      rejectLabel: "No",
      acceptLabel: "Si",
      accept: () => acceptAnular(),
      //reject: () => rejectOrden(),
    });
  };

  const acceptAnular = () => {
    confirmarAnularOrden({ ordenId: selectedRow.id });
    setTimeout(() => {
      obtenerOrdenesPagos({
        estado: estadoOrden,
        empresaId: empresaElegida.id,
        empresaTodas,
      });
    }, 1000);
  };

  const exitClick = (e) => {
    //setSeleccionar(false);
    //setBotonesPagos(false);
    //setSelectedRow(null);
    navigate("/");
  };

  const aceptarPago = (e) => {
    let total = 0;

    total =
      Number(totalCajas) +
      Number(totalCheques) +
      Number(totalCompensaciones) +
      Number(totalBancos);

    if (Number(total) === 0 && !botonesPagos) {
      toast.current.show({
        severity: "warn",
        summary: "Ordenes de Pago",
        detail: "No hay valores cargados. Debe hacer click en el botón Pagar !",
        life: 3000,
      });
      return;
    }

    if (Number(total) === 0 && botonesPagos) {
      toast.current.show({
        severity: "warn",
        summary: "Ordenes de Pago",
        detail: "No hay valores cargados. !",
        life: 3000,
      });
      return;
    }

    if (
      parseFloat(Number(totalAPagar)).toFixed(2) !==
      parseFloat(Number(total)).toFixed(2)
    ) {
      toast.current.show({
        severity: "warn",
        summary: "Ordenes de Pago",
        detail:
          "El importe a pagar no coincide con el total de valores cargados. !",
        life: 3000,
      });
      return;
    }

    let data = [];
    let id = 0;

    if (Number(totalCajas > 0)) {
      for (var i = 0; i < efectivoMonedas.length; i++) {
        id = id + 1;
        data.push({
          id,
          valor: "EFECTIVO",
          moneda: efectivoMonedas[i].moneda.moneda,
          cotizacion: efectivoMonedas[i].cotizacion
            ? efectivoMonedas[i].cotizacion.cotizacion
            : null,
          importe: parseFloat(efectivoMonedas[i].importe).toFixed(2),
          detalleBanco: null,
          numero: null,
          detalleCuenta: null,
        });
      }
    }

    if (Number(totalCheques > 0)) {
      for (var i = 0; i < selectedRowCheques.length; i++) {
        id = id + 1;
        data.push({
          id,
          valor: "CHEQUE",
          moneda: selectedRowCheques[i].moneda,
          cotizacion: selectedRowCheques[i].cotizacion,
          importe: parseFloat(selectedRowCheques[i].importe).toFixed(2),
          detalleBanco: selectedRowCheques[i].detalleBanco,
          numero: selectedRowCheques[i].numero,
          detalleCuenta: null,
        });
      }
    }

    if (Number(totalCompensaciones > 0)) {
      id = id + 1;

      let mon = monedas.findIndex((item) => item.id === monedaPredeterminada);

      data.push({
        id,
        valor: "COMPENSACIONES",
        moneda: monedas[mon].nombre,
        cotizacion: null,
        importe: parseFloat(totalCompensaciones).toFixed(2),
        detalleBanco: null,
        numero: null,
        detalleCuenta: null,
      });
    }

    if (Number(valoresBancos > 0)) {
      id = id + 1;

      let mon = monedas.findIndex((item) => item.id === cuenta.id_moneda);

      data.push({
        id,
        valor: "BANCOS",
        moneda: monedas[mon].nombre,
        cotizacion: cuenta.cotizacion ? cuenta.cotizacion.cotizacion : null,
        importe: parseFloat(valoresBancos).toFixed(2),
        detalleBanco: null,
        numero: null,
        detalleCuenta: cuenta.cuenta,
      });
    }

    guardarDataResumen(data);
    setVisibleResumen(true);
  };

  const aceptarClickPago = (e) => {
    setVisibleResumen(false);

    pagarOrdenes({
      selectedRow,
      totalAPagar,
      totalCajas,
      totalCheques,
      totalCompensaciones,
      totalBancos,
      cuentaFinal: cuenta,
      efectivoMonedas,
      selectedRowCheques,
      selectedRowCompensaciones,
      monedaPredeterminada,
      usuario: state.usuario.usuario,
    });

    returnClick();
    setTimeout(() => {
      obtenerOrdenesPagos({
        estado: estadoOrden,
        empresaId: empresaElegida.id,
        empresaTodas,
      });
    }, 1000);
  };

  const returnClick = (e) => {
    setSeleccionar(false);
    setBotonesPagos(false);
    setSelectedRow(null);
    setSelectedRowCheques(null);
    setSelectedRowCompensaciones(null);
    guardarValoresCajas(0);
    guardarTotalCajas(0);
    guardarTotalCheques(0);
    guardarTotalCompensaciones(0);
    guardarValoresBancos(0);
    guardarTotalBancos(0);
    guardarTotalAPagar(0);
    guardarCuenta(null);
    guardarCuentaFinal(null);

    setTimeout(() => {
      obtenerOrdenesPagos({
        estado: estadoOrden,
        empresaId: empresaElegida.id,
        empresaTodas,
      });
    }, 1000);
  };


  const formaPagoClick = (e) => {

    if (!selectedRow) {
      toast.current.show({
        severity: "info",
        summary: "Forma de Pago",
        detail: "Debe seleccionar una órden de pago.",
        life: 3000,
      });
      return;
    }

    obtenerFormaPagoProveedor({ordenId: selectedRow.id})
    setTimeout(() => {
      console.log('si')
      setVisibleResumenPagos(true)
    }, 1000);

  }


  const cotizacionClick = (e) => {
    if (monedaPredeterminada === selectedRow.id_moneda) {
      toast.current.show({
        severity: "error",
        summary: "Información",
        detail: "No necesita cargar cotización. !",
        life: 3000,
      });
      return;
    }

    let moneda = monedas.findIndex((item) => item.id === selectedRow.id_moneda);

    let cotiza = [];
    cotiza.push({
      id: "1",
      nombre: "Compra",
      cotizacion: monedas[moneda].compra,
    });
    cotiza.push({
      id: "2",
      nombre: "Venta",
      cotizacion: monedas[moneda].venta,
    });
    cotiza.push({
      id: "3",
      nombre: "Tarjeta",
      cotizacion: monedas[moneda].tarjeta,
    });
    guardarCotizaciones(cotiza);

    setVisibleCotizacion(true);
  };

  const pagarClick = (e) => {
    let proveed = selectedRow[0].id_proveedor;
    let empresa = selectedRow[0].empresa;
    let total = 0;
    for (var i = 0; i < selectedRow.length; i++) {
      if (selectedRow[i].id_proveedor !== proveed) {
        toast.current.show({
          severity: "error",
          summary: "Información",
          detail: "No puede seleccionar proveedores diferentes. !",
          life: 3000,
        });
        return;
      }
      if (selectedRow[i].empresa !== empresa) {
        toast.current.show({
          severity: "error",
          summary: "Información",
          detail: "No puede seleccionar empresas diferentes. !",
          life: 3000,
        });
        return;
      }

      if (selectedRow[i].id_moneda !== monedaPredeterminada) {
        total =
          Number(total) +
          Number(selectedRow[i].saldo) * Number(selectedRow[i].cotizacion);
      } else {
        total = Number(total) + Number(selectedRow[i].saldo);
      }
    }

    obtenerOrdenesPagosSeleccionadas({ selectedRow });

    toast.current.show({
      severity: "info",
      sticky: true,
      content: (
        <div
          className="p-flex p-flex-column"
          style={{ flex: "1", marginLeft: "1em" }}
        >
          <div className="p-text-center">
            <i
              className="pi pi-exclamation-triangle"
              style={{ fontSize: "3rem" }}
            ></i>
            <h4>{`Aquí pagará la órden completa.`}</h4>
          </div>
          <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
            <div className="p-col-6">
              <Button
                type="button"
                label="Aceptar"
                className="p-button-success"
                onClick={(e) => pagoOrden(total, total)}
              />
            </div>
            <div className="p-col-6">
              <Button
                type="button"
                label="Cancelar"
                className="p-button-warning"
                onClick={(e) => {
                  setSelectedRow(null);
                  opcionNo();
                  setTimeout(() => {
                    obtenerOrdenesPagos({
                      estado: estadoOrden,
                      empresaId: empresaElegida.id,
                      empresaTodas,
                    });
                  }, 1000);
                }}
              />
            </div>
            {/*}
            <div className="p-col-6">
              <Button
                type="Parcial"
                label="Parcial"
                className="p-button-warning"
                onClick={(e) => {
                  opcionParcial(total);
                }}
              />
            </div>
            */}
          </div>
        </div>
      ),
    });
  };

  const opcionParcial = (total) => {
    let importe = "";
    toast.current.show({
      severity: "info",
      sticky: true,
      content: (
        <div
          className="p-flex p-flex-column"
          style={{ flex: "1", marginLeft: "1em" }}
        >
          <div className="p-text-center">
            <i
              className="pi pi-exclamation-triangle"
              style={{ fontSize: "3rem" }}
            ></i>
            <h4>{`Importe a pagar`}</h4>

            <Grid item xs={12} md={12}>
              <InputNumber
                inputId="importe"
                value={importe}
                onChange={(e) => {
                  if (e.value) {
                    importe = e.value;
                  } else {
                    importe = "";
                  }
                }}
                size={12}
                mode="decimal"
                locale="en-US"
                minFractionDigits={2}
                style={{
                  marginLeft: "0.7vw",
                  marginTop: "0.8vw",
                }}
              />
            </Grid>
          </div>
          <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
            <div className="p-col-6">
              <Button
                type="button"
                label="Aceptar"
                className="p-button-success"
                onClick={(e) => {
                  pagoOrden(total, importe);
                }}
              />
            </div>
            <div className="p-col-6">
              <Button
                type="Parcial"
                label="Cancelar"
                className="p-button-danger"
                onClick={(e) => opcionNo()}
              />
            </div>
          </div>
        </div>
      ),
    });
  };

  const pagoOrden = (total, parcial) => {
    if (parcial > total) {
      toastL.current.show({
        severity: "error",
        summary: "Información",
        detail: "No puede hacer un pago parcial mayor que el total. !",
        life: 3000,
      });
      return;
    }

    if (parcial < total && parcial <= 0) {
      toastL.current.show({
        severity: "error",
        summary: "Información",
        detail:
          "El pago parcial debe ser mayor que cero y menor que el total. !",
        life: 3000,
      });
      return;
    }

    guardarTotalAPagar(parcial);
    guardarTotalOrden(total);
    guardarValoresCajas(0);
    guardarTotalCajas(0);

    setBotonesPagos(true);
    opcionNo();
  };

  function opcionNo() {
    toast.current.clear();
    toastL.current.clear();
  }

  const onHide = (e) => {
    setVisible(false);
  };

  const onHideCotizacion = (e) => {
    setVisibleCotizacion(false);
  };

  const onHideResumen = (e) => {
    setVisibleResumen(false);
  };

  const onHideResumenPagos = (e) => {
    setVisibleResumenPagos(false);
  };

  const onHideCaja = (e) => {
    setVisibleCaja(false);
  };

  const onHideCheques = (e) => {
    setVisibleCheques(false);
  };

  const onHideCompensaciones = (e) => {
    setVisibleCompensaciones(false);
  };

  const onHideBancos = (e) => {
    setVisibleBancos(false);
  };

  const aceptarClickCaja = (e) => {
    if (
      valoresCajas === undefined ||
      valoresCajas === null ||
      valoresCajas < 0
    ) {
      mensajeAlerta(
        "Caja",
        "No puede ingresar un importe menor que cero !.",
        "error"
      );
      return;
    }

    if (!moneda || moneda.id_moneda === null) {
      mensajeAlerta("Caja", "Debe ingresar una moneda !.", "error");
      return;
    }

    if (moneda && moneda.id_moneda !== monedaPredeterminada && !cotizacion) {
      mensajeAlerta("Caja", "Debe ingresar una cotización !.", "error");
      return;
    }

    let cloneArray = [...efectivoMonedas];

    let index = cloneArray.findIndex((item) => item.id === moneda.id_moneda);

    if (index === -1) {
      cloneArray.push({
        id: moneda.id_moneda,
        moneda: moneda,
        importe: valoresCajas,
        cotizacion: cotizacion,
      });
    } else {
      cloneArray[index].id = moneda.id_moneda;
      cloneArray[index].moneda = moneda;
      cloneArray[index].importe = valoresCajas;
      cloneArray[index].cotizacion = cotizacion;
    }

    guardarEfectivoMonedas(cloneArray);

    let total = 0;
    for (var i = 0; i < cloneArray.length; i++) {
      if (cloneArray[i].moneda.id_moneda !== monedaPredeterminada) {
        total =
          total +
          Number(cloneArray[i].importe) *
            Number(cloneArray[i].cotizacion.cotizacion);
      } else {
        total = total + Number(cloneArray[i].importe);
      }
    }

    guardarTotalCajas(total);
    setVisibleCaja(false);
  };

  const aceptarClickCotizacion = (e) => {
    if (
      !cotizacion ||
      cotizacion.cotizacion === undefined ||
      cotizacion.cotizacion === null ||
      cotizacion.cotizacion === 0 ||
      cotizacion.cotizacion === ""
    ) {
      mensajeAlerta(
        "Orden de Pago",
        "Debe ingresar una cotización !.",
        "error"
      );
      return;
    }

    actualizarOrdenPago({
      id: selectedRow.id,
      cotizacion,
    });

    setTimeout(() => {
      obtenerOrdenesPagos({
        estado: estadoOrden,
        empresaId: empresaElegida.id,
        empresaTodas,
      });
    }, 1000);

    setVisibleCotizacion(false);

    setSelectedRow(null);
  };

  const aceptarClickCheques = (e) => {
    let total = 0;

    if (
      selectedRowCheques &&
      selectedRowCheques !== null &&
      selectedRowCheques.length > 0
    ) {
      let cotizacion = new Array(monedas.length);
      cotizacion.fill(0);

      for (var i = 0; i < selectedRowCheques.length; i++) {
        if (
          selectedRowCheques[i].id_moneda !== monedaPredeterminada &&
          !selectedRowCheques[i].cotizacion
        ) {
          mensajeAlerta(
            "Cheques",
            "El cheque numero " +
              selectedRowCheques[i].numero +
              " del banco " +
              selectedRowCheques[i].detalleBanco +
              " por " +
              selectedRowCheques[i].importe +
              " " +
              selectedRowCheques[i].moneda +
              " no tiene cotización !.",
            "error"
          );
          return;
        }
      }

      for (var i = 0; i < selectedRowCheques.length; i++) {
        if (selectedRowCheques[i].id_moneda !== monedaPredeterminada) {
          let mon = monedas.findIndex(
            (item) => item.id === selectedRowCheques[i].id_moneda
          );

          if (Number(selectedRowCheques[i].cotizacion) > cotizacion[mon]) {
            cotizacion[mon] = Number(selectedRowCheques[i].cotizacion);
          }
        }
      }

      for (var i = 0; i < selectedRowCheques.length; i++) {
        if (selectedRowCheques[i].id_moneda !== monedaPredeterminada) {
          let mon = monedas.findIndex(
            (item) => item.id === selectedRowCheques[i].id_moneda
          );

          if (
            Number(selectedRowCheques[i].cotizacion) !== Number(cotizacion[mon])
          ) {
            mensajeAlerta(
              "Cheques",
              "Hay cheques en " +
                selectedRowCheques[i].moneda +
                " con diferente cotización !.",
              "error"
            );
            return;
          }
        }
      }

      let cheques = new Array(monedas.length);
      cheques.fill(0);

      for (var i = 0; i < selectedRowCheques.length; i++) {
        let mon = monedas.findIndex(
          (item) => item.id === selectedRowCheques[i].id_moneda
        );
        cheques[mon] = cheques[mon] + Number(selectedRowCheques[i].importe);
      }

      total = 0;
      for (var i = 0; i < cheques.length; i++) {
        if (Number(cheques[i]) > 0) {
          if (!monedas[i].predeterminado) {
            total = total + Number(cheques[i]) * Number(cotizacion[i]);
          } else {
            total = total + Number(cheques[i]);
          }
        }
      }
    }

    guardarTotalCheques(total);
    setVisibleCheques(false);
  };

  const aceptarClickCompensaciones = (e) => {
    let total = 0;
    if (
      selectedRowCompensaciones &&
      selectedRowCompensaciones !== null &&
      selectedRowCompensaciones.length > 0
    ) {
      for (var i = 0; i < selectedRowCompensaciones.length; i++) {
        total = Number(total) + Number(selectedRowCompensaciones[i].importe);
      }
    }
    guardarTotalCompensaciones(total);
    setVisibleCompensaciones(false);
  };

  const aceptarClickBancos = (e) => {
    if (Number(valoresBancos) >= 0) {
      if (
        !cuenta ||
        cuenta.id_cuenta === undefined ||
        cuenta.id_cuenta === null ||
        cuenta.id_cuenta === ""
      ) {
        mensajeAlerta("Bancos", "La cuenta es necesaria !.", "error");
        return;
      }
    }

    if (Number(valoresBancos) < 0) {
      mensajeAlerta(
        "Bancos",
        "el importe debe ser mayor o igual a cero !.",
        "error"
      );
      return;
    }

    if (!moneda || moneda.id_moneda === null) {
      mensajeAlerta("Bancos", "Debe ingresar una moneda !.", "error");
      return;
    }

    if (moneda && moneda.id_moneda !== monedaPredeterminada && !cotizacion) {
      mensajeAlerta("Bancos", "Debe ingresar una cotización !.", "error");
      return;
    }

    if (moneda && moneda.id_moneda !== cuenta.id_moneda) {
      mensajeAlerta(
        "Bancos",
        "La moneda no coincide con la cuenta !.",
        "error"
      );
      return;
    }

    let total = 0;
    if (moneda && moneda.id_moneda !== monedaPredeterminada) {
      total = Number(valoresBancos) * Number(cotizacion.cotizacion);
    } else {
      total = Number(valoresBancos);
    }

    /*
    if (Number(valoresBancos) === 0) {
      guardarCuenta(null);
      guardarCuentaFinal(null);
      guardarValoresBancos(0);
      guardarTotalBancos(0);
      setVisibleBancos(false);
      return;
    }
    */

    guardarTotalBancos(total);
    //guardarCuentaFinal(cuenta);
    setVisibleBancos(false);
  };

  const calcularTotal = () => {
    let total = 0;
    if (selectedRow && selectedRow !== null && selectedRow.length > 0) {
      for (var i = 0; i < selectedRow.length; i++) {
        if (selectedRow[i].id_moneda !== monedaPredeterminada) {
          total =
            Number(total) +
            Number(selectedRow[i].saldo) * Number(selectedRow[i].cotizacion);
        } else {
          total = Number(total) + Number(selectedRow[i].saldo);
        }
      }
    }
    return total;
  };

  const calcularTotalCheques = () => {
    let total = 0;
    if (selectedRowCheques && selectedRowCheques.length > 0) {
      for (var i = 0; i < selectedRowCheques.length; i++) {
        if (Number(selectedRowCheques[i].importe) > 0) {
          if (selectedRowCheques[i].id_moneda !== monedaPredeterminada) {
            total =
              total +
              Number(selectedRowCheques[i].importe) *
                Number(selectedRowCheques[i].cotizacion);
          } else {
            total = total + Number(selectedRowCheques[i].importe);
          }
        }
      }
    }

    return total;
  };

  const calcularTotalCompensaciones = () => {
    let total = 0;
    if (
      selectedRowCompensaciones &&
      selectedRowCompensaciones !== null &&
      selectedRowCompensaciones.length > 0
    ) {
      for (var i = 0; i < selectedRowCompensaciones.length; i++) {
        total = Number(total) + Number(selectedRowCompensaciones[i].importe);
      }
    }
    return total;
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Ordenes",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, ordenes);
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(ordenes);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "ordenes");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const acceptImprimir = (rowData, rowComprobantes) => {
    const doc = new jsPDF();
    let date = new Date();
    let newDate = new Date(date);
    newDate.setHours(date.getHours() - 3);

    let row = 10;
    doc.setFontSize(12);
    doc.text("Orden de Pago Nº: " + rowData.id_orden, 10, row);
    doc.text("Fecha: " + formatDateTime(newDate), 90, row);
    doc.text("Estado: " + rowData.estado.toUpperCase(), 150, row);

    doc.setFontSize(10);
    row = row + 5;
    doc.line(10, row, 195, row);

    row = row + 5;
    doc.text("Empresa: " + rowData.empresas.nombre, 10, row);
    doc.text("Fecha: " + formatDate(rowData.fecha), 90, row);
    doc.text(
      rowData.vencimiento
        ? "Venc.: " + formatDate(rowData.vencimiento)
        : "Venc.:",
      150,
      row
    );

    row = row + 5;
    doc.text("Concepto: " + rowData.conceptos.nombre, 10, row);

    row = row + 5;
    doc.text("Proveedores: " + rowData.proveedores.nombre_razonsocial, 10, row);

    row = row + 5;
    doc.line(10, row, 195, row);

    row = row + 5;
    doc.setFontSize(12);
    doc.text("Comprobantes ", 10, row);
    row = row + 5;
    doc.setFontSize(10);

    for (var i = 0; i < rowComprobantes.length; i++) {
      doc.text(
        `
        ${rowComprobantes[i].comprobante} - ${String(
          rowComprobantes[i].puntoventa
        )} - ${String(rowComprobantes[i].numero)}    ${
          rowComprobantes[i].detalle ? rowComprobantes[i].detalle : ""
        }`,
        10,
        row
      );

      row = row + 4;

      let moneda = monedas.findIndex(
        (item) => item.id === rowComprobantes[i].id_moneda
      );

      doc.text(
        monedas[moneda].simbolo + " " + String(rowComprobantes[i].total),
        170,
        row,
        {
          align: "right",
        }
      );
    }

    row = row + 5;
    if (rowData.id_moneda !== monedaPredeterminada) {
      doc.text("Cotización: $ " + rowData.cotizacion, 10, row);
    }

    row = row + 5;
    doc.line(10, row, 195, row);
    row = row + 5;
    doc.text(
      "SON: " + NumeroLetras(rowData.total, rowData.monedas.nombre),
      10,
      row,
      {
        maxWidth: 120,
        align: "left",
      }
    );

    let moneda = monedas.findIndex((item) => item.id === rowData.id_moneda);

    doc.text(monedas[moneda].simbolo + " " + String(rowData.total), 170, row, {
      align: "right",
    });
    row = row + 5;
    doc.line(10, row, 195, row);

    row = row + 5;
    doc.setFontSize(12);
    doc.text("Forma de Pago ", 10, row);
    row = row + 5;
    doc.setFontSize(10);
    doc.text("Efectivo: _________________", 10, row);
    doc.text("Compensaciones: _________________", 70, row);
    row = row + 5;
    doc.text("Banco: _________________", 10, row);
    doc.text(
      "Cuenta Banco: ___________________________________________",
      70,
      row
    );
    row = row + 5;
    doc.text(
      "Cheques: ______________________________________________________________________________",
      10,
      row
    );
    row = row + 5;
    doc.text(
      "Cheques: ______________________________________________________________________________",
      10,
      row
    );
    row = row + 10;
    doc.text("Confeccionó: _________________", 10, row);
    doc.text("Intervino el : ___/___/___", 80, row);
    doc.text("Autorizó el : ___/___/___", 140, row);
    row = row + 10;
    doc.text("Firma: ", 10, row);
    doc.text("Firma: ", 80, row);
    doc.text("Firma: ", 140, row);

    window.open(doc.output("bloburl"));
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Ordenes de Pago"></Tag>
      </div>
    </Fragment>
  );

  const header2 = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
      </div>
    </Fragment>
  );

  function formatDateTime(dateString) {
    var allDate = dateString.toISOString().replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  function formatDate(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  const fechaTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fecha)}</Fragment>;
  };

  const vencimientoTemplate = (rowData) => {
    return (
      <Fragment>
        {rowData.vencimiento ? formatDate(rowData.vencimiento) : null}
      </Fragment>
    );
  };

  const estadoTemplate = (rowData) => {
    return <Fragment>{rowData.estado.toUpperCase()}</Fragment>;
  };

  const monedaTemplate = (rowData) => {
    return (
      <Fragment>
        {rowData.monedas.nombre + " (" + rowData.monedas.simbolo + ")"}
      </Fragment>
    );
  };

  const fechaOperacionTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fecha)}</Fragment>;
  };

  const fechaEmisionTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.emision)}</Fragment>;
  };

  const fechaDepositoTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.deposito)}</Fragment>;
  };

  const importeTemplate = (rowData) => {
    return <Fragment>{parseFloat(rowData.importe).toFixed(2)}</Fragment>;
  };

  const eCheqTemplate = (rowData) => {
    return (
      <Fragment>
        {" "}
        <Checkbox checked={rowData.eCheq}></Checkbox>
      </Fragment>
    );
  };

  const NOTemplate = (rowData) => {
    return (
      <Fragment>
        {" "}
        <Checkbox checked={rowData.noALaOrden}></Checkbox>
      </Fragment>
    );
  };

  const renderHeaderCotizacion = () => {
    return (
      <div>
        <Tag value="Cotizaciones de monedas"></Tag>
      </div>
    );
  };

  const renderFooterCotizacion = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClickCotizacion}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideCotizacion}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderHeaderCaja = () => {
    return (
      <div>
        <Tag value="Caja"></Tag>
      </div>
    );
  };

  const renderFooterCaja = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClickCaja}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideCaja}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderHeaderCheques = () => {
    return (
      <div>
        <Tag value="Cartera de Cheques"></Tag>
      </div>
    );
  };

  const renderFooterCheques = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClickCheques}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideCheques}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderHeaderCompensaciones = () => {
    return (
      <div>
        <Tag value="Compensaciones Pendientes"></Tag>
      </div>
    );
  };

  const renderFooterCompensaciones = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClickCompensaciones}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideCompensaciones}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderHeaderBancos = () => {
    return (
      <div>
        <Tag value="Bancos"></Tag>
      </div>
    );
  };

  const renderFooterBancos = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClickBancos}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideBancos}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderHeaderResumen = () => {
    return (
      <div>
        <Tag value="Resumen de Valores"></Tag>
      </div>
    );
  };

  const renderFooterResumen = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClickPago}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideResumen}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderHeaderResumenPagos = () => {
    return (
      <div>
        <Tag value={selectedRow ? "Forma de Pago Orden " + selectedRow.id_orden :  "Forma de Pago"}></Tag>
      </div>
    );
  };

  const renderFooterResumenPagos = () => {
    return (
      <div>
        <ButtonMui
          onClick={onHideResumenPagos}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Salir
        </ButtonMui>
      </div>
    );
  };


  const tagValoresCaja = () => {
    let array = [...efectivoMonedas];

    let efectivo = new Array(monedas.length);
    efectivo.fill(0);

    let cotizacion = new Array(monedas.length);
    cotizacion.fill(0);

    for (var i = 0; i < array.length; i++) {
      let mon = monedas.findIndex((item) => item.id === array[i].id);
      efectivo[mon] = Number(efectivo[mon]) + Number(array[i].importe);
      if (array[i].cotizacion) {
        cotizacion[mon] = Number(array[i].cotizacion.cotizacion);
      }
    }

    //console.log(cotizacion)

    let row = "";
    let j = 0;
    for (var i = 0; i < efectivo.length; i++) {
      if (efectivo[i] !== 0) {
        j = j + 1;
        if (j > 1) {
          row = row + " - ";
        }
        row =
          row +
          monedas[i].simbolo +
          " " +
          efectivo[i].toLocaleString("es-ar", {
            minimumFractionDigits: 2,
          }) +
          (cotizacion[i] > 0
            ? " x $ " +
              cotizacion[i].toLocaleString("es-ar", {
                minimumFractionDigits: 2,
              })
            : "");
      }
    }
    return row;
  };

  const tagValoresCheques = () => {
    let array = [];
    let row = "";

    if (selectedRowCheques) {
      array = [...selectedRowCheques];

      let cheques = new Array(monedas.length);
      cheques.fill(0);

      let cotizacion = new Array(monedas.length);
      cotizacion.fill(0);

      for (var i = 0; i < array.length; i++) {
        let mon = monedas.findIndex((item) => item.id === array[i].id_moneda);
        cheques[mon] = Number(cheques[mon]) + Number(array[i].importe);
        if (array[i].cotizacion) {
          cotizacion[mon] = Number(array[i].cotizacion);
        }
      }

      row = "";

      let j = 0;
      for (var i = 0; i < cheques.length; i++) {
        if (cheques[i] !== 0) {
          j = j + 1;
          if (j > 1) {
            row = row + " - ";
          }
          row =
            row +
            monedas[i].simbolo +
            " " +
            cheques[i].toLocaleString("es-ar", {
              minimumFractionDigits: 2,
            }) +
            (cotizacion[i] > 0
              ? " x $ " +
                cotizacion[i].toLocaleString("es-ar", {
                  minimumFractionDigits: 2,
                })
              : "");
        }
      }
    }
    return row;
  };

  const tagValoresCompensaciones = () => {
    let total = 0;
    let mon = monedas.findIndex((item) => item.id === monedaPredeterminada);
    let row = "";

    if (selectedRowCompensaciones && selectedRowCompensaciones.length > 0) {
      for (var i = 0; i < selectedRowCompensaciones.length; i++) {
        if (Number(selectedRowCompensaciones[i].importe) !== 0) {
          total = total + Number(selectedRowCompensaciones[i].importe);
        }
      }

      row =
        monedas[mon].simbolo +
        " " +
        total.toLocaleString("es-ar", {
          minimumFractionDigits: 2,
        });
    }

    return row;
  };

  const tagValoresBancos = () => {
    let total = 0;
    let row = "";

    if (Number(valoresBancos > 0 && cuenta && cuenta.id_moneda)) {
      let mon = monedas.findIndex((item) => item.id === cuenta.id_moneda);

      total = Number(valoresBancos);

      if (cuenta.id_moneda !== monedaPredeterminada) {
        row =
          monedas[mon].simbolo +
          " " +
          total.toLocaleString("es-ar", {
            minimumFractionDigits: 2,
          }) +
          (cotizacion && cotizacion.cotizacion > 0
            ? " x $ " +
              cotizacion.cotizacion.toLocaleString("es-ar", {
                minimumFractionDigits: 2,
              })
            : "");
      } else {
        row =
          monedas[mon].simbolo +
          " " +
          total.toLocaleString("es-ar", {
            minimumFractionDigits: 2,
          });
      }
    }
    return row;
  };

  //console.log(formaPagoProveedor);
  //console.log(cuentas)
  //console.log(state);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      <Toast ref={toastL} position="top-left" />
      <ConfirmDialog />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {ordenes ? (
            <Fragment>
              <Grid
                container
                spacing={3}
                style={{ marginTop: "1vw", marginLeft: "1vw" }}
              >
                {origen === "compras" ? (
                  <Button
                    icon="pi pi-plus"
                    tooltip="Agregar"
                    className="p-button-rounded p-button-help"
                    style={{ marginLeft: "0em" }}
                    onClick={addClick}
                  />
                ) : null}
                <Button
                  icon="pi pi-book"
                  tooltip="Visualizar"
                  className="p-button-rounded p-button-danger"
                  disabled={origen === "tesoreria" && seleccionar}
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                {origen === "compras" ? (
                  <Fragment>
                    <Button
                      icon="pi pi-check"
                      tooltip="Autorizar"
                      className="p-button-rounded p-button-success"
                      style={{ marginLeft: "1em" }}
                      disabled={estadoOrden !== "PENDIENTE"}
                      onClick={autorizarClick}
                    />
                    <Button
                      icon="pi pi-replay"
                      tooltip="Volver a Pendiente"
                      className="p-button-rounded p-button-warning"
                      style={{ marginLeft: "1em" }}
                      disabled={estadoOrden !== "AUTORIZADA"}
                      onClick={(e) => pendienteClick(e)}
                    />
                    <Button
                      icon="pi pi-trash"
                      tooltip="Anular"
                      className="p-button-rounded p-button-danger"
                      style={{ marginLeft: "1em" }}
                      disabled={estadoOrden !== "PENDIENTE"}
                      onClick={(e) => anularClick(e)}
                    />
                    <Button
                      icon="fa-solid fa-cart-shopping"
                      tooltip="Forma de Pago"
                      className="p-button-rounded p-button-primary"
                      style={{ marginLeft: "1em" }}
                      disabled={ estadoOrden !== "AUTORIZADA" && estadoOrden !== "FINALIZADA"
                      }
                      onClick={formaPagoClick}
                    />
                  </Fragment>
                ) : null}

                {origen === "tesoreria" ? (
                  <Fragment>
                    <Button
                      icon="pi pi-ellipsis-v"
                      tooltip="Seleccionar"
                      className="p-button-rounded p-button-help"
                      style={{ marginLeft: "1em" }}
                      disabled={
                        Number(totalAPagar) > 0 || estadoOrden !== "AUTORIZADA"
                      }
                      onClick={(e) => {
                        setSeleccionar(true);
                        setSelectedRow(null);
                        guardarValoresCajas(0);
                        guardarEfectivoMonedas([]);
                        setSelectedRowCheques(null);
                        setSelectedRowCompensaciones(null);
                        guardarValoresBancos(0);
                        guardarTotalCajas(0);
                        guardarTotalCheques(0);
                        guardarTotalCompensaciones(0);
                        guardarTotalBancos(0);
                        guardarTotalAPagar(0);
                        guardarCuenta(null);
                        guardarCuentaFinal(null);
                      }}
                    />
                    <Button
                      icon="pi pi-dollar"
                      tooltip="Pagar"
                      className="p-button-rounded p-button-warning"
                      style={{ marginLeft: "1em" }}
                      disabled={
                        !selectedRow || !seleccionar || selectedRow.length === 0
                      }
                      onClick={pagarClick}
                    />
                    <Button
                      icon="fa-solid fa-coins"
                      tooltip="Cotización"
                      className="p-button-rounded p-button-help"
                      style={{ marginLeft: "1em" }}
                      disabled={
                        !selectedRow || seleccionar || selectedRow.length > 0 || estadoOrden !== "AUTORIZADA"
                      }
                      onClick={cotizacionClick}
                    />
                    <Button
                      icon="fa-solid fa-cart-shopping"
                      tooltip="Forma de Pago"
                      className="p-button-rounded p-button-primary"
                      style={{ marginLeft: "1em" }}
                      disabled={ estadoOrden !== "AUTORIZADA" && estadoOrden !== "FINALIZADA"
                      }
                      onClick={formaPagoClick}
                    />
                    <Button
                      icon="pi pi-arrow-left"
                      tooltip="Volver"
                      className="p-button-rounded p-button-success"
                      style={{ marginLeft: "1em" }}
                      onClick={returnClick}
                    />
                    {selectedRow ? (
                      <Dialog
                        header={renderHeaderCotizacion()}
                        visible={visibleCotizacion}
                        closable={false}
                        footer={renderFooterCotizacion()}
                        breakpoints={{ "960px": "75vw" }}
                        style={{ width: "50vw", paddingBottom: "0" }}
                        position="center"
                      >
                        <Grid item xs={12} md={6} style={{ padding: "0.2vw" }}>
                          <Autocomplete
                            id="cotizacion"
                            size={"small"}
                            fullWidth
                            noOptionsText={"No hay opciones"}
                            options={cotizaciones ? cotizaciones : null}
                            getOptionLabel={(option) =>
                              "$ " +
                              option.cotizacion +
                              " (" +
                              option.nombre +
                              ")"
                            }
                            openOnFocus={true}
                            disablePortal
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Cotización *"
                                variant="standard"
                                InputLabelProps={{
                                  style: { color: "red" },
                                }}
                              />
                            )}
                            //onKeyDown={handleEnter}
                            onChange={(event, value) => {
                              if (value) {
                                guardarCotizacion({
                                  ...cotizacion,
                                  cotizacion: value.cotizacion,
                                  cotizacionTipo: value.nombre,
                                });
                              } else {
                                guardarCotizacion({
                                  ...cotizacion,
                                  cotizacion: null,
                                  cotizacionTipo: null,
                                });
                              }
                            }}
                          />
                        </Grid>
                      </Dialog>
                    ) : null}
                  </Fragment>
                ) : null}

                <Button
                  icon="pi pi-print"
                  tooltip="Imprimir"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "1em" }}
                  onClick={(e) => imprimirClick(selectedRow)}
                />
                <button
                  id="imprimirOrden"
                  type="button"
                  onClick={(e) => {
                    acceptImprimir(selectedRow, comprobantes);
                  }}
                  style={{ display: "none" }}
                >
                  Imprimir
                </button>

                <Button
                  id="botonCerrar"
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />

                <Autocomplete
                  id="estado"
                  required
                  noOptionsText={"No hay opciones"}
                  options={estadosOrdenes}
                  defaultValue={
                    origen === "compras" && estadoOrden
                      ? estadosOrdenes[
                          estadosOrdenes.findIndex(
                            (item) => item.nombre === estadoOrden
                          )
                        ]
                      : origen === "tesoreria"
                      ? estadosOrdenes[
                          estadosOrdenes.findIndex(
                            (item) => item.nombre === "AUTORIZADA"
                          )
                        ]
                      : null
                  }
                  getOptionLabel={(option) => option.nombre}
                  sx={{ width: 250, marginLeft: "2vw" }}
                  openOnFocus={true}
                  disablePortal
                  disabled={origen === "tesoreria" && seleccionar}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Estado órdenes de pago"
                      variant="standard"
                    />
                  )}
                  //onKeyDown={handleEnter}
                  onChange={(event, value) => {
                    if (value) {
                      guardarEstadoOrden(value.nombre);
                      setSelectedRow(null)
                      obtenerOrdenesPagos({
                        estado: value.nombre,
                        empresaId: empresaElegida.id,
                        empresaTodas,
                      });
                    } else {
                      guardarEstadoOrden(null);
                    }
                  }}
                />

                <FormControlLabel
                  value="yes"
                  control={
                    <Checkbox
                      color="primary"
                      checked={empresaTodas}
                      style={{ marginLeft: "1vw" }}
                      onChange={(e) => {
                        setEmpresaTodas(e.target.checked);
                        setSelectedRow(null)
                        obtenerOrdenesPagos({
                          estado: estadoOrden,
                          empresaId: empresaElegida.id,
                          empresaTodas: e.target.checked,
                        });
                      }}
                      disabled={origen === "tesoreria" && seleccionar}
                    />
                  }
                  label="Todas las empresas"
                  labelPlacement="start"
                  style={{ paddingLeft: "1vw", paddingTop: "1vw" }}
                />
              </Grid>
              {!seleccionar ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ align: "center", margin: "1em" }}
                >
                  <div className="datatable-responsive-demo">
                    <div className="card">
                      <Tooltip
                        target=".export-buttons>button"
                        position="bottom"
                      />
                      <DataTable
                        value={ordenes}
                        selection={selectedRow}
                        onSelectionChange={(e) => setSelectedRow(e.value)}
                        selectionMode="single"
                        dataKey="id"
                        className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                        style={{ fontSize: "small", marginTop: "1vw" }}
                        globalFilter={globalFilter}
                        emptyMessage="No hay datos."
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                        rows={50}
                        rowsPerPageOptions={[10, 20, 50, 100, 500, 1000]}
                        //paginatorLeft={paginatorLeft}
                        //paginatorRight={paginatorRight}
                      >
                        <Column
                          field="id"
                          header="ID"
                          style={{ display: "none" }}
                          //body={idTemplate}
                        ></Column>
                        <Column
                          field="id_orden"
                          header="ID"
                          //body={idTemplate}
                          sortable
                        ></Column>
                        <Column
                          field="fecha"
                          header="Fecha"
                          body={fechaTemplate}
                          sortable
                        ></Column>
                        <Column
                          field="vencimiento"
                          header="Vencimiento"
                          body={vencimientoTemplate}
                          sortable
                        ></Column>
                        <Column
                          field="total"
                          header="Total"
                          //body={fechaTemplate}
                          sortable
                        ></Column>
                        <Column
                          field="saldo"
                          header="Saldo"
                          //body={fechaTemplate}
                          sortable
                        ></Column>
                        <Column
                          field="empresas.nombre"
                          header="Empresa"
                          //body={fechaTemplate}
                          sortable
                        ></Column>
                        <Column
                          field="estado"
                          header="Estado"
                          body={estadoTemplate}
                          sortable
                        ></Column>
                        <Column
                          field="proveedores.nombre_razonsocial"
                          header="Proveedor"
                          //body={estadoTemplate}
                          sortable
                        ></Column>
                        <Column
                          field="conceptos.nombre"
                          header="Concepto"
                          //body={estadoTemplate}
                          sortable
                        ></Column>
                        <Column
                          field="moneda"
                          header="Moneda"
                          body={monedaTemplate}
                          sortable
                        ></Column>
                        <Column
                          field="cotizacion"
                          header="Cotización"
                          //body={estadoTemplate}
                          sortable
                        ></Column>
                      </DataTable>
                    </div>
                  </div>
                </Grid>
              ) : null}
              {seleccionar ? (
                <Fragment>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ align: "center", margin: "1em" }}
                  >
                    <Grid item xs={12} md={9}>
                      <div className="datatable-responsive-demo">
                        <div className="card">
                          <Tooltip
                            target=".export-buttons>button"
                            position="bottom"
                          />

                          <Fragment>
                            <DataTable
                              value={ordenes}
                              selection={selectedRow}
                              onSelectionChange={(e) => {
                                if (e.value.length > 0) {
                                  let bandProveed = true;
                                  let bandEmpresa = true;
                                  let bandMoneda = true;
                                  let bandCotizacion = true;
                                  let proveed = e.value[0].proveedores.id;
                                  let empresa = e.value[0].empresas.id;
                                  let moneda = e.value[0].id_moneda;
                                  for (var i = 0; i < e.value.length; i++) {
                                    if (proveed !== e.value[i].proveedores.id) {
                                      bandProveed = false;
                                    }
                                    if (empresa !== e.value[i].empresas.id) {
                                      bandEmpresa = false;
                                    }
                                    if (moneda !== e.value[i].id_moneda) {
                                      bandMoneda = false;
                                    }
                                    // Temporal
                                    if (monedaPredeterminada !== e.value[i].id_moneda) {
                                      bandMoneda = false;
                                    }
                                    if (
                                      monedaPredeterminada !==
                                        e.value[i].id_moneda &&
                                      !e.value[i].cotizacion
                                    ) {
                                      bandCotizacion = false;
                                    }
                                  }
                                  if (
                                    bandProveed &&
                                    bandEmpresa &&
                                    bandMoneda &&
                                    bandCotizacion
                                  ) {
                                    setSelectedRow(e.value);
                                  } else {
                                    if (!bandProveed) {
                                      mensajeErrorProveedores();
                                    }
                                    if (!bandEmpresa) {
                                      mensajeErrorEmpresas();
                                    }
                                    if (!bandMoneda) {
                                      mensajeErrorMonedas();
                                    }
                                    if (!bandCotizacion && bandMoneda) {
                                      mensajeErrorCotizacion();
                                    }
                                  }
                                } else {
                                  setSelectedRow(e.value);
                                }
                              }}
                              selectionMode={rowClick ? null : "checkbox"}
                              dataKey="id"
                              //isDataSelectable={isRowSelectable} rowClassName={rowClassName}
                              header={header}
                              className={
                                Number(totalAPagar) > 0
                                  ? "p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo" +
                                    " p-disabled"
                                  : "p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                              }
                              style={{ fontSize: "small", marginTop: "1vw" }}
                              globalFilter={globalFilter}
                              emptyMessage="No hay datos."
                              paginator
                              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                              currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                              rows={50}
                              rowsPerPageOptions={[10, 20, 50, 100, 500, 1000]}
                              //paginatorLeft={paginatorLeft}
                              //paginatorRight={paginatorRight}
                            >
                              <Column
                                selectionMode="multiple"
                                headerStyle={{ width: "3rem" }}
                              ></Column>
                              <Column
                                field="id"
                                header="ID"
                                style={{ display: "none" }}
                                //body={idTemplate}
                              ></Column>
                              <Column
                                field="id_orden"
                                header="ID"
                                //body={idTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="fecha"
                                header="Fecha"
                                body={fechaTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="vencimiento"
                                header="Vencimiento"
                                body={vencimientoTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="total"
                                header="Total"
                                //body={fechaTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="saldo"
                                header="Saldo"
                                //body={fechaTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="empresas.nombre"
                                header="Empresa"
                                //body={fechaTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="proveedores.nombre_razonsocial"
                                header="Proveedor"
                                //body={estadoTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="conceptos.nombre"
                                header="Concepto"
                                //body={estadoTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="moneda"
                                header="Moneda"
                                body={monedaTemplate}
                                sortable
                              ></Column>
                              <Column
                                field="cotizacion"
                                header="Cotización"
                                //body={estadoTemplate}
                                sortable
                              ></Column>
                            </DataTable>
                          </Fragment>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={2.8}>
                      {botonesPagos ? (
                        <Fragment>
                          <ButtonMui
                            onClick={(e) => {
                              let mon = monedas.findIndex(
                                (item) => item.id === monedaPredeterminada
                              );
                              guardarValoresCajas(0);
                              guardarMoneda({
                                id_moneda: monedaPredeterminada,
                                moneda: monedas[mon].nombre,
                              });
                              guardarCotizacion(null);
                              guardarCotizaciones([]);
                              setVisibleCaja(true);
                            }}
                            color="success"
                            type="button"
                            variant="contained"
                            fullWidth
                            //startIcon={<CheckIcon />}
                            style={{
                              //margin: "1vw",
                              marginTop: "1vw",
                              textAlign: "left",
                              float: "left",
                            }}
                          >
                            {"Caja: " + tagValoresCaja()}
                          </ButtonMui>

                          <ButtonMui
                            onClick={(e) => {
                              obtenerMovimientosCheques({
                                empresaTodas: true,
                                empresaId: null,
                                tipo: "todos",
                                estado: "cartera",
                              });
                              setSelectedRowCheques(null);
                              guardarTotalCheques(0);
                              setTimeout(() => {
                                setVisibleCheques(true);
                              }, 500);
                            }}
                            color="primary"
                            type="button"
                            variant="contained"
                            fullWidth
                            //startIcon={<CheckIcon />}
                            style={{
                              //margin: "1vw",
                              marginTop: "0.5vw",
                              textAlign: "left",
                              float: "left",
                            }}
                          >
                            {"Cheques: " + tagValoresCheques()}
                          </ButtonMui>

                          <ButtonMui
                            onClick={(e) => {
                              obtenerMovimientosCompensacionesProveedor({
                                empresaTodas: false,
                                empresaId: selectedRow[0].empresas.id,
                                estado: "PENDIENTE",
                                numeroDocumento:
                                  selectedRow[0].proveedores.numerodocumento,
                              });
                              setSelectedRowCompensaciones(null);
                              guardarTotalCompensaciones(0);
                              setTimeout(() => {
                                setVisibleCompensaciones(true);
                              }, 500);
                            }}
                            color="secondary"
                            type="button"
                            variant="contained"
                            fullWidth
                            //startIcon={<CheckIcon />}
                            style={{
                              //margin: "1vw",
                              marginTop: "0.5vw",
                              textAlign: "left",
                              float: "left",
                            }}
                          >
                            {"Compensaciones: " + tagValoresCompensaciones()}
                          </ButtonMui>
                          <ButtonMui
                            onClick={(e) => {
                              let mon = monedas.findIndex(
                                (item) => item.id === monedaPredeterminada
                              );

                              setEmpresaTodas(false);
                              guardarCuenta(null);
                              guardarMoneda({
                                id_moneda: monedaPredeterminada,
                                moneda: monedas[mon].nombre,
                              });
                              guardarCotizacion(null);
                              guardarCotizaciones([]);
                              guardarValoresBancos(0);
                              guardarTotalBancos(0);
                              setVisibleBancos(true);
                            }}
                            color="warning"
                            type="button"
                            variant="contained"
                            fullWidth
                            //startIcon={<CheckIcon />}
                            style={{
                              //margin: "1vw",
                              marginTop: "0.5vw",
                              textAlign: "left",
                              float: "left",
                            }}
                          >
                            {"Bancos: " + tagValoresBancos()}
                          </ButtonMui>

                          <Tag
                            value={
                              "TOTAL A PAGAR: " +
                              parseFloat(totalAPagar).toFixed(2)
                            }
                            style={{ marginLeft: "1em", marginTop: "1em" }}
                          ></Tag>

                          <Tag
                            value={
                              "TOTAL AGREGADO: " +
                              parseFloat(
                                Number(
                                  totalCajas +
                                    totalCheques +
                                    totalCompensaciones +
                                    totalBancos
                                )
                              ).toFixed(2)
                            }
                            style={{ marginLeft: "1em", marginTop: "1em" }}
                          ></Tag>

                          <Tag
                            value={
                              "PENDIENTE: " +
                              parseFloat(
                                Number(totalAPagar) -
                                  Number(totalCajas) -
                                  Number(totalCheques) -
                                  Number(totalCompensaciones) -
                                  Number(totalBancos)
                              ).toFixed(2)
                            }
                            style={{ marginLeft: "1em", marginTop: "1em" }}
                          ></Tag>
                        </Fragment>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    md={12}
                    style={{ borderStyle: "solid", borderWidth: "1px" }}
                  >
                    {selectedRow ? (
                      <Fragment>
                        <Grid
                          container
                          xs={12}
                          md={12}
                          style={{ borderStyle: "solid", borderWidth: "1px" }}
                        >
                          <Grid item xs={12} md={8}>
                            <Tag
                              value={
                                "TOTAL: " +
                                selectedRow.length +
                                " x " +
                                parseFloat(calcularTotal()).toFixed(2)
                              }
                              style={{ marginLeft: "1em", marginTop: "1em" }}
                            ></Tag>
                          </Grid>

                          {botonesPagos ? (
                            <Fragment>
                              <Grid item xs={12} md={2}>
                                <ButtonMui
                                  onClick={(e) => aceptarPago()}
                                  color="primary"
                                  type="button"
                                  variant="contained"
                                  startIcon={<CheckIcon />}
                                  fullWidth
                                >
                                  Aceptar
                                </ButtonMui>
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <ButtonMui
                                  onClick={returnClick}
                                  color="secondary"
                                  type="button"
                                  variant="contained"
                                  startIcon={<CloseIcon />}
                                  fullWidth
                                  autoFocus
                                >
                                  Cancelar
                                </ButtonMui>
                              </Grid>
                            </Fragment>
                          ) : null}
                        </Grid>
                      </Fragment>
                    ) : null}
                  </Grid>

                  <Dialog
                    header={renderHeaderCaja()}
                    visible={visibleCaja}
                    closable={false}
                    footer={renderFooterCaja()}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "50vw", paddingBottom: "0" }}
                    position="center"
                  >
                    <Fragment>
                      <Grid
                        container
                        xs={12}
                        md={12}
                        style={{
                          borderStyle: "solid",
                          borderWidth: "0.5px",
                          padding: "1vw",
                        }}
                      >
                        <Grid item xs={12} md={10}>
                          <label htmlFor="importe">Importe *</label>
                          <InputNumber
                            id="importe"
                            inputId="importe"
                            required
                            value={valoresCajas ? valoresCajas : null}
                            onChange={(e) => guardarValoresCajas(e.value)}
                            mode="decimal"
                            locale="en-US"
                            size={12}
                            minFractionDigits={2}
                            style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6} style={{ padding: "0.2vw" }}>
                          <Autocomplete
                            id="moneda"
                            size={"small"}
                            fullWidth
                            noOptionsText={"No hay opciones"}
                            options={monedas ? monedas : null}
                            value={
                              monedas && moneda && moneda.id_moneda !== null
                                ? monedas[
                                    monedas.findIndex(
                                      (item) => item.id === moneda.id_moneda
                                    )
                                  ]
                                : null
                            }
                            getOptionLabel={(option) =>
                              option.nombre + " (" + option.simbolo + ")"
                            }
                            openOnFocus={true}
                            disablePortal
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Moneda *"
                                variant="standard"
                                InputLabelProps={{
                                  style: { color: "red" },
                                }}
                              />
                            )}
                            //onKeyDown={handleEnter}
                            onChange={(event, value) => {
                              if (value) {
                                guardarMoneda({
                                  ...moneda,
                                  id_moneda: value.id,
                                  moneda: value.nombre,
                                });

                                if (value.id !== selectedRow[0].id_moneda) {
                                  let mon = monedas.findIndex(
                                    (item) => item.id === value.id
                                  );

                                  let cotiza = [];
                                  cotiza.push({
                                    id: "1",
                                    nombre: "Compra",
                                    cotizacion: monedas[mon].compra,
                                  });
                                  cotiza.push({
                                    id: "2",
                                    nombre: "Venta",
                                    cotizacion: monedas[mon].venta,
                                  });
                                  cotiza.push({
                                    id: "3",
                                    nombre: "Tarjeta",
                                    cotizacion: monedas[mon].tarjeta,
                                  });
                                  guardarCotizaciones(cotiza);
                                } else {
                                  guardarCotizacion({
                                    ...cotizacion,
                                    cotizacion: selectedRow[0].cotizacion,
                                    cotizacionTipo:
                                      selectedRow[0].cotizacionTipo,
                                  });
                                }
                              } else {
                                guardarMoneda({
                                  id_moneda: null,
                                  moneda: null,
                                });
                                guardarCotizaciones([]);
                              }
                            }}
                          />
                        </Grid>

                        {monedaPredeterminada &&
                        moneda &&
                        moneda.id_moneda &&
                        moneda.id_moneda !== monedaPredeterminada ? (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ padding: "0.2vw" }}
                          >
                            {selectedRow &&
                            moneda.id_moneda !== selectedRow[0].id_moneda ? (
                              <Autocomplete
                                id="cotizacion"
                                size={"small"}
                                fullWidth
                                noOptionsText={"No hay opciones"}
                                options={cotizaciones ? cotizaciones : null}
                                value={
                                  cotizacion &&
                                  cotizacion.cotizacionTipo !== null
                                    ? cotizaciones[
                                        cotizaciones.findIndex(
                                          (item) =>
                                            item.nombre ===
                                            cotizacion.cotizacionTipo
                                        )
                                      ]
                                    : null
                                }
                                getOptionLabel={(option) =>
                                  "$ " +
                                  option.cotizacion +
                                  " (" +
                                  option.nombre +
                                  ")"
                                }
                                openOnFocus={true}
                                disablePortal
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Cotización *"
                                    variant="standard"
                                    InputLabelProps={{
                                      style: { color: "red" },
                                    }}
                                  />
                                )}
                                //onKeyDown={handleEnter}
                                onChange={(event, value) => {
                                  if (value) {
                                    guardarCotizacion({
                                      ...cotizacion,
                                      cotizacion: value.cotizacion,
                                      cotizacionTipo: value.nombre,
                                    });
                                  } else {
                                    guardarCotizacion(null);
                                  }
                                }}
                              />
                            ) : (
                              <TextField
                                value={
                                  selectedRow && selectedRow.length > 0
                                    ? selectedRow[0].cotizacion
                                    : null
                                }
                                id="cotizacionMoneda"
                                label="Cotización"
                                fullWidth
                                variant="standard"
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled
                              />
                            )}
                          </Grid>
                        ) : null}
                      </Grid>
                    </Fragment>
                  </Dialog>

                  <Dialog
                    header={renderHeaderCheques()}
                    visible={visibleCheques}
                    closable={false}
                    footer={renderFooterCheques()}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "90vw", paddingBottom: "0" }}
                    position="center"
                  >
                    <Grid
                      container
                      xs={12}
                      md={12}
                      style={{ borderStyle: "solid", borderWidth: "1px" }}
                    >
                      <Grid item xs={12} md={12}>
                        <div className="datatable-responsive-demo">
                          <div className="card">
                            <Tooltip
                              target=".export-buttons>button"
                              position="bottom"
                            />

                            <Fragment>
                              <DataTable
                                value={movimientosCheques}
                                selection={selectedRowCheques}
                                onSelectionChange={(e) =>
                                  setSelectedRowCheques(e.value)
                                }
                                selectionMode={rowClick ? null : "checkbox"}
                                dataKey="id"
                                header={header2}
                                className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                                style={{ fontSize: "small", marginTop: "1vw" }}
                                globalFilter={globalFilter}
                                emptyMessage="No hay datos."
                                paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                //paginatorLeft={paginatorLeft}
                                //paginatorRight={paginatorRight}
                              >
                                <Column
                                  selectionMode="multiple"
                                  headerStyle={{ width: "3rem" }}
                                ></Column>
                                <Column
                                  field="id"
                                  header="ID"
                                  //body={idTemplate}
                                  style={{
                                    fontSize: "xx-small",
                                    display: "none",
                                  }}
                                  sortable
                                ></Column>
                                <Column
                                  field="fecha"
                                  header="Fecha Operación"
                                  body={fechaOperacionTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="emision"
                                  header="Fecha Emisión"
                                  body={fechaEmisionTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="deposito"
                                  header="Fecha Depósito"
                                  body={fechaDepositoTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="detalleBanco"
                                  header="Banco"
                                  //body={habilitadoTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="numero"
                                  header="Número"
                                  //body={habilitadoTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="empresa"
                                  header="Empresa"
                                  //body={habilitadoTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="importe"
                                  header="Importe"
                                  body={importeTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="moneda"
                                  header="Moneda"
                                  //body={importeTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="cotizacion"
                                  header="Cotización"
                                  //body={importeTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="eCheq"
                                  header="eCheq"
                                  body={eCheqTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="noALaOrden"
                                  header="N.O."
                                  body={NOTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="diferenciaDias"
                                  header="Días para Cobrar"
                                  //body={habilitadoTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="detalle"
                                  header="Detalle"
                                  //body={habilitadoTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="tipo"
                                  header="Tipo"
                                  //body={habilitadoTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                              </DataTable>
                            </Fragment>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      md={12}
                      style={{ borderStyle: "solid", borderWidth: "1px" }}
                    >
                      {selectedRow ? (
                        <Grid item xs={12} md={12}>
                          <Tag
                            value={
                              "TOTAL: " +
                              parseFloat(calcularTotalCheques()).toFixed(2)
                            }
                            style={{ marginLeft: "1em", marginTop: "1em" }}
                          ></Tag>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Dialog>

                  <Dialog
                    header={renderHeaderCompensaciones()}
                    visible={visibleCompensaciones}
                    closable={false}
                    footer={renderFooterCompensaciones()}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "90vw", paddingBottom: "0" }}
                    position="center"
                  >
                    <Grid
                      container
                      xs={12}
                      md={12}
                      style={{ borderStyle: "solid", borderWidth: "1px" }}
                    >
                      <Grid item xs={12} md={12}>
                        <div className="datatable-responsive-demo">
                          <div className="card">
                            <Tooltip
                              target=".export-buttons>button"
                              position="bottom"
                            />

                            <Fragment>
                              <DataTable
                                value={movimientosCompensaciones}
                                selection={selectedRowCompensaciones}
                                onSelectionChange={(e) =>
                                  setSelectedRowCompensaciones(e.value)
                                }
                                selectionMode={rowClick ? null : "checkbox"}
                                dataKey="id"
                                header={header2}
                                className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                                style={{ fontSize: "small", marginTop: "1vw" }}
                                globalFilter={globalFilter}
                                emptyMessage="No hay datos."
                                paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                rows={50}
                                rowsPerPageOptions={[10, 20, 50]}
                                //paginatorLeft={paginatorLeft}
                                //paginatorRight={paginatorRight}
                              >
                                <Column
                                  selectionMode="multiple"
                                  headerStyle={{ width: "3rem" }}
                                ></Column>
                                <Column
                                  field="id"
                                  header="ID"
                                  //body={idTemplate}
                                  style={{ fontSize: "xx-small", display: "none" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="fecha"
                                  header="Fecha"
                                  body={fechaTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="afiliados.nombre_razonsocial"
                                  header="Afiliado"
                                  //body={habilitadoTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="afiliados.numerodocumento"
                                  header="C.U.I.T/D.N.I."
                                  //body={habilitadoTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="conceptos.nombre"
                                  header="Concepto"
                                  //body={habilitadoTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="detalle"
                                  header="Detalle"
                                  //body={habilitadoTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="empresas.nombre"
                                  header="Empresa"
                                  //body={habilitadoTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="importe"
                                  header="Importe"
                                  //body={habilitadoTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                              </DataTable>
                            </Fragment>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      md={12}
                      style={{ borderStyle: "solid", borderWidth: "1px" }}
                    >
                      {selectedRow ? (
                        <Grid item xs={12} md={12}>
                          <Tag
                            value={
                              "TOTAL: " +
                              parseFloat(calcularTotalCompensaciones()).toFixed(
                                2
                              )
                            }
                            style={{ marginLeft: "1em", marginTop: "1em" }}
                          ></Tag>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Dialog>

                  <Dialog
                    header={renderHeaderBancos()}
                    visible={visibleBancos}
                    closable={false}
                    footer={renderFooterBancos()}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "75vw", paddingBottom: "0" }}
                    position="center"
                  >
                    <Grid container spacing={3} xs={12} md={12}>
                      <Grid item xs={12} md={12} style={{ marginTop: "1vw" }}>
                        <Autocomplete
                          id="cuenta"
                          fullWidth
                          required
                          noOptionsText={"No hay opciones"}
                          options={
                            cuentas &&
                            selectedRow &&
                            selectedRow.length > 0 &&
                            selectedRow[0].id_empresa
                              ? cuentas.filter(
                                  (item) =>
                                    item.habilitado &&
                                    item.id_empresa ===
                                      selectedRow[0].id_empresa &&
                                    item.id_moneda
                                )
                              : null
                          }
                          getOptionLabel={(option) => option.nombre}
                          openOnFocus={true}
                          disablePortal
                          style={{ marginTop: "-0.3vw", marginLeft: "0vw" }}
                          //disabled={afiliado.titular ? false : true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Cuenta *"
                              variant="standard"
                            />
                          )}
                          onChange={(event, value) => {
                            if (value) {
                              guardarCuenta({
                                id_cuenta: value.id,
                                cuenta: value.nombre,
                                id_moneda: value.id_moneda,
                                cotizacion: null,
                              });
                            } else {
                              guardarCuenta({
                                id_cuenta: null,
                                cuenta: null,
                                id_moneda: null,
                                cotizacion: null,
                              });
                            }
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={3} style={{ marginTop: "0vw" }}>
                        <label
                          htmlFor="importe"
                          style={{ marginRight: "0.2vw" }}
                        >
                          Importe *
                        </label>
                        <InputNumber
                          id="importe"
                          inputId="importe"
                          required
                          value={valoresBancos ? valoresBancos : null}
                          onChange={(e) => guardarValoresBancos(e.value)}
                          mode="decimal"
                          locale="en-US"
                          size={12}
                          minFractionDigits={2}
                          //style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={4} style={{ marginTop: "0vw" }}>
                        <Autocomplete
                          id="moneda"
                          size={"small"}
                          fullWidth
                          noOptionsText={"No hay opciones"}
                          options={monedas ? monedas : null}
                          value={
                            monedas && moneda && moneda.id_moneda !== null
                              ? monedas[
                                  monedas.findIndex(
                                    (item) => item.id === moneda.id_moneda
                                  )
                                ]
                              : null
                          }
                          getOptionLabel={(option) =>
                            option.nombre + " (" + option.simbolo + ")"
                          }
                          openOnFocus={true}
                          disablePortal
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Moneda *"
                              variant="standard"
                            />
                          )}
                          //onKeyDown={handleEnter}
                          onChange={(event, value) => {
                            if (value) {
                              guardarMoneda({
                                ...moneda,
                                id_moneda: value.id,
                                moneda: value.nombre,
                              });

                              if (value.id !== selectedRow[0].id_moneda) {
                                let mon = monedas.findIndex(
                                  (item) => item.id === value.id
                                );

                                let cotiza = [];
                                cotiza.push({
                                  id: "1",
                                  nombre: "Compra",
                                  cotizacion: monedas[mon].compra,
                                });
                                cotiza.push({
                                  id: "2",
                                  nombre: "Venta",
                                  cotizacion: monedas[mon].venta,
                                });
                                cotiza.push({
                                  id: "3",
                                  nombre: "Tarjeta",
                                  cotizacion: monedas[mon].tarjeta,
                                });
                                guardarCotizaciones(cotiza);
                              } else {
                                guardarCotizacion({
                                  ...cotizacion,
                                  cotizacion: selectedRow[0].cotizacion,
                                  cotizacionTipo: selectedRow[0].cotizacionTipo,
                                });
                                let cotiza = {
                                  cotizacion: selectedRow[0].cotizacion,
                                  cotizacionTipo: selectedRow[0].cotizacionTipo,
                                };
                                guardarCuenta({
                                  ...cuenta,
                                  cotizacion: cotiza,
                                });
                              }
                            } else {
                              guardarMoneda({
                                id_moneda: null,
                                moneda: null,
                              });
                              guardarCotizaciones([]);
                              guardarCuenta({
                                ...cuenta,
                                cotizacion: null,
                              });
                            }
                          }}
                        />
                      </Grid>

                      {monedaPredeterminada &&
                      moneda &&
                      moneda.id_moneda &&
                      moneda.id_moneda !== monedaPredeterminada ? (
                        <Grid item xs={12} md={4} style={{ marginTop: "0vw" }}>
                          {selectedRow &&
                          moneda.id_moneda !== selectedRow[0].id_moneda ? (
                            <Autocomplete
                              id="cotizacion"
                              size={"small"}
                              fullWidth
                              noOptionsText={"No hay opciones"}
                              options={cotizaciones ? cotizaciones : null}
                              value={
                                cotizacion && cotizacion.cotizacionTipo !== null
                                  ? cotizaciones[
                                      cotizaciones.findIndex(
                                        (item) =>
                                          item.nombre ===
                                          cotizacion.cotizacionTipo
                                      )
                                    ]
                                  : null
                              }
                              getOptionLabel={(option) =>
                                "$ " +
                                option.cotizacion +
                                " (" +
                                option.nombre +
                                ")"
                              }
                              openOnFocus={true}
                              disablePortal
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Cotización *"
                                  variant="standard"
                                  InputLabelProps={{
                                    style: { color: "red" },
                                  }}
                                />
                              )}
                              //onKeyDown={handleEnter}
                              onChange={(event, value) => {
                                if (value) {
                                  guardarCotizacion({
                                    ...cotizacion,
                                    cotizacion: value.cotizacion,
                                    cotizacionTipo: value.nombre,
                                  });
                                  guardarCuenta({
                                    ...cuenta,
                                    cotizacion: value,
                                  });
                                } else {
                                  guardarCotizacion(null);
                                  guardarCuenta({
                                    ...cuenta,
                                    cotizacion: null,
                                  });
                                }
                              }}
                            />
                          ) : (
                            <TextField
                              value={
                                selectedRow && selectedRow.length > 0
                                  ? selectedRow[0].cotizacion
                                  : null
                              }
                              id="cotizacionMoneda"
                              label="Cotización"
                              fullWidth
                              variant="standard"
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled
                            />
                          )}
                        </Grid>
                      ) : null}
                    </Grid>
                  </Dialog>

                  <Dialog
                    header={renderHeaderResumen()}
                    visible={visibleResumen}
                    closable={false}
                    footer={renderFooterResumen()}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "75vw", paddingBottom: "0" }}
                    position="center"
                  >
                    <Grid
                      container
                      xs={12}
                      md={12}
                      style={{ borderStyle: "solid", borderWidth: "1px" }}
                    >
                      <Grid item xs={12} md={12}>
                        <div className="datatable-responsive-demo">
                          <div className="card">
                            <Tooltip
                              target=".export-buttons>button"
                              position="bottom"
                            />

                            <Fragment>
                              <DataTable
                                value={dataResumen}
                                dataKey="id"
                                className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                                style={{ fontSize: "small", marginTop: "1vw" }}
                                globalFilter={globalFilter}
                                emptyMessage="No hay datos."
                                paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                //paginatorLeft={paginatorLeft}
                                //paginatorRight={paginatorRight}
                              >
                                <Column
                                  field="id"
                                  header="ID"
                                  //body={idTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="valor"
                                  header="Valor"
                                  //body={fechaOperacionTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="importe"
                                  header="Importe"
                                  //body={fechaEmisionTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="moneda"
                                  header="Moneda"
                                  //body={fechaEmisionTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="cotizacion"
                                  header="Cotización"
                                  //body={fechaEmisionTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="detalleBanco"
                                  header="Cheque Banco"
                                  //body={fechaDepositoTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="numero"
                                  header="Cheque Número"
                                  //body={habilitadoTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="detalleCuenta"
                                  header="Cuenta Banco"
                                  //body={habilitadoTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                              </DataTable>
                            </Fragment>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      md={12}
                      style={{ borderStyle: "solid", borderWidth: "1px" }}
                    >
                      {selectedRow ? (
                        <Grid item xs={12} md={12}>
                          <Tag
                            value={
                              "TOTAL: " +
                              parseFloat(
                                Number(totalCajas) +
                                  Number(totalCheques) +
                                  Number(totalCompensaciones) +
                                  Number(totalBancos)
                              ).toFixed(2)
                            }
                            style={{ marginLeft: "1em", marginTop: "1em" }}
                          ></Tag>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Dialog>

                </Fragment>
              ) : null}

<Dialog
                    header={renderHeaderResumenPagos()}
                    visible={visibleResumenPagos}
                    closable={false}
                    footer={renderFooterResumenPagos()}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "75vw", paddingBottom: "0" }}
                    position="center"
                  >
                    <Grid
                      container
                      xs={12}
                      md={12}
                      style={{ borderStyle: "solid", borderWidth: "1px" }}
                    >
                      <Grid item xs={12} md={12}>
                        <div className="datatable-responsive-demo">
                          <div className="card">
                            <Tooltip
                              target=".export-buttons>button"
                              position="bottom"
                            />

                            <Fragment>
                              <DataTable
                                value={formaPagoProveedor}
                                dataKey="id"
                                className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                                style={{ fontSize: "small", marginTop: "1vw" }}
                                globalFilter={globalFilter}
                                emptyMessage="No hay datos."
                                paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                //paginatorLeft={paginatorLeft}
                                //paginatorRight={paginatorRight}
                              >
                                <Column
                                  field="id"
                                  header="ID"
                                  //body={idTemplate}
                                  style={{ fontSize: "xx-small", display: "none" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="fecha"
                                  header="Fecha"
                                  //body={fechaOperacionTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="valor"
                                  header="Valor"
                                  //body={fechaOperacionTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="importe"
                                  header="Importe"
                                  //body={fechaEmisionTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="moneda"
                                  header="Moneda"
                                  //body={fechaEmisionTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="cotizacion"
                                  header="Cotización"
                                  //body={fechaEmisionTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="detalleBanco"
                                  header="Cheque Banco"
                                  //body={fechaDepositoTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="numero"
                                  header="Cheque Número"
                                  //body={habilitadoTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="detalleCuenta"
                                  header="Cuenta Banco"
                                  //body={habilitadoTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                                <Column
                                  field="rendiciones"
                                  header="Rendiciones"
                                  //body={habilitadoTemplate}
                                  style={{ fontSize: "xx-small" }}
                                  sortable
                                ></Column>
                              </DataTable>
                            </Fragment>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Dialog>


            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "../Menu";
import { InputNumber } from "primereact/inputnumber";
import { jsPDF } from "jspdf";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputMask } from "primereact/inputmask";

export default function ProcesosPercepciones(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [visibleProceso, setVisibleProceso] = useState(true);
  const [todosLosCobradores, setTodosLosCobradores] = useState(false);
  const [repetirPeriodo, setRepetirPeriodo] = useState(false);
  const [proceso, guardarProceso] = useState(null);
  const [valor, guardarValor] = useState("cuota");
  const [fijo, setFijo] = useState(null);
  const toast = useRef(null);
  const acCobrador = useRef(null);

  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    cobradores,
    statusAcceso,
    empresaElegida,
    listadoExcel,
    empresas,
    configuracion,
    obtenerAcceso,
    tiposComprobantes,
    procesos,
    obtenerModulos,
    obtenerCobradores,
    obtenerEmpresas,
    procesoPercepciones,
    obtenerTiposComprobantes,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "ProcesosPercepciones",
      });
    }
  }, [state.id]);

  useEffect(() => {
    obtenerEmpresas();
  }, [empresaElegida]);

  const exitClick = (e) => {
    navigate("/");
  };

  const onHideProceso = (e) => {
    setVisibleProceso(false);
  };

  const aceptarProceso = async (e) => {
    e.preventDefault();
    //Validar

    if (
      !proceso ||
      proceso.periodo === undefined ||
      proceso.periodo === null ||
      proceso.periodo === 0 ||
      proceso.periodo === ""
    ) {
      mensajeAlerta("Procesos", "El período es necesario !.", "error");
      return;
    }
    confirm1();
  };

  const confirm1 = () => {
    confirmDialog({
      message: "Esta seguro de iniciar el proceso de exportación",
      header: "Confirmación",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-success",
      closable: false,
      acceptLabel: "Si",
      accept: accept1,
    });
  };

  const accept1 = async () => {
    await procesoPercepciones({
      proceso,
      empresaElegida,
      usuario: state.usuario.usuario,
    }).then((data) => {
      let elem = document.getElementById("generarProceso");
      elem.click();
    });
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Proceso de Impresión",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const renderHeaderProceso = () => {
    return (
      <div>
        <Tag
          value={
            empresas && empresas.length > 0
              ? "Percepciones A.R.B.A. " + empresaElegida.nombre
              : null
          }
        ></Tag>
      </div>
    );
  };

  const renderFooterProceso = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarProceso}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
          autoFocus
        >
          Aceptar
        </ButtonMui>

        <ButtonMui
          onClick={onHideProceso}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const fechaTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fecha)}</Fragment>;
  };

  const anuladoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.anulado}></Checkbox>
      </Fragment>
    );
  };

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  function formatDateReves(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return year + month + day;
  }

  function formatDateIso(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[0], thisDate[1], thisDate[2]].join("");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate;
  }

  const generar = async () => {
    if (!procesos || procesos.length === 0) {
      return;
    }

    const doc = new jsPDF();

    /*
    var blob = new Blob([], { type: "text/plain;charset=utf-8" });
    for (var i = 0; i < 10; i++) {
      blob = new Blob([blob, " " + i], { type: "text/plain" });
    }

    saveAs(blob, "hello_world.txt");

    return;
    */

    let hoy = new Date();

    let fechaDia = hoy.getDate();
    let fechaMes = hoy.getMonth() + 1;
    let fechaAnio = hoy.getFullYear();
    let nombreMes = "";

    if (fechaMes === 1) {
      nombreMes = "Enero";
    }
    if (fechaMes === 2) {
      nombreMes = "Febrero";
    }
    if (fechaMes === 3) {
      nombreMes = "Marzo";
    }
    if (fechaMes === 4) {
      nombreMes = "Abril";
    }
    if (fechaMes === 5) {
      nombreMes = "Mayo";
    }
    if (fechaMes === 6) {
      nombreMes = "Junio";
    }
    if (fechaMes === 7) {
      nombreMes = "Julio";
    }
    if (fechaMes === 8) {
      nombreMes = "Agosto";
    }
    if (fechaMes === 9) {
      nombreMes = "Septiembre";
    }
    if (fechaMes === 10) {
      nombreMes = "Octubre";
    }
    if (fechaMes === 11) {
      nombreMes = "Noviembre";
    }
    if (fechaMes === 12) {
      nombreMes = "Diciembre";
    }

    let empresa = empresas.find((item) => item.id === empresaElegida.id);

    let cuitEmpresa = null;
    if (empresa.cuit !== null) {
      cuitEmpresa =
        empresa.cuit.substring(0, 2) +
        empresa.cuit.substring(3, 11) +
        empresa.cuit.substring(12, 13);
    }

    let procesoMes = proceso.periodo.substring(0,2)
    let procesoAnio = "20" + proceso.periodo.substring(3,5)


    let blobArchivo = new Blob([], {
      type: "text/plain;charset=utf-8",
    });

    let xCodDoc = null;
    let xCodComp = null;

    for (var i = 0; i < procesos.length; i++) {
      let xCuit = procesos[i].cuit.substring(0, 12);

      xCuit = xCuit.substring(0,2) + "-" +  xCuit.substring(2,10) + "-" +  xCuit.substring(9,10)

      switch (procesos[i].id_comprobanteafip) {
        case 1:
        case 6:
        case 11:
        case 51:
          xCodComp = "F";
          break;
        case 3:
        case 8:
        case 13:
        case 53:
          xCodComp = "C";
          break;
        case 2:
        case 7:
        case 12:
        case 52:
          xCodComp = "D";
          break;
        case 201:
        case 206:
        case 211:
          xCodComp = "E";
          break;
        case 203:
        case 208:
        case 213:
          xCodComp = "H";
          break;
        case 202:
        case 207:
        case 212:
          xCodComp = "I";
          break;
        default:
          xCodComp = " ";
      }

      let xParteEnteraSubTotal = String(
        Math.trunc(Math.abs(Number(procesos[i].subtotal)))
      ).padStart(9, "0");
      let xParteDecimalSubTotal = String(
        parseFloat(Number(procesos[i].subtotal)).toFixed(2)
      ).slice(-2);

      let xParteEnteraPerc = String(
        Math.trunc(Math.abs(Number(procesos[i].percarba)))
      ).padStart(8, "0");
      let xParteDecimalPerc = String(
        parseFloat(Number(procesos[i].percarba)).toFixed(2)
      ).slice(-2);

      blobArchivo = new Blob(
        [
          blobArchivo,
          xCuit +
            formatDate(procesos[i].fecha) +
            xCodComp +
            procesos[i].tipo +
            String(procesos[i].puntoventa).padStart(4, "0") +
            String(procesos[i].numero).padStart(8, "0") +
            xParteEnteraSubTotal +
            "," +
            xParteDecimalSubTotal +
            xParteEnteraPerc +
            "," +
            xParteDecimalPerc +
            "A" +
            "\n",
        ],
        { type: "text/plain;charset=utf-8" }
      );

      //Print #1, !numerodocumento; Mid(!fecha, 1, 10); "F"; !tipo; ; Format(!sucursal, "0000"); Format(!numero, "00000000"); Replace(Format(!subtotal, "000000000.00"), ".", ","); Replace(Format(!ib, "00000000.00"), ".", ","); "A"
    }

    saveAs(blobArchivo, "AR-" + cuitEmpresa + "-" +  procesoAnio + procesoMes + "0-0-LOTE1");

  };

  //console.log(empresas);
  //console.log(serviciosFinalizadosExcel);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      <ConfirmDialog />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Dialog
            header={renderHeaderProceso()}
            visible={visibleProceso}
            closable={false}
            footer={renderFooterProceso()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "70vw", paddingBottom: "0" }}
            position="top"
          >
            <Grid container spacing={3} style={{ marginTop: "1vw" }}>
              <Grid item xs={12} md={4}>
                <label htmlFor="periodo">Período</label>
                <InputMask
                  id="periodo"
                  mask="99/99"
                  value={proceso ? proceso.periodo : null}
                  placeholder="99/99"
                  size={4}
                  onChange={(e) =>
                    guardarProceso({
                      ...proceso,
                      periodo: e.value,
                    })
                  }
                  style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                ></InputMask>
              </Grid>
              <button
                id="generarProceso"
                type="button"
                onClick={(e) => {
                  generar();
                }}
                style={{ display: "none" }}
              >
                Imprimir Facturas
              </button>
            </Grid>
          </Dialog>
        </Fragment>
      ) : null}

      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

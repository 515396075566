import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";
import { InputNumber } from "primereact/inputnumber";
import { jsPDF } from "jspdf";

export default function MovimientosCajas(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  let navigate = useNavigate();

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Descripción", dataKey: "descripcion" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [movimientoCaja, guardarMovimientoCaja] = useState(null);
  const [visible, setVisible] = useState(false);
  const [detalle, guardarDetalle] = useState(null);
  const [fechaDesde, guardarFechaDesde] = useState(null);
  const [fechaHasta, guardarFechaHasta] = useState(null);
  const [empresaTodas, setEmpresaTodas] = useState(true);
  const [tipo, setTipo] = useState(null);
  const [cambio, setCambio] = useState(false);
  const [moneda, guardarMoneda] = useState(null);
  const [monedaMovimiento, guardarMonedaMovimiento] = useState(null);
  const [filterMoneda, setFilterMoneda] = useState(false);
  const [cotizaciones, guardarCotizaciones] = useState([]);
  const [cotizacion, guardarCotizacion] = useState(null);

  const toast = useRef(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    movimientosCajas,
    empresaElegida,
    cajasEgresos,
    cajasIngresos,
    cajasSaldoAnterior,
    cajasSaldoActual,
    empresas,
    conceptos,
    monedas,
    monedaPredeterminada,
    obtenerMovimientosCajas,
    obtenerAcceso,
    agregarMovimientoCaja,
    actualizarMovimientoCaja,
    obtenerEmpresas,
    obtenerConceptos,
    obtenerMonedas,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "MovimientosCajas",
      });
    }
  }, [state]);

  useEffect(() => {
    let date = new Date();
    let day = `${date.getDate()}`.padStart(2, "0");
    let month = `${date.getMonth() + 1}`.padStart(2, "0");
    let year = date.getFullYear();
    let fecha = `${year}-${month}-${day}`;
    guardarFechaDesde(fecha);
    guardarFechaHasta(fecha);
    obtenerMovimientosCajas({
      fechaDesde: fecha,
      fechaHasta: fecha,
      empresaTodas,
      empresaId: null,
      filterMoneda: false,
    });
    obtenerEmpresas();
    obtenerConceptos();
  }, []);

  useEffect(() => {
    obtenerMonedas();
    if (monedas && monedaPredeterminada) {
      let mon = monedas.findIndex((item) => item.id === monedaPredeterminada);
      guardarMoneda({
        id_moneda: monedaPredeterminada,
        moneda: monedas[mon].nombre,
      });
    }
  }, [monedas.id_moneda, monedaPredeterminada]);

  const imprimirClick = () => {
    if (!selectedRow) {
      toast.current.show({
        severity: "info",
        summary: "Impresión",
        detail: "Debe seleccionar un movimiento de caja.",
        life: 3000,
      });
      return;
    }

    const doc = new jsPDF();
    let hoy = new Date();

    let fechaDia = hoy.getDate();
    let fechaMes = hoy.getMonth() + 1;
    let fechaAnio = hoy.getFullYear();
    let nombreMes = "";

    if (fechaMes === 1) {
      nombreMes = "Enero";
    }
    if (fechaMes === 2) {
      nombreMes = "Febrero";
    }
    if (fechaMes === 3) {
      nombreMes = "Marzo";
    }
    if (fechaMes === 4) {
      nombreMes = "Abril";
    }
    if (fechaMes === 5) {
      nombreMes = "Mayo";
    }
    if (fechaMes === 6) {
      nombreMes = "Junio";
    }
    if (fechaMes === 7) {
      nombreMes = "Julio";
    }
    if (fechaMes === 8) {
      nombreMes = "Agosto";
    }
    if (fechaMes === 9) {
      nombreMes = "Septiembre";
    }
    if (fechaMes === 10) {
      nombreMes = "Octubre";
    }
    if (fechaMes === 11) {
      nombreMes = "Noviembre";
    }
    if (fechaMes === 12) {
      nombreMes = "Diciembre";
    }

    let row = 10;
    doc.setFontSize(10);
    doc.text(fechaDia + " de " + nombreMes + " de " + fechaAnio, 160, row);
    row = 20;
    doc.setFontSize(11);
    let titulo = "COMPROBANTE DE CAJA";
    doc.text(titulo, 80, row);
    let textWidth = doc.getTextWidth(titulo);
    doc.line(80, row + 2, 80 + textWidth + 1, row + 2);
    doc.setFontSize(10);
    row = 30;

    doc.text(`ID: ${selectedRow.id}`, 30, row, "left", "middle");

    doc.text(`EMPRESA: ${selectedRow.empresa}`, 120, row, "left", "middle");

    row = row + 10;

    doc.text(
      `Fecha del Movimiento: ${formatDate(selectedRow.fecha)}`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text(
      `Concepto del Movimiento: ${
        selectedRow.concepto !== null ? selectedRow.concepto : ""
      }`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text(
      `Detalle del Movimiento: ${
        selectedRow.detalle !== null ? selectedRow.detalle : ""
      }`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text(
      `Tipo de  Movimiento: ${
        selectedRow.tipo === "i"
          ? "INGRESO DE CAJA"
          : selectedRow.tipo === "e"
          ? "EGRESO DE CAJA"
          : ""
      }`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text(
      `Importe $: ${parseFloat(selectedRow.importe).toFixed(2)}`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 50;

    doc.text(`Recibí conforme`, 30, row, "left", "middle");

    doc.text(`Firma y sello de la caja`, 120, row, "left", "middle");

    row = row + 50;

    doc.text(`Firma y aclaración`, 30, row, "left", "middle");

    doc.text(`${selectedRow.usuario}`, 120, row, "left", "middle");

    window.open(doc.output("bloburl"));
    //setSelectedRow(null)
  };

  const addClick = (tipo) => {
    //props.history.push(`/movimientoCaja/0`);
    setTipo(tipo);
    setVisible(true);
    setSelectedRow(null);
    let date = new Date();
    let day = `${date.getDate()}`.padStart(2, "0");
    let month = `${date.getMonth() + 1}`.padStart(2, "0");
    let year = date.getFullYear();
    let fecha = `${year}-${month}-${day}`;

    let mon = monedas.findIndex((item) => item.id === monedaPredeterminada);
    guardarMonedaMovimiento({
      id_moneda: monedaPredeterminada,
      moneda: monedas[mon].nombre,
    });

    guardarCotizacion(null)
    guardarCotizaciones([])

    guardarMovimientoCaja({
      fecha,
      tipo,
      usuario: state.usuario.usuario,
      id_empresa: empresaElegida.id,
    });
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !movimientoCaja ||
      movimientoCaja.fecha === undefined ||
      movimientoCaja.fecha === null ||
      movimientoCaja.fecha === ""
    ) {
      mensajeAlerta("Movimientos Cajas", "La fecha es necesaria !.", "error");
      return;
    }

    if (
      !movimientoCaja ||
      movimientoCaja.id_empresa === undefined ||
      movimientoCaja.id_empresa === null ||
      movimientoCaja.id_empresa === ""
    ) {
      mensajeAlerta("Movimientos Cajas", "La empresa es necesaria !.", "error");
      return;
    }

    if (
      !movimientoCaja ||
      movimientoCaja.id_concepto === undefined ||
      movimientoCaja.id_concepto === null ||
      movimientoCaja.id_concepto === ""
    ) {
      mensajeAlerta(
        "Movimientos Cajas",
        "El concepto es necesario !.",
        "error"
      );
      return;
    }

    if (
      !movimientoCaja ||
      movimientoCaja.importe === undefined ||
      movimientoCaja.importe === null ||
      movimientoCaja.importe === ""
    ) {
      mensajeAlerta("Movimientos Cajas", "El importe es necesario !.", "error");
      return;
    }

    if (Number(movimientoCaja.importe) <= 0) {
      mensajeAlerta(
        "Movimientos Cajas",
        "El importe debe ser positivo !.",
        "error"
      );
      return;
    }

    if (monedaMovimiento && !monedaMovimiento.id_moneda) {
      mensajeAlerta( "Movimientos Cajas", "Debe ingresar una moneda !.", "error");
      return;
    }

    if (monedaMovimiento && monedaMovimiento.id_moneda !== monedaPredeterminada && !cotizacion) {
      mensajeAlerta( "Movimientos Cajas", "Debe ingresar una cotización !.", "error");
      return;
    }




    // Llamar al context
    if (selectedRow) {
      //actualizarMovimientoCaja({ movimientoCaja });
    } else {
      agregarMovimientoCaja({ movimientoCaja, monedaMovimiento, cotizacion });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerMovimientosCajas({
        fechaDesde,
        fechaHasta,
        empresaTodas,
        empresaId: empresaElegida.id,
        filterMoneda,
      });

      guardarMoneda(null);
    }, 1000);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Movimientos Cajas",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  const fechaTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fecha)}</Fragment>;
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, movimientosCajas);
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(movimientosCajas);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "movimientosCajas");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Movimientos Cajas"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag
          value={
            tipo === "i"
              ? "Movimiento de Caja - INGRESOS"
              : tipo === "e"
              ? "Movimiento de Caja - EGRESOS"
              : null
          }
        ></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  function handleEnter(event) {
    if (event.keyCode === 13) {
      let element = event.target.id;
      let campo = null;
      switch (element) {
        case "fechaDesde":
          campo = document.getElementById("fechaHasta");
          campo.focus();
          break;
        case "fecha":
          campo = document.getElementById("empresaMovimiento");
          campo.focus();
          break;
        case "empresaMovimiento":
          campo = document.getElementById("concepto");
          campo.focus();
          break;
        case "concepto":
          campo = document.getElementById("detalle");
          campo.focus();
          break;
        case "detalle":
          campo = document.getElementById("importe");
          campo.focus();
          break;
      }
    }
  }

  //console.log(monedaMovimiento);
  //console.log(monedas);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Grid container xs={12} md={12}>
            {/* 1 Columna */}
            <Grid item xs={12} md={9} style={{ margin: "1em" }}>
              {movimientosCajas ? (
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={movimientosCajas}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      size="small"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "normal", marginTop: "1vw" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={100}
                      rowsPerPageOptions={[10, 20, 50, 100, 500]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        //body={idTemplate}
                        style={{ fontSize: "small", display: "none" }}
                        sortable
                      ></Column>
                      <Column
                        field="fecha"
                        header="Fecha"
                        body={fechaTemplate}
                        style={{ fontSize: "small" }}
                        sortable
                      ></Column>
                      <Column
                        field="concepto"
                        header="Concepto"
                        //body={habilitadoTemplate}
                        style={{ fontSize: "small" }}
                        sortable
                      ></Column>
                      <Column
                        field="detalle"
                        header="Detalle"
                        //body={habilitadoTemplate}
                        style={{ fontSize: "small" }}
                        sortable
                      ></Column>
                      <Column
                        field="ingreso"
                        header="Ingreso"
                        //body={habilitadoTemplate}
                        style={{ fontSize: "small" }}
                        sortable
                      ></Column>
                      <Column
                        field="egreso"
                        header="Egreso"
                        //body={habilitadoTemplate}
                        style={{ fontSize: "small" }}
                        sortable
                      ></Column>
                      <Column
                        field="moneda"
                        header="Moneda"
                        //body={habilitadoTemplate}
                        style={{ fontSize: "small" }}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              ) : null}
            </Grid>
            {/* 2 Columna */}
            <Grid
              container
              xs={12}
              md={2}
              style={{ margin: "0.5em", display: "inline-block" }}
            >
              <Grid item xs={12} md={12}>
                <Button
                  icon="pi pi-arrow-down"
                  tooltip="Agregar Ingresos"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={(e) => addClick("i")}
                />
                <Button
                  icon="pi pi-arrow-up"
                  tooltip="Agregar Egresos"
                  className="p-button-rounded p-button-secondary"
                  style={{ marginLeft: "1em" }}
                  onClick={(e) => addClick("e")}
                />
                <Button
                  icon="pi pi-file-pdf"
                  tooltip="Imprimir"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={imprimirClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />

                <Button
                  id="confirmarId"
                  icon="pi pi-check"
                  tooltip="Confirmar Fechas"
                  className={
                    cambio
                      ? "p-button-rounded p-button-danger"
                      : "p-button-rounded p-button-success"
                  }
                  style={{ marginLeft: "1em", display: "none" }}
                  onClick={(e) => {
                    if (
                      !fechaDesde ||
                      fechaDesde === undefined ||
                      fechaDesde === null ||
                      fechaDesde === ""
                    ) {
                      toast.current.show({
                        severity: "info",
                        summary: "Movimientos Bancos",
                        detail: "Debe seleccionar fecha desde.",
                        life: 3000,
                      });
                      return;
                    }

                    if (
                      !fechaHasta ||
                      fechaHasta === undefined ||
                      fechaHasta === null ||
                      fechaHasta === ""
                    ) {
                      toast.current.show({
                        severity: "info",
                        summary: "Movimientos Bancos",
                        detail: "Debe seleccionar fecha hasta.",
                        life: 3000,
                      });
                      return;
                    }

                    setCambio(false);
                    obtenerMovimientosCajas({
                      fechaDesde,
                      fechaHasta,
                      empresaTodas,
                      moneda,
                      empresaId: empresaElegida.id,
                      filterMoneda,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ marginTop: "1vw" }}>
                <TextField
                  value={fechaDesde ? fechaDesde : null}
                  id="fechaDesde"
                  label="Fecha Desde"
                  type="date"
                  variant="standard"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    guardarFechaDesde(e.target.value);
                    setCambio(true);
                    let elem = document.getElementById("confirmarId");
                    if (elem) {
                      setTimeout(() => {
                        elem.click();
                      }, 500);
                    }
                  }}
                  onKeyDown={handleEnter}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ marginTop: "1vw" }}>
                <TextField
                  value={fechaHasta ? fechaHasta : null}
                  id="fechaHasta"
                  label="Fecha Hasta"
                  type="date"
                  variant="standard"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  //style={{ marginLeft: "1em" }}
                  //onKeyDown={handleEnter}
                  onChange={(e) => {
                    setCambio(true);
                    guardarFechaHasta(e.target.value);
                    let elem = document.getElementById("confirmarId");
                    if (elem) {
                      setTimeout(() => {
                        elem.click();
                      }, 500);
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{ marginTop: "2vw", marginLeft: "-1vw" }}
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <Checkbox
                      color="primary"
                      checked={empresaTodas}
                      style={{ marginLeft: "1vw" }}
                      onChange={(e) => {
                        setCambio(true);
                        setEmpresaTodas(e.target.checked);

                        let elem = document.getElementById("confirmarId");
                        if (elem) {
                          setTimeout(() => {
                            elem.click();
                          }, 500);
                        }
                      }}
                    />
                  }
                  label="Todas las empresas"
                  labelPlacement="start"
                />
              </Grid>

              <div style={{ display: "flex" }}>
                <Grid item xs={12} md={11} style={{ marginTop: "1vw" }}>
                  <Autocomplete
                    id="moneda"
                    size={"small"}
                    noOptionsText={"No hay opciones"}
                    options={monedas ? monedas : null}
                    fullWidth
                    //style={{ width: "10vw" }}
                    value={
                      monedas && moneda && moneda.id_moneda !== null
                        ? monedas[
                            monedas.findIndex(
                              (item) => item.id === moneda.id_moneda
                            )
                          ]
                        : null
                    }
                    getOptionLabel={(option) =>
                      option.nombre + " (" + option.simbolo + ")"
                    }
                    openOnFocus={true}
                    disablePortal
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Moneda"
                        variant="standard"
                      />
                    )}
                    //onKeyDown={handleEnter}
                    onChange={(event, value) => {
                      if (value) {
                        guardarMoneda({
                          ...moneda,
                          id_moneda: value.id,
                          moneda: value.nombre,
                        });
                      } else {
                        guardarMoneda({
                          ...moneda,
                          id_moneda: null,
                          moneda: null,
                        });
                      }
                      setCambio(true);

                      let elem = document.getElementById("confirmarId");
                      if (elem) {
                        setTimeout(() => {
                          elem.click();
                        }, 500);
                      }
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={1}
                  style={{ marginTop: "2vw", marginLeft: "-1vw" }}
                >
                  <FormControlLabel
                    value="yes"
                    control={
                      <Checkbox
                        color="primary"
                        checked={filterMoneda}
                        style={{ marginLeft: "1vw" }}
                        onChange={(e) => {
                          setCambio(true);
                          setFilterMoneda(e.target.checked);

                          let elem = document.getElementById("confirmarId");
                          if (elem) {
                            setTimeout(() => {
                              elem.click();
                            }, 500);
                          }
                        }}
                      />
                    }
                    labelPlacement="start"
                  />
                </Grid>
              </div>
              {monedas && moneda && moneda.id_moneda ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{
                    align: "center",
                    marginLeft: "1vw",
                    marginTop: "5.5vw",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{ color: "blue" }}
                    gutterBottom
                  >
                    {cajasSaldoAnterior
                      ? "Saldo Anterior: " +
                        monedas[
                          monedas.findIndex(
                            (item) => item.id === moneda.id_moneda
                          )
                        ].simbolo +
                        " " +
                        Number(cajasSaldoAnterior).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })
                      : "Saldo Anterior: " +
                        monedas[
                          monedas.findIndex(
                            (item) => item.id === moneda.id_moneda
                          )
                        ].simbolo +
                        " " +
                        Number(0).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                  </Typography>

                  <Typography
                    variant="h6"
                    style={{ color: "blue" }}
                    gutterBottom
                  >
                    {cajasIngresos
                      ? "Ingresos: " +
                        monedas[
                          monedas.findIndex(
                            (item) => item.id === moneda.id_moneda
                          )
                        ].simbolo +
                        " " +
                        Number(cajasIngresos).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })
                      : "Ingresos: " +
                        monedas[
                          monedas.findIndex(
                            (item) => item.id === moneda.id_moneda
                          )
                        ].simbolo +
                        " " +
                        Number(0).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                  </Typography>

                  <Typography
                    variant="h6"
                    style={{ color: "blue" }}
                    gutterBottom
                  >
                    {cajasEgresos
                      ? "Egresos: " +
                        monedas[
                          monedas.findIndex(
                            (item) => item.id === moneda.id_moneda
                          )
                        ].simbolo +
                        " " +
                        Number(cajasEgresos).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })
                      : "Egresos: " +
                        monedas[
                          monedas.findIndex(
                            (item) => item.id === moneda.id_moneda
                          )
                        ].simbolo +
                        " " +
                        Number(0).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: "blue" }}
                    gutterBottom
                  >
                    {cajasSaldoActual
                      ? "Saldo Actual: " +
                        monedas[
                          monedas.findIndex(
                            (item) => item.id === moneda.id_moneda
                          )
                        ].simbolo +
                        " " +
                        Number(cajasSaldoActual).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })
                      : "Saldo Actual: " +
                        monedas[
                          monedas.findIndex(
                            (item) => item.id === moneda.id_moneda
                          )
                        ].simbolo +
                        " " +
                        Number(0).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Dialog
            header={renderHeader()}
            visible={visible}
            closable={false}
            footer={renderFooter()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "65vw", paddingBottom: "0" }}
            position="center"
          >
            <Grid container spacing={3} style={{ marginTop: "1vw" }}>
              {selectedRow ? (
                <Grid item xs={12} md={2}>
                  <TextField
                    value={movimientoCaja ? movimientoCaja.id : null}
                    required
                    variant="standard"
                    id="id"
                    label="ID"
                    fullWidth
                    disabled
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} md={4}>
                <TextField
                  value={movimientoCaja ? movimientoCaja.fecha : null}
                  id="fecha"
                  label="Fecha *"
                  type="date"
                  required
                  variant="standard"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ marginLeft: "1em" }}
                  onKeyDown={handleEnter}
                  onChange={(e) =>
                    guardarMovimientoCaja({
                      ...movimientoCaja,
                      fecha: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="empresaMovimiento"
                  fullWidth
                  required
                  noOptionsText={"No hay opciones"}
                  options={
                    empresas ? empresas.filter((item) => item.habilitado) : null
                  }
                  defaultValue={
                    empresas[
                      empresas.findIndex(
                        (item) => item.id === empresaElegida.id
                      )
                    ]
                  }
                  getOptionLabel={(option) => option.nombre}
                  openOnFocus={true}
                  disablePortal
                  //disabled={afiliado.titular ? false : true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Empresa *"
                      variant="standard"
                    />
                  )}
                  onKeyDown={handleEnter}
                  onChange={(event, value) => {
                    if (value) {
                      guardarMovimientoCaja({
                        ...movimientoCaja,
                        id_empresa: value.id,
                        empresa: value.nombre,
                      });
                    } else {
                      guardarMovimientoCaja({
                        ...movimientoCaja,
                        id_empresa: null,
                        empresa: null,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  id="concepto"
                  fullWidth
                  required
                  noOptionsText={"No hay opciones"}
                  options={
                    conceptos
                      ? conceptos.filter((item) => item.habilitado)
                      : null
                  }
                  getOptionLabel={(option) => option.nombre + " - " + option.id}
                  openOnFocus={true}
                  disablePortal
                  //disabled={afiliado.titular ? false : true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Concepto *"
                      variant="standard"
                    />
                  )}
                  onKeyDown={handleEnter}
                  onChange={(event, value) => {
                    if (value) {
                      guardarMovimientoCaja({
                        ...movimientoCaja,
                        id_concepto: value.id,
                        concepto: value.nombre,
                      });
                    } else {
                      guardarMovimientoCaja({
                        ...movimientoCaja,
                        id_concepto: null,
                        concepto: null,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  value={movimientoCaja ? movimientoCaja.detalle : null}
                  id="detalle"
                  label="Detalle"
                  fullWidth
                  variant="standard"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onKeyDown={handleEnter}
                  onChange={(e) =>
                    guardarMovimientoCaja({
                      ...movimientoCaja,
                      detalle: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <label htmlFor="importe">Importe *</label>
                <InputNumber
                  id="importe"
                  inputId="importe"
                  required
                  value={movimientoCaja ? movimientoCaja.importe : null}
                  onKeyDown={handleEnter}
                  size={14}
                  onChange={(e) =>
                    guardarMovimientoCaja({
                      ...movimientoCaja,
                      importe: e.value,
                    })
                  }
                  mode="decimal"
                  locale="en-US"
                  minFractionDigits={2}
                  style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Autocomplete
                  id="moneda"
                  size={"small"}
                  fullWidth
                  noOptionsText={"No hay opciones"}
                  options={monedas ? monedas : null}
                  value={
                    monedas &&
                    monedaMovimiento &&
                    monedaMovimiento.id_moneda !== null
                      ? monedas[
                          monedas.findIndex(
                            (item) => item.id === monedaMovimiento.id_moneda
                          )
                        ]
                      : monedas[
                          monedas.findIndex(
                            (item) => item.id === monedaPredeterminada
                          )
                        ]
                  }
                  getOptionLabel={(option) =>
                    option.nombre + " (" + option.simbolo + ")"
                  }
                  openOnFocus={true}
                  disablePortal
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Moneda *"
                      variant="standard"
                    />
                  )}
                  //onKeyDown={handleEnter}
                  onChange={(event, value) => {
                    if (value) {
                      guardarMonedaMovimiento({
                        ...monedaMovimiento,
                        id_moneda: value.id,
                        moneda: value.nombre,
                      });
                      let mon = monedas.findIndex(
                        (item) => item.id === value.id
                      );

                      let cotiza = [];
                      cotiza.push({
                        id: "1",
                        nombre: "Compra",
                        cotizacion: monedas[mon].compra,
                      });
                      cotiza.push({
                        id: "2",
                        nombre: "Venta",
                        cotizacion: monedas[mon].venta,
                      });
                      cotiza.push({
                        id: "3",
                        nombre: "Tarjeta",
                        cotizacion: monedas[mon].tarjeta,
                      });
                      guardarCotizaciones(cotiza);

                    } else {
                      guardarMonedaMovimiento({
                        ...monedaMovimiento,
                        id_moneda: null,
                        moneda: null,
                      });
                      guardarCotizaciones([]);

                    }
                  }}
                />
              </Grid>

              {monedaPredeterminada &&
              monedaMovimiento &&
              monedaMovimiento.id_moneda &&
              monedaMovimiento.id_moneda !== monedaPredeterminada ? (
                <Grid item xs={12} md={4} >
                  <Autocomplete
                    id="cotizacion"
                    size={"small"}
                    fullWidth
                    noOptionsText={"No hay opciones"}
                    options={cotizaciones ? cotizaciones : null}
                    value={
                      cotizacion && cotizacion.cotizacionTipo !== null
                        ? cotizaciones[
                            cotizaciones.findIndex(
                              (item) =>
                                item.nombre === cotizacion.cotizacionTipo
                            )
                          ]
                        : null
                    }
                    getOptionLabel={(option) =>
                      "$ " + option.cotizacion + " (" + option.nombre + ")"
                    }
                    openOnFocus={true}
                    disablePortal
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cotización *"
                        variant="standard"
                      />
                    )}
                    //onKeyDown={handleEnter}
                    onChange={(event, value) => {
                      if (value) {
                        guardarCotizacion({
                          ...cotizacion,
                          cotizacion: value.cotizacion,
                          cotizacionTipo: value.nombre,
                        });
                      } else {
                        guardarCotizacion(null);
                      }
                    }}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Dialog>
        </Fragment>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

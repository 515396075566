import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { Sidebar } from "primereact/sidebar";
import { PanelMenu } from "primereact/panelmenu";
import "../../assets/css/menu.css";
import { Navigate } from "react-router";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import logo1 from "../../assets/images/mediclogo.png";
//import logo1 from "../../assets/images/cardiologo.jpg";
import logo2 from "../../assets/images/cardiologo.jpg";
import { Tooltip } from "primereact/tooltip";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import "../../assets/css/DialogHeader.css";
import { Toast } from "primereact/toast";
import Logo from "./Logo";

function Menu(props) {
  const [visible, setVisible] = useState(false);
  const [band, setBand] = useState(true);

  const toast = useRef(null);
  let location = useLocation();

  const authContext = useContext(AuthContext);
  const { state, autenticado, usuario, usuarioAutenticado, cerrarSesion } =
    authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    configuracion,
    empresas,
    establecimientosUsuarios,
    empresaElegida,
    setEmpresaElegida,
    obtenerEmpresas,
    obtenerConfiguracion,
  } = configuracionContext;

  useEffect(() => {
    usuarioAutenticado();
  }, [autenticado]);

  useEffect(() => {
    let control = [];
    if (empresas && empresas.length > 0) {
      obtenerConfiguracion();

      let es = empresas.find((item) => item.control);

      if (es === undefined) {
        setEmpresaElegida({
          id: Number(localStorage.getItem("empresaElegidaId")),
          nombre: localStorage.getItem("empresaElegidaNombre"),
        });
      } else {
        for (var i = 0; i < empresas.length; i++) {
          if (empresas[i].control) {
            if (
              Number(localStorage.getItem("empresaElegidaId")) ===
              empresas[i].id
            ) {
              setEmpresaElegida({
                id: Number(localStorage.getItem("empresaElegidaId")),
                nombre: localStorage.getItem("empresaElegidaNombre"),
              });
            }
          }
        }
      }
    }
  }, [empresas]);

  useEffect(() => {
    document.body.style.zoom = "95%";
  }, []);



  const items = [
    {
      label: "Achivos",
      icon: "pi pi-fw pi-file",
      items: [
        {
          label: "Monedas y Cotizaciones",
          icon: "pi pi-money-bill",
          url: "/monedas",
        },
        {
          label: "Rubros",
          icon: "pi pi-paperclip",
          url: "/rubros",
        },
        {
          label: "Localidades",
          icon: "pi pi-map-marker",
          url: "/localidades",
        },
        {
          label: "Planes",
          icon: "pi pi-credit-card",
          url: "/planes",
        },
        {
          label: "Asesores",
          icon: "pi pi-user-edit",
          url: "/asesores",
        },
        {
          label: "Cobradores",
          icon: "pi pi-user-edit",
          url: "/cobradores",
        },
        {
          label: "Módulos Adicionales",
          icon: "pi pi-cart-plus",
          url: "/adicionales",
        },
        {
          label: "Empresas",
          icon: "pi pi-building",
          url: "/empresas",
        },
        {
          label: "Bancos",
          icon: "pi pi-home",
          url: "/bancos",
        },
        {
          label: "Cuentas Bancarias",
          icon: "pi pi-tags",
          url: "/cuentas",
        },
        {
          label: "Conceptos",
          icon: "pi pi-sort-amount-down-alt",
          url: "/conceptos",
        },
        {
          label: "Motivos de Bajas",
          icon: "pi pi-minus-circle",
          url: "/motivosbajas",
        },
        {
          label: "Motivos de Suspensión",
          icon: "pi pi-calendar-minus",
          url: "/motivossuspendidos",
        },
        {
          label: "Tipos de Retenciones",
          icon: "pi pi-th-large",
          url: "/tiposretenciones",
        },
      ],
    },
    {
      label: "Afiliados",
      icon: "pi pi-users",
      url: "/clientes",
    },

    {
      label: "Ventas",
      icon: "pi pi-chart-line",
      items: [
        {
          label: "Facturación",
          icon: "pi pi-ticket",
          url: "/facturacion/0/0",
        },
        {
          label: "Consulta Comprobantes",
          icon: "pi pi-bookmark",
          url: "/comprobantes",
        },
        {
          label: "Cierre Período",
          icon: "pi pi-times",
          url: "/cierresperiodos/ventas",
        },
      ],
    },

    {
      label: "Compras",
      icon: "pi pi-cart-plus",
      items: [
        {
          label: "Proveedores",
          icon: "pi pi-users",
          url: "/proveedores",
        },
        {
          label: "Facturación",
          icon: "pi pi-ticket",
          url: "/facturacioncompras/0/0",
        },
        {
          label: "Consulta Comprobantes",
          icon: "pi pi-bookmark",
          url: "/comprobantescompras",
        },
        {
          label: "Ordenes de Pago",
          icon: "pi pi-file-import",
          url: "/ordenespagos/compras",
        },
        {
          label: "Cierre Período",
          icon: "pi pi-times",
          url: "/cierresperiodos/compras",
        },
      ],
    },

    {
      label: "Cobranzas",
      icon: "pi pi-money-bill",
      items: [
        {
          label: "Rendiciones Diarias",
          icon: "pi pi-table",
          items: [
            {
              label: "Pendientes",
              icon: "pi pi-lock-open",
              url: "/rendicionespendientes",
            },
            {
              label: "Aplicadas",
              icon: "pi pi-paperclip",
              url: "/rendicionesaplicadaseliminadas/1",
            },
            {
              label: "Eliminadas",
              icon: "pi pi-trash",
              url: "/rendicionesaplicadaseliminadas/0",
            },
            {
              label: "Anuladas",
              icon: "pi pi-trash",
              url: "#",
            },
          ],
        },
        {
          label: "Rendiciones Especiales",
          icon: "pi pi-th-large",
          items: [
            {
              label: "Débitos Automáticos",
              icon: "pi pi pi-credit-card",
              url: "#",
              //url: "/rendicionesdebitosautomaticos",
            },
            {
              label: "Mercado Pago",
              icon: "pi pi-thumbs-up",
              url: "/rendicionesmercadopago",
            },
          ],
        },
        {
          label: "Cierre Período",
          icon: "pi pi-times",
          url: "/cierresperiodos/cobranzas",
        },
      ],
    },

    {
      label: "Tesorería",
      icon: "pi pi-dollar",
      items: [
        {
          label: "Cajas",
          icon: "pi pi-money-bill",
          url: "/movimientoscajas",
        },
        {
          label: "Cheques",
          icon: "pi pi-credit-card",
          url: "/movimientoscheques",
        },
        {
          label: "Bancos",
          icon: "pi pi-building",
          url: "/movimientosbancos",
        },
        {
          label: "Compensaciones",
          icon: "pi pi-arrow-right-arrow-left",
          url: "/movimientoscompensaciones",
        },
        {
          label: "Ordenes de Pago",
          icon: "pi pi-file-import",
          url: "/ordenespagos/tesoreria",
        },
      ],
    },

    {
      label: "Procesos",
      icon: "pi pi-paperclip",
      items: [
        {
          label: "Facturación",
          icon: "pi pi-align-justify",
          url: "/procesosfacturacion",
        },
        {
          label: "Impresión",
          icon: "pi pi-print",
          url: "/procesosimpresion",
        },
        {
          label: "Subir Padrones de Percepciones",
          icon: "pi pi-upload",
          url: "/uploadpercepciones",
        },
        {
          label: "Importar Padrones de Percepciones",
          icon: "pi pi-cog",
          url: "/importarpercepciones",
        },
        {
          label: "Régimen de Información de Compras y Ventas",
          icon: "pi pi-money-bill",
          url: "/procesoscomprasyventas",
        },
        {
          label: "Percepciones A.R.B.A.",
          icon: "pi pi-percentage",
          url: "/procesospercepciones",
        },
        {
          label: "Débitos Automáticos",
          icon: "pi pi-credit-card",
          url: "/procesosdebitosautomaticos",
        },
      ],
    },
    {
      label: "Accesos",
      icon: "pi pi-fw pi-external-link",
      url: "/accesos",
    },
    {
      label: "Configuración",
      icon: "pi pi-wrench",
      url: "/configuraciones",
    },
    /*
    {
      label: "Protocolos",
      icon: "pi pi-exclamation-triangle",
      url: "/protocolos",
    },
    {
      label: "Controles",
      icon: "pi pi-fw pi-file",
      items: [
        {
          label: "Diabetes",
          icon: "pi pi-pencil",
          url: "/diabetes",
        },
        {
          label: "Escaras",
          icon: "pi pi-circle-fill",
          url: "/escaras",
        },
        {
          label: "Caídas",
          icon: "pi pi-thumbs-down",
          url: "/escaras",
        },
        {
          label: "Escabiosis",
          icon: "pi pi-circle-fill",
          url: "/escaras",
        },
      ],
    },
    */
    {
      label: "Listados",
      icon: "pi pi-list",
      items: [
        {
          label: "Afiliados",
          icon: "pi pi-users",
          items: [
            {
              label: "Cartera",
              icon: "pi pi-briefcase",
              url: "/listadoscartera",
            },
            {
              label: "Altas",
              icon: "pi pi-upload",
              url: "/listadosaltas",
            },
            {
              label: "Bajas",
              icon: "pi pi-download",
              url: "/listadosbajas",
            },
            {
              label: "Suspendidos",
              icon: "pi pi-ban",
              url: "/listadossuspendidos",
            },
            {
              label: "Módulos",
              icon: "pi pi-tags",
              url: "/listadosmodulos",
            },
            {
              label: "Mensajes",
              icon: "pi pi-comment",
              url: "/listadosmensajes",
            },
          ],
        },
        {
          label: "Ventas",
          icon: "pi pi-chart-line",
          items: [
            {
              label: "Comprobantes",
              icon: "pi pi-file",
              url: "/listadoscomprobantesventas",
            },
            {
              label: "Retenciones",
              icon: "pi pi-percentage",
              url: "/listadosretenciones",
            },
            {
              label: "Subdiario Iva Ventas",
              icon: "pi pi-bars",
              url: "/listadosivaventas/0",
            },
            /*
            {
              label: "Listado de Ventas",
              icon: "pi pi-align-center",
              url: "/listadosivaventas/1",
            },
            */
          ],
        },
        {
          label: "Compras",
          icon: "pi pi-cart-plus",
          items: [
            {
              label: "Comprobantes",
              icon: "pi pi-file",
              url: "/listadoscomprobantescompras",
            },
            {
              label: "Subdiario Iva Compras",
              icon: "pi pi-bars",
              url: "/listadosivacompras/0",
            },
            {
              label: "Listado de Compras",
              icon: "pi pi-align-center",
              url: "/listadosivacompras/1",
            },
            {
              label: "Ordenes de Pago",
              icon: "pi pi-bookmark",
              url: "/listadosordenespagos",
            },
          ],
        },
        {
          label: "Cobranzas",
          icon: "pi pi-money-bill",
          items: [
            {
              label: "Cobranzas Pendientes",
              icon: "pi pi-clock",
              url: "/listadoscobranzaspendientes",
            },
            {
              label: "Cobranzas Realizadas",
              icon: "pi pi-thumbs-up",
              url: "/listadoscobranzasrealizadas",
            },
            {
              label: "Planilla de Cobranzas Realizadas",
              icon: "pi pi-pen-to-square",
              url: "/listadoscobranzasplanilla",
            },
          ],
        },
        {
          label: "Tesoreria",
          icon: "pi pi-dollar",
          items: [
            {
              label: "Movimientos",
              icon: "pi pi-arrows-h",
              url: "/listadomovimientos",
            },
          ],
        },
      ],
    },

    {
      label: "Informes",
      icon: "pi pi-book",
      items: [
        {
          label: "Procesos Facturación",
          icon: "pi pi-align-justify",
          url: "/informesprocesosfacturacion",
        },
      ],
    },
  ];

  const leftContents = (
    <Fragment>
      {usuario !== undefined && usuario !== null && empresas ? (
        <Fragment>
          <Sidebar visible={visible} onHide={() => setVisible(false)}>
            <PanelMenu model={items} style={{ width: "250px" }} />
          </Sidebar>
          <Button
            icon="pi pi-bars"
            onClick={(e) => setVisible(true)}
            tooltip="Menú"
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
            }}
            disabled={
              !empresaElegida ||
              empresaElegida.id === 0 ||
              empresaElegida.nombre === null
            }
          />
          {configuracion && configuracion.destinatario === 1 ? (
            <Link to="/">
              <img
                src={logo1}
                alt="Logo"
                height="50"
                style={{ marginLeft: "2em", marginTop: "-0.3em" }}
              />
            </Link>
          ) : null}
          {configuracion && configuracion.destinatario === 2 ? (
            <Link to="/">
              <img
                src={logo2}
                alt="Logo"
                height="50"
                style={{ marginLeft: "2em", marginTop: "-0.3em" }}
              />
            </Link>
          ) : null}
          {empresaElegida !== null &&
          empresaElegida.id !== null &&
          empresaElegida.id !== 0 ? (
            <Typography
              style={{
                marginLeft: "3vw",
                color: "black",
                textAlign: "center",
                fontWeight: "bold",
                fontStyle: "italic",
              }}
              variant="h4"
              gutterBottom
              component="div"
            >
              {empresaElegida.nombre}
            </Typography>
          ) : null}
        </Fragment>
      ) : null}
    </Fragment>
  );

  const rightContents = (
    <Fragment>
      {usuario === undefined || usuario === null ? (
        <Button
          icon="pi pi-sign-in"
          className="p-mr-2"
          onClick={() => (window.location.href = "/login")}
          tooltip="Iniciar Sesión"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
        />
      ) : (
        <Fragment>
          <Autocomplete
            disablePortal
            id="empresa"
            options={
              empresas &&
              empresas.filter((item) => item.control && item.habilitado)
                .length > 0
                ? empresas.filter((item) => item.control && item.habilitado)
                : empresas && empresas.filter((item) => item.habilitado)
                ? empresas.filter((item) => item.habilitado)
                : null
            }
            sx={{ width: 300 }}
            getOptionLabel={(option) => option.nombre}
            renderInput={(params) => (
              <TextField
                {...params}
                //variant="standard"
                label="Empresa Seleccionada *"
                size="small"
              />
            )}
            style={{ marginRight: "1vw" }}
            onChange={(event, value) => {
              if (value) {
                setEmpresaElegida({
                  id: Number(value.id),
                  nombre: value.nombre,
                });
                localStorage.setItem("empresaElegidaId", value.id);
                localStorage.setItem("empresaElegidaNombre", value.nombre);
                window.location.href = "/";
              }
            }}
          />

          <Typography variant="overline" display="block" gutterBottom>
            {state.usuario.usuario}
          </Typography>

          <Button
            style={{ marginLeft: "1vw" }}
            icon="pi pi-sign-out"
            className="p-mr-2"
            onClick={() => cerrarSesion()}
            tooltip="Cerrar Sesión"
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
            }}
          />
        </Fragment>
      )}
      {/*
      <Button icon="pi pi-calendar" className="p-button-success p-mr-2" />
      <Button icon="pi pi-times" className="p-button-danger" />
      */}
    </Fragment>
  );

  const handleClick = useCallback((path) => {
    return () => {
      props.history.push(path);
    };
  }, []);


  if (band && usuario !== undefined && usuario !== null) {
    obtenerEmpresas();
    setBand(false);
  }

  return (
    <div>
      <Toast ref={toast} />
      {usuario !== undefined && usuario !== null ? (
        <Toolbar left={leftContents} right={rightContents} />
      ) : (
        <Fragment>
        <Toolbar left={leftContents} right={rightContents} />
        {location && location.pathname === '/' ?
        <Logo />
        : null }
        </Fragment>
      )}
    </div>
  );
}

export default Menu;

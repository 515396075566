import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";
import { InputNumber } from "primereact/inputnumber";

export default function Monedas(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  let navigate = useNavigate();

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Nombre", dataKey: "nombre" },
    { title: "Compra", dataKey: "compra" },
    { title: "Venta", dataKey: "venta" },
    { title: "Símbolo", dataKey: "simbolo" },
  ];

  const [moneda, guardarMoneda] = useState(null);
  const [visible, setVisible] = useState(false);
  const [detalle, guardarDetalle] = useState(null);
  const [fecha, guardarFecha] = useState(null);
  const toast = useRef(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    monedas,
    obtenerMonedas,
    obtenerAcceso,
    agregarMoneda,
    actualizarMoneda,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Monedas" });
    }
  }, [state]);

  useEffect(() => {
    obtenerMonedas();
  }, []);

  const editClick = (e) => {
    if (selectedRow) {
      guardarMoneda(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un moneda.",
        life: 3000,
      });
    }
  };

  const addClick = (e) => {
    //props.history.push(`/moneda/0`);
    setVisible(true);
    setSelectedRow(null);
    guardarMoneda({
      habilitado: true,
      inicial: false,
      propio: false,
    });
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !moneda ||
      moneda.nombre === undefined ||
      moneda.nombre === null ||
      moneda.nombre.trim() === ""
    ) {
      mensajeAlerta("Monedas", "Nombre es necesario !.", "error");
      return;
    }

    if (
      !moneda ||
      moneda.compra === undefined ||
      moneda.compra === null ||
      moneda.compra <= 0
    ) {
      mensajeAlerta("Monedas", "Valor de compra es necesario !.", "error");
      return;
    }

    if (
      !moneda ||
      moneda.venta === undefined ||
      moneda.venta === null ||
      moneda.venta <= 0
    ) {
      mensajeAlerta("Monedas", "Valor de venta es necesario !.", "error");
      return;
    }

    if (
      !moneda ||
      moneda.simbolo === undefined ||
      moneda.simbolo === null ||
      moneda.simbolo.trim() === ""
    ) {
      mensajeAlerta("Monedas", "El símbolo es necesario !.", "error");
      return;
    }

    // Llamar al context
    if (selectedRow) {
      actualizarMoneda({ moneda });
    } else {
      agregarMoneda({ moneda });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerMonedas();
    }, 2000);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Monedas",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    thisTime[0] = Number(thisTime[0] - 3) 
    thisTime[0] = String(thisTime[0])
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  const updateAtTemplate = (rowData) => {
    return (
      <Fragment>
        {formatDateTime(rowData.updatedAt)}
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, monedas);
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(monedas);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "monedas");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Monedas"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Moneda"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  //console.log(monedas);
  //console.log(state);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {monedas ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ align: "center", margin: "1em" }}
              >
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-pencil"
                  tooltip="Editar"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={monedas}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        //body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="nombre"
                        header="Nombre"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="compra"
                        header="Compra"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="venta"
                        header="Venta"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="tarjeta"
                        header="Tarjeta"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="simbolo"
                        header="Símbolo"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="updatedAt"
                        header="Actualización"
                        body={updateAtTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                  {selectedRow ? (
                    <Grid item xs={12} md={2}>
                      <TextField
                        value={moneda ? moneda.id : null}
                        required
                        variant="standard"
                        id="id"
                        label="ID"
                        fullWidth
                        disabled
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={10}>
                    <TextField
                      value={moneda ? moneda.nombre : null}
                      required
                      variant="standard"
                      id="nombre"
                      label="Nombre"
                      fullWidth
                      onChange={(e) =>
                        guardarMoneda({
                          ...moneda,
                          nombre: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <label htmlFor="cuota">Valor Compra *</label>
                    <InputNumber
                      id="compra"
                      inputId="compra"
                      value={moneda ? moneda.compra : null}
                      onChange={(e) =>
                        guardarMoneda({
                          ...moneda,
                          compra: e.value,
                        })
                      }
                      mode="decimal"
                      locale="en-US"
                      minFractionDigits={2}
                      style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <label htmlFor="cuota">Valor Venta *</label>
                    <InputNumber
                      id="venta"
                      inputId="venta"
                      value={moneda ? moneda.venta : null}
                      onChange={(e) =>
                        guardarMoneda({
                          ...moneda,
                          venta: e.value,
                        })
                      }
                      mode="decimal"
                      locale="en-US"
                      minFractionDigits={2}
                      style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <label htmlFor="cuota">Valor Tarjeta *</label>
                    <InputNumber
                      id="tarjeta"
                      inputId="tarjeta"
                      value={moneda ? moneda.tarjeta : null}
                      onChange={(e) =>
                        guardarMoneda({
                          ...moneda,
                          tarjeta: e.value,
                        })
                      }
                      mode="decimal"
                      locale="en-US"
                      minFractionDigits={2}
                      style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      value={moneda ? moneda.simbolo : null}
                      required
                      variant="standard"
                      id="simbolo"
                      label="Símbolo"
                      fullWidth
                      onChange={(e) =>
                        guardarMoneda({
                          ...moneda,
                          simbolo: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

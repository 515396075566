import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";
import { InputNumber } from "primereact/inputnumber";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { InputMask } from "primereact/inputmask";
import IconButton from "@mui/material/IconButton";
import * as EmailValidator from "email-validator";
import { Dropdown } from "primereact/dropdown";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const tipos = [
  { tipo: "A", code: "A" },
  { tipo: "B", code: "B" },
  { tipo: "C", code: "C" },
  { tipo: "M", code: "M" },
];

export default function Facturacion(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRowClientes, setSelectedRowClientes] = useState(null);
  const [selectedRowComprobantes, setSelectedRowComprobantes] = useState(null);
  const [selectedField, setSelectedField] = useState("Número de socio");
  const [comprobante, guardarComprobante] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleEnvios, setVisibleEnvios] = useState(false);
  const [articulo, setArticulo] = useState(null);
  const [rows, setRows] = useState([]);
  const [bandFinal, setBandFinal] = useState(false);
  const [email, setEmail] = useState(false);
  const [whatsApp, setWhatsApp] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [bandInicio1, setBandInicio1] = useState(false);
  const [bandInicio2, setBandInicio2] = useState(false);
  const [bandInicio3, setBandInicio3] = useState(false);
  const [bandInicio4, setBandInicio4] = useState(false);
  const [bandInicio5, setBandInicio5] = useState(false);
  const [bandInicio6, setBandInicio6] = useState(false);
  const [bandId, setBandId] = useState(false);
  const [tiponc, setTiponc] = useState("");
  const [alerta, setAlerta] = useState(false);

  let navigate = useNavigate();

  let { id, titularId } = useParams();

  const idProps = props.idProps;
  const titularIdProps = props.titularIdProps;

  if (id === undefined && idProps !== undefined) {
    id = idProps;
    titularId = titularIdProps;
  }

  const opciones = [
    { label: "Número de socio", value: "id" },
    { label: "Apellido y nombre", value: "nombre" },
    { label: "Domicilio", value: "domicilio" },
    { label: "Teléfono", value: "telefono" },
    { label: "Documento", value: "dni" },
  ];
  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Descripción", dataKey: "descripcion" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const toast = useRef(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    statusAcceso2,
    statusAcceso3,
    comprobantes,
    clientes,
    cliente,
    empresa,
    ivas,
    empresaElegida,
    titular,
    numeroComprobante,
    tiposComprobantes,
    comprobanteAsociado,
    idComprobante,
    factura,
    items,
    urlPdf,
    cierre,
    verComprobante,
    configuracionesEmpresas,
    configuracionesCobradores,
    configuracionesFormasPagos,
    obtenerComprobantes,
    obtenerAcceso,
    obtenerAcceso2,
    obtenerAcceso3,
    agregarComprobante,
    actualizarComprobante,
    obtenerClientes,
    buscarTitular,
    guardarCliente,
    guardarComprobantes,
    guardarTitular,
    obtenerTiposComprobantes,
    obtenerNumeroComprobante,
    guardarNumeroComprobante,
    obtenerIvas,
    guardarClientes,
    obtenerComprobanteAsociado,
    guardarComprobanteAsociado,
    guardarIdComprobante,
    obtenerConfiguracionesFacturacion,
    crearComprobantePdf,
    enviarEmail,
    enviarWhatsApp,
    obtenerFactura,
    setVerComprobante,
    guardarUrlPdf,
    guardarFactura,
    obtenerCierre,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      if (id === "0") {
        obtenerAcceso({
          usuarioId: state.usuario.id,
          layout: "GenerarComprobantes",
        });
        obtenerAcceso2({
          usuarioId: state.usuario.id,
          layout: "GenerarAjustes",
        });
        obtenerAcceso3({
          usuarioId: state.usuario.id,
          layout: "GenerarNc",
        });
      }
    }
  }, [state]);

  useEffect(() => {
    obtenerIvas();
    //obtenerConfiguracionesFacturacion();
  }, []);

  const editClick = (e) => {
    guardarNumeroComprobante(null);
    setArticulo(null);
    //setRows([]);
    guardarComprobanteAsociado(null);
    guardarIdComprobante(null);

    setTimeout(() => {
      let elem = document.getElementById("verComprobante");
      elem.click();
    }, 500);
  };

  const returnClick = (e) => {
    if (id === "0") {
      setSelectedRowClientes(null);
      guardarTitular(null);
      guardarCliente(null);
      guardarComprobantes([]);
      guardarNumeroComprobante(null);
      setArticulo(null);
      setRows([]);
      guardarClientes([]);
      guardarIdComprobante(null);
    } else {
      //guardarComprobantes([]);
      guardarNumeroComprobante(null);
      setArticulo(null);
      setRows([]);
      guardarIdComprobante(null);
    }
  };

  const findClick = (e) => {
    if (selectedField === null) {
      toast.current.show({
        severity: "warn",
        summary: "Información",
        detail: "Debe escribir algúna opción de busqueda.",
        life: 3000,
      });
      return;
    }

    if (globalFilter && globalFilter.trim().length === 0) {
      toast.current.show({
        severity: "warn",
        summary: "Información",
        detail: "Debe escribir algún texto a buscar.",
        life: 3000,
      });
      return;
    }

    obtenerClientes({
      opcion: selectedField,
      texto: globalFilter,
      empresaId: empresaElegida.id,
    });
  };

  const addClick = (e) => {
    //props.history.push(`/comprobante/0`);
    if (!empresa) {
      return;
    }

    if (
      !empresaElegida ||
      empresaElegida.id === 0 ||
      empresaElegida.nombre === null
    ) {
      toast.current.show({
        severity: "warn",
        summary: "Información",
        detail: "Debe seleccionar una empresa.",
        life: 3000,
      });
      return;
    }

    setVisible(true);
    //setSelectedRow(null);

    guardarComprobante({
      titularId: titular.id_titular,
      nombre: titular.nombre_razonsocial,
      tipoContribuyenteId: titular.tiposcontribuyentes.id,
      tipoContribuyente: titular.tiposcontribuyentes.nombre,
      numeroDocumento: titular.numerodocumento,
      domicilio: titular.domicilio,
      email: titular.email,
      celular: titular.celular,
      puntoVenta: null,
      numero: null,
      facturaElectronica: empresa && empresa.facturaElectronica ? true : false,
      fecha: new Date().toISOString().slice(0, 10),
      periodo:
        new Date().toISOString().slice(0, 10).substring(5, 7) +
        "/" +
        new Date().toISOString().slice(0, 10).substring(2, 4),
      pdf: false,
    });

    guardarNumeroComprobante(null);
    setArticulo(null);
    setRows([]);
    guardarComprobanteAsociado(null);
    guardarIdComprobante(null);

    setTimeout(() => {
      let campo = document.getElementById("tipoComprobante");
      campo.focus();
    }, 1000);
  };

  const exitClick = (e) => {
    setSelectedRowClientes(null);
    guardarTitular(null);
    guardarCliente(null);
    guardarComprobantes([]);
    guardarNumeroComprobante(null);
    setArticulo(null);
    setRows([]);
    guardarClientes([]);
    guardarIdComprobante(null);
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
    setVerComprobante(false);
    /*
    if (id !== "0") {
      navigate("/comprobantes");
    }
    */
  };

  const onPdf = (e) => {
    guardarUrlPdf(null);
    guardarIdComprobante(id);
  };

  function ValidateCUITCUIL(cuit) {
    if (cuit.length != 13) return 0;

    var rv = false;
    var resultado = 0;
    var cuit_nro = cuit.replace("-", "");
    var codes = "6789456789";
    var cuit_long = parseInt(cuit_nro);
    var verificador = parseInt(cuit_nro[cuit_nro.length - 1]);
    var x = 0;

    while (x < 10) {
      var digitoValidador = parseInt(codes.substring(x, x + 1));
      if (isNaN(digitoValidador)) digitoValidador = 0;
      var digito = parseInt(cuit_nro.substring(x, x + 1));
      if (isNaN(digito)) digito = 0;
      var digitoValidacion = digitoValidador * digito;
      resultado += digitoValidacion;
      x++;
    }
    resultado = resultado % 11;
    rv = resultado == verificador;
    return rv;
  }

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar

    if (
      titular &&
      titular.id_tipodocumento === 3 && // C.U.I.T
      !ValidateCUITCUIL(titular.numerodocumento)
    ) {
      mensajeAlerta(
        "Comprobantes",
        "El Nº de C.U.I.T del titular es incorrecto !.",
        "error"
      );
      return;
    }

    if (
      !comprobante ||
      comprobante.comprobanteId === undefined ||
      comprobante.comprobanteId === null ||
      comprobante.comprobanteId === 0 ||
      comprobante.comprobanteId === ""
    ) {
      mensajeAlerta("Comprobantes", "Comprobante es necesario !.", "error");
      return;
    }

    if (
      !comprobante ||
      comprobante.puntoVenta === undefined ||
      comprobante.puntoVenta === null ||
      comprobante.puntoVenta === 0 ||
      comprobante.puntoVenta === ""
    ) {
      mensajeAlerta("Comprobantes", "Punto de venta es necesario !.", "error");
      return;
    }

    if (
      !comprobante ||
      comprobante.numero === undefined ||
      comprobante.numero === null ||
      comprobante.numero === 0 ||
      comprobante.numero === ""
    ) {
      mensajeAlerta("Comprobantes", "Número es necesario !.", "error");
      return;
    }

    if (
      !comprobante ||
      comprobante.fecha === undefined ||
      comprobante.fecha === null ||
      comprobante.fecha === 0 ||
      comprobante.fecha === ""
    ) {
      mensajeAlerta("Comprobantes", "Fecha es necesario !.", "error");
      return;
    }

    if (
      !comprobante ||
      comprobante.periodo === undefined ||
      comprobante.periodo === null ||
      comprobante.periodo === 0 ||
      comprobante.periodo === ""
    ) {
      mensajeAlerta("Comprobantes", "Período es necesario !.", "error");
      return;
    }

    if (
      Number(comprobante.periodo.substring(0, 2)) < 1 ||
      Number(comprobante.periodo.substring(0, 2)) > 12 ||
      Number(comprobante.periodo.substring(3, 5)) > 25 ||
      Number(comprobante.periodo.substring(3, 5)) < 22
    ) {
      mensajeAlerta("Comprobantes", "Período es incorrecto !.", "error");
      return;
    }

    let Id = Number(comprobante.comprobanteId);

    if (
      (Id === 2 ||
        Id === 3 ||
        Id === 7 ||
        Id === 8 ||
        Id === 12 ||
        Id === 13 ||
        Id === 52 ||
        Id === 53 ||
        Id === 202 ||
        Id === 203 ||
        Id === 207 ||
        Id === 208 ||
        Id === 212 ||
        Id === 213 ||
        Id === 1000 ||
        Id === 1001) &&
      !comprobanteAsociado &&
      id === "0"
    ) {
      mensajeAlerta(
        "Comprobantes",
        "Debe ingresar el comprobante asociado !.",
        "error"
      );
      return;
    }

    if (
      (Id === 3 ||
        Id === 8 ||
        Id === 13 ||
        Id === 53 ||
        Id === 203 ||
        Id === 208 ||
        Id === 212 ||
        Id === 213 ||
        Id === 1001) &&
      comprobanteAsociado &&
      id === "0" &&
      Number(comprobante.total) > Number(comprobanteAsociado.saldo)
    ) {
      mensajeAlerta(
        "Comprobantes",
        "La nota de crédito no puede superar el saldo del comprobante asociado !.",
        "error"
      );
      return;
    }

    if (
      (Id === 201 || Id === 206 || Id === 211) &&
      (empresa.cbu === null || empresa.cbu === "")
    ) {
      mensajeAlerta(
        "Comprobantes",
        "La empresa no tiene C.B.U. cargado !.",
        "error"
      );
      return;
    }

    if (rows.length === 0) {
      mensajeAlerta(
        "Comprobantes",
        "Debe ingresar al menos un servicio !.",
        "error"
      );
      return;
    }

    // Llamar al context

    if (id === "0") {
      agregarComprobante({
        comprobante,
        empresa,
        titular,
        articulos: rows,
        usuario: state.usuario.id,
        comprobanteAsociado,
      });
    } else {
      guardarIdComprobante(id);
    }
    //onHide();
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Comprobantes",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    //exitClick();
    return;
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const titularTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.titular}></Checkbox>
      </Fragment>
    );
  };

  const suspendidoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.suspendido}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, comprobantes);
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(comprobantes);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "comprobantes");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const headerClientes = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          defaultValue={opciones[0]}
          options={opciones}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Opciones de Búsqueda" />
          )}
          onChange={(event, value) => {
            if (value) {
              setSelectedField(value.label);
            } else {
              setSelectedField(null);
            }
          }}
        />

        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
            onKeyDown={handleEnterGlobal}
          />
        </span>
        <Button
          icon="pi pi-search"
          tooltip="Buscar"
          className="p-button-rounded p-button-help"
          style={{ marginLeft: "0em" }}
          onClick={findClick}
        />
        <Tag
          value="Afiliados - Facturación"
          style={{ marginLeft: "1em" }}
        ></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Comprobante"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        {id === "0" ? (
          <Fragment>
            <ButtonMui
              onClick={aceptarClick}
              color="primary"
              type="button"
              fullWidth
              variant="contained"
              startIcon={<CheckIcon />}
              disabled={
                titular &&
                empresa &&
                titular.id_empresa_facturacion !== empresa.id
              }
            >
              Aceptar
            </ButtonMui>
            <ButtonMui
              onClick={onHide}
              color="secondary"
              type="button"
              fullWidth
              variant="contained"
              startIcon={<CloseIcon />}
              autoFocus
            >
              Cancelar
            </ButtonMui>
          </Fragment>
        ) : (
          <Fragment>
            <ButtonMui
              onClick={onHide}
              color="secondary"
              type="button"
              fullWidth
              variant="contained"
              startIcon={<CloseIcon />}
              autoFocus
            >
              Cancelar
            </ButtonMui>
            <ButtonMui
              onClick={(e) => onPdf()}
              color="error"
              type="button"
              fullWidth
              disabled={comprobante && !comprobante.pdf}
              variant="contained"
              startIcon={<PictureAsPdfIcon />}
              autoFocus
            >
              Pdf
            </ButtonMui>
          </Fragment>
        )}
      </div>
    );
  };

  const headerComprobantes = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />

        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>

        <Tag
          value={
            selectedRowClientes && titular
              ? "Comprobantes Afiliado: " +
                titular.id_titular +
                " " +
                titular.nombre_razonsocial
              : null
          }
          style={{ marginLeft: "1em" }}
        ></Tag>
      </div>
    </Fragment>
  );

  function handleEnterGlobal(e) {
    if (e.keyCode === 13) {
      findClick();
    }
  }

  function handleEnter(event) {
    if (event.keyCode === 13) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "tipoComprobante":
          campo = document.getElementById("cantidad");
          campo.focus();
          break;
        case "fecha":
          campo = document.getElementById("cantidad");
          campo.focus();
          break;
        case "precioUnitario":
          campo = document.getElementById("iva");
          campo.focus();
          break;
        case "iva":
          campo = document.getElementById("precioTotal");
          campo.focus();
          break;
        case "precioTotal":
          campo = document.getElementById("agregarArticulo");
          campo.focus();
          break;
      }
    }

    if (event.keyCode === 27) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "fecha":
          campo = document.getElementById("tipoComprobante");
          campo.focus();
          break;
        case "cantidad":
          campo = document.getElementById("fecha");
          campo.focus();
          break;
        case "descripcion":
          campo = document.getElementById("cantidad");
          campo.focus();
          break;
        case "precioUnitario":
          campo = document.getElementById("descripcion");
          campo.focus();
          break;
        case "iva":
          campo = document.getElementById("precioUnitario");
          campo.focus();
          break;
        case "precioTotal":
          campo = document.getElementById("iva");
          campo.focus();
          break;
      }
    }
  }

  const handleBlurUnitario = (e) => {
    setArticulo({
      ...articulo,
      precioTotal: Number(articulo.cantidad) * Number(articulo.precioUnitario),
    });
  };

  const handleBlurFecha = (e) => {
    let fecha = e.target.value;
    let periodo = fecha.substring(5, 7) + "/" + fecha.substring(2, 4);
    const cierre = {
      periodo: periodo,
    };
    const origen = "ventas";
    obtenerCierre({ cierre, origen });
    setAlerta(true);
  };

  const buscarCuota = (e) => {
    setArticulo({
      ...articulo,
      precioUnitario: 1850,
    });

    let campo = document.getElementById("precioUnitario");
    campo.focus();
  };

  const clickAgregar = (e) => {
    if (rows && rows.length >= 5) {
      toast.current.show({
        severity: "warn",
        summary: "Servicio",
        detail: "No puede agregar mas de 5 items.",
        life: 3000,
      });
      return;
    }

    if (
      !articulo ||
      articulo.cantidad === undefined ||
      articulo.cantidad === null ||
      empresaElegida.cantidad === ""
    ) {
      toast.current.show({
        severity: "warn",
        summary: "Servicio",
        detail: "Debe indicar la cantidad.",
        life: 3000,
      });
      return;
    }

    if (
      !articulo ||
      articulo.cantidad === undefined ||
      articulo.cantidad === null ||
      empresaElegida.cantidad < 0
    ) {
      toast.current.show({
        severity: "warn",
        summary: "Servicio",
        detail: "Debe indicar una cantidad válida.",
        life: 3000,
      });
      return;
    }

    if (
      !articulo ||
      articulo.descripcion === undefined ||
      articulo.descripcion === null ||
      articulo.descripcion === ""
    ) {
      toast.current.show({
        severity: "warn",
        summary: "Servicio",
        detail: "Debe indicar una descripción.",
        life: 3000,
      });
      return;
    }

    if (articulo && Number(articulo.cantidad) !== 0) {
      if (
        !articulo ||
        articulo.precioUnitario === undefined ||
        articulo.precioUnitario === null ||
        articulo.precioTotal === ""
      ) {
        toast.current.show({
          severity: "warn",
          summary: "Servicio",
          detail: "Debe indicar precio unitario.",
          life: 3000,
        });
        return;
      }

      if (
        !articulo ||
        articulo.iva === undefined ||
        articulo.iva === null ||
        articulo.iva === ""
      ) {
        toast.current.show({
          severity: "warn",
          summary: "Servicio",
          detail: "Debe indicar tasa I.V.A.",
          life: 3000,
        });
        return;
      }
    }

    let ivaDivide = 0;
    let iva = 0;
    let subtotal = 0;

    let local = rows;

    if (Number(articulo.cantidad) === 0) {
      local.push({
        cantidad: articulo.cantidad,
        descripcion: articulo.descripcion,
        unitario: parseFloat(0).toFixed(2),
        ivaTasa: parseFloat(0).toFixed(2),
        subtotal: parseFloat(0).toFixed(2),
        exento: parseFloat(0).toFixed(2),
        subtotalNoGravado: parseFloat(0).toFixed(2),
        subtotalGravado: parseFloat(0).toFixed(2),
        iva: parseFloat(0).toFixed(2),
        total: parseFloat(0).toFixed(2),
        tasaArba: parseFloat(0).toFixed(2),
        percArba: parseFloat(0).toFixed(2),
        tasaAgip: parseFloat(0).toFixed(2),
        percAgip: parseFloat(0).toFixed(2),
      });
    } else {
      ivaDivide = articulo.iva.tasa / 100 + 1;
      subtotal = articulo.precioTotal / ivaDivide;
      iva = articulo.precioTotal - subtotal;
      local.push({
        cantidad: articulo.cantidad,
        descripcion: articulo.descripcion,
        unitario: parseFloat(articulo.precioUnitario).toFixed(2),
        ivaTasa: parseFloat(articulo.iva.tasa).toFixed(2),
        ...(empresa.id_tipocontribuyente === 2 ||
        empresa.id_tipocontribuyente === 3 /*EXENTO/MONOT*/
          ? {
              exento: parseFloat(0).toFixed(2),
              subtotalNoGravado: parseFloat(0).toFixed(2),
              subtotalGravado: parseFloat(subtotal).toFixed(2),
            }
          : {}),

        ...(empresa.id_tipocontribuyente === 6 /*RESP. INSC*/
          ? {
              ...(titular.id_tipocontribuyente === 2 /*EXENTO*/
                ? {
                    exento: parseFloat(0).toFixed(2),
                    subtotalNoGravado: parseFloat(0).toFixed(2),
                    subtotalGravado: parseFloat(subtotal).toFixed(2),
                  }
                : {
                    ...(articulo.iva.tasa === "0.00"
                      ? {
                          exento: parseFloat(0).toFixed(2),
                          //subtotalNoGravado: parseFloat(subtotal).toFixed(2),
                          //subtotalGravado: parseFloat(0).toFixed(2),
                          subtotalNoGravado: parseFloat(0).toFixed(2),
                          subtotalGravado: parseFloat(subtotal).toFixed(2),
                        }
                      : {
                          exento: parseFloat(0).toFixed(2),
                          subtotalNoGravado: parseFloat(0).toFixed(2),
                          subtotalGravado: parseFloat(subtotal).toFixed(2),
                        }),
                  }),
            }
          : {}),

        iva: parseFloat(iva).toFixed(2),
        total: parseFloat(articulo.precioTotal).toFixed(2),
        ...(empresa &&
        empresa.percepcionArba === 1 &&
        titular.marcaarba &&
        Number(titular.alicuotaarba > 0) &&
        comprobante.facturaElectronica
          ? {
              tasaArba: parseFloat(titular.alicuotaarba).toFixed(2),
              percArba: parseFloat(
                (subtotal * titular.alicuotaarba) / 100
              ).toFixed(2),
            }
          : {
              tasaArba: parseFloat(0).toFixed(2),
              percArba: parseFloat(0).toFixed(2),
            }),
        ...(empresa &&
        empresa.percepcionAgip === 1 &&
        titular.marcaagip &&
        Number(titular.alicuotaagip > 0) &&
        comprobante.facturaElectronica
          ? {
              tasaAgip: parseFloat(titular.alicuotaagip).toFixed(2),
              percAgip: parseFloat(
                (subtotal * titular.alicuotaagip) / 100
              ).toFixed(2),
            }
          : {
              tasaAgip: parseFloat(0).toFixed(2),
              percAgip: parseFloat(0).toFixed(2),
            }),
      });
    }

    let comprobanteSubtotalGravado = 0;
    let comprobanteSubtotalNoGravado = 0;
    let comprobanteExento = 0;
    let comprobanteIva0 = 0;
    let comprobanteIva105 = 0;
    let comprobanteIva21 = 0;
    let comprobanteIva27 = 0;
    let comprobanteIva = 0;
    let comprobanteTasaPercArba = 0;
    let comprobanteTasaPercAgip = 0;
    let comprobantePercArba = 0;
    let comprobantePercAgip = 0;
    let comprobanteTotal = 0;

    for (var i = 0; i < local.length; i++) {
      comprobanteSubtotalGravado =
        comprobanteSubtotalGravado + Number(local[i].subtotalGravado);
      comprobanteSubtotalNoGravado =
        comprobanteSubtotalNoGravado + Number(local[i].subtotalNoGravado);
      comprobanteExento = comprobanteExento + Number(local[i].exento);
      comprobanteIva = comprobanteIva + Number(local[i].iva);
      if (local[i].ivaTasa === "0.00") {
        comprobanteIva0 = 1;
      }
      if (local[i].ivaTasa === "10.50") {
        comprobanteIva105 = comprobanteIva105 + Number(local[i].iva);
      }
      if (local[i].ivaTasa === "21.00") {
        comprobanteIva21 = comprobanteIva21 + Number(local[i].iva);
      }
      if (local[i].ivaTasa === "27.00") {
        comprobanteIva27 = comprobanteIva27 + Number(local[i].iva);
      }
      comprobanteTasaPercArba = local[i].tasaArba;
      comprobantePercArba = comprobantePercArba + Number(local[i].percArba);
      comprobanteTasaPercAgip = local[i].tasaAgip;
      comprobantePercAgip = comprobantePercAgip + Number(local[i].percAgip);
      comprobanteTotal =
        comprobanteTotal +
        Number(local[i].total) +
        Number(local[i].percArba) +
        Number(local[i].percAgip);
    }

    guardarComprobante({
      ...comprobante,
      subtotalNoGravado: parseFloat(comprobanteSubtotalNoGravado).toFixed(2),
      subtotalGravado: parseFloat(comprobanteSubtotalGravado).toFixed(2),
      subtotal: parseFloat(
        comprobanteSubtotalGravado + comprobanteSubtotalNoGravado
      ).toFixed(2),
      exento: parseFloat(comprobanteExento).toFixed(2),
      iva: parseFloat(comprobanteIva).toFixed(2),
      iva0: comprobanteIva0,
      iva105: parseFloat(comprobanteIva105).toFixed(2),
      iva21: parseFloat(comprobanteIva21).toFixed(2),
      iva27: parseFloat(comprobanteIva27).toFixed(2),
      tasaPercArba: parseFloat(comprobanteTasaPercArba).toFixed(2),
      percArba: parseFloat(comprobantePercArba).toFixed(2),
      tasaPercAgip: parseFloat(comprobanteTasaPercAgip).toFixed(2),
      percAgip: parseFloat(comprobantePercAgip).toFixed(2),
      total: parseFloat(comprobanteTotal).toFixed(2),
      impuestos: parseFloat(comprobantePercArba + comprobantePercAgip).toFixed(
        2
      ),
    });

    setRows(local);

    setArticulo({
      ...articulo,
      cantidad: null,
      descripcion: null,
      precioUnitario: null,
      precioTotal: null,
    });

    let campo = document.getElementById("cantidad");
    campo.focus();
  };

  const buscarComprobanteAsociado = (puntoVentanc, numeronc, tiponc) => {
    if (
      !puntoVentanc ||
      puntoVentanc === undefined ||
      puntoVentanc === null ||
      puntoVentanc === ""
    ) {
      toast.current.show({
        severity: "warn",
        summary: "Comprobante",
        detail: "El punto de venta asociado es necesario. !",
        life: 3000,
      });
      return;
    }

    if (
      !numeronc ||
      numeronc === undefined ||
      numeronc === null ||
      numeronc === ""
    ) {
      toast.current.show({
        severity: "warn",
        summary: "Comprobante",
        detail: "El número asociado es necesario. !",
        life: 3000,
      });
      return;
    }

    obtenerComprobanteAsociado({
      puntoVentanc: Number(puntoVentanc),
      numeronc: Number(numeronc),
      tiponc,
      titular,
      id: comprobante.comprobanteId,
    });
  };

  // INICIO de IF

  if (
    numeroComprobante &&
    comprobante &&
    comprobante.puntoVenta === null &&
    comprobante.numero === null &&
    titular
  ) {
    guardarComprobante({
      ...comprobante,
      puntoVenta: numeroComprobante.puntoVenta.toString().padStart(4, "0"),
      numero: Number(numeroComprobante.numero).toString().padStart(8, "0"),
      ...(comprobante &&
      (comprobante.comprobanteAbreviatura === "AJDX" ||
        comprobante.comprobanteAbreviatura === "AJCX")
        ? { facturaElectronica: false }
        : {}),
    });

    if (
      comprobante &&
      (comprobante.comprobanteAbreviatura === "AJDX" ||
        comprobante.comprobanteAbreviatura === "AJCX")
    ) {
      setArticulo({
        ...articulo,
        iva: ivas[3],
      });
    } else {
      const index = ivas.findIndex((item) => item.tasa === empresa.iva);

      if (index !== -1) {
        setArticulo({
          ...articulo,
          iva: ivas[index],
        });
      }
    }

    if (titular.id_empresa_facturacion !== empresa.id) {
      toast.current.show({
        severity: "warn",
        summary: "Comprobantes",
        detail: "El titular no corresponde a esta empresa. !",
        life: 5000,
      });
      return;
    }
  }

  /*
  if (idComprobante && comprobante && !bandFinal) {
    if (comprobante.pdf && !visibleEnvios && !urlPdf) {
      setBandFinal(true);
      crearComprobantePdf({ idComprobante });
      setTimeout(() => {
        setVisibleEnvios(true);
      }, 1000);
    } else {
      setBandFinal(true);
      setTimeout(() => {
        navigate("/facturacion/0/0");
        window.location.reload();
      }, 1000);
    }
  }

  */

  if (id !== undefined && id !== "0") {
    if (!bandInicio1) {
      obtenerFactura({ id });
      buscarTitular({ titularId });
      setBandInicio1(true);
    }

    if (bandInicio1 && !bandInicio2 && titular) {
      obtenerTiposComprobantes({
        empresaOrigenId: empresaElegida.id,
        tipoContribuyenteDestino: null,
      });
      setBandInicio2(true);
    }

    if (
      bandInicio1 &&
      bandInicio2 &&
      !bandInicio3 &&
      titular &&
      !selectedRowClientes
    ) {
      setSelectedRowClientes(titular);
      setBandInicio3(true);
    }

    if (
      bandInicio1 &&
      bandInicio2 &&
      bandInicio3 &&
      !bandInicio4 &&
      titular &&
      factura &&
      tiposComprobantes &&
      tiposComprobantes.length > 0 &&
      Number(factura.id) === Number(id)
    ) {
      const index = tiposComprobantes.findIndex(
        (item) => item.abreviatura === factura.comprobante
      );

      //console.log(factura)

      guardarComprobante({
        id: factura.id,
        titularId: titular.id_titular,
        nombre: titular.nombre_razonsocial,
        tipoContribuyenteId: titular.tiposcontribuyentes.id,
        tipoContribuyente: titular.tiposcontribuyentes.nombre,
        numeroDocumento: titular.numerodocumento,
        domicilio: titular.domicilio,
        email: titular.email,
        celular: titular.celular,
        puntoVenta: factura.puntoventa,
        numero: factura.numero,
        facturaElectronica: factura.cae ? true : false,
        fecha: factura.fecha,
        periodo: factura.periodo,
        pdf: false,
        tipo: factura.tipo,
        comprobanteId: tiposComprobantes[index].id,
        comprobante: tiposComprobantes[index].nombre,
        comprobanteAbreviatura: tiposComprobantes[index].abreviatura,
        puntoVentanc: factura.puntoVentanc,
        numeronc: factura.numeronc,
        tiponc: factura.tiponc,
        subtotalNoGravado: parseFloat(factura.subtotalnogravado).toFixed(2),
        subtotalGravado: parseFloat(factura.subtotal).toFixed(2),
        subtotal: parseFloat(
          factura.subtotal + factura.subtotalnogravado
        ).toFixed(2),
        exento: parseFloat(factura.exento).toFixed(2),
        iva: parseFloat(factura.iva).toFixed(2),
        iva0: parseFloat(0).toFixed(2),
        iva105: parseFloat(factura.iva1).toFixed(2),
        iva21: parseFloat(factura.iva2).toFixed(2),
        iva27: parseFloat(factura.iva3).toFixed(2),
        tasaPercArba: parseFloat(factura.tasapercarba).toFixed(2),
        percArba: parseFloat(factura.percarba).toFixed(2),
        tasaPercAgip: parseFloat(factura.tasapercagip).toFixed(2),
        percAgip: parseFloat(factura.percagip).toFixed(2),
        total: parseFloat(factura.total).toFixed(2),
      });
      setBandInicio4(true);
    }

    if (
      bandInicio1 &&
      bandInicio2 &&
      bandInicio3 &&
      bandInicio4 &&
      !bandInicio5 &&
      items &&
      items.length > 0
    ) {
      setRows(items);
      setBandInicio5(true);
    }

    if (
      bandInicio1 &&
      bandInicio2 &&
      bandInicio3 &&
      bandInicio4 &&
      bandInicio5 &&
      !bandInicio6 &&
      comprobante &&
      rows &&
      rows.length > 0
    ) {
      setBandInicio6(true);
      editClick();
    }
  }

  if (alerta && cierre && cierre.cerrado) {
    setAlerta(false);

    let periodo =
      comprobante.fecha.substring(5, 7) +
      "/" +
      comprobante.fecha.substring(2, 4);

    if (periodo === cierre.periodo) {
      toast.current.show({
        severity: "error",
        summary: "Período Cerrado",
        detail:
          "El período " + cierre.periodo + " para ventas esta cerrado. !! ",
        life: 3000,
      });

      let elem = document.getElementById("fecha");
      if (elem) {
        elem.focus();
      }
    }
  }

  // FIN

  const aceptarEnvios = (e) => {
    if (
      email &&
      (!comprobante || comprobante.email === null || comprobante.email === "")
    ) {
      mensajeAlerta("Comprobantes", "El email es incorrecto !.", "error");
      return;
    }

    if (email && !EmailValidator.validate(comprobante.email)) {
      mensajeAlerta("Comprobantes", "El email no es válido !.", "error");
      return;
    }

    if (
      whatsApp &&
      (!comprobante ||
        comprobante.celular === null ||
        comprobante.celular === "" ||
        comprobante.celular.length !== 10)
    ) {
      mensajeAlerta("Comprobantes", "El celular es incorrecto !.", "error");
      return;
    }

    if (pdf) {
      window.open(urlPdf, "_blank");
    }

    if (email) {
      //enviarEmail({ url: urlPdf, email: comprobante.email });
    }

    if (whatsApp) {
      //enviarWhatsApp({ url: urlPdf, celular: comprobante.celular });
    }
    setVisibleEnvios(false);
    onHide();
    returnClick();
  };

  const exitEnvios = (e) => {
    setVisibleEnvios(false);
    onHide();
    returnClick();
  };

  /*
  if (idComprobante && !visibleEnvios && !bandFinal) {

    setBandFinal(true)

    let empresaTab0 = false;
    let empresaTab1 = false;
    let cobradorTab0 = false;
    let cobradorTab1 = false;
    let formaPagoTab0 = false;
    let formaPagoTab1 = false;

    for (var i = 0; i < configuracionesEmpresas.length; i++) {
      if (
        configuracionesEmpresas[i].empresaId ===
          titular.id_empresa_facturacion &&
        configuracionesEmpresas[i].tab === 0
      ) {
        empresaTab0 = true;
      }
      if (
        configuracionesEmpresas[i].empresaId ===
          titular.id_empresa_facturacion &&
        configuracionesEmpresas[i].tab === 1
      ) {
        empresaTab1 = true;
      }
    }

    for (var i = 0; i < configuracionesCobradores.length; i++) {
      if (
        configuracionesCobradores[i].cobradorId ===
          titular.id_cobrador1_facturacion &&
        configuracionesCobradores[i].tab === 0
      ) {
        cobradorTab0 = true;
      }
      if (
        configuracionesCobradores[i].cobradorId ===
          titular.id_cobrador1_facturacion &&
        configuracionesCobradores[i].tab === 1
      ) {
        cobradorTab1 = true;
      }
    }

    for (var i = 0; i < configuracionesFormasPagos.length; i++) {
      if (
        configuracionesFormasPagos[i].formaPagoId === titular.id_formapago &&
        configuracionesFormasPagos[i].tab === 0
      ) {
        formaPagoTab0 = true;
      }
      if (
        configuracionesFormasPagos[i].formaPagoId === titular.id_formapago &&
        configuracionesFormasPagos[i].tab === 1
      ) {
        formaPagoTab1 = true;
      }
    }

    if (
      empresaTab0 &&
      cobradorTab0 &&
      formaPagoTab0 &&
      titular &&
      titular.email &&
      titular.email.length > 0
    ) {
      setEmail(true);
    }

    if (
      empresaTab1 &&
      cobradorTab1 &&
      formaPagoTab1 &&
      titular.celular &&
      titular.celular.length === 10
    ) {
      setWhatsApp(true);
    }

    if (
      (empresaTab0 && cobradorTab0 && formaPagoTab0) ||
      (empresaTab1 && cobradorTab1 && formaPagoTab1)
    ) {
      setTimeout(() => {
        setVisibleEnvios(true);
      }, 1000);
    } else {
      setTimeout(() => {
        onHide();
        exitClick();
      }, 1000);
    }
  }

  */

  /*
  if (estadoComprobante === 400) {
    guardarEstadoComprobante(0);
    onHide();
    exitClick();
  }
  */

  const renderHeaderEnvios = () => {
    return (
      <div>
        <Tag value="Envío de Comprobante"></Tag>
      </div>
    );
  };

  const renderFooterEnvios = () => {
    return (
      <div>
        <ButtonMui
          onClick={(e) => aceptarEnvios()}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Enviar
        </ButtonMui>
        <ButtonMui
          onClick={(e) => exitEnvios()}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Salir
        </ButtonMui>
      </div>
    );
  };

  const handleChange = (event) => {
    setTiponc(event.target.value);
  };

  // console.log(empresa);
  //console.log(comprobante)
  //console.log(factura);

  return (
    <Fragment>
      {id === "0" ? <Menu /> : null}
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <div className="zoom80">
            {clientes ? (
              <Fragment>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ align: "center", margin: "1em" }}
                >
                  <Fragment>
                    {id === "0" ? (
                      <Fragment>
                        {/* Botones ocultos */}
                        <Button
                          id="botonAddClick"
                          icon="pi pi-plus"
                          style={{ display: "none" }}
                          className="p-button-rounded p-button-warning"
                          onClick={(e) => {
                            addClick();
                          }}
                        />
                        {/* Botones ocultos */}

                        <Button
                          icon="pi pi-plus"
                          tooltip="Agregar Factura"
                          className="p-button-rounded p-button-warning"
                          disabled={!selectedRowClientes || !titular}
                          style={{ marginLeft: "1em" }}
                          onClick={(e) => {
                            {
                              if (!titular) {
                                return;
                              }
                              obtenerTiposComprobantes({
                                empresaOrigenId: empresaElegida.id,
                                tipoContribuyenteDestino:
                                  titular.id_tipocontribuyente,
                              });
                              setTimeout(() => {
                                let elem =
                                  document.getElementById("botonAddClick");
                                elem.click();
                              }, 500);
                            }
                          }}
                        />
                        <Button
                          icon="pi pi-times"
                          tooltip="Cerrar"
                          className="p-button-rounded p-button-primary"
                          style={{ marginLeft: "1em" }}
                          onClick={exitClick}
                        />
                        <Button
                          icon="pi pi-arrow-left"
                          tooltip="Volver"
                          className="p-button-rounded p-button-success"
                          style={{ marginLeft: "1em" }}
                          onClick={returnClick}
                        />
                      </Fragment>
                    ) : null}
                    <button
                      id="verComprobante"
                      type="button"
                      onClick={(e) => {
                        setVisible(true);
                      }}
                      style={{ visibility: "hidden" }}
                    >
                      Ver
                    </button>
                  </Fragment>

                  {id === "0" ? (
                    <div className="datatable-responsive-demo">
                      <div className="card">
                        <Tooltip
                          target=".export-buttons>button"
                          position="bottom"
                        />

                        <Fragment>
                          <DataTable
                            value={clientes}
                            selection={selectedRowClientes}
                            onSelectionChange={(e) => {
                              setSelectedRowClientes(e.value);
                              buscarTitular({ titularId: e.value.id_titular });
                            }}
                            selectionMode="single"
                            dataKey="id"
                            header={headerClientes}
                            className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                            style={{ fontSize: "small", marginTop: "1vw" }}
                            //globalFilter={globalFilter}
                            emptyMessage="No hay datos."
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                            rows={100}
                            rowsPerPageOptions={[10, 20, 50]}
                            //paginatorLeft={paginatorLeft}
                            //paginatorRight={paginatorRight}
                          >
                            <Column
                              field="id"
                              header="Nº Afiliado"
                              //body={idTemplate}
                              sortable
                            ></Column>
                            <Column
                              field="nombre_razonsocial"
                              header="Razón Social"
                              //body={descripcionTemplate}
                              sortable
                            ></Column>
                            <Column
                              field="domicilio"
                              header="Domicilio"
                              //body={stockTemplate}
                              sortable
                            ></Column>
                            <Column
                              field="numerodocumento"
                              header="Nº Documento"
                              //body={unidadTemplate}
                              sortable
                            ></Column>
                            <Column
                              field="telefono1"
                              header="Teléfono"
                              //body={unidadTemplate}
                              sortable
                            ></Column>
                            <Column
                              field="celular"
                              header="Celular"
                              //body={unidadTemplate}
                              sortable
                            ></Column>
                            <Column
                              field="titular"
                              header="Titular"
                              body={titularTemplate}
                              sortable
                            ></Column>
                            <Column
                              field="habilitado"
                              header="Habilitado"
                              body={habilitadoTemplate}
                              sortable
                            ></Column>
                            <Column
                              field="suspendido"
                              header="Suspendido"
                              body={suspendidoTemplate}
                              sortable
                            ></Column>
                          </DataTable>
                        </Fragment>
                      </div>
                    </div>
                  ) : null}
                </Grid>
                {comprobante ? (
                  <Dialog
                    header={renderHeader()}
                    visible={visible}
                    closable={false}
                    maximized={true}
                    maximizable={true}
                    onHide={onHide}
                    footer={renderFooter()}
                    breakpoints={{ "960px": "95vw" }}
                    style={{ width: "95vw", paddingBottom: "0" }}
                    position="center"
                  >
                    <Grid container spacing={1} style={{ marginTop: "1vw" }}>
                      <Grid
                        container
                        xs={12}
                        md={6}
                        style={{ borderStyle: "solid", borderWidth: "1px" }}
                      >
                        <Grid
                          item
                          xs={12}
                          md={2.5}
                          style={{ padding: "0.2vw" }}
                        >
                          <TextField
                            value={comprobante ? comprobante.titularId : null}
                            required
                            variant="standard"
                            id="titularId"
                            label="Nº de Afiliado"
                            fullWidth
                            disabled
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={9} style={{ padding: "0.2vw" }}>
                          <TextField
                            value={comprobante ? comprobante.nombre : null}
                            required
                            variant="standard"
                            id="nombre"
                            label="Nombre/Razón Social"
                            fullWidth
                            disabled
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={7} style={{ padding: "0.2vw" }}>
                          <TextField
                            value={comprobante ? comprobante.domicilio : null}
                            required
                            variant="standard"
                            id="domicilio"
                            label="Domicilio"
                            fullWidth
                            disabled
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={5} style={{ padding: "0.2vw" }}>
                          <TextField
                            value={
                              comprobante ? comprobante.tipoContribuyente : null
                            }
                            required
                            variant="standard"
                            id="tipoContribuyente"
                            label="Tipo de Contribuyente"
                            fullWidth
                            disabled
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={5} style={{ padding: "0.2vw" }}>
                          <TextField
                            value={
                              comprobante ? comprobante.numeroDocumento : null
                            }
                            required
                            variant="standard"
                            id="numeroDocumento"
                            label="C.U.I.T. / D.N.I."
                            fullWidth
                            disabled
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        xs={12}
                        md={6}
                        style={{ borderStyle: "solid", borderWidth: "1px" }}
                      >
                        {id === "0" ? (
                          <Grid
                            item
                            xs={12}
                            md={8}
                            style={{ padding: "0.2vw" }}
                          >
                            <Autocomplete
                              id="tipoComprobante"
                              fullWidth
                              noOptionsText={"No hay opciones"}
                              options={
                                tiposComprobantes ? tiposComprobantes : null
                              }
                              //defaultValue={tiposComprobantes[0]}
                              getOptionLabel={(option) => option.nombre}
                              openOnFocus={true}
                              disablePortal
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Comprobante *"
                                  variant="standard"
                                  InputLabelProps={{
                                    style: { color: "red" },
                                  }}
                                />
                              )}
                              onKeyDown={handleEnter}
                              onChange={(event, value) => {
                                if (value) {
                                  if (
                                    statusAcceso2 !== 200 &&
                                    (value.id === 1000 || value.id === 1001)
                                  ) {
                                    guardarComprobante({
                                      ...comprobante,
                                      comprobanteId: null,
                                      comprobante: null,
                                      comprobanteAbreviatura: null,
                                      tipo: null,
                                      puntoVenta: null,
                                      numero: null,
                                    });
                                    guardarNumeroComprobante(null);

                                    mensajeAlerta(
                                      "Facturación",
                                      "No tiene permiso para generar ajustes !.",
                                      "error"
                                    );
                                    return;
                                  }

                                  if (
                                    statusAcceso3 !== 200 &&
                                    (value.id === 3 ||
                                      value.id === 8 ||
                                      value.id === 13 ||
                                      value.id === 53 ||
                                      value.id === 203 ||
                                      value.id === 208 ||
                                      value.id === 213)
                                  ) {
                                    guardarComprobante({
                                      ...comprobante,
                                      comprobanteId: null,
                                      comprobante: null,
                                      comprobanteAbreviatura: null,
                                      tipo: null,
                                      puntoVenta: null,
                                      numero: null,
                                    });
                                    guardarNumeroComprobante(null);

                                    mensajeAlerta(
                                      "Facturación",
                                      "No tiene permiso para generar notas de crédito !.",
                                      "error"
                                    );
                                    return;
                                  }

                                  guardarComprobante({
                                    ...comprobante,
                                    comprobanteId: value.id,
                                    comprobante: value.nombre,
                                    comprobanteAbreviatura: value.abreviatura,
                                    tipo: value.abreviatura.substring(
                                      value.abreviatura.length - 1
                                    ),
                                    puntoVenta: null,
                                    numero: null,
                                  });
                                  guardarNumeroComprobante(null);
                                  obtenerNumeroComprobante({
                                    empresaId: empresa.id,
                                    comprobanteAbreviatura: value.abreviatura,
                                  });
                                } else {
                                  guardarComprobante({
                                    ...comprobante,
                                    comprobanteId: null,
                                    comprobante: null,
                                    comprobanteAbreviatura: null,
                                    tipo: null,
                                    puntoVenta: null,
                                    numero: null,
                                  });
                                  guardarNumeroComprobante(null);
                                }
                              }}
                            />
                          </Grid>
                        ) : (
                          <Grid
                            item
                            xs={12}
                            md={8}
                            style={{ padding: "0.2vw" }}
                          >
                            <TextField
                              value={
                                comprobante && comprobante.comprobante
                                  ? comprobante.comprobante
                                  : ""
                              }
                              variant="standard"
                              id="tipoComprobante"
                              label="Comprobante *"
                              fullWidth
                              disabled
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        )}

                        <Grid
                          item
                          xs={12}
                          md={1.5}
                          style={{ padding: "0.2vw" }}
                        >
                          <TextField
                            value={
                              comprobante && comprobante.puntoVenta
                                ? comprobante.puntoVenta
                                : ""
                            }
                            variant="standard"
                            id="puntoVenta"
                            label="P. Venta"
                            fullWidth
                            disabled
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={2} style={{ padding: "0.2vw" }}>
                          <TextField
                            value={
                              comprobante && comprobante.numero
                                ? comprobante.numero
                                : ""
                            }
                            variant="standard"
                            id="numero"
                            label="Número"
                            fullWidth
                            disabled
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3} style={{ padding: "0.2vw" }}>
                          <TextField
                            id="fecha"
                            value={
                              comprobante && comprobante.fecha
                                ? comprobante.fecha
                                : null
                            }
                            label="Fecha"
                            type="date"
                            fullWidth
                            variant="standard"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                              style: { color: "red" },
                            }}
                            disabled={id !== "0"}
                            onChange={(e) =>
                              guardarComprobante({
                                ...comprobante,
                                fecha: e.target.value,
                              })
                            }
                            onBlur={(e) => handleBlurFecha(e)}
                            onKeyDown={handleEnter}
                          />
                        </Grid>

                        <Grid item xs={12} md={3} style={{ padding: "0.2vw" }}>
                          <label htmlFor="id" style={{ color: "red" }}>
                            Período
                          </label>
                          <InputMask
                            id="periodo"
                            mask="99/99"
                            value={comprobante ? comprobante.periodo : null}
                            placeholder="99/99"
                            //onBlur={handleEnter}
                            size={3}
                            disabled={id !== "0"}
                            onChange={(e) =>
                              guardarComprobante({
                                ...comprobante,
                                periodo: e.value,
                              })
                            }
                            style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                          ></InputMask>
                        </Grid>

                        <Grid item xs={12} md={6} />

                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{ paddingLeft: 0, paddingTop: "1vw" }}
                        >
                          <FormControlLabel
                            value="yes"
                            control={
                              <Checkbox
                                color="primary"
                                checked={
                                  comprobante &&
                                  empresa &&
                                  empresa.facturaElectronica
                                    ? comprobante.facturaElectronica
                                    : null
                                }
                                style={{ marginLeft: "1vw" }}
                                disabled={
                                  (empresa &&
                                    empresa.facturaElectronica !== 1) ||
                                  id !== "0" ||
                                  (comprobante &&
                                    (comprobante.comprobanteAbreviatura ===
                                      "AJDX" ||
                                      comprobante.comprobanteAbreviatura ===
                                        "AJCX"))
                                }
                                onChange={(e) =>
                                  guardarComprobante({
                                    ...comprobante,
                                    facturaElectronica: e.target.checked,
                                  })
                                }
                              />
                            }
                            label="Factura Electrónica"
                            labelPlacement="start"
                            style={{ paddingLeft: "0vw" }}
                          />
                        </Grid>

                        {/*
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{ paddingLeft: 0, paddingTop: "1vw" }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={
                            <Checkbox
                              color="primary"
                              checked={comprobante ? comprobante.pdf : null}
                              style={{ marginLeft: "1vw" }}
                              disabled={
                                comprobante.comprobanteId === 2000 ||
                                comprobante.comprobanteId === 3000
                              }
                              onChange={(e) =>
                                guardarComprobante({
                                  ...comprobante,
                                  pdf: e.target.checked,
                                })
                              }
                            />
                          }
                          label="Descargar/Enviar Pdf"
                          labelPlacement="start"
                          style={{ paddingLeft: "0vw" }}
                        />
                      </Grid>
                      */}

                        {comprobante &&
                        (Number(comprobante.comprobanteId) === 2 ||
                          Number(comprobante.comprobanteId) === 3 ||
                          Number(comprobante.comprobanteId) === 7 ||
                          Number(comprobante.comprobanteId) === 8 ||
                          Number(comprobante.comprobanteId) === 12 ||
                          Number(comprobante.comprobanteId) === 13 ||
                          Number(comprobante.comprobanteId) === 52 ||
                          Number(comprobante.comprobanteId) === 53 ||
                          Number(comprobante.comprobanteId) === 202 ||
                          Number(comprobante.comprobanteId) === 203 ||
                          Number(comprobante.comprobanteId) === 207 ||
                          Number(comprobante.comprobanteId) === 208 ||
                          Number(comprobante.comprobanteId) === 212 ||
                          Number(comprobante.comprobanteId) === 213 ||
                          Number(comprobante.comprobanteId) === 1000 ||
                          Number(comprobante.comprobanteId) === 1001) ? (
                          <Fragment>
                            <Grid item xs={12} md={5}>
                              <label
                                htmlFor="idAsociado"
                                style={{ color: "red", marginLeft: ".2vw" }}
                              >
                                {comprobante && comprobante.tipo
                                  ? "Comp. Asociado de Factura " +
                                    comprobante.tipo
                                  : null}
                              </label>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={1.3}
                              style={{ marginLeft: "0.3vw", padding: "0.2vw" }}
                            >
                              <TextField
                                value={
                                  comprobante && comprobante.puntoVentanc
                                    ? comprobante.puntoVentanc
                                    : ""
                                }
                                variant="standard"
                                id="puntoVentaAsociado"
                                label="P. Venta"
                                disabled={id !== "0"}
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(e) =>
                                  guardarComprobante({
                                    ...comprobante,
                                    puntoVentanc: e.target.value,
                                  })
                                }
                              />
                            </Grid>

                            {comprobante &&
                            (comprobante.comprobanteId === 1000 ||
                              comprobante.comprobanteId === 1001) ? (
                              <Grid
                                item
                                xs={12}
                                md={1.5}
                                style={{
                                  marginLeft: "0.5vw",
                                  padding: "0.3vw",
                                }}
                              >
                                <div className="card flex justify-content-center">
                                  <Dropdown
                                    value={
                                      id !== "0" && comprobante
                                        ? tipos[
                                            tipos.findIndex(
                                              (item) =>
                                                item.tipo === comprobante.tiponc
                                            )
                                          ]
                                        : comprobante && comprobante.tiponc
                                        ? comprobante.tiponc
                                        : ""
                                    }
                                    onChange={(e) => {
                                      if (e.value) {
                                        guardarComprobante({
                                          ...comprobante,
                                          tiponc: e.value,
                                        });
                                      }
                                    }}
                                    disabled={id !== "0"}
                                    options={tipos}
                                    optionLabel="tipo"
                                    placeholder="Tipo"
                                    className="w-full md:w-14rem"
                                  />
                                </div>
                              </Grid>
                            ) : null}

                            <Grid
                              item
                              xs={12}
                              md={2}
                              style={{ padding: "0.3vw" }}
                            >
                              <TextField
                                value={
                                  comprobante && comprobante.numeronc
                                    ? comprobante.numeronc
                                    : ""
                                }
                                variant="standard"
                                id="numero"
                                label="Número"
                                fullWidth
                                disabled={id !== "0"}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(e) =>
                                  guardarComprobante({
                                    ...comprobante,
                                    numeronc: e.target.value,
                                  })
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={1}
                              style={{ padding: "0.2vw" }}
                            >
                              <IconButton
                                color={
                                  comprobante && comprobanteAsociado
                                    ? "success"
                                    : "error"
                                }
                                aria-label="check"
                                disabled={id !== "0"}
                                onClick={(e) => {
                                  if (
                                    (comprobante.comprobanteId === 1000 ||
                                      comprobante.comprobanteId === 1001) &&
                                    comprobante.tiponc.tipo === undefined
                                  ) {
                                    toast.current.show({
                                      severity: "warn",
                                      summary: "Información",
                                      detail:
                                        "El tipo de comprobante asociado es necesario.",
                                      life: 3000,
                                    });
                                    return;
                                  }

                                  if (
                                    comprobante.comprobanteId === 1000 ||
                                    comprobante.comprobanteId === 1001
                                  ) {
                                    buscarComprobanteAsociado(
                                      comprobante.puntoVentanc,
                                      comprobante.numeronc,
                                      comprobante.tiponc.tipo
                                    );
                                  } else {
                                    buscarComprobanteAsociado(
                                      comprobante.puntoVentanc,
                                      comprobante.numeronc,
                                      comprobante.tipo
                                    );
                                  }
                                }}
                              >
                                <CheckIcon />
                              </IconButton>
                            </Grid>
                          </Fragment>
                        ) : null}
                      </Grid>
                      <Grid
                        container
                        xs={12}
                        md={12}
                        style={{ borderStyle: "solid", borderWidth: "1px" }}
                      >
                        <Grid item xs={12} md={1} style={{ margin: "0.5vw" }}>
                          <TextField
                            value={
                              articulo && articulo.cantidad !== null
                                ? articulo.cantidad
                                : ""
                            }
                            variant="standard"
                            id="cantidad"
                            label="Cantidad"
                            fullWidth
                            disabled={id !== "0"}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type="number"
                            onKeyDown={handleEnter}
                            onChange={(e) => {
                              if (Number(e.target.value) < 0) {
                                setArticulo({
                                  ...articulo,
                                  cantidad: 0,
                                });
                              } else {
                                setArticulo({
                                  ...articulo,
                                  cantidad: e.target.value,
                                });
                              }
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={3.5} style={{ margin: "0.5vw" }}>
                          <TextField
                            value={
                              articulo && articulo.descripcion !== null
                                ? articulo.descripcion
                                : ""
                            }
                            variant="standard"
                            id="descripcion"
                            label="Descripción"
                            fullWidth
                            disabled={id !== "0"}
                            multiline
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onKeyDown={handleEnter}
                            onChange={(e) =>
                              setArticulo({
                                ...articulo,
                                descripcion: e.target.value,
                              })
                            }
                          />
                        </Grid>

                        <Grid item xs={12} md={2.5} style={{ margin: "0.5vw" }}>
                          <label
                            htmlFor="precioUnitario"
                            style={{ fontSize: ".7vw" }}
                          >
                            Precio Unitario
                          </label>
                          <InputNumber
                            inputId="precioUnitario"
                            value={articulo ? articulo.precioUnitario : null}
                            onKeyDown={handleEnter}
                            disabled={
                              (articulo &&
                                Number(articulo.cantidad) === 0 &&
                                articulo.cantidad !== null) ||
                              id !== "0"
                            }
                            onBlur={(e) => handleBlurUnitario(e)}
                            onChange={(e) => {
                              setArticulo({
                                ...articulo,
                                precioUnitario: e.value,
                              });
                            }}
                            size={10}
                            mode="decimal"
                            locale="en-US"
                            minFractionDigits={2}
                            style={{ marginLeft: "0.7vw", marginTop: "0.8vw" }}
                          />
                          <Button
                            icon="pi pi-dollar"
                            tooltip="Cuota"
                            className="p-button-rounded p-button-help"
                            disabled={
                              (articulo && Number(articulo.cantidad) === 0) ||
                              id !== "0"
                            }
                            style={{ marginLeft: "0.5em" }}
                            onClick={(e) => buscarCuota()}
                          />
                        </Grid>

                        <Grid item xs={12} md={1} style={{ margin: "0.5vw" }}>
                          <Autocomplete
                            id="iva"
                            //disabled
                            fullWidth
                            options={ivas ? ivas : null}
                            disablePortal
                            value={
                              articulo && articulo.iva ? articulo.iva : null
                            }
                            getOptionLabel={(option) => option.nombre}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Tasa Iva"
                                size="small"
                              />
                            )}
                            disabled={
                              (articulo &&
                                Number(articulo.cantidad) === 0 &&
                                articulo.cantidad !== null) ||
                              (empresa && empresa.id_tipocontribuyente === 2) ||
                              (empresa && empresa.id_tipocontribuyente === 3) ||
                              id !== "0" ||
                              (comprobante &&
                                (comprobante.comprobanteAbreviatura ===
                                  "AJDX" ||
                                  comprobante.comprobanteAbreviatura ===
                                    "AJCX"))
                            }
                            onKeyDown={handleEnter}
                            //onBlur={(e) => handleBlur(e)}
                            onChange={(event, value) => {
                              if (value) {
                                setArticulo({
                                  ...articulo,
                                  iva: value,
                                });
                              } else {
                                setArticulo({
                                  ...articulo,
                                  iva: null,
                                });
                              }
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={2} style={{ margin: "0.5vw" }}>
                          <label
                            htmlFor="precioTotal"
                            style={{ fontSize: ".7vw" }}
                          >
                            Precio Total
                          </label>
                          <InputNumber
                            inputId="precioTotal"
                            value={articulo ? articulo.precioTotal : null}
                            disabled={
                              (articulo &&
                                Number(articulo.cantidad) === 0 &&
                                articulo.cantidad !== null) ||
                              id !== "0"
                            }
                            //onKeyDown={handleEnter}
                            size={10}
                            mode="decimal"
                            locale="en-US"
                            minFractionDigits={2}
                            style={{ marginLeft: "0.7vw", marginTop: "0.8vw" }}
                          />
                        </Grid>
                        <Grid item xs={12} md={1} style={{ paddingTop: "1vw" }}>
                          <Button
                            id="agregarArticulo"
                            label="Agregar"
                            aria-label="Submit"
                            onClick={(e) => clickAgregar()}
                            disabled={
                              (titular &&
                                empresa &&
                                titular.id_empresa_facturacion !==
                                  empresa.id) ||
                              id !== "0"
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid container xs={12} md={8}>
                        <Grid item xs={12} md={12}>
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell align="right">Cantidad</TableCell>
                                  <TableCell align="left">
                                    Descripción
                                  </TableCell>
                                  <TableCell align="right">
                                    P. Unitario
                                  </TableCell>
                                  <TableCell align="right">I.V.A.</TableCell>
                                  <TableCell align="right">Total</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rows.map((row) => (
                                  <TableRow
                                    key={row.name}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      align="right"
                                    >
                                      {row.cantidad}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.descripcion}
                                    </TableCell>
                                    <TableCell align="right">
                                      {parseFloat(row.unitario).toFixed(2)}
                                    </TableCell>
                                    <TableCell align="right">
                                      {parseFloat(row.ivaTasa).toFixed(2)}
                                    </TableCell>
                                    <TableCell align="right">
                                      {parseFloat(row.total).toFixed(2)}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        xs={12}
                        md={4}
                        style={{ borderStyle: "solid", borderWidth: "1px" }}
                      >
                        <Grid item xs={12} md={12} style={{ margin: "0.5vw" }}>
                          <TextField
                            value={
                              comprobante &&
                              (comprobante.tipo === "A" ||
                                comprobante.tipo === "M")
                                ? comprobante.subtotal
                                : null
                            }
                            variant="standard"
                            id="subtotal"
                            label="Subtotal"
                            fullWidth
                            disabled
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "black",
                              },
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={3.6} style={{ margin: "0.5vw" }}>
                          <TextField
                            value={
                              comprobante &&
                              (comprobante.tipo === "A" ||
                                comprobante.tipo === "M")
                                ? comprobante.iva105
                                : null
                            }
                            variant="standard"
                            id="iva105"
                            label="Iva 10.5%"
                            fullWidth
                            disabled
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "black",
                              },
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={3.6} style={{ margin: "0.5vw" }}>
                          <TextField
                            value={
                              comprobante &&
                              (comprobante.tipo === "A" ||
                                comprobante.tipo === "M")
                                ? comprobante.iva21
                                : null
                            }
                            variant="standard"
                            id="iva21"
                            label="Iva 21%"
                            fullWidth
                            disabled
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "black",
                              },
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={3.6} style={{ margin: "0.5vw" }}>
                          <TextField
                            value={
                              comprobante &&
                              (comprobante.tipo === "A" ||
                                comprobante.tipo === "M")
                                ? comprobante.iva27
                                : null
                            }
                            variant="standard"
                            id="iva27"
                            label="Iva 27%"
                            fullWidth
                            disabled
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "black",
                              },
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={5.5} style={{ margin: "0.5vw" }}>
                          <TextField
                            value={comprobante ? comprobante.percArba : null}
                            variant="standard"
                            id="percArba"
                            label={
                              empresa &&
                              empresa.percepcionArba &&
                              selectedRowClientes &&
                              selectedRowClientes.alicuotaarba
                                ? "Perc. BS. AS " +
                                  selectedRowClientes.alicuotaarba +
                                  "%"
                                : "Perc. BS. AS. 0%"
                            }
                            fullWidth
                            disabled
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "black",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={5.5} style={{ margin: "0.5vw" }}>
                          <TextField
                            value={comprobante ? comprobante.percAgip : null}
                            variant="standard"
                            id="percAgip"
                            label={
                              empresa &&
                              empresa.percepcionAgip &&
                              selectedRowClientes &&
                              selectedRowClientes.alicuotaagip
                                ? "Perc. C.A.B.A. " +
                                  selectedRowClientes.alicuotaagip +
                                  "%"
                                : "Perc. C.A.B.A. 0%"
                            }
                            fullWidth
                            disabled
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "black",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} style={{ margin: "0.5vw" }}>
                          <TextField
                            value={comprobante ? comprobante.total : null}
                            variant="standard"
                            id="total"
                            label="Total"
                            fullWidth
                            disabled
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "black",
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Dialog>
                ) : null}

                <Dialog
                  header={renderHeaderEnvios()}
                  visible={visibleEnvios}
                  closable={false}
                  footer={renderFooterEnvios()}
                  breakpoints={{ "560px": "75vw" }}
                  style={{ width: "70vw", paddingBottom: "0" }}
                  position="top"
                >
                  <Grid container spacing={3} style={{ margin: "1vw" }}>
                    <Grid item xs={12} md={7} style={{ padding: "0.2vw" }} />

                    <Grid
                      item
                      xs={12}
                      md={4}
                      style={{
                        paddingLeft: 0,
                        paddingTop: "1vw",
                        textAlign: "right",
                      }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Checkbox
                            color="primary"
                            checked={pdf ? pdf : null}
                            style={{ marginLeft: "1vw" }}
                            //disabled={empresa && !empresa.enviarEmail}
                            onChange={(e) => setPdf(e.target.checked)}
                          />
                        }
                        label="Descargar Pdf"
                        labelPlacement="start"
                        style={{ paddingLeft: ".7vw" }}
                      />
                    </Grid>

                    <Grid item xs={12} md={7} style={{ padding: "0.2vw" }}>
                      <TextField
                        value={comprobante ? comprobante.email : null}
                        variant="standard"
                        id="email"
                        label="Email"
                        fullWidth
                        onChange={(e) =>
                          guardarComprobante({
                            ...comprobante,
                            email: e.target.value,
                          })
                        }
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "red" },
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      style={{
                        paddingLeft: 0,
                        paddingTop: "1vw",
                        textAlign: "right",
                      }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Checkbox
                            color="primary"
                            checked={email ? email : null}
                            style={{ marginLeft: "1vw" }}
                            //disabled={empresa && !empresa.enviarEmail}
                            onChange={(e) => setEmail(e.target.checked)}
                          />
                        }
                        label="Enviar Email"
                        labelPlacement="start"
                        style={{ paddingLeft: ".7vw" }}
                      />
                    </Grid>

                    <Grid item xs={12} md={7} style={{ padding: "0.2vw" }}>
                      <TextField
                        value={comprobante ? comprobante.celular : null}
                        variant="standard"
                        id="celular"
                        label="Celular"
                        fullWidth
                        onChange={(e) =>
                          guardarComprobante({
                            ...comprobante,
                            celular: e.target.value,
                          })
                        }
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "red" },
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      style={{
                        paddingLeft: 0,
                        paddingTop: "1vw",
                        textAlign: "right",
                      }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Checkbox
                            color="primary"
                            checked={whatsApp ? whatsApp : null}
                            style={{ marginLeft: "1vw" }}
                            //disabled={empresa && !empresa.enviarEmail}
                            onChange={(e) => setWhatsApp(e.target.checked)}
                          />
                        }
                        label="Enviar WhatsApp"
                        labelPlacement="start"
                        style={{ paddingLeft: ".7vw" }}
                      />
                    </Grid>
                  </Grid>
                </Dialog>
              </Fragment>
            ) : (
              <Spinner />
            )}
            <Grid item xs={12} sm={12}></Grid>
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

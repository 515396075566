import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AlertContext } from "../../../context/AlertContext";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "../Menu";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { jsPDF } from "jspdf";
import { MultiSelect } from "primereact/multiselect";

const agrupar = [
  {
    id: 1,
    nombre: "Fecha",
  },
  {
    id: 2,
    nombre: "Concepto",
  },
];

const exportColumnsCaja = [
  { title: "Fecha", dataKey: "fecha" },
  { title: "Concepto", dataKey: "concepto" },
  { title: "Detalle", dataKey: "detalle" },
  { title: "Rend./Orden", dataKey: "rendicionOrden" },
  { title: "Ingresos", dataKey: "ingresos" },
  { title: "Egresos", dataKey: "egresos" },
  { title: "", dataKey: "saldo" },
];

const exportColumnsCheques = [
  { title: "Fecha", dataKey: "fecha" },
  { title: "Concepto", dataKey: "concepto" },
  { title: "Detalle", dataKey: "detalle" },
  { title: "Estado", dataKey: "estado" },
  { title: "Banco", dataKey: "banco" },
  { title: "Importe", dataKey: "importe" },
  { title: "Rendición", dataKey: "rendicion" },
  { title: "Ordenes", dataKey: "ordenes" },
];

const exportColumnsBancos = [
  { title: "Fecha", dataKey: "fecha" },
  { title: "Concepto", dataKey: "concepto" },
  { title: "Detalle", dataKey: "detalle" },
  { title: "Rendición", dataKey: "rendicion" },
  { title: "Ordenes", dataKey: "ordenes" },
  { title: "Ingresos", dataKey: "ingresos" },
  { title: "Egresos", dataKey: "egresos" },
  { title: "", dataKey: "saldo" },
];

export default function ListadoMovimientos(props) {
  const [fechaDesde, guardarFechaDesde] = useState(null);
  const [fechaHasta, guardarFechaHasta] = useState(null);
  const [visible, setVisible] = useState(true);
  const [selectedCities, setSelectedCities] = useState([]);
  const [grupo, setGrupo] = useState(null);
  const [bandInicio, setBandInicio] = useState(false);
  const [exportListado, guardarExportListado] = useState(null);

  const toast = useRef(null);

  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );

  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    result,
    result2,
    result3,
    empresas,
    monedas,
    cuentas,
    empresaElegida,
    listadoExcel,
    obtenerAcceso,
    obtenerMovimientos,
    obtenerEmpresas,
    obtenerMonedas,
    obtenerCuentas,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "ListadoMovimientos",
      });
    }
  }, [state.id]);

  useEffect(() => {
    obtenerEmpresas();
    obtenerMonedas();
    obtenerCuentas();
  }, []);

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const aceptarClick = async (exportType) => {
    //e.preventDefault();
    // Validar|

    guardarExportListado(exportType);

    if (fechaDesde === null) {
      mensajeAlerta(
        "Listados de Movimientos",
        "Debe indicar fecha desde !.",
        "error"
      );
      return;
    }

    if (fechaHasta === null) {
      mensajeAlerta(
        "Listados de Movimientos",
        "Debe indicar fecha hasta !.",
        "error"
      );
      return;
    }

    if (fechaDesde > fechaHasta) {
      mensajeAlerta(
        "Listados de Movimientos",
        "Fecha desde no puede ser mayor que fecha hasta !.",
        "error"
      );
      return;
    }

    if (selectedCities && selectedCities.length === 0) {
      mensajeAlerta(
        "Listados de Movimientos",
        "Debe indicar al menos una empresa !.",
        "error"
      );
      return;
    }

    var elem;

    let fdesde = fechaDesde + "T00:00:00";
    let fhasta = fechaHasta + "T23:59:59";

    toast.current.show({
      severity: "info",
      summary: "Listados",
      detail: "Obteniendo información... !",
      life: 3000,
    });

    var elem;

    await obtenerMovimientos({
      desde: fdesde,
      hasta: fhasta,
      selectedCities,
    }).then((data) => {
      if (exportType === "excel") {
        elem = document.getElementById("expButtonExcel");
        elem.click();
      }
      if (exportType === "pdf") {
        elem = document.getElementById("expButtonPdf");
        elem.click();
      }
    });
    onHide();
    //exitClick();
  };

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Listado de Movimientos"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <button
          id="expButtonExcel"
          type="button"
          onClick={(e) => {
            exportExcel();
          }}
          style={{ visibility: "hidden" }}
        >
          Export Excel
        </button>
        <button
          id="expButtonPdf"
          type="button"
          onClick={(e) => {
            exportPdf();
          }}
          style={{ visibility: "hidden" }}
        >
          Export Pdf
        </button>
        <Button
          icon="pi pi-file-excel"
          onClick={(e) => {
            aceptarClick("excel");
          }}
          className="p-button-raised p-button-success"
          data-pr-tooltip="XLS"
          label="Excel"
        />
        <Button
          icon="pi pi-file-pdf"
          onClick={(e) => {
            aceptarClick("pdf");
          }}
          className="p-button-raised p-button-warning"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
          label="PDF"
        />
        {/*
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        */}
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const groupBy = (prop) => (data) => {
        return data.reduce((dict, item) => {
          const { [prop]: _, ...rest } = item;
          dict[item[prop]] = [...(dict[item[prop]] || []), rest];
          return dict;
        }, {});
      };

      let campo = "id_moneda";

      const resultCaja = Object.entries(groupBy(campo)(result)).map(
        ([key, value]) => ({ nombre: key, datos: value })
      );

      let cajaGrupo = [];
      let totalGrupoIngresos = 0;
      let totalGrupoEgresos = 0;
      let totalFinalIngresos = 0;
      let totalFinalEgresos = 0;

      for (var i = 0; i < resultCaja.length; i++) {
        const index = monedas.findIndex(
          (item) => item.id === Number(resultCaja[i].nombre)
        );
        let moneda = monedas[index].nombre;

        totalGrupoIngresos = 0;
        totalGrupoEgresos = 0;
        for (var j = 0; j < resultCaja[i].datos.length; j++) {
          cajaGrupo.push({
            fecha: formatDate(resultCaja[i].datos[j].fecha),
            concepto: resultCaja[i].datos[j].conceptos.nombre,
            detalle: resultCaja[i].datos[j].detalle,
            moneda: moneda,
            rendicionOrden:
              resultCaja[i].datos[j].tipo === "i"
                ? resultCaja[i].datos[j].id_rendicion
                : resultCaja[i].datos[j].ordenes,
            ingresos:
              exportListado === "pdf"
                ? Number(resultCaja[i].datos[j].ingreso).toLocaleString(
                    "en-US",
                    {
                      minimumFractionDigits: 2,
                    }
                  )
                : Number(resultCaja[i].datos[j].ingreso),
            egresos:
              exportListado === "pdf"
                ? Number(resultCaja[i].datos[j].egreso).toLocaleString(
                    "en-US",
                    {
                      minimumFractionDigits: 2,
                    }
                  )
                : Number(resultCaja[i].datos[j].egreso),
            saldo: null,
          });
          totalGrupoIngresos =
            totalGrupoIngresos + Number(resultCaja[i].datos[j].ingreso);
          totalGrupoEgresos =
            totalGrupoEgresos + Number(resultCaja[i].datos[j].egreso);
          totalFinalIngresos =
            totalFinalIngresos + Number(resultCaja[i].datos[j].ingreso);
          totalFinalEgresos =
            totalFinalEgresos + Number(resultCaja[i].datos[j].egreso);
        }
      }

      let worksheet = xlsx.utils.json_to_sheet(cajaGrupo);
      let workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      let excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "movimientosCaja");

      campo = "tipo";

      const resultCheques = Object.entries(groupBy(campo)(result2)).map(
        ([key, value]) => ({ nombre: key, datos: value })
      );

      let chequeGrupo = [];

      for (var i = 0; i < resultCheques.length; i++) {
        for (var j = 0; j < resultCheques[i].datos.length; j++) {
          const index = monedas.findIndex(
            (item) => item.id === Number(resultCheques[i].datos[j].moneda)
          );
          let moneda = monedas[index].nombre;

          chequeGrupo.push({
            fecha: formatDate(resultCheques[i].datos[j].fecha),
            concepto: resultCheques[i].datos[j].concepto,
            detalle: resultCheques[i].datos[j].detalle,
            estado: resultCheques[i].datos[j].estado.toUpperCase(),
            banco:
              resultCheques[i].datos[j].banco +
              "-" +
              resultCheques[i].datos[j].numero,
            empresa: resultCheques[i].datos[j].empresa,
            importe:
              exportListado === "pdf"
                ? Number(resultCheques[i].datos[j].importe).toLocaleString(
                    "en-US",
                    {
                      minimumFractionDigits: 2,
                    }
                  )
                : Number(resultCheques[i].datos[j].importe),
            moneda: moneda,
            tipo: resultCheques[i].nombre,
            rendicion: resultCheques[i].datos[j].rendicionId,
            ordenes: resultCheques[i].datos[j].ordenes,
          });
        }
      }

      worksheet = xlsx.utils.json_to_sheet(chequeGrupo);
      workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "movimientosCheques");

      campo = "id_cuenta";

      const resultBancos = Object.entries(groupBy(campo)(result3)).map(
        ([key, value]) => ({ nombre: key, datos: value })
      );

      let bancoGrupo = [];
      totalGrupoIngresos = 0;
      totalGrupoEgresos = 0;
      totalFinalIngresos = 0;
      totalFinalEgresos = 0;

      for (var i = 0; i < resultBancos.length; i++) {
        const index = cuentas.findIndex(
          (item) => item.id === Number(resultBancos[i].nombre)
        );
        let cuenta = cuentas[index].nombre;

        for (var j = 0; j < resultBancos[i].datos.length; j++) {
          const index = monedas.findIndex(
            (item) =>
              item.id === Number(resultBancos[i].datos[j].cuentas.id_moneda)
          );
          let moneda = monedas[index].nombre;

          bancoGrupo.push({
            fecha: formatDate(resultBancos[i].datos[j].fecha),
            cuenta,
            concepto: resultBancos[i].datos[j].conceptos.nombre,
            detalle: resultBancos[i].datos[j].detalle,
            rendicion: resultBancos[i].datos[j].id_rendicion,
            ordenes: resultBancos[i].datos[j].ordenes,
            moneda: moneda,
            ingresos:
              exportListado === "pdf"
                ? Number(resultBancos[i].datos[j].ingreso).toLocaleString(
                    "en-US",
                    {
                      minimumFractionDigits: 2,
                    }
                  )
                : Number(resultBancos[i].datos[j].ingreso),
            egresos:
              exportListado === "pdf"
                ? Number(resultBancos[i].datos[j].egreso).toLocaleString(
                    "en-US",
                    {
                      minimumFractionDigits: 2,
                    }
                  )
                : Number(resultBancos[i].datos[j].egreso),
            saldo: null,
          });

          totalGrupoIngresos =
            totalGrupoIngresos + Number(resultBancos[i].datos[j].ingreso);
          totalGrupoEgresos =
            totalGrupoEgresos + Number(resultBancos[i].datos[j].egreso);
          totalFinalIngresos =
            totalFinalIngresos + Number(resultBancos[i].datos[j].ingreso);
          totalFinalEgresos =
            totalFinalEgresos + Number(resultBancos[i].datos[j].egreso);
        }
      }

      worksheet = xlsx.utils.json_to_sheet(bancoGrupo);
      workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "movimientosBancos");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName +
            "_export_" +
            new Date().toLocaleDateString() +
            EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
    startClick();
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.setFontSize(10);
        doc.text(
          `Listado de movimientos de tesoreria Desde: ${formatDate(
            fechaDesde
          )} Hasta: ${formatDate(fechaHasta)}`,
          30,
          8
        );

        let empresas = "";
        for (let i = 0; i < selectedCities.length; i++) {
          if (i === 0) {
            empresas = selectedCities[i].nombre;
          } else {
            empresas = empresas + " - " + selectedCities[i].nombre;
          }
        }

        doc.setFontSize(8);
        doc.text(`Empresas: ${empresas}`, 30, 13);

        let row = 20;

        const groupBy = (prop) => (data) => {
          return data.reduce((dict, item) => {
            const { [prop]: _, ...rest } = item;
            dict[item[prop]] = [...(dict[item[prop]] || []), rest];
            return dict;
          }, {});
        };

        let campo = "id_moneda";

        const resultCaja = Object.entries(groupBy(campo)(result)).map(
          ([key, value]) => ({ nombre: key, datos: value })
        );

        doc.setFontSize(10);
        let titulo = `Movimientos de Caja`;
        doc.text(titulo, 10, row);
        let textWidth = doc.getTextWidth(titulo);
        row = row + 2;
        doc.line(10, row, 10 + textWidth, row);

        let totalGrupoIngresos = 0;
        let totalGrupoEgresos = 0;
        let totalFinalIngresos = 0;
        let totalFinalEgresos = 0;

        row = row + 5;
        for (var i = 0; i < resultCaja.length; i++) {
          const index = monedas.findIndex(
            (item) => item.id === Number(resultCaja[i].nombre)
          );

          if (resultCaja[i].datos.length > 0) {
            let moneda = monedas[index].nombre;
            doc.text(`Moneda: ${moneda}`, 10, row);

            totalGrupoIngresos = 0;
            totalGrupoEgresos = 0;
            let cajaGrupo = [];

            for (var j = 0; j < resultCaja[i].datos.length; j++) {
              cajaGrupo.push({
                fecha: formatDate(resultCaja[i].datos[j].fecha),
                concepto: resultCaja[i].datos[j].conceptos.nombre,
                detalle: resultCaja[i].datos[j].detalle,
                moneda: moneda,
                rendicionOrden:
                  resultCaja[i].datos[j].tipo === "i"
                    ? resultCaja[i].datos[j].id_rendicion
                    : resultCaja[i].datos[j].ordenes,
                ingresos:
                  exportListado === "pdf"
                    ? Number(resultCaja[i].datos[j].ingreso).toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                        }
                      )
                    : Number(resultCaja[i].datos[j].ingreso),
                egresos:
                  exportListado === "pdf"
                    ? Number(resultCaja[i].datos[j].egreso).toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                        }
                      )
                    : Number(resultCaja[i].datos[j].egreso),
                saldo: null,
              });
              totalGrupoIngresos =
                totalGrupoIngresos + Number(resultCaja[i].datos[j].ingreso);
              totalGrupoEgresos =
                totalGrupoEgresos + Number(resultCaja[i].datos[j].egreso);
            }
            if (exportListado === "pdf") {
              cajaGrupo.push({
                fecha: null,
                concepto: "Total " + moneda,
                detalle: null,
                rendicionOrden: null,
                ingresos: Number(totalGrupoIngresos).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                }),
                egresos: Number(totalGrupoEgresos).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                }),
                saldo: Number(
                  totalGrupoIngresos - totalGrupoEgresos
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                }),
              });
            }

            row = row + 3;

            doc.autoTable(exportColumnsCaja, cajaGrupo, {
              margin: { left: 10 },
              startY: row,
              styles: {
                fontSize: 6,
              },
              headStyles: {
                fontStyle: "normal",
                fillColor: "#fdfdfd",
                textColor: "#1c1c1c",
                fontSize: 8,
              },
              //showHead: "never",
              columnStyles: {
                3: {
                  halign: "right",
                },
                4: {
                  halign: "right",
                },
                5: {
                  halign: "right",
                },
              },
              didParseCell: (data) => {
                if (data.cell && data.cell.section === "head") {
                  switch (data.cell.raw) {
                    case "Ingresos":
                    case "Egresos":
                    case "Rend./Orden":
                    case "":
                      data.cell.styles.halign = "right";
                      break;
                    default:
                      data.cell.styles.halign = "left";
                      break;
                  }
                }
              },
            });

            let finalY = doc.previousAutoTable.finalY;

            row = finalY + 5;
          }
        }

        campo = "moneda";

        const resultCheques = Object.entries(groupBy(campo)(result2)).map(
          ([key, value]) => ({ nombre: key, datos: value })
        );

        doc.setFontSize(10);
        titulo = `Movimientos de Cheques`;
        doc.text(titulo, 10, row);
        textWidth = doc.getTextWidth(titulo);
        row = row + 2;
        doc.line(10, row, 10 + textWidth, row);

        let chequeGrupo = [];
        for (var i = 0; i < resultCheques.length; i++) {
          const index = monedas.findIndex(
            (item) => item.id === Number(resultCheques[i].nombre)
          );

          if (resultCheques[i].datos.length > 0) {
            let moneda = monedas[index].nombre;
            row = row + 5;
            doc.text(`Moneda: ${moneda}`, 10, row);
            for (var h = 0; h < 2; h++) {
              let resultChequesTipo = [];
              if (h === 0) {
                resultChequesTipo = resultCheques[i].datos.filter(
                  (item) => item.tipo === "terceros"
                );
              } else {
                resultChequesTipo = resultCheques[i].datos.filter(
                  (item) => item.tipo === "propios"
                );
              }

              if (resultChequesTipo.length > 0) {
                if (h === 0) {
                  row = row + 5;
                  doc.text(`Cheques Terceros`, 10, row);
                } else {
                  row = row + 5;
                  doc.text(`Cheques Propios`, 10, row);
                }

                chequeGrupo = [];
                for (var j = 0; j < resultChequesTipo.length; j++) {
                  chequeGrupo.push({
                    fecha: formatDate(resultChequesTipo[j].fecha),
                    concepto: resultChequesTipo[j].concepto,
                    detalle: resultChequesTipo[j].detalle,
                    estado: resultChequesTipo[j].estado.toUpperCase(),
                    banco:
                      resultChequesTipo[j].banco +
                      "-" +
                      resultChequesTipo[j].numero,
                    empresa: resultChequesTipo[j].empresa,
                    importe:
                      exportListado === "pdf"
                        ? Number(resultChequesTipo[j].importe).toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                            }
                          )
                        : Number(resultChequesTipo[j].importe),
                    moneda: moneda,
                    tipo: resultChequesTipo[j].tipo,
                    rendicion: resultChequesTipo[j].rendicionId,
                    ordenes: resultChequesTipo[j].ordenes,
                  });
                }

                row = row + 3;

                doc.autoTable(exportColumnsCheques, chequeGrupo, {
                  margin: { left: 10 },
                  startY: row,
                  styles: {
                    fontSize: 6,
                  },
                  headStyles: {
                    fontStyle: "normal",
                    fillColor: "#fdfdfd",
                    textColor: "#1c1c1c",
                    fontSize: 8,
                  },
                  //showHead: "never",
                  columnStyles: {
                    1: { cellWidth: 35, fontSize: 5 },
                    5: {
                      halign: "right",
                    },
                  },
                  didParseCell: (data) => {
                    if (data.cell && data.cell.section === "head") {
                      switch (data.cell.raw) {
                        case "Importe":
                          data.cell.styles.halign = "right";
                          break;
                        default:
                          data.cell.styles.halign = "left";
                          break;
                      }
                    }
                  },
                });

                let finalY = doc.previousAutoTable.finalY;
                row = finalY;
              }
            }
          }
        }

        campo = "id_moneda";

        const resultBancos = Object.entries(groupBy(campo)(result3)).map(
          ([key, value]) => ({ nombre: key, datos: value })
        );

        row = row + 5;
        doc.setFontSize(10);
        titulo = `Movimientos de Bancos`;
        doc.text(titulo, 10, row);
        textWidth = doc.getTextWidth(titulo);
        row = row + 2;
        doc.line(10, row, 10 + textWidth, row);
        let cuenta;

        for (var i = 0; i < resultBancos.length; i++) {
          totalFinalIngresos = 0;
          totalFinalEgresos = 0;

          let index = monedas.findIndex(
            (item) => item.id === Number(resultBancos[i].nombre)
          );

          let moneda;

          if (resultBancos[i].datos.length > 0) {
            moneda = monedas[index].nombre;
            row = row + 5;
            doc.text(`Moneda: ${moneda}`, 10, row);

            let resultBancosArray = resultBancos[i].datos;

            campo = "id_cuenta";

            const resultBancosCuentas = Object.entries(
              groupBy(campo)(resultBancosArray)
            ).map(([key, value]) => ({ nombre: key, datos: value }));

            let bancoGrupo = [];
            for (var j = 0; j < resultBancosCuentas.length; j++) {
              index = cuentas.findIndex(
                (item) => item.id === Number(resultBancosCuentas[j].nombre)
              );
              cuenta = cuentas[index].nombre;
              row = row + 5;
              doc.text(`Cuenta ${cuenta}`, 10, row);
              bancoGrupo = [];
              totalGrupoIngresos = 0;
              totalGrupoEgresos = 0;

              for (var h = 0; h < resultBancosCuentas[j].datos.length; h++) {
                bancoGrupo.push({
                  fecha: formatDate(resultBancosCuentas[j].datos[h].fecha),
                  concepto: resultBancosCuentas[j].datos[h].conceptos.nombre,
                  detalle: resultBancosCuentas[j].datos[h].detalle,
                  rendicion: resultBancosCuentas[j].datos[h].id_rendicion,
                  ordenes: resultBancosCuentas[j].datos[h].ordenes,
                  moneda: moneda,
                  ingresos:
                    exportListado === "pdf"
                      ? Number(
                          resultBancosCuentas[j].datos[h].ingreso
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })
                      : Number(resultBancosCuentas[j].datos[h].ingreso),
                  egresos:
                    exportListado === "pdf"
                      ? Number(
                          resultBancosCuentas[j].datos[h].egreso
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })
                      : Number(resultBancosCuentas[j].datos[h].egreso),
                  saldo: null,
                });

                totalGrupoIngresos =
                  totalGrupoIngresos +
                  Number(resultBancosCuentas[j].datos[h].ingreso);
                totalGrupoEgresos =
                  totalGrupoEgresos +
                  Number(resultBancosCuentas[j].datos[h].egreso);
                totalFinalIngresos =
                  totalFinalIngresos +
                  Number(resultBancosCuentas[j].datos[h].ingreso);
                totalFinalEgresos =
                  totalFinalEgresos +
                  Number(resultBancosCuentas[j].datos[h].egreso);
              }

              if (exportListado === "pdf") {
                bancoGrupo.push({
                  fecha: null,
                  concepto: "Total " + cuenta,
                  detalle: null,
                  rendicion: null,
                  ordenes: null,
                  moneda: null,
                  ingresos: Number(totalGrupoIngresos).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  }),
                  egresos: Number(totalGrupoEgresos).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  }),
                  saldo: Number(
                    totalGrupoIngresos - totalGrupoEgresos
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  }),
                });
              }

              if (i === resultBancos.length - 1 && j=== resultBancosCuentas.length - 1) {
                bancoGrupo.push({
                  fecha: null,
                  concepto: "Total " + moneda,
                  detalle: null,
                  rendicion: null,
                  ordenes: null,
                  moneda: null,
                  ingresos: Number(totalFinalIngresos).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  }),
                  egresos: Number(totalFinalEgresos).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  }),
                  saldo: Number(
                    totalFinalIngresos - totalFinalEgresos
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  }),
                });
              }
              


              row = row + 3;

              doc.autoTable(exportColumnsBancos, bancoGrupo, {
                margin: { left: 10 },
                startY: row,
                styles: {
                  fontSize: 6,
                },
                headStyles: {
                  fontStyle: "normal",
                  fillColor: "#fdfdfd",
                  textColor: "#1c1c1c",
                  fontSize: 8,
                },
                //showHead: "never",
                columnStyles: {
                  5: {
                    halign: "right",
                  },
                  6: {
                    halign: "right",
                  },
                  7: {
                    halign: "right",
                  },
                },
                didParseCell: (data) => {
                  if (data.cell && data.cell.section === "head") {
                    switch (data.cell.raw) {
                      case "Ingresos":
                      case "Egresos":
                      case "":
                          data.cell.styles.halign = "right";
                        break;
                      default:
                        data.cell.styles.halign = "left";
                        break;
                    }
                  }
                },
              });
              let finalY = doc.previousAutoTable.finalY;
              row = finalY;
            }





          }


        }

        window.open(doc.output("bloburl"));
        //doc.save("residentes_caidas_" + new Date().toLocaleDateString() +".pdf");
      });
    });
    startClick();
  };

  const startClick = (e) => {
    setTimeout(() => {
      //navigate("listadosservicios");
      setVisible(true);
    }, 2000);
  };

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  // INICIO

  if (!bandInicio && empresas && empresas.length > 0 && empresaElegida) {
    setBandInicio(true);
    const index = empresas.findIndex((item) => item.id === empresaElegida.id);
    if (index !== -1) {
      let final = [];
      final.push(empresas[index]);
      setSelectedCities(final);
    }
  }

  // FIN

  //console.log(cajas);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Dialog
            header={renderHeader()}
            visible={visible}
            closable={false}
            footer={renderFooter()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "75vw", paddingBottom: "0" }}
            position="center"
          >
            <Grid container spacing={3} style={{ marginTop: "0vw" }}>
              <Grid item xs={12} md={3}>
                <TextField
                  id="fechaDesde"
                  label="Fecha Desde"
                  type="date"
                  variant="standard"
                  fullWidth
                  value={fechaDesde ? fechaDesde : null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    guardarFechaDesde(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  id="fechaHasta"
                  label="Fecha Hasta"
                  type="date"
                  variant="standard"
                  fullWidth
                  value={fechaHasta ? fechaHasta : null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    guardarFechaHasta(e.target.value);
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                style={{ marginLeftLeft: "1vw", marginTop: "0em" }}
              >
                <Autocomplete
                  id="agrupar"
                  fullWidth
                  options={agrupar}
                  value={grupo}
                  disablePortal
                  getOptionLabel={(option) => option.nombre}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Agrupar por"
                      variant="standard"
                    />
                  )}
                  //onKeyDown={handleEnter}
                  onChange={(event, value) => {
                    if (value) {
                      setGrupo(value);
                    } else {
                      setGrupo(null);
                    }
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                style={{ paddingLeft: "1vw", marginTop: "1vw" }}
              >
                <label htmlFor="id">Empresas</label>

                <MultiSelect
                  value={selectedCities}
                  onChange={(e) => setSelectedCities(e.value)}
                  options={
                    empresas ? empresas.filter((item) => item.habilitado) : null
                  }
                  optionLabel="nombre"
                  placeholder="Seleccione las empresas"
                  maxSelectedLabels={5}
                  display="chip"
                  style={{ marginLeft: "1vw" }}
                  //className="w-full md:w-40rem"
                />
              </Grid>
            </Grid>
          </Dialog>
        </Fragment>
      ) : null}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}
/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

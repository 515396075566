import React, { useState, Fragment, useContext, useRef } from "react";
import { createContext } from "react";
import clienteAxios from "../config/axios";
import { AlertContext } from "./AlertContext";
import { v4 as uuidv4 } from "uuid";
import { maxHeight } from "@mui/system";
import { Toast } from "primereact/toast";
import axios from "axios";
import { NumeroLetras } from "../../src/components/layout/util/NumeroaLetras";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import QRCode from "qrcode";
//import afip from "../../../../assets/images/comprobanteautorizado.png";
import afip from "../assets/images/comprobanteautorizado.png";
import bwipjs from "bwip-js";
import { jsPDF } from "jspdf";

const ConfiguracionContext = createContext();
const { Provider, Consumer } = ConfiguracionContext;

function ConfiguracionProvider({ children }) {
  const toast = useRef(null);
  const [rows, setRows] = useState(null);
  const [usuarios, guardarUsuarios] = useState([]);
  const [pantallas, guardarPantallas] = useState([]);
  const [configuracion, guardarConfiguracion] = useState(null);
  const [configuracionesEmpresas, guardarConfiguracionesEmpresas] = useState(
    []
  );
  const [configuracionesCobradores, guardarConfiguracionesCobradores] =
    useState([]);
  const [configuracionesFormasPagos, guardarConfiguracionesFormasPagos] =
    useState([]);
  const [statusAcceso, setStatusAcceso] = useState(0);
  const [statusAcceso2, setStatusAcceso2] = useState(0);
  const [statusAcceso3, setStatusAcceso3] = useState(0);
  const [statusAcceso4, setStatusAcceso4] = useState(0);
  const [parametros, setParametros] = useState({
    edadPediatrico: 12,
    diurnoDesde: "07:00",
    diurnoHasta: "17:00",
    nocturnoDesde: "17:00",
    nocturnoHasta: "07:00",
    medicamentos: false,
  });
  const [rubros, guardarRubros] = useState([]);
  const [bancos, guardarBancos] = useState([]);
  const [cuentas, guardarCuentas] = useState([]);
  const [monedas, guardarMonedas] = useState([]);
  const [monedaPredeterminada, guardarMonedaPredeterminada] = useState(null);
  const [conceptos, guardarConceptos] = useState([]);
  const [clientes, guardarClientes] = useState([]);
  const [grupos, guardarGrupos] = useState([]);
  const [localidades, guardarLocalidades] = useState([]);
  const [provincias, guardarProvincias] = useState([]);
  const [empresas, guardarEmpresas] = useState([]);
  const [planes, guardarPlanes] = useState([]);
  const [cobradores, guardarCobradores] = useState([]);
  const [asesores, guardarAsesores] = useState([]);
  const [frecuencias, guardarFrecuencias] = useState([]);
  const [tiposContribuyentes, guardarTiposContribuyentes] = useState([]);
  const [tiposComprobantes, guardarTiposComprobantes] = useState([]);
  const [tiposDocumentos, guardarTiposDocumentos] = useState([]);
  const [formasPagos, guardarFormasPagos] = useState([]);
  const [motivosBajas, guardarMotivosBajas] = useState([]);
  const [motivosSuspendidos, guardarMotivosSuspendidos] = useState([]);
  const [afiliado, guardarAfiliado] = useState(null);
  const [bandFirst, setBandFirst] = useState(true);
  const [grupo, guardarGrupo] = useState(null);
  const [rubro, guardarRubro] = useState(null);
  const [provincia, guardarProvincia] = useState(null);
  const [provinciaFacturacion, guardarProvinciaFacturacion] = useState(null);
  const [empresa, guardarEmpresa] = useState(null);
  const [localidad, guardarLocalidad] = useState(null);
  const [localidadFacturacion, guardarLocalidadFacturacion] = useState(null);
  const [domicilioGeo, guardarDomicilioGeo] = useState(null);
  const [domicilioGeoFacturacion, guardarDomicilioGeoFacturacion] =
    useState(null);
  const [tipoDocumento, guardarTipoDocumento] = useState(null);
  const [plan, guardarPlan] = useState(null);
  const [planExtraordinaria, guardarPlanExtraordinaria] = useState(null);
  const [asesor, guardarAsesor] = useState(null);
  const [cobrador, guardarCobrador] = useState(null);
  const [frecuencia, guardarFrecuencia] = useState(null);
  const [tipoContribuyente, guardarTipoContribuyente] = useState(null);
  const [formaPago, guardarFormaPago] = useState(null);
  const [titular, guardarTitular] = useState(null);
  const [integrantes, guardarIntegrantes] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [cliente, guardarCliente] = useState(null);
  const [proveedor, guardarProveedor] = useState(null);
  const [selectedRowClientes, setSelectedRowClientes] = useState(null);
  const [proximoIntegrante, setProximoIntegrante] = useState(null);
  const [historiales, guardarHistoriales] = useState([]);
  const [adicionales, guardarAdicionales] = useState([]);
  const [adicional, guardarAdicional] = useState([]);
  const [modulo, guardarModulo] = useState([]);
  const [integrantesHabilitados, setIntegrantesHabilitados] = useState(0);
  const [integrantesInhabilitados, setIntegrantesInhabilitados] = useState(0);
  const [integrantesTotales, setIntegrantesTotales] = useState(0);
  const [comprobantes, guardarComprobantes] = useState([]);
  const [comprobantesAnteriores, guardarComprobantesAnteriores] = useState([]);
  const [comprobante, guardarComprobante] = useState(null);
  const [empresaElegida, setEmpresaElegida] = useState(null);
  const [numeroComprobante, guardarNumeroComprobante] = useState(null);
  const [ultimoNumeroAfip, guardarUltimoNumeroAfip] = useState(null);
  const [ivas, guardarIvas] = useState([]);
  const [iva, guardarIva] = useState(null);
  const [percepciones, guardarPercepciones] = useState([]);
  const [comprobanteAsociado, guardarComprobanteAsociado] = useState(null);
  const [idComprobante, guardarIdComprobante] = useState(null);
  const [idOrden, guardarIdOrden] = useState(null);
  const [urlPdf, guardarUrlPdf] = useState(null);
  const [factura, guardarFactura] = useState(null);
  const [items, guardarItems] = useState([]);
  const [itemsRendicion, guardarItemsRendicion] = useState([]);
  const [tiposRetenciones, guardarTiposRetenciones] = useState([]);
  const [rendiciones, guardarRendiciones] = useState([]);
  const [rendicionesMultiselect, guardarRendicionesMultiselect] = useState([]);
  const [configuracionesRendicion, guardarConfiguracionesRendicion] =
    useState(null);
  const [nc, guardarNc] = useState([]);
  const [ajustes, guardarAjustes] = useState([]);
  const [aCuenta, guardarACuenta] = useState([]);
  const [comprobanteId, guardarComprobanteId] = useState(null);
  const [rendicionNumero, setRendicionNumero] = useState(null);
  const [bandComprobante, setBandComprobante] = useState(null);
  const [comisionCobradorEmpresa, guardarComisionCobradorEmpresa] =
    useState(null);
  const [empresaComision, guardarEmpresaComision] = useState(null);
  const [cobradorComision, guardarCobradorComision] = useState([]);
  const [cuentaBancoPredeterminada, guardarCuentaBancoPredeterminada] =
    useState(null);
  const [verificacionAfip, guardarVerificacionAfip] = useState(null);
  const [movimientosCajas, guardarMovimientosCajas] = useState([]);
  const [cajasSaldoAnterior, guardarCajasSaldoAnterior] = useState(null);
  const [cajasIngresos, guardarCajasIngresos] = useState(null);
  const [cajasEgresos, guardarCajasEgresos] = useState(null);
  const [cajasSaldoActual, guardarCajasSaldoActual] = useState(null);
  const [movimientosCheques, guardarMovimientosCheques] = useState([]);
  const [proveedores, guardarProveedores] = useState([]);
  const [condicionesPagos, guardarCondicionesPagos] = useState([]);
  const [ordenes, guardarOrdenes] = useState([]);
  const [ordenPago, guardarOrdenPago] = useState(null);
  const [estadoOrden, guardarEstadoOrden] = useState("PENDIENTE");
  const [movimientosCompensaciones, guardarMovimientosCompensaciones] =
    useState([]);
  const [movimientosBancos, guardarMovimientosBancos] = useState([]);
  const [bancosSaldoAnterior, guardarBancosSaldoAnterior] = useState(null);
  const [bancosIngresos, guardarBancosIngresos] = useState(null);
  const [bancosEgresos, guardarBancosEgresos] = useState(null);
  const [bancosSaldoActual, guardarBancosSaldoActual] = useState(null);
  const [cierre, guardarCierre] = useState({
    periodo: null,
    cerrado: null,
  });
  const [clienteRepetido, setClienteRepetido] = useState(false);
  const [documentoRepetido, setDocumentoRepetido] = useState(false);
  const [ctasCtes, guardarCtasCtes] = useState([]);
  const [verComprobante, setVerComprobante] = useState(true);
  const [saldos, guardarSaldos] = useState([]);
  const [listadoExcel, guardarListadoExcel] = useState([]);
  const [informes, guardarInformes] = useState([]);
  const [rebotes, guardarRebotes] = useState([]);
  const [impresionComprobantes, guardarImpresionComprobantes] = useState([]);
  const [procesos, guardarProcesos] = useState([]);
  const [procesosVentas, guardarProcesosVentas] = useState([]);
  const [procesosCompras, guardarProcesosCompras] = useState([]);
  const [totalTarjetas, guardarTotalTarjetas] = useState(null);
  const [aceptados, guardarAceptados] = useState([]);
  const [rechazados, guardarRechazados] = useState([]);
  const [mercadoPago, guardarMercadoPago] = useState([]);
  const [diasEnvios, guardarDiasEnvios] = useState([]);
  const [moverSabado, guardarMoverSabado] = useState(null);
  const [moverDomingo, guardarMoverDomingo] = useState(null);
  const [cotizaciones, guardarCotizaciones] = useState([]);
  const [formaPagoProveedor, guardarFormaPagoProveedor] = useState([]);
  const [resultProgressBar, guardarResultProgressBar] = useState(null);
  const [result, guardarResult] = useState(null);
  const [result2, guardarResult2] = useState(null);
  const [result3, guardarResult3] = useState(null);

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  function formatDate(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  //Usuarios

  const obtenerUsuarios = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerusuarios"
      );
      let data = respuesta.data;
      guardarUsuarios(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Usuarios", error.response.data, "error");
    }
  };

  //Ajustes

  const obtenerAjustes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerajustes",
        datos
      );
      let data = respuesta.data;
      console.log(data);
      guardarAjustes(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Ajustes", error.response.data, "error");
    }
  };

  //A Cuenta

  const obtenerACuenta = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneracuenta",
        datos
      );
      let data = respuesta.data;
      guardarACuenta(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("A Cuenta", error.response.data, "error");
    }
  };

  //NC

  const obtenerNc = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenernc",
        datos
      );
      let data = respuesta.data;
      guardarNc(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Notas de Crédito", error.response.data, "error");
    }
  };

  //Pantallas

  const obtenerAccesos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneraccesos",
        datos
      );
      let data = respuesta.data;

      const local = [];
      const rows = [];

      //console.log(data)

      if (data.response2.length === 0) {
        for (var i = 0; i < data.response.length; i++) {
          local.push({
            id: data.response[i].id,
            nombre: data.response[i].nombre,
            descripcion: data.response[i].descripcion,
            menu: data.response[i].menu,
            tableData: { checked: false },
          });
        }

        guardarPantallas(local);
        return;
      }

      for (var i = 0; i < data.response.length; i++) {
        let band = false;
        for (var j = 0; j < data.response2.length; j++) {
          if (data.response[i].nombre === data.response2[j].layout) {
            band = true;
          }
        }
        if (band) {
          local.push({
            id: data.response[i].id,
            nombre: data.response[i].nombre,
            descripcion: data.response[i].descripcion,
            menu: data.response[i].menu,
            tableData: { checked: true },
          });
          rows.push({
            id: data.response[i].id,
            nombre: data.response[i].nombre,
            descripcion: data.response[i].descripcion,
            menu: data.response[i].menu,
            tableData: { checked: true },
          });
          setRows(rows);
        } else {
          local.push({
            id: data.response[i].id,
            nombre: data.response[i].nombre,
            descripcion: data.response[i].descripcion,
            menu: data.response[i].menu,
            tableData: { checked: false },
          });
        }
      }
      guardarPantallas(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Pantallas", error.response.data, "error");
    }
  };

  const agregarAccesos = async (datos) => {
    const { rows, usuario } = datos;

    const local = [];

    if (rows !== null) {
      for (var i = 0; i < rows.length; i++) {
        local.push({
          id: 0,
          id_usuario: usuario.id,
          layout: rows[i].nombre,
          sistema: 2,
        });
      }
    }

    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregaraccesos",
        { local, usuario }
      );

      mensajeAlerta("Accesos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Accesos", error.response.data, "error");
    }
  };

  const obtenerAcceso = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneracceso",
        datos
      );
      //let data = respuesta.data;
      setStatusAcceso(200);
    } catch (error) {
      setStatusAcceso(400);
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerAcceso2 = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneracceso",
        datos
      );
      //let data = respuesta.data;
      setStatusAcceso2(200);
    } catch (error) {
      setStatusAcceso2(400);
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerAcceso3 = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneracceso",
        datos
      );
      //let data = respuesta.data;
      setStatusAcceso3(200);
    } catch (error) {
      setStatusAcceso3(400);
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerAcceso4 = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneracceso",
        datos
      );
      //let data = respuesta.data;
      setStatusAcceso4(200);
    } catch (error) {
      setStatusAcceso4(400);
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };

  // Periodos

  const cerrarPeriodo = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/cerrarperiodo",
        datos
      );

      mensajeAlerta("Cierre Período", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cierre Período", error.response.data, "error");
    }
  };

  const obtenerCierre = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercierre",
        datos
      );
      let data = respuesta.data;

      //console.log(data)
      guardarCierre(data);
    } catch (error) {
      guardarCierre({
        ...cierre,
        cerrado: null,
      });
      console.log(error.response);
    }
  };

  //Configuracion

  const obtenerConfiguracion = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerconfiguracion"
      );
      let data = respuesta.data;

      //console.log(data)
      guardarConfiguracion(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const actualizarConfiguracion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarconfiguracion",
        datos
      );

      mensajeAlerta("Configuración", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Configuración", error.response.data, "error");
    }
  };

  const actualizarConfiguracionesFacturacion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarconfiguracionesfacturacion",
        datos
      );

      mensajeAlerta("Configuraciónes", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Configuraciónes", error.response.data, "error");
    }
  };

  const obtenerConfiguracionesFacturacion = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerconfiguracionesfacturacion"
      );
      let data1 = respuesta.data.response1;
      let data2 = respuesta.data.response2;
      let data3 = respuesta.data.response3;

      guardarConfiguracionesEmpresas(data1);
      guardarConfiguracionesCobradores(data2);
      guardarConfiguracionesFormasPagos(data3);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerConfiguracionesRendicion = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerconfiguracionesrendicion"
      );
      let data = respuesta.data;

      guardarConfiguracionesRendicion(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const actualizarConfiguracionesRendicion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarconfiguracionesrendicion",
        datos
      );

      mensajeAlerta("Configuraciónes", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Configuraciónes", error.response.data, "error");
    }
  };

  //Ivas

  const obtenerIvas = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerivas"
      );

      let data = respuesta.data;

      guardarIvas(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Ivas", error.response.data, "error");
    }
  };

  const obtenerIva = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneriva",
        datos
      );

      let data = respuesta.data;

      guardarIva(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Ivas", error.response.data, "error");
    }
  };

  // Proveedores

  const actualizarProveedor = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarproveedor",
        datos
      );

      mensajeAlerta("Proveedores", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Proveedores", error.response.data, "error");
    }
  };

  const agregarProveedor = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarproveedor",
        datos
      );

      mensajeAlerta("Proveedores", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Proveedores", error.response.data, "error");
    }
  };

  const obtenerProveedores = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerproveedores"
      );

      let data = respuesta.data;

      guardarProveedores(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Proveedores", error.response.data, "error");
    }
  };

  const obtenerProveedoresComprobantes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerproveedorescomprobantes",
        datos
      );

      let data = respuesta.data;
      guardarProveedores(data);
      setRows(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Proveedores", error.response.data, "error");
    }
  };

  //Condiciones Pagos

  const obtenerCondicionesPagos = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercondicionespagos"
      );

      let data = respuesta.data;

      guardarCondicionesPagos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Condiciones Pagos", error.response.data, "error");
    }
  };

  // Emails y WhatsApp

  const enviarEmailComprobante = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/enviaremailcomprobante",
        datos
      );

      mensajeAlerta("Email", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Email", error.response.data, "error");
    }
  };

  const enviarWhatsAppAfiliado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/enviarwhatsappafiliado",
        datos
      );

      mensajeAlerta("WhatsApp", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("WhatsApp", error.response.data, "error");
    }
  };

  const enviarWhatsAppComprobante = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/enviarwhatsappcomprobante",
        datos
      );

      mensajeAlerta("WhatsApp", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("WhatsApp", error.response.data, "error");
    }
  };

  const obtenerMensajesWhatsApp = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermensajeswhatsapp",
        datos
      );
      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        for (var j = 0; j < data[i].length; j++) {
          local.push({
            id: data[i][j].id,
            telefono: data[i][j].phone,
            status: data[i][j].deliveryStatus,
            enviado: formatDate(data[i][j].deliverAt),
            mensaje: data[i][j].message,
          });
        }

        guardarListadoExcel(local);
      }
    } catch (error) {
      //guardarMovimientosCompensaciones([]);
      console.log(error.response);
      mensajeAlerta(
        "Mensajes de WhatsApp",
        error.response.data.message,
        "error"
      );
    }
  };

  //Caja

  const obtenerMovimientos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermovimientos",
        datos
      );

      let dataCaja = respuesta.data.responseCaja;
      let dataCheques = respuesta.data.responseCheques;
      let dataBancos = respuesta.data.responseBancos;

      guardarResult(dataCaja);
      guardarResult3(dataBancos);

      let local = [];
      for (var i = 0; i < dataCheques.length; i++) {
        local.push({
          fecha: dataCheques[i].fecha,
          concepto: dataCheques[i].conceptos.nombre,
          detalle: dataCheques[i].detalle,
          estado: dataCheques[i].estado,
          banco: dataCheques[i].estadoscheques.detalleBanco,
          numero: dataCheques[i].estadoscheques.numero,
          empresa: dataCheques[i].empresas.nombre,
          importe: dataCheques[i].importe,
          rendicionId: dataCheques[i].id_rendicion
            ? dataCheques[i].id_rendicion
            : null,
          ordenes: dataCheques[i].ordenes ? dataCheques[i].ordenes : null,
          moneda: dataCheques[i].estadoscheques.id_moneda,
          tipo: dataCheques[i].estadoscheques.tipo,
        });
      }

      guardarResult2(local);

      //guardarResult(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cajas", error.response.data, "error");
    }
  };

  //Rubros

  const obtenerRubros = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerrubros"
      );

      let data = respuesta.data;

      guardarRubros(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Rubros", error.response.data, "error");
    }
  };

  const actualizarRubro = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarrubro",
        datos
      );

      mensajeAlerta("Rubros", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Rubros", error.response.data, "error");
    }
  };

  const agregarRubro = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarrubro",
        datos
      );

      mensajeAlerta("Rubros", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Rubros", error.response.data, "error");
    }
  };

  //Bancos

  const obtenerMovimientosBancos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermovimientosbancos",
        datos
      );
      let data = respuesta.data.response;
      let saldoIngresosAnterior = respuesta.data.responseIngresosAnterior.sum;
      let saldoEgresosAnterior = respuesta.data.responseEgresosAnterior.sum;
      let local = [];
      let ingresos = 0;
      let egresos = 0;
      let saldoAnterior =
        Number(saldoIngresosAnterior) - Number(saldoEgresosAnterior);

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          tipo: data[i].tipo,
          fecha: data[i].fecha,
          id_concepto: data[i].id_concepto,
          concepto:
            data[i].conceptos !== undefined ? data[i].conceptos.nombre : null,
          detalle: data[i].detalle,
          ingreso: data[i].ingreso,
          egreso: data[i].egreso,
          importe: data[i].importe,
          usuario: data[i].usuario,
          id_cuenta: data[i].cuentas !== undefined ? data[i].cuentas.id : null,
          cuenta: data[i].cuentas !== undefined ? data[i].cuentas.nombre : null,
          id_empresa:
            data[i].cuentas.empresas !== undefined
              ? data[i].cuentas.empresas.id
              : null,
          empresa:
            data[i].cuentas.empresas !== undefined
              ? data[i].cuentas.empresas.nombre
              : null,
        });
        if (data[i].tipo === "i") {
          ingresos = Number(ingresos) + Number(data[i].ingreso);
        } else {
          egresos = Number(egresos) + Number(data[i].egreso);
        }
      }
      guardarMovimientosBancos(local);
      guardarBancosSaldoAnterior(saldoAnterior);
      guardarBancosEgresos(egresos);
      guardarBancosIngresos(ingresos);
      guardarBancosSaldoActual(
        Number(saldoAnterior) + Number(ingresos) - Number(egresos)
      );
    } catch (error) {
      guardarMovimientosBancos([]);
      console.log(error.response);
      mensajeAlerta("Movimientos de Bancos", error.response.data, "error");
    }
  };

  const agregarMovimientoBanco = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarmovimientobanco",
        datos
      );

      mensajeAlerta("Movimientos de Bancos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Movimientos de Bancos", error.response.data, "error");
    }
  };

  const agregarTransferencia = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregartransferencia",
        datos
      );

      mensajeAlerta("Transferencia Cuentas Propias", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(
        "Transferencia Cuentas Propias",
        error.response.data,
        "error"
      );
    }
  };

  const obtenerBancos = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerbancos"
      );

      let data = respuesta.data;

      guardarBancos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Bancos", error.response.data, "error");
    }
  };

  const actualizarBanco = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarbanco",
        datos
      );

      mensajeAlerta("Bancos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Bancos", error.response.data, "error");
    }
  };

  const agregarBanco = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarbanco",
        datos
      );

      mensajeAlerta("Bancos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Bancos", error.response.data, "error");
    }
  };

  //Cuentas

  const obtenerCuentas = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercuentas"
      );

      let data = respuesta.data;

      guardarCuentas(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cuentas", error.response.data, "error");
    }
  };

  const actualizarCuenta = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarcuenta",
        datos
      );

      mensajeAlerta("Cuentas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cuentas", error.response.data, "error");
    }
  };

  const agregarCuenta = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarcuenta",
        datos
      );

      mensajeAlerta("Cuentas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cuentas", error.response.data, "error");
    }
  };

  //Monedas

  const obtenerMonedas = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermonedas"
      );

      let data = respuesta.data;

      let predeterminada = data.find((item) => item.predeterminado);

      guardarMonedas(data);
      guardarMonedaPredeterminada(predeterminada.id);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Monedas", error.response.data, "error");
    }
  };

  const actualizarMoneda = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarmoneda",
        datos
      );

      mensajeAlerta("Monedas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Monedas", error.response.data, "error");
    }
  };

  const agregarMoneda = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarmoneda",
        datos
      );

      mensajeAlerta("Monedas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Monedas", error.response.data, "error");
    }
  };

  //Conceptos

  const obtenerConceptos = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerconceptos"
      );

      let data = respuesta.data;

      guardarConceptos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Conceptos", error.response.data, "error");
    }
  };

  const actualizarConcepto = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarconcepto",
        datos
      );

      mensajeAlerta("Conceptos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Conceptos", error.response.data, "error");
    }
  };

  const agregarConcepto = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarconcepto",
        datos
      );

      mensajeAlerta("Conceptos", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Conceptos", error.response.data, "error");
    }
  };

  const obtenerConceptosAutomaticos = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerconceptosautomaticos"
      );

      let data = respuesta.data;

      guardarConceptos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Conceptos", error.response.data, "error");
    }
  };

  //Tipos de Retenciones

  const obtenerTiposRetenciones = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertiposretenciones"
      );

      let data = respuesta.data;

      guardarTiposRetenciones(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tipos de Retenciones", error.response.data, "error");
    }
  };

  const actualizarTipoRetencion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizartiporetencion",
        datos
      );

      mensajeAlerta("Tipos de Retenciones", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tipos de Retenciones", error.response.data, "error");
    }
  };

  const agregarTipoRetencion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregartiporetencion",
        datos
      );

      mensajeAlerta("Tipos de Retenciones", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tipos de Retenciones", error.response.data, "error");
    }
  };

  //Rendiciones

  const obtenerComprobanteIdRendicionesPendientes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercomprobanteidrendicionespendientes",
        datos
      );
      let data = respuesta.data;
      guardarComprobanteId(data);
    } catch (error) {
      guardarComprobanteId(null);
      console.log(error.response);
      //mensajeAlerta("Rendiciones", error.response.data, "error");
    }
  };

  const obtenerItemsRendicionPendiente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneritemsrendicionpendiente",
        datos
      );
      let data = respuesta.data;

      window.localStorage.setItem("RENDICION_DIARIA", JSON.stringify(data));
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Rendiciones", error.response.data, "error");
    }
  };

  const obtenerItemsRendicionAplicada = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneritemsrendicionaplicada",
        datos
      );
      let data = respuesta.data;
      guardarItemsRendicion(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Rendiciones", error.response.data, "error");
    }
  };

  const obtenerItemsRendicionAnulada = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneritemsrendicionanulada",
        datos
      );
      let data = respuesta.data;

      guardarItemsRendicion(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Rendiciones", error.response.data, "error");
    }
  };

  const obtenerItemsRendicionEliminada = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneritemsrendicioneliminada",
        datos
      );
      let data = respuesta.data;

      guardarItemsRendicion(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Rendiciones", error.response.data, "error");
    }
  };

  const obtenerItemsRendicion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneritemsrendicionpendiente",
        datos
      );
      let data = respuesta.data;

      guardarItemsRendicion(data);
    } catch (error) {
      console.log(error.response);
      guardarItemsRendicion(null);
      //mensajeAlerta("Rendiciones", error.response.data, "error");
    }
  };

  const obtenerItemsRendiciones = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneritemsrendicionespendientes",
        datos
      );
      let data = respuesta.data;

      guardarItemsRendicion(data);
    } catch (error) {
      console.log(error.response);
      guardarItemsRendicion(null);
      //mensajeAlerta("Rendiciones", error.response.data, "error");
    }
  };

  const eliminarRendicionPendiente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/eliminarrendicionpendiente",
        datos
      );

      mensajeAlerta("Rendiciones", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Rendiciones", error.response.data, "error");
    }
  };

  const confirmarRendicionPendiente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/confirmarrendicionpendiente",
        datos
      );

      mensajeAlerta("Rendiciones", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Rendiciones", error.response.data, "error");
    }
  };

  const aplicarRendicionConfirmada = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/aplicarrendicionconfirmada",
        datos
      );

      mensajeAlerta("Rendiciones", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Rendiciones", error.response.data, "error");
    }
  };

  const obtenerRendicionesConfirmadas = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerrendicionesconfirmadas"
      );

      let data = respuesta.data;

      guardarRendiciones(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Rendiciones", error.response.data, "error");
    }
  };

  const obtenerRendicionesAplicadas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerrendicionesaplicadas",
        datos
      );

      let data = respuesta.data;
      guardarRendiciones(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Rendiciones", error.response.data, "error");
    }
  };

  const obtenerRendicionAplicada = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerrendicionaplicada",
        datos
      );

      let data = respuesta.data;

      let local = [];
      local.push(data);

      guardarRendiciones(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Rendiciones", error.response.data, "error");
    }
  };

  const obtenerRendicionAplicada2 = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerrendicionaplicada2",
        datos
      );

      let data = respuesta.data;

      let local = [];
      local.push(data);

      guardarRendiciones(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Rendiciones", error.response.data, "error");
    }
  };


  const obtenerRendicionesAnuladas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerrendicionesanuladas",
        datos
      );

      let data = respuesta.data;

      //console.log(data)

      guardarRendiciones(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Rendiciones", error.response.data, "error");
    }
  };

  const obtenerRendicionesEliminadas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerrendicioneseliminadas",
        datos
      );

      let data = respuesta.data;

      //console.log(data)

      guardarRendiciones(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Rendiciones", error.response.data, "error");
    }
  };

  const obtenerRendicionesPendientes = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerrendicionespendientes"
      );

      let data = respuesta.data;
      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          id_cobrador: data[i].id_cobrador,
          cobrador: data[i].cobradores.nombre,
          id_rendicion: data[i].id_rendicion,
          id_empresa: data[i].id_empresa,
          dataMultiselect: data[i].id_rendicion + "-" + data[i].empresas.nombre,
          estado: data[i].estado,
        });
      }

      guardarRendiciones(data);
      guardarRendicionesMultiselect(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Rendiciones", error.response.data, "error");
    }
  };

  const actualizarRendicionPendiente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarrendicion",
        datos
      );

      mensajeAlerta("Rendiciones", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Rendiciones", error.response.data, "error");
    }
  };

  const actualizarFechaRendicionPendiente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarfecharendicionpendiente",
        datos
      );

      mensajeAlerta("Rendiciones", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Rendiciones", error.response.data, "error");
    }
  };

  const agregarRendicionPendiente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarrendicionpendiente",
        datos
      );

      mensajeAlerta("Rendiciones", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Rendiciones", error.response.data, "error");
    }
  };

  //Modulos Adicionales

  const obtenerAdicionales = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obteneradicionales"
      );

      let data = respuesta.data;

      guardarAdicionales(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Módulos Adicionales", error.response.data, "error");
    }
  };

  const actualizarAdicional = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizaradicional",
        datos
      );

      mensajeAlerta("Módulos Adicionales", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Módulos Adicionales", error.response.data, "error");
    }
  };

  const agregarAdicional = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregaradicional",
        datos
      );

      mensajeAlerta("Módulos Adicionales", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Módulos Adicionales", error.response.data, "error");
    }
  };

  //Motivos Bajas

  const obtenerMotivosBajas = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermotivosbajas"
      );

      let data = respuesta.data;

      guardarMotivosBajas(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Motivos de Bajas", error.response.data, "error");
    }
  };

  const actualizarMotivoBaja = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarmotivobaja",
        datos
      );

      mensajeAlerta("Motivos de Bajas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Motivos de Bajas", error.response.data, "error");
    }
  };

  const agregarMotivoBaja = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarmotivobaja",
        datos
      );

      mensajeAlerta("Motivos de Bajas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Motivos de Bajas", error.response.data, "error");
    }
  };

  //Motivos Suspendidos

  const obtenerMotivosSuspendidos = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermotivossuspendidos"
      );

      let data = respuesta.data;

      guardarMotivosSuspendidos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Motivos de Suspensión", error.response.data, "error");
    }
  };

  const actualizarMotivoSuspendido = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarmotivosuspendido",
        datos
      );

      mensajeAlerta("Motivos de Suspensión", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Motivos de Suspensión", error.response.data, "error");
    }
  };

  const agregarMotivoSuspendido = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarmotivosuspendido",
        datos
      );

      mensajeAlerta("Motivos de Suspensión", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Motivos de Suspensión", error.response.data, "error");
    }
  };

  //Asesores

  const obtenerAsesores = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerasesores"
      );

      let data = respuesta.data;

      guardarAsesores(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Asesores", error.response.data, "error");
    }
  };

  const actualizarAsesor = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarasesor",
        datos
      );

      mensajeAlerta("Asesores", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Asesores", error.response.data, "error");
    }
  };

  const agregarAsesor = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarasesor",
        datos
      );

      mensajeAlerta("Asesores", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Asesores", error.response.data, "error");
    }
  };

  //Cobradores

  const obtenerCobradores = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercobradores"
      );

      let data = respuesta.data;

      guardarCobradores(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cobradores", error.response.data, "error");
    }
  };

  const obtenerPin = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerpin",
        datos
      );
      let data = respuesta.data;

      guardarCobradores(data);
      var now = Date.now();
      window.localStorage.setItem("setupTime", now);
      window.localStorage.setItem("numeroCobrador", data[0].id);
      window.location.reload();
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cobradores", error.response.data, "error");
      window.localStorage.removeItem("setupTime");
      window.localStorage.removeItem("numeroCobrador");
    }
  };

  const actualizarCobrador = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarcobrador",
        datos
      );

      mensajeAlerta("Cobradores", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cobradores", error.response.data, "error");
    }
  };

  const agregarCobrador = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarcobrador",
        datos
      );

      mensajeAlerta("Cobradores", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cobradores", error.response.data, "error");
    }
  };

  const eliminarComisionCobrador = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/eliminarcomisioncobrador",
        datos
      );

      //mensajeAlerta("Cobradores", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cobradores", error.response.data, "error");
    }
  };

  const obtenerComisionCobradorEmpresa = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercomisioncobradorempresa",
        datos
      );

      let data = respuesta.data.response;
      let dataEmpresa = respuesta.data.responseEmpresa;
      let dataCobrador = respuesta.data.responseCobrador;

      guardarComisionCobradorEmpresa(data);
      guardarEmpresaComision(dataEmpresa);
      guardarCobradorComision(dataCobrador);
    } catch (error) {
      console.log(error.response);
      guardarComisionCobradorEmpresa({
        ...comisionCobradorEmpresa,
        empresaId: null,
        cobradorId: null,
        habilitar1: false,
        entre1: 0,
        entre2: 0,
        importe1: 0,
        porcentaje1: 0,
        habilitar2: false,
        entre3: 0,
        entre4: 0,
        importe2: 0,
        porcentaje2: 0,
        descontarIVA: false,
      });
      guardarEmpresaComision(null);
      guardarCobradorComision([]);
      //mensajeAlerta("Comisiones Cobradores", error.response.data, "error");
    }
  };

  //Grupos

  const obtenerGrupos = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenergrupos"
      );

      let data = respuesta.data;

      guardarGrupos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Rubros", error.response.data, "error");
    }
  };

  //Planes

  const obtenerPlanes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerplanes",
        datos
      );

      let data = respuesta.data;

      guardarPlanes(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Planes", error.response.data, "error");
    }
  };

  const actualizarPlan = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarplan",
        datos
      );

      mensajeAlerta("Planes", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Planes", error.response.data, "error");
    }
  };

  const agregarPlan = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarplan",
        datos
      );

      mensajeAlerta("Planes", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Planes", error.response.data, "error");
    }
  };

  //Empresas

  const obtenerEmpresas = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerempresas"
      );

      let data = respuesta.data;
      guardarEmpresas(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Empresas", error.response.data, "error");
    }
  };

  const actualizarEmpresa = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarempresa",
        datos
      );

      console.log("si");

      mensajeAlerta("Empresas", respuesta.data, "success");
    } catch (error) {
      console.log("no");
      console.log(error.response);
      mensajeAlerta("Empresas", error.response.data, "error");
    }
  };

  const agregarEmpresa = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarempresa",
        datos
      );

      mensajeAlerta("Empresas", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Empresas", error.response.data, "error");
    }
  };

  //Localidades

  const obtenerLocalidades = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerlocalidades"
      );

      let data = respuesta.data;

      guardarLocalidades(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Localidades", error.response.data, "error");
    }
  };

  const actualizarLocalidad = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarlocalidad",
        datos
      );

      mensajeAlerta("Localidades", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Localidades", error.response.data, "error");
    }
  };

  const agregarLocalidad = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarlocalidad",
        datos
      );

      mensajeAlerta("Localidades", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Localidades", error.response.data, "error");
    }
  };

  //Provincias

  const obtenerProvincias = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerprovincias"
      );

      let data = respuesta.data;

      guardarProvincias(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Provincias", error.response.data, "error");
    }
  };

  const actualizarProvincia = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarprovincia",
        datos
      );

      mensajeAlerta("Provincias", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Provincias", error.response.data, "error");
    }
  };

  const agregarProvincia = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarprovincia",
        datos
      );

      mensajeAlerta("Provincias", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Provincias", error.response.data, "error");
    }
  };

  // Cta Cte

  const obtenerCtaCte = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerctacte",
        datos
      );

      let data = respuesta.data.response;
      let dataPagos = respuesta.data.result;

      let local = [];
      let saldo = 0;
      for (var i = 0; i < data.length; i++) {
        if (
          data[i].id_comprobanteafip === 1 ||
          data[i].id_comprobanteafip === 2 ||
          data[i].id_comprobanteafip === 6 ||
          data[i].id_comprobanteafip === 7 ||
          data[i].id_comprobanteafip === 11 ||
          data[i].id_comprobanteafip === 12 ||
          data[i].id_comprobanteafip === 51 ||
          data[i].id_comprobanteafip === 52 ||
          data[i].id_comprobanteafip === 201 ||
          data[i].id_comprobanteafip === 202 ||
          data[i].id_comprobanteafip === 206 ||
          data[i].id_comprobanteafip === 207 ||
          data[i].id_comprobanteafip === 211 ||
          data[i].id_comprobanteafip === 212 ||
          data[i].id_comprobanteafip === 1000
        ) {
          //saldo = Number(saldo) + Number(data[i].total);
          local.push({
            id: "c" + data[i].id,
            fecha: data[i].fecha,
            id_titular: data[i].id_titular,
            comprobante:
              data[i].comprobante + "-" +  data[i].puntoventa + "-" + data[i].numero,
            debe: parseFloat(data[i].total).toFixed(2),
            haber: parseFloat(0).toFixed(2),
            saldo: parseFloat(saldo).toFixed(2),
            empresa: data[i].empresas.nombre,
            empresaId: data[i].id_empresa,
            aplicado : null
          });
        }
        if (
          data[i].id_comprobanteafip === 3 ||
          data[i].id_comprobanteafip === 8 ||
          data[i].id_comprobanteafip === 13 ||
          data[i].id_comprobanteafip === 53 ||
          data[i].id_comprobanteafip === 203 ||
          data[i].id_comprobanteafip === 208 ||
          data[i].id_comprobanteafip === 213 ||
          data[i].id_comprobanteafip === 1001 ||
          data[i].id_comprobanteafip === 2000 ||
          data[i].id_comprobanteafip === 3000
        ) {
          //saldo = Number(saldo) - Number(data[i].total);
          local.push({
            id: "c" + data[i].id,
            fecha: data[i].fecha,
            id_titular: data[i].id_titular,
            comprobante:
              data[i].comprobante + "-"  +  data[i].puntoventa + "-" + data[i].numero,
            debe: parseFloat(0).toFixed(2),
            haber: parseFloat(data[i].total).toFixed(2),
            saldo: parseFloat(saldo).toFixed(2),
            empresa: data[i].empresas.nombre,
            empresaId: data[i].id_empresa,
            aplicado : null
          });
        }
      }

      for (var i = 0; i < dataPagos.length; i++) {
        //saldo = Number(saldo) - Number(dataPagos[i].importeTotal);
        local.push({
          id: "r" + dataPagos[i].rendicionId,
          fecha: dataPagos[i].fecha,
          id_titular: dataPagos[i].titularId,
          comprobante: "R" + "-" + dataPagos[i].rendicionId,
          debe: parseFloat(0).toFixed(2),
          haber: parseFloat(dataPagos[i].importeTotal).toFixed(2),
          saldo: parseFloat(saldo).toFixed(2),
          empresaId: dataPagos[i].empresaId,
          aplicado : dataPagos[i].comprobante + "-"  +  dataPagos[i].puntoventa + "-" + dataPagos[i].numero,
        });
      }

      local.sort(function (a, b) {
        const indiceA = a.fecha;
        const indiceB = b.fecha;

        // sort in an ascending order
        if (indiceA < indiceB) {
          return -1;
        }
        if (indiceA > indiceB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      for (var i = 0; i < local.length; i++) {
        saldo = Number(saldo) + Number(local[i].debe) - Number(local[i].haber);
        local[i].saldo = parseFloat(saldo).toFixed(2);
      }

      guardarCtasCtes(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cuenta Corriente", error.response.data, "error");
    }
  };

  const obtenerCtaCteProveedor = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerctacteproveedor",
        datos
      );

      let data = respuesta.data.response;
      let dataPagos = respuesta.data.result;

      let local = [];
      let saldo = 0;
      for (var i = 0; i < data.length; i++) {
        if (
          data[i].id_comprobanteafip === 1 ||
          data[i].id_comprobanteafip === 2 ||
          data[i].id_comprobanteafip === 6 ||
          data[i].id_comprobanteafip === 7 ||
          data[i].id_comprobanteafip === 11 ||
          data[i].id_comprobanteafip === 12 ||
          data[i].id_comprobanteafip === 51 ||
          data[i].id_comprobanteafip === 52 ||
          data[i].id_comprobanteafip === 201 ||
          data[i].id_comprobanteafip === 202 ||
          data[i].id_comprobanteafip === 206 ||
          data[i].id_comprobanteafip === 207 ||
          data[i].id_comprobanteafip === 211 ||
          data[i].id_comprobanteafip === 212 ||
          data[i].id_comprobanteafip === 1000
        ) {
          //saldo = Number(saldo) + Number(data[i].total);
          local.push({
            id: "c" + data[i].id,
            fecha: data[i].fechacontabilizacion,
            id_proveedor: data[i].id_proveedor,
            comprobante:
              data[i].comprobante + "-" + data[i].tipo + "-" + data[i].numero,
            haber: parseFloat(data[i].total).toFixed(2),
            debe: parseFloat(0).toFixed(2),
            saldo: parseFloat(saldo).toFixed(2),
            saldoComprobante: data[i].saldo,
            ordenId: data[i].id_orden,
            monedaId: data[i].monedas.id,
            moneda: data[i].monedas.nombre,
            cotizacion: data[i].cotizacion,
          });
        }
        if (
          data[i].id_comprobanteafip === 3 ||
          data[i].id_comprobanteafip === 8 ||
          data[i].id_comprobanteafip === 13 ||
          data[i].id_comprobanteafip === 53 ||
          data[i].id_comprobanteafip === 203 ||
          data[i].id_comprobanteafip === 208 ||
          data[i].id_comprobanteafip === 213 ||
          data[i].id_comprobanteafip === 1001 ||
          data[i].id_comprobanteafip === 2000 ||
          data[i].id_comprobanteafip === 3000
        ) {
          //saldo = Number(saldo) - Number(data[i].total);
          local.push({
            id: "c" + data[i].id,
            fecha: data[i].fechacontabilizacion,
            id_proveedor: data[i].id_proveedor,
            comprobante:
              data[i].comprobante + "-" + data[i].tipo + "-" + data[i].numero,
            haber: parseFloat(0).toFixed(2),
            debe: parseFloat(data[i].total).toFixed(2),
            saldo: parseFloat(saldo).toFixed(2),
            saldoComprobante: data[i].saldo,
            ordenId: data[i].id_orden,
          });
        }
      }

      for (var i = 0; i < dataPagos.length; i++) {
        //saldo = Number(saldo) - Number(dataPagos[i].importeTotal);
        local.push({
          id: "o" + dataPagos[i].ordenId,
          fecha: dataPagos[i].fecha,
          id_proveedor: dataPagos[i].proveedorId,
          comprobante: "O" + "-" + dataPagos[i].ordenId,
          haber: parseFloat(0).toFixed(2),
          debe: parseFloat(dataPagos[i].importeTotal).toFixed(2),
          saldo: parseFloat(saldo).toFixed(2),
        });
      }

      local.sort(function (a, b) {
        const indiceA = a.fecha;
        const indiceB = b.fecha;

        // sort in an ascending order
        if (indiceA < indiceB) {
          return -1;
        }
        if (indiceA > indiceB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      for (var i = 0; i < local.length; i++) {
        saldo = Number(saldo) + Number(local[i].haber) - Number(local[i].debe);
        local[i].saldo = parseFloat(saldo).toFixed(2);
      }

      guardarCtasCtes(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cuenta Corriente", error.response.data, "error");
    }
  };

  // Saldos

  const obtenerSaldos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenersaldos",
        datos
      );

      let data = respuesta.data;

      let local = [];
      let saldo = 0;
      for (var i = 0; i < data.length; i++) {
        if (
          data[i].id_comprobanteafip === 1 ||
          data[i].id_comprobanteafip === 2 ||
          data[i].id_comprobanteafip === 6 ||
          data[i].id_comprobanteafip === 7 ||
          data[i].id_comprobanteafip === 11 ||
          data[i].id_comprobanteafip === 12 ||
          data[i].id_comprobanteafip === 51 ||
          data[i].id_comprobanteafip === 52 ||
          data[i].id_comprobanteafip === 201 ||
          data[i].id_comprobanteafip === 202 ||
          data[i].id_comprobanteafip === 206 ||
          data[i].id_comprobanteafip === 207 ||
          data[i].id_comprobanteafip === 211 ||
          data[i].id_comprobanteafip === 212 ||
          data[i].id_comprobanteafip === 1000
        ) {
          local.push({
            id: data[i].id,
            fecha: data[i].fecha,
            id_titular: data[i].id_titular,
            comprobante:
              data[i].comprobante + "-" + data[i].tipo + "-" + data[i].numero,
            total: parseFloat(data[i].total).toFixed(2),
            saldo: parseFloat(data[i].saldo).toFixed(2),
            saldoFinal: 0,
            empresa: data[i].empresas.nombre,
          });
        }
        if (
          data[i].id_comprobanteafip === 3 ||
          data[i].id_comprobanteafip === 8 ||
          data[i].id_comprobanteafip === 13 ||
          data[i].id_comprobanteafip === 53 ||
          data[i].id_comprobanteafip === 203 ||
          data[i].id_comprobanteafip === 208 ||
          data[i].id_comprobanteafip === 213 ||
          data[i].id_comprobanteafip === 1001 ||
          data[i].id_comprobanteafip === 2000 ||
          data[i].id_comprobanteafip === 3000
        ) {
          local.push({
            id: data[i].id,
            fecha: data[i].fecha,
            id_titular: data[i].id_titular,
            comprobante:
              data[i].comprobante + "-" + data[i].tipo + "-" + data[i].numero,
            total: parseFloat(-data[i].total).toFixed(2),
            saldo: parseFloat(-data[i].saldo).toFixed(2),
            saldoFinal: 0,
            empresa: data[i].empresas.nombre,
          });
        }
      }

      local.sort(function (a, b) {
        const indiceA = a.fecha;
        const indiceB = b.fecha;

        // sort in an ascending order
        if (indiceA < indiceB) {
          return -1;
        }
        if (indiceA > indiceB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      for (var i = 0; i < local.length; i++) {
        saldo = Number(saldo) + Number(local[i].saldo);
        local[i].saldoFinal = parseFloat(saldo).toFixed(2);
      }

      guardarSaldos(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cuenta Corriente", error.response.data, "error");
    }
  };

  const obtenerSaldosProveedor = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenersaldosproveedor",
        datos
      );

      let data = respuesta.data;

      let local = [];
      let saldo = 0;
      for (var i = 0; i < data.length; i++) {
        if (
          data[i].id_comprobanteafip === 1 ||
          data[i].id_comprobanteafip === 2 ||
          data[i].id_comprobanteafip === 6 ||
          data[i].id_comprobanteafip === 7 ||
          data[i].id_comprobanteafip === 11 ||
          data[i].id_comprobanteafip === 12 ||
          data[i].id_comprobanteafip === 51 ||
          data[i].id_comprobanteafip === 52 ||
          data[i].id_comprobanteafip === 201 ||
          data[i].id_comprobanteafip === 202 ||
          data[i].id_comprobanteafip === 206 ||
          data[i].id_comprobanteafip === 207 ||
          data[i].id_comprobanteafip === 211 ||
          data[i].id_comprobanteafip === 212 ||
          data[i].id_comprobanteafip === 1000
        ) {
          local.push({
            id: data[i].id,
            fecha: data[i].fechacontabilizacion,
            id_titular: data[i].id_titular,
            comprobante:
              data[i].comprobante + "-" + data[i].tipo + "-" + data[i].numero,
            total: parseFloat(data[i].total).toFixed(2),
            saldo: parseFloat(data[i].saldo).toFixed(2),
            saldoFinal: 0,
          });
        }
        if (
          data[i].id_comprobanteafip === 3 ||
          data[i].id_comprobanteafip === 8 ||
          data[i].id_comprobanteafip === 13 ||
          data[i].id_comprobanteafip === 53 ||
          data[i].id_comprobanteafip === 203 ||
          data[i].id_comprobanteafip === 208 ||
          data[i].id_comprobanteafip === 213 ||
          data[i].id_comprobanteafip === 1001 ||
          data[i].id_comprobanteafip === 2000 ||
          data[i].id_comprobanteafip === 3000
        ) {
          local.push({
            id: data[i].id,
            fecha: data[i].fechacontabilizacion,
            id_titular: data[i].id_titular,
            comprobante:
              data[i].comprobante + "-" + data[i].tipo + "-" + data[i].numero,
            total: parseFloat(-data[i].total).toFixed(2),
            saldo: parseFloat(-data[i].saldo).toFixed(2),
            saldoFinal: 0,
          });
        }
      }

      local.sort(function (a, b) {
        const indiceA = a.fecha;
        const indiceB = b.fecha;

        // sort in an ascending order
        if (indiceA < indiceB) {
          return -1;
        }
        if (indiceA > indiceB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      for (var i = 0; i < local.length; i++) {
        saldo = Number(saldo) + Number(local[i].saldo);
        local[i].saldoFinal = parseFloat(saldo).toFixed(2);
      }

      guardarSaldos(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cuenta Corriente", error.response.data, "error");
    }
  };

  // Formas de Pago Proveedores

  const obtenerFormaPagoProveedor = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerformapagoproveedor",
        datos
      );

      let dataCaja = respuesta.data.responseCaja;
      let dataCheque = respuesta.data.responseCheque;
      let dataCompensacion = respuesta.data.responseCompensacion;
      let dataBanco = respuesta.data.responseBanco;

      let local = [];

      let j = 0;

      for (var i = 0; i < dataCaja.length; i++) {
        j = j + 1;
        local.push({
          id: j,
          fecha: dataCaja[i].movimientoscajas.fecha,
          valor: "Caja",
          importe: dataCaja[i].movimientoscajas.importe,
          id_moneda: dataCaja[i].movimientoscajas.id_moneda,
          moneda: dataCaja[i].movimientoscajas.monedas.nombre,
          cotizacion: dataCaja[i].movimientoscajas.cotizacion,
          detalleBanco: null,
          numero: null,
          detalleCuenta: null,
          rendiciones: dataCaja[i].movimientoscajas.rendiciones,
        });
      }

      for (var i = 0; i < dataCheque.length; i++) {
        j = j + 1;
        local.push({
          id: j,
          fecha: dataCheque[i].movimientoscheques.fecha,
          valor: "Cheque",
          importe: dataCheque[i].movimientoscheques.importe,
          id_moneda: dataCheque[i].movimientoscheques.estadoscheques.id_moneda,
          moneda:
            dataCheque[i].movimientoscheques.estadoscheques.monedas.nombre,
          cotizacion: dataCheque[i].movimientoscheques.cotizacion,
          detalleBanco:
            dataCheque[i].movimientoscheques.estadoscheques.detalleBanco,
          numero: dataCheque[i].movimientoscheques.estadoscheques.numero,
          detalleCuenta: null,
          rendiciones: dataCheque[i].movimientoscheques.rendiciones,
        });
      }

      let mon = monedas.findIndex((item) => item.id === monedaPredeterminada);

      for (var i = 0; i < dataCompensacion.length; i++) {
        j = j + 1;
        local.push({
          id: j,
          fecha: dataCompensacion[i].movimientoscompensaciones.fecha,
          valor: "Compensacion",
          importe: dataCompensacion[i].movimientoscompensaciones.importe,
          id_moneda: monedaPredeterminada,
          moneda: monedas[mon].nombre,
          cotizacion: null,
          detalleBanco: null,
          numero: null,
          detalleCuenta: null,
          rendiciones:
            dataCompensacion[i].movimientoscompensaciones.rendiciones,
        });
      }

      for (var i = 0; i < dataBanco.length; i++) {
        j = j + 1;
        local.push({
          id: j,
          fecha: dataBanco[i].movimientosbancos.fecha,
          valor: "Banco",
          importe: dataBanco[i].movimientosbancos.importe,
          id_moneda: dataBanco[i].movimientosbancos.cuentas.id_moneda,
          moneda: dataBanco[i].movimientosbancos.cuentas.monedas.nombre,
          cotizacion: dataBanco[i].movimientosbancos.cotizacion,
          detalleBanco: null,
          numero: null,
          detalleCuenta: dataBanco[i].movimientosbancos.cuentas.nombre,
          rendiciones: dataBanco[i].movimientosbancos.rendiciones,
        });
      }

      guardarFormaPagoProveedor(local);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta("Afiliados", error.response.data, "error");
    }
  };

  // Clientes

  const obtenerClienteId = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerclienteid",
        datos
      );

      setClienteRepetido(true);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta("Afiliados", error.response.data, "error");
    }
  };

  const obtenerDocumentoCliente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerdocumentocliente",
        datos
      );

      setDocumentoRepetido(true);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta("Afiliados", error.response.data, "error");
    }
  };

  const obtenerClientes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerclientes",
        datos
      );

      let data = respuesta.data;

      guardarClientes(data);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta("Afiliados", error.response.data, "error");
    }
  };

  const inhabilitarAfiliado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/inhabilitarafiliado",
        datos
      );

      mensajeAlerta("Inhabilitar", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Inhabilitar", error.response.data, "error");
    }
  };

  const suspenderAfiliado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/suspenderafiliado",
        datos
      );

      mensajeAlerta("Suspensión", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Suspensión", error.response.data, "error");
    }
  };

  const cancelarSuspenderAfiliado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/cancelarsuspenderafiliado",
        datos
      );

      mensajeAlerta("Suspensión", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Suspensión", error.response.data, "error");
    }
  };

  const habilitarAfiliado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/habilitarafiliado",
        datos
      );

      mensajeAlerta("Habilitar", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Habilitar", error.response.data, "error");
    }
  };

  const buscarTitular = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/buscartitular",
        datos
      );

      let data = respuesta.data.response;
      let data2 = respuesta.data.response2;

      guardarModulo(data2);
      guardarTitular(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Afiliados", error.response.data.msg, "error");
    }
  };

  const buscarIntegrantes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/buscarintegrantes",
        datos
      );

      let data = respuesta.data.response;
      let data2 = respuesta.data.responseMax;
      let data3 = respuesta.data.responseHabilitados;
      let data4 = respuesta.data.responseInhabilitados;

      let proximo = Number(data2[0].max.substring(8, 14)) + 1;

      guardarIntegrantes(data);
      setProximoIntegrante(proximo);
      setIntegrantesHabilitados(Number(data3.count));
      setIntegrantesInhabilitados(Number(data4.count));
      setIntegrantesTotales(Number(data4.count) + Number(data3.count));
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Afiliados", error.response.data, "error");
    }
  };

  const actualizarCliente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarcliente",
        datos
      );

      mensajeAlerta("Afiliados", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Afiliados", error.response.data, "error");
    }
  };

  const agregarCliente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarcliente",
        datos
      );

      if (datos.afiliado.titular) {
        setActiveStep(0);
      } else {
        setActiveStep(1);
      }

      mensajeAlerta("Afiliados", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Afiliados", error.response.data, "error");
    }
  };

  //Frecuencias

  const obtenerFrecuencias = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerfrecuencias"
      );

      let data = respuesta.data;

      guardarFrecuencias(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Frecuencias", error.response.data, "error");
    }
  };

  //Historial

  const agregarHistorial = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarhistorial",
        datos
      );

      mensajeAlerta("Historial", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Historial", error.response.data, "error");
    }
  };

  const obtenerHistorial = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerhistorial",
        datos
      );

      let data = respuesta.data;

      //console.log(data)

      guardarHistoriales(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Historial", error.response.data, "error");
    }
  };

  //Tipos Documentos

  const obtenerTiposDocumentos = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertiposdocumentos"
      );

      let data = respuesta.data;

      guardarTiposDocumentos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tipos de Documentos", error.response.data, "error");
    }
  };

  //Tipos Contribuyentes

  const obtenerTiposContribuyentes = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertiposcontribuyentes"
      );

      let data = respuesta.data;

      guardarTiposContribuyentes(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tipos de Contribuyentes", error.response.data, "error");
    }
  };

  // Comprobantes

  const cambiarCobradorComprobante = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/cambiarcobradorcomprobante",
        datos
      );

      mensajeAlerta("Comprobante", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Comprobante", error.response.data, "error");
    }
  };

  const agregarComprobanteCompras = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarcomprobantecompras",
        datos
      );

      let data = respuesta.data.idComprobante;
      let msg = respuesta.data.msg;

      mensajeAlerta("Comprobantes", msg, "success");
      guardarIdComprobante(data);
    } catch (error) {
      console.log(error.response);
      guardarIdComprobante(null);
      mensajeAlerta("Comprobantes", error.response.data, "error");
    }
  };

  const obtenerTiposComprobantes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertiposcomprobantes",
        datos
      );

      let data = respuesta.data.empresa;
      let data2 = respuesta.data.comprobantes;

      guardarEmpresa(data);
      guardarTiposComprobantes(data2);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tipos de Comprobantes", error.response.data, "error");
    }
  };

  const buscarProveedor = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/buscarproveedor",
        datos
      );

      let data = respuesta.data;

      guardarProveedor(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Proveedores", error.response.data.msg, "error");
    }
  };

  const obtenerTiposComprobantesProveedores = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenertiposcomprobantesproveedores",
        datos
      );

      let data = respuesta.data;

      guardarTiposComprobantes(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tipos de Comprobantes", error.response.data, "error");
    }
  };

  const obtenerNumeroComprobante = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenernumerocomprobante",
        datos
      );

      let data = respuesta.data;

      guardarNumeroComprobante(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Tipos de Comprobantes", error.response.data, "error");
    }
  };

  const obtenerUltimoNumeroSistema = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerultimonumerosistema",
        datos
      );

      let data = respuesta.data;

      toast.current.show({
        severity: "info",
        summary: "Sistema",
        detail:
          "El último comprobante registrado en sistema es: " + data.numero,
        life: 3000,
      });

      //guardarUltimoNumeroAfip(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Comprobantes Afip", error.response.data, "error");
    }
  };

  const obtenerUltimoNumeroAfip = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerultimonumeroafip",
        datos
      );

      let data = respuesta.data;

      toast.current.show({
        severity: "info",
        summary: "Afip",
        detail:
          "El último comprobante registrado en A.F.I.P. es: " + data.numero,
        life: 3000,
      });

      //guardarUltimoNumeroAfip(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Comprobantes Afip", error.response.data, "error");
    }
  };

  const verificarComprobantesAfip = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/verificarcomprobantesafip",
        datos
      );

      let data = respuesta.data;

      //console.log(data);
      //guardarVerificacionAfip(data);
      //mensajeAlerta("Comprobantes Afip", respuesta.data, "error");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Comprobantes Afip", error.response.data, "error");
    }
  };

  // Ordenes de Pago

  const pagarOrdenes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/pagarordenes",
        datos
      );

      mensajeAlerta("Ordenes de Pago", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Ordenes de Pago", error.response.data, "error");
    }
  };

  const actualizarOrdenPago = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarordenpago",
        datos
      );

      mensajeAlerta("Ordenes de Pago", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Ordenes de Pago", error.response.data, "error");
    }
  };

  const obtenerComprobantesOrden = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercomprobantesorden",
        datos
      );

      let data = respuesta.data;
      guardarComprobantes(data);

      //mensajeAlerta("Comprobantes", "El comprobante asociado es correcto.", "success");
    } catch (error) {
      guardarComprobantes(null);
      console.log(error.response);
      mensajeAlerta("Ordenes de Pago", error.response.data, "error");
    }
  };

  const obtenerOrdenPago = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerordenpago",
        datos
      );

      let data = respuesta.data;
      guardarOrdenPago(data);

      //mensajeAlerta("Comprobantes", "El comprobante asociado es correcto.", "success");
    } catch (error) {
      guardarOrdenPago(null);
      console.log(error.response);
      mensajeAlerta("Ordenes de Pago", error.response.data, "error");
    }
  };

  const confirmarAnularOrden = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/confirmaranularorden",
        datos
      );

      mensajeAlerta("Ordenes de Pago", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Ordenes de Pago", error.response.data, "error");
    }
  };

  const confirmarAutorizarOrden = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/confirmarautorizarorden",
        datos
      );

      mensajeAlerta("Ordenes de Pago", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Ordenes de Pago", error.response.data, "error");
    }
  };

  const confirmarPendienteOrden = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/confirmarpendienteorden",
        datos
      );

      mensajeAlerta("Ordenes de Pago", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Ordenes de Pago", error.response.data, "error");
    }
  };

  const obtenerOrdenesPagos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerordenespagos",
        datos
      );

      let data = respuesta.data;
      guardarOrdenes(data);

      //mensajeAlerta("Comprobantes", "El comprobante asociado es correcto.", "success");
    } catch (error) {
      guardarOrdenes(null);
      console.log(error.response);
      mensajeAlerta("Ordenes de Pago", error.response.data, "error");
    }
  };

  const obtenerOrdenesPagosSeleccionadas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerordenespagosseleccionadas",
        datos
      );

      let data = respuesta.data;
      guardarOrdenes(data);

      //mensajeAlerta("Comprobantes", "El comprobante asociado es correcto.", "success");
    } catch (error) {
      guardarOrdenes(null);
      console.log(error.response);
      mensajeAlerta("Ordenes de Pago", error.response.data, "error");
    }
  };

  const agregarOrdenPago = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarordenpago",
        datos
      );

      let msg = respuesta.data.msg;

      mensajeAlerta("Ordenes de Pago", msg, "success");
    } catch (error) {
      console.log(error.response);
      guardarIdComprobante(null);
      mensajeAlerta("Ordenes de Pago", error.response.data, "error");
    }
  };

  //Formas de Pago

  const obtenerFormasPagos = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerformaspagos"
      );

      let data = respuesta.data;

      guardarFormasPagos(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Formas de Pago", error.response.data, "error");
    }
  };

  //Procesos

  const uploadFiles = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/uploadfiles",
        datos
      );
      mensajeAlerta("Uploads", respuesta.data.msg, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Uploads", error.response.data, "error");
    }
  };

  const obtenerArchivosPercepciones = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerarchivospercepciones"
      );

      let data = respuesta.data;

      guardarPercepciones(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Importaciones", error.response.data, "error");
    }
  };

  const actualizarPercepcionAgip = async (datos) => {
    try {
      toast.current.show({
        severity: "info",
        summary: "Importaciones",
        detail:
          "Iniciando proceso de importación. Este proceso puede demorar unos minutos.",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarpercepcionagip",
        datos
      );
      toast.current.clear();

      mensajeAlerta("Importación", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Importación", error.response.data, "error");
    }
  };

  const actualizarPercepcionArba = async (datos) => {
    try {
      toast.current.show({
        severity: "info",
        summary: "Importaciones",
        detail:
          "Iniciando proceso de importación. Este proceso puede demorar unos minutos.",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarpercepcionarba",
        datos
      );
      toast.current.clear();

      mensajeAlerta("Importación", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Importación", error.response.data, "error");
    }
  };

  const procesoFacturacion = async (datos) => {
    try {
      toast.current.show({
        severity: "info",
        summary: "Proceso de Facturación",
        detail:
          "Iniciando proceso de facturación. Este proceso puede demorar varios minutos.",
        life: 5000,
      });

      const respuesta = await clienteAxios.post(
        "/api/configuracion/procesofacturacion",
        datos
      );

      mensajeAlerta("Procesos", respuesta.data, "success");
    } catch (error) {
      toast.current.clear();
      console.log(error.response);
      toast.current.show({
        severity: "error",
        summary: "Error en Proceso de Facturación",
        detail: error.response.data,
        sticky: true,
      });

      //mensajeAlerta("Procesos", error.response.data, "error");
    }
  };

  const procesoImpresion = async (datos) => {
    try {
      if (datos && datos.proceso && datos.proceso.hideMessage) {
      } else {
        toast.current.show({
          severity: "info",
          summary: "Proceso de Impresión",
          detail:
            "Iniciando proceso de impresión. Este proceso puede demorar varios minutos.",
          sticky: 5000,
        });
      }
      const respuesta = await clienteAxios.post(
        "/api/configuracion/procesoimpresion",
        datos
      );

      toast.current.clear();

      let data = respuesta.data;
      if (data.length === 0) {
        mensajeAlerta(
          "Proceso de Impresión",
          "No hay comprobantes a imprimir",
          "error"
        );
      }

      guardarImpresionComprobantes(data);
    } catch (error) {
      toast.current.clear();
      console.log(error.response);
      mensajeAlerta("Procesos", error.response.data, "error");
    }
  };

  const procesoComprasYVentas = async (datos) => {
    try {
      toast.current.show({
        severity: "info",
        summary: "Proceso de Compras y Ventas",
        detail: "Iniciando proceso. Este proceso puede demorar unos minutos.",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/configuracion/procesocomprasyventas",
        datos
      );

      toast.current.clear();

      let data1 = respuesta.data.responseVentas;
      let data2 = respuesta.data.responseCompras;
      if (data1.length === 0 && data2.length === 0) {
        mensajeAlerta(
          "Proceso de Compras y Ventas",
          "No hay comprobantes a exportar ",
          "error"
        );
      }

      guardarProcesosVentas(data1);
      guardarProcesosCompras(data2);
    } catch (error) {
      toast.current.clear();
      console.log(error.response);
      mensajeAlerta("Procesos", error.response.data, "error");
    }
  };

  const procesoPercepciones = async (datos) => {
    try {
      toast.current.show({
        severity: "info",
        summary: "Proceso de Percepciones A.R.B.A.",
        detail: "Iniciando proceso. Este proceso puede demorar unos minutos.",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/configuracion/procesopercepciones",
        datos
      );

      toast.current.clear();

      let data = respuesta.data;
      if (data.length === 0) {
        mensajeAlerta(
          "Proceso de Percepciones A.R.B.A.",
          "No hay comprobantes a exportar ",
          "error"
        );
      }

      guardarProcesos(data);
    } catch (error) {
      toast.current.clear();
      console.log(error.response);
      mensajeAlerta("Procesos", error.response.data, "error");
    }
  };

  const generarPrevio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/procesodebitosautomaticosgenerarprevios",
        datos
      );

      let data = respuesta.data;
      let local = [];
      let total = 0;

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          id_titular: data[i].id_titular,
          nombre_razonsocial: data[i].nombre_razonsocial,
          numerotarjeta: data[i].numerotarjeta,
          cbu: data[i].cbu,
          cuota: parseFloat(data[i].cuota).toFixed(2),
        });
        total = total + Number(data[i].cuota);
      }

      guardarProcesos(local);
      guardarTotalTarjetas(total);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Procesos", error.response.data, "error");
    }
  };

  const procesoDebitosAutomaticosPrevios = async (datos) => {
    try {
      toast.current.show({
        severity: "info",
        summary: "Débitos Automaticos",
        detail: "Obteniendo Información de Tarjetas.",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/configuracion/procesodebitosautomaticosprevios",
        datos
      );

      toast.current.clear();

      let data = respuesta.data;
      let local = [];
      let total = 0;

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          id_titular: data[i].id_titular,
          nombre_razonsocial: data[i].nombre_razonsocial,
          numerotarjeta: data[i].numerotarjeta,
          cbu: data[i].cbu,
          cuota: parseFloat(data[i].cuota).toFixed(2),
        });
        total = total + Number(data[i].cuota);
      }

      guardarProcesos(local);
      guardarTotalTarjetas(total);
    } catch (error) {
      toast.current.clear();
      console.log(error.response);
      mensajeAlerta("Procesos", error.response.data, "error");
    }
  };

  const procesoDebitosAutomaticos = async (datos) => {
    try {
      toast.current.show({
        severity: "info",
        summary: "Débitos Automaticos",
        detail: "Obteniendo Información de Tarjetas.",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/configuracion/procesodebitosautomaticos",
        datos
      );

      toast.current.clear();

      let data = respuesta.data;
      if (data.length === 0) {
        mensajeAlerta(
          "Proceso de Débitos Automaticos",
          "No hay tarjetas a procesarrrrr ",
          "error"
        );
        return;
      }

      let local = [];
      let total = 0;

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          id_titular: data[i].id_titular,
          nombre_razonsocial: data[i].nombre_razonsocial,
          numerotarjeta: data[i].numerotarjeta,
          cbu: data[i].cbu,
          cuota: parseFloat(data[i].cuota).toFixed(2),
        });
        total = total + Number(data[i].cuota);
      }

      guardarProcesos(local);
      guardarTotalTarjetas(total);
    } catch (error) {
      toast.current.clear();
      console.log(error.response);
      mensajeAlerta("Procesos", error.response.data, "error");
    }
  };

  const rendicionesDebitosAutomaticos = async (datos) => {
    try {
      toast.current.show({
        severity: "info",
        summary: "Débitos Automaticos",
        detail: "Obteniendo Información de Tarjetas.",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/configuracion/rendicionesdebitosautomaticos",
        datos
      );

      toast.current.clear();

      let dataAceptados = respuesta.data.aceptados;
      let dataRechazados = respuesta.data.rechazados;
      let msg = respuesta.data.msg;

      mensajeAlerta("Débitos Automáticos", msg, "success");
      guardarAceptados(dataAceptados);
      guardarRechazados(dataRechazados);
    } catch (error) {
      toast.current.clear();
      console.log(error.response);
      mensajeAlerta("Débitos Automáticos", error.response.data, "error");
    }
  };

  //Comprobantes

  const agregarComprobante = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarcomprobante",
        datos
      );

      let data = respuesta.data.idComprobante;
      let msg = respuesta.data.msg;

      mensajeAlerta("Comprobantes", msg, "success");
      guardarIdComprobante(data);
    } catch (error) {
      console.log(error.response);
      guardarIdComprobante(null);
      mensajeAlerta("Comprobantes", error.response.data, "error");
    }
  };

  const obtenerComprobanteAsociado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercomprobanteasociado",
        datos
      );

      let data = respuesta.data;
      guardarComprobanteAsociado(data);

      mensajeAlerta(
        "Comprobantes",
        "El comprobante asociado es correcto.",
        "success"
      );
    } catch (error) {
      guardarComprobanteAsociado(null);
      console.log(error.response);
      mensajeAlerta("Comprobantes", error.response.data, "error");
    }
  };

  const obtenerComprobanteId = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercomprobanteid",
        datos
      );

      let data = respuesta.data;
      guardarComprobante(data);
      setBandComprobante("ok");
    } catch (error) {
      guardarComprobante(null);
      setBandComprobante("error");
      console.log(error.response);
      //mensajeAlerta("Rendiciones", error.response.data, "error");
    }
  };

  const obtenerComprobantesAnteriores = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercomprobantesanteriores",
        datos
      );

      let data = respuesta.data;

      guardarComprobantesAnteriores(data);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta("Rendiciones", error.response.data, "error");
    }
  };

  const obtenerFactura = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerfactura",
        datos
      );

      let data1 = respuesta.data.responseComprobante;
      let data2 = respuesta.data.responseItems;
      let data3 = respuesta.data.responseComprobanteAsociado;

      guardarFactura(() => ({
        ...data1,
        puntoVentanc: data3 && data3.puntoventa ? data3.puntoventa : null,
        tiponc: data3 && data3.tipo ? data3.tipo : null,
        numeronc: data3 && data3.numero ? data3.numero : null,
      }));

      let local = [];
      for (var i = 0; i < data2.length; i++) {
        local.push({
          id: data2[i].id,
          cantidad: data2[i].cantidad,
          descripcion: data2[i].descripcion,
          unitario: data2[i].precio,
          ivaTasa: data2[i].tasa,
          total: data2[i].total,
        });
      }

      if (local && local.length === 0 && data1.id_comprobanteafip === 2000) {
        local.push({
          id: 1,
          cantidad: 1,
          descripcion: data1.retencionTipo,
          unitario: data1.subtotal,
          ivaTasa: 0,
          total: data1.total,
        });
      }

      if (local && local.length === 0 && data1.id_comprobanteafip === 3000) {
        local.push({
          id: 1,
          cantidad: 1,
          descripcion: "A CUENTA",
          unitario: data1.subtotal,
          ivaTasa: 0,
          total: data1.total,
        });
      }

      guardarItems(local);

      //mensajeAlerta("Comprobantes", "El comprobante asociado es correcto.", "success");
    } catch (error) {
      guardarFactura(null);
      console.log(error.response);
      mensajeAlerta("Comprobantes", error.response.data, "error");
    }
  };

  const anularComprobanteCompras = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/anularcomprobantecompras",
        datos
      );

      mensajeAlerta("Comprobantes", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Comprobantes", error.response.data, "error");
    }
  };

  const obtenerFacturaCompras = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerfacturacompras",
        datos
      );

      let data = respuesta.data;
      guardarFactura(data);

      //mensajeAlerta("Comprobantes", "El comprobante asociado es correcto.", "success");
    } catch (error) {
      guardarFactura(null);
      console.log(error.response);
      mensajeAlerta("Comprobantes", error.response.data, "error");
    }
  };

  const obtenerComprobantes = async (datos) => {
    try {
      toast.current.show({
        severity: "info",
        summary: "Comprobantes",
        detail: "Espere un momento...",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercomprobantes",
        datos
      );
      let data = respuesta.data;
      toast.current.clear();

      if (data.length === 0) {
        toast.current.show({
          severity: "info",
          summary: "Comprobantes",
          detail: "No hay comprobantes con esas condiciones.",
        });
      }

      guardarComprobantes(data);
    } catch (error) {
      guardarComprobantes([]);
      console.log(error.response);
      //mensajeAlerta("Comprobantes", error.response.data, "error");
    }
  };

  const obtenerComprobantesAdeudados = async (datos) => {
    try {
      toast.current.show({
        severity: "info",
        summary: "Comprobantes",
        detail: "Espere un momento...",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercomprobantesadeudados",
        datos
      );
      let data = respuesta.data;
      toast.current.clear();

      if (data.length === 0) {
        toast.current.show({
          severity: "info",
          summary: "Comprobantes",
          detail: "No hay comprobantes con deudas para el afiliado solicitado.",
        });
      }

      guardarComprobantes(data);
    } catch (error) {
      guardarComprobantes([]);
      console.log(error.response);
      //mensajeAlerta("Comprobantes", error.response.data, "error");
    }
  };

  const obtenerComprobantesAdeudadosProveedor = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercomprobantesadeudadosproveedor",
        datos
      );
      let data = respuesta.data;

      guardarComprobantes(data);
    } catch (error) {
      guardarComprobantes([]);
      console.log(error.response);
      //mensajeAlerta("Comprobantes", error.response.data, "error");
    }
  };

  const obtenerComprobantesCompras = async (datos) => {
    try {
      toast.current.show({
        severity: "info",
        summary: "Comprobantes",
        detail: "Espere un momento...",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercomprobantescompras",
        datos
      );
      let data = respuesta.data;

      toast.current.clear();

      if (data.length === 0) {
        toast.current.show({
          severity: "info",
          summary: "Comprobantes",
          detail: "No hay comprobantes con esas condiciones.",
        });
      }
      guardarComprobantes(data);

      // Listado

      let local = [];
      let total = 0;

      //console.log(data)

      if (!datos.grupo || datos.grupo === "") {
        for (var i = 0; i < data.length; i++) {
          total = total + Number(parseFloat(data[i].total).toFixed(2));

          local.push({
            fecha: formatDate(data[i].fechacomprobante),
            comprobante:
              data[i].comprobante +
              "-" +
              data[i].puntoventa +
              "-" +
              data[i].numero,
            periodo: data[i].periodo,
            proveedorId: data[i].id_proveedor,
            nombre_proveedor: data[i].nombre_proveedor,
            total: parseFloat(data[i].total).toFixed(2),
            empresa: data[i].empresas.nombre,
            concepto: data[i].conceptos.nombre,
          });
        }

        if (datos.exportType === "pdf") {
          local.push({
            fecha: "TOTAL",
            comprobante: null,
            periodo: null,
            proveedorId: null,
            nombre_razonsocial: null,
            total: parseFloat(total).toFixed(2),
            empresa: null,
            concepto: null,
          });
        }
        guardarListadoExcel(local);
      }

      let totalGrupo = 0;
      let totalFinal = 0;
      let final = [];
      let cantidad = 0;
      let cantidadTotal = 0;

      if (datos.grupo && datos.grupo !== "") {
        for (var i = 0; i < data.length; i++) {
          total = total + Number(parseFloat(data[i].total).toFixed(2));
          local.push({
            Fecha: formatDate(data[i].fechacomprobante),
            Comprobante: data[i].comprobante,
            Empresa: data[i].empresas.nombre,
            Concepto: data[i].conceptos.nombre,
            total: parseFloat(data[i].total).toFixed(2),
          });
        }

        const groupBy = (prop) => (data) => {
          return data.reduce((dict, item) => {
            const { [prop]: _, ...rest } = item;
            dict[item[prop]] = [...(dict[item[prop]] || []), rest];
            return dict;
          }, {});
        };

        //var result = groupBy(serviciosFinalizadosExcel, 'Antecedente');

        let campo = datos.grupo.nombre;

        const result = Object.entries(groupBy(campo)(local)).map(
          ([key, value]) => ({ nombre: key, datos: value })
        );

        for (var i = 0; i < result.length; i++) {
          totalGrupo = 0;
          cantidad = 0;
          for (var j = 0; j < result[i].datos.length; j++) {
            totalGrupo = totalGrupo + Number(result[i].datos[j].total);
            cantidad = cantidad + 1;
          }
          totalFinal = totalFinal + Number(totalGrupo);
          cantidadTotal = cantidadTotal + cantidad;

          final.push({
            descripcion: result[i].nombre,
            cantidad,
            total: parseFloat(totalGrupo).toFixed(2),
          });
        }

        final.push({
          descripcion: "TOTAL",
          cantidad: cantidadTotal,
          total: parseFloat(totalFinal).toFixed(2),
        });
        guardarListadoExcel(final);
      }
    } catch (error) {
      guardarComprobantes([]);
      console.log(error.response);
      //mensajeAlerta("Comprobantes", error.response.data, "error");
    }
  };

  const crearComprobantePdf = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/crearcomprobantepdf",
        datos
      );

      let data = respuesta.data;
      guardarUrlPdf(data.url);
      //mensajeAlerta("Comprobantes", "El comprobante se ha enviado correctamente.", "success");
    } catch (error) {
      guardarUrlPdf(null);
      console.log(error.response);
      mensajeAlerta("Comprobantes", error.response.data, "error");
    }
  };

  const obtenerDatosTitulares = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerdatostitulares",
        datos
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          id_titular: data[i].id_titular,
          nombre_razonsocial: data[i].nombre_razonsocial,
          domicilio:
            data[i].domicilio + " ( " + data[i].localidades.localidad + " ) ",
          telefono1: data[i].telefono1,
          numeroDocumento: data[i].numerodocumento,
          plan: data[i].planes.nombre,
          localidad: data[i].localidades.localidad,
          provincia: data[i].localidades.provincia,
          id_grupo: data[i].id_grupo,
          grupo: data[i].grupos.nombre,
          planGrupo:
            data[i].planes.nombre + " ( " + data[i].grupos.nombre + " ) ",
          habilitado: data[i].habilitado,
          id_plan: data[i].id_plan_facturacion,
        });
      }

      setRows(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  // Compensaciones

  const obtenerMovimientosCompensaciones = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermovimientoscompensaciones",
        datos
      );
      let data = respuesta.data;
      guardarMovimientosCompensaciones(data);
    } catch (error) {
      guardarMovimientosCompensaciones([]);
      console.log(error.response);
      mensajeAlerta(
        "Movimientos de Compensaciones",
        error.response.data,
        "error"
      );
    }
  };

  const obtenerMovimientosCompensacionesProveedor = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermovimientoscompensacionesproveedor",
        datos
      );
      let data = respuesta.data;
      guardarMovimientosCompensaciones(data);
    } catch (error) {
      guardarMovimientosCompensaciones([]);
      console.log(error.response);
      mensajeAlerta(
        "Movimientos de Compensaciones",
        error.response.data,
        "error"
      );
    }
  };

  // Cajas

  const obtenerMovimientosCajas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermovimientoscajas",
        datos
      );
      let data = respuesta.data.response;
      let saldoIngresosAnterior = respuesta.data.responseIngresosAnterior.sum;
      let saldoEgresosAnterior = respuesta.data.responseEgresosAnterior.sum;

      let local = [];
      let ingresos = 0;
      let egresos = 0;
      let saldoAnterior =
        Number(saldoIngresosAnterior) - Number(saldoEgresosAnterior);

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          tipo: data[i].tipo,
          fecha: data[i].fecha,
          id_concepto: data[i].id_concepto,
          concepto:
            data[i].conceptos !== undefined ? data[i].conceptos.nombre : null,
          id_moneda: data[i].id_moneda,
          moneda: data[i].monedas !== undefined ? data[i].monedas.nombre : null,
          detalle: data[i].detalle,
          ingreso: data[i].ingreso,
          egreso: data[i].egreso,
          importe: data[i].importe,
          id_empresa: data[i].id_empresa,
          usuario: data[i].usuario,
          empresa:
            data[i].empresas !== undefined ? data[i].empresas.nombre : null,
        });
        if (data[i].tipo === "i") {
          if (datos.moneda && datos.moneda.id_moneda !== null) {
            if (data[i].id_moneda === datos.moneda.id_moneda) {
              ingresos = Number(ingresos) + Number(data[i].ingreso);
            }
          } else {
            ingresos = Number(ingresos) + Number(data[i].ingreso);
          }
        } else {
          if (datos.moneda && datos.moneda.id_moneda !== null) {
            if (data[i].id_moneda === datos.moneda.id_moneda) {
              egresos = Number(egresos) + Number(data[i].egreso);
            }
          } else {
            egresos = Number(egresos) + Number(data[i].egreso);
          }
        }
      }

      //console.log(saldoAnterior)
      //console.log(egresos)
      //console.log(ingresos)
      guardarMovimientosCajas(local);
      guardarCajasSaldoAnterior(saldoAnterior);
      guardarCajasEgresos(egresos);
      guardarCajasIngresos(ingresos);
      guardarCajasSaldoActual(
        Number(saldoAnterior) + Number(ingresos) - Number(egresos)
      );
    } catch (error) {
      guardarMovimientosCajas([]);
      console.log(error.response);
      mensajeAlerta("Movimientos de Caja", error.response.data, "error");
    }
  };

  const agregarMovimientoCaja = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarmovimientocaja",
        datos
      );

      mensajeAlerta("Movimientos de Caja", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Movimientos de Caja", error.response.data, "error");
    }
  };

  // Cheques

  const confirmarAnularCheque = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/confirmaranularcheque",
        datos
      );

      mensajeAlerta("Cheques", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cheques", error.response.data, "error");
    }
  };

  const confirmarRechazarCheque = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/confirmarrechazarcheque",
        datos
      );

      mensajeAlerta("Cheques", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cheques", error.response.data, "error");
    }
  };

  const confirmarVentanillaCheque = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/confirmarventanillacheque",
        datos
      );

      mensajeAlerta("Cheques", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cheques", error.response.data, "error");
    }
  };

  const confirmarAcreditarCheque = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/confirmaracreditarcheque",
        datos
      );

      mensajeAlerta("Cheques", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cheques", error.response.data, "error");
    }
  };

  const confirmarDepositarCheque = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/confirmardepositarcheque",
        datos
      );

      mensajeAlerta("Cheques", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cheques", error.response.data, "error");
    }
  };

  const actualizarMovimientoCheque = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizarmovimientocheque",
        datos
      );

      mensajeAlerta("Cheques", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cheques", error.response.data, "error");
    }
  };

  const agregarMovimientoCheque = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/agregarmovimientocheque",
        datos
      );

      mensajeAlerta("Movimientos de Cheques", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Movimientos de Cheques", error.response.data, "error");
    }
  };

  function diferenciaDeDias(date1) {
    var date_2 = new Date(date1);

    let date = new Date();
    let day = `${date.getDate()}`.padStart(2, "0");
    let month = `${date.getMonth() + 1}`.padStart(2, "0");
    let year = date.getFullYear();
    var date_1 = new Date(`${year}-${month}-${day}`);

    var day_as_milliseconds = 86400000;
    var diff_in_millisenconds = date_2 - date_1;
    var diff_in_days = diff_in_millisenconds / day_as_milliseconds;

    return diff_in_days;
  }

  const obtenerMovimientosCheques = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermovimientoscheques",
        datos
      );
      let data = respuesta.data;

      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          tipo: data[i].tipo.toUpperCase(),
          fecha:
            data[i].movimientoscheques !== undefined &&
            data[i].movimientoscheques.length === 1
              ? data[i].movimientoscheques[0].fecha
              : null,
          id_concepto:
            data[i].movimientoscheques !== undefined &&
            data[i].movimientoscheques.length === 1 &&
            data[i].movimientoscheques[0].conceptos !== undefined
              ? data[i].movimientoscheques[0].id_concepto
              : null,
          concepto:
            data[i].movimientoscheques !== undefined &&
            data[i].movimientoscheques.length === 1 &&
            data[i].movimientoscheques[0].conceptos !== undefined
              ? data[i].movimientoscheques[0].conceptos.nombre
              : null,
          detalle:
            data[i].movimientoscheques !== undefined &&
            data[i].movimientoscheques.length === 1
              ? data[i].movimientoscheques[0].detalle
              : null,
          importe:
            data[i].movimientoscheques !== undefined &&
            data[i].movimientoscheques.length === 1
              ? data[i].movimientoscheques[0].importe
              : null,
          id_empresa:
            data[i].movimientoscheques !== undefined &&
            data[i].movimientoscheques.length === 1
              ? data[i].movimientoscheques[0].id_empresa
              : null,

          id_rendicion:
            data[i].movimientoscheques !== undefined &&
            data[i].movimientoscheques.length === 1
              ? data[i].movimientoscheques[0].id_rendicion
              : null,
          id_orden:
            data[i].movimientoscheques !== undefined &&
            data[i].movimientoscheques.length === 1
              ? data[i].movimientoscheques[0].id_orden
              : null,
          usuario:
            data[i].movimientoscheques !== undefined &&
            data[i].movimientoscheques.length === 1
              ? data[i].movimientoscheques[0].usuario
              : null,
          empresa:
            data[i].movimientoscheques !== undefined &&
            data[i].movimientoscheques.length === 1 &&
            data[i].movimientoscheques[0].empresas !== undefined
              ? data[i].movimientoscheques[0].empresas.nombre
              : null,

          cotizacion:
            data[i].movimientoscheques !== undefined &&
            data[i].movimientoscheques.length === 1
              ? data[i].movimientoscheques[0].cotizacion
              : null,

          emision: data[i].emision,
          deposito: data[i].deposito,
          estado: data[i].estado.toUpperCase(),
          id_banco: data[i].id_banco,
          detalleBanco: data[i].detalleBanco,
          id_cuenta: data[i].id_cuenta,
          detalleCuenta: data[i].detalleCuenta,
          numero: data[i].numero,
          eCheq: data[i].eCheq,
          noALaOrden: data[i].noALaOrden,
          diferenciaDias:
            data[i].tipo === "terceros" && data[i].estado === "cartera"
              ? diferenciaDeDias(data[i].deposito)
              : null,
          estadoId: data[i].estadoId,
          id_moneda: data[i].id_moneda,
          moneda: data[i].monedas !== undefined ? data[i].monedas.nombre : null,
        });
      }

      guardarMovimientosCheques(local);
    } catch (error) {
      guardarMovimientosCheques([]);
      console.log(error.response);
      mensajeAlerta("Movimientos de Cheques", error.response.data, "error");
    }
  };

  //Listados

  const obtenerOrdenesPagosListado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerordenespagoslistados",
        datos
      );

      if (datos.filtroFechas === "emision") {
        let data = respuesta.data;

        let local = [];

        for (var i = 0; i < data.length; i++) {
          local.push({
            orden: data[i].id_orden,
            fecha: data[i].fecha,
            IDproveedor: data[i].id_proveedor,
            proveedor: data[i].proveedores.nombre_razonsocial,
            total: Number(data[i].total),
            saldo: Number(data[i].saldo),
            efectivo: Number(data[i].efectivo),
            cheques: Number(data[i].cheques),
            compensaciones: Number(data[i].compensaciones),
            bancos: Number(data[i].bancos),
            vencimiento: data[i].vencimiento,
            concepto: data[i].id_concepto,
            fechaPago: data[i].fecha,
            empresa: data[i].empresas.nombre,
            ordenes: data[i].ordenes,
          });
        }

        guardarListadoExcel(local);
      }

      if (datos.filtroFechas === "pago") {
        let dataCajas = respuesta.data.responseCajas;
        let dataCheques = respuesta.data.responseCheques;
        let dataCompensaciones = respuesta.data.responseCompensaciones;
        let dataBancos = respuesta.data.responseBancos;

        let local = [];

        for (var i = 0; i < dataCajas.length; i++) {
          let index = local.findIndex(
            (item) => item.ordenId === dataCajas[i].ordenId
          );
          if (index === -1) {
            local.push({
              ordenId: dataCajas[i].ordenId,
              orden: dataCajas[i].ordenespagos.id_orden,
              fecha: dataCajas[i].ordenespagos.fecha,
              IDproveedor: dataCajas[i].ordenespagos.id_proveedor,
              proveedor:
                dataCajas[i].ordenespagos.proveedores.nombre_razonsocial,
              total: Number(dataCajas[i].ordenespagos.total),
              saldo: Number(dataCajas[i].ordenespagos.saldo),
              efectivo: Number(dataCajas[i].ordenespagos.efectivo),
              cheques: Number(dataCajas[i].ordenespagos.cheques),
              compensaciones: Number(dataCajas[i].ordenespagos.compensaciones),
              bancos: Number(dataCajas[i].ordenespagos.bancos),
              vencimiento: dataCajas[i].ordenespagos.vencimiento,
              concepto: dataCajas[i].ordenespagos.id_concepto,
              fechaPago: dataCajas[i].fecha,
              empresa: dataCajas[i].ordenespagos.empresas.nombre,
              ordenes: dataCajas[i].ordenespagos.ordenes,
            });
          }
        }

        for (var i = 0; i < dataCheques.length; i++) {
          let index = local.findIndex(
            (item) => item.ordenId === dataCheques[i].ordenId
          );
          if (index === -1) {
            local.push({
              ordenId: dataCheques[i].ordenId,
              orden: dataCheques[i].ordenespagos.id_orden,
              fecha: dataCheques[i].ordenespagos.fecha,
              total: Number(dataCheques[i].ordenespagos.total),
              saldo: Number(dataCheques[i].ordenespagos.saldo),
              efectivo: Number(dataCheques[i].ordenespagos.efectivo),
              cheques: Number(dataCheques[i].ordenespagos.cheques),
              compensaciones: Number(
                dataCheques[i].ordenespagos.compensaciones
              ),
              bancos: Number(dataCheques[i].ordenespagos.bancos),
              vencimiento: dataCheques[i].ordenespagos.vencimiento,
              concepto: dataCheques[i].ordenespagos.id_concepto,
              fechaPago: dataCheques[i].fecha,
              empresa: dataCheques[i].ordenespagos.empresas.nombre,
              ordenes: dataCheques[i].ordenespagos.ordenes,
            });
          }
        }

        for (var i = 0; i < dataCompensaciones.length; i++) {
          let index = local.findIndex(
            (item) => item.ordenId === dataCompensaciones[i].ordenId
          );
          if (index === -1) {
            local.push({
              ordenId: dataCompensaciones[i].ordenId,
              orden: dataCompensaciones[i].ordenespagos.id_orden,
              fecha: dataCompensaciones[i].ordenespagos.fecha,
              total: Number(dataCompensaciones[i].ordenespagos.total),
              saldo: Number(dataCompensaciones[i].ordenespagos.saldo),
              efectivo: Number(dataCompensaciones[i].ordenespagos.efectivo),
              cheques: Number(dataCompensaciones[i].ordenespagos.cheques),
              compensaciones: Number(
                dataCompensaciones[i].ordenespagos.compensaciones
              ),
              bancos: Number(dataCompensaciones[i].ordenespagos.bancos),
              vencimiento: dataCompensaciones[i].ordenespagos.vencimiento,
              concepto: dataCompensaciones[i].ordenespagos.id_concepto,
              fechaPago: dataCompensaciones[i].fecha,
              empresa: dataCompensaciones[i].ordenespagos.empresas.nombre,
              ordenes: dataCompensaciones[i].ordenespagos.ordenes,
            });
          }
        }

        for (var i = 0; i < dataBancos.length; i++) {
          let index = local.findIndex(
            (item) => item.ordenId === dataBancos[i].ordenId
          );
          if (index === -1) {
            local.push({
              ordenId: dataBancos[i].ordenId,
              orden: dataBancos[i].ordenespagos.id_orden,
              fecha: dataBancos[i].ordenespagos.fecha,
              total: Number(dataBancos[i].ordenespagos.total),
              saldo: Number(dataBancos[i].ordenespagos.saldo),
              efectivo: Number(dataBancos[i].ordenespagos.efectivo),
              cheques: Number(dataBancos[i].ordenespagos.cheques),
              compensaciones: Number(dataBancos[i].ordenespagos.compensaciones),
              bancos: Number(dataBancos[i].ordenespagos.bancos),
              vencimiento: dataBancos[i].ordenespagos.vencimiento,
              concepto: dataBancos[i].ordenespagos.id_concepto,
              fechaPago: dataBancos[i].fecha,
              empresa: dataBancos[i].ordenespagos.empresas.nombre,
              ordenes: dataBancos[i].ordenespagos.ordenes,
            });
          }
        }

        let array = local.filter((obj) => {
          for (let key in obj) {
            if (key === "ordenId") {
              delete obj[key];
            }
          }
          return true;
        });
        guardarListadoExcel(array);
      }
    } catch (error) {
      //guardarMovimientosCompensaciones([]);
      console.log(error.response);
      mensajeAlerta("Retenciones de Ventas", error.response.data, "error");
    }
  };

  const obtenerRetencionesVentas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercomprobantesventas",
        datos
      );
      let data = respuesta.data;
      let local = [];
      let total = 0;

      if (!datos.grupo || datos.grupo === "") {
        for (var i = 0; i < data.length; i++) {
          total = total + Number(parseFloat(data[i].total).toFixed(2));

          local.push({
            fecha: formatDate(data[i].fecha),
            comprobante:
              data[i].comprobante +
              "-" +
              data[i].puntoventa +
              "-" +
              data[i].numero,
            tipo: data[i].retencionTipo,
            periodo: data[i].periodo,
            afiliadoId: data[i].id_afiliado,
            titularId: data[i].id_titular,
            nombre_razonsocial: data[i].nombre_titular,
            total: parseFloat(data[i].total).toFixed(2),
            cobrador: data[i].id_cobrador,
            asesor: data[i].id_asesor,
            empresa: data[i].empresas.nombre,
            grupo: data[i].grupos.nombre,
            plan: data[i].planes.nombre,
          });
        }
        if (datos.exportType === "pdf") {
          local.push({
            fecha: "TOTAL",
            comprobante: null,
            tipo: null,
            periodo: null,
            afiliadoId: null,
            titularId: null,
            nombre_razonsocial: null,
            total: parseFloat(total).toFixed(2),
            cobrador: null,
            asesor: null,
            grupo: null,
            plan: null,
          });
        }
        guardarListadoExcel(local);
      }

      let totalGrupo = 0;
      let totalFinal = 0;
      let final = [];
      let cantidad = 0;
      let cantidadTotal = 0;

      if (datos.grupo && datos.grupo !== "") {
        for (var i = 0; i < data.length; i++) {
          total = total + Number(parseFloat(data[i].total).toFixed(2));
          local.push({
            Fecha: formatDate(data[i].fecha),
            Tipo: data[i].retencionTipo,
            Cobrador: data[i].id_cobrador,
            Empresa: data[i].id_empresa,
            total: parseFloat(data[i].total).toFixed(2),
          });
        }

        const groupBy = (prop) => (data) => {
          return data.reduce((dict, item) => {
            const { [prop]: _, ...rest } = item;
            dict[item[prop]] = [...(dict[item[prop]] || []), rest];
            return dict;
          }, {});
        };

        //var result = groupBy(serviciosFinalizadosExcel, 'Antecedente');

        let campo = datos.grupo.nombre;

        const result = Object.entries(groupBy(campo)(local)).map(
          ([key, value]) => ({ nombre: key, datos: value })
        );

        for (var i = 0; i < result.length; i++) {
          totalGrupo = 0;
          cantidad = 0;
          for (var j = 0; j < result[i].datos.length; j++) {
            totalGrupo = totalGrupo + Number(result[i].datos[j].total);
            cantidad = cantidad + 1;
          }
          totalFinal = totalFinal + Number(totalGrupo);
          cantidadTotal = cantidadTotal + cantidad;

          final.push({
            descripcion: result[i].nombre,
            cantidad,
            total: parseFloat(totalGrupo).toFixed(2),
          });
        }

        final.push({
          descripcion: "TOTAL",
          cantidad: cantidadTotal,
          total: parseFloat(totalFinal).toFixed(2),
        });
        guardarListadoExcel(final);
      }
    } catch (error) {
      //guardarMovimientosCompensaciones([]);
      console.log(error.response);
      mensajeAlerta("Retenciones de Ventas", error.response.data, "error");
    }
  };

  const obtenerComprobantesVentas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercomprobantesventas",
        datos
      );
      let data = respuesta.data;
      let local = [];
      let total = 0;

      if (datos && datos.tipoListado === "Orden") {
        for (var i = 0; i < data.length; i++) {
          total = total + Number(data[i].total);

          local.push({
            Fecha: formatDate(data[i].fecha),
            TipoComprobante: data[i].comprobante,
            Comprobante:
              data[i].comprobante +
              "-" +
              data[i].puntoventa +
              "-" +
              data[i].numero,
            Periodo: data[i].periodo,
            NumeroAfiliado: data[i].id_afiliado,
            NumeroTitular: data[i].id_titular,
            NombreRazonSocial: data[i].nombre_titular,
            Total: Number(data[i].total),
            Cobrador: data[i].id_cobrador,
            Asesor: data[i].id_asesor,
            Empresa: data[i].empresas.nombre,
            Grupo: data[i].grupos.nombre,
            Plan: data[i].planes.nombre,
          });
        }

        local.sort(function (a, b) {
          const indiceA =
            datos.orden.nombre === "Fecha"
              ? a.Fecha
              : datos.orden.nombre === "Plan"
              ? a.Plan
              : datos.orden.nombre === "Grupo"
              ? a.Grupo
              : datos.orden.nombre === "Cobrador"
              ? a.Cobrador
              : datos.orden.nombre === "Asesor"
              ? a.Asesor
              : datos.orden.nombre === "Empresa"
              ? a.Empresa
              : datos.orden.nombre === "TipoComprobante"
              ? a.TipoComprobante
              : datos.orden.nombre === "Afiliado"
              ? a.NumeroTitular
              : null;
          const indiceB =
            datos.orden.nombre === "Fecha"
              ? b.Fecha
              : datos.orden.nombre === "Plan"
              ? b.Plan
              : datos.orden.nombre === "Grupo"
              ? b.Grupo
              : datos.orden.nombre === "Cobrador"
              ? b.Cobrador
              : datos.orden.nombre === "Asesor"
              ? b.Asesor
              : datos.orden.nombre === "Empresa"
              ? b.Empresa
              : datos.orden.nombre === "TipoComprobante"
              ? b.TipoComprobante
              : datos.orden.nombre === "Afiliado"
              ? a.NumeroTitular
              : null;

          // sort in an ascending order
          if (indiceA < indiceB) {
            return -1;
          }
          if (indiceA > indiceB) {
            return 1;
          }

          // names must be equal
          return 0;
        });

        if (datos.exportType === "pdf") {
          let dato = null;
          let totalCorte = 0;
          let cantidadCorte = 0;
          let totalFinal = 0;
          let cantidadFinal = 0;
          let subtotal = [];

          if (local.length > 0) {
            dato =
              datos.orden.nombre === "Fecha"
                ? local[0].Fecha
                : datos.orden.nombre === "Plan"
                ? local[0].Plan
                : datos.orden.nombre === "Grupo"
                ? local[0].Grupo
                : datos.orden.nombre === "Cobrador"
                ? local[0].Cobrador
                : datos.orden.nombre === "Asesor"
                ? local[0].Asesor
                : datos.orden.nombre === "Empresa"
                ? local[0].Empresa
                : datos.orden.nombre === "TipoComprobante"
                ? local[0].TipoComprobante
                : datos.orden.nombre === "Afiliado"
                ? local[0].NumeroTitular
                : null;
          }

          for (var i = 0; i < local.length; i++) {
            let comparar =
              datos.orden.nombre === "Fecha"
                ? local[i].Fecha
                : datos.orden.nombre === "Plan"
                ? local[i].Plan
                : datos.orden.nombre === "Grupo"
                ? local[i].Grupo
                : datos.orden.nombre === "Cobrador"
                ? local[i].Cobrador
                : datos.orden.nombre === "Asesor"
                ? local[i].Asesor
                : datos.orden.nombre === "Empresa"
                ? local[i].Empresa
                : datos.orden.nombre === "TipoComprobante"
                ? local[i].TipoComprobante
                : datos.orden.nombre === "Afiliado"
                ? local[i].NumeroTitular
                : null;

            if (comparar !== dato) {
              if (datos.subtotal) {
                subtotal.push({
                  Fecha: "SUBTOTAL",
                  TipoComprobante: null,
                  Comprobante: cantidadCorte,
                  Periodo: null,
                  NumeroAfiliado: null,
                  NumeroTitular: null,
                  NombreRazonSocial: null,
                  Total: Number(totalCorte).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  }),
                  Cobrador: null,
                  Asesor: null,
                  Empresa: "\n",
                  Grupo: null,
                  Plan: null,
                });
              }

              totalCorte = 0;
              cantidadCorte = 0;

              subtotal.push({
                Fecha: local[i].Fecha,
                TipoComprobante: local[i].TipoComprobante,
                Comprobante: local[i].Comprobante,
                Periodo: local[i].Periodo,
                NumeroAfiliado: local[i].NumeroAfiliado,
                NumeroTitular: local[i].NumeroTitular,
                NombreRazonSocial: local[i].NombreRazonSocial,
                Total: Number(local[i].Total).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                }),
                Cobrador: local[i].Cobrador,
                Asesor: local[i].Asesor,
                Empresa: local[i].Empresa,
                Grupo: local[i].Grupo,
                Plan: local[i].Plan,
              });

              dato =
                datos.orden.nombre === "Fecha"
                  ? local[i].Fecha
                  : datos.orden.nombre === "Plan"
                  ? local[i].Plan
                  : datos.orden.nombre === "Grupo"
                  ? local[i].Grupo
                  : datos.orden.nombre === "Cobrador"
                  ? local[i].Cobrador
                  : datos.orden.nombre === "Asesor"
                  ? local[i].Asesor
                  : datos.orden.nombre === "Empresa"
                  ? local[i].Empresa
                  : datos.orden.nombre === "TipoComprobante"
                  ? local[i].TipoComprobante
                  : datos.orden.nombre === "Afiliado"
                  ? local[i].NumeroTitular
                  : null;

              comparar =
                datos.orden.nombre === "Fecha"
                  ? local[i].Fecha
                  : datos.orden.nombre === "Plan"
                  ? local[i].Plan
                  : datos.orden.nombre === "Grupo"
                  ? local[i].Grupo
                  : datos.orden.nombre === "Cobrador"
                  ? local[i].Cobrador
                  : datos.orden.nombre === "Asesor"
                  ? local[i].Asesor
                  : datos.orden.nombre === "Empresa"
                  ? local[i].Empresa
                  : datos.orden.nombre === "TipoComprobante"
                  ? local[i].TipoComprobante
                  : datos.orden.nombre === "Afiliado"
                  ? local[i].NumeroTitular
                  : null;

              totalCorte = totalCorte + local[i].Total;
              cantidadCorte = cantidadCorte + 1;
              totalFinal = totalFinal + local[i].Total;
              cantidadFinal = cantidadFinal + 1;
            } else {
              subtotal.push({
                Fecha: local[i].Fecha,
                TipoComprobante: local[i].TipoComprobante,
                Comprobante: local[i].Comprobante,
                Periodo: local[i].Periodo,
                NumeroAfiliado: local[i].NumeroAfiliado,
                NumeroTitular: local[i].NumeroTitular,
                NombreRazonSocial: local[i].NombreRazonSocial,
                Total: Number(local[i].Total).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                }),
                Cobrador: local[i].Cobrador,
                Asesor: local[i].Asesor,
                Empresa: local[i].Empresa,
                Grupo: local[i].Grupo,
                Plan: local[i].Plan,
              });

              totalCorte = totalCorte + local[i].Total;
              cantidadCorte = cantidadCorte + 1;
              totalFinal = totalFinal + local[i].Total;
              cantidadFinal = cantidadFinal + 1;
            }
          }

          if (datos.subtotal) {
            subtotal.push({
              Fecha: "SUBTOTAL",
              TipoComprobante: null,
              Comprobante: cantidadCorte,
              Periodo: null,
              NumeroAfiliado: null,
              NumeroTitular: null,
              NombreRazonSocial: null,
              Total: Number(totalCorte).toLocaleString("en-US", {
                minimumFractionDigits: 2,
              }),
              Cobrador: null,
              Asesor: null,
              Empresa: "\n",
              Grupo: null,
              Plan: null,
            });
          }
          subtotal.push({
            Fecha: "TOTAL",
            TipoComprobante: null,
            Comprobante: cantidadFinal,
            Periodo: null,
            NumeroAfiliado: null,
            NumeroTitular: null,
            NombreRazonSocial: null,
            Total: Number(totalFinal).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            }),
            Cobrador: null,
            Asesor: null,
            Empresa: null,
            Grupo: null,
            Plan: null,
          });

          guardarListadoExcel(subtotal);
        } else {
          guardarListadoExcel(local);
        }
      }

      let totalGrupo = 0;
      let totalFinal = 0;
      let final = [];
      let cantidad = 0;
      let cantidadTotal = 0;

      if (datos && datos.tipoListado === "Grupo") {
        for (var i = 0; i < data.length; i++) {
          total = total + Number(data[i].total);
          local.push({
            Fecha: formatDate(data[i].fecha),
            Plan: data[i].planes.nombre,
            Grupo: data[i].grupos.nombre,
            Cobrador: data[i].id_cobrador,
            Comprobante: data[i].comprobante,
            Asesor: data[i].id_asesor,
            Empresa: data[i].id_empresa,
            Afiliado: data[i].id_titular,
            total: Number(data[i].total),
          });
        }
        const groupBy = (prop) => (data) => {
          return data.reduce((dict, item) => {
            const { [prop]: _, ...rest } = item;
            dict[item[prop]] = [...(dict[item[prop]] || []), rest];
            return dict;
          }, {});
        };

        //var result = groupBy(serviciosFinalizadosExcel, 'Antecedente');

        let campo = datos.grupo.nombre;

        const result = Object.entries(groupBy(campo)(local)).map(
          ([key, value]) => ({ nombre: key, datos: value })
        );

        for (var i = 0; i < result.length; i++) {
          totalGrupo = 0;
          cantidad = 0;
          for (var j = 0; j < result[i].datos.length; j++) {
            totalGrupo = totalGrupo + Number(result[i].datos[j].total);
            cantidad = cantidad + 1;
          }
          totalFinal = totalFinal + Number(totalGrupo);
          cantidadTotal = cantidadTotal + cantidad;

          final.push({
            descripcion: result[i].nombre,
            cantidad,
            //total: parseFloat(totalGrupo).toFixed(2),
            total:
              datos.exportType === "pdf"
                ? Number(totalGrupo).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })
                : Number(totalGrupo),
          });
        }

        final.push({
          descripcion: "TOTAL",
          cantidad: cantidadTotal,
          //total: parseFloat(totalFinal).toFixed(2),
          total:
            datos.exportType === "pdf"
              ? Number(totalFinal).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })
              : Number(totalFinal),
        });
        guardarListadoExcel(final);
      }
    } catch (error) {
      //guardarMovimientosCompensaciones([]);
      console.log(error.response);
      mensajeAlerta("Comprobantes de Ventas", error.response.data, "error");
    }
  };

  const obtenerSuspendidos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenersuspendidos",
        datos
      );

      let data = respuesta.data;
      let local = [];
      let cantidad = 0;
      let totalCuota = 0;

      for (var i = 0; i < data.length; i++) {
        if (data[i].titular) {
          totalCuota =
            totalCuota + Number(parseFloat(data[i].cuota).toFixed(2));
        }
        cantidad = cantidad + 1;

        local.push({
          id: data[i].id,
          id_titular: data[i].id_titular,
          nombre_razonsocial: data[i].nombre_razonsocial,
          domicilio: data[i].domicilio,
          telefono: data[i].telefono1,
          celular: data[i].celular,
          email: data[i].email,
          localidad: data[i].localidades.nombre,
          grupo: data[i].grupos.nombre,
          empresa: data[i].empresas.nombre,
          plan: data[i].planes.nombre,
          vigencia: data[i].vigencia,
          cuota: parseFloat(data[i].cuota).toFixed(2),
          numerodocumento: data[i].numerodocumento,
          fsuspendidodesde:
            data[i].fsuspendidodesde !== null
              ? formatDate(data[i].fsuspendidodesde)
              : null,
          fsuspendidohasta:
            data[i].fsuspendidohasta !== null
              ? formatDate(data[i].fsuspendidohasta)
              : null,
          msuspendido:
            data[i].motivossuspendidos !== null
              ? data[i].motivossuspendidos.nombre
              : null,
        });
      }
      if (datos.exportType === "pdf") {
        local.push({
          id: null,
          id_titular: null,
          nombre_razonsocial: null,
          domicilio: null,
          telefono: null,
          celular: null,
          email: null,
          localidad: null,
          grupo: null,
          empresa: null,
          plan: null,
          vigencia: null,
          cuota: parseFloat(totalCuota).toFixed(2),
          numerodocumento: null,
          fsuspendidodesde: null,
          fsuspendidohasta: null,
          msuspendido: null,
        });
      }
      guardarListadoExcel(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Listado de Suspendidos", error.response.data, "error");
    }
  };

  const obtenerModulos = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermodulos",
        datos
      );

      let data = respuesta.data;
      let local = [];
      let cantidad = 0;
      let totalCuota = 0;
      let totalAdicional = 0;

      for (var i = 0; i < data.length; i++) {
        totalCuota =
          totalCuota + Number(parseFloat(data[i].afiliados.cuota).toFixed(2));

        if (data[i].adicionales.valor !== null) {
          totalAdicional =
            totalAdicional +
            Number(parseFloat(data[i].adicionales.valor).toFixed(2));
        } else {
          totalAdicional = totalAdicional + Number(parseFloat(0).toFixed(2));
        }
        cantidad = cantidad + 1;

        local.push({
          id: data[i].id,
          id_titular: data[i].afiliados.id_titular,
          nombre_razonsocial: data[i].afiliados.nombre_razonsocial,
          domicilio: data[i].afiliados.domicilio,
          telefono: data[i].afiliados.telefono1,
          celular: data[i].afiliados.celular,
          email: data[i].afiliados.email,
          localidad: data[i].afiliados.localidades.nombre,
          grupo: data[i].afiliados.grupos.nombre,
          empresa: data[i].afiliados.empresas.nombre,
          plan: data[i].afiliados.planes.nombre,
          vigencia: data[i].afiliados.vigencia,
          cuota: parseFloat(data[i].afiliados.cuota).toFixed(2),
          numerodocumento: data[i].afiliados.numerodocumento,
          modulo: data[i].adicionales.nombre,
          valor:
            data[i].adicionales.valor !== null
              ? parseFloat(data[i].adicionales.valor).toFixed(2)
              : parseFloat(0).toFixed(2),
        });
      }
      if (datos.exportType === "pdf") {
        local.push({
          id: null,
          id_titular: null,
          nombre_razonsocial: null,
          domicilio: null,
          telefono: null,
          celular: null,
          email: null,
          localidad: null,
          grupo: null,
          empresa: null,
          plan: null,
          vigencia: null,
          cuota: parseFloat(totalCuota).toFixed(2),
          numerodocumento: null,
          modulo: null,
          valor: parseFloat(totalAdicional).toFixed(2),
        });
      }
      guardarListadoExcel(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Listado de Módulos", error.response.data, "error");
    }
  };

  const obtenerCobranzasRealizadasPlanilla = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercobranzasrealizadas",
        datos
      );
      let data = respuesta.data;
      let local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          grupo: data[i].grupos.nombre,

          efectivo:
            data[i].valor === "efectivo"
              ? Number(data[i].importeTotalPesos)
              : null,
          cheques:
            data[i].valor === "cheques"
              ? Number(data[i].importeTotalPesos)
              : null,
          bancos:
            data[i].valor === "bancos"
              ? Number(data[i].importeTotalPesos)
              : null,
          compensaciones:
            data[i].valor === "compensaciones"
              ? Number(data[i].importeTotalPesos)
              : null,
          total:
            data[i].valor !== "retenciones"
              ? Number(data[i].importeTotalPesos)
              : null,
          retenciones:
            data[i].valor === "retenciones"
              ? Number(data[i].importeTotalPesos)
              : null,
          comisiones: Number(data[i].comision),
          Valor: data[i].valor,
        });
      }

      const groupBy = (prop) => (data) => {
        return data.reduce((dict, item) => {
          const { [prop]: _, ...rest } = item;
          dict[item[prop]] = [...(dict[item[prop]] || []), rest];
          return dict;
        }, {});
      };

      //var result = groupBy(serviciosFinalizadosExcel, 'Antecedente');

      let campo = "grupo";

      const result = Object.entries(groupBy(campo)(local)).map(
        ([key, value]) => ({ nombre: key, datos: value })
      );

      let totalGrupo = 0;
      let retencionesGrupo = 0;
      let comisionesGrupo = 0;
      let totalFinal = 0;
      let retencionesFinal = 0;
      let comisionesFinal = 0;
      let final = [];

      for (var i = 0; i < result.length; i++) {
        totalGrupo = 0;
        retencionesGrupo = 0;
        comisionesGrupo = 0;
        for (var j = 0; j < result[i].datos.length; j++) {
          totalGrupo = totalGrupo + Number(result[i].datos[j].total);
          retencionesGrupo =
            retencionesGrupo + Number(result[i].datos[j].retenciones);
          comisionesGrupo =
            comisionesGrupo + Number(result[i].datos[j].comisiones);
        }

        totalFinal = totalFinal + Number(totalGrupo);
        retencionesFinal = retencionesFinal + Number(retencionesGrupo);
        comisionesFinal = comisionesFinal + Number(comisionesGrupo);

        final.push({
          grupo: result[i].nombre,
          total: Number(totalGrupo).toLocaleString("en-US", {
            minimumFractionDigits: 2,
          }),
          retenciones: Number(retencionesGrupo).toLocaleString("en-US", {
            minimumFractionDigits: 2,
          }),
          comisiones: Number(comisionesGrupo).toLocaleString("en-US", {
            minimumFractionDigits: 2,
          }),
          neto: Number(
            totalGrupo + retencionesGrupo - comisionesGrupo
          ).toLocaleString("en-US", {
            minimumFractionDigits: 2,
          }),
        });
      }

      final.push({
        grupo: "TOTAL",
        total: Number(totalFinal).toLocaleString("en-US", {
          minimumFractionDigits: 2,
        }),
        retenciones: Number(retencionesFinal).toLocaleString("en-US", {
          minimumFractionDigits: 2,
        }),
        comisiones: Number(comisionesFinal).toLocaleString("en-US", {
          minimumFractionDigits: 2,
        }),
        neto: Number(
          totalFinal + retencionesFinal - comisionesFinal
        ).toLocaleString("en-US", {
          minimumFractionDigits: 2,
        }),
      });

      guardarListadoExcel(final);
      guardarResult(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(
        "Planilla de Cobranzas Realizadas",
        error.response.data,
        "error"
      );
    }
  };

  const obtenerCobranzasRealizadas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercobranzasrealizadas",
        datos
      );
      let data = respuesta.data;
      let local = [];
      let totalImporte = 0;
      let totalRetenciones = 0;

      if (datos && datos.tipoListado === "Orden") {
        for (var i = 0; i < data.length; i++) {
          if (datos.exportType === "pdf") {
            if (data[i].valor !== "retenciones") {
              totalImporte = totalImporte + Number(data[i].importeTotalPesos);
            } else {
              totalRetenciones =
                totalRetenciones + Number(data[i].importeTotalPesos);
            }

            local.push({
              Fecha: formatDate(data[i].fecha),
              TipoComprobante: data[i].comprobante,
              Comprobante:
                data[i].comprobante +
                "-" +
                data[i].puntoventa +
                "-" +
                data[i].numero,
              NumeroAfiliado: data[i].afiliadoId,
              NumeroTitular: data[i].titularId,
              NombreRazonSocial: data[i].nombreTitular,
              Total:
                data[i].valor !== "retenciones"
                  ? Number(data[i].importeTotalPesos)
                  : null,
              Retenciones:
                data[i].valor === "retenciones"
                  ? Number(data[i].importeTotalPesos)
                  : null,
              Cobrador: data[i].cobradorId,
              Empresa: data[i].empresas.nombre,
              Periodo: data[i].comprobantes
                ? data[i].comprobantes.periodo
                : "99/99",
              Rendicion: data[i].rendicionId,
              Valor: data[i].valor,
            });

            /*
            local.push({
              fecha: "TOTAL",
              TipoComprobante: null,
              comprobante: null,
              afiliadoId: null,
              titularId: null,
              nombre_razonsocial: null,
              importe: Number(totalImporte).toLocaleString("en-US", {
                minimumFractionDigits: 2,
              }),
              retenciones: Number(totalRetenciones).toLocaleString("en-US", {
                minimumFractionDigits: 2,
              }),
              cobrador: null,
              empresa: null,
              periodo: null,
              rendicion: null,
              valor: null,
            });
            */
          }

          if (datos.exportType === "excel") {
            let newPropertyName = null;
            let newPropertyValue = null;

            if (data[i].valor === "bancos") {
              newPropertyName = data[i].bancoCuenta;
              newPropertyValue = Number(data[i].importeTotalPesos);
            }

            if (data[i].valor === "retenciones") {
              newPropertyName = data[i].retencionTipo;
              newPropertyValue = Number(data[i].importeTotalPesos);
            }

            local.push({
              Fecha: formatDate(data[i].fecha),
              TipoComprobante: data[i].comprobante,
              Comprobante:
                data[i].comprobante +
                "-" +
                data[i].puntoventa +
                "-" +
                data[i].numero,
              NumeroAfiliado: data[i].afiliadoId,
              NumeroTitular: data[i].titularId,
              NombreRazonSocial: data[i].nombreTitular,
              Importe: Number(data[i].importeTotalPesos),
              Cobrador: data[i].cobradorId,
              Empresa: data[i].empresas.nombre,
              Periodo: data[i].comprobantes
                ? data[i].comprobantes.periodo
                : "99/99",
              Rendicion: data[i].rendicionId,
              Valor: data[i].valor,
              Efectivo:
                data[i].valor === "efectivo"
                  ? Number(data[i].importeTotalPesos)
                  : null,
              Cheques:
                data[i].valor === "cheques"
                  ? Number(data[i].importeTotalPesos)
                  : null,
              Compensaciones:
                data[i].valor === "compensaciones"
                  ? Number(data[i].importeTotalPesos)
                  : null,
              ...(newPropertyName
                ? {
                    [newPropertyName]: newPropertyValue,
                  }
                : {}),
            });
          }
        }

        local.sort(function (a, b) {
          const indiceA =
            datos.orden.nombre === "Fecha"
              ? a.Fecha
              : datos.orden.nombre === "Cobrador"
              ? a.Cobrador
              : datos.orden.nombre === "Empresa"
              ? a.Empresa
              : datos.orden.nombre === "Afiliado"
              ? a.NumeroTitular
              : null;
          const indiceB =
            datos.orden.nombre === "Fecha"
              ? b.Fecha
              : datos.orden.nombre === "Cobrador"
              ? b.Cobrador
              : datos.orden.nombre === "Empresa"
              ? b.Empresa
              : datos.orden.nombre === "Afiliado"
              ? a.NumeroTitular
              : null;

          // sort in an ascending order
          if (indiceA < indiceB) {
            return -1;
          }
          if (indiceA > indiceB) {
            return 1;
          }

          // names must be equal
          return 0;
        });

        if (datos.exportType === "pdf") {
          let dato = null;
          let totalCorte = 0;
          let retencionesCorte = 0;
          let cantidadCorte = 0;
          let totalFinal = 0;
          let retencionesFinal = 0;
          let cantidadFinal = 0;
          let subtotal = [];

          if (local.length > 0) {
            dato =
              datos.orden.nombre === "Fecha"
                ? local[0].Fecha
                : datos.orden.nombre === "Cobrador"
                ? local[0].Cobrador
                : datos.orden.nombre === "Empresa"
                ? local[0].Empresa
                : datos.orden.nombre === "Afiliado"
                ? local[0].NumeroTitular
                : null;
          }

          for (var i = 0; i < local.length; i++) {
            let comparar =
              datos.orden.nombre === "Fecha"
                ? local[i].Fecha
                : datos.orden.nombre === "Cobrador"
                ? local[i].Cobrador
                : datos.orden.nombre === "Empresa"
                ? local[i].Empresa
                : datos.orden.nombre === "Afiliado"
                ? local[i].NumeroTitular
                : null;

            if (comparar !== dato) {
              if (datos.subtotal) {
                subtotal.push({
                  Fecha: "SUBTOTAL",
                  TipoComprobante: null,
                  Comprobante: cantidadCorte,
                  NumeroAfiliado: null,
                  NumeroTitular: null,
                  NombreRazonSocial: null,
                  Total: Number(totalCorte).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  }),
                  Retenciones: Number(retencionesCorte).toLocaleString(
                    "en-US",
                    {
                      minimumFractionDigits: 2,
                    }
                  ),
                  Cobrador: null,
                  Empresa: "\n",
                  Periodo: null,
                  Rendicion: null,
                  Valor: null,
                });
              }

              totalCorte = 0;
              retencionesCorte = 0;
              cantidadCorte = 0;

              subtotal.push({
                Fecha: local[i].Fecha,
                TipoComprobante: local[i].TipoComprobante,
                Comprobante: local[i].Comprobante,
                Periodo: local[i].Periodo,
                NumeroAfiliado: local[i].NumeroAfiliado,
                NumeroTitular: local[i].NumeroTitular,
                NombreRazonSocial: local[i].NombreRazonSocial,
                Total: Number(local[i].Total).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                }),
                Retenciones: Number(local[i].Retenciones).toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 2,
                  }
                ),
                Cobrador: local[i].Cobrador,
                Empresa: local[i].Empresa,
                Periodo: local[i].Periodo,
                Rendicion: local[i].Rendicion,
                Valor: local[i].Valor,
              });

              dato =
                datos.orden.nombre === "Fecha"
                  ? local[i].Fecha
                  : datos.orden.nombre === "Cobrador"
                  ? local[i].Cobrador
                  : datos.orden.nombre === "Empresa"
                  ? local[i].Empresa
                  : datos.orden.nombre === "Afiliado"
                  ? local[i].NumeroTitular
                  : null;

              comparar =
                datos.orden.nombre === "Fecha"
                  ? local[i].Fecha
                  : datos.orden.nombre === "Cobrador"
                  ? local[i].Cobrador
                  : datos.orden.nombre === "Empresa"
                  ? local[i].Empresa
                  : datos.orden.nombre === "Afiliado"
                  ? local[i].NumeroTitular
                  : null;

              retencionesCorte = retencionesCorte + local[i].Retenciones;
              totalCorte = totalCorte + local[i].Total;
              cantidadCorte = cantidadCorte + 1;
              retencionesFinal = retencionesFinal + local[i].Retenciones;
              totalFinal = totalFinal + local[i].Total;
              cantidadFinal = cantidadFinal + 1;
            } else {
              subtotal.push({
                Fecha: local[i].Fecha,
                TipoComprobante: local[i].TipoComprobante,
                Comprobante: local[i].Comprobante,
                Periodo: local[i].Periodo,
                NumeroAfiliado: local[i].NumeroAfiliado,
                NumeroTitular: local[i].NumeroTitular,
                NombreRazonSocial: local[i].NombreRazonSocial,
                Total: Number(local[i].Total).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                }),
                Retenciones: Number(local[i].Retenciones).toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 2,
                  }
                ),
                Cobrador: local[i].Cobrador,
                Empresa: local[i].Empresa,
                Periodo: local[i].Periodo,
                Rendicion: local[i].Rendicion,
                Valor: local[i].Valor,
              });

              totalCorte = totalCorte + local[i].Total;
              retencionesCorte = retencionesCorte + local[i].Retenciones;
              cantidadCorte = cantidadCorte + 1;
              totalFinal = totalFinal + local[i].Total;
              retencionesFinal = retencionesFinal + local[i].Retenciones;
              cantidadFinal = cantidadFinal + 1;
            }
          }
          if (datos.subtotal) {
            subtotal.push({
              Fecha: "SUBTOTAL",
              TipoComprobante: null,
              Comprobante: cantidadCorte,
              Periodo: null,
              NumeroAfiliado: null,
              NumeroTitular: null,
              NombreRazonSocial: null,
              Total: Number(totalCorte).toLocaleString("en-US", {
                minimumFractionDigits: 2,
              }),
              Retenciones: Number(retencionesCorte).toLocaleString("en-US", {
                minimumFractionDigits: 2,
              }),
              Cobrador: null,
              Empresa: "\n",
              Periodo: null,
              Rendicion: null,
              Valor: null,
            });
          }
          subtotal.push({
            Fecha: "TOTAL",
            TipoComprobante: null,
            Comprobante: cantidadFinal,
            Periodo: null,
            NumeroAfiliado: null,
            NumeroTitular: null,
            NombreRazonSocial: null,
            Total: Number(totalFinal).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            }),
            Retenciones: Number(retencionesFinal).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            }),
            Cobrador: null,
            Empresa: null,
            Periodo: null,
            Rendicion: null,
            Valor: null,
          });
          guardarListadoExcel(subtotal);
        } else {
          guardarListadoExcel(local);
        }
      }

      let retencionesGrupo = 0;
      let totalGrupo = 0;
      let retencionesFinal = 0;
      let totalFinal = 0;
      let final = [];
      let cantidad = 0;
      let cantidadTotal = 0;

      if (datos && datos.tipoListado === "Grupo") {
        for (var i = 0; i < data.length; i++) {
          local.push({
            Fecha: formatDate(data[i].fecha),
            Cobrador: data[i].cobradorId,
            Empresa: data[i].empresaId,
            Afiliado: data[i].titularId,
            total:
              data[i].valor !== "retenciones"
                ? Number(data[i].importeTotalPesos)
                : null,
            retenciones:
              data[i].valor === "retenciones"
                ? Number(data[i].importeTotalPesos)
                : null,
          });
        }

        const groupBy = (prop) => (data) => {
          return data.reduce((dict, item) => {
            const { [prop]: _, ...rest } = item;
            dict[item[prop]] = [...(dict[item[prop]] || []), rest];
            return dict;
          }, {});
        };

        //var result = groupBy(serviciosFinalizadosExcel, 'Antecedente');

        let campo = datos.grupo.nombre;

        const result = Object.entries(groupBy(campo)(local)).map(
          ([key, value]) => ({ nombre: key, datos: value })
        );

        for (var i = 0; i < result.length; i++) {
          totalGrupo = 0;
          retencionesGrupo = 0;
          cantidad = 0;
          for (var j = 0; j < result[i].datos.length; j++) {
            totalGrupo = totalGrupo + Number(result[i].datos[j].total);
            retencionesGrupo =
              retencionesGrupo + Number(result[i].datos[j].retenciones);
            cantidad = cantidad + 1;
          }

          totalFinal = totalFinal + Number(totalGrupo);
          retencionesFinal = retencionesFinal + Number(retencionesGrupo);
          cantidadTotal = cantidadTotal + cantidad;

          final.push({
            descripcion: result[i].nombre,
            cantidad,
            total:
              datos.exportType === "pdf"
                ? Number(totalGrupo).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })
                : Number(totalGrupo),
            retenciones:
              datos.exportType === "pdf"
                ? Number(retencionesGrupo).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })
                : Number(retencionesGrupo),
          });
        }

        final.push({
          descripcion: "TOTAL",
          cantidad: cantidadTotal,
          total:
            datos.exportType === "pdf"
              ? Number(totalFinal).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })
              : Number(totalFinal),
          retenciones:
            datos.exportType === "pdf"
              ? Number(retencionesFinal).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })
              : Number(retencionesFinal),
        });
        guardarListadoExcel(final);
      }
    } catch (error) {
      //guardarMovimientosCompensaciones([]);
      console.log(error.response);
      mensajeAlerta("Cobranzas Realizadas", error.response.data, "error");
    }
  };

  const obtenerCobranzasPendientes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercobranzaspendientes",
        datos
      );
      let data = respuesta.data;
      let local = [];
      let total = 0;
      let saldo = 0;

      if (!datos.grupo || datos.grupo === "") {
        for (var i = 0; i < data.length; i++) {
          total = total + Number(data[i].total);
          saldo = saldo + Number(data[i].saldo);

          local.push({
            fecha: formatDate(data[i].fecha),
            comprobante:
              data[i].comprobante +
              "-" +
              data[i].puntoventa +
              "-" +
              data[i].numero,
            afiliadoId: data[i].id_afiliado,
            titularId: data[i].id_titular,
            nombre_razonsocial: data[i].nombre_titular,
            domicilio: data[i].afiliados.domicilio,
            telefono: data[i].afiliados.telefono,
            celular: data[i].afiliados.celular,
            total:
              datos.exportType === "pdf"
                ? Number(data[i].total).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })
                : Number(data[i].total),
            saldo:
              datos.exportType === "pdf"
                ? Number(data[i].saldo).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })
                : Number(data[i].saldo),
            cobrador: data[i].id_cobrador,
            empresa: data[i].empresas.nombre,
          });
        }
        if (datos.exportType === "pdf") {
          local.push({
            fecha: "TOTAL",
            comprobante: null,
            afiliadoId: null,
            titularId: null,
            nombre_razonsocial: null,
            domicilio: null,
            telefono: null,
            celular: null,
            total: Number(total).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            }),
            saldo: Number(saldo).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            }),
            cobrador: null,
            empresa: null,
          });
        }
        guardarListadoExcel(local);
      }

      let totalGrupo = 0;
      let saldoGrupo = 0;
      let totalFinal = 0;
      let saldoFinal = 0;
      let final = [];

      if (datos.grupo && datos.grupo !== "") {
        for (var i = 0; i < data.length; i++) {
          total = total + Number(data[i].total);
          saldo = saldo + Number(data[i].saldo);

          local.push({
            Fecha: formatDate(data[i].fecha),
            Comprobante: data[i].comprobante,
            Afiliado: data[i].id_titular,
            total: Number(data[i].total),
            saldo: Number(data[i].saldo),
            Cobrador: data[i].id_cobrador,
          });
        }

        const groupBy = (prop) => (data) => {
          return data.reduce((dict, item) => {
            const { [prop]: _, ...rest } = item;
            dict[item[prop]] = [...(dict[item[prop]] || []), rest];
            return dict;
          }, {});
        };

        //var result = groupBy(serviciosFinalizadosExcel, 'Antecedente');

        let campo = datos.grupo.nombre;

        const result = Object.entries(groupBy(campo)(local)).map(
          ([key, value]) => ({ nombre: key, datos: value })
        );

        for (var i = 0; i < result.length; i++) {
          totalFinal = 0;
          saldoFinal = 0;
          for (var j = 0; j < result[i].datos.length; j++) {
            totalGrupo = totalGrupo + Number(result[i].datos[j].total);
            saldoGrupo = saldoGrupo + Number(result[i].datos[j].saldo);
          }
          totalFinal = totalFinal + Number(totalGrupo);
          saldoFinal = saldoFinal + Number(saldoGrupo);

          final.push({
            descripcion: result[i].nombre,
            total:
              datos.exportType === "pdf"
                ? Number(totalGrupo).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })
                : Number(totalGrupo),
            saldo:
              datos.exportType === "pdf"
                ? Number(saldoGrupo).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })
                : Number(saldoGrupo),
          });
        }

        final.push({
          descripcion: "TOTAL",
          total:
            datos.exportType === "pdf"
              ? Number(totalFinal).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })
              : Number(totalFinal),
          saldo:
            datos.exportType === "pdf"
              ? Number(saldoFinal).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })
              : Number(saldoFinal),
        });
        guardarListadoExcel(final);
      }
    } catch (error) {
      //guardarMovimientosCompensaciones([]);
      console.log(error.response);
      mensajeAlerta("Cobranzas Pendientes", error.response.data, "error");
    }
  };

  const obtenerCarteraAltas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercarteraaltas",
        datos
      );

      let data = respuesta.data;
      let local = [];
      let cantidad = 0;
      let totalCuota = 0;

      if (!datos.grupo || datos.grupo === "") {
        for (var i = 0; i < data.length; i++) {
          let titular = data.find(
            (item) =>
              item.id_titular === data[i].id_titular && item.titular === 1
          );

          if (data[i].titular) {
            totalCuota =
              totalCuota + Number(parseFloat(data[i].cuota).toFixed(2));
          }
          cantidad = cantidad + 1;
          local.push({
            id: data[i].id,
            id_titular: data[i].id_titular,
            titular: data[i].titular ? "SI" : "NO",
            nombre_razonsocial: data[i].nombre_razonsocial,
            domicilio: data[i].domicilio,
            telefono: data[i].telefono1,
            celular: data[i].celular,
            email: data[i].email,
            localidad: data[i].localidad,
            grupo: titular.grupo,
            rubro: titular.rubro,
            empresa: titular.empresa,
            plan: titular.plan,
            asesor: titular.asesor,
            cobrador: titular.cobrador,
            vigencia: data[i].vigencia,
            cuota: data[i].titular
              ? parseFloat(data[i].cuota).toFixed(2)
              : parseFloat(0).toFixed(2),
            numerodocumento: data[i].numerodocumento,
            suspendido: titular.suspendido ? "SI" : "NO",
          });
        }
        if (datos.exportType === "pdf") {
          local.push({
            id: null,
            id_titular: null,
            titular: null,
            nombre_razonsocial: null,
            domicilio: null,
            telefono: null,
            celular: null,
            email: null,
            localidad: null,
            grupo: null,
            rubro: null,
            empresa: null,
            plan: null,
            asesor: cantidad,
            cobrador: null,
            vigencia: null,
            cuota: parseFloat(totalCuota).toFixed(2),
            numerodocumento: null,
            suspendido: null,
          });
        }
        guardarListadoExcel(local);
      }

      let titulares = [];
      let final = [];
      let capitas = 0;
      let totalCapitas = 0;
      let totalCuotas = 0;
      let totalFinalGrupos = 0;
      let totalFinalCapitas = 0;
      let totalFinalCuotas = 0;

      if (datos.grupo && datos.grupo !== "") {
        for (var i = 0; i < data.length; i++) {
          let titular = data.find(
            (item) =>
              item.id_titular === data[i].id_titular && item.titular === 1
          );

          if (data[i].titular) {
            capitas = data.filter(
              (item) => item.id_titular === data[i].id_titular
            ).length;
          }

          local.push({
            id: data[i].id,
            id_titular: data[i].id_titular,
            titular: data[i].titular ? "SI" : "NO",
            Grupo: titular.grupo,
            Rubro: titular.rubro,
            Empresa: titular.empresa,
            Plan: titular.plan,
            Asesor: titular.asesor,
            Cobrador: titular.cobrador,
            cuota: data[i].titular
              ? parseFloat(data[i].cuota).toFixed(2)
              : parseFloat(0).toFixed(2),
            capitas: capitas,
          });
        }

        titulares = local.filter((item) => item.titular === "SI");

        const groupBy = (prop) => (data) => {
          return data.reduce((dict, item) => {
            const { [prop]: _, ...rest } = item;
            dict[item[prop]] = [...(dict[item[prop]] || []), rest];
            return dict;
          }, {});
        };

        //var result = groupBy(serviciosFinalizadosExcel, 'Antecedente');

        let campo = datos.grupo.nombre;

        const result = Object.entries(groupBy(campo)(titulares)).map(
          ([key, value]) => ({ nombre: key, datos: value })
        );

        for (var i = 0; i < result.length; i++) {
          totalCapitas = 0;
          totalCuotas = 0;
          for (var j = 0; j < result[i].datos.length; j++) {
            totalCapitas = totalCapitas + Number(result[i].datos[j].capitas);
            totalCuotas = totalCuotas + Number(result[i].datos[j].cuota);
          }
          totalFinalGrupos = totalFinalGrupos + Number(result[i].datos.length);
          totalFinalCapitas = totalFinalCapitas + Number(totalCapitas);
          totalFinalCuotas = totalFinalCuotas + Number(totalCuotas);
          final.push({
            descripcion: result[i].nombre,
            grupos: result[i].datos.length,
            capitas: totalCapitas,
            cuotas: parseFloat(totalCuotas).toFixed(2),
          });
        }

        final.push({
          descripcion: "TOTAL",
          grupos: totalFinalGrupos,
          capitas: totalFinalCapitas,
          cuotas: parseFloat(totalFinalCuotas).toFixed(2),
        });
        guardarListadoExcel(final);
      }
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Listado de Cartera", error.response.data, "error");
    }
  };

  const obtenerBajas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerbajas",
        datos
      );

      let data = respuesta.data.response;
      let data2 = respuesta.data.response2;

      let local = [];
      let cantidad = 0;
      let totalCuota = 0;
      let saldo = 0;
      let totalSaldo = 0;

      function calcularSaldo(titularId) {
        let saldo = 0;
        for (var i = 0; i < data2.length; i++) {
          if (data2[i].id_titular === titularId) {
            if (
              data2[i].id_comprobanteafip === 1 ||
              data2[i].id_comprobanteafip === 2 ||
              data2[i].id_comprobanteafip === 6 ||
              data2[i].id_comprobanteafip === 7 ||
              data2[i].id_comprobanteafip === 11 ||
              data2[i].id_comprobanteafip === 12 ||
              data2[i].id_comprobanteafip === 51 ||
              data2[i].id_comprobanteafip === 52 ||
              data2[i].id_comprobanteafip === 201 ||
              data2[i].id_comprobanteafip === 202 ||
              data2[i].id_comprobanteafip === 206 ||
              data2[i].id_comprobanteafip === 207 ||
              data2[i].id_comprobanteafip === 211 ||
              data2[i].id_comprobanteafip === 212 ||
              data2[i].id_comprobanteafip === 1000
            ) {
              saldo =
                Number(saldo) + Number(parseFloat(data2[i].saldo).toFixed(2));
            }
            if (
              data2[i].id_comprobanteafip === 3 ||
              data2[i].id_comprobanteafip === 8 ||
              data2[i].id_comprobanteafip === 13 ||
              data2[i].id_comprobanteafip === 53 ||
              data2[i].id_comprobanteafip === 203 ||
              data2[i].id_comprobanteafip === 208 ||
              data2[i].id_comprobanteafip === 213 ||
              data2[i].id_comprobanteafip === 1001 ||
              data2[i].id_comprobanteafip === 2000 ||
              data2[i].id_comprobanteafip === 3000
            ) {
              saldo = Number(saldo) - Number(data2[i].saldo);
            }
          }
        }
        return saldo;
      }

      if (!datos.grupo || datos.grupo === "") {
        for (var i = 0; i < data.length; i++) {
          let titular = data.find(
            (item) =>
              item.id_titular === data[i].id_titular && item.titular === 1
          );

          saldo = 0;
          if (data[i].titular) {
            totalCuota =
              totalCuota + Number(parseFloat(data[i].cuota).toFixed(2));

            saldo = calcularSaldo(data[i].id_titular);

            totalSaldo = totalSaldo + Number(parseFloat(saldo).toFixed(2));
          }
          cantidad = cantidad + 1;
          local.push({
            id: data[i].id,
            id_titular: data[i].id_titular,
            titular: data[i].titular ? "SI" : "NO",
            nombre_razonsocial: data[i].nombre_razonsocial,
            domicilio: data[i].domicilio,
            telefono: data[i].telefono1,
            celular: data[i].celular,
            email: data[i].email,
            localidad: data[i].localidad,
            grupo: titular.grupo,
            rubro: titular.rubro,
            empresa: titular.empresa,
            plan: titular.plan,
            asesor: titular.asesor,
            cobrador: titular.cobrador,
            vigencia: data[i].vigencia,
            cuota: data[i].titular
              ? parseFloat(data[i].cuota).toFixed(2)
              : parseFloat(0).toFixed(2),
            saldo: data[i].titular
              ? parseFloat(saldo).toFixed(2)
              : parseFloat(0).toFixed(2),
            numerodocumento: data[i].numerodocumento,
            fhabilitado: formatDate(data[i].fhabilitado),
            id_motivobaja: data[i].id_motivobaja,
            motivobaja: titular.motivobaja,
          });
        }
        if (datos.exportType === "pdf") {
          local.push({
            id: null,
            id_titular: null,
            titular: null,
            nombre_razonsocial: null,
            domicilio: null,
            telefono: null,
            celular: null,
            email: null,
            localidad: null,
            grupo: null,
            rubro: null,
            empresa: null,
            plan: null,
            asesor: cantidad,
            cobrador: null,
            vigencia: null,
            cuota: parseFloat(totalCuota).toFixed(2),
            saldo: parseFloat(totalSaldo).toFixed(2),
            numerodocumento: null,
            fhabilitado: null,
            id_motivobaja: null,
            motivobaja: null,
          });
        }
        guardarListadoExcel(local);
      }

      let titulares = [];
      let final = [];
      let capitas = 0;
      let totalCapitas = 0;
      let totalCuotas = 0;
      let totalSaldos = 0;
      let totalFinalGrupos = 0;
      let totalFinalCapitas = 0;
      let totalFinalCuotas = 0;
      let totalFinalSaldos = 0;

      if (datos.grupo && datos.grupo !== "") {
        for (var i = 0; i < data.length; i++) {
          let titular = data.find(
            (item) =>
              item.id_titular === data[i].id_titular && item.titular === 1
          );

          saldo = 0;
          if (data[i].titular) {
            capitas = data.filter(
              (item) => item.id_titular === data[i].id_titular
            ).length;
            saldo = calcularSaldo(data[i].id_titular);
          }

          local.push({
            id: data[i].id,
            id_titular: data[i].id_titular,
            titular: data[i].titular ? "SI" : "NO",
            Grupo: titular.grupo,
            Rubro: titular.rubro,
            Empresa: titular.empresa,
            Plan: titular.plan,
            Asesor: titular.asesor,
            Cobrador: titular.cobrador,
            Motivo: titular.motivobaja,
            cuota: data[i].titular
              ? parseFloat(data[i].cuota).toFixed(2)
              : parseFloat(0).toFixed(2),
            saldo: data[i].titular
              ? parseFloat(saldo).toFixed(2)
              : parseFloat(0).toFixed(2),
            capitas: capitas,
          });
        }

        titulares = local.filter((item) => item.titular === "SI");

        const groupBy = (prop) => (data) => {
          return data.reduce((dict, item) => {
            const { [prop]: _, ...rest } = item;
            dict[item[prop]] = [...(dict[item[prop]] || []), rest];
            return dict;
          }, {});
        };

        //var result = groupBy(serviciosFinalizadosExcel, 'Antecedente');

        let campo = datos.grupo.nombre;

        const result = Object.entries(groupBy(campo)(titulares)).map(
          ([key, value]) => ({ nombre: key, datos: value })
        );

        for (var i = 0; i < result.length; i++) {
          totalCapitas = 0;
          totalCuotas = 0;
          totalSaldos = 0;
          for (var j = 0; j < result[i].datos.length; j++) {
            totalCapitas = totalCapitas + Number(result[i].datos[j].capitas);
            totalCuotas = totalCuotas + Number(result[i].datos[j].cuota);
            totalSaldos = totalSaldos + Number(result[i].datos[j].saldo);
          }
          totalFinalGrupos = totalFinalGrupos + Number(result[i].datos.length);
          totalFinalCapitas = totalFinalCapitas + Number(totalCapitas);
          totalFinalCuotas = totalFinalCuotas + Number(totalCuotas);
          totalFinalSaldos = totalFinalSaldos + Number(totalSaldos);
          final.push({
            descripcion: result[i].nombre,
            grupos: result[i].datos.length,
            capitas: totalCapitas,
            cuotas: parseFloat(totalCuotas).toFixed(2),
            saldos: parseFloat(totalSaldos).toFixed(2),
          });
        }

        final.push({
          descripcion: "TOTAL",
          grupos: totalFinalGrupos,
          capitas: totalFinalCapitas,
          cuotas: parseFloat(totalFinalCuotas).toFixed(2),
          saldos: parseFloat(totalFinalSaldos).toFixed(2),
        });
        guardarListadoExcel(final);
      }
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Listado de Cartera", error.response.data, "error");
    }
  };

  const obtenerIvaVentas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerivaventas",
        datos
      );

      let data = respuesta.data;

      //console.log(data);

      let local = [];
      let totalNeto = 0;
      let totalIva1 = 0;
      let totalIva2 = 0;
      let totalIva3 = 0;
      let totalExento = 0;
      let total = 0;
      let totalPercArba = 0;
      let totalPercAgip = 0;

      if (!datos.grupo || datos.grupo === "") {
        for (var i = 0; i < data.length; i++) {
          if (
            data[i].id_comprobanteafip === 3 ||
            data[i].id_comprobanteafip === 8 ||
            data[i].id_comprobanteafip === 13 ||
            data[i].id_comprobanteafip === 53 ||
            data[i].id_comprobanteafip === 203 ||
            data[i].id_comprobanteafip === 208 ||
            data[i].id_comprobanteafip === 213 ||
            data[i].id_comprobanteafip === 1001 ||
            data[i].id_comprobanteafip === 2000 ||
            data[i].id_comprobanteafip === 3000
          ) {
            data[i].subtotal = -data[i].subtotal;
            data[i].iva1 = -data[i].iva1;
            data[i].iva2 = -data[i].iva2;
            data[i].iva3 = -data[i].iva3;
            data[i].total = -data[i].total;
            data[i].percarba = -data[i].percarba;
            data[i].percagip = -data[i].percagip;
          }
          if (Number(data[i].iva) > 0) {
            totalNeto =
              totalNeto + Number(parseFloat(data[i].subtotal).toFixed(2));
          } else {
            totalExento =
              totalExento + Number(parseFloat(data[i].subtotal).toFixed(2));
          }
          totalIva1 = totalIva1 + Number(parseFloat(data[i].iva1).toFixed(2));
          totalIva2 = totalIva2 + Number(parseFloat(data[i].iva2).toFixed(2));
          totalIva3 = totalIva3 + Number(parseFloat(data[i].iva3).toFixed(2));
          total = total + Number(parseFloat(data[i].total).toFixed(2));
          totalPercArba =
            totalPercArba + Number(parseFloat(data[i].percarba).toFixed(2));
          totalPercAgip =
            totalPercAgip + Number(parseFloat(data[i].percagip).toFixed(2));

          local.push({
            id: data[i].id,
            fecha: formatDate(data[i].fecha),
            comprobante:
              data[i].comprobante +
              "-" +
              data[i].puntoventa +
              "-" +
              data[i].numero,
            nombre_razonsocial: data[i].nombre_titular,
            numerodocumento: data[i].afiliados.numerodocumento,
            condicion: data[i].tiposcontribuyentes.nombre,
            neto:
              Number(data[i].iva) > 0
                ? parseFloat(data[i].subtotal).toFixed(2)
                : parseFloat(0).toFixed(2),
            exento:
              Number(data[i].iva) > 0
                ? parseFloat(0).toFixed(2)
                : parseFloat(data[i].subtotal).toFixed(2),
            iva105: parseFloat(data[i].iva1).toFixed(2),
            iva21: parseFloat(data[i].iva2).toFixed(2),
            iva27: parseFloat(data[i].iva3).toFixed(2),
            percarba: parseFloat(data[i].percarba).toFixed(2),
            percagip: parseFloat(data[i].percagip).toFixed(2),
            total: parseFloat(data[i].total).toFixed(2),
            localidad: data[i].afiliados.localidades.nombre,
          });
        }
        if (datos.exportType === "pdf") {
          local.push({
            id: null,
            fecha: null,
            comprobante: null,
            nombre_razonsocial: null,
            numerodocumento: null,
            condicion: null,
            neto: parseFloat(totalNeto).toFixed(2),
            exento: parseFloat(totalExento).toFixed(2),
            iva105: parseFloat(totalIva1).toFixed(2),
            iva21: parseFloat(totalIva2).toFixed(2),
            iva27: parseFloat(totalIva3).toFixed(2),
            percarba: parseFloat(totalPercArba).toFixed(2),
            percagip: parseFloat(totalPercAgip).toFixed(2),
            total: parseFloat(total).toFixed(2),
            localidad: null,
          });
        }
        guardarListadoExcel(local);
      }

      let final = [];
      let finalNeto = 0;
      let finalIva1 = 0;
      let finalIva2 = 0;
      let finalIva3 = 0;
      let finalExento = 0;
      let finalTotal = 0;
      let finalPercArba = 0;
      let finalPercAgip = 0;

      if (datos.grupo && datos.grupo !== "") {
        for (var i = 0; i < data.length; i++) {
          if (
            data[i].id_comprobanteafip === 3 ||
            data[i].id_comprobanteafip === 8 ||
            data[i].id_comprobanteafip === 13 ||
            data[i].id_comprobanteafip === 53 ||
            data[i].id_comprobanteafip === 203 ||
            data[i].id_comprobanteafip === 208 ||
            data[i].id_comprobanteafip === 213 ||
            data[i].id_comprobanteafip === 1001 ||
            data[i].id_comprobanteafip === 2000 ||
            data[i].id_comprobanteafip === 3000
          ) {
            data[i].subtotal = -data[i].subtotal;
            data[i].iva1 = -data[i].iva1;
            data[i].iva2 = -data[i].iva2;
            data[i].iva3 = -data[i].iva3;
            data[i].total = -data[i].total;
            data[i].percarba = -data[i].percarba;
            data[i].percagip = -data[i].percagip;
          }

          local.push({
            Id: data[i].id,
            Fecha: formatDate(data[i].fecha),
            Comprobante: data[i].comprobante,
            PuntoVenta: data[i].puntoventa,
            Numero: data[i].numero,
            Afiliado: data[i].id_titular,
            Nombre_razonsocial: data[i].nombre_titular,
            Numerodocumento: data[i].afiliados.numerodocumento,
            Condicion: data[i].tiposcontribuyentes.nombre,
            Neto:
              Number(data[i].iva) > 0
                ? parseFloat(data[i].subtotal).toFixed(2)
                : parseFloat(0).toFixed(2),
            Exento:
              Number(data[i].iva) > 0
                ? parseFloat(0).toFixed(2)
                : parseFloat(data[i].subtotal).toFixed(2),
            Iva105: parseFloat(data[i].iva1).toFixed(2),
            Iva21: parseFloat(data[i].iva2).toFixed(2),
            Iva27: parseFloat(data[i].iva3).toFixed(2),
            Percarba: parseFloat(data[i].percarba).toFixed(2),
            Percagip: parseFloat(data[i].percagip).toFixed(2),
            Total: parseFloat(data[i].total).toFixed(2),
            Localidad: data[i].afiliados.localidades.nombre,
          });
        }

        const groupBy = (prop) => (data) => {
          return data.reduce((dict, item) => {
            const { [prop]: _, ...rest } = item;
            dict[item[prop]] = [...(dict[item[prop]] || []), rest];
            return dict;
          }, {});
        };

        //var result = groupBy(serviciosFinalizadosExcel, 'Antecedente');

        let campo = datos.grupo.nombre;

        const result = Object.entries(groupBy(campo)(local)).map(
          ([key, value]) => ({ nombre: key, datos: value })
        );

        for (var i = 0; i < result.length; i++) {
          totalNeto = 0;
          totalIva1 = 0;
          totalIva2 = 0;
          totalIva3 = 0;
          totalExento = 0;
          total = 0;
          totalPercArba = 0;
          totalPercAgip = 0;
          for (var j = 0; j < result[i].datos.length; j++) {
            totalNeto =
              totalNeto +
              Number(parseFloat(result[i].datos[j].Neto).toFixed(2));
            totalIva1 =
              totalIva1 +
              Number(parseFloat(result[i].datos[j].Iva105).toFixed(2));
            totalIva2 =
              totalIva2 +
              Number(parseFloat(result[i].datos[j].Iva21).toFixed(2));
            totalIva3 =
              totalIva3 +
              Number(parseFloat(result[i].datos[j].Iva27).toFixed(2));
            totalExento =
              totalExento +
              Number(parseFloat(result[i].datos[j].Exento).toFixed(2));
            total =
              total + Number(parseFloat(result[i].datos[j].Total).toFixed(2));
            totalPercArba =
              totalPercArba +
              Number(parseFloat(result[i].datos[j].Percarba).toFixed(2));
            totalPercAgip =
              totalPercAgip +
              Number(parseFloat(result[i].datos[j].Percagip).toFixed(2));
          }
          finalNeto = finalNeto + Number(totalNeto);
          finalIva1 = finalIva1 + Number(totalIva1);
          finalIva2 = finalIva2 + Number(totalIva2);
          finalIva3 = finalIva3 + Number(totalIva3);
          finalExento = finalExento + Number(totalExento);
          finalTotal = finalTotal + Number(total);
          finalPercArba = finalPercArba + Number(totalPercArba);
          finalPercAgip = finalPercAgip + Number(totalPercAgip);

          final.push({
            descripcion: result[i].nombre,
            Neto: parseFloat(totalNeto).toFixed(2),
            Exento: parseFloat(totalExento).toFixed(2),
            Iva105: parseFloat(totalIva1).toFixed(2),
            Iva21: parseFloat(totalIva2).toFixed(2),
            Iva27: parseFloat(totalIva3).toFixed(2),
            Percarba: parseFloat(totalPercArba).toFixed(2),
            Percagip: parseFloat(totalPercAgip).toFixed(2),
            Total: parseFloat(total).toFixed(2),
          });
        }

        final.push({
          descripcion: "TOTAL",
          Neto: parseFloat(finalNeto).toFixed(2),
          Exento: parseFloat(finalExento).toFixed(2),
          Iva105: parseFloat(finalIva1).toFixed(2),
          Iva21: parseFloat(finalIva2).toFixed(2),
          Iva27: parseFloat(finalIva3).toFixed(2),
          Percarba: parseFloat(finalPercArba).toFixed(2),
          Percagip: parseFloat(finalPercAgip).toFixed(2),
          Total: parseFloat(finalTotal).toFixed(2),
        });
        guardarListadoExcel(final);
      }
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Subdiario Iva Ventas", error.response.data, "error");
    }
  };

  const obtenerIvaCompras = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerivacompras",
        datos
      );

      let data = respuesta.data;

      let local = [];
      let totalExento = 0;
      let totalNeto1 = 0;
      let totalNeto2 = 0;
      let totalNeto3 = 0;
      let totalIva1 = 0;
      let totalIva2 = 0;
      let totalIva3 = 0;
      let totalPercArba = 0;
      let totalPercAgip = 0;
      let totalPercIva = 0;
      let totalPercGanancias = 0;
      let totalRetGanancias = 0;
      let totalRetIva = 0;
      let totalRetIIBB = 0;
      let totalOtros = 0;
      let total = 0;

      if (!datos.grupo || datos.grupo === "") {
        for (var i = 0; i < data.length; i++) {
          if (
            data[i].id_comprobanteafip === 3 ||
            data[i].id_comprobanteafip === 8 ||
            data[i].id_comprobanteafip === 13 ||
            data[i].id_comprobanteafip === 53 ||
            data[i].id_comprobanteafip === 203 ||
            data[i].id_comprobanteafip === 208 ||
            data[i].id_comprobanteafip === 213 ||
            data[i].id_comprobanteafip === 1001 ||
            data[i].id_comprobanteafip === 2000 ||
            data[i].id_comprobanteafip === 3000
          ) {
            data[i].exento = -data[i].exento;
            data[i].neto105 = -data[i].neto105;
            data[i].neto21 = -data[i].neto21;
            data[i].neto27 = -data[i].neto27;
            data[i].iva1 = -data[i].iva1;
            data[i].iva2 = -data[i].iva2;
            data[i].iva3 = -data[i].iva3;
            data[i].total = -data[i].total;
            data[i].periibbbsas = -data[i].periibbbsas;
            data[i].periibbcaba = -data[i].periibbcaba;
            data[i].periva = -data[i].periva;
            data[i].perganancias = -data[i].perganancias;
            data[i].retganancias = -data[i].retganancias;
            data[i].retiva = -data[i].retiva;
            data[i].retiibb = -data[i].retiibb;
            data[i].otrostributos = -data[i].otrostributos;
          }
          totalExento =
            totalExento + Number(parseFloat(data[i].exento).toFixed(2));
          totalNeto1 =
            totalNeto1 + Number(parseFloat(data[i].neto105).toFixed(2));
          totalNeto2 =
            totalNeto2 + Number(parseFloat(data[i].neto21).toFixed(2));
          totalNeto3 =
            totalNeto3 + Number(parseFloat(data[i].neto27).toFixed(2));
          totalIva1 = totalIva1 + Number(parseFloat(data[i].iva1).toFixed(2));
          totalIva2 = totalIva2 + Number(parseFloat(data[i].iva2).toFixed(2));
          totalIva3 = totalIva3 + Number(parseFloat(data[i].iva3).toFixed(2));
          totalPercArba =
            totalPercArba + Number(parseFloat(data[i].periibbbsas).toFixed(2));
          totalPercAgip =
            totalPercAgip + Number(parseFloat(data[i].periibbcaba).toFixed(2));
          totalPercIva =
            totalPercIva + Number(parseFloat(data[i].periva).toFixed(2));
          totalPercGanancias =
            totalPercGanancias +
            Number(parseFloat(data[i].perganancias).toFixed(2));
          totalRetGanancias =
            totalRetGanancias +
            Number(parseFloat(data[i].retganancias).toFixed(2));
          totalRetIva =
            totalRetIva + Number(parseFloat(data[i].retiva).toFixed(2));
          totalRetIIBB =
            totalRetIIBB + Number(parseFloat(data[i].retiibb).toFixed(2));
          totalOtros =
            totalOtros + Number(parseFloat(data[i].otrostributos).toFixed(2));
          total = total + Number(parseFloat(data[i].total).toFixed(2));

          local.push({
            id: data[i].id,
            fechacomprobante: formatDate(data[i].fechacomprobante),
            fechacontabilizacion: formatDate(data[i].fechacontabilizacion),
            comprobante:
              data[i].comprobante +
              "-" +
              data[i].puntoventa +
              "-" +
              data[i].numero,
            nombre_razonsocial: data[i].nombre_proveedor,
            numerodocumento: data[i].cuit,
            condicion: data[i].tiposcontribuyentes.nombre,
            exento: parseFloat(data[i].exento).toFixed(2),
            neto105: parseFloat(data[i].neto105).toFixed(2),
            neto21: parseFloat(data[i].neto21).toFixed(2),
            neto27: parseFloat(data[i].neto27).toFixed(2),
            iva105: parseFloat(data[i].iva1).toFixed(2),
            iva21: parseFloat(data[i].iva2).toFixed(2),
            iva27: parseFloat(data[i].iva3).toFixed(2),
            percbsas: parseFloat(data[i].periibbbsas).toFixed(2),
            perccaba: parseFloat(data[i].periibbcaba).toFixed(2),
            perciva: parseFloat(data[i].periva).toFixed(2),
            percganancias: parseFloat(data[i].perganancias).toFixed(2),
            retiva: parseFloat(data[i].retiva).toFixed(2),
            retganancias: parseFloat(data[i].retganancias).toFixed(2),
            retiibb: parseFloat(data[i].retiibb).toFixed(2),
            otrostributos: parseFloat(data[i].otrostributos).toFixed(2),
            total: parseFloat(data[i].total).toFixed(2),
          });
        }

        if (datos.exportType === "pdf") {
          local.push({
            id: null,
            fechaComprobante: null,
            fechaContabilizacion: null,
            comprobante: null,
            nombre_razonsocial: null,
            numerodocumento: null,
            condicion: null,
            exento: parseFloat(totalExento).toFixed(2),
            neto105: parseFloat(totalNeto1).toFixed(2),
            neto21: parseFloat(totalNeto2).toFixed(2),
            neto27: parseFloat(totalNeto3).toFixed(2),
            iva105: parseFloat(totalIva1).toFixed(2),
            iva21: parseFloat(totalIva2).toFixed(2),
            iva27: parseFloat(totalIva3).toFixed(2),
            percbsas: parseFloat(totalPercArba).toFixed(2),
            perccaba: parseFloat(totalPercAgip).toFixed(2),
            perciva: parseFloat(totalPercIva).toFixed(2),
            percganancias: parseFloat(totalPercGanancias).toFixed(2),
            retiva: parseFloat(totalRetIva).toFixed(2),
            retganancias: parseFloat(totalRetGanancias).toFixed(2),
            retiibb: parseFloat(totalRetIIBB).toFixed(2),
            otrostributos: parseFloat(totalOtros).toFixed(2),
            total: parseFloat(total).toFixed(2),
          });
        }
        guardarListadoExcel(local);
      }

      let final = [];
      let finalExento = 0;
      let finalNeto1 = 0;
      let finalNeto2 = 0;
      let finalNeto3 = 0;
      let finalIva1 = 0;
      let finalIva2 = 0;
      let finalIva3 = 0;
      let finalPercArba = 0;
      let finalPercAgip = 0;
      let finalPercIva = 0;
      let finalPercGanancias = 0;
      let finalRetGanancias = 0;
      let finalRetIva = 0;
      let finalRetIIBB = 0;
      let finalOtros = 0;
      let finalTotal = 0;

      if (datos.grupo && datos.grupo !== "") {
        for (var i = 0; i < data.length; i++) {
          if (
            data[i].id_comprobanteafip === 3 ||
            data[i].id_comprobanteafip === 8 ||
            data[i].id_comprobanteafip === 13 ||
            data[i].id_comprobanteafip === 53 ||
            data[i].id_comprobanteafip === 203 ||
            data[i].id_comprobanteafip === 208 ||
            data[i].id_comprobanteafip === 213 ||
            data[i].id_comprobanteafip === 1001 ||
            data[i].id_comprobanteafip === 2000 ||
            data[i].id_comprobanteafip === 3000
          ) {
            data[i].exento = -data[i].exento;
            data[i].neto105 = -data[i].neto105;
            data[i].neto21 = -data[i].neto21;
            data[i].neto27 = -data[i].neto27;
            data[i].iva1 = -data[i].iva1;
            data[i].iva2 = -data[i].iva2;
            data[i].iva3 = -data[i].iva3;
            data[i].total = -data[i].total;
            data[i].periibbbsas = -data[i].periibbbsas;
            data[i].periibbcaba = -data[i].periibbcaba;
            data[i].periva = -data[i].periva;
            data[i].perganancias = -data[i].perganancias;
            data[i].retganancias = -data[i].retganancias;
            data[i].retiva = -data[i].retiva;
            data[i].retiibb = -data[i].retiibb;
            data[i].otrostributos = -data[i].otrostributos;
          }

          local.push({
            Id: data[i].id,
            Fechacomprobante: formatDate(data[i].fechacomprobante),
            Fechacontabilizacion: formatDate(data[i].fechacontabilizacion),
            Fecha: formatDate(data[i].fechacontabilizacion),
            Comprobante: data[i].comprobante,
            PuntoVenta: data[i].puntoventa,
            Numero: data[i].numero,
            Nombre_razonsocial: data[i].nombre_proveedor,
            Proveedor: data[i].id_proveedor,
            Numerodocumento: data[i].cuit,
            Condicion: data[i].tiposcontribuyentes.nombre,
            Exento: parseFloat(data[i].exento).toFixed(2),
            Neto105: parseFloat(data[i].neto105).toFixed(2),
            Neto21: parseFloat(data[i].neto21).toFixed(2),
            Neto27: parseFloat(data[i].neto27).toFixed(2),
            Iva105: parseFloat(data[i].iva1).toFixed(2),
            Iva21: parseFloat(data[i].iva2).toFixed(2),
            Iva27: parseFloat(data[i].iva3).toFixed(2),
            Percbsas: parseFloat(data[i].periibbbsas).toFixed(2),
            Perccaba: parseFloat(data[i].periibbcaba).toFixed(2),
            Perciva: parseFloat(data[i].periva).toFixed(2),
            Percganancias: parseFloat(data[i].perganancias).toFixed(2),
            Retiva: parseFloat(data[i].retiva).toFixed(2),
            Retganancias: parseFloat(data[i].retganancias).toFixed(2),
            Retiibb: parseFloat(data[i].retiibb).toFixed(2),
            Otrostributos: parseFloat(data[i].otrostributos).toFixed(2),
            Total: parseFloat(data[i].total).toFixed(2),
          });
        }

        const groupBy = (prop) => (data) => {
          return data.reduce((dict, item) => {
            const { [prop]: _, ...rest } = item;
            dict[item[prop]] = [...(dict[item[prop]] || []), rest];
            return dict;
          }, {});
        };

        //var result = groupBy(serviciosFinalizadosExcel, 'Antecedente');

        let campo = datos.grupo.nombre;

        const result = Object.entries(groupBy(campo)(local)).map(
          ([key, value]) => ({ nombre: key, datos: value })
        );

        for (var i = 0; i < result.length; i++) {
          totalExento = 0;
          totalNeto1 = 0;
          totalNeto2 = 0;
          totalNeto3 = 0;
          totalIva1 = 0;
          totalIva2 = 0;
          totalIva3 = 0;
          totalPercArba = 0;
          totalPercAgip = 0;
          totalPercIva = 0;
          totalPercGanancias = 0;
          totalRetGanancias = 0;
          totalRetIva = 0;
          totalRetIIBB = 0;
          totalOtros = 0;
          total = 0;

          for (var j = 0; j < result[i].datos.length; j++) {
            totalExento =
              totalExento + Number(parseFloat(data[i].exento).toFixed(2));
            totalNeto1 =
              totalNeto1 + Number(parseFloat(data[i].neto105).toFixed(2));
            totalNeto2 =
              totalNeto2 + Number(parseFloat(data[i].neto21).toFixed(2));
            totalNeto3 =
              totalNeto3 + Number(parseFloat(data[i].neto27).toFixed(2));
            totalIva1 = totalIva1 + Number(parseFloat(data[i].iva1).toFixed(2));
            totalIva2 = totalIva2 + Number(parseFloat(data[i].iva2).toFixed(2));
            totalIva3 = totalIva3 + Number(parseFloat(data[i].iva3).toFixed(2));
            totalPercArba =
              totalPercArba +
              Number(parseFloat(data[i].periibbbsas).toFixed(2));
            totalPercAgip =
              totalPercAgip +
              Number(parseFloat(data[i].periibbcaba).toFixed(2));
            totalPercIva =
              totalPercIva + Number(parseFloat(data[i].periva).toFixed(2));
            totalPercGanancias =
              totalPercGanancias +
              Number(parseFloat(data[i].perganancias).toFixed(2));
            totalRetGanancias =
              totalRetGanancias +
              Number(parseFloat(data[i].retganancias).toFixed(2));
            totalRetIva =
              totalRetIva + Number(parseFloat(data[i].retiva).toFixed(2));
            totalRetIIBB =
              totalRetIIBB + Number(parseFloat(data[i].retiibb).toFixed(2));
            totalOtros =
              totalOtros + Number(parseFloat(data[i].otrostributos).toFixed(2));
            total = total + Number(parseFloat(data[i].total).toFixed(2));
          }

          finalExento = finalExento + Number(totalExento);
          finalNeto1 = finalNeto1 + Number(totalNeto1);
          finalNeto2 = finalNeto2 + Number(totalNeto2);
          finalNeto3 = finalNeto3 + Number(totalNeto3);
          finalIva1 = finalIva1 + Number(totalIva1);
          finalIva2 = finalIva2 + Number(totalIva2);
          finalIva3 = finalIva3 + Number(totalIva3);
          finalPercArba = finalPercArba + Number(totalPercArba);
          finalPercAgip = finalPercAgip + Number(totalPercAgip);
          finalPercIva = finalPercIva + Number(totalPercIva);
          finalPercGanancias = finalPercGanancias + Number(totalPercGanancias);
          finalRetIva = finalRetIva + Number(totalRetIva);
          finalRetGanancias = finalRetGanancias + Number(totalRetGanancias);
          finalRetIIBB = finalRetIIBB + Number(totalRetIIBB);
          finalOtros = finalOtros + Number(totalOtros);
          finalTotal = finalTotal + Number(total);

          final.push({
            Descripcion: result[i].nombre,
            Exento: parseFloat(totalExento).toFixed(2),
            Neto105: parseFloat(totalNeto1).toFixed(2),
            Neto21: parseFloat(totalNeto2).toFixed(2),
            Neto27: parseFloat(totalNeto3).toFixed(2),
            Iva105: parseFloat(totalIva1).toFixed(2),
            Iva21: parseFloat(totalIva2).toFixed(2),
            Iva27: parseFloat(totalIva3).toFixed(2),
            Percbsas: parseFloat(totalPercArba).toFixed(2),
            Perccaba: parseFloat(totalPercAgip).toFixed(2),
            Perciva: parseFloat(totalPercIva).toFixed(2),
            Percganancias: parseFloat(totalPercGanancias).toFixed(2),
            Retiva: parseFloat(totalRetIva).toFixed(2),
            Retganancias: parseFloat(totalRetGanancias).toFixed(2),
            Retiibb: parseFloat(totalRetIIBB).toFixed(2),
            Otrostributos: parseFloat(totalOtros).toFixed(2),
            Total: parseFloat(total).toFixed(2),
          });
        }

        final.push({
          Descripcion: "TOTAL",
          Exento: parseFloat(finalExento).toFixed(2),
          Neto105: parseFloat(finalNeto1).toFixed(2),
          Neto21: parseFloat(finalNeto2).toFixed(2),
          Neto27: parseFloat(finalNeto3).toFixed(2),
          Iva105: parseFloat(finalIva1).toFixed(2),
          Iva21: parseFloat(finalIva2).toFixed(2),
          Iva27: parseFloat(finalIva3).toFixed(2),
          Percbsas: parseFloat(finalPercArba).toFixed(2),
          Perccaba: parseFloat(finalPercAgip).toFixed(2),
          Perciva: parseFloat(finalPercIva).toFixed(2),
          Percganancias: parseFloat(finalPercGanancias).toFixed(2),
          Retiva: parseFloat(finalRetIva).toFixed(2),
          Retganancias: parseFloat(finalRetGanancias).toFixed(2),
          Retiibb: parseFloat(finalRetIIBB).toFixed(2),
          Otrostributos: parseFloat(finalOtros).toFixed(2),
          Total: parseFloat(finalTotal).toFixed(2),
        });
        guardarListadoExcel(final);
      }
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Subdiario Iva Compras", error.response.data, "error");
    }
  };

  // Informes

  const obtenerComprobantesProcesoFacturacion = async (datos) => {
    const { id } = datos;
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercomprobantesprocesosfacturacion",
        datos
      );

      let data = respuesta.data;

      let local = [];
      for (var i = 0; i < data.length; i++) {
        local.push({
          IDProceso: id,
          IDComprobante: data[i].id,
          Fecha: data[i].fecha,
          Comprobante: data[i].comprobante,
          PuntoVenta: data[i].puntoventa,
          Numero: data[i].numero,
          Total: data[i].total,
          IDTitular: data[i].id_titular,
          NombreTitular: data[i].nombre_titular,
          Periodo: data[i].periodo,
          IDCobrador: data[i].id_cobrador,
          Cae: data[i].cae,
          VtoCae: data[i].vtocae,
        });
      }
      guardarComprobantes(local);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Procesos Facturación", error.response.data, "error");
    }
  };

  const obtenerRebotesProcesoFacturacion = async (datos) => {
    const { id } = datos;
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerrebotesprocesosfacturacion",
        datos
      );

      let data = respuesta.data;
      guardarRebotes(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Procesos Facturación", error.response.data, "error");
    }
  };

  const obtenerInformesProcesosFacturacion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerinformesprocesosfacturacion",
        datos
      );

      let data = respuesta.data;

      guardarInformes(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Procesos Facturación", error.response.data, "error");
    }
  };

  // Mercado Pago

  const actualizarDiasEnviosLinks = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/actualizardiasenvioslinks",
        datos
      );

      mensajeAlerta("Mercado Pago", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Mercado Pago", error.response.data, "error");
    }
  };

  const obtenerDiasEnviosLinks = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenerdiasenvioslinks"
      );

      let data = respuesta.data;

      let local = [];
      let moverSabado = null;
      let moverDomingo = null;

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].dia,
          label: String(data[i].dia),
        });
        moverSabado = data[i].mueveSabado;
        moverDomingo = data[i].mueveDomingo;
      }

      guardarDiasEnvios(local);
      guardarMoverSabado(moverSabado);
      guardarMoverDomingo(moverDomingo);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Mercado Pago", error.response.data, "error");
    }
  };

  const obtenerCotizaciones = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenercotizaciones"
      );

      let data = respuesta.data;

      guardarCotizaciones(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cotizaciones", error.response.data, "error");
    }
  };

  const obtenerMercadoPago = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/obtenermercadopago",
        datos
      );

      toast.current.clear();

      let dataAceptados = respuesta.data.aceptados;
      let dataRechazados = respuesta.data.rechazados;
      let msg = respuesta.data.msg;

      mensajeAlerta("Mercado Pago", msg, "success");
      guardarAceptados(dataAceptados);
      guardarRechazados(dataRechazados);
    } catch (error) {
      toast.current.clear();
      console.log(error.response);
      mensajeAlerta("Débitos Automáticos", error.response.data, "error");
    }
  };

  const generarLinkMp = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/generarlinkmp",
        datos
      );

      mensajeAlerta("Mercado Pago", respuesta.data, "success");
    } catch (error) {
      toast.current.clear();
      console.log(error.response);
      mensajeAlerta("Mercado Pago", error.response.data, "error");
    }
  };

  /*
  const obtenerMercadoPago = async (empresas, empresaId) => {
    let newDate = new Date();
    let oldDate = new Date();
    newDate.setHours(newDate.getHours() - 3);
    oldDate.setHours(newDate.getHours() - 144);
    newDate = newDate.toISOString();
    oldDate = oldDate.toISOString();

    let access_token = empresas.find(
      (item) =>
        item.id === empresaId
    ).accesstoken


    try {
      const respuesta = await axios.get(
        "https://api.mercadopago.com/v1/payments/search",
        {
          params: {
            access_token,
            status: "approved",
            offset: "0",
            limit: "50",
            //begin_date: oldDate,
            //end_date: newDate,
          },
          headers: {
            accept: "application/json",
          },
        }
      );

      let data = respuesta.data;

      console.log(data);
      //guardarMercadoPago(data)
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Ivas", error.response.data, "error");
    }
  };
  */

  const imprimirComprobantes = async (proceso) => {
    if (!impresionComprobantes || impresionComprobantes.length === 0) {
      return;
    }

    const doc = new jsPDF();

    let hoy = new Date();

    let fechaDia = hoy.getDate();
    let fechaMes = hoy.getMonth() + 1;
    let fechaAnio = hoy.getFullYear();
    let nombreMes = "";
    let row = 0;

    if (fechaMes === 1) {
      nombreMes = "Enero";
    }
    if (fechaMes === 2) {
      nombreMes = "Febrero";
    }
    if (fechaMes === 3) {
      nombreMes = "Marzo";
    }
    if (fechaMes === 4) {
      nombreMes = "Abril";
    }
    if (fechaMes === 5) {
      nombreMes = "Mayo";
    }
    if (fechaMes === 6) {
      nombreMes = "Junio";
    }
    if (fechaMes === 7) {
      nombreMes = "Julio";
    }
    if (fechaMes === 8) {
      nombreMes = "Agosto";
    }
    if (fechaMes === 9) {
      nombreMes = "Septiembre";
    }
    if (fechaMes === 10) {
      nombreMes = "Octubre";
    }
    if (fechaMes === 11) {
      nombreMes = "Noviembre";
    }
    if (fechaMes === 12) {
      nombreMes = "Diciembre";
    }

    let empresa = empresas.find((item) => item.id === empresaElegida.id);

    for (var i = 0; i < impresionComprobantes.length; i++) {
      //"impresionComprobantes[i].id_titular" +
      let barCode = "00" + String(impresionComprobantes[i].id).padStart(8, "0");

      if (
        configuracion &&
        configuracion.destinatario === 2 &&
        proceso.formularioId === 1
      ) {
        doc.setFontSize(8);
        row = 133;
        doc.rect(105, row, 13, 13);
        row = row + 8;
        doc.setFontSize(24);
        doc.text(impresionComprobantes[i].tipo, 109, row);
        row = row + 3;
        doc.setFontSize(6);
        doc.text(
          "COD." +
            String(impresionComprobantes[i].id_comprobanteafip).padStart(
              2,
              "0"
            ),
          108,
          row
        );

        let tipoComprobante = null;
        switch (impresionComprobantes[i].id_comprobanteafip) {
          case 1:
          case 6:
          case 11:
          case 51:
            tipoComprobante = "FACTURA";
            break;
          case 2:
          case 7:
          case 12:
          case 52:
            tipoComprobante = "NOTA DE DEBITO";
            break;
          case 3:
          case 8:
          case 13:
          case 53:
            tipoComprobante = "NOTA DE CREDITO";
            break;
          case 201:
            tipoComprobante = "FACTURA DE CREDITO ELECTRONICA";
            break;
          case 202:
            tipoComprobante = "NOTA DE DEBITO ELECTRONICA";
            break;
          case 203:
            tipoComprobante = "NOTA DE CREDITO ELECTRONICA";
            break;
          case 1000:
            tipoComprobante = "AJUSTE DE DEBITO";
            break;
          case 1001:
            tipoComprobante = "AJUSTE DE CREDITO";
            break;
        }

        row = row - 8;

        doc.setFontSize(8);
        doc.text(tipoComprobante, 155, row);
        row = row + 4;
        doc.text(
          "Nº: " +
            String(impresionComprobantes[i].puntoventa).padStart(4, "0") +
            "-" +
            String(impresionComprobantes[i].numero).padStart(8, "0"),
          155,
          row
        );
        row = row + 4;
        doc.text(
          "FECHA: " + formatDate(impresionComprobantes[i].fecha),
          155,
          row
        );

        if (empresaElegida.id === 4) {
          //CUPONES GRIMPLOT CON QR

          row = row + 8;
          doc.text("C.U.I.T.: " + "30-71085401-3", 155, row);
          row = row + 4;
          doc.text("Ing. Brutos C.M.: " + "30-71085401-3", 155, row);
          row = row + 4;
          doc.text("Inicio de Actividades: " + "01/2009", 155, row);
          row = row - 10;
          doc.text("GRIMPLOT INC. S.A.", 38, row);
          row = row + 3;
          doc.setFontSize(6);
          doc.text("Hipolito Yrigoyen 11021", 31, row);
          row = row + 3;
          doc.text("(1832) Lomas de Zamora Bs. As.", 32, row);
          row = row + 3;
          doc.text(
            "Emergencia: Tel.: 0810 220 6060 (Lineas Rotativas)",
            24,
            row
          );
          doc.setFontSize(8);
          row = row + 5;
          doc.text("I.V.A. Responsable Inscripto", 31, row);
        }

        if (empresaElegida.id === 11) {
          //CUPONES MEDISIM CON QR
          row = row + 8;
          doc.text("C.U.I.T.: " + "30-71073074-8", 155, row);
          row = row + 4;
          doc.text("Ing. Brutos C.M.: " + "30-71073074-8", 155, row);
          row = row + 4;
          doc.text("Inicio de Actividades: " + "10/2008", 155, row);
          row = row - 10;
          doc.text("MEDISIM SALUD S.R.L.", 38, row);
          row = row + 3;
          doc.setFontSize(6);
          doc.text("AV. E. Santamarina 148", 38, row);
          row = row + 3;
          doc.text("(1842) Monte Grande - Bs. As", 34, row);
          row = row + 3;
          doc.text(
            "Tel. 7079-4848 Emergencias: 0810 220 6060 (Lineas Rotativas)",
            21,
            row
          );
          doc.setFontSize(8);
          row = row + 5;
          doc.text("I.V.A. Responsable Inscripto", 31, row);
        }

        if (empresaElegida.id === 18) {
          //CUPONES EMERSUR CON QR
          row = row + 8;
          doc.text("C.U.I.T.: " + "30-62504744-3", 155, row);
          row = row + 4;
          doc.text("Ing. Brutos C.M.: " + "901-939660-7", 155, row);
          row = row + 4;
          doc.text("Inicio de Actividades: " + "01/08/1987", 155, row);
          row = row - 10;
          doc.text("GESUNDHEIT S.A.", 38, row);
          row = row + 3;
          doc.setFontSize(6);
          doc.text("Hipolito Yrigoyen 4881", 38, row);
          row = row + 3;
          doc.text("(1824) Lanus - Bs. As", 34, row);
          row = row + 3;
          doc.text("Emergencias: 0810 220 3030 (Lineas Rotativas)", 21, row);
          doc.setFontSize(8);
          row = row + 5;
          doc.text("I.V.A. Responsable Inscripto", 31, row);
        }

        row = row + 5;
        doc.text(
          "Sr/es: " +
            impresionComprobantes[i].afiliados.nombre_razonsocial_facturacion +
            " (" +
            impresionComprobantes[i].id_titular +
            ")",
          15,
          row
        );
        row = row + 4;
        if (impresionComprobantes[i].afiliados.entre1_facturacion) {
          doc.text(
            "Domicilio: " +
              impresionComprobantes[i].afiliados.domicilio_facturacion +
              " Entre " +
              impresionComprobantes[i].afiliados.entre1_facturacion +
              " y " +
              impresionComprobantes[i].afiliados.entre2_facturacion +
              " " +
              impresionComprobantes[i].afiliados.localidadesfacturacion.nombre +
              " (" +
              impresionComprobantes[i].afiliados.telefono1 +
              ")",
            15,
            row
          );
        } else {
          doc.text(
            "Domicilio: " +
              impresionComprobantes[i].afiliados.domicilio_facturacion +
              " " +
              impresionComprobantes[i].afiliados.localidadesfacturacion.nombre +
              " (" +
              impresionComprobantes[i].afiliados.telefono1 +
              ")",
            15,
            row
          );
        }
        row = row + 4;
        doc.text(
          "I.V.A.: " +
            impresionComprobantes[i].tiposcontribuyentes.nombre +
            "      CONDICION: CONTADO" +
            "         C.U.I.T.: " +
            impresionComprobantes[i].cuit,
          15,
          row
        );
        row = row + 4;
        let hab = 0;
        doc.text("COBRADOR: " + impresionComprobantes[i].id_cobrador, 15, row);
        row = row + 10;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            hab = hab + 1;
            if (hab === 1) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 15, row);
            }
            if (hab === 2) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 60, row);
            }
            if (hab === 3) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 105, row);
            }
            if (hab === 4) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 150, row);
            }
          }
        }
        row = row + 4;
        hab = 0;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            hab = hab + 1;
            if (hab === 1) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                15,
                row
              );
            }
            if (hab === 2) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                60,
                row
              );
            }
            if (hab === 3) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                105,
                row
              );
            }
            if (hab === 4) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                150,
                row
              );
            }
          }
        }

        // Cupones 1 a 4

        row = row + 4;
        let periodoMes = null;
        let periodoAnio = null;
        let mesNombre = null;
        hab = 0;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            hab = hab + 1;
            periodoMes = Number(
              impresionComprobantes[i].periodo.substring(0, 2)
            );
            periodoAnio =
              "20" + impresionComprobantes[i].periodo.substring(3, 6);

            if (periodoMes === 1) {
              mesNombre = "Enero";
            }
            if (periodoMes === 2) {
              mesNombre = "Febrero";
            }
            if (periodoMes === 3) {
              mesNombre = "Marzo";
            }
            if (periodoMes === 4) {
              mesNombre = "Abril";
            }
            if (periodoMes === 5) {
              mesNombre = "Mayo";
            }
            if (periodoMes === 6) {
              mesNombre = "Junio";
            }
            if (periodoMes === 7) {
              mesNombre = "Julio";
            }
            if (periodoMes === 8) {
              mesNombre = "Agosto";
            }
            if (periodoMes === 9) {
              mesNombre = "Septiembre";
            }
            if (periodoMes === 10) {
              mesNombre = "Octubre";
            }
            if (periodoMes === 11) {
              mesNombre = "Noviembre";
            }
            if (periodoMes === 12) {
              mesNombre = "Diciembre";
            }

            if (hab === 1) {
              doc.text(mesNombre + "/" + periodoAnio, 15, row);
            }
            if (hab === 2) {
              doc.text(mesNombre + "/" + periodoAnio, 60, row);
            }
            if (hab === 3) {
              doc.text(mesNombre + "/" + periodoAnio, 105, row);
            }
            if (hab === 4) {
              doc.text(mesNombre + "/" + periodoAnio, 150, row);
            }
          }
        }

        // Cupones 5 a 8

        row = row + 5;
        hab = 0;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            hab = hab + 1;
            if (hab === 5) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 15, row);
            }
            if (hab === 6) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 60, row);
            }
            if (hab === 7) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 105, row);
            }
            if (hab === 8) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 150, row);
            }
          }
        }
        row = row + 4;
        hab = 0;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            hab = hab + 1;
            if (hab === 5) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                15,
                row
              );
            }
            if (hab === 6) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                60,
                row
              );
            }
            if (hab === 7) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                105,
                row
              );
            }
            if (hab === 8) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                150,
                row
              );
            }
          }
        }
        row = row + 4;
        periodoMes = null;
        periodoAnio = null;
        mesNombre = null;
        hab = 0;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            hab = hab + 1;
            periodoMes = Number(
              impresionComprobantes[i].periodo.substring(0, 2)
            );
            periodoAnio =
              "20" + impresionComprobantes[i].periodo.substring(3, 6);

            if (periodoMes === 1) {
              mesNombre = "Enero";
            }
            if (periodoMes === 2) {
              mesNombre = "Febrero";
            }
            if (periodoMes === 3) {
              mesNombre = "Marzo";
            }
            if (periodoMes === 4) {
              mesNombre = "Abril";
            }
            if (periodoMes === 5) {
              mesNombre = "Mayo";
            }
            if (periodoMes === 6) {
              mesNombre = "Junio";
            }
            if (periodoMes === 7) {
              mesNombre = "Julio";
            }
            if (periodoMes === 8) {
              mesNombre = "Agosto";
            }
            if (periodoMes === 9) {
              mesNombre = "Septiembre";
            }
            if (periodoMes === 10) {
              mesNombre = "Octubre";
            }
            if (periodoMes === 11) {
              mesNombre = "Noviembre";
            }
            if (periodoMes === 12) {
              mesNombre = "Diciembre";
            }

            if (hab === 5) {
              doc.text(mesNombre + "/" + periodoAnio, 15, row);
            }
            if (hab === 6) {
              doc.text(mesNombre + "/" + periodoAnio, 60, row);
            }
            if (hab === 7) {
              doc.text(mesNombre + "/" + periodoAnio, 105, row);
            }
            if (hab === 8) {
              doc.text(mesNombre + "/" + periodoAnio, 150, row);
            }
          }
        }

        row = row + 5;

        doc.text(
          parseFloat(impresionComprobantes[i].tasapercarba).toFixed(2),
          115,
          row
        );
        if (Number(impresionComprobantes[i].iva1) > 0) {
          doc.text("10.5", 150, row);
        }
        if (Number(impresionComprobantes[i].iva2) > 0) {
          doc.text("21", 150, row);
        }
        if (Number(impresionComprobantes[i].iva3) > 0) {
          doc.text("27", 150, row);
        }

        row = row + 4;
        doc.setFontSize(6);

        doc.text(
          "SON: " + NumeroLetras(impresionComprobantes[i].total, 0),
          15,
          row
        );
        doc.text("II.BB", 115, row);
        doc.text("Neto Gravado", 130, row);
        doc.text("I.V.A.", 150, row);
        doc.text("total", 170, row);

        row = row + 4;
        doc.setFontSize(8);
        doc.text(
          impresionComprobantes[i].cae
            ? "C.A.E.: " + impresionComprobantes[i].cae
            : "C.A.E.: ",
          15,
          row
        );
        doc.text(
          impresionComprobantes[i].vtocae
            ? "Fecha Vto.: " + formatDate(impresionComprobantes[i].vtocae)
            : "Fecha Vto.: ",
          55,
          row
        );
        doc.text(
          parseFloat(impresionComprobantes[i].percarba).toFixed(2),
          115,
          row
        );
        doc.text(
          parseFloat(impresionComprobantes[i].subtotal).toFixed(2),
          130,
          row
        );
        doc.text(parseFloat(impresionComprobantes[i].iva).toFixed(2), 150, row);
        doc.text(
          parseFloat(impresionComprobantes[i].total).toFixed(2),
          170,
          row
        );

        if (impresionComprobantes[i].cae) {
          const cuit =
            empresa.cuit.substring(0, 2) +
            empresa.cuit.substring(3, 11) +
            empresa.cuit.substring(12, 13);

          let pv = Number(impresionComprobantes[i].puntoventa);
          let nu = Number(impresionComprobantes[i].numero);
          let id = Number(impresionComprobantes[i].id_comprobanteafip);

          let qrAfip = {
            ver: 1,
            fecha: impresionComprobantes[i].fecha,
            cuit,
            ptoVta: pv,
            tipoCmp: id,
            nroCmp: nu,
            importe: Number(impresionComprobantes[i].total),
            moneda: "PES",
            ctz: 1,
            tipoDocRec:
              impresionComprobantes[i].afiliados.id_tipodocumento === 3
                ? 80
                : 96,
            nroDocRec: impresionComprobantes[i].cuit,
            tipoCodAut: "A",
            codAut: impresionComprobantes[i].cae,
          };

          const stringifyObj = JSON.stringify(qrAfip);
          const binaryObj = new TextEncoder().encode(stringifyObj);
          const base64Obj = base64_encode(binaryObj);

          let qr = "https://www.afip.gob.ar/fe/qr/?p=" + base64Obj;

          let qrImage = await QRCode.toDataURL(qr);
          row = row + 4;
          doc.addImage(qrImage, "JPEG", 15, row, 20, 20);
          doc.addImage(afip, "JPEG", 40, row + 3, 30, 10);

          row = row + 33;

          doc.setFontSize(8);
          doc.text(
            "Recibo Nº: " +
              String(impresionComprobantes[i].puntoventa).padStart(4, "0") +
              "-" +
              String(impresionComprobantes[i].numero).padStart(8, "0"),
            15,
            row
          );
          doc.text(
            "Emitido el: " + formatDate(impresionComprobantes[i].fecha),
            95,
            row
          );
          doc.text(barCode, 150, row);
          row = row + 4;
          doc.text(
            impresionComprobantes[i].afiliados.nombre_razonsocial_facturacion,
            15,
            row
          );
          doc.text(
            "Cobrador: " + impresionComprobantes[i].id_cobrador,
            95,
            row
          );

          const canvas = document.createElement("canvas");

          const dataURL = bwipjs
            .toCanvas(canvas, {
              bcid: "code128", // Barcode type
              text: barCode,
              scale: 3, // 3x scaling factor
              height: 20, // Bar height, in millimeters,
              border: 5,
              includetext: false, // Show human-readable text
            })
            .toDataURL("image/png");

          doc.addImage(dataURL, "JPEG", 150, row, 50, 10);

          row = row + 4;
          doc.text("Socio Nº: " + impresionComprobantes[i].id_titular, 15, row);
          row = row + 4;
          doc.text(
            impresionComprobantes[i].afiliados.domicilio_facturacion +
              " " +
              impresionComprobantes[i].afiliados.localidadesfacturacion.nombre,
            15,
            row
          );
          if (impresionComprobantes[i].afiliados.entre1_facturacion) {
            row = row + 4;
            doc.text(
              "Entre " +
                impresionComprobantes[i].afiliados.entre1_facturacion +
                " y " +
                impresionComprobantes[i].afiliados.entre2_facturacion,
              15,
              row
            );
          }
          row = row + 4;
          doc.text(mesNombre + "/" + periodoAnio, 15, row);
          row = row + 4;
          doc.text(
            "$ " + parseFloat(impresionComprobantes[i].total).toFixed(2),
            15,
            row
          );
        }
      }

      if (
        configuracion &&
        configuracion.destinatario === 2 &&
        proceso.formularioId === 2
      ) {
        //CUPONES SOCIOS
        let perMes = Number(impresionComprobantes[i].periodo.substring(0, 2));
        let perAnio = "20" + impresionComprobantes[i].periodo.substring(3, 6);

        let mesNom = null;

        if (perMes === 1) {
          mesNom = "Enero";
        }
        if (perMes === 2) {
          mesNom = "Febrero";
        }
        if (perMes === 3) {
          mesNom = "Marzo";
        }
        if (perMes === 4) {
          mesNom = "Abril";
        }
        if (perMes === 5) {
          mesNom = "Mayo";
        }
        if (perMes === 6) {
          mesNom = "Junio";
        }
        if (perMes === 7) {
          mesNom = "Julio";
        }
        if (perMes === 8) {
          mesNom = "Agosto";
        }
        if (perMes === 9) {
          mesNom = "Septiembre";
        }
        if (perMes === 10) {
          mesNom = "Octubre";
        }
        if (perMes === 11) {
          mesNom = "Noviembre";
        }
        if (perMes === 12) {
          mesNom = "Diciembre";
        }

        if (i % 2 == 0) {
          row = 42;
        } else {
          row = 180;
        }

        doc.setFontSize(8);
        doc.text(
          impresionComprobantes[i].afiliados.nombre_razonsocial_facturacion,
          10,
          row
        );

        doc.text(mesNom.toUpperCase() + "/" + perAnio, 175, row);

        row = row + 4;
        if (impresionComprobantes[i].afiliados.entre1_facturacion) {
          doc.text(
            "Domicilio: " +
              impresionComprobantes[i].afiliados.domicilio_facturacion +
              " Entre " +
              impresionComprobantes[i].afiliados.entre1_facturacion +
              " y " +
              impresionComprobantes[i].afiliados.entre2_facturacion +
              " " +
              impresionComprobantes[i].afiliados.localidadesfacturacion.nombre +
              " (" +
              impresionComprobantes[i].afiliados.telefono1 +
              ")",
            10,
            row
          );
        } else {
          doc.text(
            "Domicilio: " +
              impresionComprobantes[i].afiliados.domicilio_facturacion +
              " " +
              impresionComprobantes[i].afiliados.localidadesfacturacion.nombre +
              " (" +
              impresionComprobantes[i].afiliados.telefono1 +
              ")",
            10,
            row
          );
        }
        row = row + 4;
        doc.text(impresionComprobantes[i].tiposcontribuyentes.nombre, 10, row);
        doc.text(impresionComprobantes[i].total, 175, row);
        row = row + 8;
        doc.text(impresionComprobantes[i].id_titular, 175, row);

        row = row + 23;
        let hab = 0;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            hab = hab + 1;
            if (hab === 1) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 10, row);
            }
            if (hab === 2) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 55, row);
            }
            if (hab === 3) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 110, row);
            }
            if (hab === 4) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 165, row);
            }
          }
        }
        row = row + 4;
        hab = 0;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            hab = hab + 1;
            if (hab === 1) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                10,
                row
              );
            }
            if (hab === 2) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                55,
                row
              );
            }
            if (hab === 3) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                110,
                row
              );
            }
            if (hab === 4) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                165,
                row
              );
            }
          }
        }

        // Cupones 1 a 4

        row = row + 4;
        perMes = null;
        perAnio = null;
        mesNom = null;
        hab = 0;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            hab = hab + 1;
            perMes = Number(impresionComprobantes[i].periodo.substring(0, 2));
            perAnio = "20" + impresionComprobantes[i].periodo.substring(3, 6);

            if (perMes === 1) {
              mesNom = "Enero";
            }
            if (perMes === 2) {
              mesNom = "Febrero";
            }
            if (perMes === 3) {
              mesNom = "Marzo";
            }
            if (perMes === 4) {
              mesNom = "Abril";
            }
            if (perMes === 5) {
              mesNom = "Mayo";
            }
            if (perMes === 6) {
              mesNom = "Junio";
            }
            if (perMes === 7) {
              mesNom = "Julio";
            }
            if (perMes === 8) {
              mesNom = "Agosto";
            }
            if (perMes === 9) {
              mesNom = "Septiembre";
            }
            if (perMes === 10) {
              mesNom = "Octubre";
            }
            if (perMes === 11) {
              mesNom = "Noviembre";
            }
            if (perMes === 12) {
              mesNom = "Diciembre";
            }

            if (hab === 1) {
              doc.text(mesNom + "/" + perAnio, 10, row);
            }
            if (hab === 2) {
              doc.text(mesNom + "/" + perAnio, 55, row);
            }
            if (hab === 3) {
              doc.text(mesNom + "/" + perAnio, 110, row);
            }
            if (hab === 4) {
              doc.text(mesNom + "/" + perAnio, 165, row);
            }
          }
        }

        // Cupones 5 a 8

        row = row + 18;
        hab = 0;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            hab = hab + 1;
            if (hab === 5) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 10, row);
            }
            if (hab === 6) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 55, row);
            }
            if (hab === 7) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 110, row);
            }
            if (hab === 8) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 165, row);
            }
          }
        }
        row = row + 4;
        hab = 0;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            hab = hab + 1;
            if (hab === 5) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                10,
                row
              );
            }
            if (hab === 6) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                55,
                row
              );
            }
            if (hab === 7) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                110,
                row
              );
            }
            if (hab === 8) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                165,
                row
              );
            }
          }
        }
        row = row + 4;
        perMes = null;
        perAnio = null;
        mesNom = null;
        hab = 0;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            hab = hab + 1;
            perMes = Number(impresionComprobantes[i].periodo.substring(0, 2));
            perAnio = "20" + impresionComprobantes[i].periodo.substring(3, 6);

            if (perMes === 1) {
              mesNom = "Enero";
            }
            if (perMes === 2) {
              mesNom = "Febrero";
            }
            if (perMes === 3) {
              mesNom = "Marzo";
            }
            if (perMes === 4) {
              mesNom = "Abril";
            }
            if (perMes === 5) {
              mesNom = "Mayo";
            }
            if (perMes === 6) {
              mesNom = "Junio";
            }
            if (perMes === 7) {
              mesNom = "Julio";
            }
            if (perMes === 8) {
              mesNom = "Agosto";
            }
            if (perMes === 9) {
              mesNom = "Septiembre";
            }
            if (perMes === 10) {
              mesNom = "Octubre";
            }
            if (perMes === 11) {
              mesNom = "Noviembre";
            }
            if (perMes === 12) {
              mesNom = "Diciembre";
            }

            if (hab === 5) {
              doc.text(mesNom + "/" + perAnio, 10, row);
            }
            if (hab === 6) {
              doc.text(mesNom + "/" + perAnio, 55, row);
            }
            if (hab === 7) {
              doc.text(mesNom + "/" + perAnio, 110, row);
            }
            if (hab === 8) {
              doc.text(mesNom + "/" + perAnio, 165, row);
            }
          }
        }

        row = row + 13;

        doc.setFontSize(8);
        doc.text(barCode, 150, row);
        doc.text(mesNom + "/" + perAnio, 180, row);

        row = row + 4;
        doc.text(
          impresionComprobantes[i].id_titular +
            " - " +
            impresionComprobantes[i].afiliados.nombre_razonsocial_facturacion,
          15,
          row
        );
        doc.text("Cobrador: " + impresionComprobantes[i].id_cobrador, 130, row);

        const canvas = document.createElement("canvas");

        const dataURL = bwipjs
          .toCanvas(canvas, {
            bcid: "code128", // Barcode type
            text: barCode,
            scale: 3, // 3x scaling factor
            height: 20, // Bar height, in millimeters,
            border: 5,
            includetext: false, // Show human-readable text
          })
          .toDataURL("image/png");

        doc.addImage(dataURL, "JPEG", 150, row - 2, 50, 10);

        row = row + 4;
        doc.text(
          impresionComprobantes[i].afiliados.domicilio_facturacion +
            " " +
            impresionComprobantes[i].afiliados.localidadesfacturacion.nombre,
          15,
          row
        );
        doc.text(
          "$ " + parseFloat(impresionComprobantes[i].total).toFixed(2),
          130,
          row
        );

        if (impresionComprobantes[i].afiliados.entre1_facturacion) {
          row = row + 4;
          doc.text(
            "Entre " +
              impresionComprobantes[i].afiliados.entre1_facturacion +
              " y " +
              impresionComprobantes[i].afiliados.entre2_facturacion,
            15,
            row
          );
        }
      }

      if (
        configuracion &&
        configuracion.destinatario === 1 &&
        proceso.formularioId === 3
      ) {
        //FACTURAS CON QR
        doc.setFontSize(8);
        row = 133;
        doc.rect(105, row, 13, 13);
        row = row + 8;
        doc.setFontSize(24);
        doc.text(impresionComprobantes[i].tipo, 109, row);
        row = row + 3;
        doc.setFontSize(6);
        doc.text(
          "COD." +
            String(impresionComprobantes[i].id_comprobanteafip).padStart(
              2,
              "0"
            ),
          108,
          row
        );

        row = row - 8;
        doc.setFontSize(8);
        doc.text("FACTURA", 155, row);
        row = row + 4;
        doc.text(
          "Nº: " +
            String(impresionComprobantes[i].puntoventa).padStart(4, "0") +
            "-" +
            String(impresionComprobantes[i].numero).padStart(8, "0"),
          155,
          row
        );
        row = row + 4;
        doc.text(
          "FECHA: " + formatDate(impresionComprobantes[i].fecha),
          155,
          row
        );

        row = row + 10;
        doc.setFontSize(8);
        if (impresionComprobantes[i].id_empresa === 4) {
          doc.text("GRIMPLOT INC. S.A.", 38, row);
          row = row + 4;
          doc.text("Hipolito Yrigoyen 11021", 31, row);
          doc.text("C.U.I.T.: " + "30-71085401-3", 155, row);
          row = row + 4;
          doc.text("(1832) Lomas de Zamora Bs. As.", 32, row);
          doc.text("Ing. Brutos C.M.: " + "30-71085401-3", 155, row);
          row = row + 4;
          doc.text(
            "Emergencia: Tel.: 0810 220 6060 (Lineas Rotativas)",
            24,
            row
          );
          doc.text("Inicio de Actividades: " + "01/2009", 155, row);
          row = row + 5;
          doc.text("I.V.A. Responsable Inscripto", 31, row);
        }

        if (impresionComprobantes[i].id_empresa === 11) {
          doc.text("GRIMPLOT INC. S.A.", 38, row);
          row = row + 4;
          doc.text("Hipolito Yrigoyen 11021", 31, row);
          doc.text("C.U.I.T.: " + "30-71085401-3", 155, row);
          row = row + 4;
          doc.text("(1832) Lomas de Zamora Bs. As.", 32, row);
          doc.text("Ing. Brutos C.M.: " + "30-71085401-3", 155, row);
          row = row + 4;
          doc.text(
            "Emergencia: Tel.: 0810 220 6060 (Lineas Rotativas)",
            24,
            row
          );
          doc.text("Inicio de Actividades: " + "01/2009", 155, row);
          row = row + 5;
          doc.text("I.V.A. Responsable Inscripto", 31, row);
        }

        row = row + 5;
        doc.text(
          "Sr/es: " +
            impresionComprobantes[i].nombre_titular +
            " (" +
            impresionComprobantes[i].id_titular +
            ")",
          15,
          row
        );
        row = row + 4;
        if (impresionComprobantes[i].afiliados.entre1) {
          doc.text(
            "Domicilio: " +
              impresionComprobantes[i].afiliados.domicilio +
              " Entre " +
              impresionComprobantes[i].afiliados.entre1 +
              " y " +
              impresionComprobantes[i].afiliados.entre2 +
              " " +
              impresionComprobantes[i].afiliados.localidades.nombre +
              " (" +
              impresionComprobantes[i].afiliados.telefono1 +
              ")",
            15,
            row
          );
        } else {
          doc.text(
            "Domicilio: " +
              impresionComprobantes[i].afiliados.domicilio +
              " " +
              impresionComprobantes[i].afiliados.localidades.nombre +
              " (" +
              impresionComprobantes[i].afiliados.telefono1 +
              ")",
            15,
            row
          );
        }
        row = row + 4;
        doc.text(
          "I.V.A.: " +
            impresionComprobantes[i].tiposcontribuyentes.nombre +
            "      CONDICION: CONTADO" +
            "         C.U.I.T.: " +
            impresionComprobantes[i].cuit,
          15,
          row
        );
        row = row + 4;
        let hab = 0;
        doc.text("COBRADOR: " + impresionComprobantes[i].id_cobrador, 15, row);
        row = row + 10;

        let cantidad0 = "";
        let descripcion0 = "";
        let precioUnitario0 = "";
        let subtotal0 = "";
        let cantidad1 = "";
        let descripcion1 = "";
        let precioUnitario1 = "";
        let subtotal1 = "";
        let cantidad2 = "";
        let descripcion2 = "";
        let precioUnitario2 = "";
        let subtotal2 = "";
        let cantidad3 = "";
        let descripcion3 = "";
        let precioUnitario3 = "";
        let subtotal3 = "";
        let cantidad4 = "";
        let descripcion4 = "";
        let precioUnitario4 = "";
        let subtotal4 = "";

        let responseItems = impresionComprobantes[i].itemscomprobantes;

        for (var j = 0; j < responseItems.length; j++) {
          if (responseItems[j].cantidad !== "") {
            if (j === 0) {
              cantidad0 =
                responseItems[j].cantidad > 0 ? responseItems[j].cantidad : "";
              descripcion0 = responseItems[j].descripcion;
              if (
                impresionComprobantes[i].tipo === "B" ||
                impresionComprobantes[i].tipo === "C" ||
                impresionComprobantes[i].tipo === "X"
              ) {
                /*
                precioUnitario0 = parseFloat(
                  Number(responseItems[j].total) / Number(responseItems[j].cantidad)
                ).toFixed(2);
                */
                subtotal0 =
                  responseItems[j].cantidad > 0
                    ? parseFloat(Number(responseItems[j].total)).toFixed(2)
                    : "";
              } else {
                /*
                precioUnitario0 = parseFloat(
                  Number(responseItems[j].subtotal) /
                    Number(responseItems[j].cantidad)
                ).toFixed(2);
                */
                subtotal0 =
                  responseItems[j].cantidad > 0
                    ? parseFloat(Number(responseItems[j].total)).toFixed(2)
                    : "";
              }
            }
            if (j === 1) {
              cantidad1 =
                responseItems[j].cantidad > 0 ? responseItems[j].cantidad : "";
              descripcion1 = responseItems[j].descripcion;
              if (
                impresionComprobantes[i].tipo === "B" ||
                impresionComprobantes[i].tipo === "C" ||
                impresionComprobantes[i].tipo === "X"
              ) {
                /*
                precioUnitario1 = parseFloat(
                  Number(responseItems[j].total) / Number(responseItems[j].cantidad)
                ).toFixed(2);
                */
                subtotal1 =
                  responseItems[j].cantidad > 0
                    ? parseFloat(Number(responseItems[j].total)).toFixed(2)
                    : "";
              } else {
                /*
                precioUnitario1 = parseFloat(
                  Number(responseItems[j].subtotal) /
                    Number(responseItems[j].cantidad)
                ).toFixed(2);
                */
                subtotal1 =
                  responseItems[j].cantidad > 0
                    ? parseFloat(Number(responseItems[j].total)).toFixed(2)
                    : "";
              }
            }
            if (j === 2) {
              cantidad2 =
                responseItems[j].cantidad > 0 ? responseItems[j].cantidad : "";
              descripcion2 = responseItems[j].descripcion;
              if (
                impresionComprobantes[i].tipo === "B" ||
                impresionComprobantes[i].tipo === "C" ||
                impresionComprobantes[i].tipo === "X"
              ) {
                /*
                precioUnitario2 = parseFloat(
                  Number(responseItems[j].total) / Number(responseItems[j].cantidad)
                ).toFixed(2);
                */
                subtotal2 =
                  responseItems[j].cantidad > 0
                    ? parseFloat(Number(responseItems[j].total)).toFixed(2)
                    : "";
              } else {
                /*
                precioUnitario2 = parseFloat(
                  Number(responseItems[j].subtotal) /
                    Number(responseItems[j].cantidad)
                ).toFixed(2);
                */
                subtotal2 =
                  responseItems[j].cantidad > 0
                    ? parseFloat(Number(responseItems[j].total)).toFixed(2)
                    : "";
              }
            }
            if (j === 3) {
              cantidad3 =
                responseItems[j].cantidad > 0 ? responseItems[j].cantidad : "";
              descripcion3 = responseItems[j].descripcion;
              if (
                impresionComprobantes[i].tipo === "B" ||
                impresionComprobantes[i].tipo === "C" ||
                impresionComprobantes[i].tipo === "X"
              ) {
                /*
                precioUnitario3 = parseFloat(
                  Number(responseItems[j].total) / Number(responseItems[j].cantidad)
                ).toFixed(2);
                */
                subtotal3 =
                  responseItems[j].cantidad > 0
                    ? parseFloat(Number(responseItems[j].total)).toFixed(2)
                    : "";
              } else {
                /*
                precioUnitario3 = parseFloat(
                  Number(responseItems[j].subtotal) /
                    Number(responseItems[j].cantidad)
                ).toFixed(2);
                */
                subtotal3 =
                  responseItems[j].cantidad > 0
                    ? parseFloat(Number(responseItems[j].total)).toFixed(2)
                    : "";
              }
            }
            if (j === 4) {
              cantidad4 =
                responseItems[j].cantidad > 0 ? responseItems[j].cantidad : "";
              descripcion4 = responseItems[j].descripcion;
              if (
                impresionComprobantes[i].tipo === "B" ||
                impresionComprobantes[i].tipo === "C" ||
                impresionComprobantes[i].tipo === "X"
              ) {
                /*
                precioUnitario4 = parseFloat(
                  Number(responseItems[j].total) / Number(responseItems[j].cantidad)
                ).toFixed(2);
                */
                subtotal4 =
                  responseItems[j].cantidad > 0
                    ? parseFloat(Number(responseItems[j].total)).toFixed(2)
                    : "";
              } else {
                /*
                precioUnitario4 = parseFloat(
                  Number(responseItems[j].subtotal) /
                    Number(responseItems[j].cantidad)
                ).toFixed(2);
                */
                subtotal4 =
                  responseItems[j].cantidad > 0
                    ? parseFloat(Number(responseItems[j].total)).toFixed(2)
                    : "";
              }
            }
          }
        }

        for (var j = 0; j < 4; j++) {
          if (j === 0) {
            doc.text(String(cantidad0), 15, row);
            doc.text(descripcion0, 30, row);
            doc.text(String(subtotal0), 160, row);
          }
          if (j === 1) {
            doc.text(String(cantidad1), 15, row);
            doc.text(descripcion1, 30, row);
            doc.text(String(subtotal1), 160, row);
          }
          if (j === 2) {
            doc.text(String(cantidad2), 15, row);
            doc.text(descripcion2, 30, row);
            doc.text(String(subtotal2), 160, row);
          }
          if (j === 3) {
            doc.text(String(cantidad3), 15, row);
            doc.text(descripcion3, 30, row);
            doc.text(String(subtotal3), 160, row);
          }
          if (j === 4) {
            doc.text(String(cantidad4), 15, row);
            doc.text(descripcion4, 30, row);
            doc.text(String(subtotal4), 160, row);
          }
          row = row + 5;
        }

        row = row + 5;

        let tasaiva = 0;
        let tasaiibb = 0;

        if (Number(impresionComprobantes[i].tasapercarba) > 0) {
          tasaiibb = tasaiibb + Number(impresionComprobantes[i].tasapercarba);
        }

        if (Number(impresionComprobantes[i].tasapercagip) > 0) {
          tasaiibb = tasaiibb + Number(impresionComprobantes[i].tasapercagip);
        }

        if (Number(impresionComprobantes[i].iva1) > 0) {
          tasaiva = tasaiva + 10.5;
        }
        if (Number(impresionComprobantes[i].iva2) > 0) {
          tasaiva = tasaiva + 21;
        }
        if (Number(impresionComprobantes[i].iva3) > 0) {
          tasaiva = tasaiva + 27;
        }
        doc.text(String(parseFloat(tasaiibb).toFixed(2)), 115, row);
        doc.text(String(parseFloat(tasaiva).toFixed(2)), 150, row);

        row = row + 4;
        doc.setFontSize(6);

        doc.text(
          "SON: " + NumeroLetras(impresionComprobantes[i].total, 0),
          15,
          row
        );
        doc.text("II.BB", 115, row);
        doc.text("Neto Gravado", 130, row);
        doc.text("I.V.A.", 150, row);
        doc.text("total", 170, row);

        row = row + 4;
        doc.setFontSize(8);
        doc.text(
          impresionComprobantes[i].cae
            ? "C.A.E.: " + impresionComprobantes[i].cae
            : "C.A.E.: ",
          15,
          row
        );
        doc.text(
          impresionComprobantes[i].vtocae
            ? "Fecha Vto.: " + formatDate(impresionComprobantes[i].vtocae)
            : "Fecha Vto.: ",
          55,
          row
        );
        doc.text(
          parseFloat(impresionComprobantes[i].percarba).toFixed(2),
          115,
          row
        );
        doc.text(
          parseFloat(impresionComprobantes[i].subtotal).toFixed(2),
          130,
          row
        );
        doc.text(parseFloat(impresionComprobantes[i].iva).toFixed(2), 150, row);
        doc.text(
          parseFloat(impresionComprobantes[i].total).toFixed(2),
          170,
          row
        );

        if (impresionComprobantes[i].cae) {
          const cuit =
            empresa.cuit.substring(0, 2) +
            empresa.cuit.substring(3, 11) +
            empresa.cuit.substring(12, 13);

          let pv = Number(impresionComprobantes[i].puntoventa);
          let nu = Number(impresionComprobantes[i].numero);
          let id = Number(impresionComprobantes[i].id_comprobanteafip);

          let qrAfip = {
            ver: 1,
            fecha: impresionComprobantes[i].fecha,
            cuit,
            ptoVta: pv,
            tipoCmp: id,
            nroCmp: nu,
            importe: Number(impresionComprobantes[i].total),
            moneda: "PES",
            ctz: 1,
            tipoDocRec:
              impresionComprobantes[i].afiliados.id_tipodocumento === 3
                ? 80
                : 96,
            nroDocRec: impresionComprobantes[i].cuit,
            tipoCodAut: "A",
            codAut: impresionComprobantes[i].cae,
          };

          const stringifyObj = JSON.stringify(qrAfip);
          const binaryObj = new TextEncoder().encode(stringifyObj);
          const base64Obj = base64_encode(binaryObj);

          let qr = "https://www.afip.gob.ar/fe/qr/?p=" + base64Obj;

          let qrImage = await QRCode.toDataURL(qr);
          row = row + 4;
          doc.addImage(qrImage, "JPEG", 15, row, 20, 20);
          doc.addImage(afip, "JPEG", 40, row + 3, 30, 10);
        }
      }

      if (i < impresionComprobantes.length - 1 && proceso.formularioId === 1) {
        doc.addPage();
      }
      if (
        proceso.formularioId === 2 &&
        row > 160 &&
        i < impresionComprobantes.length - 1
      ) {
        doc.addPage();
      }
    }

    if (proceso && proceso.nombre !== undefined && proceso.nombre !== null) {
      doc.save(proceso.nombre);
    } else {
      window.open(doc.output("bloburl"));
    }
  };

  const progressBar = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/configuracion/progressbar"
      );

      let data = respuesta.data;

      guardarResultProgressBar(data);
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta("ProgressBar", error.response.data, "error");
    }
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <Provider
        value={{
          usuarios,
          pantallas,
          configuracion,
          rows,
          statusAcceso,
          statusAcceso2,
          statusAcceso3,
          statusAcceso4,
          rubros,
          grupos,
          clientes,
          localidades,
          provincias,
          empresas,
          planes,
          asesores,
          cobradores,
          frecuencias,
          tiposDocumentos,
          tiposContribuyentes,
          formasPagos,
          afiliado,
          bandFirst,
          grupo,
          rubro,
          provincia,
          provinciaFacturacion,
          empresa,
          localidad,
          localidadFacturacion,
          domicilioGeo,
          domicilioGeoFacturacion,
          tipoDocumento,
          plan,
          planExtraordinaria,
          asesor,
          cobrador,
          frecuencia,
          tipoContribuyente,
          formaPago,
          titular,
          integrantes,
          activeStep,
          skipped,
          cliente,
          selectedRowClientes,
          proximoIntegrante,
          historiales,
          motivosBajas,
          motivosSuspendidos,
          adicionales,
          adicional,
          modulo,
          integrantesHabilitados,
          integrantesInhabilitados,
          integrantesTotales,
          empresaElegida,
          comprobantes,
          tiposComprobantes,
          numeroComprobante,
          ivas,
          iva,
          percepciones,
          ultimoNumeroAfip,
          comprobanteAsociado,
          configuracionesEmpresas,
          configuracionesCobradores,
          configuracionesFormasPagos,
          configuracionesRendicion,
          idComprobante,
          urlPdf,
          factura,
          items,
          tiposRetenciones,
          rendiciones,
          rendicionesMultiselect,
          comprobante,
          bancos,
          conceptos,
          cuentas,
          nc,
          ajustes,
          aCuenta,
          comprobanteId,
          rendicionNumero,
          comprobantesAnteriores,
          bandComprobante,
          comisionCobradorEmpresa,
          empresaComision,
          cobradorComision,
          cuentaBancoPredeterminada,
          itemsRendicion,
          verificacionAfip,
          movimientosCajas,
          cajasEgresos,
          cajasIngresos,
          cajasSaldoAnterior,
          cajasSaldoActual,
          movimientosCheques,
          movimientosCompensaciones,
          proveedores,
          condicionesPagos,
          proveedor,
          ordenes,
          ordenPago,
          estadoOrden,
          movimientosBancos,
          bancosEgresos,
          bancosIngresos,
          bancosSaldoAnterior,
          bancosSaldoActual,
          cierre,
          clienteRepetido,
          documentoRepetido,
          ctasCtes,
          verComprobante,
          saldos,
          listadoExcel,
          informes,
          rebotes,
          impresionComprobantes,
          procesos,
          procesosVentas,
          procesosCompras,
          totalTarjetas,
          aceptados,
          rechazados,
          mercadoPago,
          diasEnvios,
          moverSabado,
          moverDomingo,
          monedas,
          monedaPredeterminada,
          formaPagoProveedor,
          resultProgressBar,
          result,
          result2,
          result3,
          obtenerUsuarios,
          agregarAccesos,
          obtenerAccesos,
          obtenerConfiguracion,
          setRows,
          obtenerAcceso,
          obtenerAcceso2,
          obtenerAcceso3,
          obtenerAcceso4,
          actualizarConfiguracion,
          guardarConfiguracion,
          obtenerRubros,
          obtenerGrupos,
          agregarRubro,
          actualizarRubro,
          guardarClientes,
          obtenerClientes,
          obtenerLocalidades,
          agregarLocalidad,
          actualizarLocalidad,
          obtenerProvincias,
          agregarProvincia,
          actualizarProvincia,
          obtenerEmpresas,
          agregarEmpresa,
          actualizarEmpresa,
          obtenerPlanes,
          guardarPlanes,
          obtenerAsesores,
          agregarAsesor,
          actualizarAsesor,
          obtenerCobradores,
          agregarCobrador,
          actualizarCobrador,
          obtenerFrecuencias,
          obtenerTiposDocumentos,
          obtenerTiposContribuyentes,
          obtenerFormasPagos,
          guardarAfiliado,
          actualizarCliente,
          agregarCliente,
          guardarGrupo,
          guardarRubro,
          guardarProvincia,
          guardarProvinciaFacturacion,
          guardarEmpresa,
          guardarLocalidad,
          guardarLocalidadFacturacion,
          guardarDomicilioGeo,
          guardarDomicilioGeoFacturacion,
          guardarTipoDocumento,
          guardarPlan,
          guardarPlanExtraordinaria,
          guardarAsesor,
          guardarCobrador,
          guardarFrecuencia,
          guardarTipoContribuyente,
          guardarFormaPago,
          setBandFirst,
          buscarTitular,
          guardarTitular,
          buscarIntegrantes,
          guardarIntegrantes,
          setActiveStep,
          setSkipped,
          guardarCliente,
          setSelectedRowClientes,
          obtenerHistorial,
          agregarHistorial,
          guardarHistoriales,
          obtenerMotivosBajas,
          agregarMotivoBaja,
          actualizarMotivoBaja,
          inhabilitarAfiliado,
          habilitarAfiliado,
          suspenderAfiliado,
          cancelarSuspenderAfiliado,
          obtenerAdicionales,
          agregarAdicional,
          actualizarAdicional,
          guardarAdicional,
          actualizarPlan,
          agregarPlan,
          guardarComprobantes,
          setEmpresaElegida,
          obtenerTiposComprobantes,
          obtenerNumeroComprobante,
          guardarNumeroComprobante,
          obtenerIvas,
          obtenerIva,
          uploadFiles,
          obtenerArchivosPercepciones,
          actualizarPercepcionAgip,
          actualizarPercepcionArba,
          guardarClientes,
          agregarComprobante,
          obtenerUltimoNumeroAfip,
          obtenerUltimoNumeroSistema,
          obtenerComprobanteAsociado,
          guardarComprobanteAsociado,
          obtenerDatosTitulares,
          obtenerComprobantes,
          obtenerConfiguracionesFacturacion,
          actualizarConfiguracionesFacturacion,
          obtenerConfiguracionesRendicion,
          actualizarConfiguracionesRendicion,
          crearComprobantePdf,
          guardarIdComprobante,
          guardarIdOrden,
          obtenerFactura,
          guardarFactura,
          guardarItems,
          obtenerTiposRetenciones,
          agregarTipoRetencion,
          actualizarTipoRetencion,
          obtenerRendicionesPendientes,
          agregarRendicionPendiente,
          actualizarRendicionPendiente,
          actualizarFechaRendicionPendiente,
          obtenerComprobantesAdeudados,
          obtenerComprobanteId,
          obtenerBancos,
          agregarBanco,
          actualizarBanco,
          obtenerConceptos,
          agregarConcepto,
          actualizarConcepto,
          obtenerCuentas,
          agregarCuenta,
          actualizarCuenta,
          obtenerConceptosAutomaticos,
          guardarConfiguracionesRendicion,
          obtenerNc,
          obtenerAjustes,
          obtenerComprobanteIdRendicionesPendientes,
          setRendicionNumero,
          obtenerItemsRendicionPendiente,
          eliminarRendicionPendiente,
          guardarComprobante,
          obtenerPin,
          obtenerComprobantesAnteriores,
          guardarComprobantesAnteriores,
          eliminarComisionCobrador,
          obtenerComisionCobradorEmpresa,
          guardarComisionCobradorEmpresa,
          guardarEmpresaComision,
          guardarCuentaBancoPredeterminada,
          obtenerItemsRendicion,
          confirmarRendicionPendiente,
          aplicarRendicionConfirmada,
          verificarComprobantesAfip,
          obtenerRendicionesConfirmadas,
          obtenerACuenta,
          obtenerMovimientosCajas,
          agregarMovimientoCaja,
          obtenerMovimientosCheques,
          agregarMovimientoCheque,
          confirmarAnularCheque,
          confirmarDepositarCheque,
          confirmarAcreditarCheque,
          actualizarMovimientoCheque,
          confirmarRechazarCheque,
          confirmarVentanillaCheque,
          obtenerProveedores,
          obtenerCondicionesPagos,
          agregarProveedor,
          actualizarProveedor,
          obtenerProveedoresComprobantes,
          obtenerTiposComprobantesProveedores,
          guardarProveedor,
          guardarProveedores,
          agregarComprobanteCompras,
          obtenerComprobantesCompras,
          obtenerFacturaCompras,
          buscarProveedor,
          obtenerComprobantesAdeudadosProveedor,
          agregarOrdenPago,
          obtenerOrdenesPagos,
          guardarOrdenes,
          confirmarAutorizarOrden,
          confirmarAnularOrden,
          obtenerOrdenPago,
          obtenerComprobantesOrden,
          guardarEstadoOrden,
          actualizarOrdenPago,
          anularComprobanteCompras,
          obtenerMovimientosCompensaciones,
          confirmarPendienteOrden,
          obtenerMovimientosCompensacionesProveedor,
          obtenerMovimientosBancos,
          agregarMovimientoBanco,
          agregarTransferencia,
          pagarOrdenes,
          cerrarPeriodo,
          guardarCierre,
          obtenerCierre,
          obtenerMotivosSuspendidos,
          actualizarMotivoSuspendido,
          agregarMotivoSuspendido,
          obtenerClienteId,
          setClienteRepetido,
          obtenerDocumentoCliente,
          setDocumentoRepetido,
          obtenerCtaCte,
          obtenerCtaCteProveedor,
          guardarCtasCtes,
          guardarUrlPdf,
          setVerComprobante,
          obtenerSaldos,
          obtenerRendicionesAplicadas,
          obtenerRendicionAplicada,
          obtenerRendicionAplicada2,
          obtenerItemsRendicionAplicada,
          guardarItemsRendicion,
          obtenerRendicionesAnuladas,
          obtenerRendicionesEliminadas,
          obtenerItemsRendicionAnulada,
          obtenerItemsRendicionEliminada,
          obtenerCarteraAltas,
          obtenerBajas,
          obtenerSaldosProveedor,
          guardarRendiciones,
          obtenerIvaVentas,
          obtenerIvaCompras,
          guardarNc,
          guardarACuenta,
          obtenerCobranzasPendientes,
          obtenerCobranzasRealizadas,
          obtenerCobranzasRealizadasPlanilla,
          obtenerSuspendidos,
          obtenerComprobantesVentas,
          obtenerRetencionesVentas,
          obtenerOrdenesPagosListado,
          obtenerModulos,
          procesoFacturacion,
          procesoImpresion,
          obtenerInformesProcesosFacturacion,
          obtenerComprobantesProcesoFacturacion,
          obtenerRebotesProcesoFacturacion,
          procesoComprasYVentas,
          procesoPercepciones,
          procesoDebitosAutomaticos,
          procesoDebitosAutomaticosPrevios,
          guardarTotalTarjetas,
          guardarProcesos,
          generarPrevio,
          rendicionesDebitosAutomaticos,
          obtenerMercadoPago,
          obtenerDiasEnviosLinks,
          actualizarDiasEnviosLinks,
          guardarDiasEnvios,
          guardarMoverSabado,
          guardarMoverDomingo,
          generarLinkMp,
          imprimirComprobantes,
          enviarEmailComprobante,
          enviarWhatsAppComprobante,
          obtenerMensajesWhatsApp,
          enviarWhatsAppAfiliado,
          cambiarCobradorComprobante,
          obtenerItemsRendiciones,
          obtenerCotizaciones,
          obtenerMonedas,
          agregarMoneda,
          actualizarMoneda,
          obtenerOrdenesPagosSeleccionadas,
          obtenerFormaPagoProveedor,
          progressBar,
          obtenerMovimientos,
        }}
      >
        {children}
      </Provider>
    </Fragment>
  );
}

export {
  ConfiguracionProvider,
  Consumer as ConfiguracionConsumer,
  ConfiguracionContext,
};

import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";
import { InputNumber } from "primereact/inputnumber";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { v4 as uuidv4 } from "uuid";
import { TabView, TabPanel } from "primereact/tabview";

export default function RendicionesPendientesItems(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowComprobantes, setSelectedRowComprobantes] = useState(null);
  const [selectedRowValores, setSelectedRowValores] = useState(null);
  const [value, setValue] = useState("efectivo");
  const [valueBarCode, setValueBarCode] = useState("");
  const [efectivoDefault, setEfectivoDefault] = useState(true);
  const [bancoDefault, setBancoDefault] = useState(false);
  const [cuentaPredeterminada, setCuentaPredeterminada] = useState(false);
  const [visibleCompartido, setVisibleCompartido] = useState(false);
  const [visibleParcial, setVisibleParcial] = useState(false);
  const [visibleNc, setVisibleNc] = useState(false);
  const [visibleAjustes, setVisibleAjustes] = useState(false);
  const [visibleACuenta, setVisibleACuenta] = useState(false);
  const [visibleTotal, setVisibleTotal] = useState(false);
  const [bandComisiones, setBandComisiones] = useState(false);
  const [selectedRowNc, setSelectedRowNc] = useState(null);
  const [selectedRowAjustes, setSelectedRowAjustes] = useState(null);
  const [selectedRowACuenta, setSelectedRowACuenta] = useState(null);
  const [statusBarCode, setStatusBarCode] = useState(0);
  const [barCode, setBarCode] = useState(false);
  const [bandInicio, setBandInicio] = useState(false);
  const [selectedRowChequesCompartidos, setSelectedRowChequesCompartidos] =
    useState([]);
  const [compartidoArray, guardarCompartidoArray] = useState([]);
  const [importeParcialCompartido, guardarImporteParcialCompartido] =
    useState(0);
  const [importeAcumuladoCompartido, guardarImporteAcumuladoCompartido] =
    useState(0);
  const [compartidoIndex, setCompartidoIndex] = useState(0);

  let navigate = useNavigate();

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Descripción", dataKey: "descripcion" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [manual, setManual] = useState(false);
  const [bandComprobante, setBandComprobante] = useState(false);
  const [itemRendicion, guardarItemRendicion] = useState(null);
  const [bandNuevoCheque, setBandNuevoCheque] = useState(false);
  const [bandNuevoRetencion, setBandNuevoRetencion] = useState(false);
  const [efectivoComprobante, guardarEfectivoComprobante] = useState(0);
  const [chequesComprobante, guardarChequesComprobante] = useState(null);
  const [compensacionesComprobante, guardarCompensacionesComprobante] =
    useState(0);
  const [bancosComprobante, guardarBancosComprobante] = useState(null);
  const [ncComprobante, guardarNcComprobante] = useState(null);
  const [retencionesComprobante, guardarRetencionesComprobante] = useState([]);
  const [ajustesComprobante, guardarAjustesComprobante] = useState([]);
  const [aCuentaComprobante, guardarACuentaComprobante] = useState([]);
  const [rendicionDiaria, guardarRendicionDiaria] = useState(null);
  const [banco, guardarBanco] = useState(null);
  const [cuenta, guardarCuenta] = useState(null);
  const [retencion, guardarRetencion] = useState(null);
  const [chequesCompartidos, guardarChequesCompartidos] = useState([]);
  const [moneda, guardarMoneda] = useState(null);
  const [cotizaciones, guardarCotizaciones] = useState([]);
  const [cotizacion, guardarCotizacion] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const toast = useRef(null);
  const toastBC = useRef(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    rendiciones,
    titular,
    bancos,
    cuentas,
    nc,
    ajustes,
    aCuenta,
    comprobante,
    comprobantes,
    comprobanteId,
    tiposRetenciones,
    monedas,
    monedaPredeterminada,
    ivas,
    comisionCobradorEmpresa,
    empresaElegida,
    rendicionNumero,
    cuentaBancoPredeterminada,
    configuracionesRendicion,
    obtenerRendicionesPendientes,
    obtenerAcceso,
    agregarRendicionPendiente,
    actualizarRendicionPendiente,
    buscarTitular,
    obtenerComprobantesAdeudados,
    obtenerComprobanteId,
    guardarTitular,
    guardarComprobantes,
    obtenerBancos,
    obtenerTiposRetenciones,
    //obtenerConceptosAutomaticos,
    obtenerCuentas,
    obtenerConfiguracionesRendicion,
    obtenerNc,
    obtenerAjustes,
    obtenerACuenta,
    obtenerComprobanteIdRendicionesPendientes,
    guardarComprobante,
    obtenerComisionCobradorEmpresa,
    guardarCuentaBancoPredeterminada,
    guardarNc,
    guardarACuenta,
    obtenerMonedas,
    obtenerIvas,
  } = configuracionContext;

  const { id } = useParams();

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "RendicionesPendientes",
      });
    }
  }, [state]);

  useEffect(() => {
    obtenerConfiguracionesRendicion();
    setBandInicio(false);
  }, []);

  useEffect(() => {
    obtenerCuentas();
    obtenerBancos();
    obtenerMonedas();
    obtenerTiposRetenciones();
    //obtenerIvas()
  }, []);

  useEffect(() => {
    const nameLocalStorage = "RENDICION_DIARIA";

    //window.localStorage.removeItem(nameLocalStorage)
    //window.localStorage.removeItem("CHEQUES_COMPARTIDOS")

    let local = window.localStorage.getItem(nameLocalStorage);

    let array = [];

    if (local) {
      array = JSON.parse(local);
      guardarRendicionDiaria(array);
    } else {
      guardarRendicionDiaria([]);
    }
    //mostrarTotales(array);

    setTimeout(() => {
      let elem = document.getElementById("barCode");
      if (elem) {
        elem.focus();
      }
    }, 1000);
  }, [monedas]);

  const obtenerComision = (importe, monedaId, cotizacion) => {
    let comision = 0;
    let sinIva = 0;

    if (monedaId !== monedaPredeterminada) {
      importe = importe * cotizacion;
    }

    if (
      comisionCobradorEmpresa &&
      comisionCobradorEmpresa.habilitarComision &&
      comisionCobradorEmpresa.habilitar1
    ) {
      if (
        Number(importe) >= comisionCobradorEmpresa.entre1 &&
        Number(importe) < comisionCobradorEmpresa.entre2
      ) {
        if (Number(comisionCobradorEmpresa.importe1) > 0) {
          if (comisionCobradorEmpresa.descontarIVA) {

            if (Number(selectedRowComprobantes.iva1) > 0) {
              sinIva = parseFloat(Number(importe) / 1.105);
              comision = parseFloat(
                Number(comisionCobradorEmpresa.importe1) - Number(sinIva)
              ).toFixed(2);
            }
            if (Number(selectedRowComprobantes.iva2) > 0) {
              sinIva = parseFloat(Number(importe) / 1.21);
              comision = parseFloat(
                Number(comisionCobradorEmpresa.importe1) - Number(sinIva)
              ).toFixed(2);
            }
            if (Number(selectedRowComprobantes.iva3) > 0) {
              sinIva = parseFloat(Number(importe) / 1.27);
              comision = parseFloat(
                Number(comisionCobradorEmpresa.importe1) - Number(sinIva)
              ).toFixed(2);
            }
            if (
              Number(selectedRowComprobantes.iva1) === 0 &&
              Number(selectedRowComprobantes.iva2) === 0 &&
              Number(selectedRowComprobantes.iva3) === 0
            ) {
              comision = parseFloat(
                Number(comisionCobradorEmpresa.importe1)
              ).toFixed(2);
            }
          } else {
            comision = parseFloat(
              Number(comisionCobradorEmpresa.importe1)
            ).toFixed(2);
          }
        }
        if (Number(comisionCobradorEmpresa.porcentaje1) > 0) {
          if (comisionCobradorEmpresa.descontarIVA) {
            if (Number(selectedRowComprobantes.iva1) > 0) {
              sinIva = parseFloat(Number(importe) / 1.105);
              comision = parseFloat(
                (Number(sinIva) * Number(comisionCobradorEmpresa.porcentaje1)) /
                  100
              ).toFixed(2);
            }
            if (Number(selectedRowComprobantes.iva2) > 0) {
              sinIva = parseFloat(Number(importe) / 1.21);
              comision = parseFloat(
                (Number(sinIva) * Number(comisionCobradorEmpresa.porcentaje1)) /
                  100
              ).toFixed(2);
            }
            if (Number(selectedRowComprobantes.iva3) > 0) {
              sinIva = parseFloat(Number(importe) / 1.27);
              comision = parseFloat(
                (Number(sinIva) * Number(comisionCobradorEmpresa.porcentaje1)) /
                  100
              ).toFixed(2);
            }
            if (
              Number(selectedRowComprobantes.iva1) === 0 &&
              Number(selectedRowComprobantes.iva2) === 0 &&
              Number(selectedRowComprobantes.iva3) === 0
            ) {
              comision = parseFloat(
                (Number(importe) *
                  Number(comisionCobradorEmpresa.porcentaje1)) /
                  100
              ).toFixed(2);
            }
          } else {
            comision = parseFloat(
              (Number(importe) * Number(comisionCobradorEmpresa.porcentaje1)) /
                100
            ).toFixed(2);
          }
        }
      }
    }

    if (
      comisionCobradorEmpresa &&
      comisionCobradorEmpresa.habilitarComision &&
      comisionCobradorEmpresa.habilitar2
    ) {
      if (
        Number(importe) >= comisionCobradorEmpresa.entre3 &&
        Number(importe) < comisionCobradorEmpresa.entre4
      ) {
        if (Number(comisionCobradorEmpresa.importe2) > 0) {
          if (comisionCobradorEmpresa.descontarIVA) {
            if (Number(selectedRowComprobantes.iva1) > 0) {
              sinIva = parseFloat(Number(importe) / 1.105);
              comision = parseFloat(
                Number(comisionCobradorEmpresa.importe2) - Number(sinIva)
              ).toFixed(2);
            }
            if (Number(selectedRowComprobantes.iva2) > 0) {
              sinIva = parseFloat(Number(importe) / 1.21);
              comision = parseFloat(
                Number(comisionCobradorEmpresa.importe2) - Number(sinIva)
              ).toFixed(2);
            }
            if (Number(selectedRowComprobantes.iva3) > 0) {
              sinIva = parseFloat(Number(importe) / 1.27);
              comision = parseFloat(
                Number(comisionCobradorEmpresa.importe2) - Number(sinIva)
              ).toFixed(2);
            }
            if (
              Number(selectedRowComprobantes.iva1) === 0 &&
              Number(selectedRowComprobantes.iva2) === 0 &&
              Number(selectedRowComprobantes.iva3) === 0
            ) {
              comision = parseFloat(
                Number(comisionCobradorEmpresa.importe2)
              ).toFixed(2);
            }
          } else {
            comision = parseFloat(
              Number(comisionCobradorEmpresa.importe2)
            ).toFixed(2);
          }
        }
        if (Number(comisionCobradorEmpresa.porcentaje2) > 0) {
          if (comisionCobradorEmpresa.descontarIVA) {
            if (Number(selectedRowComprobantes.iva1) > 0) {
              sinIva = parseFloat(Number(importe) / 1.105);
              comision = parseFloat(
                (Number(sinIva) * Number(comisionCobradorEmpresa.porcentaje2)) /
                  100
              ).toFixed(2);
            }
            if (Number(selectedRowComprobantes.iva2) > 0) {
              sinIva = parseFloat(Number(importe) / 1.21);
              comision = parseFloat(
                (Number(sinIva) * Number(comisionCobradorEmpresa.porcentaje2)) /
                  100
              ).toFixed(2);
            }
            if (Number(selectedRowComprobantes.iva3) > 0) {
              sinIva = parseFloat(Number(importe) / 1.27);
              comision = parseFloat(
                (Number(sinIva) * Number(comisionCobradorEmpresa.porcentaje2)) /
                  100
              ).toFixed(2);
            }
            if (
              Number(selectedRowComprobantes.iva1) === 0 &&
              Number(selectedRowComprobantes.iva2) === 0 &&
              Number(selectedRowComprobantes.iva3) === 0
            ) {
              comision = parseFloat(
                (Number(importe) *
                  Number(comisionCobradorEmpresa.porcentaje2)) /
                  100
              ).toFixed(2);
            }
          } else {
            comision = parseFloat(
              (Number(importe) * Number(comisionCobradorEmpresa.porcentaje2)) /
                100
            ).toFixed(2);
          }
        }
      }
    }
    return comision;
  };

  const addClick = async (value) => {
    // Validar
    if (
      (comprobanteId && !rendicionNumero) ||
      (comprobanteId &&
        rendicionNumero &&
        comprobanteId.rendicionId !== rendicionNumero.rendicionId &&
        comprobanteId.empresaId !== rendicionNumero.empresaId)
    ) {
      mensajeAlerta(
        "Rendiciones",
        "El comprobante tiene una aplicación pendiente en la rendicion " +
          comprobanteId.rendicionId,
        "warn"
      );
      return;
    }

    let totalEfectivoComprobante = 0;
    let totalChequesComprobante = 0;
    let totalChequesComprobanteCompartido = 0;
    let totalChequesComprobanteNoCompartido = 0;
    let totalCompensacionesComprobante = 0;
    let totalBancosComprobante = 0;
    let totalNcComprobante = 0;
    let totalAjustesComprobante = 0;
    let totalACuentaComprobante = 0;
    let totalRetencionesComprobante = 0;

    let rd = [];

    if (selectedRowValores) {
      if (
        selectedRowValores.valor === "efectivo" ||
        (selectedRowValores.valor === "cheques" &&
          !selectedRowValores.chequeCompartido) ||
        selectedRowValores.valor === "bancos"
      ) {
        rd = rendicionDiaria.filter(
          (item) => item.id !== selectedRowValores.id
        );
      } else {
        rd = [...rendicionDiaria];
      }
    } else {
      rd = [...rendicionDiaria];
    }

    if (value === "efectivo") {
      if (
        !efectivoComprobante ||
        efectivoComprobante === undefined ||
        efectivoComprobante === null ||
        efectivoComprobante <= 0
      ) {
        mensajeAlerta(
          "Efectivo",
          "No puede ingresar un importe menor que cero ó cero !.",
          "error"
        );
        return;
      }

      if (moneda && !moneda.id_moneda) {
        mensajeAlerta("Efectivo", "Debe ingresar una moneda !.", "error");
        return;
      }

      if (moneda && moneda.id_moneda !== monedaPredeterminada && !cotizacion) {
        mensajeAlerta("Efectivo", "Debe ingresar una cotización !.", "error");
        return;
      }
    }

    if (value === "cheques") {
      if (!selectedRowValores) {
        const index = rd.findIndex(
          (item) =>
            item.valor === "cheques" &&
            item.chequeNumero === chequesComprobante.numero &&
            item.chequeBancoId === chequesComprobante.id_banco &&
            !item.chequeCompartido
        );

        if (index !== -1) {
          mensajeAlerta(
            "Cheques",
            "Este cheque ya ha sido cargado en esta rendición !.",
            "error"
          );
          return;
        }
      }
    }

    if (value === "bancos") {
      if (
        !bancosComprobante ||
        bancosComprobante.id_cuenta === undefined ||
        bancosComprobante.id_cuenta === null ||
        bancosComprobante.id_cuenta === 0
      ) {
        mensajeAlerta("Bancos", "La cuenta es necesaria !.", "error");
        return;
      }

      if (
        !bancosComprobante ||
        bancosComprobante.importe === undefined ||
        bancosComprobante.importe === null ||
        bancosComprobante.importe <= 0
      ) {
        mensajeAlerta(
          "Bancos",
          "No puede ingresar un importe menor que cero ó cero !.",
          "error"
        );
        return;
      }

      if (moneda && !moneda.id_moneda) {
        mensajeAlerta("Bancos", "Debe ingresar una moneda !.", "error");
        return;
      }

      if (moneda && moneda.id_moneda !== monedaPredeterminada && !cotizacion) {
        mensajeAlerta("Bancos", "Debe ingresar una cotización !.", "error");
        return;
      }

      if (moneda && moneda.id_moneda !== cuenta.id_moneda) {
        mensajeAlerta(
          "Bancos",
          "La moneda de la cuenta seleccionada no coincide !.",
          "error"
        );
        return;
      }
    }
    if (value === "compensaciones") {
      if (
        !compensacionesComprobante ||
        compensacionesComprobante === undefined ||
        compensacionesComprobante === null ||
        compensacionesComprobante <= 0
      ) {
        mensajeAlerta(
          "Compensaciones",
          "No puede ingresar un importe menor que cero ó cero !.",
          "error"
        );
        return;
      }
    }

    if (value === "nc") {
      if (
        !ncComprobante.importe ||
        ncComprobante.importe === undefined ||
        ncComprobante.importe === null ||
        ncComprobante.importe <= 0
      ) {
        mensajeAlerta(
          "Nota de Crédito",
          "No puede ingresar un importe menor que cero ó cero !.",
          "error"
        );
        return;
      }
    }

    if (value === "ajustes") {
      if (
        !ajustesComprobante.importe ||
        ajustesComprobante.importe === undefined ||
        ajustesComprobante.importe === null ||
        ajustesComprobante.importe <= 0
      ) {
        mensajeAlerta(
          "Ajustes",
          "No puede ingresar un importe menor que cero ó cero !.",
          "error"
        );
        return;
      }
    }

    if (value === "acuenta") {
      if (
        !aCuentaComprobante.importe ||
        aCuentaComprobante.importe === undefined ||
        aCuentaComprobante.importe === null ||
        aCuentaComprobante.importe <= 0
      ) {
        mensajeAlerta(
          "A Cuenta",
          "No puede ingresar un importe menor que cero ó cero !.",
          "error"
        );
        return;
      }
    }

    if (value === "retenciones") {
      if (
        !retencionesComprobante ||
        retencionesComprobante.fecha === undefined ||
        retencionesComprobante.fecha === null ||
        retencionesComprobante.fecha === 0
      ) {
        mensajeAlerta("Retenciones", "La fecha es necesaria !.", "error");
        return;
      }

      if (
        !retencionesComprobante ||
        retencionesComprobante.sucursal === undefined ||
        retencionesComprobante.sucursal === null ||
        retencionesComprobante.sucursal === 0 ||
        retencionesComprobante.numero === undefined ||
        retencionesComprobante.numero === null ||
        retencionesComprobante.numero === 0
      ) {
        mensajeAlerta("Retenciones", "El número es necesario !.", "error");
        return;
      }

      if (
        !retencionesComprobante ||
        retencionesComprobante.id_tipo === undefined ||
        retencionesComprobante.id_tipo === null ||
        retencionesComprobante.id_tipo === 0
      ) {
        mensajeAlerta("Retenciones", "El tipo es necesario !.", "error");
        return;
      }

      if (
        !retencionesComprobante ||
        retencionesComprobante.importe === undefined ||
        retencionesComprobante.importe === null
      ) {
        mensajeAlerta("Retenciones", "El importe es necesario !.", "error");
        return;
      }

      if (
        !retencionesComprobante.importe ||
        retencionesComprobante.importe === undefined ||
        retencionesComprobante.importe === null ||
        retencionesComprobante.importe <= 0
      ) {
        mensajeAlerta(
          "Retenciones",
          "No puede ingresar un importe menor que cero ó cero !.",
          "error"
        );
        return;
      }
    }

    totalEfectivoComprobante = rd.reduce((acc, val) => {
      if (
        val.valor === "efectivo" &&
        val.comprobanteId === selectedRowComprobantes.id
      )
        return acc + Number(val.importeTotalPesos);
      return acc;
    }, 0);

    totalChequesComprobanteCompartido = rd.reduce((acc, val) => {
      if (
        val.valor === "cheques" &&
        val.chequeCompartido &&
        val.comprobanteId === selectedRowComprobantes.id
      )
        return acc + Number(val.chequeImporteCompartidoPesos);
      return acc;
    }, 0);

    totalChequesComprobanteNoCompartido = rd.reduce((acc, val) => {
      if (
        val.valor === "cheques" &&
        !val.chequeCompartido &&
        val.comprobanteId === selectedRowComprobantes.id
      )
        return acc + Number(val.importeTotalPesos);
      return acc;
    }, 0);

    totalChequesComprobante =
      Number(totalChequesComprobanteCompartido) +
      Number(totalChequesComprobanteNoCompartido);

    totalBancosComprobante = rd.reduce((acc, val) => {
      if (
        val.valor === "bancos" &&
        val.comprobanteId === selectedRowComprobantes.id
      )
        return acc + Number(val.importeTotalPesos);
      return acc;
    }, 0);

    totalCompensacionesComprobante = rd.reduce((acc, val) => {
      if (
        val.valor === "compensaciones" &&
        val.comprobanteId === selectedRowComprobantes.id
      )
        return acc + Number(val.importeTotalPesos);
      return acc;
    }, 0);

    totalRetencionesComprobante = rd.reduce((acc, val) => {
      if (
        val.valor === "retenciones" &&
        val.comprobanteId === selectedRowComprobantes.id
      )
        return acc + Number(val.importeTotalPesos);
      return acc;
    }, 0);

    totalACuentaComprobante = rd.reduce((acc, val) => {
      if (
        val.valor === "acuenta" &&
        val.comprobanteId === selectedRowComprobantes.id
      )
        return acc + Number(val.importeTotalPesos);
      return acc;
    }, 0);

    totalNcComprobante = rd.reduce((acc, val) => {
      if (
        val.valor === "nc" &&
        val.comprobanteId === selectedRowComprobantes.id
      )
        return acc + Number(val.importeTotalPesos);
      return acc;
    }, 0);

    totalAjustesComprobante = rd.reduce((acc, val) => {
      if (
        val.valor === "ajustes" &&
        val.comprobanteId === selectedRowComprobantes.id
      )
        return acc + Number(val.importeTotalPesos);
      return acc;
    }, 0);

    if (value === "efectivo") {
      let ef =
        moneda && moneda.id_moneda === monedaPredeterminada
          ? Number(efectivoComprobante)
          : Number(efectivoComprobante) * Number(cotizacion.cotizacion);

      if (
        Number(selectedRowComprobantes.saldo) -
          (Number(totalEfectivoComprobante) +
            Number(totalChequesComprobante) +
            Number(totalCompensacionesComprobante) +
            Number(totalBancosComprobante) +
            Number(totalNcComprobante) +
            Number(totalAjustesComprobante) +
            Number(totalACuentaComprobante) +
            Number(totalRetencionesComprobante) +
            Number(ef)) <
        0
      ) {
        mensajeAlerta(
          "Efectivo",
          "Quedará saldo a favor del cliente !.",
          "warn"
        );
      }
    }

    if (value === "cheques") {
      let ch = 0;

      if (chequesComprobante && chequesComprobante.compartido) {
        ch =
          moneda && moneda.id_moneda === monedaPredeterminada
            ? Number(chequesComprobante.importeCompartido)
            : Number(chequesComprobante.importeCompartido) *
              Number(cotizacion.cotizacion);
      } else {
        ch =
          moneda && moneda.id_moneda === monedaPredeterminada
            ? Number(chequesComprobante.importeTotal)
            : Number(chequesComprobante.importeTotal) *
              Number(cotizacion.cotizacion);
      }

      if (
        Number(selectedRowComprobantes.saldo) -
          (Number(totalEfectivoComprobante) +
            Number(totalChequesComprobante) +
            Number(totalCompensacionesComprobante) +
            Number(totalBancosComprobante) +
            Number(totalNcComprobante) +
            Number(totalAjustesComprobante) +
            Number(totalACuentaComprobante) +
            Number(totalRetencionesComprobante) +
            Number(ch)) <
        0
      ) {
        mensajeAlerta(
          "Cheques",
          "Quedará saldo a favor del cliente !.",
          "warn"
        );
      }
    }

    if (value === "compensaciones") {
      if (
        Number(selectedRowComprobantes.saldo) -
          (Number(totalEfectivoComprobante) +
            Number(totalChequesComprobante) +
            Number(totalBancosComprobante) +
            Number(totalNcComprobante) +
            Number(totalAjustesComprobante) +
            Number(totalACuentaComprobante) +
            Number(totalRetencionesComprobante) +
            Number(compensacionesComprobante)) <
        0
      ) {
        mensajeAlerta(
          "Compensaciones",
          "No puede quedar saldo a favor del cliente con compensaciones !.",
          "error"
        );
        return;
      }
    }

    if (value === "bancos") {
      let ba =
        moneda && moneda.id_moneda === monedaPredeterminada
          ? Number(bancosComprobante.importe)
          : Number(bancosComprobante.importe) * Number(cotizacion.cotizacion);

      if (
        Number(selectedRowComprobantes.saldo) -
          (Number(totalEfectivoComprobante) +
            Number(totalChequesComprobante) +
            Number(totalCompensacionesComprobante) +
            Number(totalNcComprobante) +
            Number(totalAjustesComprobante) +
            Number(totalACuentaComprobante) +
            Number(totalRetencionesComprobante) +
            Number(ba)) <
        0
      ) {
        mensajeAlerta(
          "Bancos",
          "No puede quedar saldo a favor del cliente con transferencias !.",
          "error"
        );
        return;
      }
    }

    if (value === "nc") {
      if (
        Number(selectedRowComprobantes.saldo) -
          (Number(totalEfectivoComprobante) +
            Number(totalChequesComprobante) +
            Number(totalCompensacionesComprobante) +
            Number(totalBancosComprobante) +
            Number(totalAjustesComprobante) +
            Number(totalACuentaComprobante) +
            Number(totalRetencionesComprobante) +
            Number(ncComprobante.importe)) <
        0
      ) {
        mensajeAlerta(
          "Nota de Crédito",
          "No puede quedar saldo a favor del cliente con notas de crédito !.",
          "error"
        );
        return;
      }
    }

    if (value === "ajustes") {
      if (
        Number(selectedRowComprobantes.saldo) -
          (Number(totalEfectivoComprobante) +
            Number(totalChequesComprobante) +
            Number(totalCompensacionesComprobante) +
            Number(totalBancosComprobante) +
            Number(totalACuentaComprobante) +
            Number(totalNcComprobante) +
            Number(totalRetencionesComprobante) +
            Number(ajustesComprobante.importe)) <
        0
      ) {
        mensajeAlerta(
          "Ajustes",
          "No puede quedar saldo a favor del cliente con ajustes !.",
          "error"
        );
        return;
      }
    }

    if (value === "acuenta") {
      if (
        Number(selectedRowComprobantes.saldo) -
          (Number(totalEfectivoComprobante) +
            Number(totalChequesComprobante) +
            Number(totalCompensacionesComprobante) +
            Number(totalBancosComprobante) +
            Number(totalAjustesComprobante) +
            Number(totalNcComprobante) +
            Number(totalRetencionesComprobante) +
            Number(aCuentaComprobante.importe)) <
        0
      ) {
        mensajeAlerta(
          "A Cuenta",
          "No puede quedar saldo a favor del cliente en comprobantes a cuenta !.",
          "error"
        );
        return;
      }
    }

    if (value === "retenciones") {
      if (
        Number(selectedRowComprobantes.saldo) -
          (Number(totalEfectivoComprobante) +
            Number(totalChequesComprobante) +
            Number(totalCompensacionesComprobante) +
            Number(totalBancosComprobante) +
            Number(totalNcComprobante) +
            Number(totalAjustesComprobante) +
            Number(totalACuentaComprobante) +
            Number(retencionesComprobante.importe)) <
        0
      ) {
        mensajeAlerta(
          "Retenciones",
          "No puede quedar saldo a favor del cliente con retenciones !.",
          "error"
        );
        return;
      }
    }

    //let local = window.localStorage.getItem("RENDICION_DIARIA");

    let array = [...rendicionDiaria];
    let date = new Date();
    let newDate = new Date(date);
    newDate.setHours(date.getHours() - 3);

    let fechaActual = newDate.toISOString().slice(0, 10);
    let horaActual = newDate.toISOString().slice(11, 16);
    let fechaRendicion = null;

    if (
      configuracionesRendicion &&
      horaActual >= configuracionesRendicion.horaDesde &&
      horaActual <= configuracionesRendicion.horaHasta
    ) {
      fechaRendicion = fechaActual;
    } else {
      if (
        configuracionesRendicion &&
        configuracionesRendicion.inhabilitarRendicion
      ) {
        mensajeAlerta(
          "Rendiciones",
          "Esta fuera del horario de carga de rendiciones !.",
          "error"
        );
        //return;
      }
      fechaRendicion = fechaActual + 1;
    }

    /*
    if (local) {
      array = JSON.parse(local);
    }
    */

    if (array.length === 0) {
      fechaRendicion = fechaActual;
    } else {
      fechaRendicion = array[0].fecha;
    }

    if (fechaRendicion !== fechaActual) {
      if (
        configuracionesRendicion &&
        configuracionesRendicion.inhabilitarRendicion
      ) {
        mensajeAlerta(
          "Rendiciones",
          "No coincide la fecha de rendición !.",
          "error"
        );
        return;
      }
    }

    if (value === "efectivo") {
      let cotiza = null;
      if (moneda.id_moneda !== monedaPredeterminada) {
        cotiza = cotizacion.cotizacion;
      }
      let comision = obtenerComision(
        efectivoComprobante,
        moneda.id_moneda,
        cotiza
      );

      let index = -1;

      if (selectedRowValores) {
        index = array.findIndex((item) => item.id === selectedRowValores.id);
      }

      if (index === -1) {
        let indice = array.length + 1;
        array.push({
          afiliadoId: titular.id,
          nombreTitular: titular.nombre_razonsocial,
          titularId: titular.id_titular,
          valor: value,
          importeTotal: parseFloat(efectivoComprobante).toFixed(2),
          importeTotalPesos:
            moneda.id_moneda !== monedaPredeterminada
              ? parseFloat(
                  Number(efectivoComprobante) * Number(cotiza)
                ).toFixed(2)
              : parseFloat(efectivoComprobante).toFixed(2),
          saldo: parseFloat(selectedRowComprobantes.saldo).toFixed(2),
          comprobanteId: selectedRowComprobantes.id,
          puntoventa: selectedRowComprobantes.puntoventa,
          numero: selectedRowComprobantes.numero,
          comprobante: selectedRowComprobantes.comprobante,
          fecha: fechaRendicion,
          cobradorId: selectedRowComprobantes.id_cobrador,
          empresaId: selectedRowComprobantes.id_empresa,
          asesorId: selectedRowComprobantes.id_asesor,
          planesId: selectedRowComprobantes.id_planes,
          tipoContribuyenteId: selectedRowComprobantes.id_tipocontribuyente,
          grupoId: selectedRowComprobantes.id_grupo,
          rubroId: selectedRowComprobantes.id_rubro,
          chequeEmision: null,
          chequeDeposito: null,
          chequeNumero: null,
          chequeBancoId: null,
          chequeBanco: null,
          chequeNoALaOrden: null,
          chequeEcheq: null,
          chequeCompartido: null,
          chequeImporteCompartido: 0,
          chequeImporteCompartidoPesos: 0,
          chequeIndex: 0,
          bancoCuentaId: null,
          bancoCuenta: null,
          ncComprobanteId: null,
          ncComprobante: null,
          ncPuntoVenta: null,
          ncNumero: null,
          ajusteComprobanteId: null,
          ajusteComprobante: null,
          ajustePuntoVenta: null,
          ajusteNumero: null,
          aCuentaComprobanteId: null,
          aCuentaComprobante: null,
          aCuentaPuntoVenta: null,
          aCuentaNumero: null,
          retencionFecha: null,
          retencionSucursal: null,
          retencionNumero: null,
          retencionTipoId: null,
          retencionTipo: null,
          id: uuidv4(),
          rendicionId: null,
          indice,
          comision,
          recibo: 0,
          id_moneda: moneda.id_moneda,
          cotizacion: cotiza,
          cotizacionTipo:
            cotizacion && cotizacion.cotizacionTipo
              ? cotizacion.cotizacionTipo
              : null,
        });
      } else {
        array[index].importeTotal = parseFloat(efectivoComprobante).toFixed(2);
        array[index].importeTotalPesos =
          moneda.id_moneda !== monedaPredeterminada
            ? parseFloat(Number(efectivoComprobante) * Number(cotiza)).toFixed(
                2
              )
            : parseFloat(efectivoComprobante).toFixed(2);
        array[index].comision = comision;
        array[index].id_moneda = moneda.id_moneda;
        array[index].cotizacion = cotiza;
        array[index].cotizacionTipo =
          cotizacion && cotizacion.cotizacionTipo
            ? cotizacion.cotizacionTipo
            : null;
      }
    }

    if (value === "cheques") {
      let cotiza = null;
      let comision = 0;
      if (moneda.id_moneda !== monedaPredeterminada) {
        cotiza = cotizacion.cotizacion;
      }

      if (chequesComprobante && chequesComprobante.compartido) {
        comision = obtenerComision(
          chequesComprobante.importeCompartido,
          moneda.id_moneda,
          cotiza
        );
      } else {
        comision = obtenerComision(
          chequesComprobante.importeTotal,
          moneda.id_moneda,
          cotiza
        );
      }

      let index = -1;

      if (selectedRowValores) {
        index = array.findIndex((item) => item.id === selectedRowValores.id);
      }

      if (index === -1) {
        let indice = array.length + 1;
        array.push({
          afiliadoId: titular.id,
          nombreTitular: titular.nombre_razonsocial,
          titularId: titular.id_titular,
          valor: value,
          importeTotal: parseFloat(chequesComprobante.importeTotal).toFixed(2),
          importeTotalPesos:
            moneda.id_moneda !== monedaPredeterminada
              ? parseFloat(
                  Number(chequesComprobante.importeTotal) * Number(cotiza)
                ).toFixed(2)
              : parseFloat(chequesComprobante.importeTotal).toFixed(2),
          saldo: parseFloat(selectedRowComprobantes.saldo).toFixed(2),
          comprobanteId: selectedRowComprobantes.id,
          puntoventa: selectedRowComprobantes.puntoventa,
          numero: selectedRowComprobantes.numero,
          comprobante: selectedRowComprobantes.comprobante,
          fecha: fechaRendicion,
          cobradorId: selectedRowComprobantes.id_cobrador,
          empresaId: selectedRowComprobantes.id_empresa,
          asesorId: selectedRowComprobantes.id_asesor,
          planesId: selectedRowComprobantes.id_planes,
          tipoContribuyenteId: selectedRowComprobantes.id_tipocontribuyente,
          grupoId: selectedRowComprobantes.id_grupo,
          rubroId: selectedRowComprobantes.id_rubro,
          chequeEmision: chequesComprobante.fechaEmision,
          chequeDeposito: chequesComprobante.fechaDeposito,
          chequeNumero: chequesComprobante.numero,
          chequeBancoId: chequesComprobante.id_banco,
          chequeBanco: chequesComprobante.banco,
          chequeNoALaOrden:
            chequesComprobante.noALaOrden !== undefined
              ? chequesComprobante.noALaOrden
              : false,
          chequeEcheq:
            chequesComprobante.eCheq !== undefined
              ? chequesComprobante.eCheq
              : false,
          chequeCompartido: chequesComprobante.compartido
            ? chequesComprobante.compartido
            : false,
          chequeImporteCompartido: chequesComprobante.importeCompartido
            ? parseFloat(chequesComprobante.importeCompartido).toFixed(2)
            : 0,
          chequeImporteCompartidoPesos:
            moneda.id_moneda !== monedaPredeterminada
              ? parseFloat(
                  Number(chequesComprobante.importeCompartido) * Number(cotiza)
                ).toFixed(2)
              : parseFloat(chequesComprobante.importeCompartido).toFixed(2),
          chequeIndex: compartidoIndex,
          bancoCuentaId: null,
          bancoCuenta: null,
          ncComprobanteId: null,
          ncComprobante: null,
          ncPuntoVenta: null,
          ncNumero: null,
          ajusteComprobanteId: null,
          ajusteComprobante: null,
          ajustePuntoVenta: null,
          ajusteNumero: null,
          aCuentaComprobanteId: null,
          aCuentaComprobante: null,
          aCuentaPuntoVenta: null,
          aCuentaNumero: null,
          retencionFecha: null,
          retencionSucursal: null,
          retencionNumero: null,
          retencionTipoId: null,
          retencionTipo: null,
          id: uuidv4(),
          rendicionId: null,
          indice,
          comision,
          recibo: 0,
          id_moneda: moneda.id_moneda,
          cotizacion: cotiza,
          cotizacionTipo:
            cotizacion && cotizacion.cotizacionTipo
              ? cotizacion.cotizacionTipo
              : null,
        });

        if (chequesComprobante.compartido) {
          let local = window.localStorage.getItem("CHEQUES_COMPARTIDOS");

          let arrayChequesStorage = [];
          if (local) {
            arrayChequesStorage = JSON.parse(local);
          }

          const index = arrayChequesStorage.findIndex(
            (item) =>
              item.id_banco === chequesComprobante.id_banco &&
              item.numero === chequesComprobante.numero &&
              item.importeCompartido === chequesComprobante.importeCompartido
          );
          arrayChequesStorage[index].utilizado = true;

          window.localStorage.setItem(
            "CHEQUES_COMPARTIDOS",
            JSON.stringify(arrayChequesStorage)
          );
        }
      } else {
        if (array[index].chequeCompartido) {
          let arrayCompartido = array.filter(
            (cheque) =>
              cheque.chequeNumero === selectedRowValores.chequeNumero &&
              cheque.chequeBancoId === selectedRowValores.chequeBancoId
          );

          for (var i = 0; i < arrayCompartido.length; i++) {
            const index2 = array.findIndex(
              (item) => item.id === arrayCompartido[i].id
            );

            array[index2].chequeEmision = chequesComprobante.fechaEmision;
            array[index2].chequeDeposito = chequesComprobante.fechaDeposito;
            array[index2].chequeNumero = chequesComprobante.numero;
            array[index2].chequeBancoId = chequesComprobante.id_banco;
            array[index2].chequeBanco = chequesComprobante.banco;
            array[index2].chequeNoALaOrden = chequesComprobante.noALaOrden;
            array[index2].chequeEcheq = chequesComprobante.eCheq;
          }

          let local = window.localStorage.getItem("CHEQUES_COMPARTIDOS");

          let arrayChequesStorage = [];
          if (local) {
            arrayChequesStorage = JSON.parse(local);
          }

          for (var i = 0; i < arrayChequesStorage.length; i++) {
            if (
              arrayChequesStorage[i].numero ===
                selectedRowValores.chequeNumero &&
              arrayChequesStorage[i].id_banco ===
                selectedRowValores.chequeBancoId
            ) {
              arrayChequesStorage[i].fechaEmision =
                chequesComprobante.fechaEmision;
              arrayChequesStorage[i].fechaDeposito =
                chequesComprobante.fechaDeposito;
              arrayChequesStorage[i].numero = chequesComprobante.numero;
              arrayChequesStorage[i].id_banco = chequesComprobante.id_banco;
              arrayChequesStorage[i].banco = chequesComprobante.banco;
              arrayChequesStorage[i].noALaOrden = chequesComprobante.noALaOrden;
              arrayChequesStorage[i].eCheq = chequesComprobante.eCheq;
            }
          }

          window.localStorage.setItem(
            "CHEQUES_COMPARTIDOS",
            JSON.stringify(arrayChequesStorage)
          );
        } else {
          array[index].chequeEmision = chequesComprobante.fechaEmision;
          array[index].chequeDeposito = chequesComprobante.fechaDeposito;
          array[index].chequeNumero = chequesComprobante.numero;
          array[index].chequeBancoId = chequesComprobante.id_banco;
          array[index].chequeBanco = chequesComprobante.banco;
          array[index].chequeNoALaOrden = chequesComprobante.noALaOrden;
          array[index].chequeEcheq = chequesComprobante.eCheq;
          array[index].importeTotal = parseFloat(
            chequesComprobante.importeTotal
          ).toFixed(2);
          array[index].importeTotalPesos =
            moneda.id_moneda !== monedaPredeterminada
              ? parseFloat(
                  Number(chequesComprobante.importeTotal) * Number(cotiza)
                ).toFixed(2)
              : parseFloat(chequesComprobante.importeTotal).toFixed(2);
          array[index].comision = comision;
          array[index].id_moneda = moneda.id_moneda;
          array[index].cotizacion = cotiza;
          array[index].cotizacionTipo =
            cotizacion && cotizacion.cotizacionTipo
              ? cotizacion.cotizacionTipo
              : null;
        }
      }
    }

    if (value === "bancos") {
      let cotiza = null;
      if (moneda.id_moneda !== monedaPredeterminada) {
        cotiza = cotizacion.cotizacion;
      }
      const index = array.findIndex(
        (item) =>
          item.valor === "bancos" &&
          item.comprobanteId === selectedRowComprobantes.id
      );

      if (index === -1) {
        let indice = array.length + 1;
        array.push({
          afiliadoId: titular.id,
          nombreTitular: titular.nombre_razonsocial,
          titularId: titular.id_titular,
          valor: value,
          importeTotal: parseFloat(bancosComprobante.importe).toFixed(2),
          importeTotalPesos:
            moneda.id_moneda !== monedaPredeterminada
              ? parseFloat(
                  Number(bancosComprobante.importe) * Number(cotiza)
                ).toFixed(2)
              : parseFloat(bancosComprobante.importe).toFixed(2),
          saldo: parseFloat(selectedRowComprobantes.saldo).toFixed(2),
          comprobanteId: selectedRowComprobantes.id,
          puntoventa: selectedRowComprobantes.puntoventa,
          numero: selectedRowComprobantes.numero,
          comprobante: selectedRowComprobantes.comprobante,
          fecha: fechaRendicion,
          cobradorId: selectedRowComprobantes.id_cobrador,
          empresaId: selectedRowComprobantes.id_empresa,
          asesorId: selectedRowComprobantes.id_asesor,
          planesId: selectedRowComprobantes.id_planes,
          tipoContribuyenteId: selectedRowComprobantes.id_tipocontribuyente,
          grupoId: selectedRowComprobantes.id_grupo,
          rubroId: selectedRowComprobantes.id_rubro,
          chequeEmision: null,
          chequeDeposito: null,
          chequeNumero: null,
          chequeBancoId: null,
          chequeBanco: null,
          chequeNoALaOrden: null,
          chequeEcheq: null,
          chequeCompartido: null,
          chequeImporteCompartido: 0,
          chequeImporteCompartidoPesos: 0,
          chequeIndex: 0,
          bancoCuentaId: bancosComprobante.id_cuenta,
          bancoCuenta: bancosComprobante.cuenta,
          ncComprobanteId: null,
          ncComprobante: null,
          ncPuntoVenta: null,
          ncNumero: null,
          ajusteComprobanteId: null,
          ajusteComprobante: null,
          ajustePuntoVenta: null,
          ajusteNumero: null,
          aCuentaComprobanteId: null,
          aCuentaComprobante: null,
          aCuentaPuntoVenta: null,
          aCuentaNumero: null,
          retencionFecha: null,
          retencionSucursal: null,
          retencionNumero: null,
          retencionTipoId: null,
          retencionTipo: null,
          id: uuidv4(),
          rendicionId: null,
          indice,
          comision: 0,
          recibo: 0,
          id_moneda: moneda.id_moneda,
          cotizacion: cotiza,
          cotizacionTipo:
            cotizacion && cotizacion.cotizacionTipo
              ? cotizacion.cotizacionTipo
              : null,
        });
      } else {
        array[index].importeTotal = parseFloat(
          bancosComprobante.importe
        ).toFixed(2);
        array[index].importeTotalPesos =
          moneda.id_moneda !== monedaPredeterminada
            ? parseFloat(
                Number(bancosComprobante.importe) * Number(cotiza)
              ).toFixed(2)
            : parseFloat(bancosComprobante.importe).toFixed(2);
        array[index].id_moneda = moneda.id_moneda;
        array[index].cotizacion = cotiza;
        array[index].cotizacionTipo =
          cotizacion && cotizacion.cotizacionTipo
            ? cotizacion.cotizacionTipo
            : null;
      }
    }

    if (value === "compensaciones") {
      let cotiza = null;

      let index = -1;

      if (selectedRowValores) {
        index = array.findIndex((item) => item.id === selectedRowValores.id);
      }

      if (index === -1) {
        let indice = array.length + 1;
        array.push({
          afiliadoId: titular.id,
          nombreTitular: titular.nombre_razonsocial,
          titularId: titular.id_titular,
          valor: value,
          importeTotal: parseFloat(compensacionesComprobante).toFixed(2),
          importeTotalPesos: parseFloat(compensacionesComprobante).toFixed(2),
          saldo: parseFloat(selectedRowComprobantes.saldo).toFixed(2),
          comprobanteId: selectedRowComprobantes.id,
          puntoventa: selectedRowComprobantes.puntoventa,
          numero: selectedRowComprobantes.numero,
          comprobante: selectedRowComprobantes.comprobante,
          fecha: fechaRendicion,
          cobradorId: selectedRowComprobantes.id_cobrador,
          empresaId: selectedRowComprobantes.id_empresa,
          asesorId: selectedRowComprobantes.id_asesor,
          planesId: selectedRowComprobantes.id_planes,
          tipoContribuyenteId: selectedRowComprobantes.id_tipocontribuyente,
          grupoId: selectedRowComprobantes.id_grupo,
          rubroId: selectedRowComprobantes.id_rubro,
          chequeEmision: null,
          chequeDeposito: null,
          chequeNumero: null,
          chequeBancoId: null,
          chequeBanco: null,
          chequeNoALaOrden: null,
          chequeEcheq: null,
          chequeCompartido: null,
          chequeImporteCompartido: 0,
          chequeImporteCompartidoPesos: 0,
          chequeIndex: 0,
          bancoCuentaId: null,
          bancoCuenta: null,
          ncComprobanteId: null,
          ncComprobante: null,
          ncPuntoVenta: null,
          ncNumero: null,
          ajusteComprobanteId: null,
          ajusteComprobante: null,
          ajustePuntoVenta: null,
          ajusteNumero: null,
          aCuentaComprobanteId: null,
          aCuentaComprobante: null,
          aCuentaPuntoVenta: null,
          aCuentaNumero: null,
          retencionFecha: null,
          retencionSucursal: null,
          retencionNumero: null,
          retencionTipoId: null,
          retencionTipo: null,
          id: uuidv4(),
          rendicionId: null,
          indice,
          comision: 0,
          recibo: 0,
          id_moneda: moneda.id_moneda,
          cotizacion: cotiza,
          cotizacionTipo:
            cotizacion && cotizacion.cotizacionTipo
              ? cotizacion.cotizacionTipo
              : null,
        });
      } else {
        array[index].importeTotal = parseFloat(
          compensacionesComprobante
        ).toFixed(2);
        array[index].importeTotalPesos = parseFloat(
          compensacionesComprobante
        ).toFixed(2);
      }
    }

    if (value === "nc") {
      let cotiza = null;

      if (moneda.id_moneda !== monedaPredeterminada) {
        cotiza = cotizacion.cotizacion;
      }
      const index = array.findIndex(
        (item) =>
          item.valor === "nc" &&
          item.comprobanteId === selectedRowComprobantes.id
      );

      if (index === -1) {
        let indice = array.length + 1;
        array.push({
          afiliadoId: titular.id,
          nombreTitular: titular.nombre_razonsocial,
          titularId: titular.id_titular,
          valor: value,
          importeTotal: parseFloat(ncComprobante.importe).toFixed(2),
          importeTotalPesos: parseFloat(ncComprobante.importe).toFixed(2),
          saldo: parseFloat(selectedRowComprobantes.saldo).toFixed(2),
          comprobanteId: selectedRowComprobantes.id,
          puntoventa: selectedRowComprobantes.puntoventa,
          numero: selectedRowComprobantes.numero,
          comprobante: selectedRowComprobantes.comprobante,
          fecha: fechaRendicion,
          cobradorId: selectedRowComprobantes.id_cobrador,
          empresaId: selectedRowComprobantes.id_empresa,
          asesorId: selectedRowComprobantes.id_asesor,
          planesId: selectedRowComprobantes.id_planes,
          tipoContribuyenteId: selectedRowComprobantes.id_tipocontribuyente,
          grupoId: selectedRowComprobantes.id_grupo,
          rubroId: selectedRowComprobantes.id_rubro,
          chequeEmision: null,
          chequeDeposito: null,
          chequeNumero: null,
          chequeBancoId: null,
          chequeBanco: null,
          chequeNoALaOrden: null,
          chequeEcheq: null,
          chequeCompartido: null,
          chequeImporteCompartido: 0,
          chequeImporteCompartidoPesos: 0,
          chequeIndex: 0,
          bancoCuentaId: null,
          bancoCuenta: null,
          ncComprobanteId: ncComprobante.id,
          ncComprobante: ncComprobante.comprobante,
          ncPuntoVenta: ncComprobante.puntoventa,
          ncNumero: ncComprobante.numero,
          ajusteComprobanteId: null,
          ajusteComprobante: null,
          ajustePuntoVenta: null,
          ajusteNumero: null,
          aCuentaComprobanteId: null,
          aCuentaComprobante: null,
          aCuentaPuntoVenta: null,
          aCuentaNumero: null,
          retencionFecha: null,
          retencionSucursal: null,
          retencionNumero: null,
          retencionTipoId: null,
          retencionTipo: null,
          id: uuidv4(),
          rendicionId: null,
          indice,
          comision: 0,
          recibo: 0,
          id_moneda: moneda.id_moneda,
          cotizacion: cotiza,
          cotizacionTipo:
            cotizacion && cotizacion.cotizacionTipo
              ? cotizacion.cotizacionTipo
              : null,
        });
      }
    }

    if (value === "ajustes") {
      let cotiza = null;
      if (moneda.id_moneda !== monedaPredeterminada) {
        cotiza = cotizacion.cotizacion;
      }
      const index = array.findIndex(
        (item) =>
          item.valor === "ajustes" &&
          item.comprobanteId === selectedRowComprobantes.id
      );

      if (index === -1) {
        let indice = array.length + 1;
        array.push({
          afiliadoId: titular.id,
          nombreTitular: titular.nombre_razonsocial,
          titularId: titular.id_titular,
          valor: value,
          importeTotal: parseFloat(ajustesComprobante.importe).toFixed(2),
          importeTotalPesos: parseFloat(ajustesComprobante.importe).toFixed(2),
          saldo: parseFloat(selectedRowComprobantes.saldo).toFixed(2),
          comprobanteId: selectedRowComprobantes.id,
          puntoventa: selectedRowComprobantes.puntoventa,
          numero: selectedRowComprobantes.numero,
          comprobante: selectedRowComprobantes.comprobante,
          fecha: fechaRendicion,
          cobradorId: selectedRowComprobantes.id_cobrador,
          empresaId: selectedRowComprobantes.id_empresa,
          asesorId: selectedRowComprobantes.id_asesor,
          planesId: selectedRowComprobantes.id_planes,
          tipoContribuyenteId: selectedRowComprobantes.id_tipocontribuyente,
          grupoId: selectedRowComprobantes.id_grupo,
          rubroId: selectedRowComprobantes.id_rubro,
          chequeEmision: null,
          chequeDeposito: null,
          chequeNumero: null,
          chequeBancoId: null,
          chequeBanco: null,
          chequeNoALaOrden: null,
          chequeEcheq: null,
          chequeCompartido: null,
          chequeImporteCompartido: 0,
          chequeImporteCompartidoPesos: 0,
          chequeIndex: 0,
          bancoCuentaId: null,
          bancoCuenta: null,
          ncComprobanteId: null,
          ncComprobante: null,
          ncPuntoVenta: null,
          ncNumero: null,
          ajusteComprobanteId: ajustesComprobante.id,
          ajusteComprobante: ajustesComprobante.comprobante,
          ajustePuntoVenta: ajustesComprobante.puntoventa,
          ajusteNumero: ajustesComprobante.numero,
          aCuentaComprobanteId: null,
          aCuentaComprobante: null,
          aCuentaPuntoVenta: null,
          aCuentaNumero: null,
          retencionFecha: null,
          retencionSucursal: null,
          retencionNumero: null,
          retencionTipoId: null,
          retencionTipo: null,
          id: uuidv4(),
          rendicionId: null,
          indice,
          comision: 0,
          recibo: 0,
          id_moneda: moneda.id_moneda,
          cotizacion: cotiza,
          cotizacionTipo:
            cotizacion && cotizacion.cotizacionTipo
              ? cotizacion.cotizacionTipo
              : null,
        });
      }
    }

    if (value === "acuenta") {
      let cotiza = null;
      if (moneda.id_moneda !== monedaPredeterminada) {
        cotiza = cotizacion.cotizacion;
      }
      const index = array.findIndex(
        (item) =>
          item.valor === "acuenta" &&
          item.comprobanteId === selectedRowComprobantes.id
      );

      if (index === -1) {
        let indice = array.length + 1;
        array.push({
          afiliadoId: titular.id,
          nombreTitular: titular.nombre_razonsocial,
          titularId: titular.id_titular,
          valor: value,
          importeTotal: parseFloat(aCuentaComprobante.importe).toFixed(2),
          importeTotalPesos: parseFloat(aCuentaComprobante.importe).toFixed(2),
          saldo: parseFloat(selectedRowComprobantes.saldo).toFixed(2),
          comprobanteId: selectedRowComprobantes.id,
          puntoventa: selectedRowComprobantes.puntoventa,
          numero: selectedRowComprobantes.numero,
          comprobante: selectedRowComprobantes.comprobante,
          fecha: fechaRendicion,
          cobradorId: selectedRowComprobantes.id_cobrador,
          empresaId: selectedRowComprobantes.id_empresa,
          asesorId: selectedRowComprobantes.id_asesor,
          planesId: selectedRowComprobantes.id_planes,
          tipoContribuyenteId: selectedRowComprobantes.id_tipocontribuyente,
          grupoId: selectedRowComprobantes.id_grupo,
          rubroId: selectedRowComprobantes.id_rubro,
          chequeEmision: null,
          chequeDeposito: null,
          chequeNumero: null,
          chequeBancoId: null,
          chequeBanco: null,
          chequeNoALaOrden: null,
          chequeEcheq: null,
          chequeCompartido: null,
          chequeImporteCompartido: 0,
          chequeImporteCompartidoPesos: 0,
          chequeIndex: 0,
          bancoCuentaId: null,
          bancoCuenta: null,
          ncComprobanteId: null,
          ncComprobante: null,
          ncPuntoVenta: null,
          ncNumero: null,
          ajusteComprobanteId: null,
          ajusteComprobante: null,
          ajustePuntoVenta: null,
          ajusteNumero: null,
          aCuentaComprobanteId: aCuentaComprobante.id,
          aCuentaComprobante: aCuentaComprobante.comprobante,
          aCuentaPuntoVenta: aCuentaComprobante.puntoventa,
          aCuentaNumero: aCuentaComprobante.numero,
          retencionFecha: null,
          retencionSucursal: null,
          retencionNumero: null,
          retencionTipoId: null,
          retencionTipo: null,
          id: uuidv4(),
          rendicionId: null,
          indice,
          comision: 0,
          recibo: 0,
          id_moneda: moneda.id_moneda,
          cotizacion: cotiza,
          cotizacionTipo:
            cotizacion && cotizacion.cotizacionTipo
              ? cotizacion.cotizacionTipo
              : null,
        });
      }
    }

    if (value === "retenciones") {
      let cotiza = null;
      if (moneda.id_moneda !== monedaPredeterminada) {
        cotiza = cotizacion.cotizacion;
      }

      let comision = obtenerComision(
        retencionesComprobante.importe,
        moneda.id_moneda,
        cotiza
      );

      const index = array.findIndex(
        (item) =>
          item.valor === "retenciones" &&
          item.comprobanteId === selectedRowComprobantes.id &&
          selectedRowValores &&
          selectedRowValores.id === item.id
      );

      if (index === -1) {
        let indice = array.length + 1;
        array.push({
          afiliadoId: titular.id,
          nombreTitular: titular.nombre_razonsocial,
          titularId: titular.id_titular,
          valor: value,
          importeTotal: parseFloat(retencionesComprobante.importe).toFixed(2),
          importeTotalPesos: parseFloat(retencionesComprobante.importe).toFixed(
            2
          ),
          saldo: parseFloat(selectedRowComprobantes.saldo).toFixed(2),
          comprobanteId: selectedRowComprobantes.id,
          numero: selectedRowComprobantes.numero,
          puntoventa: selectedRowComprobantes.puntoventa,
          comprobante: selectedRowComprobantes.comprobante,
          fecha: fechaRendicion,
          cobradorId: selectedRowComprobantes.id_cobrador,
          empresaId: selectedRowComprobantes.id_empresa,
          asesorId: selectedRowComprobantes.id_asesor,
          planesId: selectedRowComprobantes.id_planes,
          tipoContribuyenteId: selectedRowComprobantes.id_tipocontribuyente,
          grupoId: selectedRowComprobantes.id_grupo,
          rubroId: selectedRowComprobantes.id_rubro,
          chequeEmision: null,
          chequeDeposito: null,
          chequeNumero: null,
          chequeBancoId: null,
          chequeBanco: null,
          chequeNoALaOrden: null,
          chequeEcheq: null,
          chequeCompartido: null,
          chequeImporteCompartido: 0,
          chequeImporteCompartidoPesos: 0,
          chequeIndex: 0,
          bancoCuentaId: null,
          bancoCuenta: null,
          ncComprobanteId: null,
          ncComprobante: null,
          ncPuntoVenta: null,
          ncNumero: null,
          ajusteComprobanteId: null,
          ajusteComprobante: null,
          ajustePuntoVenta: null,
          ajusteNumero: null,
          aCuentaComprobanteId: null,
          aCuentaComprobante: null,
          aCuentaPuntoVenta: null,
          aCuentaNumero: null,
          retencionFecha: retencionesComprobante.fecha,
          retencionSucursal: retencionesComprobante.sucursal,
          retencionNumero: retencionesComprobante.numero,
          retencionTipoId: retencionesComprobante.id_tipo,
          retencionTipo: retencionesComprobante.tipo,
          id: uuidv4(),
          rendicionId: null,
          indice,
          comision,
          recibo: 0,
          id_moneda: moneda.id_moneda,
          cotizacion: cotiza,
          cotizacionTipo:
            cotizacion && cotizacion.cotizacionTipo
              ? cotizacion.cotizacionTipo
              : null,
        });
      } else {
        array[index].retencionFecha = retencionesComprobante.fecha;
        array[index].retencionSucursal = retencionesComprobante.sucursal;
        array[index].retencionNumero = retencionesComprobante.numero;
        array[index].retencionTipoId = retencionesComprobante.id_tipo;
        array[index].retencionTipo = retencionesComprobante.tipo;
        array[index].importeTotal = parseFloat(
          retencionesComprobante.importe
        ).toFixed(2);
        array[index].comision = comision;
      }
    }

    //mostrarTotales(array);

    array.sort(function (a, b) {
      const indiceA = a.indice;
      const indiceB = b.indice;

      // sort in an ascending order
      if (indiceA > indiceB) {
        return -1;
      }
      if (indiceA < indiceB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    //setSelectedRowValores(null);

    guardarRendicionDiaria(array);

    if (barCode) {
      guardarItemRendicion(null);
      setSelectedRowComprobantes(null);
      guardarTitular(null);
      guardarComprobantes([]);
      inicializar();
      setBarCode(false);
      let elem = document.getElementById("barCode");
      if (elem) {
        elem.focus();
      }

      //window.location.reload();
    } else {
      inicializar();
    }

    window.localStorage.setItem("RENDICION_DIARIA", JSON.stringify(array));
  };

  /*
  const mostrarTotales = (array) => {
    let totalEfectivoRendicion = new Array(monedas.length);
    totalEfectivoRendicion.fill(0);
    let totalCotizacionRendicion = new Array(array.length);
    totalCotizacionRendicion.fill(0);
    let totalChequesRendicion = 0;
    let totalCompensacionesRendicion = 0;
    let totalBancosRendicion = 0;
    let totalNcRendicion = 0;
    let totalAjustesRendicion = 0;
    let totalACuentaRendicion = 0;
    let totalRetencionesRendicion = 0;
    let totalComision = 0;

    for (var i = 0; i < array.length; i++) {
      if (array[i].valor === "efectivo") {
        totalEfectivoRendicion[i] = Number(array[i].importeTotal);
        totalCotizacionRendicion[i] = Number(array[i].cotizacion);
      }

      if (array[i].valor === "cheques" && array[i].chequeIndex === 0) {
        totalChequesRendicion =
          Number(totalChequesRendicion) + Number(array[i].importeTotal);
      }

      if (array[i].valor === "compensaciones") {
        totalCompensacionesRendicion =
          Number(totalCompensacionesRendicion) + Number(array[i].importeTotal);
      }

      if (array[i].valor === "bancos") {
        totalBancosRendicion =
          Number(totalBancosRendicion) + Number(array[i].importeTotal);
      }

      if (array[i].valor === "nc") {
        totalNcRendicion =
          Number(totalNcRendicion) + Number(array[i].importeTotal);
      }

      if (array[i].valor === "ajustes") {
        totalAjustesRendicion =
          Number(totalAjustesRendicion) + Number(array[i].importeTotal);
      }

      if (array[i].valor === "acuenta") {
        totalACuentaRendicion =
          Number(totalACuentaRendicion) + Number(array[i].importeTotal);
      }

      if (array[i].valor === "retenciones") {
        totalRetencionesRendicion =
          Number(totalRetencionesRendicion) + Number(array[i].importeTotal);
      }

      totalComision = totalComision + Number(array[i].comision);
    }

    guardarTotalCotizacionRendicion(totalCotizacionRendicion);
    guardarTotalEfectivoRendicion(totalEfectivoRendicion);
    guardarTotalChequesRendicion(totalChequesRendicion);
    guardarTotalCompensacionesRendicion(totalCompensacionesRendicion);
    guardarTotalBancosRendicion(totalBancosRendicion);
    guardarTotalNcRendicion(totalNcRendicion);
    guardarTotalAjustesRendicion(totalAjustesRendicion);
    guardarTotalACuentaRendicion(totalACuentaRendicion);
    guardarTotalRetencionesRendicion(totalRetencionesRendicion);
    guardarTotalComisionRendicion(totalComision);
  };
  */

  const handleChange = (index) => {
    let mon = null;
    let valorIndex = null;
    let valores = null;

    setBandComisiones(false);

    switch (index) {
      case 0:
        valorIndex = "efectivo";
        break;
      case 1:
        valorIndex = "cheques";
        break;
      case 2:
        valorIndex = "bancos";
        break;
      case 3:
        valorIndex = "compensaciones";
        break;
      case 4:
        valorIndex = "retenciones";
        break;
      case 5:
        valorIndex = "nc";
        break;
      case 6:
        valorIndex = "ajustes";
        break;
      case 7:
        valorIndex = "acuenta";
        break;
    }

    if (selectedRowValores) {
      valores = selectedRowValores;
    }

    if (valores && valores.valor !== valorIndex) {
      setSelectedRowValores(null);
      valores = null;
    }

    if (valorIndex === "efectivo") {
      if (valores && valores.valor === "efectivo") {
        guardarEfectivoComprobante(valores.importeTotal);
        mon = monedas.findIndex((item) => item.id === valores.id_moneda);
        guardarMoneda({
          id_moneda: valores.id_moneda,
          moneda: monedas[mon].nombre,
        });

        let cotiza = [];
        cotiza.push({
          id: "1",
          nombre: "Compra",
          cotizacion: monedas[mon].compra,
        });
        cotiza.push({
          id: "2",
          nombre: "Venta",
          cotizacion: monedas[mon].venta,
        });
        cotiza.push({
          id: "3",
          nombre: "Tarjeta",
          cotizacion: monedas[mon].tarjeta,
        });
        guardarCotizaciones(cotiza);

        guardarCotizacion({
          ...cotizacion,
          cotizacion: valores.cotizacion,
          cotizacionTipo: valores.cotizacionTipo,
        });
      }

      if (!valores) {
        guardarEfectivoComprobante(0);
        mon = monedas.findIndex((item) => item.id === monedaPredeterminada);
        guardarMoneda({
          id_moneda: monedaPredeterminada,
          moneda: monedas[mon].nombre,
        });
        guardarCotizaciones([]);
        guardarCotizacion(null);
      }
    }

    if (valorIndex === "cheques") {
      if (valores && valores.valor === "cheques") {
        const index = bancos.findIndex(
          (item) => item.id === valores.chequeBancoId
        );

        if (index !== -1) {
          guardarBanco(bancos[index]);
        }

        guardarChequesComprobante({
          fechaEmision: valores.chequeEmision,
          fechaDeposito: valores.chequeDeposito,
          numero: valores.chequeNumero,
          id_banco: valores.chequeBancoId,
          banco: valores.chequeBanco,
          noALaOrden: valores.chequeNoALaOrden,
          eCheq: valores.chequeEcheq,
          compartido: valores.chequeCompartido,
          importeTotal: valores.importeTotal,
          //importeCompartido: valores.chequeImporteCompartido,
        });

        setBandNuevoCheque(true);

        mon = monedas.findIndex((item) => item.id === valores.id_moneda);
        guardarMoneda({
          id_moneda: valores.id_moneda,
          moneda: monedas[mon].nombre,
        });

        let cotiza = [];
        cotiza.push({
          id: "1",
          nombre: "Compra",
          cotizacion: monedas[mon].compra,
        });
        cotiza.push({
          id: "2",
          nombre: "Venta",
          cotizacion: monedas[mon].venta,
        });
        cotiza.push({
          id: "3",
          nombre: "Tarjeta",
          cotizacion: monedas[mon].tarjeta,
        });
        guardarCotizaciones(cotiza);

        guardarCotizacion({
          ...cotizacion,
          cotizacion: valores.cotizacion,
          cotizacionTipo: valores.cotizacionTipo,
        });
      }

      if (!valores) {
        guardarBanco(null);
        guardarChequesComprobante(null);
        setBandNuevoCheque(false);

        mon = monedas.findIndex((item) => item.id === monedaPredeterminada);
        guardarMoneda({
          id_moneda: monedaPredeterminada,
          moneda: monedas[mon].nombre,
        });
        guardarCotizaciones([]);
        guardarCotizacion(null);
      }
    }

    if (valorIndex === "bancos") {
      if (valores && valores.valor === "bancos") {
        const index = cuentas.findIndex(
          (item) => item.id === valores.bancoCuentaId
        );

        if (index !== -1) {
          guardarCuenta(cuentas[index]);
        }
        guardarBancosComprobante({
          id_cuenta: valores.bancoCuentaId,
          cuenta: valores.bancoCuenta,
          importe: valores.importeTotal,
        });

        mon = monedas.findIndex((item) => item.id === valores.id_moneda);
        guardarMoneda({
          id_moneda: valores.id_moneda,
          moneda: monedas[mon].nombre,
        });

        let cotiza = [];
        cotiza.push({
          id: "1",
          nombre: "Compra",
          cotizacion: monedas[mon].compra,
        });
        cotiza.push({
          id: "2",
          nombre: "Venta",
          cotizacion: monedas[mon].venta,
        });
        cotiza.push({
          id: "3",
          nombre: "Tarjeta",
          cotizacion: monedas[mon].tarjeta,
        });
        guardarCotizaciones(cotiza);

        guardarCotizacion({
          ...cotizacion,
          cotizacion: valores.cotizacion,
          cotizacionTipo: valores.cotizacionTipo,
        });
      }

      if (!valores) {
        if (cuentaPredeterminada) {
          const index = cuentas.findIndex(
            (item) => item.id === cuentaBancoPredeterminada.id
          );

          if (index !== -1) {
            guardarCuenta(cuentas[index]);
          }
        } else {
          guardarCuenta(null);
        }
        guardarBancosComprobante(null);

        mon = monedas.findIndex((item) => item.id === monedaPredeterminada);
        guardarMoneda({
          id_moneda: monedaPredeterminada,
          moneda: monedas[mon].nombre,
        });
        guardarCotizaciones([]);
        guardarCotizacion(null);
      }
    }

    if (valorIndex === "compensaciones") {
      if (valores && valores.valor === "compensaciones") {
        guardarCompensacionesComprobante(valores.importeTotal);

        mon = monedas.findIndex((item) => item.id === monedaPredeterminada);
        guardarMoneda({
          id_moneda: valores.id_moneda,
          moneda: monedas[mon].nombre,
        });
        guardarCotizaciones([]);
        guardarCotizacion(null);
      }

      if (!valores) {
        let com = rendicionDiaria.findIndex(
          (item) =>
            item.comprobanteId === selectedRowComprobantes.id &&
            item.valor === "compensaciones"
        );
        if (com !== -1) {
          setSelectedRowValores(rendicionDiaria[com]);
          guardarCompensacionesComprobante(rendicionDiaria[com].importeTotal);

          let mon = monedas.findIndex(
            (item) => item.id === monedaPredeterminada
          );

          guardarMoneda({
            id_moneda: monedaPredeterminada,
            moneda: monedas[mon].nombre,
          });
          guardarCotizaciones([]);
          guardarCotizacion(null);
        } else {
          guardarCompensacionesComprobante(0);
          let mon = monedas.findIndex(
            (item) => item.id === monedaPredeterminada
          );
          guardarMoneda({
            id_moneda: monedaPredeterminada,
            moneda: monedas[mon].nombre,
          });
          guardarCotizaciones([]);
          guardarCotizacion(null);
        }
      }
    }

    if (valorIndex === "retenciones") {
      if (valores && valores.valor === "retenciones") {
        const index = tiposRetenciones.findIndex(
          (item) => item.id === valores.retencionTipoId
        );

        if (index !== -1) {
          guardarRetencion(tiposRetenciones[index]);
        }

        guardarRetencionesComprobante({
          fecha: valores.retencionFecha,
          afiliadoId: titular.id,
          titularId: titular.id_titular,
          sucursal: valores.retencionSucursal,
          numero: valores.retencionNumero,
          id_tipo: tiposRetenciones[index].id,
          tipo: tiposRetenciones[index].nombre,
          importe: valores.importeTotal,
        });

        setBandNuevoRetencion(true);

        mon = monedas.findIndex((item) => item.id === monedaPredeterminada);

        guardarMoneda({
          id_moneda: valores.id_moneda,
          moneda: monedas[mon].nombre,
        });
        guardarCotizaciones([]);
        guardarCotizacion(null);
      }
      if (!valores) {
        guardarRetencionesComprobante(null);
        setBandNuevoRetencion(false);
        mon = monedas.findIndex((item) => item.id === monedaPredeterminada);
        guardarMoneda({
          id_moneda: monedaPredeterminada,
          moneda: monedas[mon].nombre,
        });
        guardarCotizaciones([]);
        guardarCotizacion(null);
      }
    }

    if (valorIndex === "nc") {
      //nc
      obtenerNc({ afiliadoId: titular.id });
      setSelectedRowNc(null);
      //setVisibleNc(true);
    } else {
      setVisibleNc(false);
    }

    if (valorIndex === "ajustes") {
      // ajustes
      obtenerAjustes({ afiliadoId: titular.id });
      setSelectedRowAjustes(null);
      //setVisibleAjustes(true);
    } else {
      setVisibleAjustes(false);
    }

    if (valorIndex === "acuenta") {
      //acuenta
      obtenerACuenta({ afiliadoId: titular.id });
      setSelectedRowACuenta(null);
      //setVisibleACuenta(true);
    } else {
      setVisibleACuenta(false);
    }

    //setValue(event.target.value);
  };

  const inicializar = (e) => {
    setBandNuevoCheque(false);
    setBandNuevoRetencion(false);
    guardarEfectivoComprobante(0);
    guardarChequesComprobante(null);
    guardarCompensacionesComprobante(0);
    guardarBancosComprobante(null);
    guardarNcComprobante(null);
    guardarRetencionesComprobante([]);
    guardarAjustesComprobante([]);
    setSelectedRowComprobantes(null);
    setSelectedRowValores(null);
    setSelectedRowNc(null);
    guardarNc([]);
    guardarACuenta([]);
    setBandInicio(false);
    let mon = monedas.findIndex((item) => item.id === monedaPredeterminada);
    guardarMoneda({
      id_moneda: monedaPredeterminada,
      moneda: monedas[mon].nombre,
    });
  };

  const exitClick = (e) => {
    guardarTitular(null);
    guardarComprobantes([]);
    navigate("/rendicionespendientes");
  };

  const onHide = (e) => {};

  const accesoDenegado = () => {
    mensajeAlerta(
      "Bancos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const eliminarValor = (e) => {
    if (!selectedRowValores) {
      mensajeAlerta(
        "Eliminar Valor",
        "Debe seleccionar un valor a eliminar  !.",
        "info"
      );
      return;
    }
    showConfirm();
  };

  const acceptValor = () => {
    let local = window.localStorage.getItem("RENDICION_DIARIA");

    let array = [];
    if (local) {
      array = JSON.parse(local);
    }

    let totalArray = [];

    if (
      selectedRowValores.valor === "cheques" &&
      selectedRowValores.chequeCompartido
    ) {
      totalArray = array.filter(
        (cheque) =>
          !(
            cheque.chequeNumero === selectedRowValores.chequeNumero &&
            cheque.chequeBancoId === selectedRowValores.chequeBancoId &&
            cheque.chequeCompartido === selectedRowValores.chequeCompartido
          )
      );

      let local = window.localStorage.getItem("CHEQUES_COMPARTIDOS");

      let arrayChequesStorage = [];
      if (local) {
        arrayChequesStorage = JSON.parse(local);
      }

      let totalArrayChequesStorage = [];

      totalArrayChequesStorage = arrayChequesStorage.filter(
        (cheque) =>
          !(
            cheque.numero === selectedRowValores.chequeNumero &&
            cheque.id_banco === selectedRowValores.chequeBancoId &&
            cheque.compartido === selectedRowValores.chequeCompartido
          )
      );

      window.localStorage.setItem(
        "CHEQUES_COMPARTIDOS",
        JSON.stringify(totalArrayChequesStorage)
      );
    } else {
      totalArray = array.filter((item) => item.id !== selectedRowValores.id);
    }

    //mostrarTotales(array);

    guardarRendicionDiaria(totalArray);
    window.localStorage.setItem("RENDICION_DIARIA", JSON.stringify(totalArray));

    if (value === "efectivo") {
      guardarEfectivoComprobante(0);
    }

    if (value === "compensaciones") {
      guardarCompensacionesComprobante(0);
    }

    if (value === "bancos") {
      guardarBancosComprobante(null);
    }

    if (value === "nc") {
      guardarNcComprobante(null);
    }

    if (value === "ajustes") {
      guardarAjustesComprobante(null);
    }

    setSelectedRowComprobantes(null);
    setSelectedRowValores(null);
    setSelectedRowNc(null);
    guardarComprobantes([]);
    guardarTitular(null);
    guardarItemRendicion({
      ...itemRendicion,
      id_titular: null,
    });
    inicializar();

    let elem = document.getElementById("barCode");
    if (elem) {
      elem.focus();
    }
    toast.current.show({
      severity: "info",
      summary: "Confirmado",
      detail: "Se ha eliminado el valor.",
      life: 3000,
    });
    //window.location.reload();
  };

  const showConfirm = () => {
    let moneda = monedas.findIndex(
      (item) => item.id === selectedRowValores.id_moneda
    );
    confirmDialog({
      message:
        selectedRowValores.valor === "efectivo" ||
        selectedRowValores.valor === "compensaciones" ||
        selectedRowValores.valor === "bancos"
          ? "Esta seguro de eliminar el valor " +
            selectedRowValores.valor +
            " por " +
            monedas[moneda].simbolo +
            " " +
            selectedRowValores.importeTotal +
            "?"
          : selectedRowValores.valor === "cheques"
          ? "Esta seguro de eliminar el valor " +
            selectedRowValores.valor +
            " número " +
            selectedRowValores.chequeNumero +
            " del banco " +
            selectedRowValores.chequeBanco +
            " por " +
            monedas[moneda].simbolo +
            " " +
            selectedRowValores.importeTotal +
            "?"
          : selectedRowValores.valor === "nc"
          ? "Esta seguro de eliminar el valor " +
            selectedRowValores.valor +
            " número " +
            selectedRowValores.ncComprobante +
            "-" +
            selectedRowValores.ncNumero +
            " por " +
            monedas[moneda].simbolo +
            " " +
            selectedRowValores.importeTotal +
            "?"
          : selectedRowValores.valor === "ajustes"
          ? "Esta seguro de eliminar el valor " +
            selectedRowValores.valor +
            " número " +
            selectedRowValores.ajusteComprobante +
            "-" +
            selectedRowValores.ajusteNumero +
            " por " +
            monedas[moneda].simbolo +
            " " +
            selectedRowValores.importeTotal +
            "?"
          : selectedRowValores.valor === "retenciones"
          ? "Esta seguro de eliminar el valor " +
            selectedRowValores.valor +
            " número " +
            selectedRowValores.retencionSucursal +
            "-" +
            selectedRowValores.retencionNumero +
            " por " +
            monedas[moneda].simbolo +
            " " +
            selectedRowValores.importeTotal +
            "?"
          : selectedRowValores.valor === "acuenta"
          ? "Esta seguro de eliminar el valor " +
            selectedRowValores.valor +
            " número " +
            selectedRowValores.aCuentaComprobante +
            "-" +
            selectedRowValores.aCuentaNumero +
            " por " +
            monedas[moneda].simbolo +
            " " +
            selectedRowValores.importeTotal +
            "?"
          : null,

      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      rejectLabel: "No",
      acceptLabel: "Si",
      accept: () => acceptValor(),
      //reject: () => reject(),
    });
  };

  const acceptEliminar = () => {
    window.localStorage.removeItem("RENDICION_DIARIA");
    window.localStorage.removeItem("CHEQUES_COMPARTIDOS");
    guardarRendicionDiaria([]);
    guardarTitular(null);
    guardarComprobantes([]);
    inicializar();
    navigate("/rendicionespendientes");
    //window.location.reload();
  };

  const comprobarCobrador = () => {
    if (
      Number(titular.id_cobrador1_facturacion) !==
      Number(selectedRowComprobantes.id_cobrador)
    ) {
      toast.current.show({
        severity: "warn",
        summary: "Rendiciones",
        detail:
          "El comprobante no corresponse al cobrador " +
          selectedRowComprobantes.id_cobrador +
          ".",
        life: 3000,
      });
      setBandComprobante(false);
      setSelectedRowComprobantes(null);
      return;
    }
  };

  const acceptConfirmar = () => {
    if (rendicionDiaria && rendicionDiaria.length === 0) {
      toast.current.show({
        severity: "error",
        summary: "Rendiciones",
        detail: "La rendición debe tener al menos un valor a aplicar. !",
        life: 3000,
      });
      return;
    }

    // validar cheques compaqrtidos

    let local = window.localStorage.getItem("CHEQUES_COMPARTIDOS");

    let array = [];
    if (local) {
      array = JSON.parse(local);
    }

    let chequeCompartido = array.findIndex((item) => !item.utilizado);

    if (chequeCompartido !== -1) {
      mensajeAlerta(
        "Cheques",
        "Hay cheques compartidos pendientes de aplicar !.",
        "error"
      );
      return;
    }

    agregarRendicionPendiente({
      rendicionDiaria,
      usuarioId: state.usuario.id,
      rendicionNumero,
    });
    setTimeout(() => {
      acceptEliminar();
    }, 3000);
  };

  const eliminarRendicion = () => {
    confirmDialog({
      message: "Esta seguro de salir. Las modificaciones se perderán.",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      rejectLabel: "No",
      acceptLabel: "Si",
      accept: () => acceptEliminar(),
      //reject: () => reject(),
    });
  };

  const confirmarRendicion = () => {
    confirmDialog({
      message: "Esta seguro de confirmar la rendición.",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      rejectLabel: "No",
      acceptLabel: "Si",
      accept: () => acceptConfirmar(),
      //reject: () => reject(),
    });
  };

  const header = () => {
    return (
      <div>
        <Tag value="Comprobantes Adeudados"></Tag>
      </div>
    );
  };

  const headerValores = () => {
    return (
      <Fragment>
        <div>
          <Tag
            value={
              rendicionDiaria && rendicionDiaria.length > 0
                ? "Valores Rendición Cobrador " +
                  rendicionDiaria[0].cobradorId +
                  " - " +
                  formatDate(rendicionDiaria[0].fecha)
                : "Valores Rendición"
            }
          ></Tag>
          {/*Botones Ocultos*/}
          <Button
            id="botonComprobarCobrador"
            style={{ display: "none" }}
            onClick={(e) => comprobarCobrador()}
          />
          <Button
            id="botonLimpiarCodigoBarra"
            style={{ display: "none" }}
            onClick={(e) => setValueBarCode("")}
          />
          <Button
            id="botonAgregarChequesCompartidos"
            style={{ display: "none" }}
            onClick={(e) => addClick("cheques")}
          />
          {/*Botones Ocultos*/}
          <span
            style={{ marginLeft: "1em", marginRight: "1em" }}
            className="p-input-icon-left"
          >
            <i className="pi pi-search" />
            <InputText
              type="search"
              className="p-inputtext-sm"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Buscar"
            />
          </span>
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-danger"
            aria-label="Filter"
            tooltip="Eliminar Valor"
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
              className: "tooltip",
            }}
            style={{ marginLeft: "1vw" }}
            onClick={(e) => eliminarValor()}
          />
          <Button
            icon="pi pi-info"
            className="p-button-rounded p-button-primary"
            aria-label="Filter"
            tooltip="Info"
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
              className: "tooltip",
            }}
            style={{ marginLeft: "1vw" }}
            onClick={(e) => {
              messageACuenta();
              messageNc();
            }}
          />
          <Button
            icon="pi pi-percentage"
            className="p-button-rounded p-button-secondary"
            aria-label="Filter"
            tooltip="Comisiones"
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
              className: "tooltip",
            }}
            style={{ marginLeft: "1vw" }}
            onClick={(e) => {
              setBandComisiones(true);
            }}
          />
          <Button
            icon="pi pi-check-circle"
            className="p-button-rounded p-button-success"
            aria-label="Filter"
            tooltip="Finalizar"
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
              className: "tooltip",
            }}
            style={{ marginLeft: "1vw" }}
            onClick={(e) => {
              setSelectedRowComprobantes(null);
              setVisibleTotal(true);
            }}
          />
        </div>
      </Fragment>
    );
  };

  const valorTemplate = (rowData) => {
    if (monedas && monedas.length > 0) {
      let moneda = monedas.findIndex((item) => item.id === rowData.id_moneda);
      if (moneda !== -1) {
        return (
          <Fragment>
            {rowData.valor.substring(0, 3).toUpperCase() +
              "-" +
              monedas[moneda].simbolo}
          </Fragment>
        );
      }
    }
  };

  const importeTotalChequeTemplate = (rowData) => {
    if (monedas && monedas.length > 0) {
      let moneda = monedas.findIndex((item) => item.id === rowData.id_moneda);
      if (moneda !== -1) {
        return (
          <Fragment>
            {monedas[moneda].simbolo + " " + rowData.importeTotal}
          </Fragment>
        );
      }
    }
  };

  const importeParcialChequeTemplate = (rowData) => {
    if (monedas && monedas.length > 0) {
      let moneda = monedas.findIndex((item) => item.id === rowData.id_moneda);
      if (moneda !== -1) {
        return (
          <Fragment>
            {monedas[moneda].simbolo + " " + rowData.importeCompartido}
          </Fragment>
        );
      }
    }
  };

  const fechaTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fecha)}</Fragment>;
  };

  const comprobanteTemplate = (rowData) => {
    return (
      <Fragment>
        {rowData.comprobante + "-" + rowData.puntoventa + "-" + rowData.numero}
      </Fragment>
    );
  };

  const comprobanteNcTemplate = (rowData) => {
    return (
      <Fragment>
        {rowData.comprobantenc
          ? rowData.comprobantenc.comprobante +
            "-" +
            rowData.comprobantenc.puntoventa +
            "-" +
            rowData.comprobantenc.numero
          : null}
      </Fragment>
    );
  };

  const chequeNumero_BancoCuenta_Nc_Ajustes_ACuenta_Moneda_Template = (
    rowData
  ) => {
    if (rowData.valor === "efectivo" && monedas && monedas.length > 0) {
      let moneda = monedas.findIndex((item) => item.id === rowData.id_moneda);
      return <Fragment>{monedas[moneda].nombre}</Fragment>;
    }
    if (rowData.valor === "cheques") {
      return (
        <Fragment>{rowData.chequeBanco + "-" + rowData.chequeNumero}</Fragment>
      );
    }
    if (rowData.valor === "bancos") {
      return <Fragment>{rowData.bancoCuenta}</Fragment>;
    }
    if (rowData.valor === "nc") {
      return (
        <Fragment>
          {rowData.ncComprobante} - {rowData.ncPuntoVenta} - {rowData.ncNumero}
        </Fragment>
      );
    }
    if (rowData.valor === "ajustes") {
      return (
        <Fragment>
          {rowData.ajusteComprobante} - {rowData.ajustePuntoVenta} -{" "}
          {rowData.ajusteNumero}
        </Fragment>
      );
    }
    if (rowData.valor === "acuenta") {
      return (
        <Fragment>
          {rowData.aCuentaComprobante} - {rowData.aCuentaPuntoVenta} -{" "}
          {rowData.aCuentaNumero}
        </Fragment>
      );
    }
    if (rowData.valor === "retenciones") {
      return <Fragment>{rowData.retencionTipo}</Fragment>;
    }
    if (rowData.valor === "compensaciones" && monedas && monedas.length > 0) {
      let moneda = monedas.findIndex((item) => item.id === rowData.id_moneda);
      return <Fragment>{monedas[moneda].nombre}</Fragment>;
    }
  };

  const saldoTemplate = (rowData) => {
    return (
      <Fragment>
        {Number(rowData.saldo).toLocaleString("en-US", {
          minimumFractionDigits: 2,
        })}
      </Fragment>
    );
  };

  const importeTemplate = (rowData) => {
    return (
      <Fragment>
        {" "}
        {Number(rowData.importeTotal).toLocaleString("en-US", {
          minimumFractionDigits: 2,
        })}
      </Fragment>
    );
  };

  const importeComisionTemplate = (rowData) => {
    return (
      <Fragment>
        {" "}
        {Number(rowData.comision).toLocaleString("en-US", {
          minimumFractionDigits: 2,
        })}
      </Fragment>
    );
  };

  const importeCompartidoTemplate = (rowData) => {
    return (
      <Fragment>
        {rowData.valor === "cheques" && rowData.chequeCompartido
          ? Number(rowData.chequeImporteCompartido).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })
          : null}
        {rowData.valor === "cheques" &&
        !rowData.chequeCompartido &&
        rowData.cotizacion
          ? Number(rowData.cotizacion).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })
          : null}
        {rowData.valor === "efectivo" && rowData.cotizacion
          ? Number(rowData.cotizacion).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })
          : null}
        {rowData.valor === "bancos" && rowData.cotizacion
          ? Number(rowData.cotizacion).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })
          : null}
      </Fragment>
    );
  };

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  /* INICIO IF */

  // Obtiene datos de los valores en las rendiciones para actualizar
  if (titular && comprobantes && bandComprobante ) {

    let local = window.localStorage.getItem("RENDICION_DIARIA");

    let array = [];
    if (local) {
      array = JSON.parse(local);
    }

    if (selectedRowComprobantes) {
      if (
        array &&
        array.length > 0 &&
        selectedRowComprobantes.id_cobrador !== array[0].cobradorId
      ) {
        toast.current.show({
          severity: "warn",
          summary: "Rendiciones",
          detail:
            "El comprobante no corresponse al cobrador " +
            array[0].cobradorId +
            ".",
          life: 3000,
        });
        setBandComprobante(false);
        setSelectedRowComprobantes(null);
        return;
      }
      obtenerComprobanteIdRendicionesPendientes({
        id: Number(selectedRowComprobantes.id),
      });

      obtenerComisionCobradorEmpresa({
        empresaId: selectedRowComprobantes.id_empresa,
        cobradorId: selectedRowComprobantes.id_cobrador,
      });
    }

    let indexComprobantes = null;
    let comprobante = null;
    let id_comprobante = null;

    if (!selectedRowComprobantes && comprobantes ) {
      indexComprobantes = comprobantes.findIndex(
        (item) => item.id === selectedRowValores.comprobanteId
      );

      if (indexComprobantes === -1) {
        return
      }

      comprobante = comprobantes[indexComprobantes];
      setSelectedRowComprobantes(comprobantes[indexComprobantes]);
      id_comprobante = comprobante.id;

      obtenerComisionCobradorEmpresa({
        empresaId: selectedRowValores.empresaId,
        cobradorId: selectedRowValores.cobradorId,
      });

    }

    if (!selectedRowValores) {
      setActiveIndex(0);
      handleChange(0);
    } else {
      if (selectedRowValores.valor === "efectivo") {
        setActiveIndex(0);
        handleChange(0);
      }
      if (selectedRowValores.valor === "cheques") {
        setActiveIndex(1);
        handleChange(1);
      }
      if (selectedRowValores.valor === "bancos") {
        setActiveIndex(2);
        handleChange(2);
      }
      if (selectedRowValores.valor === "compensaciones") {
        setActiveIndex(3);
        handleChange(3);
      }
      if (selectedRowValores.valor === "retenciones") {
        setActiveIndex(4);
        handleChange(4);
      }
      if (selectedRowValores.valor === "nc") {
        setActiveIndex(5);
        handleChange(5);
      }
      if (selectedRowValores.valor === "ajustes") {
        setActiveIndex(6);
        handleChange(6);
      }
      if (selectedRowValores.valor === "ac") {
        setActiveIndex(7);
        handleChange(7);
      }
    }
    setBandComprobante(false);
    setVisibleTotal(false);
    setBandComisiones(false);
  }

  //Verifica que si es manual no sea otro cobrador
  if (
    titular &&
    itemRendicion &&
    itemRendicion.id_titular.padStart(8, 0) === titular.id_titular &&
    rendicionDiaria &&
    rendicionDiaria.length > 0 &&
    titular.id_cobrador1_facturacion !== rendicionDiaria[0].cobradorId
  ) {
    guardarTitular(null);
    toastBC.current.show({
      severity: "warn",
      summary: "Rendiciones",
      detail:
        "El afiliado no pertenece al cobrador " +
        rendicionDiaria[0].cobradorId +
        ".",
      life: 3000,
    });
    /*
    guardarItemRendicion({
      ...itemRendicion,
      id_titular: null,
    });
    */
  }

  if (id !== "0") {
    //obtenerComprobanteId(id);
  }

  if (valueBarCode !== "" && valueBarCode.length === 10) {


    let num = Number(valueBarCode.slice(-8));

    obtenerComprobanteId({
      id: Number(num),
    });

    setValueBarCode("")
    /*
    setTimeout(() => {
      let elem = document.getElementById("botonLimpiarCodigoBarra");
      if (elem) {
        elem.click();
      }
    }, 500);
    */
  }

  if (comprobante && !titular && statusBarCode === 0) {
    let local = [];
    local.push(comprobante);
    guardarComprobantes(local);
    //setSelectedRowComprobantes(comprobantes[0])
    buscarTitular({
      titularId: comprobante.id_titular,
    });
    guardarItemRendicion({
      ...itemRendicion,
      id_titular: comprobante.id_titular,
    });
    setStatusBarCode(1);
    setBarCode(true);
  }

  if (
    comprobante &&
    statusBarCode === 1 &&
    comprobantes &&
    comprobantes.length === 1 &&
    titular
  ) {
    setSelectedRowComprobantes(comprobantes[0]);
    obtenerComisionCobradorEmpresa({
      empresaId: comprobantes[0].id_empresa,
      cobradorId: comprobantes[0].id_cobrador,
    });
    guardarComprobante(null);
    setStatusBarCode(0);
    if (efectivoDefault) {
      //setValue("efectivo");
      setActiveIndex(0);
      let mon = monedas.findIndex((item) => item.id === monedaPredeterminada);
      guardarMoneda({
        id_moneda: monedaPredeterminada,
        moneda: monedas[mon].nombre,
      });
      guardarEfectivoComprobante(comprobantes[0].saldo);
      setTimeout(() => {
        let elem = document.getElementById("confirmarEfectivo");
        if (elem) {
          elem.click();
        }
      }, 500);
    }
    if (bancoDefault) {
      setValue("bancos");
      if (cuentaBancoPredeterminada && cuentaPredeterminada) {
        guardarBancosComprobante({
          id_cuenta: cuentaBancoPredeterminada.id,
          cuenta: cuentaBancoPredeterminada.nombre,
          importe: comprobantes[0].saldo,
        });
        setTimeout(() => {
          let elem = document.getElementById("confirmarBancos");
          if (elem) {
            elem.click();
          }
        }, 500);
      }
    }
  }

  if (
    titular && itemRendicion && 
    titular.id_titular === itemRendicion.id_titular.padStart(8, 0) &&
    !bandInicio &&
    aCuenta &&
    aCuenta.length === 0 &&
    nc &&
    nc.length === 0
  ) {
    toast.current.clear();
    setTimeout(() => {
      setBandInicio(true);
      obtenerACuenta({ afiliadoId: titular.id });
      obtenerNc({ afiliadoId: titular.id });
    }, 500);
  }

  // FIN

  const messageACuenta = () => {
    let total = 0;
    for (var i = 0; i < aCuenta.length; i++) {
      total = total + Number(aCuenta[i].saldo);
    }
    if (total > 0) {
      toastBC.current.show({
        severity: "info",
        summary: "A Cuenta",
        detail: `Este afiliado tiene $ ${parseFloat(total).toFixed(
          2
        )} a cuenta para aplicar.`,
        sticky: false,
        life: 3000,
      });
    }
  };

  const messageNc = () => {
    let total = 0;
    for (var i = 0; i < nc.length; i++) {
      total = total + Number(nc[i].saldo);
    }
    if (total > 0) {
      toastBC.current.show({
        severity: "info",
        summary: "NC",
        detail: `Este afiliado tiene $ ${parseFloat(total).toFixed(
          2
        )} en nc para aplicar.`,
        sticky: false,
        life: 3000,
      });
    }
  };

  const renderHeaderCompartido = () => {
    return (
      <div>
        <Tag value="Cheques Compartidos"></Tag>
      </div>
    );
  };

  const renderFooterCompartido = () => {
    return (
      <div>
        <Button
          label="Aceptar"
          icon="pi pi-check"
          onClick={() => acceptCompartido()}
          className="p-button-text"
        />
        <Button
          label="Cerrar"
          icon="pi pi-times"
          onClick={() => exitCompartido()}
          className="p-button-text"
        />
      </div>
    );
  };

  const renderFooterParcial = () => {
    return (
      <div>
        <Button
          label="Aceptar"
          icon="pi pi-check"
          onClick={() => agregarCompartidoArray()}
          className="p-button-text"
        />
        <Button
          label="Cerrar"
          icon="pi pi-times"
          onClick={() => {
            setVisibleParcial(false);
            inicializar();
          }}
          className="p-button-text"
        />
      </div>
    );
  };

  const renderHeaderNc = () => {
    return (
      <div>
        <Tag value="Notas de Crédito"></Tag>
      </div>
    );
  };

  const renderFooterNc = () => {
    return (
      <div>
        <Button
          label="Aceptar"
          icon="pi pi-check"
          onClick={() => acceptNc()}
          className="p-button-text"
        />
        <Button
          label="Cerrar"
          icon="pi pi-times"
          onClick={() => exitNc()}
          className="p-button-text"
        />
      </div>
    );
  };

  const renderHeaderAjustes = () => {
    return (
      <div>
        <Tag value="Ajustes"></Tag>
      </div>
    );
  };

  const renderFooterAjustes = () => {
    return (
      <div>
        <Button
          label="Aceptar"
          icon="pi pi-check"
          onClick={() => acceptAjustes()}
          className="p-button-text"
        />
        <Button
          label="Cerrar"
          icon="pi pi-times"
          onClick={() => exitAjustes()}
          className="p-button-text"
        />
      </div>
    );
  };

  const renderHeaderACuenta = () => {
    return (
      <div>
        <Tag value="A Cuenta"></Tag>
      </div>
    );
  };

  const renderFooterACuenta = () => {
    return (
      <div>
        <Button
          label="Aceptar"
          icon="pi pi-check"
          onClick={() => acceptACuenta()}
          className="p-button-text"
        />
        <Button
          label="Cerrar"
          icon="pi pi-times"
          onClick={() => exitACuenta()}
          className="p-button-text"
        />
      </div>
    );
  };

  const exitCompartido = () => {
    setVisibleCompartido(false);
  };

  const acceptCompartido = () => {
    //setBandNuevoCheque(true);

    /*
    if (
      selectedRowComprobantes.id_titular !==
      selectedRowChequesCompartidos.id_titular
    ) {
      mensajeAlerta(
        "Cheques",
        "El titular del Comprobante es diferente al titular del cheque !.",
        "error"
      );
      return;
    }
    */

    guardarChequesComprobante({
      id: selectedRowChequesCompartidos.id,
      id_titular: selectedRowComprobantes.id_titular,
      id_moneda: selectedRowChequesCompartidos.id_moneda,
      fechaEmision: selectedRowChequesCompartidos.fechaEmision,
      fechaDeposito: selectedRowChequesCompartidos.fechaDeposito,
      numero: selectedRowChequesCompartidos.numero,
      id_banco: selectedRowChequesCompartidos.id_banco,
      banco: selectedRowChequesCompartidos.banco,
      noALaOrden:
        selectedRowChequesCompartidos.noALaOrden !== undefined
          ? selectedRowChequesCompartidos.noALaOrden
          : false,
      eCheq:
        selectedRowChequesCompartidos.eCheq !== undefined
          ? selectedRowChequesCompartidos.eCheq
          : false,
      compartido: selectedRowChequesCompartidos.compartido,
      importeTotal: selectedRowChequesCompartidos.importeTotal,
      importeCompartido: selectedRowChequesCompartidos.importeCompartido,
    });

    setVisibleCompartido(false);
    setCompartidoIndex(1);

    let elem = document.getElementById("botonAgregarChequesCompartidos");
    if (elem) {
      setTimeout(() => {
        elem.click();
      }, 500);
    }
  };

  const exitNc = () => {
    setSelectedRowNc(null);
    setVisibleNc(false);
  };

  const acceptNc = () => {
    const index = rendicionDiaria.findIndex(
      (item) => item.valor === "nc" && item.ncComprobanteId === selectedRowNc.id
    );

    if (index !== -1) {
      mensajeAlerta(
        "Notas de Crédito",
        "Esta nota de crédito ya ha sido utilizada !.",
        "error"
      );
      return;
    }

    if (selectedRowNc.id === selectedRowComprobantes.id) {
      mensajeAlerta(
        "Notas de Crédito",
        "La nota de crédito no corresponde al comprobante que se quiere aplicar !.",
        "error"
      );
      return;
    }

    if (selectedRowNc.comprobanteidnc !== selectedRowComprobantes.id) {
      mensajeAlerta(
        "Notas de Crédito",
        "La nota de crédito no corresponde al comprobante que se quiere aplicar !.",
        "error"
      );
      return;
    }

    addClick("nc");
    setVisibleNc(false);
  };

  const exitAjustes = () => {
    setSelectedRowAjustes(null);
    setVisibleAjustes(false);
  };

  const acceptAjustes = () => {
    const index = rendicionDiaria.findIndex(
      (item) =>
        item.valor === "ajustes" &&
        item.ajusteComprobanteId === selectedRowAjustes.id
    );

    if (index !== -1) {
      mensajeAlerta(
        "Ajustes",
        "Este comprobante de ajuste ya ha sido utilizado !.",
        "error"
      );
      return;
    }

    if (selectedRowAjustes.comprobanteidnc !== selectedRowComprobantes.id) {
      mensajeAlerta(
        "Ajustes",
        "El ajuste no corresponde al comprobante que se quiere aplicar !.",
        "error"
      );
      return;
    }

    addClick("ajustes");
    setVisibleAjustes(false);
  };

  const exitACuenta = () => {
    setSelectedRowACuenta(null);
    setVisibleACuenta(false);
  };

  const acceptACuenta = () => {
    const index = rendicionDiaria.findIndex(
      (item) =>
        item.valor === "acuenta" &&
        item.aCuentaComprobanteId === selectedRowACuenta.id
    );

    if (index !== -1) {
      mensajeAlerta(
        "A Cuenta",
        "Este comprobante a cuenta ya ha sido utilizado !.",
        "error"
      );
      return;
    }

    addClick("acuenta");
    setVisibleACuenta(false);
  };

  const agregarCompartidoArray = () => {
    if (Number(importeParcialCompartido) <= 0) {
      mensajeAlerta(
        "Cheques",
        "No puede ingresar un importe compartido menor que cero ó cero !.",
        "error"
      );
      return;
    }

    if (
      Number(importeParcialCompartido) >=
      Number(chequesComprobante.importeTotal)
    ) {
      mensajeAlerta(
        "Cheques",
        "No puede ingresar un cheque compartido con importe parcial igual o mayor al importe total !.",
        "error"
      );
      return;
    }

    if (
      Number(importeParcialCompartido) + Number(importeAcumuladoCompartido) >
      Number(chequesComprobante.importeTotal)
    ) {
      mensajeAlerta(
        "Cheques",
        "No puede ser mayor la suma de los importes parciales que el importe total del cheque !.",
        "error"
      );
      return;
    }

    if (
      Number(importeParcialCompartido) + Number(importeAcumuladoCompartido) <
      Number(chequesComprobante.importeTotal)
    ) {
      const copyArr = [...compartidoArray];
      const copyChequesComprobante = { ...chequesComprobante };
      copyArr.push(copyChequesComprobante);
      copyArr[copyArr.length - 1].importeCompartido = importeParcialCompartido;
      copyArr[copyArr.length - 1].id = uuidv4();
      copyArr[copyArr.length - 1].id_moneda = moneda.id_moneda;
      copyArr[copyArr.length - 1].id_titular =
        selectedRowComprobantes.id_titular;
      copyArr[copyArr.length - 1].utilizado = false;
      if (copyArr.length - 1 === 0) {
        guardarChequesComprobante({
          ...chequesComprobante,
          importeCompartido: importeParcialCompartido,
        });
      }

      guardarCompartidoArray(copyArr);

      let reduce = copyArr.reduce(
        (acumulador, actual) => acumulador + actual.importeCompartido,
        0
      );

      guardarImporteAcumuladoCompartido(reduce);
      guardarImporteParcialCompartido(0);
    }

    if (
      Number(importeParcialCompartido) + Number(importeAcumuladoCompartido) ===
      Number(chequesComprobante.importeTotal)
    ) {
      const copyArr = [...compartidoArray];
      const copyChequesComprobante = { ...chequesComprobante };
      copyArr.push(copyChequesComprobante);
      copyArr[copyArr.length - 1].importeCompartido = importeParcialCompartido;
      copyArr[copyArr.length - 1].id = uuidv4();
      copyArr[copyArr.length - 1].id_moneda = moneda.id_moneda;
      copyArr[copyArr.length - 1].id_titular =
        selectedRowComprobantes.id_titular;
      copyArr[copyArr.length - 1].utilizado = false;
      if (copyArr.length - 1 === 0) {
        guardarChequesComprobante({
          ...chequesComprobante,
          importeCompartido: importeParcialCompartido,
        });
      }

      let local = window.localStorage.getItem("CHEQUES_COMPARTIDOS");

      let array = [];
      if (local) {
        array = JSON.parse(local);
      }

      const totalArray = array.concat(copyArr);

      window.localStorage.setItem(
        "CHEQUES_COMPARTIDOS",
        JSON.stringify(totalArray)
      );

      setVisibleParcial(false);
      let elem = document.getElementById("botonAgregarChequesCompartidos");
      if (elem) {
        setTimeout(() => {
          elem.click();
        }, 500);
      }

      //addClick("cheques");
    }
  };

  const agregarCheque = () => {
    if (
      !chequesComprobante ||
      chequesComprobante.fechaEmision === undefined ||
      chequesComprobante.fechaEmision === null ||
      chequesComprobante.fechaEmision === 0
    ) {
      mensajeAlerta("Cheques", "La fecha de emisión es necesaria !.", "error");
      return;
    }

    if (
      !chequesComprobante ||
      chequesComprobante.fechaDeposito === undefined ||
      chequesComprobante.fechaDeposito === null ||
      chequesComprobante.fechaDeposito === 0
    ) {
      mensajeAlerta("Cheques", "La fecha de depósito es necesaria !.", "error");
      return;
    }

    if (
      !chequesComprobante ||
      chequesComprobante.numero === undefined ||
      chequesComprobante.numero === null ||
      chequesComprobante.numero === 0
    ) {
      mensajeAlerta("Cheques", "El número es necesario !.", "error");
      return;
    }

    if (
      !chequesComprobante ||
      chequesComprobante.id_banco === undefined ||
      chequesComprobante.id_banco === null ||
      chequesComprobante.id_banco === 0
    ) {
      mensajeAlerta("Cheques", "El banco es necesario !.", "error");
      return;
    }

    if (moneda && !moneda.id_moneda) {
      mensajeAlerta("Cheques", "Debe ingresar una moneda !.", "error");
      return;
    }

    if (moneda && moneda.id_moneda !== monedaPredeterminada && !cotizacion) {
      mensajeAlerta("Cheques", "Debe ingresar una cotización !.", "error");
      return;
    }

    if (
      !chequesComprobante ||
      chequesComprobante.importeTotal === undefined ||
      chequesComprobante.importeTotal === null
    ) {
      mensajeAlerta("Cheques", "El importe es necesario !.", "error");
      return;
    }

    if (chequesComprobante.importeTotal <= 0) {
      mensajeAlerta(
        "Cheques",
        "No puede ingresar un importe menor que cero ó cero !.",
        "error"
      );
      return;
    }

    if (
      chequesComprobante &&
      chequesComprobante.compartido &&
      !selectedRowValores
    ) {
      guardarImporteAcumuladoCompartido(0);
      guardarCompartidoArray([]);
      guardarImporteParcialCompartido(0);
      setCompartidoIndex(0);
      setVisibleParcial(true);
    } else {
      addClick("cheques");
    }
  };

  const obtenerChequesCompartidos = () => {
    let local = window.localStorage.getItem("CHEQUES_COMPARTIDOS");

    let array = [];
    if (local) {
      array = JSON.parse(local);
    }

    if (array.length > 0) {
      let chequesCompartidos = array.filter((cheque) => !cheque.utilizado);

      if (chequesCompartidos.length > 0) {
        guardarChequesCompartidos(chequesCompartidos);
        setVisibleCompartido(true);
      } else {
        mensajeAlerta(
          "Cheques Compartidos",
          "No se han creado cheques compartidos !.",
          "error"
        );
        return;
      }
    } else {
      mensajeAlerta(
        "Cheques Compartidos",
        "No se han creado cheques compartidos !.",
        "error"
      );
      return;
    }
  };

  const tagEfectivo = () => {
    let local = window.localStorage.getItem("RENDICION_DIARIA");

    let array = [];
    if (local) {
      array = JSON.parse(local);
    }

    let efectivo = new Array(monedas.length);
    efectivo.fill(0);

    for (var i = 0; i < array.length; i++) {
      if (array[i].valor === "efectivo") {
        let moneda = monedas.findIndex(
          (item) => item.id === array[i].id_moneda
        );
        efectivo[moneda] =
          Number(efectivo[moneda]) + Number(array[i].importeTotal);
      }
    }

    let row = "";
    let j = 0;
    for (var i = 0; i < efectivo.length; i++) {
      if (efectivo[i] !== 0) {
        j = j + 1;
        if (j > 1) {
          row = row + " - ";
        }
        row =
          row +
          monedas[i].simbolo +
          ": " +
          efectivo[i].toLocaleString("es-ar", {
            minimumFractionDigits: 2,
          });
      }
    }
    return row;
  };

  const tagCheques = () => {
    let local = window.localStorage.getItem("RENDICION_DIARIA");

    let array = [];
    if (local) {
      array = JSON.parse(local);
    }

    let cheques = new Array(monedas.length);
    cheques.fill(0);

    for (var i = 0; i < array.length; i++) {
      if (array[i].valor === "cheques") {
        if (array[i].chequeCompartido && array[i].chequeIndex !== 0) {
        } else {
          let moneda = monedas.findIndex(
            (item) => item.id === array[i].id_moneda
          );
          cheques[moneda] =
            Number(cheques[moneda]) + Number(array[i].importeTotal);
        }
      }
    }

    let row = "";
    let j = 0;
    for (var i = 0; i < cheques.length; i++) {
      if (cheques[i] !== 0) {
        j = j + 1;
        if (j > 1) {
          row = row + " - ";
        }
        row =
          row +
          monedas[i].simbolo +
          ": " +
          cheques[i].toLocaleString("es-ar", {
            minimumFractionDigits: 2,
          });
      }
    }
    return row;
  };

  const tagBancos = () => {
    let local = window.localStorage.getItem("RENDICION_DIARIA");

    let array = [];
    if (local) {
      array = JSON.parse(local);
    }

    let bancos = new Array(monedas.length);
    bancos.fill(0);

    for (var i = 0; i < array.length; i++) {
      if (array[i].valor === "bancos") {
        let moneda = monedas.findIndex(
          (item) => item.id === array[i].id_moneda
        );
        bancos[moneda] =
          Number(bancos[moneda]) + Number(array[i].importeTotalPesos);
      }
    }

    let row = "";
    let j = 0;
    for (var i = 0; i < bancos.length; i++) {
      if (bancos[i] !== 0) {
        j = j + 1;
        if (j > 1) {
          row = row + " - ";
        }
        row =
          row +
          monedas[i].simbolo +
          ": " +
          bancos[i].toLocaleString("es-ar", {
            minimumFractionDigits: 2,
          });
      }
    }
    return row;
  };

  const tagNc = () => {
    let local = window.localStorage.getItem("RENDICION_DIARIA");

    let array = [];
    if (local) {
      array = JSON.parse(local);
    }

    let nc = 0;

    for (var i = 0; i < array.length; i++) {
      if (array[i].valor === "nc") {
        nc = Number(nc) + Number(array[i].importeTotal);
      }
    }

    return nc.toLocaleString("es-ar", {
      minimumFractionDigits: 2,
    });
  };

  const tagRetenciones = () => {
    let local = window.localStorage.getItem("RENDICION_DIARIA");

    let array = [];
    if (local) {
      array = JSON.parse(local);
    }

    let retenciones = 0;

    for (var i = 0; i < array.length; i++) {
      if (array[i].valor === "retenciones") {
        retenciones = Number(retenciones) + Number(array[i].importeTotal);
      }
    }

    return retenciones.toLocaleString("es-ar", {
      minimumFractionDigits: 2,
    });
  };

  const tagCompensaciones = () => {
    let local = window.localStorage.getItem("RENDICION_DIARIA");

    let array = [];
    if (local) {
      array = JSON.parse(local);
    }

    let compensaciones = 0;

    for (var i = 0; i < array.length; i++) {
      if (array[i].valor === "compensaciones") {
        compensaciones = Number(compensaciones) + Number(array[i].importeTotal);
      }
    }

    return compensaciones.toLocaleString("es-ar", {
      minimumFractionDigits: 2,
    });
  };

  const tagAjustes = () => {
    let local = window.localStorage.getItem("RENDICION_DIARIA");

    let array = [];
    if (local) {
      array = JSON.parse(local);
    }

    let ajustes = 0;

    for (var i = 0; i < array.length; i++) {
      if (array[i].valor === "ajustes") {
        ajustes = Number(ajustes) + Number(array[i].importeTotal);
      }
    }

    return ajustes.toLocaleString("es-ar", {
      minimumFractionDigits: 2,
    });
  };

  const tagACuenta = () => {
    let local = window.localStorage.getItem("RENDICION_DIARIA");

    let array = [];
    if (local) {
      array = JSON.parse(local);
    }

    let acuenta = 0;

    for (var i = 0; i < array.length; i++) {
      if (array[i].valor === "acuenta") {
        acuenta = Number(acuenta) + Number(array[i].importeTotal);
      }
    }

    return acuenta.toLocaleString("es-ar", {
      minimumFractionDigits: 2,
    });
  };

  const tagTotal = () => {
    let local = window.localStorage.getItem("RENDICION_DIARIA");

    let array = [];
    if (local) {
      array = JSON.parse(local);
    }

    let efectivo = 0;
    let cheques = 0;
    let bancos = 0;

    for (var i = 0; i < array.length; i++) {
      if (array[i].valor === "efectivo") {
        efectivo = Number(efectivo) + Number(array[i].importeTotalPesos);
      }
      if (array[i].valor === "cheques") {
        if (array[i].chequeCompartido && array[i].chequeIndex !== 0) {
        } else {
          cheques = Number(cheques) + Number(array[i].importeTotalPesos);
        }
      }
      if (array[i].valor === "bancos") {
        bancos = Number(bancos) + Number(array[i].importeTotalPesos);
      }
    }
    return (efectivo + cheques + bancos).toLocaleString("es-ar", {
      minimumFractionDigits: 2,
    });
  };

  const tagComision = () => {
    let local = window.localStorage.getItem("RENDICION_DIARIA");

    let array = [];
    if (local) {
      array = JSON.parse(local);
    }

    let comision = 0;

    for (var i = 0; i < array.length; i++) {
      comision = Number(comision) + Number(array[i].comision);
    }

    return comision.toLocaleString("es-ar", {
      minimumFractionDigits: 2,
    });
  };

  //console.log(rendicionDiaria);
  //console.log(selectedRowValores)

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      <Toast ref={toastBC} position="bottom-center" />
      <ConfirmDialog />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Grid container xs={12} md={12}>
            {/* 1 Columna */}
            <Grid item xs={12} md={3} style={{ margin: "1em" }}>
              {!manual ? (
                <Fragment>
                  <Grid container xs={12} md={12}>
                    <Grid item xs={12} md={11}>
                      <TextField
                        variant="standard"
                        id="barCode"
                        value={valueBarCode}
                        label="Leer Código Barra"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          if (e.target.value) {
                            setValueBarCode(e.target.value);
                          }
                        }}
                        style={{ marginTop: "0vw" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <Button
                        icon="fa-regular fa-keyboard"
                        rounded
                        text
                        severity="success"
                        onClick={(e) => {
                          setManual(true);
                          guardarComprobantes([]);
                        }}
                        aria-label="Bookmark"
                        tooltip="Manual"
                        tooltipOptions={{
                          position: "bottom",
                          mouseTrack: true,
                          mouseTrackTop: 15,
                          className: "tooltip",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Fragment>
              ) : (
                <Fragment>
                  <Grid container xs={12} md={12}>
                    <Grid item xs={12} md={2.2}>
                      <TextField
                        value={
                          itemRendicion && itemRendicion.id_titular
                            ? itemRendicion.id_titular
                            : ""
                        }
                        required
                        variant="standard"
                        id="id_titular"
                        label="Titular"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                          fontSize: "10px",
                        }}
                        inputProps={{ style: { fontSize: "14px" } }} // font size of input text
                        disabled={id !== "0"}
                        onKeyDown={(e) => {
                          if (e.key == "Enter") {
                            let nuevoId = itemRendicion.id_titular.replace(
                              /_/g,
                              ""
                            );
                            nuevoId = nuevoId.padStart(8, 0);
                            buscarTitular({
                              titularId: nuevoId,
                            });
                            obtenerComprobantesAdeudados({
                              titularId: nuevoId,
                            });
                            inicializar();
                          }
                        }}
                        onChange={(e) => {
                          guardarItemRendicion({
                            ...itemRendicion,
                            id_titular: e.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={7.8}>
                      <TextField
                        value={titular ? titular.nombre_razonsocial : ""}
                        required
                        variant="standard"
                        id="titular"
                        label="Apellido y Nombre / Razón Social"
                        disabled
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{ style: { fontSize: "14px" } }} // font size of input text
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "black",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} md={1}>
                      <Button
                        icon="fa-solid fa-eraser"
                        rounded
                        text
                        severity="warning"
                        onClick={(e) => {
                          guardarItemRendicion({
                            ...itemRendicion,
                            id_titular: "",
                          });
                          guardarTitular({
                            ...titular,
                            nombre_razonsocial: "",
                          });

                          let elem = document.getElementById("id_titular");
                          if (elem) {
                            elem.focus();
                          }

                          guardarComprobantes([]);
                        }}
                        aria-label="Bookmark"
                        tooltip="Nuevo Afiliado"
                        tooltipOptions={{
                          position: "bottom",
                          mouseTrack: true,
                          mouseTrackTop: 15,
                          className: "tooltip",
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} md={1}>
                      <Button
                        icon="fa-solid fa-barcode"
                        rounded
                        text
                        severity="danger"
                        onClick={(e) => {
                          setManual(false);
                          guardarComprobantes([]);
                          setValueBarCode("")
                        }}
                        aria-label="Bookmark"
                        tooltip="Lector de Barra"
                        tooltipOptions={{
                          position: "bottom",
                          mouseTrack: true,
                          mouseTrackTop: 15,
                          className: "tooltip",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Fragment>
              )}
              {titular && comprobantes ? (
                <Fragment>
                  <Grid item xs={12} md={12}>
                    <div className="datatable-responsive-demo">
                      <div className="card">
                        <Tooltip
                          target=".export-buttons>button"
                          position="bottom"
                        />
                        <DataTable
                          value={comprobantes}
                          selection={selectedRowComprobantes}
                          onSelectionChange={(e) => {
                            setSelectedRowComprobantes(e.value);
                            //handleChange(0);
                            setSelectedRowValores(null);
                            //inicializar(e);
                            //obtenerValoresComprobante(e.value.id)
                            setBandComprobante(true);
                            //si// setBandNuevoCheque(false);
                            //si// setBandNuevoRetencion(false);

                            setTimeout(() => {
                              let elem = document.getElementById(
                                "botonComprobarCobrador"
                              );
                              if (elem) {
                                elem.click();
                              }
                            }, 500);
                          }}
                          selectionMode="single"
                          dataKey="id"
                          header={header}
                          className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                          style={{ fontSize: "10px", marginTop: "1vw" }}
                          //globalFilter={globalFilter}
                          emptyMessage="No hay datos."
                          paginator
                          paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                          currentPageReportTemplate="{first} a {last} de {totalRecords}"
                          rows={10}
                          size={"small"}
                          //rowsPerPageOptions={[10, 20, 50]}
                          //paginatorLeft={paginatorLeft}
                          //paginatorRight={paginatorRight}
                        >
                          <Column
                            field="id"
                            header="ID"
                            //body={idTemplate}
                            style={{ display: "none" }}
                          ></Column>
                          <Column
                            header="Fecha"
                            body={fechaTemplate}
                            sortable
                          ></Column>
                          <Column
                            field=""
                            header="Comprobante"
                            body={comprobanteTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="saldo"
                            header="Saldo"
                            body={saldoTemplate}
                            sortable
                          ></Column>
                        </DataTable>
                      </div>
                    </div>
                  </Grid>

                  {/*
                  <Grid item xs={12} md={12}>
                    <ButtonMui
                      onClick={(e) => confirmarComprobante()}
                      color="secondary"
                      type="button"
                      fullWidth
                      variant="contained"
                      startIcon={<CheckIcon />}
                      autoFocus
                    >
                      Confirmar
                    </ButtonMui>
                    <ButtonMui
                      onClick={(e) => exitClick()}
                      color="primary"
                      type="button"
                      fullWidth
                      variant="contained"
                      startIcon={<CloseIcon />}
                      autoFocus
                      style={{ marginTop: "0.2vw" }}
                    >
                      Salir
                    </ButtonMui>
                  </Grid>
                        */}
                </Fragment>
              ) : null}

              <Grid
                item
                xs={12}
                md={12}
                style={{ marginLeft: "0vw", paddingTop: "1vw" }}
              >
                <span style={{ fontSize: "12px", marginRight: "0.5vw" }}>
                  Efectivo por defecto
                </span>
                <FormControlLabel
                  value="yes"
                  control={
                    <Checkbox
                      color="primary"
                      checked={efectivoDefault}
                      style={{ marginLeft: "1vw" }}
                      disabled={bancoDefault}
                      onChange={(e) => {
                        setEfectivoDefault(e.target.checked);
                        setTimeout(() => {
                          let elem = document.getElementById("barCode");
                          if (elem) {
                            elem.focus();
                          }
                        }, 500);
                      }}
                    />
                  }
                  style={{ paddingLeft: "0vw" }}
                />

                <span style={{ fontSize: "12px", marginRight: "0.5vw" }}>
                  Banco por defecto
                </span>
                <FormControlLabel
                  value="yes"
                  control={
                    <Checkbox
                      color="primary"
                      checked={bancoDefault}
                      style={{ marginLeft: "1vw" }}
                      disabled={efectivoDefault}
                      onChange={(e) => {
                        setBancoDefault(e.target.checked);
                        setTimeout(() => {
                          let elem = document.getElementById("barCode");
                          if (elem) {
                            elem.focus();
                          }
                        }, 500);
                      }}
                    />
                  }
                  style={{ paddingLeft: "0vw" }}
                />
              </Grid>
            </Grid>

            {/*   
              <Grid item xs={12} md={12}>
                <ButtonMui
                  onClick={(e) => eliminarRendicion()}
                  color="error"
                  type="button"
                  fullWidth
                  variant="contained"
                  startIcon={<CloseIcon />}
                  autoFocus
                  style={{ marginTop: "1vw" }}
                >
                  Salir
                </ButtonMui>
              </Grid>
              */}

            {/* 2 Columna */}

            <Grid items xs={12} md={4.8} style={{ margin: "0.5em" }}>
              {titular &&
              comprobantes &&
              comprobantes.length > 0 &&
              selectedRowComprobantes ? (
                <Fragment>
                  <TabView
                    activeIndex={activeIndex}
                    onTabChange={(e) => {
                      setActiveIndex(e.index);
                      handleChange(e.index);
                    }}
                    style={{ fontSize: "small" }}
                  >
                    <TabPanel header="Efectivo">
                      <Fragment>
                        <Grid
                          container
                          xs={12}
                          md={12}
                          style={{
                            borderStyle: "solid",
                            borderWidth: "0.5px",
                            padding: "1vw",
                          }}
                        >
                          <Grid item xs={12} md={12}>
                            <label htmlFor="efectivo">Importe Efectivo:</label>
                            <InputNumber
                              inputId="efectivo"
                              value={
                                efectivoComprobante ? efectivoComprobante : null
                              }
                              onChange={(e) => {
                                if (e.value) {
                                  guardarEfectivoComprobante(e.value);
                                } else {
                                  guardarEfectivoComprobante(0);
                                }
                              }}
                              size={10}
                              mode="decimal"
                              locale="en-US"
                              minFractionDigits={2}
                              style={{
                                marginLeft: "0.7vw",
                                marginTop: "0.8vw",
                              }}
                            />
                            <Button
                              icon="pi pi-dollar"
                              onClick={(e) =>
                                guardarEfectivoComprobante(
                                  selectedRowComprobantes.saldo
                                )
                              }
                              className="p-button-rounded p-button-primary"
                              aria-label="Bookmark"
                              tooltip="Total"
                              tooltipOptions={{
                                position: "bottom",
                                mouseTrack: true,
                                mouseTrackTop: 15,
                                className: "tooltip",
                              }}
                              style={{ marginLeft: "1vw" }}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ padding: "0.2vw" }}
                          >
                            <Autocomplete
                              id="moneda"
                              size={"small"}
                              fullWidth
                              noOptionsText={"No hay opciones"}
                              options={monedas ? monedas : null}
                              value={
                                monedas && moneda && moneda.id_moneda !== null
                                  ? monedas[
                                      monedas.findIndex(
                                        (item) => item.id === moneda.id_moneda
                                      )
                                    ]
                                  : monedas[
                                      monedas.findIndex(
                                        (item) =>
                                          item.id === monedaPredeterminada
                                      )
                                    ]
                              }
                              getOptionLabel={(option) =>
                                option.nombre + " (" + option.simbolo + ")"
                              }
                              openOnFocus={true}
                              disablePortal
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Moneda *"
                                  variant="standard"
                                  InputLabelProps={{
                                    style: { color: "red" },
                                  }}
                                />
                              )}
                              //onKeyDown={handleEnter}
                              onChange={(event, value) => {
                                if (value) {
                                  guardarMoneda({
                                    ...moneda,
                                    id_moneda: value.id,
                                    moneda: value.nombre,
                                  });

                                  let mon = monedas.findIndex(
                                    (item) => item.id === value.id
                                  );

                                  let cotiza = [];
                                  cotiza.push({
                                    id: "1",
                                    nombre: "Compra",
                                    cotizacion: monedas[mon].compra,
                                  });
                                  cotiza.push({
                                    id: "2",
                                    nombre: "Venta",
                                    cotizacion: monedas[mon].venta,
                                  });
                                  cotiza.push({
                                    id: "3",
                                    nombre: "Tarjeta",
                                    cotizacion: monedas[mon].tarjeta,
                                  });
                                  guardarCotizaciones(cotiza);
                                } else {
                                  guardarMoneda({
                                    ...moneda,
                                    id_moneda: null,
                                    moneda: null,
                                  });
                                  guardarCotizaciones([]);
                                }
                              }}
                            />
                          </Grid>

                          {monedaPredeterminada &&
                          moneda &&
                          moneda.id_moneda &&
                          moneda.id_moneda !== monedaPredeterminada ? (
                            <Grid
                              item
                              xs={12}
                              md={6}
                              style={{ padding: "0.2vw" }}
                            >
                              <Autocomplete
                                id="cotizacion"
                                size={"small"}
                                fullWidth
                                noOptionsText={"No hay opciones"}
                                options={cotizaciones ? cotizaciones : null}
                                value={
                                  cotizacion &&
                                  cotizacion.cotizacionTipo !== null
                                    ? cotizaciones[
                                        cotizaciones.findIndex(
                                          (item) =>
                                            item.nombre ===
                                            cotizacion.cotizacionTipo
                                        )
                                      ]
                                    : null
                                }
                                getOptionLabel={(option) =>
                                  "$ " +
                                  option.cotizacion +
                                  " (" +
                                  option.nombre +
                                  ")"
                                }
                                openOnFocus={true}
                                disablePortal
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Cotización *"
                                    variant="standard"
                                    InputLabelProps={{
                                      style: { color: "red" },
                                    }}
                                  />
                                )}
                                //onKeyDown={handleEnter}
                                onChange={(event, value) => {
                                  if (value) {
                                    guardarCotizacion({
                                      ...cotizacion,
                                      cotizacion: value.cotizacion,
                                      cotizacionTipo: value.nombre,
                                    });
                                  } else {
                                    guardarCotizacion(null);
                                  }
                                }}
                              />
                            </Grid>
                          ) : null}
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <ButtonMui
                            id="confirmarEfectivo"
                            onClick={(e) => {
                              addClick("efectivo");
                            }}
                            color="success"
                            fullWidth
                            type="button"
                            variant="contained"
                            startIcon={<CheckIcon />}
                            autoFocus
                            /*
                          disabled={
                            selectedRowComprobantes &&
                            rendicionDiaria &&
                            rendicionDiaria.findIndex(
                              (item) =>
                                item.valor === "efectivo" &&
                                item.comprobanteId ===
                                  selectedRowComprobantes.id &&
                                !selectedRowValores
                            ) !== -1
                          }
                          */
                          >
                            Confirmar Efectivo
                          </ButtonMui>
                        </Grid>
                      </Fragment>
                    </TabPanel>

                    <TabPanel header="Cheques">
                      {bandNuevoCheque ? (
                        <Fragment>
                          <Grid
                            container
                            xs={12}
                            md={12}
                            style={{
                              borderStyle: "solid",
                              borderWidth: "0.5px",
                              padding: "1vw",
                              marginTop: "0.5vw",
                            }}
                          >
                            <Grid item xs={12} md={3}>
                              <TextField
                                id="fechaEmision"
                                value={
                                  chequesComprobante &&
                                  chequesComprobante.fechaEmision
                                    ? chequesComprobante.fechaEmision
                                    : null
                                }
                                label="Fecha Emisión"
                                type="date"
                                fullWidth
                                variant="standard"
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(e) =>
                                  guardarChequesComprobante({
                                    ...chequesComprobante,
                                    fechaEmision: e.target.value,
                                  })
                                }
                                //disabled={compartidoDisable ? true : false}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <TextField
                                id="fechaDeposito"
                                value={
                                  chequesComprobante &&
                                  chequesComprobante.fechaDeposito
                                    ? chequesComprobante.fechaDeposito
                                    : null
                                }
                                label="Fecha Depósito"
                                type="date"
                                fullWidth
                                variant="standard"
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(e) =>
                                  guardarChequesComprobante({
                                    ...chequesComprobante,
                                    fechaDeposito: e.target.value,
                                  })
                                }
                                //disabled={compartidoDisable ? true : false}
                                style={{ marginLeft: "1vw" }}
                              />
                            </Grid>

                            <Grid item xs={12} md={5}>
                              <label
                                style={{ marginLeft: "2vw" }}
                                htmlFor="numero"
                              >
                                Número
                              </label>
                              <InputNumber
                                inputId="numero"
                                value={
                                  chequesComprobante &&
                                  chequesComprobante.numero
                                    ? chequesComprobante &&
                                      chequesComprobante.numero
                                    : null
                                }
                                onChange={(e) => {
                                  if (e.value) {
                                    guardarChequesComprobante({
                                      ...chequesComprobante,
                                      numero: e.value,
                                    });
                                  } else {
                                    guardarChequesComprobante({
                                      ...chequesComprobante,
                                      numero: null,
                                    });
                                  }
                                }}
                                size={9}
                                //mode="decimal"
                                locale="en-US"
                                minFractionDigits={0}
                                style={{
                                  marginLeft: "0.7vw",
                                  marginTop: "0.8vw",
                                }}
                                //disabled={compartidoDisable ? true : false}
                              />
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <Autocomplete
                                id="bancos"
                                //disabled
                                fullWidth
                                options={
                                  bancos
                                    ? bancos.filter(
                                        (banco) =>
                                          banco.habilitado && !banco.propio
                                      )
                                    : null
                                }
                                disablePortal
                                value={banco}
                                getOptionLabel={(option) => option.nombre}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label="Banco *"
                                    size="small"
                                  />
                                )}
                                onChange={(event, value) => {
                                  if (value) {
                                    guardarBanco(value);
                                    guardarChequesComprobante({
                                      ...chequesComprobante,
                                      id_banco: value.id,
                                      banco: value.nombre,
                                    });
                                  } else {
                                    guardarBanco(null);
                                    guardarChequesComprobante({
                                      ...chequesComprobante,
                                      id_banco: null,
                                      banco: null,
                                    });
                                  }
                                }}
                                //disabled={compartidoDisable ? true : false}
                              />
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={6}
                              style={{ padding: "0.2vw" }}
                            >
                              <Autocomplete
                                id="moneda"
                                size={"small"}
                                fullWidth
                                noOptionsText={"No hay opciones"}
                                options={monedas ? monedas : null}
                                value={
                                  monedas && moneda && moneda.id_moneda
                                    ? monedas[
                                        monedas.findIndex(
                                          (item) => item.id === moneda.id_moneda
                                        )
                                      ]
                                    : monedas[
                                        monedas.findIndex(
                                          (item) =>
                                            item.id === monedaPredeterminada
                                        )
                                      ]
                                }
                                getOptionLabel={(option) =>
                                  option.nombre + " (" + option.simbolo + ")"
                                }
                                openOnFocus={true}
                                disablePortal
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Moneda *"
                                    variant="standard"
                                    InputLabelProps={{
                                      style: { color: "red" },
                                    }}
                                  />
                                )}
                                //onKeyDown={handleEnter}
                                onChange={(event, value) => {
                                  if (value) {
                                    guardarMoneda({
                                      ...moneda,
                                      id_moneda: value.id,
                                      moneda: value.nombre,
                                    });

                                    let mon = monedas.findIndex(
                                      (item) => item.id === value.id
                                    );

                                    let cotiza = [];
                                    cotiza.push({
                                      id: "1",
                                      nombre: "Compra",
                                      cotizacion: monedas[mon].compra,
                                    });
                                    cotiza.push({
                                      id: "2",
                                      nombre: "Venta",
                                      cotizacion: monedas[mon].venta,
                                    });
                                    cotiza.push({
                                      id: "3",
                                      nombre: "Tarjeta",
                                      cotizacion: monedas[mon].tarjeta,
                                    });
                                    guardarCotizaciones(cotiza);
                                  } else {
                                    guardarMoneda({
                                      ...moneda,
                                      id_moneda: null,
                                      moneda: null,
                                    });
                                    guardarCotizaciones([]);
                                  }
                                }}
                                disabled={
                                  chequesComprobante &&
                                  chequesComprobante.compartido &&
                                  selectedRowValores
                                    ? true
                                    : false
                                }
                              />
                            </Grid>

                            {monedaPredeterminada &&
                            moneda &&
                            moneda.id_moneda &&
                            moneda.id_moneda !== monedaPredeterminada ? (
                              <Grid
                                item
                                xs={12}
                                md={6}
                                style={{ padding: "0.2vw" }}
                              >
                                <Autocomplete
                                  id="cotizacion"
                                  size={"small"}
                                  fullWidth
                                  noOptionsText={"No hay opciones"}
                                  options={cotizaciones ? cotizaciones : null}
                                  value={
                                    cotizacion &&
                                    cotizacion.cotizacionTipo !== null
                                      ? cotizaciones[
                                          cotizaciones.findIndex(
                                            (item) =>
                                              item.nombre ===
                                              cotizacion.cotizacionTipo
                                          )
                                        ]
                                      : null
                                  }
                                  getOptionLabel={(option) =>
                                    "$ " +
                                    option.cotizacion +
                                    " (" +
                                    option.nombre +
                                    ")"
                                  }
                                  openOnFocus={true}
                                  disablePortal
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Cotización *"
                                      variant="standard"
                                      InputLabelProps={{
                                        style: { color: "red" },
                                      }}
                                    />
                                  )}
                                  //onKeyDown={handleEnter}
                                  onChange={(event, value) => {
                                    if (value) {
                                      guardarCotizacion({
                                        ...cotizacion,
                                        cotizacion: value.cotizacion,
                                        cotizacionTipo: value.nombre,
                                      });
                                    } else {
                                      guardarCotizacion(null);
                                    }
                                  }}
                                  disabled={
                                    chequesComprobante &&
                                    chequesComprobante.compartido &&
                                    selectedRowValores
                                      ? true
                                      : false
                                  }
                                />
                              </Grid>
                            ) : (
                              <Grid
                                item
                                xs={12}
                                md={6}
                                style={{ padding: "0.2vw" }}
                              />
                            )}

                            <Grid
                              item
                              xs={12}
                              md={4}
                              style={{ paddingLeft: 0, paddingTop: "1vw" }}
                            >
                              <FormControlLabel
                                value="yes"
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      chequesComprobante
                                        ? chequesComprobante.noALaOrden
                                        : null
                                    }
                                    style={{ marginLeft: "1vw" }}
                                    onChange={(e) =>
                                      guardarChequesComprobante({
                                        ...chequesComprobante,
                                        noALaOrden: e.target.checked,
                                      })
                                    }
                                  />
                                }
                                label="No a la Orden"
                                labelPlacement="start"
                                style={{ paddingLeft: "0vw" }}
                                //disabled={compartidoDisable ? true : false}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              style={{ paddingLeft: 0, paddingTop: "1vw" }}
                            >
                              <FormControlLabel
                                value="yes"
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      chequesComprobante
                                        ? chequesComprobante.eCheq
                                        : null
                                    }
                                    style={{ marginLeft: "1vw" }}
                                    onChange={(e) =>
                                      guardarChequesComprobante({
                                        ...chequesComprobante,
                                        eCheq: e.target.checked,
                                      })
                                    }
                                  />
                                }
                                label="Echeq"
                                labelPlacement="start"
                                style={{ paddingLeft: "0vw" }}
                                //disabled={compartidoDisable ? true : false}
                              />
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={4}
                              style={{ paddingLeft: 0, paddingTop: "1vw" }}
                            >
                              <FormControlLabel
                                value="yes"
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      chequesComprobante
                                        ? chequesComprobante.compartido
                                        : null
                                    }
                                    style={{ marginLeft: "1vw" }}
                                    onChange={(e) =>
                                      guardarChequesComprobante({
                                        ...chequesComprobante,
                                        compartido: e.target.checked,
                                      })
                                    }
                                  />
                                }
                                label="Compartido"
                                labelPlacement="start"
                                style={{ paddingLeft: "0vw" }}
                                disabled={
                                  chequesComprobante && selectedRowValores
                                    ? true
                                    : false
                                }
                              />
                            </Grid>

                            <Grid item xs={12} md={5}>
                              <label
                                style={{
                                  marginLeft: "0.0vw",
                                  fontSize: "medium",
                                }}
                                htmlFor="importeTotal"
                              >
                                Importe Total:
                              </label>
                              <InputNumber
                                inputId="importeTotal"
                                value={
                                  chequesComprobante
                                    ? chequesComprobante.importeTotal
                                    : null
                                }
                                onChange={(e) => {
                                  if (e.value) {
                                    guardarChequesComprobante({
                                      ...chequesComprobante,
                                      importeTotal: e.value,
                                    });
                                  } else {
                                    guardarChequesComprobante({
                                      ...chequesComprobante,
                                      importeTotal: 0,
                                    });
                                  }
                                }}
                                size={12}
                                mode="decimal"
                                locale="en-US"
                                minFractionDigits={2}
                                style={{
                                  marginLeft: "0.7vw",
                                  marginTop: "0.8vw",
                                }}
                                disabled={
                                  chequesComprobante &&
                                  chequesComprobante.compartido &&
                                  selectedRowValores
                                    ? true
                                    : false
                                }
                              />
                            </Grid>
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <ButtonMui
                              onClick={(e) => {
                                //addClick("cheques");
                                agregarCheque();
                              }}
                              color="success"
                              fullWidth
                              type="button"
                              variant="contained"
                              startIcon={<CheckIcon />}
                              autoFocus
                            >
                              Confirmar Cheque
                            </ButtonMui>
                          </Grid>
                        </Fragment>
                      ) : null}

                      <Grid item xs={12} md={12} style={{ marginTop: "0.5vw" }}>
                        <Button
                          icon="pi pi-plus-circle"
                          onClick={(e) => {
                            setSelectedRowValores(null);
                            guardarChequesComprobante(null);
                            guardarBanco(null);
                            let mon = monedas.findIndex(
                              (item) => item.id === monedaPredeterminada
                            );
                            guardarMoneda({
                              id_moneda: monedaPredeterminada,
                              moneda: monedas[mon].nombre,
                            });
                            guardarCotizaciones([]);
                            guardarCotizacion(null);
                            setBandNuevoCheque(true);
                          }}
                          className="p-button-rounded p-button-primary"
                          aria-label="Bookmark"
                          tooltip="Nuevo Cheque"
                          tooltipOptions={{
                            position: "bottom",
                            mouseTrack: true,
                            mouseTrackTop: 15,
                            className: "tooltip",
                          }}
                          style={{ marginLeft: "1vw" }}
                        />

                        <Button
                          icon="pi pi-share-alt"
                          onClick={(e) => {
                            setSelectedRowValores(null);
                            setSelectedRowChequesCompartidos(null);
                            obtenerChequesCompartidos();
                          }}
                          className="p-button-rounded p-button-primary"
                          aria-label="Bookmark"
                          tooltip="Cheque Compartido"
                          tooltipOptions={{
                            position: "bottom",
                            mouseTrack: true,
                            mouseTrackTop: 15,
                            className: "tooltip",
                          }}
                          style={{ marginLeft: "1vw" }}
                        />
                      </Grid>
                    </TabPanel>

                    <TabPanel header="Bancos">
                      <Grid
                        container
                        xs={12}
                        md={12}
                        style={{
                          borderStyle: "solid",
                          borderWidth: "0.5px",
                          padding: "1vw",
                        }}
                      >
                        <Grid item xs={12} md={12}>
                          <Autocomplete
                            id="bancos"
                            //disabled
                            fullWidth
                            options={
                              cuentas
                                ? cuentas.filter(
                                    (cuenta) =>
                                      cuenta.id_empresa ===
                                        selectedRowComprobantes.id_empresa &&
                                      cuenta.id_moneda
                                  )
                                : null
                            }
                            value={cuenta}
                            disablePortal
                            getOptionLabel={(option) => option.nombre}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Cuenta *"
                                size="small"
                              />
                            )}
                            onChange={(event, value) => {
                              if (value) {
                                guardarCuenta(value);
                                guardarBancosComprobante({
                                  ...bancosComprobante,
                                  id_cuenta: value.id,
                                  cuenta: value.nombre,
                                });
                              } else {
                                guardarCuenta(null);
                                guardarBancosComprobante({
                                  ...bancosComprobante,
                                  id_cuenta: null,
                                  cuenta: null,
                                });
                              }
                              setCuentaPredeterminada(false);
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6} style={{ padding: "0.2vw" }}>
                          <Autocomplete
                            id="moneda"
                            size={"small"}
                            fullWidth
                            noOptionsText={"No hay opciones"}
                            options={monedas ? monedas : null}
                            value={
                              monedas && moneda && moneda.id_moneda
                                ? monedas[
                                    monedas.findIndex(
                                      (item) => item.id === moneda.id_moneda
                                    )
                                  ]
                                : monedas[
                                    monedas.findIndex(
                                      (item) => item.id === monedaPredeterminada
                                    )
                                  ]
                            }
                            getOptionLabel={(option) =>
                              option.nombre + " (" + option.simbolo + ")"
                            }
                            openOnFocus={true}
                            disablePortal
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Moneda *"
                                variant="standard"
                                InputLabelProps={{
                                  style: { color: "red" },
                                }}
                              />
                            )}
                            //onKeyDown={handleEnter}
                            onChange={(event, value) => {
                              if (value) {
                                guardarMoneda({
                                  ...moneda,
                                  id_moneda: value.id,
                                  moneda: value.nombre,
                                });

                                let mon = monedas.findIndex(
                                  (item) => item.id === value.id
                                );

                                let cotiza = [];
                                cotiza.push({
                                  id: "1",
                                  nombre: "Compra",
                                  cotizacion: monedas[mon].compra,
                                });
                                cotiza.push({
                                  id: "2",
                                  nombre: "Venta",
                                  cotizacion: monedas[mon].venta,
                                });
                                cotiza.push({
                                  id: "3",
                                  nombre: "Tarjeta",
                                  cotizacion: monedas[mon].tarjeta,
                                });
                                guardarCotizaciones(cotiza);
                              } else {
                                guardarMoneda({
                                  ...moneda,
                                  id_moneda: null,
                                  moneda: null,
                                });
                                guardarCotizaciones([]);
                              }
                            }}
                          />
                        </Grid>

                        {monedaPredeterminada &&
                        moneda &&
                        moneda.id_moneda &&
                        moneda.id_moneda !== monedaPredeterminada ? (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ padding: "0.2vw" }}
                          >
                            <Autocomplete
                              id="cotizacion"
                              size={"small"}
                              fullWidth
                              noOptionsText={"No hay opciones"}
                              options={cotizaciones ? cotizaciones : null}
                              value={
                                cotizacion && cotizacion.cotizacionTipo !== null
                                  ? cotizaciones[
                                      cotizaciones.findIndex(
                                        (item) =>
                                          item.nombre ===
                                          cotizacion.cotizacionTipo
                                      )
                                    ]
                                  : null
                              }
                              getOptionLabel={(option) =>
                                "$ " +
                                option.cotizacion +
                                " (" +
                                option.nombre +
                                ")"
                              }
                              openOnFocus={true}
                              disablePortal
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Cotización *"
                                  variant="standard"
                                  InputLabelProps={{
                                    style: { color: "red" },
                                  }}
                                />
                              )}
                              //onKeyDown={handleEnter}
                              onChange={(event, value) => {
                                if (value) {
                                  guardarCotizacion({
                                    ...cotizacion,
                                    cotizacion: value.cotizacion,
                                    cotizacionTipo: value.nombre,
                                  });
                                } else {
                                  guardarCotizacion(null);
                                }
                              }}
                            />
                          </Grid>
                        ) : null}

                        <Grid item xs={12} md={12}>
                          <label htmlFor="bancos">Importe Bancos:</label>
                          <InputNumber
                            inputId="bancos"
                            value={
                              bancosComprobante
                                ? bancosComprobante.importe
                                : null
                            }
                            onChange={(e) => {
                              if (e.value) {
                                guardarBancosComprobante({
                                  ...bancosComprobante,
                                  importe: e.value,
                                });
                              } else {
                                guardarBancosComprobante({
                                  ...bancosComprobante,
                                  importe: 0,
                                });
                              }
                            }}
                            size={10}
                            mode="decimal"
                            locale="en-US"
                            minFractionDigits={2}
                            style={{
                              marginLeft: "0.7vw",
                              marginTop: "0.8vw",
                            }}
                          />
                          <Button
                            icon="pi pi-dollar"
                            onClick={(e) => {
                              guardarBancosComprobante({
                                ...bancosComprobante,
                                importe: selectedRowComprobantes.saldo,
                              });
                            }}
                            className="p-button-rounded p-button-primary"
                            aria-label="Bookmark"
                            tooltip="Total"
                            tooltipOptions={{
                              position: "bottom",
                              mouseTrack: true,
                              mouseTrackTop: 15,
                              className: "tooltip",
                            }}
                            style={{ marginLeft: "1vw" }}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          marginLeft: "0.5vw",
                          paddingTop: "1vw",
                          paddingBottom: "1vw",
                        }}
                      >
                        <span
                          style={{ fontSize: "12px", marginRight: "0.5vw" }}
                        >
                          {cuentaPredeterminada
                            ? "Cuenta Predeterminada - " +
                              cuentaBancoPredeterminada.nombre
                            : "Cuenta Predeterminada"}
                        </span>
                        <FormControlLabel
                          value="yes"
                          control={
                            <Checkbox
                              color="primary"
                              checked={cuentaPredeterminada}
                              style={{ marginLeft: "1vw" }}
                              disabled={
                                !bancosComprobante ||
                                bancosComprobante.id_cuenta === undefined ||
                                bancosComprobante.id_cuenta === null
                              }
                              onChange={(e) => {
                                setCuentaPredeterminada(e.target.checked);
                                guardarCuentaBancoPredeterminada(cuenta);
                              }}
                            />
                          }
                          style={{ paddingLeft: "0vw" }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <ButtonMui
                          id="confirmarBancos"
                          onClick={(e) => {
                            addClick("bancos");
                          }}
                          color="success"
                          fullWidth
                          type="button"
                          variant="contained"
                          startIcon={<CheckIcon />}
                          autoFocus
                        >
                          Confirmar Bancos
                        </ButtonMui>
                      </Grid>
                    </TabPanel>

                    <TabPanel header="Compensaciones">
                      <Grid
                        container
                        xs={12}
                        md={12}
                        style={{
                          borderStyle: "solid",
                          borderWidth: "0.5px",
                          padding: "1vw",
                        }}
                      >
                        <Grid item xs={12} md={12}>
                          <label htmlFor="compensaciones">
                            Importe Compensaciones:
                          </label>
                          <InputNumber
                            inputId="compensaciones"
                            value={
                              compensacionesComprobante
                                ? compensacionesComprobante
                                : null
                            }
                            onChange={(e) => {
                              if (e.value) {
                                guardarCompensacionesComprobante(e.value);
                              } else {
                                guardarCompensacionesComprobante(0);
                              }
                            }}
                            size={10}
                            mode="decimal"
                            locale="en-US"
                            minFractionDigits={2}
                            style={{
                              marginLeft: "0.7vw",
                              marginTop: "0.8vw",
                            }}
                          />
                          <Button
                            icon="pi pi-dollar"
                            onClick={(e) =>
                              guardarCompensacionesComprobante(
                                selectedRowComprobantes.saldo
                              )
                            }
                            className="p-button-rounded p-button-primary"
                            aria-label="Bookmark"
                            tooltip="Total"
                            tooltipOptions={{
                              position: "bottom",
                              mouseTrack: true,
                              mouseTrackTop: 15,
                              className: "tooltip",
                            }}
                            style={{ marginLeft: "1vw" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <ButtonMui
                          onClick={(e) => {
                            addClick("compensaciones");
                          }}
                          color="success"
                          fullWidth
                          type="button"
                          variant="contained"
                          startIcon={<CheckIcon />}
                          autoFocus
                        >
                          Confirmar Compensaciones
                        </ButtonMui>
                      </Grid>
                    </TabPanel>

                    <TabPanel header="Retenciones">
                      <Grid item xs={12} md={12} style={{ marginTop: "0.5vw" }}>
                        <Button
                          icon="pi pi-plus-circle"
                          onClick={(e) => {
                            guardarRetencionesComprobante({
                              fecha: null,
                              afiliadoId: titular.id,
                              titularId: titular.id_titular,
                              sucursal: null,
                              numero: null,
                              id_tipo: null,
                              tipo: null,
                              importe: 0,
                            });
                            guardarRetencion(null);
                            setSelectedRowValores(null);
                            setBandNuevoRetencion(true);
                          }}
                          className="p-button-rounded p-button-primary"
                          aria-label="Bookmark"
                          tooltip="Nueva Retención"
                          tooltipOptions={{
                            position: "bottom",
                            mouseTrack: true,
                            mouseTrackTop: 15,
                            className: "tooltip",
                          }}
                          style={{ marginLeft: "1vw" }}
                        />
                      </Grid>
                      {bandNuevoRetencion ? (
                        <Fragment>
                          <Grid
                            container
                            xs={12}
                            md={12}
                            style={{
                              borderStyle: "solid",
                              borderWidth: "0.5px",
                              padding: "1vw",
                              marginTop: "0.5vw",
                            }}
                          >
                            <Grid item xs={12} md={3}>
                              <TextField
                                id="fecha"
                                value={
                                  retencionesComprobante &&
                                  retencionesComprobante.fecha
                                    ? retencionesComprobante.fecha
                                    : null
                                }
                                label="Fecha"
                                type="date"
                                fullWidth
                                variant="standard"
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(e) =>
                                  guardarRetencionesComprobante({
                                    ...retencionesComprobante,
                                    fecha: e.target.value,
                                  })
                                }
                              />
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <label
                                style={{ marginLeft: "2vw" }}
                                htmlFor="sucursal"
                              >
                                Número
                              </label>
                              <InputNumber
                                inputId="sucursal"
                                value={
                                  retencionesComprobante &&
                                  retencionesComprobante.sucursal
                                    ? retencionesComprobante &&
                                      retencionesComprobante.sucursal
                                    : null
                                }
                                onChange={(e) => {
                                  if (e.value) {
                                    guardarRetencionesComprobante({
                                      ...retencionesComprobante,
                                      sucursal: e.value,
                                    });
                                  } else {
                                    guardarRetencionesComprobante({
                                      ...retencionesComprobante,
                                      sucursal: null,
                                    });
                                  }
                                }}
                                size={4}
                                //mode="decimal"
                                locale="en-US"
                                minFractionDigits={0}
                                style={{
                                  marginLeft: "0.7vw",
                                  marginTop: "0.8vw",
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <InputNumber
                                inputId="numero"
                                value={
                                  retencionesComprobante &&
                                  retencionesComprobante.numero
                                    ? retencionesComprobante &&
                                      retencionesComprobante.numero
                                    : null
                                }
                                onChange={(e) => {
                                  if (e.value) {
                                    guardarRetencionesComprobante({
                                      ...retencionesComprobante,
                                      numero: e.value,
                                    });
                                  } else {
                                    guardarRetencionesComprobante({
                                      ...retencionesComprobante,
                                      numero: null,
                                    });
                                  }
                                }}
                                size={8}
                                //mode="decimal"
                                locale="en-US"
                                minFractionDigits={0}
                                style={{
                                  marginLeft: "0.7vw",
                                  marginTop: "0.8vw",
                                }}
                              />
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <Autocomplete
                                id="retenciones"
                                //disabled
                                fullWidth
                                options={
                                  tiposRetenciones
                                    ? tiposRetenciones.filter(
                                        (tipo) => tipo.habilitado
                                      )
                                    : null
                                }
                                disablePortal
                                value={retencion}
                                getOptionLabel={(option) => option.nombre}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label="Tipos de Retenciones*"
                                    size="small"
                                  />
                                )}
                                onChange={(event, value) => {
                                  if (value) {
                                    guardarRetencion(value);
                                    guardarRetencionesComprobante({
                                      ...retencionesComprobante,
                                      id_tipo: value.id,
                                      tipo: value.nombre,
                                    });
                                  } else {
                                    guardarRetencion(null);
                                    guardarRetencionesComprobante({
                                      ...retencionesComprobante,
                                      id_tipo: null,
                                      tipo: null,
                                    });
                                  }
                                }}
                              />
                            </Grid>

                            <Grid item xs={12} md={5}>
                              <label
                                style={{ marginLeft: "0.0vw" }}
                                htmlFor="importe"
                              >
                                Importe:
                              </label>
                              <InputNumber
                                inputId="importe"
                                value={
                                  retencionesComprobante
                                    ? retencionesComprobante.importe
                                    : null
                                }
                                onChange={(e) => {
                                  if (e.value) {
                                    guardarRetencionesComprobante({
                                      ...retencionesComprobante,
                                      importe: e.value,
                                    });
                                  } else {
                                    guardarRetencionesComprobante({
                                      ...chequesComprobante,
                                      importe: 0,
                                    });
                                  }
                                }}
                                size={10}
                                mode="decimal"
                                locale="en-US"
                                minFractionDigits={2}
                                style={{
                                  marginLeft: "0.7vw",
                                  marginTop: "0.8vw",
                                }}
                              />
                            </Grid>
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <ButtonMui
                              onClick={(e) => {
                                addClick("retenciones");
                              }}
                              color="success"
                              fullWidth
                              type="button"
                              variant="contained"
                              startIcon={<CheckIcon />}
                              autoFocus
                            >
                              Confirmar Retención
                            </ButtonMui>
                          </Grid>
                        </Fragment>
                      ) : null}
                    </TabPanel>
                    {/*}
                      <TabPanel header="NC">
                        <Grid
                          item
                          xs={12}
                          md={12}
                          style={{ marginTop: "0.5vw" }}
                        >
                          <Button
                            icon="pi pi-folder-open"
                            onClick={(e) => {
                              setVisibleNc(true);
                            }}
                            className="p-button-rounded p-button-primary"
                            aria-label="Bookmark"
                            tooltip="Abrir NC"
                            tooltipOptions={{
                              position: "bottom",
                              mouseTrack: true,
                              mouseTrackTop: 15,
                              className: "tooltip",
                            }}
                            style={{ marginLeft: "1vw" }}
                          />
                        </Grid>
                      </TabPanel>
                      <TabPanel header="Ajustes">
                        <Grid
                          item
                          xs={12}
                          md={12}
                          style={{ marginTop: "0.5vw" }}
                        >
                          <Button
                            icon="pi pi-folder-open"
                            onClick={(e) => {
                              setVisibleAjustes(true);
                            }}
                            className="p-button-rounded p-button-primary"
                            aria-label="Bookmark"
                            tooltip="Abrir Ajustes"
                            tooltipOptions={{
                              position: "bottom",
                              mouseTrack: true,
                              mouseTrackTop: 15,
                              className: "tooltip",
                            }}
                            style={{ marginLeft: "1vw" }}
                          />
                        </Grid>
                      </TabPanel>
                      */}
                    <TabPanel header="A Cuenta">
                      <Grid item xs={12} md={12} style={{ marginTop: "0.5vw" }}>
                        <Button
                          icon="pi pi-folder-open"
                          onClick={(e) => {
                            setVisibleACuenta(true);
                          }}
                          className="p-button-rounded p-button-primary"
                          aria-label="Bookmark"
                          tooltip="Abrir A Cuenta"
                          tooltipOptions={{
                            position: "bottom",
                            mouseTrack: true,
                            mouseTrackTop: 15,
                            className: "tooltip",
                          }}
                          style={{ marginLeft: "1vw" }}
                        />
                      </Grid>
                    </TabPanel>
                  </TabView>
                </Fragment>
              ) : null}

              {/* 4 TOTALES */}

              {visibleTotal ? (
                <Fragment>
                  <Grid item xs={12} md={12} style={{ marginTop: "0.5vw" }}>
                    <Typography variant="h4" gutterBottom>
                      Totales
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={12} style={{ marginTop: "0.5vw" }}>
                    <Tag value="Efectivo: "></Tag>
                    <Tag
                      value={
                        monedas && monedas.length > 0 ? tagEfectivo() : null
                      }
                    ></Tag>
                  </Grid>
                  <Grid item xs={12} md={12} style={{ marginTop: "0.5vw" }}>
                    <Tag
                      value="Cheques: "
                      //style={{ marginLeft: "2vw" }}
                    ></Tag>
                    <Tag
                      value={
                        monedas && monedas.length > 0 ? tagCheques() : null
                      }
                    ></Tag>
                  </Grid>
                  <Grid item xs={12} md={12} style={{ marginTop: "0.5vw" }}>
                    <Tag
                      value="Bancos: "
                      //style={{ marginLeft: "2vw" }}
                    ></Tag>
                    <Tag
                      value={monedas && monedas.length > 0 ? tagBancos() : null}
                    ></Tag>
                  </Grid>
                  <Grid item xs={12} md={12} style={{ marginTop: "0.5vw" }}>
                    <Tag value={"Retenciones: $ " + tagRetenciones()}></Tag>
                  </Grid>

                  <Grid item xs={12} md={12} style={{ marginTop: "0.5vw" }}>
                    <Tag severity="success" value="Total: $"></Tag>
                    <Tag
                      severity="success"
                      value={monedas && monedas.length > 0 ? tagTotal() : null}
                    ></Tag>
                  </Grid>
                  <Grid item xs={12} md={12} style={{ marginTop: "0.5vw" }}>
                    <Tag severity="warning" value="Comisión: $ "></Tag>
                    <Tag
                      severity="warning"
                      value={
                        monedas && monedas.length > 0 ? tagComision() : null
                      }
                    ></Tag>
                  </Grid>

                  <Grid item xs={12} md={12} style={{ marginTop: "0.5vw" }}>
                    <Tag
                      value={"Compensaciones: $ " + tagCompensaciones()}
                    ></Tag>
                  </Grid>

                  {/*    
                  <Grid item xs={12} md={12} style={{ marginTop: "0.5vw" }}>
                    <Tag value={"NC: $ " + tagNc()}></Tag>
                  </Grid>

                  <Grid item xs={12} md={12} style={{ marginTop: "0.5vw" }}>
                    <Tag value={"Ajustes: $ " + tagAjustes()}></Tag>
                  </Grid>
                  */}
                  <Grid item xs={12} md={12} style={{ marginTop: "0.5vw" }}>
                    <Tag value={"A Cuenta: $ " + tagACuenta()}></Tag>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <ButtonMui
                      onClick={(e) => confirmarRendicion()}
                      color="success"
                      type="button"
                      fullWidth
                      size="small"
                      variant="contained"
                      startIcon={<CheckIcon />}
                      autoFocus
                      style={{ marginTop: "1vw" }}
                    >
                      Confirmar Rendición
                    </ButtonMui>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <ButtonMui
                      onClick={(e) => eliminarRendicion()}
                      color="error"
                      type="button"
                      fullWidth
                      size="small"
                      variant="contained"
                      startIcon={<CloseIcon />}
                      autoFocus
                      style={{ marginTop: "1vw" }}
                    >
                      Salir
                    </ButtonMui>
                  </Grid>
                </Fragment>
              ) : null}
            </Grid>

            {/* 3 Columna */}
            {rendicionDiaria ? (
              <Fragment>
                <Grid items xs={12} md={3.7} style={{ paddingLeft: "0w" }}>
                  <div className="datatable-responsive-demo">
                    <div className="card">
                      <Tooltip
                        target=".export-buttons>button"
                        position="bottom"
                      />
                      <DataTable
                        value={rendicionDiaria}
                        selection={selectedRowValores}
                        onSelectionChange={(e) => {
                          setSelectedRowValores(e.value);
                          setSelectedRowComprobantes(null);
                          buscarTitular({
                            titularId: e.value.titularId,
                          });
                          obtenerComprobantesAdeudados({
                            titularId: e.value.titularId,
                          });
                          guardarItemRendicion({
                            ...itemRendicion,
                            id_titular: e.value.titularId,
                          });
                          //obtenerValoresComprobante(e.value.id)
                          setBandComprobante(true);
                        }}
                        selectionMode="single"
                        dataKey="id"
                        header={headerValores}
                        className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                        style={{ fontSize: "10px", marginTop: "1vw" }}
                        globalFilter={globalFilter}
                        emptyMessage="No hay datos."
                        paginator
                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        currentPageReportTemplate="{first} a {last} de {totalRecords}"
                        rows={13}
                        size={"small"}
                        //rowsPerPageOptions={[10, 20, 50]}
                        //paginatorLeft={paginatorLeft}
                        //paginatorRight={paginatorRight}
                      >
                        <Column
                          field="id"
                          header="ID"
                          //body={idTemplate}
                          style={{ display: "none" }}
                        ></Column>
                        <Column
                          field="valor"
                          header="Valor"
                          body={valorTemplate}
                          //style={{ width: "10%" }}
                          //sortable
                        ></Column>
                        <Column
                          field="titularId"
                          header="Socio"
                          //body={fechaTemplate}
                          //style={{ width: "10%" }}
                          //sortable
                        ></Column>
                        <Column
                          field=""
                          header="Comp."
                          body={comprobanteTemplate}
                          //style={{ width: "20%" }}
                          //sortable
                        ></Column>
                        <Column
                          field=""
                          header="Datos Valores"
                          body={
                            chequeNumero_BancoCuenta_Nc_Ajustes_ACuenta_Moneda_Template
                          }
                          //style={{ width: "40%" }}
                          //sortable
                        ></Column>

                        <Column
                          field="importeTotal"
                          header="Importe"
                          //style={{ width: "10%" }}
                          body={importeTemplate}
                          //sortable
                        ></Column>
                        {bandComisiones ? (
                          <Column
                            field="comision"
                            header="Comisión"
                            //style={{ width: "10%" }}
                            body={importeComisionTemplate}
                            //sortable
                          ></Column>
                        ) : (
                          <Column
                            field="chequeImporteCompartido"
                            header="Compart/Cotiza"
                            //style={{ width: "10%" }}
                            body={importeCompartidoTemplate}
                            //sortable
                          ></Column>
                        )}
                      </DataTable>
                    </div>
                  </div>
                </Grid>
              </Fragment>
            ) : null}
          </Grid>

          <div className="dialog-demo">
            <Dialog
              //header={renderHeaderCompartido()}
              visible={visibleCompartido}
              footer={renderFooterCompartido()}
              closable={false}
              breakpoints={{ "960px": "75vw" }}
              style={{
                width: "60vw",
                paddingBottom: "0",
                overflowY: "visible",
              }}
            >
              <div className="datatable-responsive-demo">
                <div className="card">
                  <Tooltip target=".export-buttons>button" position="bottom" />
                  <DataTable
                    value={chequesCompartidos}
                    selection={selectedRowChequesCompartidos}
                    onSelectionChange={(e) => {
                      setSelectedRowChequesCompartidos(e.value);
                    }}
                    selectionMode="single"
                    dataKey="id"
                    header={renderHeaderCompartido}
                    className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                    style={{ fontSize: "small", marginTop: "1vw" }}
                    //globalFilter={globalFilter}
                    size="small"
                    emptyMessage="No hay datos."
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    rows={10}
                    //rowsPerPageOptions={[10, 20, 50]}
                    //paginatorLeft={paginatorLeft}
                    //paginatorRight={paginatorRight}
                  >
                    <Column
                      field="id"
                      header="ID"
                      //body={idTemplate}
                      style={{ display: "none" }}
                    ></Column>
                    <Column
                      field="banco"
                      header="Banco"
                      //body={valorTemplate}
                      //style={{ width: "10%" }}
                      //sortable
                    ></Column>
                    <Column
                      field="numero"
                      header="Nº Cheque"
                      //body={numeroTemplate}
                      //style={{ width: "20%" }}
                      //sortable
                    ></Column>
                    <Column
                      field="importeTotal"
                      header="Imp. Total"
                      body={importeTotalChequeTemplate}
                      //style={{ width: "10%" }}
                      //sortable
                    ></Column>
                    <Column
                      field="importeCompartido"
                      header="Imp. Parcial"
                      body={importeParcialChequeTemplate}
                      //style={{ width: "10%" }}
                      //sortable
                    ></Column>
                    {/*
                    <Column
                      field="id_titular"
                      header="Titular"
                      //body={valorTemplate}
                      //style={{ width: "10%" }}
                      //sortable
                    ></Column>
                    */}
                  </DataTable>
                </div>
              </div>
            </Dialog>
          </div>

          <div className="dialog-demo">
            <Dialog
              //header={renderHeaderCompartido()}
              visible={visibleParcial}
              footer={renderFooterParcial()}
              closable={false}
              breakpoints={{ "960px": "60vw" }}
              style={{
                width: "40vw",
                paddingBottom: "0",
                overflowY: "visible",
              }}
            >
              <Grid item xs={12} md={5}>
                <label
                  style={{ marginLeft: "0.0vw", fontWeight: "bold" }}
                  htmlFor="importe"
                >
                  {"Importe Parcial " + (compartidoArray.length + 1) + ":"}
                </label>
                <InputNumber
                  inputId="importeCompartido"
                  value={importeParcialCompartido}
                  onChange={(e) => {
                    if (e.value) {
                      guardarImporteParcialCompartido(e.value);
                    } else {
                      guardarRetencionesComprobante(0);
                    }
                  }}
                  size={12}
                  mode="decimal"
                  locale="en-US"
                  minFractionDigits={2}
                  style={{
                    marginLeft: "0.7vw",
                    marginTop: "0.8vw",
                  }}
                />
              </Grid>
              {compartidoArray.length === 0 && selectedRowComprobantes ? (
                <Fragment>
                  <br />
                  <label
                    style={{ marginLeft: "0.0vw", fontWeight: "bold" }}
                    htmlFor="importe"
                  >
                    {"Aplicar a comprobante " +
                      selectedRowComprobantes.comprobante +
                      "-" +
                      selectedRowComprobantes.numero +
                      " con saldo " +
                      selectedRowComprobantes.saldo}
                  </label>
                </Fragment>
              ) : null}
              <br />
              <br />
              {chequesComprobante &&
              Number(chequesComprobante.importeTotal > 0) ? (
                <Grid item xs={12} md={12}>
                  <label style={{ marginLeft: "0.0vw" }} htmlFor="importe">
                    {"Importe Total " +
                      chequesComprobante.importeTotal.toLocaleString("es-ar", {
                        minimumFractionDigits: 2,
                      })}
                  </label>

                  <label style={{ marginLeft: "4.0vw" }} htmlFor="importe">
                    {"Importe Acumulado " +
                      (
                        importeAcumuladoCompartido + importeParcialCompartido
                      ).toLocaleString("es-ar", {
                        minimumFractionDigits: 2,
                      })}
                  </label>
                </Grid>
              ) : null}
            </Dialog>
          </div>

          <div className="dialog-demo">
            <Dialog
              //header={renderHeaderCompartido()}
              visible={visibleNc}
              footer={renderFooterNc()}
              closable={false}
              breakpoints={{ "960px": "75vw" }}
              style={{
                width: "45vw",
                paddingBottom: "0",
                overflowY: "visible",
              }}
            >
              <div className="datatable-responsive-demo">
                <div className="card">
                  <Tooltip target=".export-buttons>button" position="bottom" />
                  <DataTable
                    value={nc}
                    selection={selectedRowNc}
                    onSelectionChange={(e) => {
                      setSelectedRowNc(e.value);
                      guardarNcComprobante({
                        id: e.value.id,
                        comprobante: e.value.comprobante,
                        numero: e.value.numero,
                        puntoventa: e.value.puntoventa,
                        importe: e.value.saldo,
                      });
                    }}
                    selectionMode="single"
                    dataKey="id"
                    header={renderHeaderNc}
                    className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                    style={{ fontSize: "small", marginTop: "1vw" }}
                    //globalFilter={globalFilter}
                    size="small"
                    emptyMessage="No hay datos."
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    rows={5}
                    //rowsPerPageOptions={[10, 20, 50]}
                    //paginatorLeft={paginatorLeft}
                    //paginatorRight={paginatorRight}
                  >
                    <Column
                      field="id"
                      header="ID"
                      //body={idTemplate}
                      style={{ display: "none" }}
                    ></Column>
                    <Column
                      header="Fecha"
                      body={fechaTemplate}
                      sortable
                    ></Column>
                    <Column
                      field=""
                      header="Comprobante"
                      body={comprobanteTemplate}
                      sortable
                    ></Column>
                    <Column
                      field=""
                      header="Aplicar"
                      body={comprobanteNcTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="saldo"
                      header="Saldo"
                      body={saldoTemplate}
                      sortable
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </Dialog>

            <div className="dialog-demo">
              <Dialog
                //header={renderHeaderCompartido()}
                visible={visibleAjustes}
                footer={renderFooterAjustes()}
                closable={false}
                breakpoints={{ "960px": "75vw" }}
                style={{
                  width: "45vw",
                  paddingBottom: "0",
                  overflowY: "visible",
                }}
              >
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={ajustes}
                      selection={selectedRowAjustes}
                      onSelectionChange={(e) => {
                        setSelectedRowAjustes(e.value);
                        guardarAjustesComprobante({
                          id: e.value.id,
                          comprobante: e.value.comprobante,
                          puntoventa: e.value.puntoventa,
                          numero: e.value.numero,
                          importe: e.value.saldo,
                        });
                      }}
                      selectionMode="single"
                      dataKey="id"
                      header={renderHeaderAjustes}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      //globalFilter={globalFilter}
                      size="small"
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={5}
                      //rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        //body={idTemplate}
                        style={{ display: "none" }}
                      ></Column>
                      <Column
                        header="Fecha"
                        body={fechaTemplate}
                        sortable
                      ></Column>
                      <Column
                        field=""
                        header="Comprobante"
                        body={comprobanteTemplate}
                        sortable
                      ></Column>
                      <Column
                        field=""
                        header="Aplicar"
                        body={comprobanteNcTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="saldo"
                        header="Saldo"
                        body={saldoTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Dialog>
            </div>

            <div className="dialog-demo">
              <Dialog
                //header={renderHeaderCompartido()}
                visible={visibleACuenta}
                footer={renderFooterACuenta()}
                closable={false}
                breakpoints={{ "960px": "75vw" }}
                style={{
                  width: "45vw",
                  paddingBottom: "0",
                  overflowY: "visible",
                }}
              >
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={aCuenta}
                      selection={selectedRowACuenta}
                      onSelectionChange={(e) => {
                        setSelectedRowACuenta(e.value);
                        guardarACuentaComprobante({
                          id: e.value.id,
                          comprobante: e.value.comprobante,
                          puntoventa: e.value.puntoventa,
                          numero: e.value.numero,
                          importe: e.value.saldo,
                        });
                      }}
                      selectionMode="single"
                      dataKey="id"
                      header={renderHeaderACuenta}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      //globalFilter={globalFilter}
                      size="small"
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={5}
                      //rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        //body={idTemplate}
                        style={{ display: "none" }}
                      ></Column>
                      <Column
                        header="Fecha"
                        body={fechaTemplate}
                        sortable
                      ></Column>
                      <Column
                        field=""
                        header="Comprobante"
                        body={comprobanteTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="saldo"
                        header="Saldo"
                        body={saldoTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
        </Fragment>
      ) : (
        <Spinner />
      )}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";
import { InputNumber } from "primereact/inputnumber";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { InputMask } from "primereact/inputmask";
import IconButton from "@mui/material/IconButton";
import Facturacion from "./Facturacion";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

export default function RendicionesAplicadasEliminadas(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [visible, setVisible] = useState(false);
  const [visibleFechas, setVisibleFechas] = useState(false);
  const [fechas, setFechas] = useState(null);
  const toast = useRef(null);
  const [comprobante, guardarComprobante] = useState(null);
  const [titularId, setTitularId] = useState(null);
  const [titularNombre, setTitularNombre] = useState(null);
  const [titularSeleccionado, setTitularSeleccionado] = useState(null);
  const [numero, setNumero] = useState(null);
  const [cobradorId, setCobradorId] = useState(null);
  const [band, setBand] = useState(0);

  let navigate = useNavigate();

  let { tipo } = useParams();

  const idProps = props.idProps;
  const empresaIdProps = props.empresaIdProps;

  if (idProps !== undefined) {
    tipo = "1";
  }

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    rows,
    rendiciones,
    itemsRendicion,
    empresaElegida,
    obtenerAcceso,
    obtenerRendicionAplicada2,
    obtenerRendicionesAplicadas,
    obtenerRendicionesEliminadas,
    obtenerItemsRendicionAplicada,
    obtenerItemsRendicionEliminada,
    obtenerDatosTitulares,
    setRows,
    guardarFactura,
    guardarTitular,
    guardarIdComprobante,
    guardarUrlPdf,
    guardarItemsRendicion,
    guardarRendiciones,
    anularRendicionAplicada,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      if (tipo === "1") {
        obtenerAcceso({
          usuarioId: state.usuario.id,
          layout: "VisualizarRendicionesAplicadas",
        });
      }
      if (tipo === "0") {
        obtenerAcceso({
          usuarioId: state.usuario.id,
          layout: "VisualizarRendicionesAnuladas",
        });
      }
    }
  }, [state.id]);

  useEffect(() => {
    if (idProps !== undefined) {
      guardarRendiciones([]);
    }
  }, []);

  /*
  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }
  */
  const calendar = (e) => {
    setNumero(null);
    setCobradorId(null);
    setVisibleFechas(true);
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHideFechas = (e) => {
    setVisibleFechas(false);
  };

  const editClick = (e) => {
    if (selectedRow) {
      if (tipo === "1") {
        obtenerItemsRendicionAplicada({
          id: selectedRow.id_rendicion,
          empresaId: selectedRow.id_empresa,
        });
      } else {
        //obtenerItemsRendicionEliminada({ id: selectedRow.id });
      }
      toast.current.show({
        severity: "info",
        summary: "Rendiciones",
        detail: "Obteniendo información... !",
        life: 3000,
      });
      var elem;
      elem = document.getElementById("imprimirClick");
      setTimeout(() => {
        if (elem) {
          elem.click();
        }
      }, 2000);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Visualizar",
        detail: "Debe seleccionar una rendición.",
        life: 3000,
      });
    }
  };

  const anular = (e) => {
    if (selectedRow) {
      const id = selectedRow.id;
      const rendicionId = selectedRow.id_rendicion;
      const empresaId = selectedRow.id_empresa;

      confirmDialog({
        message: `Esta seguro de anular la rendición aplicada ${rendicionId}.`,
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        rejectLabel: "No",
        acceptLabel: "Si",
        accept: () => {
          anularRendicionAplicada({
            id,
            rendicionId,
            empresaId,
            usuario: state.usuario.id,
          }).then((data) => {
            let fdesde = null;
            let fhasta = null;

            if (fechas) {
              fdesde = fechas.desde + "T00:00";
              fhasta = fechas.hasta + "T23:59";
            }

            obtenerRendicionesAplicadas({
              fdesde: fdesde,
              fhasta: fhasta,
              numero,
              cobradorId,
              empresaElegida,
            });
          });
        },
        //reject: () => reject(),
      });
    } else {
      toast.current.show({
        severity: "info",
        summary: "Anular",
        detail: "Debe seleccionar una rendición.",
        life: 3000,
      });
    }
  };

  const aceptarFechas = async (exportType) => {
    //e.preventDefault();
    //Validar

    let fdesde = null;
    let fhasta = null;

    if (numero !== null && numero === 0) {
      if (tipo === "1") {
        mensajeAlerta(
          "Rendiciones Aplicadas",
          "Número no puede ser cero !.",
          "error"
        );
      } else {
        mensajeAlerta(
          "Rendiciones Eliminadas",
          "Número no puede ser cero !.",
          "error"
        );
      }
      return;
    }
    if (numero === null || numero === "") {
      if (
        !fechas ||
        fechas.desde === undefined ||
        fechas.desde === null ||
        fechas.desde.trim() === ""
      ) {
        if (tipo === "1") {
          mensajeAlerta(
            "Rendiciones Aplicadas",
            "Fecha desde es necesaria !.",
            "error"
          );
        } else {
          mensajeAlerta(
            "Rendiciones Eliminadas",
            "Fecha desde es necesaria !.",
            "error"
          );
        }
        return;
      }

      if (
        !fechas ||
        fechas.hasta === undefined ||
        fechas.hasta === null ||
        fechas.hasta.trim() === ""
      ) {
        if (tipo === "1") {
          mensajeAlerta(
            "Rendiciones Aplicadas",
            "Fecha hasta es necesaria !.",
            "error"
          );
        } else {
          mensajeAlerta(
            "Rendiciones Eliminadas",
            "Fecha hasta es necesaria !.",
            "error"
          );
        }
        return;
      }

      fdesde = fechas.desde + "T00:00";
      fhasta = fechas.hasta + "T23:59";
    }

    if (tipo === "1") {
      await obtenerRendicionesAplicadas({
        fdesde: fdesde,
        fhasta: fhasta,
        numero,
        cobradorId,
        empresaElegida,
      });
    } else {
      await obtenerRendicionesEliminadas({
        fdesde: fdesde,
        fhasta: fhasta,
        numero,
        cobradorId,
        empresaElegida,
      });
    }
    //guardarIdComprobante(null)
    onHideFechas();
  };

  const accesoDenegado = () => {
    if (tipo === "1") {
      mensajeAlerta(
        "Rendiciones Aplicadas",
        "No tiene permiso para acceder a esta pantalla !.",
        "error"
      );
    } else {
      mensajeAlerta(
        "Rendiciones Eliminadas",
        "No tiene permiso para acceder a esta pantalla !.",
        "error"
      );
    }
    exitClick();
    return;
  };

  const renderHeaderFechas = () => {
    return (
      <div>
        <Tag
          value={
            tipo === "1"
              ? "Rendiciones Aplicadas"
              : tipo === "0"
              ? "Rendiciones Eliminadas"
              : null
          }
        ></Tag>
      </div>
    );
  };

  const renderFooterFechas = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarFechas}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideFechas}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag
          value={
            tipo === "1"
              ? "Rendiciones Aplicadas"
              : tipo === "0"
              ? "Rendiciones Eliminadas"
              : null
          }
        ></Tag>
      </div>
    </Fragment>
  );

  function formatDate(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  const fechaTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fecha)}</Fragment>;
  };

  const cobradorTemplate = (rowData) => {
    return <span>{rowData.id_cobrador + "-" + rowData.cobradores.nombre}</span>;
  };

  const empresaTemplate = (rowData) => {
    return <span>{rowData.id_empresa + "-" + rowData.empresas.nombre}</span>;
  };

  const usuarioTemplate = (rowData) => {
    return <span>{rowData.usuarios.usuario}</span>;
  };

  const usuariofechaTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.usuario_fecha)}</Fragment>;
  };

  const anuladoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.anulado}></Checkbox>
      </Fragment>
    );
  };

  const acceptImprimir = (rowData) => {
    const doc = new jsPDF();
    let date = new Date();
    let newDate = new Date(date);
    newDate.setHours(date.getHours() - 3);

    let row = 10;
    doc.setFontSize(10);
    doc.text("Rendición Nº: " + rowData.id_rendicion, 10, row);
    doc.text("Fecha: " + formatDateTime(newDate), 70, row);
    doc.text(
      tipo === "1"
        ? "Estado: APLICADA"
        : tipo === "0"
        ? "Estado: ELIMINADA"
        : "",
      130,
      row
    );
    row = 15;
    doc.text(
      "Cobrador: " +
        rowData.cobradores.nombre +
        " (" +
        rowData.cobradores.id +
        ")",
      10,
      row
    );
    row = 25;

    var columns = [
      "Nº. Afiliado",
      "Razón Social",
      "Comprobante",
      "Valor",
      "Importe",
      "Descuento",
      "Recibo",
      "Empresa",
    ];

    let data = itemsRendicion.map((item) => [
      item.titularId,
      item.nombreTitular,
      item.comprobante + "-" + item.numero,
      item.valor.toUpperCase(),
      parseFloat(item.importeTotal).toFixed(2),
      parseFloat(item.comision).toFixed(2),
      item.recibo !== null && item.recibo !== 0 ? item.recibo : "",
      item.empresas.nombre,
    ]);

    let total = 0;
    let comision = 0;
    for (var i = 0; i < itemsRendicion.length; i++) {
      total = Number(total) + Number(itemsRendicion[i].importeTotal);
      comision = Number(comision) + Number(itemsRendicion[i].comision);
    }

    let arrayTotal = [
      "TOTAL",
      "",
      "",
      "",
      parseFloat(total).toFixed(2),
      parseFloat(comision).toFixed(2),
      "",
      "",
    ];

    data.push(arrayTotal);

    doc.autoTable(columns, data, {
      margin: { top: 25, left: 10 },
      styles: {
        fontSize: 8,
      },
      columnStyles: {
        0: {
          halign: "left",
        },
        1: {
          halign: "left",
        },
        2: {
          halign: "left",
        },
        3: {
          halign: "left",
        },
        4: {
          halign: "right",
        },
        5: {
          halign: "right",
        },
        6: {
          halign: "left",
        },
        7: {
          halign: "left",
        },
      },
      didParseCell: (data) => {
        if (data.cell && data.cell.section === "head") {
          switch (data.cell.raw) {
            case "Importe":
            case "Descuento":
              data.cell.styles.halign = "right";
              break;
            default:
              data.cell.styles.halign = "left";
              break;
          }
        }
      },
    });

    let finalY = doc.previousAutoTable.finalY;

    row = finalY + 10;

    if (row >= 270) {
      doc.addPage();
    }

    const result = Object.entries(groupBy("valor")(itemsRendicion)).map(
      ([key, value]) => ({ valor: key, datos: value })
    );

    let array = [];

    // efectivo
    let efectivoTotal = 0;
    let efectivoComision = 0

    for (var i = 0; i < result.length; i++) {
      if (result[i].valor === "efectivo") {
        for (var j = 0; j < result[i].datos.length; j++) {
          efectivoTotal =
            Number(efectivoTotal) + Number(result[i].datos[j].importeTotal);
            efectivoComision =
            Number(efectivoComision) + Number(result[i].datos[j].comision);

        }
      }
    }

    array.push({
      valor: "Total Efectivo",
      importe: Number(efectivoTotal),
      detalle: "",
      //empresa: "",
      comision: parseFloat(Number(efectivoComision).toFixed(2)),
    });

    // cheques

    let totalCheques = 0;
    let comisionCheques = 0;

    for (var i = 0; i < result.length; i++) {
      if (result[i].valor === "cheques") {
        for (var j = 0; j < result[i].datos.length; j++) {
          if (result[i].datos[j].chequeIndex === 0) {
            totalCheques =
              Number(totalCheques) + Number(result[i].datos[j].importeTotal);
              comisionCheques =
              Number(comisionCheques) + Number(result[i].datos[j].comision);
            array.push({
              valor: "Cheques",
              importe: parseFloat(
                Number(result[i].datos[j].importeTotal).toFixed(2)
              ),
              detalle:
                formatDate(result[i].datos[j].chequeEmision) +
                "-" +
                formatDate(result[i].datos[j].chequeDeposito) +
                "-" +
                result[i].datos[j].chequeBanco,
              //empresa: result[i].datos[j].empresas.nombre,
              comision: parseFloat(
                Number(result[i].datos[j].comision).toFixed(2)
              ),

            });
          }
        }
      }
    }

    array.push({
      valor: "Total Cheques",
      importe: parseFloat(Number(totalCheques).toFixed(2)),
      detalle: "",
      //empresa: "",
      comision: parseFloat(Number(comisionCheques).toFixed(2)),
    });

    let result2 = [];

    for (var i = 0; i < result.length; i++) {
      if (result[i].valor === "bancos") {
        result2 = Object.entries(groupBy("bancoCuentaId")(result[i].datos)).map(
          ([key, value]) => ({ bancoCuentaId: key, datos: value })
        );
      }
    }

    let totalBancos = 0;
    let comisionBancos = 0;
    for (var i = 0; i < result2.length; i++) {
      let bancosTotal = 0;
      let bancosComision = 0;
      let detalle = "";
      let empresa = "";
      for (var j = 0; j < result2[i].datos.length; j++) {
        totalBancos =
          Number(totalBancos) + Number(result2[i].datos[j].importeTotal);
        bancosTotal =
          Number(bancosTotal) + Number(result2[i].datos[j].importeTotal);
          comisionBancos =
          Number(comisionBancos) + Number(result2[i].datos[j].comision);
        bancosComision =
          Number(bancosComision) + Number(result2[i].datos[j].comision);
        detalle = result2[i].datos[j].bancoCuenta;
        //empresa = result2[i].datos[j].empresas.nombre;
      }
      array.push({
        valor: "Bancos",
        importe: parseFloat(Number(bancosTotal).toFixed(2)),
        detalle,
        //empresa,
        comision: parseFloat(Number(bancosComision).toFixed(2)),
      });
    }

    array.push({
      valor: "Total Bancos",
      importe: parseFloat(Number(totalBancos).toFixed(2)),
      detalle: "",
      //empresa: "",
      comision: parseFloat(Number(comisionBancos).toFixed(2)),
    });

    array.push({
      valor: "Total",
      importe: parseFloat(Number(total).toFixed(2)),
      detalle: "",
      comision: parseFloat(Number(comision).toFixed(2)),
      //empresa: "",
    });

    doc.setFontSize(9);

    doc.text("VALORES A RENDIR: ", 10, row);

    row = row + 5;

    if (row >= 270) {
      doc.addPage();
    }

    var columns = ["Valor", "Detalle", "Importe", "Descuento"];

    data = array.map((item) => [
      item.valor,
      item.detalle,
      parseFloat(item.importe).toFixed(2),
      //item.empresa,
      parseFloat(item.comision).toFixed(2),
    ]);

    doc.autoTable(columns, data, {
      margin: { left: 10 },
      styles: {
        fontSize: 8,
      },
      startY: row,
      columnStyles: {
        0: {
          halign: "left",
        },
        1: {
          halign: "left",
        },
        2: {
          halign: "right",
        },
        3: {
          halign: "right",
        },
      },
      didParseCell: (data) => {
        if (data.cell && data.cell.section === "head") {
          switch (data.cell.raw) {
            case "Valor":
            case "Detalle":
              data.cell.styles.halign = "left";
              break;
            default:
              data.cell.styles.halign = "right";
              break;
          }
        }
      },
    });

    finalY = doc.previousAutoTable.finalY;

    if (row >= 270) {
      doc.addPage();
    }

    row = finalY + 2;

    if (row >= 270) {
      doc.addPage();
    }

    window.open(doc.output("bloburl"));
  };

  function formatDateTime(dateString) {
    var allDate = dateString.toISOString().replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  const groupBy = (prop) => (data) => {
    return data.reduce((dict, item) => {
      const { [prop]: _, ...rest } = item;
      dict[item[prop]] = [...(dict[item[prop]] || []), rest];
      return dict;
    }, {});
  };

  // INICIO

  if (
    idProps !== undefined &&
    Number(idProps) > 0 &&
    band === 0 &&
    rendiciones &&
    rendiciones.length === 0
  ) {
    setBand(band + 1);
    obtenerRendicionAplicada2({ id: idProps, empresaId: empresaIdProps });
    obtenerItemsRendicionAplicada({ id: idProps, empresaId: empresaIdProps });

  }

  if (
    idProps !== undefined &&
    Number(idProps) > 0 &&
    rendiciones &&
    rendiciones.length === 1 &&
    band === 1
  ) {
    setBand(band + 1);
    acceptImprimir(rendiciones[0]);
  }
  // FIN

  //console.log(itemsRendicion);

  return (
    <Fragment>
      {idProps === undefined ? <Menu /> : null}
      <Toast ref={toast} />
      <ConfirmDialog />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {rendiciones && idProps === undefined ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ align: "center", margin: "1em" }}
              >
                <Button
                  icon="pi pi-calendar"
                  tooltip="Fechas"
                  className="p-button-rounded p-button-success"
                  style={{ marginLeft: "1em" }}
                  onClick={(e) => calendar()}
                />
                {tipo === "1" ? (
                  <Fragment>
                    <Button
                      icon="pi pi-book"
                      tooltip="Visualizar"
                      className="p-button-rounded p-button-warning"
                      style={{ marginLeft: "1em" }}
                      onClick={editClick}
                    />
                    <Button
                      icon="pi pi-trash"
                      tooltip="Anular"
                      className="p-button-rounded p-button-danger"
                      style={{ marginLeft: "1em" }}
                      onClick={anular}
                    />
                  </Fragment>
                ) : null}
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <Button
                  id="imprimirClick"
                  icon="pi pi-print"
                  tooltip="Imprimir"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "1em", display: "none" }}
                  onClick={(e) => acceptImprimir(selectedRow)}
                />

                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={rendiciones}
                      selection={selectedRow}
                      onSelectionChange={(e) => {
                        setSelectedRow(e.value);
                        guardarItemsRendicion(null);
                      }}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={50}
                      rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        //body={idTemplate}
                        sortable
                        style={{ display: "none" }}
                      ></Column>
                      <Column
                        field="id_rendicion"
                        header="Número"
                        //body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="fecha"
                        header="Fecha"
                        body={fechaTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="total"
                        header="Total"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="autoliquidacion"
                        header="Autoliquidación"
                        //body={descripcionTemplate}
                        sortable
                        //style={{ fontSize: "10px" }}
                      ></Column>
                      <Column
                        field=""
                        header="Cobrador"
                        body={cobradorTemplate}
                        sortable
                      ></Column>
                      <Column
                        field=""
                        header="Empresa"
                        body={empresaTemplate}
                        sortable
                      ></Column>
                      <Column
                        field=""
                        header="Usuario"
                        body={usuarioTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
            </Fragment>
          ) : null}
          <Dialog
            header={renderHeaderFechas()}
            visible={visibleFechas}
            closable={false}
            footer={renderFooterFechas()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "70vw", paddingBottom: "0" }}
            position="top"
          >
            <Grid container spacing={3} style={{ marginTop: "1vw" }}>
              <Grid item xs={12} md={4}>
                <TextField
                  id="desde"
                  label="Desde"
                  type="date"
                  fullWidth
                  //onKeyDown={handleEnter}
                  value={fechas && fechas.desde ? fechas.desde : null}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFechas({
                      ...fechas,
                      desde: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="hasta"
                  label="Hasta"
                  type="date"
                  fullWidth
                  //onKeyDown={handleEnter}
                  value={fechas && fechas.hasta ? fechas.hasta : null}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFechas({
                      ...fechas,
                      hasta: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{ paddingLeft: 0, marginTop: "0em" }}
              />

              <Grid item xs={12} md={3}>
                <TextField
                  id="numero"
                  label="Número"
                  type="number"
                  fullWidth
                  //onKeyDown={handleEnter}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setNumero(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  id="cobrador"
                  label="Nº de Cobrador"
                  type="number"
                  fullWidth
                  //onKeyDown={handleEnter}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setCobradorId(e.target.value);
                  }}
                />
              </Grid>

              {/*   
              <Grid
                item
                xs={12}
                md={12}
                style={{ paddingLeft: 0, marginTop: "0em" }}
              >
                {!rows &&
                ((titularId && titularId.length >= 4) ||
                  (titularNombre && titularNombre.length >= 3)) ? (
                  <ProgressBar
                    mode="indeterminate"
                    style={{ height: "6px" }}
                  ></ProgressBar>
                ) : null}
              </Grid>
                  */}
            </Grid>
          </Dialog>
        </Fragment>
      ) : null}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

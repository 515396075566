import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";
import { InputNumber } from "primereact/inputnumber";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { InputMask } from "primereact/inputmask";
import IconButton from "@mui/material/IconButton";
import Facturacion from "./Facturacion";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

export default function ComprobantesCompras(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [visible, setVisible] = useState(false);
  const [visibleFechas, setVisibleFechas] = useState(false);
  const [fechas, setFechas] = useState(null);
  const toast = useRef(null);
  const [comprobante, guardarComprobante] = useState(null);
  const [proveedorId, setProveedorId] = useState(null);
  const [proveedorNombre, setProveedorNombre] = useState(null);
  const [proveedorSeleccionado, setProveedorSeleccionado] = useState(null);
  const [numero, setNumero] = useState(null);

  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    rows,
    comprobantes,
    empresaElegida,
    obtenerAcceso,
    obtenerComprobantesCompras,
    obtenerProveedoresComprobantes,
    setRows,
    guardarFactura,
    guardarProveedor,
    anularComprobanteCompras,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "VisualizarComprobantesCompras",
      });
    }
  }, [state.id]);

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  const calendar = (e) => {
    setVisibleFechas(true);
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHideFechas = (e) => {
    setVisibleFechas(false);
  };

  const editClick = (e) => {
    if (selectedRow) {
      //guardarComprobante(selectedRow);
      navigate(
        `/facturacioncompras/${selectedRow.id}/${selectedRow.id_proveedor}`
      );

      //setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Visualizar",
        detail: "Debe seleccionar un comprobante.",
        life: 3000,
      });
    }
  };

  const anularClick = () => {
    if (!selectedRow) {
      toast.current.show({
        severity: "info",
        summary: "Anular",
        detail: "Debe seleccionar un comprobante.",
        life: 3000,
      });
      return;
    }

    if (
      selectedRow &&
      selectedRow.id_orden !== null &&
      Number(selectedRow.id_orden) > 0
    ) {
      toast.current.show({
        severity: "error",
        summary: "Anular",
        detail: "Este comprobante ya tiene asignada una órden de pago !.",
        life: 3000,
      });
      return;
    }

    if (
      selectedRow &&
      selectedRow.anulado
    ) {
      toast.current.show({
        severity: "error",
        summary: "Anular",
        detail: "Este comprobante ya está anulado !.",
        life: 3000,
      });
      return;
    }



    confirmDialog({
      message:
        "Desea anular el comprobante " +
        selectedRow.id +
        " de $" +
        selectedRow.total +
        " ?.",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      rejectLabel: "No",
      acceptLabel: "Si",
      accept: () => acceptAnular(),
      //reject: () => rejectOrden(),
    });
  };

  const acceptAnular = () => {
    let selectedCities = [];
    selectedCities.push(empresaElegida);

    anularComprobanteCompras({ comprobanteId: selectedRow.id }).then((data) => {
      obtenerComprobantesCompras({
        fdesde: fechas.desde + "T00:00",
        fhasta: fechas.hasta + "T23:59",
        proveedorSeleccionado,
        numero,
        selectedCities,
      });
    });
  };
  const aceptarFechas = async (exportType) => {
    //e.preventDefault();
    //Validar

    let fdesde = null;
    let fhasta = null;

    if (numero !== null && numero === 0) {
      mensajeAlerta("Comprobantes", "Número no puede ser cero !.", "error");
      return;
    }

    if (numero === null || numero === "") {
      if (
        !fechas ||
        fechas.desde === undefined ||
        fechas.desde === null ||
        fechas.desde.trim() === ""
      ) {
        mensajeAlerta("Comprobantes", "Fecha desde es necesaria !.", "error");
        return;
      }

      if (
        !fechas ||
        fechas.hasta === undefined ||
        fechas.hasta === null ||
        fechas.hasta.trim() === ""
      ) {
        mensajeAlerta("Comprobantes", "Fecha hasta es necesaria !.", "error");
        return;
      }

      fdesde = fechas.desde + "T00:00";
      fhasta = fechas.hasta + "T23:59";
    }

    let selectedCities = [];

    selectedCities.push(empresaElegida);

    await obtenerComprobantesCompras({
      fdesde: fdesde,
      fhasta: fhasta,
      proveedorSeleccionado,
      numero,
      selectedCities,
    });
    onHideFechas();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Comprobantes",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const renderHeaderFechas = () => {
    return (
      <div>
        <Tag value="Comprobantes de Compras"></Tag>
      </div>
    );
  };

  const renderFooterFechas = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarFechas}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideFechas}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const buscarProveedores = () => {
    if (proveedorNombre !== null && proveedorNombre !== "") {
      obtenerProveedoresComprobantes({
        opcion: "Apellido y nombre",
        texto: proveedorNombre,
      });
    }
    if (proveedorId !== null && proveedorId !== "") {
      obtenerProveedoresComprobantes({
        opcion: "Número de proveedor",
        texto: proveedorId,
      });
    }
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Comprobantes de Compras"></Tag>
      </div>
    </Fragment>
  );

  function formatDate(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  const fechaComprobanteTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fechacomprobante)}</Fragment>;
  };

  const fechaContabilizacionTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fechacontabilizacion)}</Fragment>;
  };

  const monedaTemplate = (rowData) => {
    return <Fragment>{rowData.monedas.nombre + ' (' + rowData.monedas.simbolo + ')' }</Fragment>;
  };

  const anuladoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.anulado}></Checkbox>
      </Fragment>
    );
  };

  //console.log(comprobantes);
  //console.log(serviciosFinalizadosExcel);

  return (
    <Fragment>
      <Menu />
      <ConfirmDialog />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {comprobantes ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ align: "center", margin: "1em" }}
              >
                <Button
                  icon="pi pi-calendar"
                  tooltip="Fechas"
                  className="p-button-rounded p-button-success"
                  style={{ marginLeft: "1em" }}
                  onClick={(e) => calendar()}
                />

                <Button
                  icon="pi pi-book"
                  tooltip="Visualizar"
                  className="p-button-rounded p-button-warning"
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                <Button
                  icon="pi pi-trash"
                  tooltip="Anular"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={(e) => anularClick(e)}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />

                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={comprobantes}
                      selection={selectedRow}
                      onSelectionChange={(e) => {
                        setSelectedRow(e.value);
                        guardarFactura(null);
                        guardarProveedor(null);
                      }}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={50}
                      rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        //body={idTemplate}
                        style={{display: "none"}}
                        sortable
                      ></Column>
                      <Column
                        field="fechacomprobante"
                        header="Fecha Comprob."
                        body={fechaComprobanteTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="fechacontabilizacion"
                        header="Fecha Contab."
                        body={fechaContabilizacionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="comprobante"
                        header="Comprobante"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="puntoventa"
                        header="P. Venta"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="numero"
                        header="Número"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="total"
                        header="Total"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="id_proveedor"
                        header="Nº Proveedor"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="nombre_proveedor"
                        header="Nombre Proveedor"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="id_orden2"
                        header="ID Orden"
                        //body={estadoTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="moneda"
                        header="Moneda"
                        body={monedaTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="cotizacion"
                        header="Cotización"
                        //body={estadoTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="anulado"
                        header="Anulado"
                        body={anuladoTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
            </Fragment>
          ) : null}

          <Dialog
            header={renderHeaderFechas()}
            visible={visibleFechas}
            closable={false}
            footer={renderFooterFechas()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "70vw", paddingBottom: "0" }}
            position="top"
          >
            <Grid container spacing={3} style={{ marginTop: "1vw" }}>
              <Grid item xs={12} md={4}>
                <TextField
                  id="desde"
                  label="Desde"
                  type="date"
                  fullWidth
                  //onKeyDown={handleEnter}
                  value={fechas && fechas.desde ? fechas.desde : null}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFechas({
                      ...fechas,
                      desde: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="hasta"
                  label="Hasta"
                  type="date"
                  fullWidth
                  //onKeyDown={handleEnter}
                  value={fechas && fechas.hasta ? fechas.hasta : null}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFechas({
                      ...fechas,
                      hasta: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{ paddingLeft: 0, marginTop: "0em" }}
              />

              <Grid item xs={12} md={2}>
                <TextField
                  id="proveedor"
                  label="ID Proveedor"
                  fullWidth
                  type="number"
                  value={proveedorId ? proveedorId : ""}
                  disabled={proveedorNombre !== null && proveedorNombre !== ""}
                  //onKeyDown={handleEnter}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setProveedorId(e.target.value);
                    setProveedorSeleccionado(null);
                    setRows(null);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  id="razonSearch"
                  label="Apellido y Nombre o Razón Social"
                  fullWidth
                  value={proveedorNombre ? proveedorNombre : ""}
                  disabled={
                    proveedorId !== null &&
                    proveedorId !== "" &&
                    proveedorId !== 0
                  }
                  //onKeyDown={handleEnter}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setProveedorNombre(e.target.value);
                    setProveedorSeleccionado(null);
                    setRows(null);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={1}>
                <Button
                  icon="pi pi-search"
                  className="p-button-rounded p-button-success"
                  onClick={() => buscarProveedores()}
                />
              </Grid>

              <Grid item xs={12} md={5}>
                <Autocomplete
                  id="razon"
                  fullWidth
                  options={rows}
                  disabled={!rows}
                  noOptionsText={"No hay opciones"}
                  style={{ marginLeft: ".8vw", color: "green" }}
                  //defaultValue={grupo ? agrupar[grupo - 1] : null}
                  value={proveedorSeleccionado}
                  disablePortal
                  getOptionLabel={(option) => option.nombre_razonsocial}
                  renderInput={(params) => (
                    <TextField {...params} label="Seleccione el Proveedor" />
                  )}
                  onChange={(event, value) => {
                    if (value) {
                      setProveedorSeleccionado(value);
                      setProveedorId(value.id);
                      setProveedorNombre(null);
                    } else {
                      setProveedorSeleccionado(null);
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  id="numero"
                  label="Número"
                  type="number"
                  fullWidth
                  //onKeyDown={handleEnter}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setNumero(e.target.value);
                  }}
                />
              </Grid>

              {/*   
              <Grid
                item
                xs={12}
                md={12}
                style={{ paddingLeft: 0, marginTop: "0em" }}
              >
                {!rows &&
                ((proveedorId && proveedorId.length >= 4) ||
                  (proveedorNombre && proveedorNombre.length >= 3)) ? (
                  <ProgressBar
                    mode="indeterminate"
                    style={{ height: "6px" }}
                  ></ProgressBar>
                ) : null}
              </Grid>
                  */}
            </Grid>
          </Dialog>
        </Fragment>
      ) : null}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

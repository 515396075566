import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";
import { InputNumber } from "primereact/inputnumber";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { InputMask } from "primereact/inputmask";
import IconButton from "@mui/material/IconButton";
import Facturacion from "./Facturacion";
import * as EmailValidator from "email-validator";
import { FileUpload } from "primereact/fileupload";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

export default function Comprobantes(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [visible, setVisible] = useState(false);
  const [visibleFechas, setVisibleFechas] = useState(false);
  const [fechas, setFechas] = useState(null);
  const toast = useRef(null);
  const [comprobante, guardarComprobante] = useState(null);
  const [titularId, setTitularId] = useState(null);
  const [titularNombre, setTitularNombre] = useState(null);
  const [titularSeleccionado, setTitularSeleccionado] = useState(null);
  const [numero, setNumero] = useState(null);
  const [visibleComprobante, setVisibleComprobante] = useState(false);
  const [todosLosCobradores, setTodosLosCobradores] = useState(false);
  const [proceso, guardarProceso] = useState(null);
  const [visibleEnvios, setVisibleEnvios] = useState(false);
  const [emailAdrress, guardarEmailAddress] = useState(null);
  const [celular, guardarCelular] = useState(null);
  const [emailComprobante, setEmailComprobante] = useState(false);
  const [emailLink, setEmailLink] = useState(false);
  const [whatsAppComprobante, setWhatsAppComprobante] = useState(false);
  const [whatsAppLink, setWhatsAppLink] = useState(false);
  const [linkEmailEnable, guardarLinkEmailEnable] = useState(null);
  const [linkMpEnable, guardarLinkMpEnable] = useState(null);

  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    rows,
    empresas,
    verComprobante,
    comprobantes,
    empresaElegida,
    obtenerAcceso,
    obtenerComprobantes,
    obtenerDatosTitulares,
    setRows,
    guardarFactura,
    guardarTitular,
    guardarIdComprobante,
    guardarUrlPdf,
    setVerComprobante,
    generarLinkMp,
    imprimirComprobantes,
    procesoImpresion,
    obtenerEmpresas,
    crearComprobantePdf,
    enviarEmailComprobante,
    enviarWhatsAppComprobante,
    cambiarCobradorComprobante,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "VisualizarComprobantes",
      });
    }
  }, [state.id]);

  useEffect(() => {
    obtenerEmpresas();
  }, []);

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  const calendar = (e) => {
    setVisibleFechas(true);
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHideFechas = (e) => {
    setVisibleFechas(false);
  };

  const onHideComprobante = (e) => {
    setVisibleComprobante(false);
  };

  const editClick = (e) => {
    if (selectedRow) {
      //guardarComprobante(selectedRow);
      //navigate(`/facturacion/${selectedRow.id}/${selectedRow.id_titular}`);
      setVisibleComprobante(true);

      //setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Visualizar",
        detail: "Debe seleccionar un comprobante.",
        life: 3000,
      });
    }
  };

  const crearLinkPdf = async (rowData) => {
    if (rowData.linkPdf) {
      toast.current.show({
        severity: "info",
        summary: "Visualizar",
        detail: "El link del pdf del comprobante ya esta creado.",
        life: 3000,
      });

      window.open(rowData.linkPdf);


    }

    let facturaElectronica = empresas.find(
      (item) => item.id === empresaElegida.id
    ).facturaElectronica;

    if (!facturaElectronica) {
      toast.current.show({
        severity: "error",
        summary: "Visualizar",
        detail: "Esta empresa no puede crear links de pdf de comprobantes.",
        life: 3000,
      });
      return;
    }

    const id = rowData.id;

    let formularioId = empresas.find(
      (item) => item.id === empresaElegida.id
    ).formularioId;

    await crearComprobantePdf({
      idComprobante: id,
      idFormulario: formularioId,
    }).then((data) => {
      let elem = document.getElementById("actualizarComprobantes");
      setTimeout(() => {
        elem.click();
      }, 500);
    });

    //window.location.reload();
  };

  const linkItems = async (rowData) => {
    if (rowData.linkMp) {
      toast.current.show({
        severity: "info",
        summary: "Visualizar",
        detail: "El link de Mercado Pago ya esta creado.",
        life: 3000,
      });
      return;
    }

    let mp = empresas.find((item) => item.id === empresaElegida.id).mp;

    if (!mp) {
      toast.current.show({
        severity: "error",
        summary: "Visualizar",
        detail: "Esta empresa no puede crear links de Mercado Pago.",
        life: 3000,
      });
      return;
    }

    const id = rowData.id;

    await generarLinkMp({
      comprobanteId: id,
      empresaId: empresaElegida.id,
    }).then((data) => {
      let elem = document.getElementById("actualizarComprobantes");
      setTimeout(() => {
        elem.click();
      }, 500);
    });

    //window.location.reload();
  };

  const cambioCobrador = async (rowData) => {
    confirmDialog({
      message: `Desea cambiar el comprobante al Nº de cobrador ${rowData.afiliados.id_cobrador1_facturacion}`,
      header: "Confirmación",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-success",
      closable: false,
      acceptLabel: "Si",
      accept: (e) => accept1(rowData.afiliados.id_cobrador1_facturacion, rowData.id),
    });
  };

  const accept1 = (cobradorId, id) => {
    cambiarCobradorComprobante({
      id: id,
      cobradorId: cobradorId,
    });
    setTimeout(() => {
      aceptarFechas()
    }, 1000);
  };

  const crearPdf = async (rowData, formularioId) => {

    guardarProceso({
      /*
      nombre:
        rowData.id_titular +
        rowData.comprobante +
        String(rowData.numero).padStart(8, "0"),
      */
      abreviatura: rowData.comprobante,
      desde: rowData.numero,
      hasta: rowData.numero,
      id_tipoComprobante: rowData.id_comprobanteafip,
      hideMessage: true,
      formularioId,
    });
    setTodosLosCobradores(true);
    setTimeout(() => {
      let elem = document.getElementById("callProceso");
      elem.click();
    }, 500);
  };

  const procesoPdf = async () => {

    await procesoImpresion({
      proceso,
      todosLosCobradores,
      empresaElegida,
      usuario: state.usuario.usuario,
    }).then((data) => {
      let elem = document.getElementById("imprimirFacturas");
      elem.click();
    });

    //window.location.reload();
  };

  const enviar = async (rowData) => {
    let facturaElectronica = empresas.find(
      (item) => item.id === empresaElegida.id
    ).facturaElectronica;

    if (!facturaElectronica) {
      toast.current.show({
        severity: "error",
        summary: "Visualizar",
        detail:
          "Esta empresa no puede enviar links de comprobantes ni de Mercado Pago.",
        life: 3000,
      });
      return;
    }

    if (rowData.afiliados && rowData.afiliados.email) {
      guardarEmailAddress(rowData.afiliados.email);
    }

    if (rowData.afiliados && rowData.afiliados.celular) {
      guardarCelular(rowData.afiliados.celular);
    }

    if (rowData.linkPdf) {
      guardarLinkEmailEnable(rowData.linkPdf);
    } else {
      guardarLinkEmailEnable(null);
    }
    if (rowData.linkMp) {
      guardarLinkMpEnable(rowData.linkMp);
    } else {
      guardarLinkMpEnable(null);
    }




    setVisibleEnvios(true);
  };

  const aceptarFechas = async (exportType) => {
    //e.preventDefault();
    //Validar

    let fdesde = null;
    let fhasta = null;

    if (numero !== null && numero === 0) {
      mensajeAlerta("Comprobantes", "Número no puede ser cero !.", "error");
      return;
    }

    if ((numero === null || numero === "") && titularSeleccionado === null) {
      if (
        !fechas ||
        fechas.desde === undefined ||
        fechas.desde === null ||
        fechas.desde.trim() === ""
      ) {
        mensajeAlerta("Comprobantes", "Fecha desde es necesaria !.", "error");
        return;
      }
      if (
        !fechas ||
        fechas.hasta === undefined ||
        fechas.hasta === null ||
        fechas.hasta.trim() === ""
      ) {
        mensajeAlerta("Comprobantes", "Fecha hasta es necesaria !.", "error");
        return;
      }
      fdesde = fechas.desde + "T00:00";
      fhasta = fechas.hasta + "T23:59";
    }

    await obtenerComprobantes({
      fdesde: fdesde,
      fhasta: fhasta,
      titularSeleccionado,
      numero,
      empresaElegida,
    });
    guardarIdComprobante(null);
    onHideFechas();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Comprobantes",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const renderHeaderFechas = () => {
    return (
      <div>
        <Tag value="Comprobantes de Ventas"></Tag>
      </div>
    );
  };

  const renderFooterFechas = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarFechas}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideFechas}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const buscarAfiliados = () => {
    if (titularNombre !== null && titularNombre !== "") {
      obtenerDatosTitulares({
        opcion: "Apellido y nombre",
        texto: titularNombre,
        empresaId: empresaElegida.id,
      });
    }
    if (titularId !== null && titularId !== "") {
      obtenerDatosTitulares({
        opcion: "Número de socio",
        texto: titularId,
        empresaId: empresaElegida.id,
      });
    }
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Comprobantes de Ventas"></Tag>
      </div>
    </Fragment>
  );

  function formatDate(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  const fechaTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fecha)}</Fragment>;
  };

  const anuladoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.anulado}></Checkbox>
      </Fragment>
    );
  };

  const linkMpTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox
          checked={rowData.linkMp !== null && rowData.linkMp.length > 0}
        ></Checkbox>
      </Fragment>
    );
  };

  const linkPdfTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox
          checked={rowData.linkPdf !== null && rowData.linkPdf.length > 0}
        ></Checkbox>
      </Fragment>
    );
  };

  const vencimientoLinkTemplate = (rowData) => {
    if (rowData.fechaLimiteMp) {
      return <Fragment>{formatDate(rowData.fechaLimiteMp)}</Fragment>;
    }
  };

  const opcionesTemplate = (rowData) => {

    let empresa = empresas.find((item) => item.id === empresaElegida.id);

    return (
      <Fragment>
        <Button
          icon="pi pi-link"
          rounded
          text
          severity="success"
          onClick={(e) => linkItems(rowData)}
          aria-label="Bookmark"
          tooltip="Link M.P."
          tooltipOptions={{ position: "bottom" }}
        />
        <Button
          style={{ marginLeft: "0.2vw" }}
          icon="pi pi-file-pdf"
          rounded
          text
          severity="danger"
          onClick={(e) => {
            crearLinkPdf(rowData);
          }}
          aria-label="Bookmark"
          tooltip="Link Pdf"
          tooltipOptions={{ position: "bottom" }}
        />
        <Button
          style={{ marginLeft: "0.2vw" }}
          icon="pi pi-send"
          rounded
          text
          severity="secondary"
          onClick={(e) => enviar(rowData)}
          aria-label="Bookmark"
          tooltip="Enviar"
          tooltipOptions={{ position: "bottom" }}
        />
        {empresa.facturaElectronica ? (
          <Fragment>
            <Button
              style={{ marginLeft: "0.2vw" }}
              icon="pi pi-print"
              rounded
              text
              severity="primary"
              onClick={(e) => {
                crearPdf(rowData, 1);
              }}
              aria-label="Bookmark"
              tooltip="Imprimir"
              tooltipOptions={{ position: "bottom" }}
            />
            {/*
            <Button
              style={{ marginLeft: "0.2vw" }}
              icon="pi pi-ticket"
              rounded
              text
              severity="primary"
              onClick={(e) => {
                crearPdf(rowData, 1);
              }}
              aria-label="Bookmark"
              tooltip="Imprimir Cupones"
              tooltipOptions={{ position: "bottom" }}
            />
            */}
          </Fragment>
        ) : (
          <Button
            style={{ marginLeft: "0.2vw" }}
            icon="pi pi-ticket"
            rounded
            text
            severity="primary"
            onClick={(e) => {
              crearPdf(rowData, 2);
            }}
            aria-label="Bookmark"
            tooltip="Imprimir Cupones"
            tooltipOptions={{ position: "bottom" }}
          />
        )}
        <Button
          style={{ marginLeft: "0.2vw" }}
          icon="pi pi-user"
          rounded
          text
          severity="warning"
          onClick={(e) => cambioCobrador(rowData)}
          aria-label="Bookmark"
          tooltip="Cambio Cobrador"
          tooltipOptions={{ position: "bottom" }}
          disabled={ rowData && rowData.afiliados && 
            rowData.id_cobrador === rowData.afiliados.id_cobrador1_facturacion
          }
        />
        {/*
        <Button
          style={{ marginLeft: "0.2vw" }}
          icon="pi pi-file-pdf"
          rounded
          text
          severity="danger"
          onClick={(e) => {
            const id = rowData.id;
            //obtenerItemsRendicion({ id });
            toast.current.show({
              severity: "info",
              summary: "Rendiciones",
              detail: "Obteniendo información... !",
              life: 3000,
            });
            var elem;
            elem = document.getElementById("imprimirClick");
            setTimeout(() => {
              if (elem) {
                elem.click();
              }
            }, 2000);
          }}
          aria-label="Bookmark"
          tooltip="Pdf"
          tooltipOptions={{ position: "bottom" }}
        />
        */}
        <Button
          id="actualizarComprobantes"
          icon="pi pi-print"
          tooltip="Imprimir"
          className="p-button-rounded p-button-help"
          style={{ marginLeft: "1em", display: "none" }}
          onClick={(e) => {
            let fdesde = null;
            let fhasta = null;
            if (fechas) {
              fdesde = fechas.desde + "T00:00";
              fhasta = fechas.hasta + "T23:59";
            }
            obtenerComprobantes({
              fdesde: fdesde,
              fhasta: fhasta,
              titularSeleccionado,
              numero,
              empresaElegida,
            });
            guardarIdComprobante(null);
          }}
        />
        <Button
          id="imprimirFacturas"
          icon="pi pi-print"
          tooltip="Imprimir"
          className="p-button-rounded p-button-help"
          style={{ marginLeft: "1em", display: "none" }}
          onClick={(e) => imprimirComprobantes(proceso)}
        />
        <Button
          id="callProceso"
          icon="pi pi-print"
          tooltip="Imprimir"
          className="p-button-rounded p-button-help"
          style={{ marginLeft: "1em", display: "none" }}
          onClick={(e) => procesoPdf()}
        />
      </Fragment>
    );
  };

  const renderHeaderEnvios = () => {
    return (
      <div>
        <Tag value="Envío de links de comprobantes y Mercado Pago"></Tag>
      </div>
    );
  };

  const renderFooterEnvios = () => {
    return (
      <div>
        <ButtonMui
          onClick={(e) => aceptarEnvios(e)}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Enviar
        </ButtonMui>
        <ButtonMui
          onClick={(e) => exitEnvios()}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Salir
        </ButtonMui>
      </div>
    );
  };

  const aceptarEnvios = (e) => {
    e.preventDefault();
    if (emailAdrress && (emailAdrress === null || emailAdrress === "")) {
      mensajeAlerta("Comprobantes", "El email es incorrecto !.", "error");
      return;
    }

    if (emailAdrress && !EmailValidator.validate(emailAdrress)) {
      mensajeAlerta("Comprobantes", "El email no es válido !.", "error");
      return;
    }

    if (
      celular &&
      (celular === null || celular === "" || celular.length !== 10)
    ) {
      mensajeAlerta("Comprobantes", "El celular es incorrecto !.", "error");
      return;
    }

    if (emailComprobante) {
      enviarEmailComprobante({
        emailAdrress,
        linkEmailEnable,
        linkMpEnable,
        empresaId: empresaElegida.id
      });
    }

    if (whatsAppComprobante) {
      enviarWhatsAppComprobante({
        celular,
        linkEmailEnable,
        linkMpEnable,
        empresaId: empresaElegida.id,
      });
    }

    setVisibleEnvios(false);
  };

  const exitEnvios = (e) => {
    setVisibleEnvios(false);
  };

  // INICIO

  if (!verComprobante && visibleComprobante) {
    setVerComprobante(true);
    onHideComprobante();
  }

  // FIN

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      <ConfirmDialog />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {comprobantes ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ align: "center", margin: "1em" }}
              >
                <Button
                  icon="pi pi-calendar"
                  tooltip="Fechas"
                  className="p-button-rounded p-button-success"
                  style={{ marginLeft: "1em" }}
                  onClick={(e) => calendar()}
                />

                <Button
                  icon="pi pi-book"
                  tooltip="Visualizar"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />

                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={comprobantes}
                      selection={selectedRow}
                      onSelectionChange={(e) => {
                        setSelectedRow(e.value);
                        guardarFactura(null);
                        guardarTitular(null);
                        guardarIdComprobante(null);
                        guardarUrlPdf(null);
                      }}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw", fontWeight: "bold"}}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={50}
                      rowsPerPageOptions={[10, 20, 50, 100, 500]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        className="xxsmalldata"
                        //body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="fecha"
                        header="Fecha"
                        body={fechaTemplate}
                        sortable
                        className="xxsmalldata"
                      ></Column>
                      <Column
                        field="comprobante"
                        header="Comp."
                        //body={descripcionTemplate}
                        sortable
                        className="xxsmalldata"
                      ></Column>
                      <Column
                        field="puntoventa"
                        header="P. Venta"
                        //body={descripcionTemplate}
                        sortable
                        className="xxsmalldata"
                      ></Column>
                      <Column
                        field="numero"
                        header="Número"
                        //body={descripcionTemplate}
                        sortable
                        className="xxsmalldata"
                      ></Column>
                      <Column
                        field="total"
                        header="Total"
                        //body={descripcionTemplate}
                        sortable
                        className="xxsmalldata"
                      ></Column>
                      <Column
                        field="saldo"
                        header="Saldo"
                        //body={descripcionTemplate}
                        sortable
                        className="xxsmalldata"
                      ></Column>
                      <Column
                        field="id_titular"
                        header="Nº Titular"
                        //body={descripcionTemplate}
                        sortable
                        className="xxsmalldata"
                      ></Column>
                      <Column
                        field="nombre_titular"
                        header="Nombre Titular"
                        //body={descripcionTemplate}
                        sortable
                        className="xxsmalldata"
                      ></Column>
                      <Column
                        field="id_cobrador"
                        header="Nº Cobrador"
                        //body={descripcionTemplate}
                        sortable
                        className="xxsmalldata"
                      ></Column>
                      <Column
                        field="anulado"
                        header="Anulado"
                        body={anuladoTemplate}
                        sortable
                        className="xxsmalldata"
                      ></Column>
                      <Column
                        field="linkMp"
                        header="Link M.P."
                        body={linkMpTemplate}
                        //sortable
                        className="xxsmalldata"
                      ></Column>
                      <Column
                        field="fechaLimiteMp"
                        header="Venc. link M.P."
                        body={vencimientoLinkTemplate}
                        //sortable
                        className="xxsmalldata"
                      ></Column>
                      <Column
                        field="linkPdf"
                        header="Link Pdf"
                        body={linkPdfTemplate}
                        //sortable
                        className="xxsmalldata"
                      ></Column>
                      <Column
                        header="Opciones"
                        className="xxsmalldata"
                        body={opcionesTemplate}
                        //body={descripcionTemplate}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
            </Fragment>
          ) : null}

          <Dialog
            header={renderHeaderFechas()}
            visible={visibleFechas}
            closable={false}
            footer={renderFooterFechas()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "70vw", paddingBottom: "0" }}
            position="top"
          >
            <Grid container spacing={3} style={{ marginTop: "1vw" }}>
              <Grid item xs={12} md={3}>
                <TextField
                  id="desde"
                  label="Desde"
                  type="date"
                  fullWidth
                  //onKeyDown={handleEnter}
                  value={fechas && fechas.desde ? fechas.desde : null}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFechas({
                      ...fechas,
                      desde: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  id="hasta"
                  label="Hasta"
                  type="date"
                  fullWidth
                  //onKeyDown={handleEnter}
                  value={fechas && fechas.hasta ? fechas.hasta : null}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFechas({
                      ...fechas,
                      hasta: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ paddingLeft: 0, marginTop: "0em" }}
              />

              <Grid item xs={12} md={2}>
                <TextField
                  id="titular"
                  label="ID Titular"
                  fullWidth
                  type="number"
                  value={titularId ? titularId : ""}
                  disabled={titularNombre !== null && titularNombre !== ""}
                  //onKeyDown={handleEnter}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setTitularId(e.target.value);
                    setTitularSeleccionado(null);
                    setRows(null);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  id="razonSearch"
                  label="Apellido y Nombre o Razón Social"
                  fullWidth
                  value={titularNombre ? titularNombre : ""}
                  disabled={
                    titularId !== null && titularId !== "" && titularId !== 0
                  }
                  //onKeyDown={handleEnter}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setTitularNombre(e.target.value);
                    setTitularSeleccionado(null);
                    setRows(null);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={1}>
                <Button
                  icon="pi pi-search"
                  className="p-button-rounded p-button-success"
                  onClick={() => buscarAfiliados()}
                />
              </Grid>

              <Grid item xs={12} md={5}>
                <Autocomplete
                  id="razon"
                  fullWidth
                  options={rows}
                  disabled={!rows}
                  noOptionsText={"No hay opciones"}
                  style={{ marginLeft: ".8vw", color: "green" }}
                  //defaultValue={grupo ? agrupar[grupo - 1] : null}
                  value={titularSeleccionado}
                  disablePortal
                  getOptionLabel={(option) => option.nombre_razonsocial}
                  renderInput={(params) => (
                    <TextField {...params} label="Seleccione el Afiliado" />
                  )}
                  onChange={(event, value) => {
                    if (value) {
                      setTitularSeleccionado(value);
                      setTitularId(value.id_titular);
                      setTitularNombre(null);
                    } else {
                      setTitularSeleccionado(null);
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  id="numero"
                  label="Número"
                  type="number"
                  fullWidth
                  //onKeyDown={handleEnter}
                  value={numero ? numero : ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setNumero(e.target.value);
                  }}
                />
              </Grid>

              {/*   
              <Grid
                item
                xs={12}
                md={12}
                style={{ paddingLeft: 0, marginTop: "0em" }}
              >
                {!rows &&
                ((titularId && titularId.length >= 4) ||
                  (titularNombre && titularNombre.length >= 3)) ? (
                  <ProgressBar
                    mode="indeterminate"
                    style={{ height: "6px" }}
                  ></ProgressBar>
                ) : null}
              </Grid>
                  */}
            </Grid>
          </Dialog>

          <Dialog
            //header={renderHeader()}
            visible={visibleComprobante}
            closable={true}
            maximizable={true}
            maximized={true}
            onHide={() => onHideComprobante()}
            //footer={renderFooter()}
            breakpoints={{ "960px": "95vw" }}
            style={{ width: "95vw", paddingBottom: "0" }}
            position="center"
          >
            <Grid container spacing={3} style={{ marginTop: "0vw" }}>
              {selectedRow ? (
                <Facturacion
                  idProps={selectedRow.id}
                  titularIdProps={selectedRow.id_titular}
                />
              ) : null}
            </Grid>
          </Dialog>

          <Dialog
            header={renderHeaderEnvios()}
            visible={visibleEnvios}
            closable={false}
            footer={renderFooterEnvios()}
            breakpoints={{ "560px": "75vw" }}
            style={{ width: "70vw", paddingBottom: "0" }}
            position="top"
          >
            <Grid container spacing={3} style={{ margin: "1vw" }}>
              <Grid item xs={12} md={7} style={{ padding: "0.2vw" }} />

              <Grid item xs={12} md={7} style={{ padding: "0.2vw" }}>
                <TextField
                  value={emailAdrress ? emailAdrress : null}
                  variant="standard"
                  id="email"
                  label="Email"
                  fullWidth
                  onChange={(e) => {
                    if (e.target.value) {
                      guardarEmailAddress(e.target.value);
                    } else {
                      guardarEmailAddress(null);
                      setEmailComprobante(false);
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { color: "red" },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4} style={{ padding: "0.2vw" }}>
                <TextField
                  value={celular ? celular : null}
                  variant="standard"
                  id="celular"
                  label="Celular"
                  fullWidth
                  onChange={(e) => {
                    if (e.target.value) {
                      guardarCelular(e.target.value);
                    } else {
                      guardarCelular(null);
                      setWhatsAppComprobante(false);
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { color: "red" },
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={3}
                style={{
                  paddingLeft: 0,
                  paddingTop: "1vw",
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <Checkbox
                      color="primary"
                      checked={emailComprobante ? emailComprobante : null}
                      style={{ marginLeft: "1vw" }}
                      disabled={!emailAdrress}
                      onChange={(e) => setEmailComprobante(e.target.checked)}
                    />
                  }
                  label="Email"
                  labelPlacement="start"
                  style={{ paddingLeft: ".7vw" }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={3}
                style={{
                  paddingLeft: 0,
                  paddingTop: "1vw",
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <Checkbox
                      color="primary"
                      checked={whatsAppComprobante ? whatsAppComprobante : null}
                      style={{ marginLeft: "1vw" }}
                      disabled={!celular}
                      onChange={(e) => setWhatsAppComprobante(e.target.checked)}
                    />
                  }
                  label="WhatsApp"
                  labelPlacement="start"
                  style={{ paddingLeft: ".7vw" }}
                />
              </Grid>
            </Grid>
          </Dialog>
        </Fragment>
      ) : null}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "../Menu";
import { InputNumber } from "primereact/inputnumber";
import { jsPDF } from "jspdf";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { NumeroLetras } from "../util/NumeroaLetras";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import QRCode from "qrcode";
import afip from "../../../assets/images/comprobanteautorizado.png";
import bwipjs from "bwip-js";

const exportColumnsListado = [
  { title: "Nº Afiliado.", dataKey: "id_titular" },
  { title: "Nombre/Razón Social", dataKey: "nombre_razonsocial" },
  { title: "Domicilio", dataKey: "domicilio" },
  { title: "Localidad", dataKey: "localidad" },
  { title: "Grupo", dataKey: "grupo" },
  { title: "Plan", dataKey: "plan" },
  { title: "Empresa", dataKey: "empresa" },
  { title: "Vigencia", dataKey: "vigencia" },
  { title: "Cuota", dataKey: "cuota" },
  { title: "Módulo", dataKey: "modulo" },
  { title: "Valor", dataKey: "valor" },
];

export default function ProcesosImpresion(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [visibleProceso, setVisibleProceso] = useState(true);
  const [todosLosCobradores, setTodosLosCobradores] = useState(false);
  const [repetirPeriodo, setRepetirPeriodo] = useState(false);
  const [proceso, guardarProceso] = useState(null);
  const [valor, guardarValor] = useState("cuota");
  const [fijo, setFijo] = useState(null);
  const [bandInicio, setBandInicio] = useState(false);

  const toast = useRef(null);
  const acCobrador = useRef(null);

  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    cobradores,
    statusAcceso,
    empresaElegida,
    listadoExcel,
    empresas,
    configuracion,
    obtenerAcceso,
    tiposComprobantes,
    impresionComprobantes,
    obtenerModulos,
    obtenerCobradores,
    obtenerEmpresas,
    procesoImpresion,
    obtenerTiposComprobantes,
    imprimirComprobantes,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "ProcesosImpresion",
      });
    }
  }, [state.id]);

  useEffect(() => {
    obtenerEmpresas();
    obtenerCobradores({ todos: false });
  }, []);

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  const exitClick = (e) => {
    navigate("/");
  };

  const onHideProceso = (e) => {
    setVisibleProceso(false);
  };

  const aceptarProceso = async (e) => {

    e.preventDefault();
    //Validar

    if (
      !proceso ||
      proceso.id_tipoComprobante === undefined ||
      proceso.id_tipoComprobante === null ||
      proceso.id_tipoComprobante === 0 ||
      proceso.id_tipoComprobante === ""
    ) {
      mensajeAlerta("Procesos", "El comprobante es necesario !.", "error");
      return;
    }

    if (
      !proceso ||
      proceso.desde === undefined ||
      proceso.desde === null ||
      proceso.desde === 0 ||
      proceso.desde === ""
    ) {
      mensajeAlerta("Procesos", "Número desde es necesario !.", "error");
      return;
    }

    if (
      !proceso ||
      proceso.hasta === undefined ||
      proceso.hasta === null ||
      proceso.hasta === 0 ||
      proceso.hasta === ""
    ) {
      mensajeAlerta("Procesos", "Número hasta es necesario !.", "error");
      return;
    }

    if (proceso && proceso.desde > proceso.hasta) {
      mensajeAlerta(
        "Procesos",
        "Número desde debe ser menor que número hasta !.",
        "error"
      );
      return;
    }

    if (!todosLosCobradores) {
      if (
        !proceso ||
        proceso.id_cobrador === undefined ||
        proceso.id_cobrador === null ||
        proceso.id_cobrador === 0 ||
        proceso.id_cobrador === ""
      ) {
        mensajeAlerta("Procesos", "El cobrador es necesario !.", "error");
        return;
      }
    }

    confirm1();
  };

  const confirm1 = () => {
    confirmDialog({
      message: "Esta seguro de iniciar el proceso de impresión",
      header: "Confirmación",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-success",
      closable: false,
      acceptLabel: "Si",
      accept: accept1,
    });
  };

  const accept1 = async () => {
    await procesoImpresion({
      proceso,
      todosLosCobradores,
      empresaElegida,
      usuario: state.usuario.usuario,
    }).then((data) => {
      let elem = document.getElementById("imprimirFacturas");
      elem.click();
    });
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Proceso de Impresión",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const renderHeaderProceso = () => {
    return (
      <div>
        <Tag
          value={
            empresas && empresas.length > 0 && empresaElegida
              ? "Proceso de Impresión de " + empresaElegida.nombre
              : null
          }
        ></Tag>

        <Tag
          style={{ marginLeft: "1vw" }}
          value={
            empresas &&
            empresas.length > 0 &&
            empresaElegida &&
            empresas.find((item) => item.id === empresaElegida.id)
              .facturaElectronica
              ? "Factura Electrónica"
              : null
          }
          severity={"danger"}
        ></Tag>
        <Tag
          style={{ marginLeft: "1vw" }}
          value={
            empresas &&
            empresas.length > 0 &&
            empresaElegida &&
            empresas.find((item) => item.id === empresaElegida.id).formularios
              .nombre
              ? "Formulario: " +
                empresas.find((item) => item.id === empresaElegida.id)
                  .formularios.nombre
              : null
          }
          severity={"success"}
        ></Tag>
      </div>
    );
  };

  const renderFooterProceso = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarProceso}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
          autoFocus
        >
          Aceptar
        </ButtonMui>

        <ButtonMui
          onClick={onHideProceso}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const fechaTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fecha)}</Fragment>;
  };

  const anuladoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.anulado}></Checkbox>
      </Fragment>
    );
  };

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  /*

  const imprimir = async () => {
    if (!impresionComprobantes || impresionComprobantes.length === 0) {
      return;
    }

    //console.log(impresionComprobantes)

    const doc = new jsPDF();

    let hoy = new Date();

    let fechaDia = hoy.getDate();
    let fechaMes = hoy.getMonth() + 1;
    let fechaAnio = hoy.getFullYear();
    let nombreMes = "";

    if (fechaMes === 1) {
      nombreMes = "Enero";
    }
    if (fechaMes === 2) {
      nombreMes = "Febrero";
    }
    if (fechaMes === 3) {
      nombreMes = "Marzo";
    }
    if (fechaMes === 4) {
      nombreMes = "Abril";
    }
    if (fechaMes === 5) {
      nombreMes = "Mayo";
    }
    if (fechaMes === 6) {
      nombreMes = "Junio";
    }
    if (fechaMes === 7) {
      nombreMes = "Julio";
    }
    if (fechaMes === 8) {
      nombreMes = "Agosto";
    }
    if (fechaMes === 9) {
      nombreMes = "Septiembre";
    }
    if (fechaMes === 10) {
      nombreMes = "Octubre";
    }
    if (fechaMes === 11) {
      nombreMes = "Noviembre";
    }
    if (fechaMes === 12) {
      nombreMes = "Diciembre";
    }

    let empresa = empresas.find((item) => item.id === empresaElegida.id);

    for (var i = 0; i < impresionComprobantes.length; i++) {
      let barCode =
        impresionComprobantes[i].id_titular +
        String(impresionComprobantes[i].numero).padStart(8, "0");
      if (
        configuracion &&
        configuracion.destinatario === 1 &&
        empresa.formularios.id === 1
      ) {
        //CUPONES GRIMPLOT - MEDISIM CON QR
        doc.setFontSize(8);
        let row = 133;
        doc.rect(105, row, 13, 13);
        row = row + 8;
        doc.setFontSize(24);
        doc.text(impresionComprobantes[i].tipo, 109, row);
        row = row + 3;
        doc.setFontSize(6);
        doc.text(
          "COD." +
            String(impresionComprobantes[i].id_comprobanteafip).padStart(
              2,
              "0"
            ),
          108,
          row
        );

        row = row - 8;
        doc.setFontSize(8);
        doc.text("FACTURA", 155, row);
        row = row + 4;
        doc.text(
          "Nº: " +
            String(impresionComprobantes[i].puntoventa).padStart(4, "0") +
            "-" +
            String(impresionComprobantes[i].numero).padStart(8, "0"),
          155,
          row
        );
        row = row + 4;
        doc.text(
          "FECHA: " + formatDate(impresionComprobantes[i].fecha),
          155,
          row
        );

        row = row + 10;
        doc.setFontSize(8);
        if (impresionComprobantes[i].id_empresa === 4) {
          doc.text("Grimplot Inc S.A.", 38, row);
          row = row + 4;
          doc.text("HIPOLITO YRIGOYEN 11021", 31, row);
          doc.text("C.U.I.T.: " + "30-71085401-3", 155, row);
          row = row + 4;
          doc.text("(1832) LOMAS DE ZAMORA", 32, row);
          doc.text("Ing. Brutos C.M.: " + "30-71085401-3", 155, row);
          row = row + 4;
          doc.text(
            "EMERGENCIA: TEL.: 0810 220 6060 (Lineas Rotativas)",
            24,
            row
          );
          doc.text("Inicio de Actividades: " + "01/2009", 155, row);
          row = row + 5;
          doc.text("I.V.A. Responsable Inscripto", 31, row);
        }

        row = row + 5;
        doc.text(
          "Sr/es: " +
            impresionComprobantes[i].nombre_titular +
            " (" +
            impresionComprobantes[i].id_titular +
            ")",
          15,
          row
        );
        row = row + 4;
        if (impresionComprobantes[i].afiliados.entre1) {
          doc.text(
            "Domicilio: " +
              impresionComprobantes[i].afiliados.domicilio +
              " Entre " +
              impresionComprobantes[i].afiliados.entre1 +
              " y " +
              impresionComprobantes[i].afiliados.entre2 +
              " " +
              impresionComprobantes[i].afiliados.localidades.nombre +
              " (" +
              impresionComprobantes[i].afiliados.telefono1 +
              ")",
            15,
            row
          );
        } else {
          doc.text(
            "Domicilio: " +
              impresionComprobantes[i].afiliados.domicilio +
              " " +
              impresionComprobantes[i].afiliados.localidades.nombre +
              " (" +
              impresionComprobantes[i].afiliados.telefono1 +
              ")",
            15,
            row
          );
        }
        row = row + 4;
        doc.text(
          "I.V.A.: " +
            impresionComprobantes[i].tiposcontribuyentes.nombre +
            "      CONDICION: CONTADO" +
            "         C.U.I.T.: " +
            impresionComprobantes[i].cuit,
          15,
          row
        );
        row = row + 4;
        doc.text("COBRADOR: " + impresionComprobantes[i].id_cobrador, 15, row);
        row = row + 10;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            if (j === 0) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 15, row);
            }
            if (j === 1) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 60, row);
            }
            if (j === 2) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 105, row);
            }
            if (j === 3) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 150, row);
            }
          }
        }
        row = row + 4;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            if (j === 0) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                15,
                row
              );
            }
            if (j === 1) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                60,
                row
              );
            }
            if (j === 2) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                105,
                row
              );
            }
            if (j === 3) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                150,
                row
              );
            }
          }
        }

        // Cupones 1 a 4

        row = row + 4;
        let periodoMes = null;
        let periodoAnio = null;
        let mesNombre = null;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            periodoMes = Number(
              impresionComprobantes[i].periodo.substring(0, 2)
            );
            periodoAnio =
              "20" + impresionComprobantes[i].periodo.substring(3, 6);

            if (periodoMes === 1) {
              mesNombre = "Enero";
            }
            if (periodoMes === 2) {
              mesNombre = "Febrero";
            }
            if (periodoMes === 3) {
              mesNombre = "Marzo";
            }
            if (periodoMes === 4) {
              mesNombre = "Abril";
            }
            if (periodoMes === 5) {
              mesNombre = "Mayo";
            }
            if (periodoMes === 6) {
              mesNombre = "Junio";
            }
            if (periodoMes === 7) {
              mesNombre = "Julio";
            }
            if (periodoMes === 8) {
              mesNombre = "Agosto";
            }
            if (periodoMes === 9) {
              mesNombre = "Septiembre";
            }
            if (periodoMes === 10) {
              mesNombre = "Octubre";
            }
            if (periodoMes === 11) {
              mesNombre = "Noviembre";
            }
            if (periodoMes === 12) {
              mesNombre = "Diciembre";
            }

            if (j === 0) {
              doc.text(mesNombre + "/" + periodoAnio, 15, row);
            }
            if (j === 1) {
              doc.text(mesNombre + "/" + periodoAnio, 60, row);
            }
            if (j === 2) {
              doc.text(mesNombre + "/" + periodoAnio, 105, row);
            }
            if (j === 3) {
              doc.text(mesNombre + "/" + periodoAnio, 150, row);
            }
          }
        }

        // Cupones 5 a 8

        row = row + 5;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            if (j === 5) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 15, row);
            }
            if (j === 6) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 60, row);
            }
            if (j === 7) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 105, row);
            }
            if (j === 8) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 150, row);
            }
          }
        }
        row = row + 4;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            if (j === 5) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                15,
                row
              );
            }
            if (j === 6) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                60,
                row
              );
            }
            if (j === 7) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                105,
                row
              );
            }
            if (j === 8) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                150,
                row
              );
            }
          }
        }
        row = row + 4;
        periodoMes = null;
        periodoAnio = null;
        mesNombre = null;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            periodoMes = Number(
              impresionComprobantes[i].periodo.substring(0, 2)
            );
            periodoAnio =
              "20" + impresionComprobantes[i].periodo.substring(3, 6);

            if (periodoMes === 1) {
              mesNombre = "Enero";
            }
            if (periodoMes === 2) {
              mesNombre = "Febrero";
            }
            if (periodoMes === 3) {
              mesNombre = "Marzo";
            }
            if (periodoMes === 4) {
              mesNombre = "Abril";
            }
            if (periodoMes === 5) {
              mesNombre = "Mayo";
            }
            if (periodoMes === 6) {
              mesNombre = "Junio";
            }
            if (periodoMes === 7) {
              mesNombre = "Julio";
            }
            if (periodoMes === 8) {
              mesNombre = "Agosto";
            }
            if (periodoMes === 9) {
              mesNombre = "Septiembre";
            }
            if (periodoMes === 10) {
              mesNombre = "Octubre";
            }
            if (periodoMes === 11) {
              mesNombre = "Noviembre";
            }
            if (periodoMes === 12) {
              mesNombre = "Diciembre";
            }

            if (j === 5) {
              doc.text(mesNombre + "/" + periodoAnio, 15, row);
            }
            if (j === 6) {
              doc.text(mesNombre + "/" + periodoAnio, 60, row);
            }
            if (j === 7) {
              doc.text(mesNombre + "/" + periodoAnio, 105, row);
            }
            if (j === 8) {
              doc.text(mesNombre + "/" + periodoAnio, 150, row);
            }
          }
        }

        row = row + 5;

        doc.text(
          parseFloat(impresionComprobantes[i].tasapercarba).toFixed(2),
          115,
          row
        );
        if (Number(impresionComprobantes[i].iva1) > 0) {
          doc.text("10.5", 150, row);
        }
        if (Number(impresionComprobantes[i].iva2) > 0) {
          doc.text("21", 150, row);
        }
        if (Number(impresionComprobantes[i].iva3) > 0) {
          doc.text("27", 150, row);
        }

        row = row + 4;
        doc.setFontSize(6);

        doc.text(
          "SON: " + NumeroLetras(impresionComprobantes[i].total, 0),
          15,
          row
        );
        doc.text("II.BB", 115, row);
        doc.text("Neto Gravado", 130, row);
        doc.text("I.V.A.", 150, row);
        doc.text("total", 170, row);

        row = row + 4;
        doc.setFontSize(8);
        doc.text(
          impresionComprobantes[i].cae
            ? "C.A.E.: " + impresionComprobantes[i].cae
            : "C.A.E.: ",
          15,
          row
        );
        doc.text(
          impresionComprobantes[i].vtocae
            ? "Fecha Vto.: " + formatDate(impresionComprobantes[i].vtocae)
            : "Fecha Vto.: ",
          55,
          row
        );
        doc.text(
          parseFloat(impresionComprobantes[i].percarba).toFixed(2),
          115,
          row
        );
        doc.text(
          parseFloat(impresionComprobantes[i].subtotal).toFixed(2),
          130,
          row
        );
        doc.text(parseFloat(impresionComprobantes[i].iva).toFixed(2), 150, row);
        doc.text(
          parseFloat(impresionComprobantes[i].total).toFixed(2),
          170,
          row
        );

        if (impresionComprobantes[i].cae) {
          const cuit =
            empresa.cuit.substring(0, 2) +
            empresa.cuit.substring(3, 11) +
            empresa.cuit.substring(12, 13);

          let pv = Number(impresionComprobantes[i].puntoventa);
          let nu = Number(impresionComprobantes[i].numero);
          let id = Number(impresionComprobantes[i].id_comprobanteafip);

          let qrAfip = {
            ver: 1,
            fecha: impresionComprobantes[i].fecha,
            cuit,
            ptoVta: pv,
            tipoCmp: id,
            nroCmp: nu,
            importe: Number(impresionComprobantes[i].total),
            moneda: "PES",
            ctz: 1,
            tipoDocRec:
              impresionComprobantes[i].afiliados.id_tipodocumento === 3
                ? 80
                : 96,
            nroDocRec: impresionComprobantes[i].cuit,
            tipoCodAut: "A",
            codAut: impresionComprobantes[i].cae,
          };

          const stringifyObj = JSON.stringify(qrAfip);
          const binaryObj = new TextEncoder().encode(stringifyObj);
          const base64Obj = base64_encode(binaryObj);

          let qr = "https://www.afip.gob.ar/fe/qr/?p=" + base64Obj;

          let qrImage = await QRCode.toDataURL(qr);
          row = row + 4;
          doc.addImage(qrImage, "JPEG", 15, row, 20, 20);
          doc.addImage(afip, "JPEG", 40, row + 3, 30, 10);

          row = row + 33;

          doc.setFontSize(8);
          doc.text(
            "Recibo Nº: " +
              String(impresionComprobantes[i].puntoventa).padStart(4, "0") +
              "-" +
              String(impresionComprobantes[i].numero).padStart(8, "0"),
            15,
            row
          );
          doc.text(
            "Emitido el: " + formatDate(impresionComprobantes[i].fecha),
            95,
            row
          );
          doc.text(barCode, 150, row);
          row = row + 4;
          doc.text(impresionComprobantes[i].nombre_titular, 15, row);
          doc.text(
            "Cobrador: " + impresionComprobantes[i].id_cobrador,
            95,
            row
          );

          const canvas = document.createElement("canvas");

          const dataURL = bwipjs
            .toCanvas(canvas, {
              bcid: "code128", // Barcode type
              text: barCode,
              scale: 3, // 3x scaling factor
              height: 20, // Bar height, in millimeters,
              border: 5,
              includetext: false, // Show human-readable text
            })
            .toDataURL("image/png");

          doc.addImage(dataURL, "JPEG", 150, row, 50, 10);

          row = row + 4;
          doc.text("Socio Nº: " + impresionComprobantes[i].id_titular, 15, row);
          row = row + 4;
          doc.text(
            impresionComprobantes[i].afiliados.domicilio +
              " " +
              impresionComprobantes[i].afiliados.localidades.nombre,
            15,
            row
          );
          if (impresionComprobantes[i].afiliados.entre1) {
            row = row + 4;
            doc.text(
              "Entre " +
                impresionComprobantes[i].afiliados.entre1 +
                " y " +
                impresionComprobantes[i].afiliados.entre2,
              15,
              row
            );
          }
          row = row + 4;
          doc.text(mesNombre + "/" + periodoAnio, 15, row);
          row = row + 4;
          doc.text(
            "$ " + parseFloat(impresionComprobantes[i].total).toFixed(2),
            15,
            row
          );
        }
      }

      if (
        configuracion &&
        configuracion.destinatario === 1 &&
        empresa.formularios.id === 2
      ) {
        //CUPONES SOCIOS

        let perMes = Number(impresionComprobantes[i].periodo.substring(0, 2));
        let perAnio = "20" + impresionComprobantes[i].periodo.substring(3, 6);

        let mesNom = null;

        if (perMes === 1) {
          mesNom = "Enero";
        }
        if (perMes === 2) {
          mesNom = "Febrero";
        }
        if (perMes === 3) {
          mesNom = "Marzo";
        }
        if (perMes === 4) {
          mesNom = "Abril";
        }
        if (perMes === 5) {
          mesNom = "Mayo";
        }
        if (perMes === 6) {
          mesNom = "Junio";
        }
        if (perMes === 7) {
          mesNom = "Julio";
        }
        if (perMes === 8) {
          mesNom = "Agosto";
        }
        if (perMes === 9) {
          mesNom = "Septiembre";
        }
        if (perMes === 10) {
          mesNom = "Octubre";
        }
        if (perMes === 11) {
          mesNom = "Noviembre";
        }
        if (perMes === 12) {
          mesNom = "Diciembre";
        }

        doc.setFontSize(8);

        let row = 0;

        if (i % 2 == 0) {
          row = 42;
        } else {
          row = 196;
        }

        doc.text(impresionComprobantes[i].nombre_titular, 10, row);

        doc.text(mesNom.toUpperCase() + "/" + perAnio, 175, row);

        row = row + 4;
        if (impresionComprobantes[i].afiliados.entre1) {
          doc.text(
            "Domicilio: " +
              impresionComprobantes[i].afiliados.domicilio +
              " Entre " +
              impresionComprobantes[i].afiliados.entre1 +
              " y " +
              impresionComprobantes[i].afiliados.entre2 +
              " " +
              impresionComprobantes[i].afiliados.localidades.nombre +
              " (" +
              impresionComprobantes[i].afiliados.telefono1 +
              ")",
            10,
            row
          );
        } else {
          doc.text(
            "Domicilio: " +
              impresionComprobantes[i].afiliados.domicilio +
              " " +
              impresionComprobantes[i].afiliados.localidades.nombre +
              " (" +
              impresionComprobantes[i].afiliados.telefono1 +
              ")",
            10,
            row
          );
        }
        row = row + 4;
        doc.text(impresionComprobantes[i].tiposcontribuyentes.nombre, 10, row);
        doc.text(impresionComprobantes[i].total, 175, row);
        row = row + 8;
        doc.text(impresionComprobantes[i].id_titular, 175, row);

        row = row + 23;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            if (j === 0) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 10, row);
            }
            if (j === 1) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 55, row);
            }
            if (j === 2) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 110, row);
            }
            if (j === 3) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 165, row);
            }
          }
        }
        row = row + 4;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            if (j === 0) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                10,
                row
              );
            }
            if (j === 1) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                55,
                row
              );
            }
            if (j === 2) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                110,
                row
              );
            }
            if (j === 3) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                165,
                row
              );
            }
          }
        }

        // Cupones 1 a 4

        row = row + 4;
        perMes = null;
        perAnio = null;
        mesNom = null;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            perMes = Number(impresionComprobantes[i].periodo.substring(0, 2));
            perAnio = "20" + impresionComprobantes[i].periodo.substring(3, 6);

            if (perMes === 1) {
              mesNom = "Enero";
            }
            if (perMes === 2) {
              mesNom = "Febrero";
            }
            if (perMes === 3) {
              mesNom = "Marzo";
            }
            if (perMes === 4) {
              mesNom = "Abril";
            }
            if (perMes === 5) {
              mesNom = "Mayo";
            }
            if (perMes === 6) {
              mesNom = "Junio";
            }
            if (perMes === 7) {
              mesNom = "Julio";
            }
            if (perMes === 8) {
              mesNom = "Agosto";
            }
            if (perMes === 9) {
              mesNom = "Septiembre";
            }
            if (perMes === 10) {
              mesNom = "Octubre";
            }
            if (perMes === 11) {
              mesNom = "Noviembre";
            }
            if (perMes === 12) {
              mesNom = "Diciembre";
            }

            if (j === 0) {
              doc.text(mesNom + "/" + perAnio, 10, row);
            }
            if (j === 1) {
              doc.text(mesNom + "/" + perAnio, 55, row);
            }
            if (j === 2) {
              doc.text(mesNom + "/" + perAnio, 110, row);
            }
            if (j === 3) {
              doc.text(mesNom + "/" + perAnio, 165, row);
            }
          }
        }

        // Cupones 5 a 8

        row = row + 18;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            if (j === 5) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 10, row);
            }
            if (j === 6) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 55, row);
            }
            if (j === 7) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 110, row);
            }
            if (j === 8) {
              doc.text(impresionComprobantes[i].integrantes[j].id, 165, row);
            }
          }
        }
        row = row + 4;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            if (j === 5) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                10,
                row
              );
            }
            if (j === 6) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                55,
                row
              );
            }
            if (j === 7) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                110,
                row
              );
            }
            if (j === 8) {
              doc.text(
                impresionComprobantes[i].integrantes[
                  j
                ].nombre_razonsocial.substring(0, 20),
                165,
                row
              );
            }
          }
        }
        row = row + 4;
        perMes = null;
        perAnio = null;
        mesNom = null;
        for (var j = 0; j < impresionComprobantes[i].integrantes.length; j++) {
          if (impresionComprobantes[i].integrantes[j].habilitado) {
            perMes = Number(impresionComprobantes[i].periodo.substring(0, 2));
            perAnio = "20" + impresionComprobantes[i].periodo.substring(3, 6);

            if (perMes === 1) {
              mesNom = "Enero";
            }
            if (perMes === 2) {
              mesNom = "Febrero";
            }
            if (perMes === 3) {
              mesNom = "Marzo";
            }
            if (perMes === 4) {
              mesNom = "Abril";
            }
            if (perMes === 5) {
              mesNom = "Mayo";
            }
            if (perMes === 6) {
              mesNom = "Junio";
            }
            if (perMes === 7) {
              mesNom = "Julio";
            }
            if (perMes === 8) {
              mesNom = "Agosto";
            }
            if (perMes === 9) {
              mesNom = "Septiembre";
            }
            if (perMes === 10) {
              mesNom = "Octubre";
            }
            if (perMes === 11) {
              mesNom = "Noviembre";
            }
            if (perMes === 12) {
              mesNom = "Diciembre";
            }

            if (j === 5) {
              doc.text(mesNom + "/" + perAnio, 10, row);
            }
            if (j === 6) {
              doc.text(mesNom + "/" + perAnio, 55, row);
            }
            if (j === 7) {
              doc.text(mesNom + "/" + perAnio, 110, row);
            }
            if (j === 8) {
              doc.text(mesNom + "/" + perAnio, 165, row);
            }
          }
        }

        row = row + 13;

        doc.setFontSize(8);
        doc.text(barCode, 150, row);
        doc.text(mesNom + "/" + perAnio, 180, row);

        row = row + 4;
        doc.text(
          impresionComprobantes[i].id_titular +
            " - " +
            impresionComprobantes[i].nombre_titular,
          15,
          row
        );
        doc.text("Cobrador: " + impresionComprobantes[i].id_cobrador, 130, row);

        const canvas = document.createElement("canvas");

        const dataURL = bwipjs
          .toCanvas(canvas, {
            bcid: "code128", // Barcode type
            text: barCode,
            scale: 3, // 3x scaling factor
            height: 20, // Bar height, in millimeters,
            border: 5,
            includetext: false, // Show human-readable text
          })
          .toDataURL("image/png");

        doc.addImage(dataURL, "JPEG", 150, row - 2, 50, 10);

        row = row + 4;
        doc.text(
          impresionComprobantes[i].afiliados.domicilio +
            " " +
            impresionComprobantes[i].afiliados.localidades.nombre,
          15,
          row
        );
        doc.text(
          "$ " + parseFloat(impresionComprobantes[i].total).toFixed(2),
          130,
          row
        );

        if (impresionComprobantes[i].afiliados.entre1) {
          row = row + 4;
          doc.text(
            "Entre " +
              impresionComprobantes[i].afiliados.entre1 +
              " y " +
              impresionComprobantes[i].afiliados.entre2,
            15,
            row
          );
        }
      }

      if (i < impresionComprobantes.length - 1) {
        doc.addPage();
      }
    }

    window.open(doc.output("bloburl"));
  };

  */


  //INICIO

  if (!bandInicio && empresaElegida && empresas && empresas.length>0) {
    setBandInicio(true);
    obtenerTiposComprobantes({
      empresaOrigenId: empresaElegida.id,
      tipoContribuyenteDestino: null,
    });

    const formularioId  = empresas.find((item) => item.id === empresaElegida.id)
    .formularioId

    guardarProceso({
      ...proceso,
      formularioId,
    })


  }

  // FIN


  //console.log(empresas);
  //console.log(serviciosFinalizadosExcel);


  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      <ConfirmDialog />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Dialog
            header={renderHeaderProceso()}
            visible={visibleProceso}
            closable={false}
            footer={renderFooterProceso()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "70vw", paddingBottom: "0" }}
            position="top"
          >
            <Grid container spacing={3} style={{ marginTop: "1vw" }}>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  id="tipoComprobante"
                  fullWidth
                  noOptionsText={"No hay opciones"}
                  options={
                    tiposComprobantes
                      ? tiposComprobantes.filter((item) => item.id < 1000)
                      : null
                  }
                  getOptionLabel={(option) => option.nombre}
                  openOnFocus={true}
                  disablePortal
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Comprobante *"
                      variant="standard"
                    />
                  )}
                  //onKeyDown={handleEnter}
                  onChange={(event, value) => {
                    if (value) {
                      guardarProceso({
                        ...proceso,
                        id_tipoComprobante: value.id,
                        tipoComprobante: value.nombre,
                        abreviatura: value.abreviatura,
                      });
                    } else {
                      guardarProceso({
                        ...proceso,
                        id_tipoComprobante: null,
                        tipoComprobante: null,
                        abreviatura: null,
                      });
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <label htmlFor="desde">Desde *</label>
                <InputNumber
                  id="desde"
                  inputId="desde"
                  value={proceso ? proceso.desde : null}
                  onChange={(e) =>
                    guardarProceso({
                      ...proceso,
                      desde: e.value,
                    })
                  }
                  //mode="decimal"
                  //locale="en-US"
                  //minFractionDigits={2}
                  style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <label htmlFor="hasta">Hasta *</label>
                <InputNumber
                  id="hasta"
                  inputId="hasta"
                  value={proceso ? proceso.hasta : null}
                  onChange={(e) =>
                    guardarProceso({
                      ...proceso,
                      hasta: e.value,
                    })
                  }
                  //mode="decimal"
                  //locale="en-US"
                  //minFractionDigits={2}
                  style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="cobrador"
                  ref={acCobrador}
                  fullWidth
                  noOptionsText={"No hay opciones"}
                  options={
                    cobradores
                      ? cobradores.filter((item) => item.habilitado)
                      : null
                  }
                  getOptionLabel={(option) => option.nombre}
                  openOnFocus={true}
                  disablePortal
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cobrador *"
                      variant="standard"
                    />
                  )}
                  disabled={todosLosCobradores}
                  //onKeyDown={handleEnter}
                  onChange={(event, value) => {
                    if (value) {
                      guardarProceso({
                        ...proceso,
                        id_cobrador: value.id,
                        cobrador: value.nombre,
                      });
                    } else {
                      guardarProceso({
                        ...proceso,
                        id_cobrador: null,
                        cobrador: null,
                      });
                    }
                  }}
                />
              </Grid>

              <Grid
                item
                xs={6}
                md={3}
                style={{ paddingLeft: "1vw", marginTop: "1vw" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ marginLeft: "1vw", marginRight: "1vw" }}
                      inputId="todosLosCobradores"
                      onChange={(e) => {
                        if (proceso && proceso.id_cobrador) {
                          acCobrador.current
                            .getElementsByClassName(
                              "MuiAutocomplete-clearIndicator"
                            )[0]
                            .click();
                        }
                        setTodosLosCobradores(e.target.checked);
                      }}
                      checked={todosLosCobradores ? todosLosCobradores : false}
                    />
                  }
                  label="Todos Las Cobradores"
                />
              </Grid>
              <button
                id="imprimirFacturas"
                type="button"
                onClick={(e) => {
                  imprimirComprobantes(proceso);
                }}
                style={{ display: "none" }}
              >
                Imprimir Facturas
              </button>
            </Grid>
          </Dialog>
        </Fragment>
      ) : null}

      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

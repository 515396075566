import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";
import { InputNumber } from "primereact/inputnumber";
import { jsPDF } from "jspdf";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

export default function MovimientosCheques(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  let navigate = useNavigate();

  const estadosCheques = [
    { id: 1, nombre: "CARTERA" },
    { id: 2, nombre: "ACREDITADO" },
    { id: 3, nombre: "DEBITADO" },
    { id: 4, nombre: "VENTANILLA" },
    { id: 5, nombre: "DEPOSITADO" },
    { id: 6, nombre: "RECHAZADO" },
    { id: 7, nombre: "ENTREGADO" },
    { id: 8, nombre: "ANULADO" },
  ];

  const tiposCheques = [
    { id: 1, nombre: "TERCEROS" },
    { id: 2, nombre: "PROPIOS" },
    { id: 3, nombre: "TODOS" },
  ];

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Descripción", dataKey: "descripcion" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [movimientoCheque, guardarMovimientoCheque] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleDatos, setVisibleDatos] = useState(false);
  const [visibleDepositar, setVisibleDepositar] = useState(false);
  const [visibleAcreditar, setVisibleAcreditar] = useState(false);
  const [detalle, guardarDetalle] = useState(null);
  const [fechaDesde, guardarFechaDesde] = useState(null);
  const [fechaHasta, guardarFechaHasta] = useState(null);
  const [empresaTodas, setEmpresaTodas] = useState(true);
  const [estado, guardarEstado] = useState("CARTERA");
  const [tipo, guardarTipo] = useState("TODOS");
  const [tipoMovimiento, setTipoMovimiento] = useState(null);
  const [modificarCheque, setModificarCheque] = useState(false);
  const [cotizaciones, guardarCotizaciones] = useState([]);
  const [cotizacion, guardarCotizacion] = useState(null);
  const [monedaMovimiento, guardarMonedaMovimiento] = useState(null);

  const toast = useRef(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    movimientosCheques,
    empresaElegida,
    chequesEgresos,
    chequesIngresos,
    chequesSaldoAnterior,
    chequesSaldoActual,
    empresas,
    conceptos,
    monedas,
    monedaPredeterminada,
    bancos,
    cuentas,
    obtenerMovimientosCheques,
    obtenerAcceso,
    agregarMovimientoCheque,
    actualizarMovimientoCheque,
    obtenerEmpresas,
    obtenerConceptos,
    obtenerBancos,
    obtenerCuentas,
    confirmarAnularCheque,
    confirmarAcreditarCheque,
    confirmarDepositarCheque,
    confirmarRechazarCheque,
    confirmarVentanillaCheque,
    obtenerMonedas,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "MovimientosCheques",
      });
    }
  }, [state]);

  useEffect(() => {
    let date = new Date();
    let day = `${date.getDate()}`.padStart(2, "0");
    let month = `${date.getMonth() + 1}`.padStart(2, "0");
    let year = date.getFullYear();
    let fecha = `${year}-${month}-${day}`;

    obtenerMovimientosCheques({
      empresaTodas,
      empresaId: null,
      tipo: tipo,
      estado: estado,
    });
    obtenerEmpresas();
    obtenerConceptos();
    obtenerBancos();
    obtenerCuentas();
    obtenerMonedas();
  }, []);

  const imprimirClick = () => {
    if (!selectedRow) {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un movimiento de cheque.",
        life: 3000,
      });
    }

    const doc = new jsPDF();
    let hoy = new Date();

    let fechaDia = hoy.getDate();
    let fechaMes = hoy.getMonth() + 1;
    let fechaAnio = hoy.getFullYear();
    let nombreMes = "";

    if (fechaMes === 1) {
      nombreMes = "Enero";
    }
    if (fechaMes === 2) {
      nombreMes = "Febrero";
    }
    if (fechaMes === 3) {
      nombreMes = "Marzo";
    }
    if (fechaMes === 4) {
      nombreMes = "Abril";
    }
    if (fechaMes === 5) {
      nombreMes = "Mayo";
    }
    if (fechaMes === 6) {
      nombreMes = "Junio";
    }
    if (fechaMes === 7) {
      nombreMes = "Julio";
    }
    if (fechaMes === 8) {
      nombreMes = "Agosto";
    }
    if (fechaMes === 9) {
      nombreMes = "Septiembre";
    }
    if (fechaMes === 10) {
      nombreMes = "Octubre";
    }
    if (fechaMes === 11) {
      nombreMes = "Noviembre";
    }
    if (fechaMes === 12) {
      nombreMes = "Diciembre";
    }

    let row = 10;
    doc.setFontSize(10);
    doc.text(fechaDia + " de " + nombreMes + " de " + fechaAnio, 160, row);
    row = 20;
    doc.setFontSize(11);
    let titulo = "COMPROBANTE DE CAJA";
    doc.text(titulo, 80, row);
    let textWidth = doc.getTextWidth(titulo);
    doc.line(80, row + 2, 80 + textWidth + 1, row + 2);
    doc.setFontSize(10);
    row = 30;

    doc.text(`ID: ${selectedRow.id}`, 30, row, "left", "middle");

    doc.text(`EMPRESA: ${selectedRow.empresa}`, 120, row, "left", "middle");

    row = row + 10;

    doc.text(
      `Fecha del Movimiento: ${formatDate(selectedRow.fecha)}`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text(
      `Concepto del Movimiento: ${
        selectedRow.concepto !== null ? selectedRow.concepto : ""
      }`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text(
      `Detalle del Movimiento: ${
        selectedRow.detalle !== null ? selectedRow.detalle : ""
      }`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text(
      `Tipo de  Movimiento: ${
        selectedRow.tipo === "i"
          ? "INGRESO DE CAJA"
          : selectedRow.tipo === "e"
          ? "EGRESO DE CAJA"
          : ""
      }`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 10;

    doc.text(
      `Importe $: ${parseFloat(selectedRow.importe).toFixed(2)}`,
      30,
      row,
      "left",
      "middle"
    );

    row = row + 50;

    doc.text(`Recibí conforme`, 30, row, "left", "middle");

    doc.text(`Firma y sello de la cheque`, 120, row, "left", "middle");

    row = row + 50;

    doc.text(`Firma y aclaración`, 30, row, "left", "middle");

    doc.text(`${selectedRow.usuario}`, 120, row, "left", "middle");

    window.open(doc.output("bloburl"));
    //setSelectedRow(null)
  };

  const anularClick = (e) => {
    if (!selectedRow) {
      toast.current.show({
        severity: "info",
        summary: "Anular",
        detail: "Debe seleccionar un cheque.",
        life: 3000,
      });
      return;
    }

    let motivo = "";
    toast.current.show({
      severity: "warn",
      sticky: true,
      content: (
        <div
          className="p-flex p-flex-column"
          style={{ flex: "1", marginLeft: "1em" }}
        >
          <div className="p-text-center">
            <i
              className="pi pi-exclamation-triangle"
              style={{ fontSize: "3rem" }}
            ></i>
            <h4>{`Esta seguro de anular el cheque ID ${selectedRow.id} de $ ${selectedRow.importe} del banco ${selectedRow.detalleBanco}`}</h4>
            <Grid item xs={6} md={12}>
              <TextField
                value={movimientoCheque ? movimientoCheque.detalle : null}
                id="detalle"
                label="Motivo"
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  motivo = e.target.value;
                }}
              />
            </Grid>
          </div>
          <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
            <div className="p-col-6">
              <Button
                type="button"
                label="Si"
                className="p-button-success"
                onClick={(e) => {
                  //console.log(motivo);
                  confirmarAnularCheque({
                    estadoId: selectedRow.estadoId,
                    usuario: state.usuario.usuario,
                  });
                  setTimeout(() => {
                    obtenerMovimientosCheques({
                      empresaTodas,
                      empresaId: empresaElegida.id,
                      tipo: tipo,
                      estado: estado,
                    });
                  }, 1000);
                  opcionNo();
                }}
              />
            </div>
            <div className="p-col-6">
              <Button
                type="button"
                label="No"
                className="p-button-danger"
                onClick={(e) => opcionNo()}
              />
            </div>
          </div>
        </div>
      ),
    });
  };

  const rechazarClick = (e) => {
    if (!selectedRow) {
      toast.current.show({
        severity: "info",
        summary: "Rechazar",
        detail: "Debe seleccionar un cheque.",
        life: 3000,
      });
      return;
    }

    let motivo = "";
    toast.current.show({
      severity: "warn",
      sticky: true,
      content: (
        <div
          className="p-flex p-flex-column"
          style={{ flex: "1", marginLeft: "1em" }}
        >
          <div className="p-text-center">
            <i
              className="pi pi-exclamation-triangle"
              style={{ fontSize: "3rem" }}
            ></i>
            <h4>{`Esta seguro de rechazar el cheque ID ${selectedRow.id} de $ ${selectedRow.importe} del banco ${selectedRow.detalleBanco}`}</h4>
            <Grid item xs={6} md={12}>
              <TextField
                value={movimientoCheque ? movimientoCheque.detalle : null}
                id="detalle"
                label="Motivo"
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  motivo = e.target.value;
                }}
              />
            </Grid>
          </div>
          <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
            <div className="p-col-6">
              <Button
                type="button"
                label="Si"
                className="p-button-success"
                onClick={(e) => {
                  confirmarRechazarCheque({
                    estadoId: selectedRow.estadoId,
                    usuario: state.usuario.usuario,
                    estado: selectedRow.estado,
                    detalle: motivo,
                  });
                  setTimeout(() => {
                    obtenerMovimientosCheques({
                      empresaTodas,
                      empresaId: empresaElegida.id,
                      tipo: tipo,
                      estado: estado,
                    });
                  }, 1000);
                  opcionNo();
                }}
              />
            </div>
            <div className="p-col-6">
              <Button
                type="button"
                label="No"
                className="p-button-danger"
                onClick={(e) => opcionNo()}
              />
            </div>
          </div>
        </div>
      ),
    });
  };

  function opcionNo() {
    toast.current.clear();
    setSelectedRow(null);
  }

  const depositarClick = (e) => {
    if (!selectedRow) {
      toast.current.show({
        severity: "info",
        summary: "Depositar",
        detail: "Debe seleccionar un cheque.",
        life: 3000,
      });
      return;
    }

    if (selectedRow.tipo !== "TERCEROS") {
      toast.current.show({
        severity: "info",
        summary: "Depositar",
        detail: "Debe seleccionar un cheque de terceros.",
        life: 3000,
      });
      return;
    }

    if (selectedRow.diferenciaDias > 0) {
      toast.current.show({
        severity: "warn",
        summary: "Depositar",
        detail: "El cheque no tiene fecha para depositar.",
        life: 3000,
      });
      return;
    }

    guardarMovimientoCheque({
      ...movimientoCheque,
      detalle: null,
    });

    setVisibleDepositar(true);
  };

  const acreditarClick = (e) => {
    if (!selectedRow) {
      toast.current.show({
        severity: "info",
        summary: "Acreditar",
        detail: "Debe seleccionar un cheque.",
        life: 3000,
      });
      return;
    }

    if (selectedRow.tipo !== "TERCEROS") {
      toast.current.show({
        severity: "info",
        summary: "Acreditar",
        detail: "Debe seleccionar un cheque de terceros.",
        life: 3000,
      });
      return;
    }

    guardarMovimientoCheque({
      ...movimientoCheque,
      detalle:
        "CRED. CH. " + selectedRow.detalleBanco + " Nº. " + selectedRow.numero,
    });

    setVisibleAcreditar(true);
  };

  const ventanillaClick = (e) => {
    if (!selectedRow) {
      toast.current.show({
        severity: "info",
        summary: "Ventanilla",
        detail: "Debe seleccionar un cheque.",
        life: 3000,
      });
      return;
    }

    let motivo = "";
    let banco = null;
    if (selectedRow.tipo === "TERCEROS") {
      banco = selectedRow.detalleBanco;
    }
    if (selectedRow.tipo === "PROPIOS") {
      banco = selectedRow.detalleCuenta;
    }
    toast.current.show({
      severity: "warn",
      sticky: true,
      content: (
        <div
          className="p-flex p-flex-column"
          style={{ flex: "1", marginLeft: "1em" }}
        >
          <div className="p-text-center">
            <i
              className="pi pi-exclamation-triangle"
              style={{ fontSize: "3rem" }}
            ></i>
            <h4>{`Esta seguro de cobrar el cheque ID ${selectedRow.id} de $ ${selectedRow.importe} del banco/cuenta ${banco} por ventanilla`}</h4>
            <h4>{`Este valor ingresara a la caja`}</h4>
            <Grid item xs={6} md={12}>
              <TextField
                value={movimientoCheque ? movimientoCheque.detalle : null}
                id="detalle"
                label="Motivo"
                fullWidth
                variant="standard"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  motivo = e.target.value;
                }}
              />
            </Grid>
          </div>
          <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
            <div className="p-col-6">
              <Button
                type="button"
                label="Si"
                className="p-button-success"
                onClick={(e) => {
                  //console.log(motivo);
                  confirmarVentanillaCheque({
                    estadoId: selectedRow.estadoId,
                    usuario: state.usuario.usuario,
                    detalle: motivo,
                  });
                  setTimeout(() => {
                    obtenerMovimientosCheques({
                      empresaTodas,
                      empresaId: empresaElegida.id,
                      tipo: tipo,
                      estado: estado,
                    });
                  }, 1000);
                  opcionNo();
                }}
              />
            </div>
            <div className="p-col-6">
              <Button
                type="button"
                label="No"
                className="p-button-danger"
                onClick={(e) => opcionNo()}
              />
            </div>
          </div>
        </div>
      ),
    });
  };

  const modificaciones = (rowData) => {
    guardarMovimientoCheque({
      ...movimientoCheque,
      fecha: rowData.fecha,
      id_empresa: rowData.id_empresa,
      empresa: rowData.empresa,
      id_concepto: rowData.id_concepto,
      concepto: rowData.concepto,
      detalle: rowData.detalle,
      fechaEmision: rowData.emision,
      fechaDeposito: rowData.deposito,
      numero: rowData.numero,
      id_banco: rowData.id_banco,
      banco: rowData.detalleBanco,
      id_cuenta: rowData.id_cuenta,
      cuenta: rowData.detalleCuenta,
      noALaOrden: rowData.noALaOrden,
      importeTotal: rowData.importe,
      id_rendicion: rowData.id_rendicion,
      estadoId: rowData.estadoId,
      id_moneda: rowData.id_moneda,
      moneda: rowData.moneda,
      cotizacion: rowData.cotizacion,
    });

    let mon = monedas.findIndex((item) => item.id === rowData.id_moneda);

    guardarMonedaMovimiento({
      id_moneda: rowData.id_moneda,
      moneda: monedas[mon].nombre,
    });

    let cotiza = [];
    cotiza.push({
      id: "1",
      nombre: "Compra",
      cotizacion: monedas[mon].compra,
    });
    cotiza.push({
      id: "2",
      nombre: "Venta",
      cotizacion: monedas[mon].venta,
    });
    cotiza.push({
      id: "3",
      nombre: "Tarjeta",
      cotizacion: monedas[mon].tarjeta,
    });
    guardarCotizaciones(cotiza);
    guardarCotizacion(null);

    setTipoMovimiento(rowData.tipo);
    setVisible(true);
  };

  const addClick = () => {
    //props.history.push(`/movimientoCheque/0`);
    setVisible(true);
    setSelectedRow(null);
    let date = new Date();
    let day = `${date.getDate()}`.padStart(2, "0");
    let month = `${date.getMonth() + 1}`.padStart(2, "0");
    let year = date.getFullYear();
    let fecha = `${year}-${month}-${day}`;

    guardarMovimientoCheque({
      fecha,
      usuario: state.usuario.usuario,
      id_empresa: empresaElegida.id,
      id_rendicion: null,
      detalle: null,
    });

    let mon = monedas.findIndex((item) => item.id === monedaPredeterminada);

    guardarMonedaMovimiento({
      id_moneda: monedaPredeterminada,
      moneda: monedas[mon].nombre,
    });

    guardarCotizaciones([]);
    guardarCotizacion(null);
    setTipoMovimiento(null);
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const onHideDepositar = (e) => {
    setVisibleDepositar(false);
  };

  const onHideAcreditar = (e) => {
    setVisibleAcreditar(false);
  };

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !movimientoCheque ||
      movimientoCheque.fecha === undefined ||
      movimientoCheque.fecha === null ||
      movimientoCheque.fecha === ""
    ) {
      mensajeAlerta("Ingreso de Cheques", "La fecha es necesaria !.", "error");
      return;
    }

    if (
      !tipoMovimiento ||
      tipoMovimiento === undefined ||
      tipoMovimiento === null
    ) {
      mensajeAlerta(
        "Ingreso de Cheques",
        "El tipo de cheque es necesario !.",
        "error"
      );
      return;
    }

    if (
      !movimientoCheque ||
      movimientoCheque.id_empresa === undefined ||
      movimientoCheque.id_empresa === null ||
      movimientoCheque.id_empresa === ""
    ) {
      mensajeAlerta(
        "Ingreso de Cheques",
        "La empresa es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !movimientoCheque ||
      movimientoCheque.id_concepto === undefined ||
      movimientoCheque.id_concepto === null ||
      movimientoCheque.id_concepto === ""
    ) {
      mensajeAlerta(
        "Ingreso de Cheques",
        "El concepto es necesario !.",
        "error"
      );
      return;
    }

    if (
      !movimientoCheque ||
      movimientoCheque.fechaEmision === undefined ||
      movimientoCheque.fechaEmision === null ||
      movimientoCheque.fechaEmision === 0
    ) {
      mensajeAlerta(
        "Ingreso de Cheques",
        "La fecha de emisión es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !movimientoCheque ||
      movimientoCheque.fechaDeposito === undefined ||
      movimientoCheque.fechaDeposito === null ||
      movimientoCheque.fechaDeposito === 0
    ) {
      mensajeAlerta(
        "Ingreso de Cheques",
        "La fecha de depósito es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !movimientoCheque ||
      movimientoCheque.numero === undefined ||
      movimientoCheque.numero === null ||
      movimientoCheque.numero === 0
    ) {
      mensajeAlerta("Ingreso de Cheques", "El número es necesario !.", "error");
      return;
    }

    if (tipoMovimiento === "TERCEROS") {
      if (
        !movimientoCheque ||
        movimientoCheque.id_banco === undefined ||
        movimientoCheque.id_banco === null ||
        movimientoCheque.id_banco === 0
      ) {
        mensajeAlerta(
          "Ingreso de Cheques",
          "El banco es necesario !.",
          "error"
        );
        return;
      }
    }

    if (tipoMovimiento === "PROPIOS") {
      if (
        !movimientoCheque ||
        movimientoCheque.id_cuenta === undefined ||
        movimientoCheque.id_cuenta === null ||
        movimientoCheque.id_cuenta === 0
      ) {
        mensajeAlerta(
          "Ingreso de Cheques",
          "La cuenta es necesaria !.",
          "error"
        );
        return;
      }
    }

    if (
      !movimientoCheque ||
      movimientoCheque.importeTotal === undefined ||
      movimientoCheque.importeTotal === null
    ) {
      mensajeAlerta(
        "Ingreso de Cheques",
        "El importe es necesario !.",
        "error"
      );
      return;
    }

    if (movimientoCheque.importeTotal <= 0) {
      mensajeAlerta(
        "Ingreso de Cheques",
        "No puede ingresar un importe menor que cero ó cero !.",
        "error"
      );
      return;
    }

    if (monedaMovimiento && !monedaMovimiento.id_moneda) {
      mensajeAlerta(
        "Ingreso de Cheques",
        "Debe ingresar una moneda !.",
        "error"
      );
      return;
    }

    if (
      monedaMovimiento &&
      monedaMovimiento.id_moneda !== monedaPredeterminada &&
      !cotizacion
    ) {
      mensajeAlerta(
        "Ingreso de Cheques",
        "Debe ingresar una cotización !.",
        "error"
      );
      return;
    }

    // Llamar al context
    if (selectedRow) {
      actualizarMovimientoCheque({
        movimientoCheque,
        tipoMovimiento,
        monedaMovimiento,
        cotizacion,
      });
    } else {
      agregarMovimientoCheque({ movimientoCheque, tipoMovimiento, monedaMovimiento, cotizacion });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerMovimientosCheques({
        empresaTodas,
        empresaId: empresaElegida.id,
        tipo: tipo,
        estado: estado,
      });
    }, 2000);
    //exitClick();
  };

  const aceptarDepositarClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !movimientoCheque ||
      movimientoCheque.id_cuenta === undefined ||
      movimientoCheque.id_cuenta === null ||
      movimientoCheque.id_cuenta === 0
    ) {
      mensajeAlerta("Depositar Cheques", "La cuenta es necesaria !.", "error");
      return;
    }

    if (selectedRow) {
      confirmarDepositarCheque({
        estadoId: selectedRow.estadoId,
        movimientoCheque,
        usuario: state.usuario.usuario,
      });
    } else {
      //agregarMovimientoCheque({ movimientoCheque, tipoMovimiento });
    }
    onHideDepositar();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerMovimientosCheques({
        empresaTodas,
        empresaId: empresaElegida.id,
        tipo: tipo,
        estado: estado,
      });
    }, 2000);
    //exitClick();
  };

  const aceptarAcreditarClick = (e) => {
    e.preventDefault();
    //Validar

    if (selectedRow) {
      confirmarAcreditarCheque({
        estadoId: selectedRow.estadoId,
        movimientoCheque,
        cuentaId: selectedRow.id_cuenta,
        usuario: state.usuario.usuario,
      });
    } else {
      //agregarMovimientoCheque({ movimientoCheque, tipoMovimiento });
    }
    onHideAcreditar();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerMovimientosCheques({
        empresaTodas,
        empresaId: empresaElegida.id,
        tipo: tipo,
        estado: estado,
      });
    }, 2000);
    //exitClick();
  };

  const obtenerMovimientos = () => {
    if (estado === undefined || !estado) {
      mensajeAlerta(
        "Movimientos Cheques",
        "Debe ingresar un estado del cheque !.",
        "error"
      );
    }

    if (tipo === undefined || !tipo) {
      mensajeAlerta(
        "Movimientos Cheques",
        "Debe ingresar un tipo de cheque !.",
        "error"
      );
    }

    obtenerMovimientosCheques({
      empresaTodas,
      empresaId: empresaElegida.id,
      tipo: tipo,
      estado: estado,
    });
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Movimientos Cheques",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  const fechaIngresoTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fecha)}</Fragment>;
  };

  const fechaOperacionTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fecha)}</Fragment>;
  };

  const fechaEmisionTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.emision)}</Fragment>;
  };

  const fechaDepositoTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.deposito)}</Fragment>;
  };

  const importeTemplate = (rowData) => {
    return <Fragment>{parseFloat(rowData.importe).toFixed(2)}</Fragment>;
  };

  const eCheqTemplate = (rowData) => {
    return (
      <Fragment>
        {" "}
        <Checkbox checked={rowData.eCheq}></Checkbox>
      </Fragment>
    );
  };

  const NOTemplate = (rowData) => {
    return (
      <Fragment>
        {" "}
        <Checkbox checked={rowData.noALaOrden}></Checkbox>
      </Fragment>
    );
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <Button
          style={{
            margin: 0,
            padding: 0,
          }}
          icon="pi pi-search"
          className="p-button-success p-button-text"
          /*
          onClick={() => {
            consultaCheque(rowData);
            setVisibleDatos(true);
          }}
          */
          tooltip="Datos del cheque"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: "tooltip",
          }}
        />
        <Button
          style={{
            margin: 0,
            padding: 0,
          }}
          icon="pi pi-pencil"
          className="p-button-text"
          onClick={(e) => {
            setSelectedRow(rowData);
            modificaciones(rowData);
          }}
          tooltip="Modificaciones"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: "tooltip",
          }}
          disabled={rowData.estado !== "CARTERA"}
        />
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, movimientosCheques);
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(movimientosCheques);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "movimientosCheques");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Movimientos de Cheques"></Tag>
      </div>
    </Fragment>
  );

  const renderHeaderDatos = () => {
    return (
      <div>
        <Tag value="Datos del Cheque"></Tag>
      </div>
    );
  };

  const renderHeaderDepositar = () => {
    return (
      <div>
        <Tag value="Depósito del Cheque"></Tag>
      </div>
    );
  };

  const renderHeaderAcreditar = () => {
    return (
      <div>
        <Tag value="Acreditación del Cheque"></Tag>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Ingreso del Cheque"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderFooterDepositar = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarDepositarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideDepositar}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderFooterAcreditar = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarAcreditarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideAcreditar}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  function handleEnter(event) {
    if (event.keyCode === 13) {
      let element = event.target.id;
      let campo = null;
      switch (element) {
        case "fechaDesde":
          campo = document.getElementById("fechaHasta");
          campo.focus();
          break;
        case "fecha":
          campo = document.getElementById("empresaMovimiento");
          campo.focus();
          break;
        case "empresaMovimiento":
          campo = document.getElementById("concepto");
          campo.focus();
          break;
        case "concepto":
          campo = document.getElementById("detalle");
          campo.focus();
          break;
        case "detalle":
          campo = document.getElementById("importe");
          campo.focus();
          break;
      }
    }
  }

  //console.log(movimientosCheques);
  //console.log(state);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      <ConfirmDialog />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {movimientosCheques ? (
            <Fragment>
              <Grid
                container
                spacing={3}
                style={{ marginTop: "1vw", marginLeft: "1vw" }}
              >
                <Button
                  icon="pi pi-arrow-down"
                  tooltip="Ingreso de Cheques"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={(e) => addClick()}
                />
                <Button
                  icon="pi pi-trash"
                  tooltip="Anular Cheque"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  disabled={estado !== "CARTERA"}
                  onClick={(e) => anularClick(e)}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Rechazar Cheque"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  disabled={estado !== "DEPOSITADO" && estado !== "ENTREGADO"}
                  onClick={(e) => rechazarClick(e)}
                />
                <Button
                  icon="pi pi-building"
                  tooltip="Depositar Cheque"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  disabled={estado !== "CARTERA"}
                  onClick={(e) => depositarClick(e)}
                />
                <Button
                  icon="pi pi-stop"
                  tooltip="Ventanilla"
                  className="p-button-rounded p-button-warning"
                  style={{ marginLeft: "1em" }}
                  disabled={estado !== "CARTERA"}
                  onClick={(e) => ventanillaClick(e)}
                />
                <Button
                  icon="pi pi-plus"
                  tooltip="Acreditar Cheque"
                  className="p-button-rounded p-button-secondary"
                  style={{ marginLeft: "1em" }}
                  disabled={estado !== "DEPOSITADO"}
                  onClick={(e) => acreditarClick(e)}
                />
                <Button
                  icon="pi pi-file-pdf"
                  tooltip="Imprimir"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={imprimirClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <Autocomplete
                  id="estado"
                  required
                  noOptionsText={"No hay opciones"}
                  options={estadosCheques}
                  defaultValue={estadosCheques[0]}
                  getOptionLabel={(option) => option.nombre}
                  sx={{ width: 250, marginLeft: "1vw" }}
                  openOnFocus={true}
                  disablePortal
                  //disabled={afiliado.titular ? false : true}
                  renderInput={(params) => (
                    <TextField {...params} label="Estado" variant="standard" />
                  )}
                  //onKeyDown={handleEnter}
                  onChange={(event, value) => {
                    if (value) {
                      guardarEstado(value.nombre);
                      obtenerMovimientosCheques({
                        empresaTodas,
                        empresaId: empresaElegida.id,
                        tipo: tipo,
                        estado: value.nombre,
                      });
                    } else {
                      guardarEstado(null);
                    }
                  }}
                />

                <Autocomplete
                  id="tipo"
                  required
                  noOptionsText={"No hay opciones"}
                  options={tiposCheques}
                  defaultValue={tiposCheques[2]}
                  getOptionLabel={(option) => option.nombre}
                  sx={{ width: 250, marginLeft: "1vw" }}
                  openOnFocus={true}
                  disablePortal
                  //disabled={afiliado.titular ? false : true}
                  renderInput={(params) => (
                    <TextField {...params} label="Tipos" variant="standard" />
                  )}
                  //onKeyDown={handleEnter}
                  onChange={(event, value) => {
                    if (value) {
                      guardarTipo(value.nombre);
                      obtenerMovimientosCheques({
                        empresaTodas,
                        empresaId: empresaElegida.id,
                        tipo: value.nombre,
                        estado: estado,
                      });
                    } else {
                      guardarTipo(null);
                    }
                  }}
                />

                <FormControlLabel
                  value="yes"
                  control={
                    <Checkbox
                      color="primary"
                      checked={empresaTodas}
                      style={{ marginLeft: "1vw" }}
                      onChange={(e) => {
                        setEmpresaTodas(e.target.checked);
                        obtenerMovimientosCheques({
                          empresaTodas: e.target.checked,
                          empresaId: empresaElegida.id,
                          tipo: tipo,
                          estado: estado,
                        });
                      }}
                    />
                  }
                  label="Todas las empresas"
                  labelPlacement="start"
                  style={{ paddingLeft: "1vw", paddingTop: "1vw" }}
                />
                <Button
                  icon="pi pi-check"
                  tooltip="Confirmar"
                  className="p-button-rounded p-button-success"
                  style={{ marginLeft: "3em", display: "none" }}
                  onClick={(e) => obtenerMovimientos()}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ align: "center", margin: "1em" }}
              >
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={movimientosCheques}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      size="small"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={100}
                      rowsPerPageOptions={[10, 20, 50, 100, 500]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        //body={idTemplate}
                        style={{ fontSize: "xx-small", display: "none" }}
                        sortable
                      ></Column>
                      <Column
                        field="fecha"
                        header="Fecha Operación"
                        body={fechaOperacionTemplate}
                        style={{ fontSize: "xx-small" }}
                        sortable
                      ></Column>
                      <Column
                        field="emision"
                        header="Fecha Emisión"
                        body={fechaEmisionTemplate}
                        style={{ fontSize: "xx-small" }}
                        sortable
                      ></Column>
                      <Column
                        field="deposito"
                        header="Fecha Depósito"
                        body={fechaDepositoTemplate}
                        style={{ fontSize: "xx-small" }}
                        sortable
                      ></Column>
                      <Column
                        field="detalleBanco"
                        header="Banco"
                        //body={habilitadoTemplate}
                        style={{ fontSize: "xx-small" }}
                        sortable
                      ></Column>
                      <Column
                        field="numero"
                        header="Número"
                        //body={habilitadoTemplate}
                        style={{ fontSize: "xx-small" }}
                        sortable
                      ></Column>
                      <Column
                        field="empresa"
                        header="Empresa"
                        //body={habilitadoTemplate}
                        style={{ fontSize: "xx-small" }}
                        sortable
                      ></Column>
                      <Column
                        field="importe"
                        header="Importe"
                        body={importeTemplate}
                        style={{ fontSize: "xx-small" }}
                        sortable
                      ></Column>
                      <Column
                        field="moneda"
                        header="Moneda"
                        //body={monedaTemplate}
                        style={{ fontSize: "xx-small" }}
                        sortable
                      ></Column>
                      <Column
                        field="cotizacion"
                        header="Cotización"
                        //body={monedaTemplate}
                        style={{ fontSize: "xx-small" }}
                        sortable
                      ></Column>
                      <Column
                        field="eCheq"
                        header="eCheq"
                        body={eCheqTemplate}
                        style={{ fontSize: "xx-small" }}
                        sortable
                      ></Column>
                      <Column
                        field="noALaOrden"
                        header="N.O."
                        body={NOTemplate}
                        style={{ fontSize: "xx-small" }}
                        sortable
                      ></Column>
                      <Column
                        field="diferenciaDias"
                        header="Días para Cobrar"
                        //body={habilitadoTemplate}
                        style={{ fontSize: "xx-small" }}
                        sortable
                      ></Column>
                      <Column
                        field="id_rendicion"
                        header="Rendición"
                        //body={habilitadoTemplate}
                        style={{ fontSize: "xx-small" }}
                        sortable
                      ></Column>
                      <Column
                        field="id_orden"
                        header="Orden"
                        //body={habilitadoTemplate}
                        style={{ fontSize: "xx-small" }}
                        sortable
                      ></Column>
                      <Column
                        field="detalle"
                        header="Detalle"
                        //body={habilitadoTemplate}
                        style={{ fontSize: "xx-small" }}
                        sortable
                      ></Column>
                      <Column
                        field="tipo"
                        header="Tipo"
                        //body={habilitadoTemplate}
                        style={{ fontSize: "xx-small" }}
                        sortable
                      ></Column>
                      <Column
                        className="colBotones"
                        body={actionBodyTemplate}
                        style={{ width: "8%" }}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid
                  container
                  xs={12}
                  md={12}
                  style={{
                    borderStyle: "solid",
                    borderWidth: "0.5px",
                    padding: "1vw",
                    marginTop: "0.5vw",
                  }}
                >
                  <Grid item xs={12} md={2.5}>
                    <TextField
                      id="fecha"
                      value={movimientoCheque ? movimientoCheque.fecha : null}
                      label="Fecha Ingreso"
                      type="date"
                      fullWidth
                      variant="standard"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={4} style={{ marginLeft: "1vw" }}>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Tipo
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={tipoMovimiento}
                        onChange={(e) => {
                          setTipoMovimiento(e.target.value);
                          guardarMovimientoCheque({
                            ...movimientoCheque,
                            id_banco: null,
                            banco: null,
                            id_cuenta: null,
                            cuenta: null,
                          });
                        }}
                      >
                        <FormControlLabel
                          value="PROPIOS"
                          control={<Radio />}
                          label="PROPIOS"
                          disabled={
                            movimientoCheque &&
                            movimientoCheque.id_rendicion !== null
                          }
                        />
                        <FormControlLabel
                          value="TERCEROS"
                          control={<Radio />}
                          label="TERCEROS"
                          disabled={
                            movimientoCheque &&
                            movimientoCheque.id_rendicion !== null
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={5}>
                    <Autocomplete
                      id="empresaMovimiento"
                      fullWidth
                      required
                      noOptionsText={"No hay opciones"}
                      options={
                        empresas
                          ? empresas.filter((item) => item.habilitado)
                          : null
                      }
                      defaultValue={
                        movimientoCheque && movimientoCheque.id_empresa !== null
                          ? empresas[
                              empresas.findIndex(
                                (item) =>
                                  item.id === movimientoCheque.id_empresa
                              )
                            ]
                          : empresas[
                              empresas.findIndex(
                                (item) => item.id === empresaElegida.id
                              )
                            ]
                      }
                      getOptionLabel={(option) => option.nombre}
                      openOnFocus={true}
                      disablePortal
                      //disabled={afiliado.titular ? false : true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Empresa *"
                          variant="standard"
                        />
                      )}
                      onChange={(event, value) => {
                        if (value) {
                          guardarMovimientoCheque({
                            ...movimientoCheque,
                            id_empresa: value.id,
                            empresa: value.nombre,
                          });
                        } else {
                          guardarMovimientoCheque({
                            ...movimientoCheque,
                            id_empresa: null,
                            empresa: null,
                          });
                        }
                      }}
                      disabled={
                        movimientoCheque &&
                        movimientoCheque.id_rendicion !== null
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      id="concepto"
                      fullWidth
                      required
                      noOptionsText={"No hay opciones"}
                      options={
                        conceptos
                          ? conceptos.filter((item) => item.habilitado)
                          : null
                      }
                      defaultValue={
                        movimientoCheque &&
                        movimientoCheque.id_concepto !== null
                          ? conceptos[
                              conceptos.findIndex(
                                (item) =>
                                  item.id === movimientoCheque.id_concepto
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) =>
                        option.nombre + " - " + option.id
                      }
                      openOnFocus={true}
                      disablePortal
                      //disabled={afiliado.titular ? false : true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Concepto *"
                          variant="standard"
                        />
                      )}
                      onChange={(event, value) => {
                        if (value) {
                          guardarMovimientoCheque({
                            ...movimientoCheque,
                            id_concepto: value.id,
                            concepto: value.nombre,
                          });
                        } else {
                          guardarMovimientoCheque({
                            ...movimientoCheque,
                            id_concepto: null,
                            concepto: null,
                          });
                        }
                      }}
                      disabled={
                        movimientoCheque &&
                        movimientoCheque.id_rendicion !== null
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12} style={{ marginTop: "1vw" }}>
                    <TextField
                      value={movimientoCheque ? movimientoCheque.detalle : null}
                      id="detalle"
                      label="Detalle"
                      fullWidth
                      variant="standard"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        guardarMovimientoCheque({
                          ...movimientoCheque,
                          detalle: e.target.value,
                        })
                      }
                      disabled={
                        movimientoCheque &&
                        movimientoCheque.id_rendicion !== null
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12} />

                  {tipoMovimiento !== null &&
                  movimientoCheque &&
                  movimientoCheque.id_empresa !== undefined &&
                  movimientoCheque.id_empresa !== null &&
                  movimientoCheque.id_concepto !== undefined &&
                  movimientoCheque.id_concepto !== null ? (
                    <Fragment>
                      <br />
                      <br />
                      <Grid item xs={12} md={3}>
                        <TextField
                          id="fechaEmision"
                          value={
                            movimientoCheque
                              ? movimientoCheque.fechaEmision
                              : null
                          }
                          label="Fecha Emisión"
                          type="date"
                          fullWidth
                          variant="standard"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) =>
                            guardarMovimientoCheque({
                              ...movimientoCheque,
                              fechaEmision: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          id="fechaDeposito"
                          value={
                            movimientoCheque
                              ? movimientoCheque.fechaDeposito
                              : null
                          }
                          label="Fecha Depósito"
                          type="date"
                          fullWidth
                          variant="standard"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) =>
                            guardarMovimientoCheque({
                              ...movimientoCheque,
                              fechaDeposito: e.target.value,
                            })
                          }
                          style={{ marginLeft: "1vw" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <label style={{ marginLeft: "2vw" }} htmlFor="numero">
                          Número
                        </label>
                        <InputNumber
                          inputId="numero"
                          value={
                            movimientoCheque ? movimientoCheque.numero : null
                          }
                          onChange={(e) => {
                            if (e.value) {
                              guardarMovimientoCheque({
                                ...movimientoCheque,
                                numero: e.value,
                              });
                            } else {
                              guardarMovimientoCheque({
                                ...movimientoCheque,
                                numero: null,
                              });
                            }
                          }}
                          size={12}
                          //mode="decimal"
                          locale="en-US"
                          minFractionDigits={0}
                          style={{
                            marginLeft: "0.7vw",
                            marginTop: "0.8vw",
                          }}
                        />
                      </Grid>

                      {tipoMovimiento === "TERCEROS" ? (
                        <Grid item xs={12} md={12}>
                          <Autocomplete
                            id="bancos"
                            //disabled
                            fullWidth
                            options={
                              bancos
                                ? bancos.filter(
                                    (banco) => banco.habilitado && !banco.propio
                                  )
                                : null
                            }
                            value={
                              movimientoCheque &&
                              movimientoCheque.id_banco !== null
                                ? bancos[
                                    bancos.findIndex(
                                      (item) =>
                                        item.id === movimientoCheque.id_banco
                                    )
                                  ]
                                : null
                            }
                            disablePortal
                            getOptionLabel={(option) => option.nombre}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Banco *"
                                size="small"
                              />
                            )}
                            onChange={(event, value) => {
                              if (value) {
                                guardarMovimientoCheque({
                                  ...movimientoCheque,
                                  id_banco: value.id,
                                  banco: value.nombre,
                                });
                              } else {
                                guardarMovimientoCheque({
                                  ...movimientoCheque,
                                  id_banco: null,
                                  banco: null,
                                });
                              }
                            }}
                          />
                        </Grid>
                      ) : tipoMovimiento === "PROPIOS" ? (
                        <Grid item xs={12} md={12}>
                          <Autocomplete
                            id="cuentas"
                            //disabled
                            fullWidth
                            options={
                              cuentas
                                ? cuentas.filter(
                                    (cuenta) =>
                                      cuenta.habilitado &&
                                      cuenta.id_empresa ===
                                        movimientoCheque.id_empresa
                                  )
                                : null
                            }
                            value={
                              movimientoCheque &&
                              movimientoCheque.id_cuenta !== null
                                ? cuentas[
                                    cuentas.findIndex(
                                      (item) =>
                                        item.id === movimientoCheque.id_cuenta
                                    )
                                  ]
                                : null
                            }
                            disablePortal
                            getOptionLabel={(option) => option.nombre}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Cuenta *"
                                size="small"
                              />
                            )}
                            onChange={(event, value) => {
                              if (value) {
                                guardarMovimientoCheque({
                                  ...movimientoCheque,
                                  id_cuenta: value.id,
                                  cuenta: value.nombre,
                                });
                              } else {
                                guardarMovimientoCheque({
                                  ...movimientoCheque,
                                  id_cuenta: null,
                                  cuenta: null,
                                });
                              }
                            }}
                          />
                        </Grid>
                      ) : null}
                      <Grid
                        item
                        xs={12}
                        md={4}
                        style={{ paddingLeft: 0, paddingTop: "1vw" }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={
                            <Checkbox
                              color="primary"
                              checked={
                                movimientoCheque
                                  ? movimientoCheque.noALaOrden
                                  : null
                              }
                              style={{ marginLeft: "1vw" }}
                              onChange={(e) =>
                                guardarMovimientoCheque({
                                  ...movimientoCheque,
                                  noALaOrden: e.target.checked,
                                })
                              }
                            />
                          }
                          label="No a la Orden"
                          labelPlacement="start"
                          style={{ paddingLeft: "0vw" }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        style={{ paddingLeft: 0, paddingTop: "1vw" }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={
                            <Checkbox
                              color="primary"
                              checked={
                                movimientoCheque ? movimientoCheque.eCheq : null
                              }
                              style={{ marginLeft: "1vw" }}
                              onChange={(e) =>
                                guardarMovimientoCheque({
                                  ...movimientoCheque,
                                  eCheq: e.target.checked,
                                })
                              }
                            />
                          }
                          label="Echeq"
                          labelPlacement="start"
                          style={{ paddingLeft: "0vw" }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={4}
                        style={{ paddingLeft: 0, paddingTop: "1vw" }}
                      />

                      <Grid item xs={12} md={3}>
                        <label
                          style={{
                            marginLeft: "0.0vw",
                            fontSize: "small",
                          }}
                          htmlFor="importeTotal"
                        >
                          Importe Total:
                        </label>
                        <InputNumber
                          inputId="importeTotal"
                          value={
                            movimientoCheque
                              ? movimientoCheque.importeTotal
                              : null
                          }
                          onChange={(e) => {
                            if (e.value) {
                              guardarMovimientoCheque({
                                ...movimientoCheque,
                                importeTotal: e.value,
                              });
                            } else {
                              guardarMovimientoCheque({
                                ...movimientoCheque,
                                importeTotal: 0,
                              });
                            }
                          }}
                          size={10}
                          mode="decimal"
                          locale="en-US"
                          minFractionDigits={2}
                          style={{
                            marginLeft: "0.7vw",
                            marginTop: "0.8vw",
                          }}
                          disabled={
                            movimientoCheque &&
                            movimientoCheque.id_rendicion !== null
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Autocomplete
                          id="moneda"
                          fullWidth
                          required
                          noOptionsText={"No hay opciones"}
                          options={monedas ? monedas : null}
                          value={
                            monedas &&
                            monedaMovimiento &&
                            monedaMovimiento.id_moneda !== null
                              ? monedas[
                                  monedas.findIndex(
                                    (item) =>
                                      item.id === monedaMovimiento.id_moneda
                                  )
                                ]
                              : monedas[
                                  monedas.findIndex(
                                    (item) => item.id === monedaPredeterminada
                                  )
                                ]
                          }
                          getOptionLabel={(option) =>
                            option.nombre + " (" + option.simbolo + ")"
                          }
                          openOnFocus={true}
                          disablePortal
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Moneda *"
                              variant="standard"
                            />
                          )}
                          //onKeyDown={handleEnter}
                          onChange={(event, value) => {
                            if (value) {
                              guardarMonedaMovimiento({
                                ...monedaMovimiento,
                                id_moneda: value.id,
                                moneda: value.nombre,
                              });
                              let mon = monedas.findIndex(
                                (item) => item.id === value.id
                              );

                              let cotiza = [];
                              cotiza.push({
                                id: "1",
                                nombre: "Compra",
                                cotizacion: monedas[mon].compra,
                              });
                              cotiza.push({
                                id: "2",
                                nombre: "Venta",
                                cotizacion: monedas[mon].venta,
                              });
                              cotiza.push({
                                id: "3",
                                nombre: "Tarjeta",
                                cotizacion: monedas[mon].tarjeta,
                              });
                              guardarCotizaciones(cotiza);
                            } else {
                              guardarMonedaMovimiento({
                                ...monedaMovimiento,
                                id_moneda: null,
                                moneda: null,
                              });
                              guardarCotizaciones([]);
                            }
                          }}
                          disabled={
                            movimientoCheque &&
                            movimientoCheque.id_rendicion !== null
                          }
                        />
                      </Grid>

                      {monedaPredeterminada &&
                      monedaMovimiento &&
                      monedaMovimiento.id_moneda &&
                      monedaMovimiento.id_moneda !== monedaPredeterminada ? (
                        <Grid item xs={12} md={5}>
                          <Autocomplete
                            id="cotizacion"
                            size={"small"}
                            fullWidth
                            noOptionsText={"No hay opciones"}
                            options={cotizaciones ? cotizaciones : null}
                            value={
                              cotizacion && cotizacion.cotizacionTipo !== null
                                ? cotizaciones[
                                    cotizaciones.findIndex(
                                      (item) =>
                                        item.nombre ===
                                        cotizacion.cotizacionTipo
                                    )
                                  ]
                                : null
                            }
                            getOptionLabel={(option) =>
                              "$ " +
                              option.cotizacion +
                              " (" +
                              option.nombre +
                              ")"
                            }
                            openOnFocus={true}
                            disablePortal
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Cotización *"
                                variant="standard"
                              />
                            )}
                            //onKeyDown={handleEnter}
                            onChange={(event, value) => {
                              if (value) {
                                guardarCotizacion({
                                  ...cotizacion,
                                  cotizacion: value.cotizacion,
                                  cotizacionTipo: value.nombre,
                                });
                              } else {
                                guardarCotizacion(null);
                              }
                            }}
                          />
                        </Grid>
                      ) : null}
                    </Fragment>
                  ) : null}
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeaderDatos()}
                visible={visibleDatos}
                closable={true}
                //footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      value={movimientoCheque ? movimientoCheque.fecha : null}
                      id="fecha"
                      label="Fecha *"
                      type="date"
                      required
                      variant="standard"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ marginLeft: "1em" }}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarMovimientoCheque({
                          ...movimientoCheque,
                          fecha: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="empresaMovimiento"
                      fullWidth
                      required
                      noOptionsText={"No hay opciones"}
                      options={
                        empresas
                          ? empresas.filter((item) => item.habilitado)
                          : null
                      }
                      defaultValue={
                        movimientoCheque && movimientoCheque.id_empresa !== null
                          ? empresas[
                              empresas.findIndex(
                                (item) =>
                                  item.id === movimientoCheque.id_empresa
                              )
                            ]
                          : empresas[
                              empresas.findIndex(
                                (item) => item.id === empresaElegida.id
                              )
                            ]
                      }
                      getOptionLabel={(option) => option.nombre}
                      openOnFocus={true}
                      disablePortal
                      //disabled={afiliado.titular ? false : true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Empresa *"
                          variant="standard"
                        />
                      )}
                      onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarMovimientoCheque({
                            ...movimientoCheque,
                            id_empresa: value.id,
                            empresa: value.nombre,
                          });
                        } else {
                          guardarMovimientoCheque({
                            ...movimientoCheque,
                            id_empresa: null,
                            empresa: null,
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      id="concepto"
                      fullWidth
                      required
                      noOptionsText={"No hay opciones"}
                      options={
                        conceptos
                          ? conceptos.filter((item) => item.habilitado)
                          : null
                      }
                      getOptionLabel={(option) =>
                        option.nombre + " - " + option.id
                      }
                      openOnFocus={true}
                      disablePortal
                      //disabled={afiliado.titular ? false : true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Concepto *"
                          variant="standard"
                        />
                      )}
                      onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarMovimientoCheque({
                            ...movimientoCheque,
                            id_concepto: value.id,
                            concepto: value.nombre,
                          });
                        } else {
                          guardarMovimientoCheque({
                            ...movimientoCheque,
                            id_concepto: null,
                            concepto: null,
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      value={movimientoCheque ? movimientoCheque.detalle : null}
                      id="detalle"
                      label="Detalle"
                      fullWidth
                      variant="standard"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarMovimientoCheque({
                          ...movimientoCheque,
                          detalle: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <label htmlFor="cuota">Importe *</label>
                    <InputNumber
                      id="importe"
                      inputId="importe"
                      required
                      value={movimientoCheque ? movimientoCheque.importe : null}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarMovimientoCheque({
                          ...movimientoCheque,
                          importe: e.value,
                        })
                      }
                      mode="decimal"
                      locale="en-US"
                      minFractionDigits={2}
                      style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                    />
                  </Grid>
                </Grid>
              </Dialog>
              {cuentas && selectedRow ? (
                <Dialog
                  header={renderHeaderDepositar()}
                  visible={visibleDepositar}
                  closable={false}
                  footer={renderFooterDepositar()}
                  breakpoints={{ "960px": "75vw" }}
                  style={{ width: "50vw", paddingBottom: "0" }}
                  position="center"
                >
                  <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                    <Grid item xs={12} md={12}>
                      <Autocomplete
                        id="cuentas"
                        //disabled
                        fullWidth
                        options={
                          cuentas
                            ? cuentas.filter(
                                (cuenta) =>
                                  cuenta.habilitado &&
                                  cuenta.id_empresa === selectedRow.id_empresa
                              )
                            : null
                        }
                        disablePortal
                        getOptionLabel={(option) => option.nombre}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Cuenta *"
                            size="small"
                          />
                        )}
                        onChange={(event, value) => {
                          if (value) {
                            guardarMovimientoCheque({
                              ...movimientoCheque,
                              id_cuenta: value.id,
                              cuenta: value.nombre,
                            });
                          } else {
                            guardarMovimientoCheque({
                              ...movimientoCheque,
                              id_cuenta: null,
                              cuenta: null,
                            });
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <TextField
                        value={
                          movimientoCheque ? movimientoCheque.detalle : null
                        }
                        id="detalle"
                        label="Detalle"
                        fullWidth
                        variant="standard"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          guardarMovimientoCheque({
                            ...movimientoCheque,
                            detalle: e.target.value,
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </Dialog>
              ) : null}
              {cuentas && selectedRow ? (
                <Dialog
                  header={renderHeaderAcreditar()}
                  visible={visibleAcreditar}
                  closable={false}
                  footer={renderFooterAcreditar()}
                  breakpoints={{ "960px": "75vw" }}
                  style={{ width: "50vw", paddingBottom: "0" }}
                  position="center"
                >
                  <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                    <Grid item xs={12} md={12}>
                      <TextField
                        value={
                          movimientoCheque ? movimientoCheque.detalle : null
                        }
                        id="detalle"
                        label="Detalle"
                        fullWidth
                        variant="standard"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          guardarMovimientoCheque({
                            ...movimientoCheque,
                            detalle: e.target.value,
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </Dialog>
              ) : null}
            </Fragment>
          ) : (
            <Spinner />
          )}

          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../context/AlertContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./../Menu";
import { InputNumber } from "primereact/inputnumber";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { InputMask } from "primereact/inputmask";
import IconButton from "@mui/material/IconButton";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { MultiSelect } from "primereact/multiselect";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const exportColumnsListado = [
  { title: "Fecha", dataKey: "Fecha" },
  { title: "Comprobante", dataKey: "Comprobante" },
  { title: "Importe", dataKey: "Total" },
  { title: "Afiliado", dataKey: "NumeroTitular" },
  { title: "Período", dataKey: "Periodo" },
  { title: "Cobrador", dataKey: "Cobrador" },
  { title: "Asesor", dataKey: "Asesor" },
  { title: "Plan", dataKey: "Plan" },
  { title: "Grupo", dataKey: "Grupo" },
  { title: "Empresa", dataKey: "Empresa" },
];

const exportColumnsAgrupado = [
  { title: "Descripción", dataKey: "descripcion" },
  { title: "Cantidad", dataKey: "cantidad" },
  { title: "Importe", dataKey: "total" },
];

const ordenar = [
  {
    id: 1,
    nombre: "Fecha",
  },
  {
    id: 2,
    nombre: "Plan",
  },
  {
    id: 3,
    nombre: "Grupo",
  },
  {
    id: 4,
    nombre: "Cobrador",
  },
  {
    id: 5,
    nombre: "Asesor",
  },
  {
    id: 6,
    nombre: "Empresa",
  },
  {
    id: 7,
    nombre: "TipoComprobante",
  },
  {
    id: 8,
    nombre: "Afiliado",
  },
];

const agrupar = [
  {
    id: 1,
    nombre: "Fecha",
  },
  {
    id: 2,
    nombre: "Plan",
  },
  {
    id: 3,
    nombre: "Grupo",
  },
  {
    id: 4,
    nombre: "Cobrador",
  },
  {
    id: 5,
    nombre: "Asesor",
  },
  {
    id: 6,
    nombre: "Empresa",
  },
  {
    id: 7,
    nombre: "Comprobante",
  },
  {
    id: 8,
    nombre: "Afiliado",
  },
];

const tipos = [
  {
    id: 1,
    nombre: "Facturas",
  },
  {
    id: 2,
    nombre: "Notas de Débito",
  },
  {
    id: 3,
    nombre: "Notas de Crédito",
  },
  {
    id: 4,
    nombre: "Ajustes Débito",
  },
  {
    id: 5,
    nombre: "Ajustes Crédito",
  },
];

export default function ListadosComprobantesVentas(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [visibleFechas, setVisibleFechas] = useState(true);
  const [grupo, setGrupo] = useState(null);
  const [orden, setOrden] = useState(null);
  const [incluyeIntegrantes, setIncluyeIntegrantes] = useState(false);
  const [todasLasEmpresas, setTodasLasEmpresas] = useState(false);
  const [fechaDesde, guardarFechaDesde] = useState(null);
  const [fechaHasta, guardarFechaHasta] = useState(null);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedTipos, setSelectedTipos] = useState([]);
  const [bandInicio, setBandInicio] = useState(false);
  const [cobranza, setCobranza] = useState("todos");
  const [extendido, setExtendido] = useState(false);
  const [tipoListado, setTipoListado] = useState("Orden");
  const [subtotal, setSubtotal] = useState(false);
  const [salto, setSalto] = useState(false);

  const toast = useRef(null);

  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    empresaElegida,
    empresas,
    listadoExcel,
    obtenerAcceso,
    obtenerBajas,
    obtenerEmpresas,
    obtenerComprobantesVentas,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "ListadosComprobantesVentas",
      });
    }
  }, [state.id]);

  useEffect(() => {
    obtenerEmpresas();
  }, []);

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  const startClick = (e) => {
    setTimeout(() => {
      navigate(`/listadosbajas`);
      setVisibleFechas(true);
    }, 2000);
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHideFechas = (e) => {
    setVisibleFechas(false);
  };

  const aceptarFechas = async (exportType) => {
    //e.preventDefault();
    //Validar

    if (fechaDesde === null) {
      mensajeAlerta(
        "Listados de Comprobantes",
        "Debe indicar fecha desde !.",
        "error"
      );
      return;
    }

    if (fechaHasta === null) {
      mensajeAlerta(
        "Listados de Comprobantes",
        "Debe indicar fecha hasta !.",
        "error"
      );
      return;
    }

    if (fechaDesde > fechaHasta) {
      mensajeAlerta(
        "Listados de Comprobantes",
        "Fecha desde no puede ser mayor que fecha hasta !.",
        "error"
      );
      return;
    }

    if (tipoListado === "Orden" && !orden) {
      mensajeAlerta(
        "Listados de Comprobantes",
        "Debe seleccionar on órden. !.",
        "error"
      );
      return;
    }

    if (tipoListado === "Grupo" && !grupo) {
      mensajeAlerta(
        "Listados de Comprobantes",
        "Debe seleccionar on grupo. !.",
        "error"
      );
      return;
    }

    if (selectedCities && selectedCities.length === 0) {
      mensajeAlerta(
        "Listados de Comprobantes",
        "Debe indicar al menos una empresa !.",
        "error"
      );
      return;
    }

    if (selectedTipos && selectedTipos.length === 0) {
      mensajeAlerta(
        "Listados de Comprobantes",
        "Debe indicar al menos un tipo de comprobante !.",
        "error"
      );
      return;
    }

    var elem;

    let fdesde = fechaDesde + "T00:00:00";
    let fhasta = fechaHasta + "T23:59:59";

    toast.current.show({
      severity: "info",
      summary: "Listados",
      detail: "Obteniendo información... !",
      life: 3000,
    });

    await obtenerComprobantesVentas({
      desde: fdesde,
      hasta: fhasta,
      selectedCities,
      selectedTipos,
      grupo,
      orden,
      subtotal,
      salto,
      tipoListado,
      retencion: false,
      exportType,
    }).then((data) => {
      if (exportType === "excel") {
        elem = document.getElementById("expButtonExcel");
        elem.click();
      }
      if (exportType === "pdf") {
        elem = document.getElementById("expButtonPdf");
        elem.click();
      }
    });
    /*
    onHideFechas();
    setGrupo(null);
    guardarVigencia({
      desde: null,
      hasta: null,
    });
    setIncluyeIntegrantes(false);
    setTodasLasEmpresas(false);
    */
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Listados de Comprobantes",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const renderHeaderFechas = () => {
    return (
      <div>
        <Tag value="Listado de Comprobantes de Venta"></Tag>
      </div>
    );
  };

  const renderFooterFechas = () => {
    return (
      <div>
        <button
          id="expButtonExcel"
          type="button"
          onClick={(e) => {
            exportExcel();
          }}
          style={{ display: "none" }}
        >
          Export Excel
        </button>
        <button
          id="expButtonPdf"
          type="button"
          onClick={(e) => {
            exportPdf();
          }}
          style={{ display: "none" }}
        >
          Export Pdf
        </button>

        <Button
          icon="pi pi-file-excel"
          onClick={(e) => {
            aceptarFechas("excel");
          }}
          className="p-button-raised p-button-success"
          data-pr-tooltip="XLS"
          label="Excel"
        />
        <Button
          icon="pi pi-file-pdf"
          onClick={(e) => {
            aceptarFechas("pdf");
            //exportPdf();
          }}
          className="p-button-raised p-button-warning"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
          label="PDF"
        />
        <ButtonMui
          onClick={onHideFechas}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const fechaTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fecha)}</Fragment>;
  };

  const anuladoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.anulado}></Checkbox>
      </Fragment>
    );
  };

  const exportExcel = () => {
    for (let i = 0; i < listadoExcel.length; i++) {
      listadoExcel[i].Total = parseFloat(listadoExcel[i].Total, 10);
    }
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(listadoExcel);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "comprobantesVentas");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        if (orden || tipoListado === "Orden") {
          const doc = new jsPDF.default(0, 0);
          doc.setFontSize(10);
          doc.text(
            `Listado de comprobantes ordenado por ${
              orden.nombre
            } Desde: ${formatDate(fechaDesde)} Hasta: ${formatDate(
              fechaHasta
            )}`,
            30,
            8
          );

          let empresas = "";
          for (let i = 0; i < selectedCities.length; i++) {
            if (i === 0) {
              empresas = selectedCities[i].nombre;
            } else {
              empresas = empresas + " - " + selectedCities[i].nombre;
            }
          }

          doc.setFontSize(8);
          doc.text(`Empresas: ${empresas}`, 30, 13);

          let row = 20;

          doc.autoTable(exportColumnsListado, listadoExcel, {
            margin: { left: 10 },
            startY: row,
            styles: {
              fontSize: 6,
            },
            //rowPageBreak: "avoid", // avoid breaking rows into multiple sections
            columnStyles: {
              2: {
                halign: "right",
              },
            },
            didParseCell: (data) => {
              if (data.cell && data.cell.section === "head") {
                switch (data.cell.raw) {
                  case "Importe":
                    data.cell.styles.halign = "right";
                    break;
                  default:
                    data.cell.styles.halign = "left";
                    break;
                }
              }
              if (data.cell && data.cell.section === "body") {
                if (data.cell.raw === "\n" && salto) {
                  data.row.height = doc.internal.pageSize.height;
                }
              }
            },
          });
          //doc.save("bajasAfiliados.pdf");
          window.open(doc.output("bloburl"));
        }
        if (grupo && tipoListado === "Grupo") {
          const doc = new jsPDF.default(0, 0);
          doc.setFontSize(10);
          doc.text(
            `Listado de comprobantes agrupado por ${
              grupo.nombre
            } Desde: ${formatDate(fechaDesde)} Hasta: ${formatDate(
              fechaHasta
            )}`,
            30,
            8
          );

          let empresas = "";
          for (let i = 0; i < selectedCities.length; i++) {
            if (i === 0) {
              empresas = selectedCities[i].nombre;
            } else {
              empresas = empresas + " - " + selectedCities[i].nombre;
            }
          }

          doc.setFontSize(8);
          doc.text(`Empresas: ${empresas}`, 30, 13);

          let row = 20;

          doc.autoTable(exportColumnsAgrupado, listadoExcel, {
            margin: { left: 10 },
            startY: row,
            styles: {
              fontSize: 10,
            },
            columnStyles: {
              1: {
                halign: "right",
              },
              2: {
                halign: "right",
              },
            },
            didParseCell: (data) => {
              if (data.cell && data.cell.section === "head") {
                switch (data.cell.raw) {
                  case "Importe":
                  case "Cantidad":
                    data.cell.styles.halign = "right";
                    break;
                  default:
                    data.cell.styles.halign = "left";
                    break;
                }
              }
            },
          });
          //doc.save("bajasAfiliadosAgrupado.pdf");
          window.open(doc.output("bloburl"));
        }
      });
    });
  };

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  // INICIO

  if (!bandInicio && empresas && empresas.length > 0 && empresaElegida) {
    setBandInicio(true);
    const index = empresas.findIndex((item) => item.id === empresaElegida.id);
    if (index !== -1) {
      let final = [];
      final.push(empresas[index]);
      setSelectedCities(final);
    }
  }

  // FIN

  //console.log(selectedCities);
  //console.log(serviciosFinalizadosExcel);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Dialog
            header={renderHeaderFechas()}
            visible={visibleFechas}
            closable={false}
            footer={renderFooterFechas()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "75vw", paddingBottom: "0" }}
            position="top"
          >
            <Grid container spacing={3} style={{ marginTop: "1vw" }}>
              <Grid item xs={12} md={2}>
                <TextField
                  id="fechaDesde"
                  label="Fecha Desde"
                  type="date"
                  variant="standard"
                  fullWidth
                  value={fechaDesde ? fechaDesde : null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    guardarFechaDesde(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={2}>
                <TextField
                  id="fechaHasta"
                  label="Fecha Hasta"
                  type="date"
                  variant="standard"
                  fullWidth
                  value={fechaHasta ? fechaHasta : null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    guardarFechaHasta(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3} style={{ marginLeft: "1vw" }}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Tipo
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={tipoListado}
                    onChange={(e) => {
                      setTipoListado(e.target.value);
                      setGrupo(null);
                      setOrden(null);
                    }}
                  >
                    <FormControlLabel
                      value="Orden"
                      control={<Radio />}
                      label="Orden"
                    />
                    <FormControlLabel
                      value="Grupo"
                      control={<Radio />}
                      label="Grupo"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {tipoListado === "Orden" ? (
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{ marginLeftLeft: "1vw", marginTop: "0em" }}
                >
                  <Autocomplete
                    id="ordenar"
                    fullWidth
                    options={ordenar}
                    value={orden}
                    disablePortal
                    getOptionLabel={(option) => option.nombre}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Ordenar por"
                        variant="standard"
                      />
                    )}
                    //onKeyDown={handleEnter}
                    onChange={(event, value) => {
                      if (value) {
                        setOrden(value);
                        setGrupo(null);
                      } else {
                        setOrden(null);
                      }
                    }}
                  />
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{ marginLeftLeft: "1vw", marginTop: "0em" }}
                >
                  <Autocomplete
                    id="agrupar"
                    fullWidth
                    options={agrupar}
                    value={grupo}
                    disablePortal
                    getOptionLabel={(option) => option.nombre}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Agrupar por"
                        variant="standard"
                      />
                    )}
                    //onKeyDown={handleEnter}
                    onChange={(event, value) => {
                      if (value) {
                        setGrupo(value);
                        setOrden(null);
                      } else {
                        setGrupo(null);
                      }
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={12} md={2} style={{ paddingLeft: 0 }}>
                <FormControlLabel
                  value="yes"
                  control={
                    <Checkbox
                      color="primary"
                      checked={subtotal ? subtotal : null}
                      style={{ marginLeft: "1vw" }}
                      onChange={(e) => setSubtotal(e.target.checked)}
                    />
                  }
                  label="Subtotal"
                  labelPlacement="start"
                  style={{ paddingLeft: ".7vw" }}
                />
              </Grid>
              <Grid item xs={12} md={2} style={{ paddingLeft: 0 }}>
                <FormControlLabel
                  value="yes"
                  control={
                    <Checkbox
                      color="primary"
                      checked={salto ? salto : null}
                      style={{ marginLeft: "1vw" }}
                      onChange={(e) => setSalto(e.target.checked)}
                    />
                  }
                  label="Salto Página"
                  labelPlacement="start"
                  style={{ paddingLeft: ".7vw" }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                style={{ paddingLeft: "1vw", marginTop: "1vw" }}
              >
                <label htmlFor="id">Empresas</label>

                <MultiSelect
                  value={selectedCities}
                  onChange={(e) => setSelectedCities(e.value)}
                  options={
                    empresas ? empresas.filter((item) => item.habilitado) : null
                  }
                  optionLabel="nombre"
                  placeholder="Seleccione la/s empresa/s"
                  maxSelectedLabels={5}
                  display="chip"
                  style={{ marginLeft: "1vw" }}
                  //className="w-full md:w-40rem"
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                style={{ paddingLeft: "1vw", marginTop: "1vw" }}
              >
                <label htmlFor="id">Comprobantes</label>

                <MultiSelect
                  value={selectedTipos}
                  onChange={(e) => setSelectedTipos(e.value)}
                  options={tipos ? tipos : null}
                  optionLabel="nombre"
                  placeholder="Seleccione los comprobantes"
                  maxSelectedLabels={5}
                  display="chip"
                  style={{ marginLeft: "1vw" }}
                  //className="w-full md:w-40rem"
                />
              </Grid>
            </Grid>
          </Dialog>
        </Fragment>
      ) : null}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

import { ConnectedOverlayScrollHandler } from "primereact/utils";
import React, { useState, useContext, Fragment } from "react";
import { createContext } from "react";
import clienteAxios from "../config/axios";
import clienteAxiosAdmin from "../config/axiosAdmin";
import { AlertContext } from "./AlertContext";
import Axios from "axios";

const ServiciosContext = createContext();
const { Provider, Consumer } = ServiciosContext;

function ServiciosProvider({ children }) {
  const alertContext = useContext(AlertContext);
  const { mensajeAlerta, mensajeAlerta2, cerrarAlerta } = alertContext;

  const [statusAcceso, setStatusAcceso] = useState(0);
  const [statusAcceso2, setStatusAcceso2] = useState(0);
  const [usuarios, guardarUsuarios] = useState(null);
  const [pantallas, guardarPantallas] = useState(null);
  const [rows, setRows] = useState(null);
  const [configuraciones, guardarConfiguraciones] = useState(null);
  const [direcciones, guardarDirecciones] = useState([]);
  const [grupos, guardarGrupos] = useState([]);
  const [clientes, guardarClientes] = useState([]);
  const [rubros, guardarRubros] = useState([]);

  //Afiliados

  const obtenerDirecciones = async (datos) => {
    const { domicilio, provincia } = datos;

    let dom = domicilio.replace(/ /g, "%20");
    let pro = provincia.replace(/ /g, "%20");

    //var axios = require("axios");

    /*
    var config = {
      method: 'get',
      url: `https://api.geoapify.com/v1/geocode/search?text=${dom}&Argentina&lang=es&limit=3&apiKey=${process.env.REACT_APP_GEOAPIFY_API_KEY}`,
      headers: { }
    };
    */

    var config = {
      method: "get",
      url: `https://maps.googleapis.com/maps/api/geocode/json?address=${dom}%20${pro}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
      headers: {},
    };
   

    try {
      const respuesta = await Axios(config);

      let result = respuesta.data;
      let data = respuesta.data.results;
      let fields = data[0].address_components;
      let geometry = data[0].geometry.location;

      let locality = fields.find((element) => element.types[0] === "locality");
      let areaLevel1 = fields.find(
        (element) => element.types[0] === "administrative_area_level_1"
      );
      let postalCode = fields.find((element) => element.types[0] === "postal_code");

      const local = [];

      for (var i = 0; i < data.length; i++) {

        local.push({
          id: i,
          domicilioGeo: data[i].formatted_address,
          localidad: locality !== undefined ? locality.short_name : "",
          provincia: areaLevel1 !== undefined ? areaLevel1.short_name : "",
          codigoPostal: postalCode !== undefined ? postalCode.short_name : "",
          lat: geometry.lat,
          lon: geometry.lng,
        });
      }

      /*
        local.sort(function (a, b) {
          if (a.provincia > b.provincia) {
            return 1;
          }
          if (a.provincia < b.provincia) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        */

      guardarDirecciones(local);
    } catch (error) {
      console.log(error.response);
    }
  };




  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  function formatDate2(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  function formatDate(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  return (
    <Fragment>
      <Provider
        value={{
          direcciones,
          obtenerDirecciones,
          guardarDirecciones,
        }}
      >
        {children}
      </Provider>
    </Fragment>
  );
}

export { ServiciosProvider, Consumer as ServiciosConsumer, ServiciosContext };

import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";

export default function InformesProcesosFacturacion(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  let navigate = useNavigate();

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Fecha", dataKey: "fecha" },
    { title: "Fecha Factura", dataKey: "fechaFactura" },
    { title: "Período", dataKey: "periodo" },
    { title: "Repetir Período", dataKey: "repetirPeriodo" },
    { title: "Cobrador", dataKey: "cobrador" },
    { title: "Descripción", dataKey: "descripcion" },
    { title: "Importe", dataKey: "importe" },
    { title: "Usuario", dataKey: "usuario" },
  ];

  const [banco, guardarBanco] = useState(null);
  const [visible, setVisible] = useState(false);
  const [detalle, guardarDetalle] = useState(null);
  const [fecha, guardarFecha] = useState(null);
  const toast = useRef(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    informes,
    comprobantes,
    rebotes,
    empresaElegida,
    obtenerInformesProcesosFacturacion,
    obtenerAcceso,
    obtenerComprobantesProcesoFacturacion,
    obtenerRebotesProcesoFacturacion,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "InformesProcesosFacturacion",
      });
    }
  }, [state]);

  useEffect(() => {
    if (empresaElegida) {
      obtenerInformesProcesosFacturacion({ empresa: empresaElegida });
    }
  }, [empresaElegida]);

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const obtenerComprobantesProceso = async (procesoId) => {
    await obtenerComprobantesProcesoFacturacion({
      id: procesoId,
    }).then((data) => {
      let elem;
      elem = document.getElementById("expButtonExcelComprobantes");
      elem.click();
    });
  };

  const obtenerRebotesProceso = async (procesoId) => {
    await obtenerRebotesProcesoFacturacion({
      id: procesoId,
    }).then((data) => {
      let elem;
      elem = document.getElementById("expButtonExcelRebotes");
      elem.click();
    });
  };


  

  const accesoDenegado = () => {
    mensajeAlerta(
      "Informes Procesos Facturación",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }


  const fechaTemplate = (rowData) => {
    return <Fragment>{formatDateTime(rowData.fecha)}</Fragment>;
  };

  const fechaFacturaTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fechaFactura)}</Fragment>;
  };

  const repetirTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.repetirPeriodo}></Checkbox>
      </Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <Button
          style={{
            margin: 0,
            padding: 0,
          }}
          icon="pi pi-check"
          className="p-button-success p-button-text"
          onClick={() => {
            obtenerComprobantesProceso(rowData.id);
          }}
          tooltip="Facturados"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: "tooltip",
          }}
        />
        <Button
          style={{
            margin: 0,
            padding: 0,
          }}
          icon="pi pi-trash"
          className="p-button-danger p-button-text"
          onClick={() => {
            obtenerRebotesProceso(rowData.id);
          }}
          tooltip="Rechazados"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: "tooltip",
          }}
        />
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, informes, {
          styles: {
            fontSize: 7,
          },
        });
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(informes);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "informesProcesoFacturacion" + empresaElegida.nombre);
    });
  };

  const exportExcelComprobantes = () => {
    for (let i = 0; i < comprobantes.length; i++) {
      comprobantes[i].Total = parseFloat(comprobantes[i].Total, 10);
    }
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(comprobantes);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "comprobantesProcesoFacturacion" + empresaElegida.nombre);
    });
  };

  const exportExcelRebotes = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(rebotes);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "rechazosProcesoFacturacion" + empresaElegida.nombre);
    });
  };


  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Informes de Procesos de Facturación"></Tag>
        <button
          id="expButtonExcelComprobantes"
          type="button"
          onClick={(e) => {
            exportExcelComprobantes();
          }}
          style={{ display: "none" }}
        >
          Export Excel
        </button>
        <button
          id="expButtonExcelRebotes"
          type="button"
          onClick={(e) => {
            exportExcelRebotes();
          }}
          style={{ display: "none" }}
        >
          Export Excel
        </button>
      </div>
    </Fragment>
  );

  //console.log(empresaElegida);
  //console.log(state);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {informes ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ align: "center", margin: "1em" }}
              >
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={informes}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        //body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="fecha"
                        header="Fecha"
                        body={fechaTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="fechaFactura"
                        header="Fecha Factura"
                        body={fechaFacturaTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="periodo"
                        header="Período"
                        //body={fechaFacturaTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="repetirPeriodo"
                        header="Repetir Período"
                        body={repetirTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="cobrador"
                        header="Cobrador"
                        //body={repetirTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="descripcion"
                        header="Descripción"
                        //body={repetirTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="importe"
                        header="Importe"
                        //body={repetirTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="usuario"
                        header="Usuario"
                        //body={repetirTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="empresa"
                        header="Empresa"
                        //body={fechaFacturaTemplate}
                        sortable
                      ></Column>
                      <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

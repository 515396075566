import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "../Menu";
import { InputNumber } from "primereact/inputnumber";
import { jsPDF } from "jspdf";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputMask } from "primereact/inputmask";

export default function ProcesosComprasYVentas(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [visibleProceso, setVisibleProceso] = useState(true);
  const [todosLosCobradores, setTodosLosCobradores] = useState(false);
  const [repetirPeriodo, setRepetirPeriodo] = useState(false);
  const [proceso, guardarProceso] = useState(null);
  const [valor, guardarValor] = useState("cuota");
  const [fijo, setFijo] = useState(null);
  const toast = useRef(null);
  const acCobrador = useRef(null);

  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    cobradores,
    statusAcceso,
    empresaElegida,
    listadoExcel,
    empresas,
    configuracion,
    obtenerAcceso,
    tiposComprobantes,
    procesosVentas,
    procesosCompras,
    obtenerModulos,
    obtenerCobradores,
    obtenerEmpresas,
    procesoComprasYVentas,
    obtenerTiposComprobantes,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "ProcesosComprasYVentas",
      });
    }
  }, [state.id]);

  useEffect(() => {
    obtenerEmpresas();
  }, [empresaElegida]);

  const exitClick = (e) => {
    navigate("/");
  };

  const onHideProceso = (e) => {
    setVisibleProceso(false);
  };

  const aceptarProceso = async (e) => {
    e.preventDefault();
    //Validar

    if (
      !proceso ||
      proceso.periodo === undefined ||
      proceso.periodo === null ||
      proceso.periodo === 0 ||
      proceso.periodo === ""
    ) {
      mensajeAlerta("Procesos", "El período es necesario !.", "error");
      return;
    }
    confirm1();
  };

  const confirm1 = () => {
    confirmDialog({
      message: "Esta seguro de iniciar el proceso de exportación",
      header: "Confirmación",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-success",
      closable: false,
      acceptLabel: "Si",
      accept: accept1,
    });
  };

  const accept1 = async () => {
    await procesoComprasYVentas({
      proceso,
      empresaElegida,
      usuario: state.usuario.usuario,
    }).then((data) => {
      let elem = document.getElementById("generarProceso");
      elem.click();
    });
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Proceso de Impresión",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const renderHeaderProceso = () => {
    return (
      <div>
        <Tag
          value={
            empresas && empresas.length > 0
              ? "Régimen de Información de Compras y Ventas " +
                empresaElegida.nombre
              : null
          }
        ></Tag>
      </div>
    );
  };

  const renderFooterProceso = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarProceso}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
          autoFocus
        >
          Aceptar
        </ButtonMui>

        <ButtonMui
          onClick={onHideProceso}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const fechaTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fecha)}</Fragment>;
  };

  const anuladoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.anulado}></Checkbox>
      </Fragment>
    );
  };

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  function formatDateReves(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return year + month + day;
  }

  function formatDateIso(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[0], thisDate[1], thisDate[2]].join("");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate;
  }

  const generar = async () => {
    if (
      (!procesosVentas || procesosVentas.length === 0) &&
      (!procesosCompras || procesosCompras.length === 0)
    ) {
      return;
    }

    const doc = new jsPDF();

    /*
    var blob = new Blob([], { type: "text/plain;charset=utf-8" });
    for (var i = 0; i < 10; i++) {
      blob = new Blob([blob, " " + i], { type: "text/plain" });
    }

    saveAs(blob, "hello_world.txt");

    return;
    */

    let hoy = new Date();

    let fechaDia = hoy.getDate();
    let fechaMes = hoy.getMonth() + 1;
    let fechaAnio = hoy.getFullYear();
    let nombreMes = "";

    if (fechaMes === 1) {
      nombreMes = "Enero";
    }
    if (fechaMes === 2) {
      nombreMes = "Febrero";
    }
    if (fechaMes === 3) {
      nombreMes = "Marzo";
    }
    if (fechaMes === 4) {
      nombreMes = "Abril";
    }
    if (fechaMes === 5) {
      nombreMes = "Mayo";
    }
    if (fechaMes === 6) {
      nombreMes = "Junio";
    }
    if (fechaMes === 7) {
      nombreMes = "Julio";
    }
    if (fechaMes === 8) {
      nombreMes = "Agosto";
    }
    if (fechaMes === 9) {
      nombreMes = "Septiembre";
    }
    if (fechaMes === 10) {
      nombreMes = "Octubre";
    }
    if (fechaMes === 11) {
      nombreMes = "Noviembre";
    }
    if (fechaMes === 12) {
      nombreMes = "Diciembre";
    }

    let empresa = empresas.find((item) => item.id === empresaElegida.id);
    let xCeros = "000000000000000";

    let cuitEmpresa = null;
    if (empresa.cuit !== null) {
      cuitEmpresa =
        empresa.cuit.substring(0, 2) +
        empresa.cuit.substring(3, 11) +
        empresa.cuit.substring(12, 13);
    }

    //Cabecera
    let blobCabecera = new Blob([], { type: "text/plain;charset=utf-8" });
    let blobVentasComprobantes = new Blob([], {
      type: "text/plain;charset=utf-8",
    });
    let blobVentasAlicuotas = new Blob([], {
      type: "text/plain;charset=utf-8",
    });
    let blobVentasCsv = new Blob([], { type: "text/plain;charset=utf-8" });
    let blobComprasComprobantes = new Blob([], {
      type: "text/plain;charset=utf-8",
    });
    let blobComprasAlicuotas = new Blob([], {
      type: "text/plain;charset=utf-8",
    });
    let blobComprasCsv = new Blob([], { type: "text/plain;charset=utf-8" });

    blobCabecera = new Blob(
      [
        cuitEmpresa +
          fechaAnio +
          String(fechaMes - 1).padStart(2, "0") +
          "0" +
          "NN " +
          xCeros +
          xCeros +
          xCeros +
          xCeros +
          xCeros +
          xCeros +
          "\n",
      ],
      { type: "text/plain;charset=utf-8" }
    );

    //saveAs(blobCabecera, "REGINFO_CV_CABECERA.TXT");

    //Comprobantes Ventas y Alicuotas Ventas

    let xCodDoc = null;

    for (var i = 0; i < procesosVentas.length; i++) {
      switch (procesosVentas[i].afiliados.id_tipodocumento) {
        case 3:
          xCodDoc = "80";
          break;
        case 4:
          xCodDoc = "96";
          break;
        default:
          xCodDoc = "00";
      }

      let xCuit = procesosVentas[i].cuit.padStart(20, "0");
      if (xCuit === "00000000000000000000") {
        xCodDoc = "99";
      }
      let xNombre = procesosVentas[i].nombre_titular.trim();
      if (xNombre.length > 30) {
        xNombre = xNombre.substring(0, 30);
      }
      xNombre = xNombre.padEnd(30, " ");

      let xTipo = String(procesosVentas[i].id_comprobanteafip).padStart(3, "0");

      let xParteEnteraTotal = String(
        Math.trunc(Math.abs(Number(procesosVentas[i].total)))
      ).padStart(13, "0");
      let xParteDecimalTotal = String(
        parseFloat(Number(procesosVentas[i].total)).toFixed(2)
      ).slice(-2);
      let xParteEnteraExento = null;
      let xParteDecimalExento = null;
      let xParteEnteraSubTotal = null;
      let xParteDecimalSubTotal = null;
      let xParteEnteraIva = null;
      let xParteDecimalIva = null;
      let xCodOpe = null;
      let xTasa = null;
      let xCantIva = null;
      let xNeto1 = null;
      let xNeto2 = null;
      let xNeto3 = null;
      let xParteEnteraNeto1 = null;
      let xParteDecimalNeto1 = null;
      let xParteEnteraNeto2 = null;
      let xParteDecimalNeto2 = null;
      let xParteEnteraNeto3 = null;
      let xParteDecimalNeto3 = null;
      let xParteEnteraIva1 = null;
      let xParteDecimalIva1 = null;
      let xParteEnteraIva2 = null;
      let xParteDecimalIva2 = null;
      let xParteEnteraIva3 = null;
      let xParteDecimalIva3 = null;
      let xParteEnteraIB = null;
      let xParteDecimalIB = null;
      let xTasa1 = 10.5;
      let xTasa2 = 21;

      if (Number(procesosVentas[i].iva) === 0) {
        xParteEnteraExento = String(
          Math.trunc(Math.abs(Number(procesosVentas[i].subtotal)))
        ).padStart(13, "0");
        xParteDecimalExento = String(
          parseFloat(Number(procesosVentas[i].subtotal)).toFixed(2)
        ).slice(-2);
        xParteEnteraSubTotal = "0000000000000";
        xParteDecimalSubTotal = "00";
        xParteEnteraIva = "0000000000000";
        xParteDecimalIva = "00";
        xCodOpe = "E";
        xTasa = "0003";
        xCantIva = 1;
      } else {
        xParteEnteraSubTotal = String(
          Math.trunc(Math.abs(Number(procesosVentas[i].subtotal)))
        ).padStart(13, "0");
        xParteDecimalSubTotal = String(
          parseFloat(Number(procesosVentas[i].subtotal)).toFixed(2)
        ).slice(-2);
        xParteEnteraExento = "0000000000000";
        xParteDecimalExento = "00";
        xCodOpe = " ";
        xCantIva = 0;
        if (Number(procesosVentas[i].iva1) !== 0) {
          xTasa = "0004"; //10.5
          xCantIva = xCantIva + 1;
        }
        if (Number(procesosVentas[i].iva2) !== 0) {
          xTasa = "0005"; // 21
          xCantIva = xCantIva + 1;
        }
        if (Number(procesosVentas[i].iva3) !== 0) {
          xTasa = "0006"; // 27
          xCantIva = xCantIva + 1;
        }
        xParteEnteraIva = String(
          Math.trunc(Math.abs(Number(procesosVentas[i].iva)))
        ).padStart(13, "0");
        xParteDecimalIva = String(
          parseFloat(Number(procesosVentas[i].iva)).toFixed(2)
        ).slice(-2);
      }

      if (xCantIva > 1) {
        if (
          Number(procesosVentas[i].iva1) !== 0 &&
          Number(procesosVentas[i].iva2) !== 0 &&
          Number(procesosVentas[i].iva3) !== 0
        ) {
          xNeto1 = Number(procesosVentas[i].iva1) / (xTasa1 / 100);
          xParteEnteraNeto1 = String(
            Math.trunc(Math.abs(Number(xNeto1)))
          ).padStart(13, "0");
          xParteDecimalNeto1 = String(
            parseFloat(Number(xNeto1)).toFixed(2)
          ).slice(-2);
          xNeto2 = Number(procesosVentas[i].iva2) / (xTasa2 / 100);
          xParteEnteraNeto2 = String(
            Math.trunc(Math.abs(Number(xNeto2)))
          ).padStart(13, "0");
          xParteDecimalNeto2 = String(
            parseFloat(Number(xNeto2)).toFixed(2)
          ).slice(-2);
          xNeto3 =
            Number(procesosVentas[i].subtotal) -
            Number(xNeto1) -
            Number(xNeto2);
          xParteEnteraNeto3 = String(
            Math.trunc(Math.abs(Number(xNeto3)))
          ).padStart(13, "0");
          xParteDecimalNeto3 = String(
            parseFloat(Number(xNeto3)).toFixed(2)
          ).slice(-2);
        }
        if (
          Number(procesosVentas[i].iva1) !== 0 &&
          Number(procesosVentas[i].iva2) !== 0 &&
          Number(procesosVentas[i].iva3) === 0
        ) {
          xNeto1 = Number(procesosVentas[i].iva1) / (xTasa1 / 100);
          xParteEnteraNeto1 = String(
            Math.trunc(Math.abs(Number(xNeto1)))
          ).padStart(13, "0");
          xParteDecimalNeto1 = String(
            parseFloat(Number(xNeto1)).toFixed(2)
          ).slice(-2);
          xNeto2 = Math.abs(
            Number(procesosVentas[i].subtotal) - Number(xNeto1)
          );
          xParteEnteraNeto2 = String(
            Math.trunc(Math.abs(Number(xNeto2)))
          ).padStart(13, "0");
          xParteDecimalNeto2 = String(
            parseFloat(Number(xNeto2)).toFixed(2)
          ).slice(-2);
        }
        if (
          Number(procesosVentas[i].iva1) !== 0 &&
          Number(procesosVentas[i].iva2) === 0 &&
          Number(procesosVentas[i].iva3) !== 0
        ) {
          xNeto1 = Number(procesosVentas[i].iva1) / (xTasa1 / 100);
          xParteEnteraNeto1 = String(
            Math.trunc(Math.abs(Number(xNeto1)))
          ).padStart(13, "0");
          xParteDecimalNeto1 = String(
            parseFloat(Number(xNeto1)).toFixed(2)
          ).slice(-2);
          xNeto3 = Math.abs(
            Number(procesosVentas[i].subtotal) - Number(xNeto1)
          );
          xParteEnteraNeto3 = String(
            Math.trunc(Math.abs(Number(xNeto3)))
          ).padStart(13, "0");
          xParteDecimalNeto3 = String(
            parseFloat(Number(xNeto3)).toFixed(2)
          ).slice(-2);
        }
        if (
          Number(procesosVentas[i].iva1) === 0 &&
          Number(procesosVentas[i].iva2) !== 0 &&
          Number(procesosVentas[i].iva3) !== 0
        ) {
          xNeto2 = Number(procesosVentas[i].iva2) / (xTasa2 / 100);
          xParteEnteraNeto2 = String(
            Math.trunc(Math.abs(Number(xNeto2)))
          ).padStart(13, "0");
          xParteDecimalNeto2 = String(
            parseFloat(Number(xNeto2)).toFixed(2)
          ).slice(-2);
          xNeto3 = Math.abs(
            Number(procesosVentas[i].subtotal) - Number(xNeto2)
          );
          xParteEnteraNeto3 = String(
            Math.trunc(Math.abs(Number(xNeto3)))
          ).padStart(13, "0");
          xParteDecimalNeto3 = String(
            parseFloat(Number(xNeto3)).toFixed(2)
          ).slice(-2);
        }

        xCantIva = 0;
        if (Number(procesosVentas[i].iva1) !== 0) {
          xCantIva = xCantIva + 1;
          xParteEnteraIva1 = String(
            Math.trunc(Math.abs(Number(procesosVentas[i].iva1)))
          ).padStart(13, "0");
          xParteDecimalIva1 = String(
            parseFloat(Number(procesosVentas[i].iva1)).toFixed(2)
          ).slice(-2);
        }
        if (Number(procesosVentas[i].iva1) !== 0) {
          xCantIva = xCantIva + 1;
          xParteEnteraIva2 = String(
            Math.trunc(Math.abs(Number(procesosVentas[i].iva2)))
          ).padStart(13, "0");
          xParteDecimalIva2 = String(
            parseFloat(Number(procesosVentas[i].iva2)).toFixed(2)
          ).slice(-2);
        }
        if (Number(procesosVentas[i].iva3) !== 0) {
          xCantIva = xCantIva + 1;
          xParteEnteraIva3 = String(
            Math.trunc(Math.abs(Number(procesosVentas[i].iva3)))
          ).padStart(13, "0");
          xParteDecimalIva3 = String(
            parseFloat(Number(procesosVentas[i].iva3)).toFixed(2)
          ).slice(-2);
        }

        xParteEnteraIva = String(
          Math.trunc(
            Math.abs(
              Number(procesosVentas[i].iva1) +
                Number(procesosVentas[i].iva2) +
                Number(procesosVentas[i].iva3)
            )
          ).padStart(13, "0")
        );
        xParteDecimalIva = String(
          parseFloat(
            Number(procesosVentas[i].iva1) +
              Number(procesosVentas[i].iva2) +
              Number(procesosVentas[i].iva3)
          ).toFixed(2)
        ).slice(-2);
      }

      xParteEnteraIB = String(
        Math.trunc(
          Math.abs(
            Number(procesosVentas[i].percarba) +
              Number(procesosVentas[i].percagip)
          )
        )
      ).padStart(13, "0");

      xParteDecimalIB = String(
        parseFloat(
          Number(procesosVentas[i].percarba) +
            Number(procesosVentas[i].percagip)
        ).toFixed(2)
      ).slice(-2);

      let date1 = new Date(procesosVentas[i].fecha);
      date1 = date1.toISOString();
      let newDate = new Date(date1);
      newDate.setDate(newDate.getDate() + 60);
      newDate = formatDateIso(newDate.toISOString());

      if (procesosVentas[i].tipo !== "M") {
        blobVentasComprobantes = new Blob(
          [
            blobVentasComprobantes,
            formatDateReves(procesosVentas[i].fecha) +
              xTipo +
              String(procesosVentas[i].puntoventa).padStart(5, "0") +
              String(procesosVentas[i].numero).padStart(20, "0") +
              String(procesosVentas[i].numero).padStart(20, "0") +
              xCodDoc +
              xCuit +
              xNombre +
              xParteEnteraTotal +
              xParteDecimalTotal +
              xCeros +
              xCeros +
              xParteEnteraExento +
              xParteDecimalExento +
              xCeros +
              xParteEnteraIB +
              xParteDecimalIB +
              xCeros +
              xCeros +
              "PES" +
              "0001000000" +
              String(xCantIva) +
              xCodOpe +
              xCeros +
              newDate +
              "\n",
          ],
          { type: "text/plain;charset=utf-8" }
        );

        if (
          Number(procesosVentas[i].iva1) === 0 &&
          Number(procesosVentas[i].iva2) === 0 &&
          Number(procesosVentas[i].iva3) === 0
        ) {
          blobVentasAlicuotas = new Blob(
            [
              blobVentasAlicuotas,
              xTipo +
                String(procesosVentas[i].puntoventa).padStart(5, "0") +
                String(procesosVentas[i].numero).padStart(20, "0") +
                xParteEnteraSubTotal +
                xParteDecimalSubTotal +
                "0003" +
                xParteEnteraIva +
                xParteDecimalIva +
                "\n",
            ],
            { type: "text/plain;charset=utf-8" }
          );
          //Print #3, Format(!fecha, "dd/mm/yyyy"); ";"; xFactExcel; ";"; xTipo; ";"; !sucursal; ";"; !numero; ";"; !nombre_razonsocial; ";"; xCodDoc; ";"; xCuit; ";"; !domicilio; ";"; !cp; ";"; xLocalidad; ";"; xCondicion; ";"; "REF"; ";"; !subtotal; ";"; 0; ";"; 0; ";"; 0; ";"; "REF"; ";"; !subtotal; ";"; "REF"; ";"; !ib + !ib2; ";"; xLocalidad; ";"; !total
        } else {
          if (xCantIva > 1) {
            if (Number(procesosVentas[i].iva1) !== 0) {
              blobVentasAlicuotas = new Blob(
                [
                  blobVentasAlicuotas,
                  xTipo +
                    String(procesosVentas[i].puntoventa).padStart(5, "0") +
                    String(procesosVentas[i].numero).padStart(20, "0") +
                    xParteEnteraNeto1 +
                    xParteDecimalNeto1 +
                    "0004" +
                    xParteEnteraIva1 +
                    xParteDecimalIva1 +
                    "\n",
                ],
                { type: "text/plain;charset=utf-8" }
              );
              //Print #3, Format(!fecha, "dd/mm/yyyy"); ";"; xFactExcel; ";"; xTipo; ";"; !sucursal; ";"; !numero; ";"; !nombre_razonsocial; ";"; xCodDoc; ";"; xCuit; ";"; !domicilio; ";"; !cp; ";"; xLocalidad; ";"; xCondicion; ";"; "REF"; ";"; !subtotal; ";"; 21; ";"; !iva1; ";"; !iva1; ";"; "REF"; ";"; 0; ";"; "REF"; ";"; !ib + !ib2; ";"; xLocalidad; ";"; !total
            }
            if (Number(procesosVentas[i].iva2) !== 0) {
              blobVentasAlicuotas = new Blob(
                [
                  blobVentasAlicuotas,
                  xTipo +
                    String(procesosVentas[i].puntoventa).padStart(5, "0") +
                    String(procesosVentas[i].numero).padStart(20, "0") +
                    xParteEnteraNeto2 +
                    xParteDecimalNeto2 +
                    "0005" +
                    xParteEnteraIva2 +
                    xParteDecimalIva2 +
                    "\n",
                ],
                { type: "text/plain;charset=utf-8" }
              );
              //Print #3, Format(!fecha, "dd/mm/yyyy"); ";"; xFactExcel; ";"; xTipo; ";"; !sucursal; ";"; !numero; ";"; !nombre_razonsocial; ";"; xCodDoc; ";"; xCuit; ";"; !domicilio; ";"; !cp; ";"; xLocalidad; ";"; xCondicion; ";"; "REF"; ";"; !subtotal; ";"; 27; ";"; !iva2; ";"; !iva2; ";"; "REF"; ";"; 0; ";"; "REF"; ";"; !ib + !ib2; ";"; xLocalidad; ";"; !total
            }
            if (Number(procesosVentas[i].iva3) !== 0) {
              blobVentasAlicuotas = new Blob(
                [
                  blobVentasAlicuotas,
                  xTipo +
                    String(procesosVentas[i].puntoventa).padStart(5, "0") +
                    String(procesosVentas[i].numero).padStart(20, "0") +
                    xParteEnteraNeto3 +
                    xParteDecimalNeto3 +
                    "0006" +
                    xParteEnteraIva3 +
                    xParteDecimalIva3 +
                    "\n",
                ],
                { type: "text/plain;charset=utf-8" }
              );
              //Print #3, Format(!fecha, "dd/mm/yyyy"); ";"; xFactExcel; ";"; xTipo; ";"; !sucursal; ";"; !numero; ";"; !nombre_razonsocial; ";"; xCodDoc; ";"; xCuit; ";"; !domicilio; ";"; !cp; ";"; xLocalidad; ";"; xCondicion; ";"; "REF"; ";"; !subtotal; ";"; 10.5; ";"; !iva3; ";"; !iva3; ";"; "REF"; ";"; 0; ";"; "REF"; ";"; !ib + !ib2; ";"; xLocalidad; ";"; !total
            }
          } else {
            blobVentasAlicuotas = new Blob(
              [
                blobVentasAlicuotas,
                xTipo +
                  String(procesosVentas[i].puntoventa).padStart(5, "0") +
                  String(procesosVentas[i].numero).padStart(20, "0") +
                  xParteEnteraSubTotal +
                  xParteDecimalSubTotal +
                  xTasa +
                  xParteEnteraIva +
                  xParteDecimalIva +
                  "\n",
              ],
              { type: "text/plain;charset=utf-8" }
            );
            if (Number(procesosVentas[i].iva1) !== 0) {
              //Print #3, Format(!fecha, "dd/mm/yyyy"); ";"; xFactExcel; ";"; xTipo; ";"; !sucursal; ";"; !numero; ";"; !nombre_razonsocial; ";"; xCodDoc; ";"; xCuit; ";"; !domicilio; ";"; !cp; ";"; xLocalidad; ";"; xCondicion; ";"; "REF"; ";"; !subtotal; ";"; 21; ";"; !iva1; ";"; !iva1; ";"; "REF"; ";"; 0; ";"; "REF"; ";"; !ib + !ib2; ";"; xLocalidad; ";"; !total
            }
            if (Number(procesosVentas[i].iva2) !== 0) {
              //Print #3, Format(!fecha, "dd/mm/yyyy"); ";"; xFactExcel; ";"; xTipo; ";"; !sucursal; ";"; !numero; ";"; !nombre_razonsocial; ";"; xCodDoc; ";"; xCuit; ";"; !domicilio; ";"; !cp; ";"; xLocalidad; ";"; xCondicion; ";"; "REF"; ";"; !subtotal; ";"; 27; ";"; !iva2; ";"; !iva2; ";"; "REF"; ";"; 0; ";"; "REF"; ";"; !ib + !ib2; ";"; xLocalidad; ";"; !total
            }
            if (Number(procesosVentas[i].iva3) !== 0) {
              //Print #3, Format(!fecha, "dd/mm/yyyy"); ";"; xFactExcel; ";"; xTipo; ";"; !sucursal; ";"; !numero; ";"; !nombre_razonsocial; ";"; xCodDoc; ";"; xCuit; ";"; !domicilio; ";"; !cp; ";"; xLocalidad; ";"; xCondicion; ";"; "REF"; ";"; !subtotal; ";"; 10.5; ";"; !iva3; ";"; !iva3; ";"; "REF"; ";"; 0; ";"; "REF"; ";"; !ib + !ib2; ";"; xLocalidad; ";"; !total
            }
          }
        }
      } else {
        blobVentasComprobantes = new Blob(
          [
            blobVentasComprobantes,
            formatDateReves(procesosVentas[i].fecha) +
              xTipo +
              String(procesosVentas[i].puntoventa).padStart(5, "0") +
              String(procesosVentas[i].numero).padStart(20, "0") +
              String(procesosVentas[i].numero).padStart(20, "0") +
              xCodDoc +
              xCuit +
              xNombre +
              xParteEnteraTotal +
              xParteDecimalTotal +
              xCeros +
              xCeros +
              xParteEnteraExento +
              xParteDecimalExento +
              xCeros +
              xParteEnteraIB +
              xParteDecimalIB +
              xCeros +
              xCeros +
              "PES" +
              "0001000000" +
              String(xCantIva) +
              xCodOpe +
              xCeros +
              "00000000" +
              "\n",
          ],
          { type: "text/plain;charset=utf-8" }
        );

        if (
          Number(procesosVentas[i].iva1) === 0 &&
          Number(procesosVentas[i].iva2) === 0 &&
          Number(procesosVentas[i].iva3) === 0
        ) {
          blobVentasAlicuotas = new Blob(
            [
              blobVentasAlicuotas,
              xTipo +
                String(procesosVentas[i].puntoventa).padStart(5, "0") +
                String(procesosVentas[i].numero).padStart(20, "0") +
                xParteEnteraSubTotal +
                xParteDecimalSubTotal +
                "0003" +
                xParteEnteraIva +
                xParteDecimalIva +
                "\n",
            ],
            { type: "text/plain;charset=utf-8" }
          );

          //Print #3, Format(!fecha, "dd/mm/yyyy"); ";"; xFactExcel; ";"; xTipo; ";"; !sucursal; ";"; !numero; ";"; !nombre_razonsocial; ";"; xCodDoc; ";"; xCuit; ";"; !domicilio; ";"; !cp; ";"; xLocalidad; ";"; xCondicion; ";"; "REF"; ";"; !subtotal; ";"; 0; ";"; 0; ";"; 0; ";"; "REF"; ";"; !subtotal; ";"; "REF"; ";"; !ib + !ib2; ";"; xLocalidad; ";"; !total
        } else {
          if (xCantIva > 1) {
            if (Number(procesosVentas[i].iva1) !== 0) {
              blobVentasAlicuotas = new Blob(
                [
                  blobVentasAlicuotas,
                  xTipo +
                    String(procesosVentas[i].puntoventa).padStart(5, "0") +
                    String(procesosVentas[i].numero).padStart(20, "0") +
                    xParteEnteraNeto1 +
                    xParteDecimalNeto1 +
                    "0004" +
                    xParteEnteraIva1 +
                    xParteDecimalIva1 +
                    "\n",
                ],
                { type: "text/plain;charset=utf-8" }
              );
              //Print #3, Format(!fecha, "dd/mm/yyyy"); ";"; xFactExcel; ";"; xTipo; ";"; !sucursal; ";"; !numero; ";"; !nombre_razonsocial; ";"; xCodDoc; ";"; xCuit; ";"; !domicilio; ";"; !cp; ";"; xLocalidad; ";"; xCondicion; ";"; "REF"; ";"; !subtotal; ";"; 21; ";"; !iva1; ";"; !iva1; ";"; "REF"; ";"; 0; ";"; "REF"; ";"; !ib + !ib2; ";"; xLocalidad; ";"; !total
            }
            if (Number(procesosVentas[i].iva2) !== 0) {
              blobVentasAlicuotas = new Blob(
                [
                  blobVentasAlicuotas,
                  xTipo +
                    String(procesosVentas[i].puntoventa).padStart(5, "0") +
                    String(procesosVentas[i].numero).padStart(20, "0") +
                    xParteEnteraNeto2 +
                    xParteDecimalNeto2 +
                    "0005" +
                    xParteEnteraIva2 +
                    xParteDecimalIva2 +
                    "\n",
                ],
                { type: "text/plain;charset=utf-8" }
              );
              //Print #3, Format(!fecha, "dd/mm/yyyy"); ";"; xFactExcel; ";"; xTipo; ";"; !sucursal; ";"; !numero; ";"; !nombre_razonsocial; ";"; xCodDoc; ";"; xCuit; ";"; !domicilio; ";"; !cp; ";"; xLocalidad; ";"; xCondicion; ";"; "REF"; ";"; !subtotal; ";"; 27; ";"; !iva2; ";"; !iva2; ";"; "REF"; ";"; 0; ";"; "REF"; ";"; !ib + !ib2; ";"; xLocalidad; ";"; !total
            }
            if (Number(procesosVentas[i].iva3) !== 0) {
              blobVentasAlicuotas = new Blob(
                [
                  blobVentasAlicuotas,
                  xTipo +
                    String(procesosVentas[i].puntoventa).padStart(5, "0") +
                    String(procesosVentas[i].numero).padStart(20, "0") +
                    xParteEnteraNeto3 +
                    xParteDecimalNeto3 +
                    "0006" +
                    xParteEnteraIva3 +
                    xParteDecimalIva3 +
                    "\n",
                ],
                { type: "text/plain;charset=utf-8" }
              );
              //Print #3, Format(!fecha, "dd/mm/yyyy"); ";"; xFactExcel; ";"; xTipo; ";"; !sucursal; ";"; !numero; ";"; !nombre_razonsocial; ";"; xCodDoc; ";"; xCuit; ";"; !domicilio; ";"; !cp; ";"; xLocalidad; ";"; xCondicion; ";"; "REF"; ";"; !subtotal; ";"; 10.5; ";"; !iva3; ";"; !iva3; ";"; "REF"; ";"; 0; ";"; "REF"; ";"; !ib + !ib2; ";"; xLocalidad; ";"; !total
            }
          } else {
            blobVentasAlicuotas = new Blob(
              [
                blobVentasAlicuotas,
                xTipo +
                  String(procesosVentas[i].puntoventa).padStart(5, "0") +
                  String(procesosVentas[i].numero).padStart(20, "0") +
                  xParteEnteraNeto3 +
                  xParteDecimalNeto3 +
                  xTasa +
                  xParteEnteraIva3 +
                  xParteDecimalIva3 +
                  "\n",
              ],
              { type: "text/plain;charset=utf-8" }
            );

            if (Number(procesosVentas[i].iva1) !== 0) {
              //Print #3, Format(!fecha, "dd/mm/yyyy"); ";"; xFactExcel; ";"; xTipo; ";"; !sucursal; ";"; !numero; ";"; !nombre_razonsocial; ";"; xCodDoc; ";"; xCuit; ";"; !domicilio; ";"; !cp; ";"; xLocalidad; ";"; xCondicion; ";"; "REF"; ";"; !subtotal; ";"; 21; ";"; !iva1; ";"; !iva1; ";"; "REF"; ";"; 0; ";"; "REF"; ";"; !ib + !ib2; ";"; xLocalidad; ";"; !total
            }
            if (Number(procesosVentas[i].iva2) !== 0) {
              //Print #3, Format(!fecha, "dd/mm/yyyy"); ";"; xFactExcel; ";"; xTipo; ";"; !sucursal; ";"; !numero; ";"; !nombre_razonsocial; ";"; xCodDoc; ";"; xCuit; ";"; !domicilio; ";"; !cp; ";"; xLocalidad; ";"; xCondicion; ";"; "REF"; ";"; !subtotal; ";"; 27; ";"; !iva2; ";"; !iva2; ";"; "REF"; ";"; 0; ";"; "REF"; ";"; !ib + !ib2; ";"; xLocalidad; ";"; !total
            }
            if (Number(procesosVentas[i].iva3) !== 0) {
              //Print #3, Format(!fecha, "dd/mm/yyyy"); ";"; xFactExcel; ";"; xTipo; ";"; !sucursal; ";"; !numero; ";"; !nombre_razonsocial; ";"; xCodDoc; ";"; xCuit; ";"; !domicilio; ";"; !cp; ";"; xLocalidad; ";"; xCondicion; ";"; "REF"; ";"; !subtotal; ";"; 10.5; ";"; !iva3; ";"; !iva3; ";"; "REF"; ";"; 0; ";"; "REF"; ";"; !ib + !ib2; ";"; xLocalidad; ";"; !total
            }
          }
        }
      }
    }

    //Comprobantes Compras y Alicuotas Compras

    xCodDoc = null;
    let xLocalidad = null;

    for (var i = 0; i < procesosCompras.length; i++) {
      switch (procesosCompras[i].proveedores.id_tipodocumento) {
        case 3:
          xCodDoc = "80";
          break;
        case 4:
          xCodDoc = "96";
          break;
        default:
          xCodDoc = "00";
      }

      if (procesosCompras[i].proveedores.id_provincia === 24) {
        xLocalidad = "00";
      } else {
        xLocalidad = "01";
      }

      let xCuit =
      procesosCompras[i].cuit.substring(0, 2) +
      procesosCompras[i].cuit.substring(3, 11) +
      procesosCompras[i].cuit.substring(12, 13);


      xCuit = xCuit.padStart(20, "0");
      if (xCuit === "00000000000000000000") {
        xCodDoc = "99";
      }
      let xNombre = procesosCompras[i].nombre_proveedor.trim();
      if (xNombre.length > 30) {
        xNombre = xNombre.substring(0, 30);
      }
      xNombre = xNombre.padEnd(30, " ");

      let xTipo = String(procesosCompras[i].id_comprobanteafip).padStart(
        3,
        "0"
      );

      let xParteEnteraTotal = String(
        Math.trunc(Math.abs(Number(procesosCompras[i].total)))
      ).padStart(13, "0");
      let xParteDecimalTotal = String(
        parseFloat(Number(procesosCompras[i].total)).toFixed(2)
      ).slice(-2);
      let xParteEnteraExento = null;
      let xParteDecimalExento = null;
      let xParteEnteraSubTotal = null;
      let xParteDecimalSubTotal = null;
      let xParteEnteraIva = null;
      let xParteDecimalIva = null;
      let xCodOpe = null;
      let xTasa = null;
      let xCantIva = null;
      let xNeto1 = null;
      let xNeto2 = null;
      let xNeto3 = null;
      let xParteEnteraNeto1 = null;
      let xParteDecimalNeto1 = null;
      let xParteEnteraNeto2 = null;
      let xParteDecimalNeto2 = null;
      let xParteEnteraNeto3 = null;
      let xParteDecimalNeto3 = null;
      let xParteEnteraIva1 = null;
      let xParteDecimalIva1 = null;
      let xParteEnteraIva2 = null;
      let xParteDecimalIva2 = null;
      let xParteEnteraIva3 = null;
      let xParteDecimalIva3 = null;
      let xParteEnteraIB = null;
      let xParteDecimalIB = null;
      let xParteEnteraMono = null;
      let xParteDecimalMono = null;
      let xParteEnteraPerIva = null;
      let xParteDecimalPerIva = null;
      let xParteEnteraPerGan = null;
      let xParteDecimalPerGan = null;
      let xParteEnteraPerIB = null;
      let xParteDecimalPerIB = null;
      let xParteEnteraNeto = null;
      let xParteDecimalNeto = null;
      let xParteEnteraOtros = null;
      let xParteDecimalOtros = null;

      let xTasa1 = 10.5;
      let xTasa2 = 21;

      if (procesosCompras[i].tipo === "B" || procesosCompras[i].tipo === "C") {
        xParteEnteraMono = "0000000000000";
        xParteDecimalMono = "00";
      } else {
        xParteEnteraMono = String(
          Math.trunc(Math.abs(Number(procesosCompras[i].exento)))
        ).padStart(13, "0");
        xParteDecimalMono = String(
          parseFloat(Number(procesosCompras[i].exento)).toFixed(2)
        ).slice(-2);
      }

      xParteEnteraPerIva = String(
        Math.trunc(Math.abs(Number(procesosCompras[i].periva)))
      ).padStart(13, "0");
      xParteDecimalPerIva = String(
        parseFloat(Number(procesosCompras[i].periva)).toFixed(2)
      ).slice(-2);

      xParteEnteraPerGan = String(
        Math.trunc(Math.abs(Number(procesosCompras[i].perganancias)))
      ).padStart(13, "0");
      xParteDecimalPerGan = String(
        parseFloat(Number(procesosCompras[i].perganancias)).toFixed(2)
      ).slice(-2);

      xParteEnteraPerIB = String(
        Math.trunc(Math.abs(Number(procesosCompras[i].perganancias)))
      ).padStart(13, "0");
      xParteDecimalPerIB = String(
        parseFloat(
          Number(procesosCompras[i].periibbbsas) +
            Number(procesosCompras[i].periibbcaba)
        ).toFixed(2)
      ).slice(-2);

      xParteEnteraNeto = String(
        Math.trunc(Math.abs(Number(procesosCompras[i].subtotal)))
      ).padStart(13, "0");
      xParteDecimalNeto = String(
        parseFloat(Number(procesosCompras[i].subtotal)).toFixed(2)
      ).slice(-2);

      xParteEnteraNeto1 = String(
        Math.trunc(Math.abs(Number(procesosCompras[i].neto105)))
      ).padStart(13, "0");
      xParteDecimalNeto1 = String(
        parseFloat(Number(procesosCompras[i].neto105)).toFixed(2)
      ).slice(-2);
      xParteEnteraNeto2 = String(
        Math.trunc(Math.abs(Number(procesosCompras[i].neto21)))
      ).padStart(13, "0");
      xParteDecimalNeto2 = String(
        parseFloat(Number(procesosCompras[i].neto21)).toFixed(2)
      ).slice(-2);
      xParteEnteraNeto3 = String(
        Math.trunc(Math.abs(Number(procesosCompras[i].neto27)))
      ).padStart(13, "0");
      xParteDecimalNeto3 = String(
        parseFloat(Number(procesosCompras[i].neto27)).toFixed(2)
      ).slice(-2);

      if (procesosCompras[i].tipo === "B" || procesosCompras[i].tipo === "C") {
        xCantIva = 0;
        xCodOpe = "E";
      } else {
        xCantIva = 0;
        if (
          Number(procesosCompras[i].iva1) === 0 &&
          Number(procesosCompras[i].iva2) === 0 &&
          Number(procesosCompras[i].iva3) === 0
        ) {
          xParteEnteraIva = "0000000000000";
          xParteDecimalIva = "00";
          xCodOpe = "E";
          xCantIva = 1;
        } else {
          if (Number(procesosCompras[i].iva1) !== 0) {
            xCantIva = xCantIva + 1;
            xParteEnteraIva1 = String(
              Math.trunc(Math.abs(Number(procesosCompras[i].iva1)))
            ).padStart(13, "0");
            xParteDecimalIva1 = String(
              parseFloat(Number(procesosCompras[i].iva1)).toFixed(2)
            ).slice(-2);
          }
          if (Number(procesosCompras[i].iva2) !== 0) {
            xCantIva = xCantIva + 1;
            xParteEnteraIva2 = String(
              Math.trunc(Math.abs(Number(procesosCompras[i].iva2)))
            ).padStart(13, "0");
            xParteDecimalIva2 = String(
              parseFloat(Number(procesosCompras[i].iva2)).toFixed(2)
            ).slice(-2);
          }
          if (Number(procesosCompras[i].iva3) !== 0) {
            xCantIva = xCantIva + 1;
            xParteEnteraIva3 = String(
              Math.trunc(Math.abs(Number(procesosCompras[i].iva3)))
            ).padStart(13, "0");
            xParteDecimalIva3 = String(
              parseFloat(Number(procesosCompras[i].iva3)).toFixed(2)
            ).slice(-2);
          }

          xParteEnteraIva = String(
            Math.trunc(Math.abs(Number(procesosCompras[i].iva)))
          ).padStart(13, "0");
          xParteDecimalIva = String(
            parseFloat(Number(procesosCompras[i].iva)).toFixed(2)
          ).slice(-2);
          xCodOpe = " ";
        }
      }

      xParteEnteraOtros = String(
        Math.trunc(
          Math.abs(
            Number(procesosCompras[i].retiva) +
              Number(procesosCompras[i].retganancias) +
              Number(procesosCompras[i].retiibb) +
              Number(procesosCompras[i].otrostributos)
          )
        )
      ).padStart(13, "0");
      xParteDecimalOtros = String(
        parseFloat(
          Number(procesosCompras[i].retiva) +
            Number(procesosCompras[i].retganancias) +
            Number(procesosCompras[i].retiibb) +
            Number(procesosCompras[i].otrostributos)
        ).toFixed(2)
      ).slice(-2);

      if (
        procesosCompras[i].tipo === "A" ||
        procesosCompras[i].tipo === "B" ||
        procesosCompras[i].tipo === "M"
      ) {
        blobComprasComprobantes = new Blob(
          [
            blobComprasComprobantes,
            formatDateReves(procesosCompras[i].fechacomprobante) +
              xTipo +
              String(procesosCompras[i].puntoventa).padStart(5, "0") +
              String(procesosCompras[i].numero).padStart(20, "0") +
              " ".repeat(16) +
              xCodDoc +
              xCuit +
              xNombre +
              xParteEnteraTotal +
              xParteDecimalTotal +
              xCeros +
              xParteEnteraMono +
              xParteDecimalMono +
              xParteEnteraPerIva +
              xParteDecimalPerIva +
              xParteEnteraPerGan +
              xParteDecimalPerGan +
              xParteEnteraPerIB +
              xParteDecimalPerIB +
              xCeros +
              xCeros +
              "PES" +
              "0001000000" +
              String(xCantIva) +
              xCodOpe +
              xParteEnteraIva +
              xParteDecimalIva +
              xParteEnteraOtros +
              xParteDecimalOtros +
              "0".repeat(11) +
              " ".repeat(30) +
              xCeros +
              "\n",
          ],
          { type: "text/plain;charset=utf-8" }
        );

        if (
          Number(procesosCompras[i].iva1) === 0 &&
          Number(procesosCompras[i].iva2) === 0 &&
          Number(procesosCompras[i].iva3) === 0
        ) {
          blobComprasAlicuotas = new Blob(
            [
              blobComprasAlicuotas,
              xTipo +
                String(procesosCompras[i].puntoventa).padStart(5, "0") +
                String(procesosCompras[i].numero).padStart(20, "0") +
                xCodDoc +
                xCuit +
                xParteEnteraNeto +
                xParteDecimalNeto +
                "0003" +
                xParteEnteraIva +
                xParteDecimalIva +
                "\n",
            ],
            { type: "text/plain;charset=utf-8" }
          );
          //Print #3, Format(!fechacomprobante, "dd/mm/yyyy"); ";"; Format(!fechacontabilizacion, "dd/mm/yyyy"); ";"; xFactExcel; ";"; xTipo; ";"; !sucursal; ";"; !numero; ";"; !nombre_razonsocial; ";"; xCodDoc; ";"; xCuit; ";"; !domicilio; ";"; !cp; ";"; xLocalidad; ";"; xCondicion; ";"; "REF"; ";"; !neto; ";"; 0; ";"; 0; ";"; 0; ";"; "REF"; ";"; !monotributista; ";"; "REF"; ";"; !periva + !perganancias + !perib + !perib2 + !retganancias + !retib; ";"; xLocalidad; ";"; !total
        } else {
          if (Number(procesosCompras[i].iva1) !== 0) {
            blobComprasAlicuotas = new Blob(
              [
                blobComprasAlicuotas,
                xTipo +
                  String(procesosCompras[i].puntoventa).padStart(5, "0") +
                  String(procesosCompras[i].numero).padStart(20, "0") +
                  xCodDoc +
                  xCuit +
                  xParteEnteraNeto +
                  xParteDecimalNeto +
                  "0004" +
                  xParteEnteraIva +
                  xParteDecimalIva +
                  "\n",
              ],
              { type: "text/plain;charset=utf-8" }
            );

            //Print #3, Format(!fechacomprobante, "dd/mm/yyyy"); ";"; Format(!fechacontabilizacion, "dd/mm/yyyy"); ";"; xFactExcel; ";"; xTipo; ";"; !sucursal; ";"; !numero; ";"; !nombre_razonsocial; ";"; xCodDoc; ";"; xCuit; ";"; !domicilio; ";"; !cp; ";"; xLocalidad; ";"; xCondicion; ";"; "REF"; ";"; !neto; ";"; 21; ";"; !iva1; ";"; !iva1; ";"; "REF"; ";"; !monotributista; ";"; "REF"; ";"; !periva + !perganancias + !perib + !perib2 + !retganancias + !retib; ";"; xLocalidad; ";"; !total
          }
          if (Number(procesosCompras[i].iva2) !== 0) {
            blobComprasAlicuotas = new Blob(
              [
                blobComprasAlicuotas,
                xTipo +
                  String(procesosCompras[i].puntoventa).padStart(5, "0") +
                  String(procesosCompras[i].numero).padStart(20, "0") +
                  xCodDoc +
                  xCuit +
                  xParteEnteraNeto +
                  xParteDecimalNeto +
                  "0005" +
                  xParteEnteraIva +
                  xParteDecimalIva +
                  "\n",
              ],
              { type: "text/plain;charset=utf-8" }
            );
            //Print #3, Format(!fechacomprobante, "dd/mm/yyyy"); ";"; Format(!fechacontabilizacion, "dd/mm/yyyy"); ";"; xFactExcel; ";"; xTipo; ";"; !sucursal; ";"; !numero; ";"; !nombre_razonsocial; ";"; xCodDoc; ";"; xCuit; ";"; !domicilio; ";"; !cp; ";"; xLocalidad; ";"; xCondicion; ";"; "REF"; ";"; !neto; ";"; 27; ";"; !iva2; ";"; !iva2; ";"; "REF"; ";"; !monotributista; ";"; "REF"; ";"; !periva + !perganancias + !perib + !perib2 + !retganancias + !retib; ";"; xLocalidad; ";"; !total
          }
          if (Number(procesosCompras[i].iva3) !== 0) {
            blobComprasAlicuotas = new Blob(
              [
                blobComprasAlicuotas,
                xTipo +
                  String(procesosCompras[i].puntoventa).padStart(5, "0") +
                  String(procesosCompras[i].numero).padStart(20, "0") +
                  xCodDoc +
                  xCuit +
                  xParteEnteraNeto +
                  xParteDecimalNeto +
                  "0006" +
                  xParteEnteraIva +
                  xParteDecimalIva +
                  "\n",
              ],
              { type: "text/plain;charset=utf-8" }
            );
            //Print #3, Format(!fechacomprobante, "dd/mm/yyyy"); ";"; Format(!fechacontabilizacion, "dd/mm/yyyy"); ";"; xFactExcel; ";"; xTipo; ";"; !sucursal; ";"; !numero; ";"; !nombre_razonsocial; ";"; xCodDoc; ";"; xCuit; ";"; !domicilio; ";"; !cp; ";"; xLocalidad; ";"; xCondicion; ";"; "REF"; ";"; !neto; ";"; 10.5; ";"; !iva3; ";"; !iva3; ";"; "REF"; ";"; !monotributista; ";"; "REF"; ";"; !periva + !perganancias + !perib + !perib2 + !retganancias + !retib; ";"; xLocalidad; ";"; !total
          }
        }
      }
    }

    saveAs(blobCabecera, "REGINFO_CV_CABECERA.TXT");
    saveAs(blobVentasComprobantes, "REGINFO_CV_VENTAS_CBTE.TXT");
    saveAs(blobVentasAlicuotas, "REGINFO_CV_VENTAS_ALICUOTAS.TXT");
    saveAs(blobComprasComprobantes, "REGINFO_CV_COMPRAS_CBTE.TXT");
    saveAs(blobComprasAlicuotas, "REGINFO_CV_COMPRAS_ALICUOTAS.TXT");

    /*
      if (i < impresionComprobantes.length - 1) {
        doc.addPage();
      }
      */
    //window.open(doc.output("bloburl"));
  };

  //console.log(empresas);
  //console.log(serviciosFinalizadosExcel);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      <ConfirmDialog />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Dialog
            header={renderHeaderProceso()}
            visible={visibleProceso}
            closable={false}
            footer={renderFooterProceso()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "70vw", paddingBottom: "0" }}
            position="top"
          >
            <Grid container spacing={3} style={{ marginTop: "1vw" }}>
              <Grid item xs={12} md={4}>
                <label htmlFor="periodo">Período</label>
                <InputMask
                  id="periodo"
                  mask="99/99"
                  value={proceso ? proceso.periodo : null}
                  placeholder="99/99"
                  size={4}
                  onChange={(e) =>
                    guardarProceso({
                      ...proceso,
                      periodo: e.value,
                    })
                  }
                  style={{ marginLeft: "0.7vw", marginTop: "0.4vw" }}
                ></InputMask>
              </Grid>
              <button
                id="generarProceso"
                type="button"
                onClick={(e) => {
                  generar();
                }}
                style={{ display: "none" }}
              >
                Imprimir Facturas
              </button>
            </Grid>
          </Dialog>
        </Fragment>
      ) : null}

      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

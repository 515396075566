import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../context/AlertContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./../Menu";
import { InputNumber } from "primereact/inputnumber";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { InputMask } from "primereact/inputmask";
import IconButton from "@mui/material/IconButton";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { MultiSelect } from "primereact/multiselect";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Cobradores from "../Cobradores";

const exportColumnsGrupos = [
  { title: "Grupo", dataKey: "grupo" },
  { title: "Total", dataKey: "total" },
  { title: "Retenciones", dataKey: "retenciones" },
  { title: "Comisiones", dataKey: "comisiones" },
  { title: "Neto", dataKey: "neto" },
];

const exportColumnsCheques = [
  { title: "Número", dataKey: "numero" },
  { title: "Importe", dataKey: "importe" },
  { title: "Banco", dataKey: "banco" },
  { title: "echeq", dataKey: "echeq" },
  { title: "Empresa", dataKey: "empresa" },
];

const exportColumnsCuentas = [
  { title: "Cuenta", dataKey: "cuenta" },
  { title: "Importe", dataKey: "importe" },
];


export default function ListadosCobranzasPlanilla(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [visibleFechas, setVisibleFechas] = useState(true);
  const [grupo, setGrupo] = useState(null);
  const [orden, setOrden] = useState(null);
  const [fechaDesde, guardarFechaDesde] = useState(null);
  const [fechaHasta, guardarFechaHasta] = useState(null);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedCobradores, setSelectedCobradores] = useState(null);
  const [bandInicio, setBandInicio] = useState(false);
  const [cobranza, setCobranza] = useState("todos");
  const [planilla, setPlanilla] = useState(false);
  const [tipoListado, setTipoListado] = useState("Orden");
  const [subtotal, setSubtotal] = useState(false);
  const [salto, setSalto] = useState(false);

  const toast = useRef(null);

  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    empresaElegida,
    empresas,
    cobradores,
    listadoExcel,
    result,
    obtenerAcceso,
    obtenerBajas,
    obtenerEmpresas,
    obtenerCobradores,
    obtenerCobranzasRealizadasPlanilla,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "ListadosCobranzasPlanilla",
      });
    }
  }, [state.id]);

  useEffect(() => {
    obtenerEmpresas();
    obtenerCobradores();
  }, []);

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  const exitClick = (e) => {
    navigate("/");
  };

  const onHideFechas = (e) => {
    setVisibleFechas(false);
  };

  const aceptarFechas = async (exportType) => {
    //e.preventDefault();
    //Validar

    if (fechaDesde === null) {
      mensajeAlerta(
        "Listados de Planilla de Cobranzas Realizadas",
        "Debe indicar fecha desde !.",
        "error"
      );
      return;
    }

    if (fechaHasta === null) {
      mensajeAlerta(
        "Listados de Planilla de Cobranzas Realizadas",
        "Debe indicar fecha hasta !.",
        "error"
      );
      return;
    }

    if (fechaDesde > fechaHasta) {
      mensajeAlerta(
        "Listados de Planilla de Cobranzas Realizadas",
        "Fecha desde no puede ser mayor que fecha hasta !.",
        "error"
      );
      return;
    }

    if (selectedCities && selectedCities.length === 0) {
      mensajeAlerta(
        "Listados de Cobranzas Realizadas",
        "Debe indicar al menos una empresa !.",
        "error"
      );
      return;
    }

    if (selectedCobradores && selectedCobradores.length === 0) {
      mensajeAlerta(
        "Listados de Cobranzas Realizadas",
        "Debe indicar al menos un cobrador !.",
        "error"
      );
      return;
    }

    var elem;

    let fdesde = fechaDesde + "T00:00:00";
    let fhasta = fechaHasta + "T23:59:59";

    toast.current.show({
      severity: "info",
      summary: "Listados",
      detail: "Obteniendo información... !",
      life: 3000,
    });

    await obtenerCobranzasRealizadasPlanilla({
      desde: fdesde,
      hasta: fhasta,
      selectedCities,
      selectedCobradores,
      exportType,
    }).then((data) => {
      if (exportType === "excel") {
        elem = document.getElementById("expButtonExcel");
        elem.click();
      }
      if (exportType === "pdf") {
        elem = document.getElementById("expButtonPdf");
        elem.click();
      }
    });
    /*
    onHideFechas();
    setGrupo(null);
    guardarVigencia({
      desde: null,
      hasta: null,
    });
    setIncluyeIntegrantes(false);
    setTodasLasEmpresas(false);
    */
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Listados de Cobranzas Realizadas",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const renderHeaderFechas = () => {
    return (
      <div>
        <Tag value="Listado de Cobranzas Realizadas"></Tag>
      </div>
    );
  };

  const renderFooterFechas = () => {
    return (
      <div>
        <button
          id="expButtonExcel"
          type="button"
          onClick={(e) => {
            exportExcel();
          }}
          style={{ display: "none" }}
        >
          Export Excel
        </button>
        <button
          id="expButtonPdf"
          type="button"
          onClick={(e) => {
            exportPdf();
          }}
          style={{ display: "none" }}
        >
          Export Pdf
        </button>
        <Button
          icon="pi pi-file-pdf"
          onClick={(e) => {
            aceptarFechas("pdf");
            //exportPdf();
          }}
          className="p-button-raised p-button-warning"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
          label="PDF"
        />
        <ButtonMui
          onClick={onHideFechas}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const fechaTemplate = (rowData) => {
    return <Fragment>{formatDate(rowData.fecha)}</Fragment>;
  };

  const anuladoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.anulado}></Checkbox>
      </Fragment>
    );
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(listadoExcel);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "cobranzasRealizadas");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default("p");
        doc.setFontSize(10);
        doc.text(
          `Listado de planilla de cobranzas realizadas Desde: ${formatDate(
            fechaDesde
          )} Hasta: ${formatDate(fechaHasta)}`,
          30,
          8
        );

        let empresas = "";
        for (let i = 0; i < selectedCities.length; i++) {
          if (i === 0) {
            empresas = selectedCities[i].nombre;
          } else {
            empresas = empresas + " - " + selectedCities[i].nombre;
          }
        }

        let cobradores = "";
        for (let i = 0; i < selectedCobradores.length; i++) {
          if (i === 0) {
            cobradores = selectedCobradores[i].nombre;
          } else {
            cobradores = cobradores + " - " + selectedCobradores[i].nombre;
          }
        }

        doc.setFontSize(8);
        doc.text(`Empresas: ${empresas}`, 30, 13);

        doc.setFontSize(6);
        doc.text(`Cobradores: ${cobradores}`, 30, 18, {
          maxWidth: 170,
          align: "left",
        });

        let row = 45;

        doc.autoTable(exportColumnsGrupos, listadoExcel, {
          margin: { left: 10 },
          startY: row,
          styles: {
            fontSize: 10,
          },

          columnStyles: {
            1: {
              halign: "right",
            },
            2: {
              halign: "right",
            },
            3: {
              halign: "right",
            },
            4: {
              halign: "right",
            },
          },
          didParseCell: (data) => {
            if (data.cell && data.cell.section === "head") {
              switch (data.cell.raw) {
                case "Total":
                case "Retenciones":
                case "Comisiones":
                case "Neto":
                  data.cell.styles.halign = "right";
                  break;
                default:
                  data.cell.styles.halign = "left";
                  break;
              }
            }
          },
        });

        let efectivo = 0;
        let cheques = 0;
        let bancos = 0;
        let compensaciones = 0;
        let chequesListado = []
        let cuentasListado = []

        for (var i = 0; i < result.length; i++) {
          if (result[i].valor === "efectivo") {
            efectivo = efectivo + Number(result[i].importeTotalPesos);
          }

          if (result[i].valor === "cheques" && result[i].chequeIndex === 0) {
            cheques = cheques + Number(result[i].importeTotalPesos);
            chequesListado.push({
              numero: result[i].chequeNumero,
              importe: Number(result[i].importeTotalPesos).toLocaleString("en-US", {
                minimumFractionDigits: 2,
              }),
              banco: result[i].chequeBanco,
              echeq: result[i].chequeEcheq ? "Si" : "No",
              empresa: result[i].empresas.nombre
            })
          }

          if (result[i].valor === "bancos") {
            bancos = bancos + Number(result[i].importeTotalPesos);
            cuentasListado.push({
              cuenta: result[i].bancoCuenta,
              importe: Number(result[i].importeTotalPesos),
            })
          }

          if (result[i].valor === "compensaciones") {
            compensaciones =
              compensaciones + Number(result[i].importeTotalPesos);
          }
        }

        if (chequesListado && chequesListado.length>0) {
          chequesListado.push({
            numero: null,
            importe: Number(cheques).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            }),            
            banco: null,
            echeq: null,
            empresa: null,
          })
        }


        let finalY = doc.previousAutoTable.finalY;
        row = finalY + 5;

        doc.setFontSize(10);

        doc.text(
          `Total Efectivo: ${Number(efectivo).toLocaleString("en-US", {
            minimumFractionDigits: 2,
          })}`,
          10,
          row
        );

        row = row + 5;
        doc.text(
          `Total Cheques: ${Number(cheques).toLocaleString("en-US", {
            minimumFractionDigits: 2,
          })}`,
          10,
          row
        );

        row = row + 5;
        doc.text(
          `Total Bancos: ${Number(bancos).toLocaleString("en-US", {
            minimumFractionDigits: 2,
          })}`,
          10,
          row
        );

        row = row + 5;
        doc.text(
          `Total Compensaciones: ${Number(compensaciones).toLocaleString(
            "en-US",
            {
              minimumFractionDigits: 2,
            }
          )}`,
          10,
          row
        );

        row = row + 5

        doc.autoTable(exportColumnsCheques, chequesListado, {
          margin: { left: 10 },
          startY: row,
          styles: {
            fontSize: 10,
          },

          columnStyles: {
            1: {
              halign: "right",
            },
          },
          didParseCell: (data) => {
            if (data.cell && data.cell.section === "head") {
              switch (data.cell.raw) {
                case "Importe":
                  data.cell.styles.halign = "right";
                  break;
                default:
                  data.cell.styles.halign = "left";
                  break;
              }
            }
          },
        });


        const groupBy = (prop) => (data) => {
          return data.reduce((dict, item) => {
            const { [prop]: _, ...rest } = item;
            dict[item[prop]] = [...(dict[item[prop]] || []), rest];
            return dict;
          }, {});
        };
  
        //var result = groupBy(serviciosFinalizadosExcel, 'Antecedente');
  
        let campo = "cuenta";
  
        const resultCuentas = Object.entries(groupBy(campo)(cuentasListado)).map(
          ([key, value]) => ({ nombre: key, datos: value })
        );

        let cuentasListadoFinal = []
        let totalGrupo = 0


        for (var i = 0; i < resultCuentas.length; i++) {
          totalGrupo = 0;
          for (var j = 0; j < resultCuentas[i].datos.length; j++) {
            totalGrupo = totalGrupo + Number(resultCuentas[i].datos[j].importe);
          }
          cuentasListadoFinal.push({
            cuenta: resultCuentas[i].nombre,
            importe: Number(totalGrupo).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            }),            

          })
        }

        if (cuentasListadoFinal && cuentasListadoFinal.length>0) {

        cuentasListadoFinal.push({
          cuenta: null,
          importe: Number(bancos).toLocaleString("en-US", {
            minimumFractionDigits: 2,
          }),            

        })
      }


        finalY = doc.previousAutoTable.finalY;
        row = finalY + 5;


        doc.autoTable(exportColumnsCuentas, cuentasListadoFinal, {
          margin: { left: 10 },
          startY: row,
          styles: {
            fontSize: 10,
          },

          columnStyles: {
            1: {
              halign: "right",
            },
          },
          didParseCell: (data) => {
            if (data.cell && data.cell.section === "head") {
              switch (data.cell.raw) {
                case "Importe":
                  data.cell.styles.halign = "right";
                  break;
                default:
                  data.cell.styles.halign = "left";
                  break;
              }
            }
          },
        });





        //doc.save("bajasAfiliados.pdf");
        window.open(doc.output("bloburl"));
      });
    });
  };

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  // INICIO

  if (!bandInicio && empresas && empresas.length > 0 && empresaElegida) {
    setBandInicio(true);
    const index = empresas.findIndex((item) => item.id === empresaElegida.id);
    if (index !== -1) {
      let final = [];
      final.push(empresas[index]);
      setSelectedCities(final);
    }

    setSelectedCobradores([]);
  }

  // FIN

  //console.log(selectedCities);
  //console.log(serviciosFinalizadosExcel);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Dialog
            header={renderHeaderFechas()}
            visible={visibleFechas}
            closable={false}
            footer={renderFooterFechas()}
            breakpoints={{ "960px": "90vw" }}
            style={{ width: "90vw", paddingBottom: "0" }}
            position="top"
          >
            <Grid container spacing={3} style={{ marginTop: "1vw" }}>
              <Grid item xs={12} md={2}>
                <TextField
                  id="fechaDesde"
                  label="Fecha Desde"
                  type="date"
                  variant="standard"
                  fullWidth
                  value={fechaDesde ? fechaDesde : null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    guardarFechaDesde(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={2}>
                <TextField
                  id="fechaHasta"
                  label="Fecha Hasta"
                  type="date"
                  variant="standard"
                  fullWidth
                  value={fechaHasta ? fechaHasta : null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    guardarFechaHasta(e.target.value);
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                style={{ paddingLeft: "1vw", marginTop: "1vw" }}
              >
                <label htmlFor="id">Empresas</label>

                <MultiSelect
                  value={selectedCities}
                  onChange={(e) => setSelectedCities(e.value)}
                  options={
                    empresas ? empresas.filter((item) => item.habilitado) : null
                  }
                  optionLabel="nombre"
                  placeholder="Seleccione las empresas"
                  maxSelectedLabels={5}
                  display="chip"
                  style={{ marginLeft: "1vw" }}
                  //className="w-full md:w-40rem"
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                style={{
                  paddingLeft: "1vw",
                  marginTop: "1vw",
                  fontSize: "x-small",
                }}
              >
                <label htmlFor="id">Cobradores</label>

                <MultiSelect
                  value={selectedCobradores}
                  onChange={(e) => setSelectedCobradores(e.value)}
                  options={
                    cobradores
                      ? cobradores.filter((item) => item.habilitado)
                      : null
                  }
                  optionLabel="nombre"
                  placeholder="Seleccione los cobradores"
                  maxSelectedLabels={8}
                  display="chip"
                  style={{ marginLeft: "1vw", fontSize: "x-small" }}
                  //className="w-full md:w-40rem"
                />
              </Grid>
            </Grid>
          </Dialog>
        </Fragment>
      ) : null}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */

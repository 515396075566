import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../context/AlertContext";
import { ServiciosContext } from "../../context/ServiciosContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Navigate, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Menu from "./Menu";

export default function Cuentas(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [bandInicio, setBandInicio] = useState(false);

  let navigate = useNavigate();

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Descripción", dataKey: "descripcion" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [cuenta, guardarCuenta] = useState(null);
  const [visible, setVisible] = useState(false);
  const [banco, guardarBanco] = useState(null);
  const [empresa, guardarEmpresa] = useState(null);
  const [conceptoBanco, guardarConceptoBanco] = useState(null);
  const [conceptoCheque, guardarConceptoCheque] = useState(null);
  const [moneda, guardarMoneda] = useState(null);
  const toast = useRef(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    cuentas,
    bancos,
    empresas,
    conceptos,
    monedas,
    monedaPredeterminada,
    obtenerCuentas,
    obtenerAcceso,
    agregarCuenta,
    actualizarCuenta,
    obtenerConceptos,
    obtenerBancos,
    obtenerEmpresas,
    empresaElegida,
    obtenerMonedas,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Cuentas" });
    }
  }, [state]);

  useEffect(() => {
    obtenerCuentas();
    obtenerConceptos();
    obtenerBancos();
    obtenerEmpresas();
    obtenerMonedas();
  }, []);

  const editClick = (e) => {
    if (selectedRow) {
      setBandInicio(false);
      guardarCuenta(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar una cuenta bancaria.",
        life: 3000,
      });
    }
  };

  const addClick = (e) => {
    //props.history.push(`/cuenta/0`);
    setVisible(true);
    setSelectedRow(null);
    guardarCuenta({
      habilitado: true,
    });

    let mon = monedas.findIndex((item) => item.id === monedaPredeterminada);
    guardarMoneda({
      id_moneda: monedaPredeterminada,
      moneda: monedas[mon].nombre,
    });
  };

  const exitClick = (e) => {
    navigate("/");
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !cuenta ||
      cuenta.nombre === undefined ||
      cuenta.nombre === null ||
      cuenta.nombre.trim() === ""
    ) {
      mensajeAlerta("Cuentas Bancarias", "Nombre es necesario !.", "error");
      return;
    }

    if (!banco || banco === undefined || banco === null) {
      mensajeAlerta("Cuentas Bancarias", "Banco es necesario !.", "error");
      return;
    }

    if (!empresa || empresa === undefined || empresa === null) {
      mensajeAlerta("Cuentas Bancarias", "Empresa es necesario !.", "error");
      return;
    }

    if (
      !conceptoBanco ||
      conceptoBanco === undefined ||
      conceptoBanco === null
    ) {
      mensajeAlerta(
        "Cuentas Bancarias",
        "Concepto Banco es necesario !.",
        "error"
      );
      return;
    }

    if (
      !conceptoCheque ||
      conceptoCheque === undefined ||
      conceptoCheque === null
    ) {
      mensajeAlerta(
        "Cuentas Bancarias",
        "Concepto Cheque es necesario !.",
        "error"
      );
      return;
    }

    if (!moneda || moneda === undefined || moneda === null) {
      mensajeAlerta(
        "Cuentas Bancarias",
        "Debe ingresar una moneda !.",
        "error"
      );
      return;
    }

    //console.log(cuenta)

    // Llamar al context
    if (selectedRow) {
      actualizarCuenta({ cuenta });
    } else {
      agregarCuenta({ cuenta });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerCuentas();
    }, 2000);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Cuentas",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, cuentas);
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(cuentas);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "cuentas");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      return new Promise((resolve) => {
        saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
        resolve(true);
      });
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Cuentas Bancarias"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Cuenta Bancaria"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  //INICIO IF

  if (
    bancos &&
    bancos.length > 0 &&
    empresas &&
    empresas.length > 0 &&
    conceptos &&
    conceptos.length > 0 &&
    cuenta &&
    monedas &&
    monedas.length > 0 &&
    monedaPredeterminada &&
    !bandInicio
  ) {
    setBandInicio(true);

    let index = null;
    let data = null;

    index = bancos.findIndex((banco) => banco.id === cuenta.id_banco);
    data = index !== -1 ? bancos[index] : null;
    guardarBanco(data);

    index = empresas.findIndex((empresa) => empresa.id === cuenta.id_empresa);
    data = index !== -1 ? empresas[index] : null;
    guardarEmpresa(data);

    index = conceptos.findIndex(
      (concepto) => concepto.id === cuenta.id_conceptoba
    );
    data = index !== -1 ? conceptos[index] : null;
    guardarConceptoBanco(data);

    index = conceptos.findIndex(
      (concepto) => concepto.id === cuenta.id_conceptoch
    );
    data = index !== -1 ? conceptos[index] : null;
    guardarConceptoCheque(data);

    index = monedas.findIndex((moneda) => moneda.id === cuenta.id_moneda);
    data = index !== -1 ? monedas[index] : null;
    guardarMoneda(data);
  }

  //FIN IF

  //console.log(conceptoBanco);

  return (
    <Fragment>
      <Menu />
      <Toast ref={toast} />
      {statusAcceso === 0 ? <Spinner /> : null}
      {statusAcceso === 400 ? accesoDenegado() : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {cuentas && bancos && empresas && conceptos ? (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ align: "center", margin: "1em" }}
              >
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-pencil"
                  tooltip="Editar"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={
                        empresas &&
                        empresas.filter(
                          (item) => item.control && item.habilitado
                        ).length > 0
                          ? cuentas.filter(
                              (item) => item.id_empresa === empresaElegida.id
                            )
                          : empresas &&
                            empresas.filter((item) => item.habilitado)
                          ? cuentas.filter((item) => item.habilitado)
                          : null
                      }
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small", marginTop: "1vw" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        //body={idTemplate}
                        style={{ display: "none" }}
                        sortable
                      ></Column>
                      <Column
                        field="nombre"
                        header="Nombre"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="bancos.nombre"
                        header="Banco"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="empresas.nombre"
                        header="Empresa"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="conceptosbancos.nombre"
                        header="Concepto Banco"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="conceptoscheques.nombre"
                        header="Concepto Cheque"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="monedas.nombre"
                        header="Moneda"
                        //body={descripcionTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="habilitado"
                        header="Habilitado"
                        body={habilitadoTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                  {selectedRow ? (
                    <Grid item xs={12} md={2}>
                      <TextField
                        value={cuenta ? cuenta.id : null}
                        required
                        variant="standard"
                        id="id"
                        label="ID"
                        fullWidth
                        disabled
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={10}>
                    <TextField
                      value={cuenta ? cuenta.nombre : null}
                      required
                      variant="standard"
                      id="nombre"
                      label="Nombre"
                      fullWidth
                      onChange={(e) =>
                        guardarCuenta({
                          ...cuenta,
                          nombre: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="bancos"
                      //disabled
                      fullWidth
                      options={
                        bancos
                          ? bancos.filter((banco) => banco.habilitado)
                          : null
                      }
                      disablePortal
                      /*
                      defaultValue={
                        bancos[
                          bancos.findIndex(
                            (banco) => banco.id === cuenta.id_banco
                          )
                        ]
                      }
                      */
                      value={banco}
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Banco *"
                          size="small"
                        />
                      )}
                      onChange={(event, value) => {
                        if (value) {
                          guardarBanco(value);
                          guardarCuenta({
                            ...cuenta,
                            id_banco: value.id,
                            banco: value.nombre,
                          });
                        } else {
                          guardarBanco(null);
                          guardarCuenta({
                            ...cuenta,
                            id_banco: null,
                            banco: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="empresas"
                      //disabled
                      fullWidth
                      options={
                        empresas
                          ? empresas.filter((empresa) => empresa.habilitado)
                          : null
                      }
                      disablePortal
                      //defaultValue={colores[idColorDefault]}

                      value={empresa}
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Empresa *"
                          size="small"
                        />
                      )}
                      onChange={(event, value) => {
                        if (value) {
                          guardarEmpresa(value);
                          guardarCuenta({
                            ...cuenta,
                            id_empresa: value.id,
                            empresa: value.nombre,
                          });
                        } else {
                          guardarEmpresa(null);
                          guardarCuenta({
                            ...cuenta,
                            id_empresa: null,
                            empresa: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      id="conceptoBanco"
                      //disabled
                      fullWidth
                      options={
                        conceptos
                          ? conceptos.filter((concepto) => concepto.habilitado)
                          : null
                      }
                      disablePortal
                      //defaultValue={colores[idColorDefault]}

                      value={conceptoBanco}
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Concepto Banco *"
                          size="small"
                        />
                      )}
                      onChange={(event, value) => {
                        if (value) {
                          guardarConceptoBanco(value);
                          guardarCuenta({
                            ...cuenta,
                            id_conceptoba: value.id,
                            conceptoba: value.nombre,
                          });
                        } else {
                          guardarConceptoBanco(null);
                          guardarCuenta({
                            ...cuenta,
                            id_conceptoba: null,
                            conceptoba: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      id="conceptoCheque"
                      //disabled
                      fullWidth
                      options={
                        conceptos
                          ? conceptos.filter((concepto) => concepto.habilitado)
                          : null
                      }
                      disablePortal
                      //defaultValue={colores[idColorDefault]}

                      value={conceptoCheque}
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Concepto Cheque *"
                          size="small"
                        />
                      )}
                      onChange={(event, value) => {
                        if (value) {
                          guardarConceptoCheque(value);
                          guardarCuenta({
                            ...cuenta,
                            id_conceptoch: value.id,
                            conceptoch: value.nombre,
                          });
                        } else {
                          guardarConceptoCheque(null);
                          guardarCuenta({
                            ...cuenta,
                            id_conceptoch: null,
                            conceptoch: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="moneda"
                      size={"small"}
                      fullWidth
                      noOptionsText={"No hay opciones"}
                      options={monedas ? monedas : null}
                      value={moneda}
                      getOptionLabel={(option) =>
                        option.nombre + " (" + option.simbolo + ")"
                      }
                      openOnFocus={true}
                      disablePortal
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Moneda *"
                          variant="standard"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarMoneda(value);
                          guardarCuenta({
                            ...cuenta,
                            id_moneda: value.id,
                            moneda: value.nombre,
                          });
                        } else {
                          guardarMoneda(null);
                          guardarCuenta({
                            ...cuenta,
                            id_moneda: null,
                            moneda: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ paddingLeft: 0, marginTop: "1vw" }}
                  >
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={cuenta ? cuenta.habilitado : null}
                          style={{ marginLeft: "1vw" }}
                          onChange={(e) =>
                            guardarCuenta({
                              ...cuenta,
                              habilitado: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Habilitado"
                      labelPlacement="start"
                      style={{ paddingLeft: ".7vw" }}
                    />
                  </Grid>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
